/* eslint-env es6 */

import { isDate } from 'lodash';
import ndRes from 'deprecated/nd.res';

const prefix = {
    second: 'Second',
    minute: 'Minute',
    hour: 'Hour',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    year: 'Year',
};

/**
 * Throws an error if given argument is not a Date object.
 * @param {*} date a value to check.
 * @throws {TypeError} Argument is not a Date object.
 */
function checkIfDate(date) {
    if (!isDate(date)) {
        throw new TypeError(`Date expected, given ${typeof date}`);
    }
}

/**
 * Calculates how many milliseconds there are in given days amount.
 * @param {Number} days Days amount.
 * @return {Number} The amount of milliseconds.
 */
function daysToMilliseconds(days) {
    return 1000 * 60 * 60 * 24 * days;
}

/**
 * Calculates how many days there are in given milliseconds amount.
 * @param {Number} ms Milliseconds amount.
 * @return {Number} The amount of days.
 */
function millisecondsToDays(ms) {
    return ms / (1000 * 60 * 60 * 24);
}

/**
 * Returns the beginning of the same day.
 * In a sense this method returns a date rounded to its day value.
 * @param {Date} date A date to process.
 * @return {Date} A date object that represents the same day, but has zero hours, minutes, seconds and milliseconds.
 */
function roundToDay(date) {
    checkIfDate(date);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

/**
 * Returns the beginning of the day of the current moment.
 * @return {Date} The beginning of present day.
 */
function today() {
    return roundToDay(new Date());
}

/**
 * Returns an array of continuous days
 * that starts at the given day and lasts for given amount of days.
 * @param {date} startDate Starting date.
 * @param {Number} dayAmount The amount of days for period.
 * @return {Date[]} An array of dates.
 */
function getRangeByDayAmount(startDate, dayAmount) {
    checkIfDate(startDate);

    const days = [];
    const current = roundToDay(startDate);

    while (dayAmount-- > 0) {
        days.push(new Date(current));
        current.setDate(current.getDate() + 1);
    }

    return days;
}

/**
 * Returns an array of continuous days
 * that starts at the given start day and lasts until given end day.
 * The day of given end date is included.
 * @param {Date} startDate Start day.
 * @param {Date} endDate End day.
 * @return {Date[]} An array of dates.
 */
function getRange(startDate, endDate) {
    checkIfDate(startDate);
    checkIfDate(endDate);

    const daysAmount = Math.round(millisecondsToDays(endDate - startDate));
    return getRangeByDayAmount(startDate, daysAmount + 1); // +1
}

function getDateInfo(d) {
    return {
        year: d.getFullYear(),
        month: d.getMonth(),
        date: d.getDate(),
        hours: d.getHours(),
        minutes: d.getMinutes(),
        seconds: d.getSeconds(),
    };
}

/**
 * Split interval between two dates into periods of years, months, weeks, days, hours, minutes and seconds.
 * @param  startDate Initial date.
 * @param  endDate Last date. By default uses current date.
 * @returns {Array.<Object>|null}   An array of objects each of which contains time period prefix and duration of that period.
 */
function parsePeriodsFromDate(startDate, endDate = new Date()) {
    checkIfDate(startDate);
    checkIfDate(endDate);

    if (startDate > endDate) {
        throw new TypeError(`The endDate (${endDate}) must be later than the startDate (${startDate})`);
    }
    const startDateInfo = getDateInfo(startDate);
    const endDateInfo = getDateInfo(endDate);

    let years = endDateInfo.year - startDateInfo.year;
    let months = endDateInfo.month - startDateInfo.month;
    let days = endDateInfo.date - startDateInfo.date;
    let hours = endDateInfo.hours - startDateInfo.hours;
    let minutes = endDateInfo.minutes - startDateInfo.minutes;
    let seconds = endDateInfo.seconds - startDateInfo.seconds;

    if (seconds < 0) {
        minutes--;
        seconds += 60;
    }
    if (minutes < 0) {
        hours--;
        minutes += 60;
    }
    if (hours < 0) {
        days--;
        hours += 24;
    }
    if (days < 0) {
        months--;
        const daysInPrevMonth = new Date(new Date(endDate).setDate(0)).getDate();
        days += daysInPrevMonth;
    }
    if (months < 0) {
        years--;
        months += 12;
    }

    const periodDesc = [
        { prefix: prefix.year, duration: years },
        { prefix: prefix.month, duration: months },
        { prefix: prefix.day, duration: days },
        { prefix: prefix.hour, duration: hours },
        { prefix: prefix.minute, duration: minutes },
        { prefix: prefix.second, duration: seconds },
    ];

    return periodDesc.reduce((buf, item) => {
        if (item.duration > 0 || buf.length) {
            buf.push({
                prefix: item.prefix,
                period: item.duration,
            });
        }
        return buf;
    }, []);
}

/**
 * Splits time interval from startDate to now into periods of years, months, weeks, days, hours, minutes and seconds.
 * @param startDate - Initial date of the period
 * @param endDate - Final date of the period
 * @param precision - Number parts of the period
 */
function getPrettyTimePeriodBetweenDates(startDate, endDate, precision = 2) {
    let parsed;
    try {
        parsed = parsePeriodsFromDate(startDate, endDate);
    } catch (e) {
        return '';
    }
    // Default "precision" is two time periods e.g. days and hours, hours and minutes etc.
    // There is an issue in 'splice' in 'es-shim' in Safari, second parameter isn't optional there.
    parsed.splice(precision, Number.MAX_VALUE);
    const resourceNamePrefix = 'TimePeriod';

    return (
        parsed
            // Period duration is not zero and there were no empty periods before
            .filter(p => p.period > 0)
            .map(p => {
                let resourceName = p.prefix;
                if (p.period > 1) {
                    resourceName = `${resourceName}s`;
                }
                const fullResourceName = [resourceNamePrefix, resourceName].join('.');
                return ndRes.format(fullResourceName, p.period);
            })
            .join(' ')
    );
}

/**
 * Splits time different from startDate to now into periods of years, months, weeks, days, hours, minutes and seconds.
 * @param  {!date|!number} startDate date or the amount of seconds.
 * @returns {string}  String contains time period prefix and duration of that period.
 */
function getPrettyTimePeriod(startDate, precision = 2) {
    if (!isDate(startDate)) {
        const seconds = parseInt(startDate, 10);
        startDate = new Date(new Date() - seconds * 1000);
    }
    return getPrettyTimePeriodBetweenDates(startDate, new Date(), precision);
}

/*
 * Usage:
 * getPrettyTimePeriodAgo(211201200, 2) //4 years 2 months ago
 * getPrettyTimePeriodAgo(211201200)    //4 years 2 months ago
 * getPrettyTimePeriodAgo(date, 1)      //4 years ago
 **/
function getPrettyTimePeriodAgo(seconds, precision = 2) {
    const period = getPrettyTimePeriod(seconds, precision);
    return period ? `${period} ago` : period;
}

/**
 * Represents the maximum value of Date object.
 * @see http://ecma-international.org/ecma-262/5.1/#sec-15.9.1.1|Spec §15.9.1.1: Time Values and Time Range
 */
const MAX_DATE = new Date(8640000000000000);

/**
 * Represents the minimum value of Date object.
 * @see http://ecma-international.org/ecma-262/5.1/#sec-15.9.1.1|Spec §15.9.1.1: Time Values and Time Range
 */
const MIN_DATE = new Date(-8640000000000000);

function calcDiffInDays(date1, date2) {
    const diffTime = Math.abs(date2 - date1);
    return Math.floor(diffTime / (1000 * 60 * 60 * 24));
}

export {
    MAX_DATE,
    MIN_DATE,
    daysToMilliseconds,
    millisecondsToDays,
    roundToDay,
    today,
    getRangeByDayAmount,
    getRange,
    parsePeriodsFromDate,
    getPrettyTimePeriod,
    getPrettyTimePeriodAgo,
    getPrettyTimePeriodBetweenDates,
    calcDiffInDays,
};
