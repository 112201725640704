import * as ko from 'knockout';
import { isArray, find } from 'lodash';
import { resetValidationState, validate } from '@pressreader/src/ko/nd/validate';

/**
 * Represents base section view model.
 * Section has set of properties copied from master model.
 *
 * @abstract
 */
class SectionBaseViewModel {
    /**
     * Constructor
     * @param master - master model which has observables properties
     * @param properties - list of master properties names to copy into section properties
     */
    constructor({ master, properties }) {
        if (!isArray(properties)) {
            throw new TypeError('Array of properties expected');
        }

        // inidcates that section is currently expanded
        // if true - expand section for editing
        // if false - collapses section for editing
        this.isExpanded = ko.observable(false);

        this._master = master;
        this._properties = properties;
        this._subscriptions = [];
        this._properties.forEach(prop => {
            if (!ko.isObservable(master[prop])) {
                throw new TypeError(`'${prop}' property of master model has to be observable`);
            }

            // create local observable with copy of property from master model
            this[prop] = ko.observable(master[prop]());

            // subscribe to changes in master model and propagate them to the section properties
            this._subscriptions.push(
                master[prop].subscribe(value => {
                    this[prop](value);
                }),
            );
        });
    }

    get master() {
        return this._master;
    }

    /**
     * Saves data of section
     * @abstract
     */
    _save() {
        throw new Error('Abstract method should not be invoked');
    }

    /**
     * Gets data from section properties
     */
    getData() {
        const data = this._properties.reduce((data, prop) => {
            if (!['visible', 'required', 'editable', 'available'].some(v => prop.toLowerCase().indexOf(v) >= 0)) {
                data[prop] = this[prop]();
            }
            return data;
        }, {});
        return this.isAddress ? { address: data } : data;
    }

    /**
     * Restores original values
     */
    restore() {
        // restore original values
        this._properties.forEach(prop => {
            this[prop](this._master[prop]());
        });
    }

    dispose() {
        this._subscriptions.forEach(subscription => {
            subscription.dispose();
        });
    }

    edit() {
        this.isExpanded(true);
        resetValidationState(this);
    }

    save() {
        if (validate(this)) {
            this._save().then(() => {
                this.isExpanded(false);
            });
        }
    }

    cancelEdit() {
        this.restore();
        this.isExpanded(false);
    }

    hasUnsavedChanges() {
        // eslint-disable-next-line eqeqeq
        return find(this._properties, prop => this[prop]() != this._master[prop]()) !== undefined;
    }
}

export default SectionBaseViewModel;
