import { ActionPattern, take, TakeEffect } from 'redux-saga/effects';

import { ActionCreator, IAction } from '../models/action';

/**
 * Creates an Effect description that instructs the middleware to wait for a specified action on the Store.
 * The Generator is suspended until an action is dispatched which matches pattern and predicate return true for that action.
 */
function takeIf<TPayload>(
    actionCreator: ActionCreator<TPayload>,
    predicate: (action: IAction<TPayload>) => boolean,
): Generator<TakeEffect, IAction<TPayload>, IAction<TPayload>>;
/**
 * Creates an Effect description that instructs the middleware to wait for a specified action on the Store.
 * The Generator is suspended until an action is dispatched which matches pattern and predicate return true for that action.
 */
function takeIf<TPayload>(
    pattern: ActionPattern,
    predicate: (action: IAction<TPayload>) => boolean,
): Generator<TakeEffect, IAction<TPayload>, IAction<TPayload>>;

function* takeIf<TPayload>(
    pattern: ActionPattern,
    predicate: (action: IAction<TPayload>) => boolean,
): Generator<TakeEffect, IAction<TPayload>, IAction<TPayload>> {
    while (true) {
        const action = yield take(pattern);
        if (predicate(action)) {
            return action;
        }
    }
}

export { takeIf };
