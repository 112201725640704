import { assign } from 'lodash';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { sendLoginBeganEvent, sendLoginDoneEvent, sendLoginFailedEvent, signInService } from '@pressreader/authentication-api';
import { EXTERNAL_AUTH_ERROR } from '@pressreader/authentication-types';
import { svcAuth } from '@pressreader/services';
import { AuthenticationType } from '@pressreader/src/authentication/authenticationtypes';
import config from '@pressreader/src/deprecated/nd.config';
import { eventbus } from '@pressreader/src/eventbus/eventbus';
import ndAlert from '@pressreader/src/nd.alert';
import { DialogStatus } from '@pressreader/src/nd.ui.dialogstatus';
import ndLoading from '@pressreader/src/shell/loading.indicator';

import 'authentication/analytics/eventsubscriber';

import { loadExternalAuthenticationProvider } from 'nd.externalauthenticationprovider';
import res from 'nd.res';
import ndUser from 'nd.user';
import authProvider from 'authentication/nd.authentication.provider';
import {
    authorizeExternalUser,
    doRecoverPassword,
    doSignInExternal,
    externalSignIn,
    onAfterAccountChanged,
    onBeforeAccountChanged,
    reauthorize,
    signInByKey,
    signOut,
} from 'authentication/nd.authentication.service';
import signinDialog from 'authentication/nd.ui.dialog.signin';
import { ScreensetType } from 'integration/screenSets';

import { SignInRequestedEvent } from './events/events';

let loginSubscription: Subscription = null;

function subscribeOnce(subscription: Subscription) {
    if (loginSubscription) {
        loginSubscription.unsubscribe();
    }
    loginSubscription = subscription;
}

function createDialogActions(dialog) {
    async function signIn({ userName, password, enableAutologin }) {
        try {
            await signInService({ userName, password, enableAutologin });
            return dialog.hide(DialogStatus.Ok);
        } catch (error) {
            return dialog.setSignInError(error.message);
        }
    }

    function signInExternal({ system, provider, enableAutologin }) {
        return doSignInExternal({ system, provider, enableAutologin })
            .then(() => dialog.hide(DialogStatus.Ok))
            .catch(error => {
                if (!error || error.code === EXTERNAL_AUTH_ERROR.CANCELLED) {
                    return Promise.reject();
                }

                dialog.setSignInError(error.message || 'Unknown error');
                return Promise.reject(error);
            });
    }

    function recoverPassword({ logonName }) {
        doRecoverPassword({ userName: logonName }).catch(error => ndAlert().show(error.message));
        const message = res.val('Accounting.PasswordRecovery.EmailConfirmationIsSent').replace(/\{0\}/g, logonName);
        ndAlert().show(message);
        dialog.hide(DialogStatus.Ok);
    }

    return {
        signIn,
        signInExternal,
        recoverPassword,
    };
}

// do not remove: backward compatibility
function addMethodBeforeAccountWasChanged(fn) {
    if (typeof fn !== 'function') {
        throw new TypeError('Method has to be a function');
    }
    onBeforeAccountChanged.subscribe(fn);
}

// do not remove: backward compatibility
function addMethodAfterAccountWasChanged(fn) {
    if (typeof fn !== 'function') {
        throw new TypeError('Method has to be a function');
    }
    onAfterAccountChanged.subscribe(fn);
}

function signOutServices() {
    return authProvider.signOut();
}

async function onExternalLoginSuccess({ signInResult, errorHandler = null }) {
    ndLoading.show();
    try {
        await authorizeExternalUser(signInResult);
        ndLoading.hide();
        return await Promise.resolve();
    } catch (error) {
        ndLoading.hide();
        if (typeof errorHandler === 'function') {
            errorHandler(error);
        }
        return await Promise.reject(error);
    }
}

async function showExternalLoginPlugin() {
    await config.loaded();
    if (config.get('Authentication.EnableExternalAuthenticationLoginPlugin', false)) {
        const [, extAuthProvider] = await Promise.all([svcAuth.authorized(), loadExternalAuthenticationProvider()]);
        if (extAuthProvider) {
            subscribeOnce(
                extAuthProvider.onLoginSuccess.subscribe(signInResult =>
                    onExternalLoginSuccess({
                        signInResult,
                    }).catch(error => {
                        ndAlert().show(error.message);
                        extAuthProvider.signOut();
                    }),
                ),
            );
            extAuthProvider.showLoginPlugin();
        }
    }
}

async function showExternalLoginPage(containerSelector) {
    ndLoading.show();
    const [, extAuthProvider] = await Promise.all([svcAuth.authorized(), loadExternalAuthenticationProvider()]);
    ndLoading.hide();
    if (extAuthProvider) {
        extAuthProvider.showInlineScreenSet(ScreensetType.signIn, containerSelector);
    }
}

async function init() {
    await config.loaded();
    if (
        config.get('Authentication.EnableExternalAuthenticationScreenSet', false) ||
        config.get('Authentication.EnableExternalAuthenticationLoginPlugin', false)
    ) {
        const [, extAuthProvider] = await Promise.all([svcAuth.authorized(), loadExternalAuthenticationProvider()]);
        if (extAuthProvider) {
            subscribeOnce(
                extAuthProvider.onLoginSuccess.subscribe(async signInResult => {
                    if (ndUser.isLogin()) {
                        return;
                    }
                    const system = 'email';
                    sendLoginBeganEvent({ system });
                    try {
                        await onExternalLoginSuccess({
                            signInResult,
                        });
                        sendLoginDoneEvent({ system });
                    } catch (error) {
                        sendLoginFailedEvent({ error, system });
                        ndAlert().show(error.message);
                        extAuthProvider.signOut();
                    }
                }),
            );
        }
    }
}

const signIn =
    (globalConf = null) =>
    (dialogConf = null) => {
        return config.loaded().then(() => {
            const externalAuthenticationPage = config
                .get('Authentication.ExternalAuthenticationPage', '')
                .replace('__CURRENT_PAGE__', encodeURIComponent(window.location.href));
            const enablePublisherAuthenticationWebService = config.get('Authentication.EnablePublisherAuthenticationWebService', false);
            if (externalAuthenticationPage && enablePublisherAuthenticationWebService) {
                window.location.href = externalAuthenticationPage;
                return Promise.reject(undefined);
            }

            const externalAuthenticationScreenSetEnabled = config.get('Authentication.EnableExternalAuthenticationScreenSet', false);
            if (externalAuthenticationScreenSetEnabled) {
                return new Promise((resolve, reject) => {
                    Promise.all([svcAuth.authorized(), loadExternalAuthenticationProvider()]).then(([_, extAuthProvider]) => {
                        subscribeOnce(
                            extAuthProvider.onLoginSuccess.subscribe(signInResult => {
                                extAuthProvider.closeModal();
                                onExternalLoginSuccess({
                                    signInResult,
                                    errorHandler: error => ndAlert().show(error.message),
                                })
                                    .then(resolve)
                                    .catch(reject);
                            }),
                        );

                        extAuthProvider.onModalClosed.pipe(first()).subscribe(dialogStatus => {
                            if (dialogStatus?.status === DialogStatus.Cancel) {
                                reject();
                            }
                        });

                        if (dialogConf?.initialState === signinDialog.states.signUp) {
                            extAuthProvider.showScreenSet(ScreensetType.register);
                        } else {
                            extAuthProvider.showScreenSet(ScreensetType.signIn);
                        }

                        eventbus.send(new SignInRequestedEvent({ system: AuthenticationType.Social }));
                    });
                });
            }

            // OAuth
            let oAuthProvider = '';
            let isWindowMode = false;
            let returnUrl = '';
            const cfg = config.get('Authentication.OAuthProvider');
            if (typeof cfg === 'object') {
                oAuthProvider = cfg.Name;
                isWindowMode = cfg.WindowMode?.Enabled || false;
                returnUrl = isWindowMode ? cfg.WindowMode?.returnUrl || window.location.href : null;
            } else {
                oAuthProvider = cfg;
            }

            if (oAuthProvider) {
                return doSignInExternal({ system: oAuthProvider, enableAutologin: true, isWindowMode, returnUrl })
                    .then(() => Promise.resolve())
                    .catch(error => {
                        if (!error || error.code === EXTERNAL_AUTH_ERROR.CANCELLED) {
                            return Promise.reject();
                        }

                        ndAlert().show(error.message || 'Unknown error');
                        return Promise.reject(error);
                    });
            }

            // Default
            return new Promise((resolve, reject) => {
                signinDialog.onHide((_evt, result) => {
                    if (result.status === DialogStatus.Ok) {
                        return resolve(result.context);
                    }
                    return reject({ userCancelled: true });
                });
                signinDialog.onShow(() => {
                    const externalAuthenticationLoginPluginEnabled = config.get('Authentication.EnableExternalAuthenticationLoginPlugin', false);
                    if (externalAuthenticationLoginPluginEnabled) {
                        Promise.all([svcAuth.authorized(), loadExternalAuthenticationProvider()]).then(([_, extAuthProvider]) => {
                            if (typeof extAuthProvider?.showLoginPlugin === 'function') {
                                subscribeOnce(
                                    extAuthProvider.onLoginSuccess.subscribe(signInResult => {
                                        extAuthProvider.closeModal();
                                        onExternalLoginSuccess({
                                            signInResult,
                                            errorHandler: error => ndAlert().show(error.message),
                                        })
                                            .then(resolve)
                                            .catch(reject);
                                    }),
                                );
                                extAuthProvider.onloginPluginLoad.subscribe(() => signinDialog.resize());
                                extAuthProvider.onloginPluginButtonClicked.subscribe(() => signinDialog.hide());
                                extAuthProvider.showLoginPlugin();
                            }
                        });
                    }
                });
                const conf = assign({}, globalConf, dialogConf);
                const actions = createDialogActions(signinDialog);
                signinDialog.show(conf, actions);
            });
        });
    };

export { doRecoverPassword, doSignInExternal, init };

export default {
    signIn: signIn(),
    externalSignIn,
    onBeforeAccountChanged,
    onAfterAccountChanged,
    addMethodBeforeAccountWasChanged,
    addMethodAfterAccountWasChanged,
    signOut,
    signOutServices,
    signInByKey,
    showExternalLoginPlugin,
    showExternalLoginPage,
    reauthorize,
    init,
};
