import config from 'deprecated/nd.config';
import { FileUpload, IFileUploadParams, IFileUploadResult } from 'storage/file.upload';

const defaultOptions = {
    maxFiles: 1,
    maxFileSize: 10 * 1000 * 1000,
    chunkSize: 1000 * 1000,
    allowedExtensions: '.jpeg,.jpg,.gif,.png',
    method: 'octet',
    uploadMethod: 'PUT',
};

interface IImageUploadResult extends IFileUploadResult {
    width: number;
    height: number;
}

class ImageUpload {
    private fileUpload: FileUpload<IImageUploadResult> = null;

    constructor(private storage: string, private options: any = defaultOptions) {}

    public upload(params?: IFileUploadParams): Promise<IImageUploadResult> {
        return this.getFileUpload().then(uploader => uploader.upload(params));
    }

    private getFileUpload(): Promise<FileUpload<IImageUploadResult>> {
        if (this.fileUpload === null) {
            return config.getAsync<string>('imageServer.upload.url').then(serverUrl => {
                this.fileUpload = new FileUpload(serverUrl, this.storage, this.options);
                return this.fileUpload;
            });
        }

        return Promise.resolve(this.fileUpload);
    }
}

export { ImageUpload, IImageUploadResult };
export default ImageUpload;
