import { Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

class LockHandle {
    private readonly _onReleasedHandler: () => void;
    private _isReleased = false;

    constructor(onReleasedHandler: () => void) {
        this._onReleasedHandler = onReleasedHandler;
    }

    release() {
        if (this._isReleased) {
            return;
        }
        this._isReleased = true;
        this._onReleasedHandler();
    }
}

class Lock {
    get onUnlocked() {
        return this._subject.pipe(
            filter(({ locksCount }) => locksCount === 0),
            map(() => this),
        );
    }

    get onLocked() {
        return this._subject.pipe(
            filter(({ locksCount, delta }) => locksCount === 1 && delta === 1),
            map(() => this),
        );
    }

    get isLocked() {
        return this._locksCount !== 0;
    }

    private _locksCount = 0;
    private readonly _subject = new Subject<{ locksCount: number; delta: 1 | -1 }>();

    lock() {
        this._subject.next({ locksCount: ++this._locksCount, delta: 1 });
        return new LockHandle(() => {
            this._subject.next({ locksCount: --this._locksCount, delta: -1 });
        });
    }

    /**
     * Calls `callback` as soon as the lock is unlocked. If the lock isn't locked, `callback` will be called immediately.
     */
    onUnlockedOnce(callback: () => void) {
        if (this.isLocked) {
            this.onUnlocked.pipe(take(1)).subscribe(callback);
        } else {
            callback();
        }
    }
}

class SingleEntryLock {
    private readonly _lock = new Lock();

    lock() {
        if (!this._lock.isLocked) {
            return Promise.resolve(this._lock.lock());
        }
        return new Promise<LockHandle>(resolve => {
            const subscription = this._lock.onUnlocked.subscribe(() => {
                if (this._lock.isLocked) {
                    // One of other subscribers locked again.
                    return;
                }
                subscription.unsubscribe();
                resolve(this._lock.lock());
            });
        });
    }
}

export { Lock, LockHandle, SingleEntryLock };
