import * as ko from 'knockout';
import resources from 'nd.res';
import SectionBaseViewModel from 'account/viewmodels/section.base.viewmodel';
import { setEmail, setPassword } from 'account/services/account.service';
import { alertError } from '@pressreader/src/shared/errors/error.handlers';
import { validate, resetValidationState } from '@pressreader/src/ko/nd/validate';

const msMinute = 1000 * 60;
const msHour = 60 * msMinute;
const msDay = 24 * msHour;
const msMonth = 30 * msDay;
const msYear = 365 * msDay;

function spanToDate(date) {
    const spanInMilliseconds = new Date().valueOf() - date.valueOf();
    return {
        totalYears: Math.floor(spanInMilliseconds / msYear),
        totalMonths: Math.floor(spanInMilliseconds / msMonth),
        totalDays: Math.floor(spanInMilliseconds / msDay),
        totalHours: Math.floor(spanInMilliseconds / msHour),
        totalMinutes: Math.floor(spanInMilliseconds / msMinute),
    };
}

class PasswordSectionViewModel extends SectionBaseViewModel {
    constructor({ profile, expandEventStream }) {
        super({ master: profile, properties: ['email', 'lastPasswordChangeDate', 'passwordEditable', 'externalAuthentication'], expandEventStream });
        this._subscriptions = [];

        const minPasswordLength = parseInt(resources.val('v7.Client.Signin.Validators.Password.Minlength'), 10) || 6;
        const minPasswordLengthMessage = resources.format('v7.Client.Signin.Validators.Password.Minlength.Message', minPasswordLength);

        this.isEmailRequired = ko.pureComputed(() => this.master.email() === null);
        this.email = ko.observable(this.master.email()).extend({
            customValidator: {
                message: resources.val('Dialogs.Signin.EmailRequired', 'Valid email address is required'),
                handler: value => !this.isEmailRequired() || value,
            },
            email: resources.val('Dialogs.Signin.EmailRequired', 'Valid email address is required'),
            trackDirtyState: true,
        });

        // force email re-validation upon validation conditions changed
        this._subscriptions.push(
            this.isEmailRequired.subscribe(() => {
                this.email.valueHasMutated();
                this.email.isDirty(false);
            }),
        );

        this.isCurrentPasswordRequired = ko.pureComputed(() => this.master.lastPasswordChangeDate() !== null);
        this.currentPassword = ko.observable('').extend({
            customValidator: {
                message: resources.val('Accounting.Validators.CurrentPasswordRequired.ErrorMessage', 'Password required'),
                handler: value => !this.isCurrentPasswordRequired() || value,
            },
            trackDirtyState: true,
        });

        // force current passowrd re-validation upon validation conditions changed
        this._subscriptions.push(
            this.isCurrentPasswordRequired.subscribe(() => {
                this.currentPassword.valueHasMutated();
                this.currentPassword.isDirty(false);
            }),
        );

        this.newPassword = ko.observable('').extend({
            reqiured: true,
            minLength: {
                val: minPasswordLength,
                message: minPasswordLengthMessage,
            },
            trackDirtyState: true,
        });

        this.confirmNewPassword = ko.observable('').extend({
            minLength: {
                val: minPasswordLength,
                message: minPasswordLengthMessage,
            },
            customValidator: {
                message: resources.val('Accounting.Validators.ConfirmNewPasswordCompare.ErrorMessage', "Passwords don't match"),
                handler: value => !this.newPassword() || value === this.newPassword(),
            },
            trackDirtyState: true,
        });

        // force current passowrd re-validation upon validation conditions changed
        this._subscriptions.push(
            this.newPassword.subscribe(() => {
                this.confirmNewPassword.valueHasMutated();
                if (this.confirmNewPassword() === '') {
                    this.confirmNewPassword.isDirty(false);
                }
            }),
        );

        this.displayName = ko.pureComputed(() => {
            if (this.master.externalAuthentication()) {
                return resources.val('Accounting.UserProfile.ExternalPasswordIsSet');
            }
            const lastPasswordChangeDate = this.lastPasswordChangeDate();
            if (lastPasswordChangeDate) {
                const span = spanToDate(lastPasswordChangeDate);
                if (span.totalYears > 0) {
                    return resources.val('Accounting.PasswordChangePeriod.MoreThanYearAgo');
                }
                if (span.totalMonths > 0) {
                    return resources.format('Accounting.PasswordChangePeriod.MonthsAgoFormat', span.totalMonths);
                }
                if (span.totalDays > 0) {
                    return resources.format('Accounting.PasswordChangePeriod.DaysAgoFormat', span.totalDays);
                }
                if (span.totalHours > 0) {
                    return resources.format('Accounting.PasswordChangePeriod.HoursAgoFormat', span.totalHours);
                }
                if (span.totalMinutes > 0) {
                    return resources.format('Accounting.PasswordChangePeriod.MinutesAgoFormat', span.totalMinutes);
                }

                return resources.val('Accounting.PasswordChangePeriod.JustChanged');
            }

            return resources.val('Accounting.PasswordChangePeriod.NotYetChanged');
        });

        this.actionTitle = ko.observable(resources.val('Buttons.Edit'));
    }

    restore() {
        super.restore();
        this.currentPassword('');
        this.newPassword('');
        this.confirmNewPassword('');
        resetValidationState(this);
    }

    _save() {
        if (!validate(this)) {
            return Promise.reject();
        }

        let saveEmail = Promise.resolve();
        if (this.isEmailRequired() === true) {
            if (!this.email()) {
                return Promise.reject('Email required');
            }
            saveEmail = setEmail(this.email());
        }

        return saveEmail
            .then(() =>
                setPassword({ newPassword: this.newPassword(), currentPassword: this.currentPassword() }).then(() => {
                    this.currentPassword('');
                    this.newPassword('');
                    this.confirmNewPassword('');
                    resetValidationState(this);
                    // reload profile
                    return this.master.load();
                }),
            )
            .catch(alertError);
    }

    dispose() {
        this._subscriptions.forEach(s => s.dispose());
        this._subscriptions.length = 0;
    }
}

export default PasswordSectionViewModel;
