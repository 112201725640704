import isString from 'lodash/isString';
import endsWith from 'lodash/endsWith';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import mapValues from 'lodash/mapValues';
import clone from 'lodash/clone';
import find from 'lodash/find';

import { createConstraint } from './route.constraint';
import { routeParameterFactory as createRouteParameter } from './route.parameter';
import {
    parseQuery,
    normalizeSeparation,
    combineRouteUrl,
    extractValue,
    splitByLast,
    clonePlain,
    PATH_SEPARATOR,
    QUERY_SEPARATOR,
    QUERY_PAIR_SEPARATOR,
    decodeQueryParameter,
    updatePathPartsByLangCode,
    echo,
} from './routing.utils';

type RouteParam = ReturnType<typeof createRouteParameter>;
const splitSafe = (str: string, separator: string) => normalizeSeparation(str, separator).split(separator);

/**
 * Transform given template string to a collection of {@link RouteParam} route parameters.
 * @param template Route template to parse.
 * @param defaults A map with default values for route parameters.
 * @return An array of parsed route parameters.
 */
const parsePathTemplate = (template: string, defaults: Record<string, unknown>) => {
    const params: RouteParam[] = [];

    // Edge case: empty template or the one equals to separator (e g. '/')
    // should be treated similarly (as empty route param collection);
    if (isEmpty(template) || template === PATH_SEPARATOR) {
        return params;
    }

    const parts = splitSafe(template, PATH_SEPARATOR);
    parts.pop(); // The last member is always an empty string since template is normaized before being split
    parts.reduce(
        ({ parsed, hasOptional }, part) => {
            const param = createRouteParameter(part);

            param.defaultValue = extractValue(defaults, param.name);
            if (hasOptional && !param.optional) {
                throw new Error('Optional parameter cannot be followed by required parameter');
            }

            hasOptional = hasOptional || param.optional;
            parsed.push(param);
            return { parsed, hasOptional };
        },
        { parsed: params, hasOptional: false },
    );
    return params;
};

/**
 * Transform given template string to a collection of {@link RouteParam} route query parameters.
 * @param  template Route template to parse.
 * @param  defaults A map with default values for route parameters.
 * @return An array of parsed route parameters.
 */
const parseQueryTemplate = (template: string, defaults: Record<string, unknown>): RouteParam[] => {
    if (endsWith(template, QUERY_PAIR_SEPARATOR)) {
        throw new Error(`Route template '${template}' is malformed: invalid query`);
    }

    if (isEmpty(template)) {
        return [];
    }

    const parts = template.split(QUERY_PAIR_SEPARATOR);
    return parts.map(part => {
        const param = createRouteParameter(part);
        if (param.fragmentary) {
            throw new Error(`Suffixes or prefixes are not allowed in query string parameters. Parsing ${part}`);
        }

        param.defaultValue = extractValue(defaults, param.name);
        return param;
    });
};

/**
 * Produce a RegExp from a collection of {@link RouteParam} route parameters.
 * @param pathParams Route parameters to build a Regexp from.
 * @return Route regular expression.
 */
const buildRoutePattern = (pathParams: RouteParam[] = []): RegExp => {
    let paramPatterns = pathParams.map(p => `(?:${p.pattern}/)${p.optional ? '?' : ''}`).join('');
    if (isEmpty(paramPatterns)) {
        // Edge case: if there are no params, route '' and '/' must match.
        paramPatterns = '/?';
    }

    return new RegExp(`^${paramPatterns}$`, 'i');
};

/**
 * Synchronously check if given url matches the given regular expression.
 * Takes care of leading/trailing slashes.
 * Used for fast route filtering (to avoid potentially costly costraint validations).
 * @param url Url string to check the expression against.
 * @param patternExp Regular expression to test on.
 * @return true if given url matches given pattern, false otherwise.
 */
const matchByPattern = (url: string, patternExp: RegExp): boolean => {
    if (!isString(url)) {
        return false;
    }

    const [path] = splitByLast(url, QUERY_SEPARATOR);
    return patternExp.test(normalizeSeparation(path, PATH_SEPARATOR));
};

export type ExecCallbackFn = (val: Record<string, unknown>) => void | Record<string, unknown> | Promise<Record<string, unknown>> | Promise<void>;

/**
 * Class representing a client-side route.
 */
export class Route {
    private otherDefaults: Record<string, unknown>;
    private params: RouteParam[];
    private query: RouteParam[];
    private allowLooseQuery: boolean;
    private patternExp: RegExp;
    private constraints: Record<string, ReturnType<typeof createConstraint>>;
    private execCallback: ExecCallbackFn;
    name: string;
    // TODO: property indicating loose query might be put into route template as an asterisk, consider which way is more readable
    /**
     * Create a Route.
     * Route accepts constrains which can be strings, functions (sync or async)
     * or instances of RouteConstraint {@see RouteConstraint}.
     * If given route template specifies any query params,
     * the route will check their existence while resolving context and generating urls.
     * If no query params specified the route assumes that query might contain arbitrary parameters.
     * @param  [name] Route name (optional).
     * @param  template Route template.
     * @param  [paramDefaults] Route parameters default values.
     * @param  [constraints] Route constrains.
     * @param  [executeWith] Function called upon successful context resolving.
     * @param  [looseQuery] True if route might have arbitrary query string. False by default
     */
    constructor(
        name: string,
        template: string,
        paramDefaults: Record<string, unknown> = {},
        constraints: Record<string, Parameters<typeof createConstraint>[0]> = {},
        executeWith: ExecCallbackFn = echo,
        looseQuery = false,
    ) {
        if (!isString(template)) {
            throw new Error('Route template must be a string');
        }

        const [pathStr, queryStr] = splitByLast(template, QUERY_SEPARATOR);

        // Route might have default values for parameters not specified in template.
        // They are preserved and used for extending data object during url parsing.
        this.otherDefaults = clonePlain(paramDefaults, true);
        this.params = parsePathTemplate(pathStr, this.otherDefaults);
        this.query = parseQueryTemplate(queryStr, this.otherDefaults);
        this.allowLooseQuery = !!looseQuery;

        // Check for collisions in template path and query
        if (this.params.some(p => find(this.query, queryParam => queryParam.name === p.name))) {
            // TODO: specify which param
            throw new Error(`Route template '${template}' is malformed: param names collision`);
        }

        // TODO: optimize route pattern: if has query params add checking on existence;
        this.patternExp = buildRoutePattern(this.params);
        this.constraints = mapValues(constraints, createConstraint);
        this.execCallback = executeWith;
        this.name = name;
    }

    get hasQuery() {
        return !isEmpty(this.query);
    }

    /**
     * Asynchronously looks for a route matching given URL to the route pattern and constraints.
     * If found, extracts values of route parameters,
     * including default values configured in the route
     * and values evaluated within resolution procedure,
     * and returns it.
     * @param url An Url to resolve
     * @param referrer A referrer url
     * @return Promise fulfilled with resolved route parameters.
     */
    async resolveContext(url: string, referrer?: string): Promise<{ route: Route; routeData: Record<string, unknown> }> {
        if (!isString(url)) {
            return Promise.reject('Invalid url');
        }

        const [path, queryStr] = splitByLast(url, QUERY_SEPARATOR);

        // Fast checking against url pattern
        if (!matchByPattern(url, this.patternExp)) {
            return Promise.reject(`Url ${url} does not match route pattern ${this.patternExp}`);
        }

        const routeData = clone(this.otherDefaults);

        if (referrer) {
            routeData['referrer'] = referrer;
        }

        const parsedQuery = reduce(
            parseQuery(queryStr),
            (reduced: Record<string, string>, value, name) => {
                reduced[decodeQueryParameter(name)] = decodeQueryParameter(value);
                return reduced;
            },
            {},
        );

        // Extend route data with default values from query params,
        // and check if no required query params are missing.
        for (let qi = 0; qi < this.query.length; qi++) {
            const { name: qName, optional: qOptional, defaultValue: qDefault } = this.query[qi];
            let qParamValue = extractValue(parsedQuery, qName);
            if (qParamValue === undefined) {
                if (!qOptional) {
                    return Promise.reject(`Required query parameter '${qName}' is missing`);
                }

                qParamValue = qDefault;
            }

            routeData[qName] = qParamValue;
        }

        if (this.allowLooseQuery) {
            Object.assign(routeData, parsedQuery);
        }

        const matched = this.patternExp.exec(normalizeSeparation(path, PATH_SEPARATOR)) ?? [];
        matched.shift(); // First element is always the initial string
        this.params.reduce((ctx, param, i) => {
            const value = matched[i] !== undefined ? decodeURIComponent(matched[i]) : param.defaultValue;
            ctx[param.name] = value;
            return ctx;
        }, routeData);

        await this._checkConstrains(routeData);

        return { routeData, route: this };
    }

    /**
     * Create a url from given data.
     * @param  data Input data.
     * @return Promise fulfilled with generated url and parsed parameters { parameters, url }.
     */
    async generateUrl(data: Record<string, unknown> = {}): Promise<{ parameters: Record<string, unknown>; url: string }> {
        // Do not touch initial object and cast all values to strings explicitly,
        // it must be done because any of them might be put into url.
        const values = clonePlain(data);
        await this._checkConstrains(values);
        // Dealing with jQuery promises not being throw-safe.
        try {
            const { path, parameters } = this.params.reduceRight<{ path: string[]; parameters: Record<string, unknown>; omit: boolean }>(
                ({ path, parameters, omit }, p) => {
                    // eslint-disable-line no-shadow
                    const givenValue = extractValue<string | undefined>(values, p.name);
                    const paramValue = p.build(givenValue);

                    // If a parameter is optional and given value equals to its default value
                    // and all params to the right meet the same requirements, it can be omitted
                    omit = omit && p.optional && (givenValue === undefined || p.defaultValue === givenValue);
                    if (!omit) {
                        path.unshift(paramValue);
                    }

                    if (p.interpolated) {
                        parameters[p.name] = paramValue;
                    }

                    return { path, parameters, omit };
                },
                { path: [], parameters: {}, omit: true },
            );

            const { queryPairs } = this.query.reduce<{ parameters: Record<string, unknown>; queryPairs: [string, string][] }>(
                ({ parameters, queryPairs }, q) => {
                    // eslint-disable-line no-shadow
                    const givenValue = extractValue<string | undefined>(values, q.name);
                    const paramValue = q.build(givenValue);

                    parameters[q.name] = paramValue;

                    // Add to url query string if only given value is different than the parameter default value
                    if (givenValue !== undefined && q.defaultValue !== givenValue) {
                        queryPairs.push([q.name, paramValue]);
                    }

                    return { parameters, queryPairs };
                },
                { parameters, queryPairs: [] },
            );

            if (this.allowLooseQuery) {
                reduce(
                    values,
                    ({ parameters, queryPairs }, val, name) => {
                        // eslint-disable-line no-shadow
                        parameters[name] = val;
                        queryPairs.push([name, val]);
                        return { parameters, queryPairs };
                    },
                    { parameters, queryPairs },
                );
            }
            const combinedUrl = combineRouteUrl(updatePathPartsByLangCode(path), queryPairs);
            return { parameters, url: combinedUrl };
        } catch (err) {
            return Promise.reject(err);
        }
    }

    execute(data: Record<string, unknown>) {
        return Promise.resolve(this.execCallback(data));
    }

    /**
     * Check route data against a set of {@see RouteConstraint} constraints.
     * @param  routeData Route data to check.
     * @return Promise fulfilled if given data comply with route constrains,
     *                   rejected if any constraint fails.
     */
    _checkConstrains(routeData: Record<string, unknown>): Promise<unknown> {
        // TODO: introduce "fastConstraints" which should be checked prior to "regular" ones
        //       "viewName" constraint used to be considered "more important" and was checked first.
        //       Check if it gives any performance improvement.

        const waitMatch = map(this.constraints, (c, name) => c.matchAsync(name, routeData));
        return Promise.all(waitMatch);
    }
}
