import { put, select, takeEvery } from 'redux-saga/effects';

import { getBasePath, getCurrentPath, withAbsolutePath } from '@pressreader/routing';
import { cookies } from '@pressreader/utils';

import {
    COOKIE_FACELIFT_ENABLED_EXPIRES_IN_DAYS,
    COOKIE_FACELIFT_ENABLED_KEY,
    COOKIE_TARGETPLATFORM_EXPIRES_IN_DAYS,
    COOKIE_TARGETPLATFORM_KEY,
} from '../constants';

import { appStarted, changeTargetPlatformAction, startAppAction, toggleFaceliftUiAction } from './actions';
import { selectDefaultTargetPlatform, selectFaceliftEnabled, selectSupportedPlatforms, selectTargetPlatform } from './selectors';

function* startApp() {
    yield put(appStarted());
}

function* changeTargetPlatform(action: ReturnType<typeof changeTargetPlatformAction>) {
    const { newTargetPlatform, keepLocation } = action.payload;

    const defaultTargetPlatform: ReturnType<typeof selectTargetPlatform> = yield select(selectDefaultTargetPlatform);
    const supportedPlatforms: ReturnType<typeof selectSupportedPlatforms> = yield select(selectSupportedPlatforms);

    if (supportedPlatforms.indexOf(newTargetPlatform) < 0) {
        return;
    }

    if (newTargetPlatform === defaultTargetPlatform) {
        cookies.set(COOKIE_TARGETPLATFORM_KEY, '', {
            path: getBasePath(),
            expiresInDays: -1,
        });
    } else {
        cookies.set(COOKIE_TARGETPLATFORM_KEY, newTargetPlatform, {
            path: getBasePath(),
            expiresInDays: COOKIE_TARGETPLATFORM_EXPIRES_IN_DAYS,
        });
    }

    if (keepLocation) {
        window.location.assign(getCurrentPath());
    } else {
        // reload the page
        window.location.assign(withAbsolutePath(''));
    }
}

function* toggleFaceliftUi(action: ReturnType<typeof toggleFaceliftUiAction>) {
    const { enable } = action.payload;

    const enabled: ReturnType<typeof selectFaceliftEnabled> = yield select(selectFaceliftEnabled);

    if (enabled === enable) {
        return;
    }

    if (!enable) {
        cookies.set(COOKIE_FACELIFT_ENABLED_KEY, '', {
            path: getBasePath(),
            expiresInDays: -1,
        });
    } else {
        cookies.set(COOKIE_FACELIFT_ENABLED_KEY, 'true', {
            path: getBasePath(),
            expiresInDays: COOKIE_FACELIFT_ENABLED_EXPIRES_IN_DAYS,
        });
    }

    // reload the page
    window.location.assign(withAbsolutePath(''));
}

function* saga() {
    yield takeEvery(startAppAction, startApp);
    yield takeEvery(changeTargetPlatformAction, changeTargetPlatform);
    yield takeEvery(toggleFaceliftUiAction, toggleFaceliftUi);
}
export { saga };
