// eslint-env es6

import * as ko from 'knockout';
import * as $ from 'jquery';
import actions from 'nd.actions';
import { Modernizr } from '@pressreader/modernizr';

/**
 * Finds all links with attribute "target" = "_blank" inside bound element
 * and overrides default navigation behaviour -
 * adds onclick handler, which calls openWindow action with url from "href" attribute.
 *
 * This is to make external links working in native apps.
 * WebView of native apps blocks such links (let's name them 'external'),
 * so we have to employ native call to open external links in native context.
 *
 * @example:
 * <h2 data-bind="externalLinks: true">
 *     Please confirm that you agree to the
 *     <a href="http://care.pressreader.com/hc/articles/206528495-Terms-of-Use" target="_blank">Terms of Use</a>
 *     and the <a href="http://care.pressreader.com/hc/articles/205818089-Privacy-Policy" target="_blank">Privacy Policy</a>
 * </h2>
 **/
const externalLinksBindingHandler = {
    init: element => {
        if (!actions.openWindow) {
            throw new Error('"externalLinks" binding not supported, because the "openWindow" action is not registered');
        }

        // eslint-disable-next-line no-undef
        const listenedEvents = Modernizr.touch ? 'singleTapConfirmed singleTap' : 'click';

        $(element)
            .find("a[target='_blank']")
            .on(listenedEvents, event => {
                const url = $(event.currentTarget).attr('href');
                actions.openWindow.activate({ url });
                event.preventDefault();
                return false;
            });
    },
};

// binding auto registration
ko.bindingHandlers.externalLinks = externalLinksBindingHandler;
