import { IEvent } from '@pressreader/src/eventbus/events';

enum EventTypes {
    ContextMenu = '[ContextMenu] ItemSelected',
    ContextMenuCompleted = '[ContextMenu] ItemActivatedCompleted',
    ContextMenuFailed = '[ContextMenu] ItemActivatedFailed',
    IssueContextMenu = '[ContextMenu] IssueContextMenu',
}

class ContextMenuEvent implements IEvent {
    type: string = EventTypes.ContextMenu;

    constructor(public payload: { action: string }) {}
}

class ContextMenuEventCompleted extends ContextMenuEvent {
    type = EventTypes.ContextMenuCompleted;
}
class ContextMenuEventFailed extends ContextMenuEvent {
    readonly type = EventTypes.ContextMenuFailed;
}
class IssueContextMenuEvent implements IEvent {
    readonly type = EventTypes.IssueContextMenu;

    constructor(public payload: { action: string }) {}
}

export { EventTypes, ContextMenuEvent, ContextMenuEventCompleted, ContextMenuEventFailed, IssueContextMenuEvent };
