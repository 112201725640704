import * as ko from 'knockout';
import ndAlert from '@pressreader/src/nd.alert';
import ndRes from 'nd.res';
import { httpPost } from '@pressreader/services';
import { fixToLocalURL, getBasePath } from '@pressreader/routing';

const consentStepType = {
    confirm: 'confirm',
    done: 'done',
};

class ConsentViewModel {
    constructor(config) {
        this.cookiesConsentGranted = ko.observable(config.cookiesConsentGranted);
        this.promoEmailConsentGranted = ko.observable(config.promoEmailConsentGranted);
        this.termsOfUseConsentGranted = ko.observable(config.termsOfUseConsentGranted);
        this.isSubmitting = ko.observable(false);
        this.step = ko.observable(consentStepType.confirm);
        this.canSubmit = ko.computed(() => this.cookiesConsentGranted() && this.termsOfUseConsentGranted() && !this.isSubmitting());
        this.returnUrl = fixToLocalURL(config.returnUrl) || getBasePath();
    }

    submit(callback) {
        if (!this.canSubmit()) {
            return;
        }

        this.isSubmitting(true);
        this.updateEmailConsent()
            .then(() => {
                if (typeof callback === 'function') {
                    callback();
                } else {
                    this.step(consentStepType.done);
                }
            })
            .catch(() => {
                ndAlert().alert(ndRes.val('Accounting.EmailConsent.SubmitErrorMessage'));
            })
            .finally(() => this.isSubmitting(false));
    }

    done() {
        window.location.assign(this.returnUrl);
    }

    updateEmailConsent() {
        return httpPost({
            data: {
                cookiesConsentGranted: this.cookiesConsentGranted(),
                promoEmailConsentGranted: this.promoEmailConsentGranted(),
                termsOfUseConsentGranted: this.termsOfUseConsentGranted(),
            },
            url: 'UpdateConsent',
            requestConfig: {
                contentType: 'application/json',
            },
        });
    }
}

export default ConsentViewModel;
