import * as $ from 'jquery';
import general from 'nd.views.general';
import 'jquery-validation';
import { fixToLocalURL, getBasePath } from '@pressreader/routing';

const passwordView = (function () {
    function redirectTo(url) {
        const returnUrl = fixToLocalURL(url);
        window.location.assign(returnUrl || getBasePath());
    }

    const passwordFormSel = '#formPassword',
        tokenSel = "[name='token']",
        newPasswordSel = "[name='newPassword']";
    let passwordForm, tokenInput, newPasswordInput, validator;

    async function upload(url) {
        if (!passwordForm.valid()) {
            return false;
        }

        const data = {
            token: tokenInput.val(),
            newPassword: newPasswordInput.val(),
        };

        const response = await fetch(`${getBasePath()}account/resetpassword`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            redirectTo(getBasePath());
        } else {
            const txt = await response.text();
            alert(txt);
        }

        return true;
    }

    return {
        init: function () {
            passwordForm = $(passwordFormSel);
            tokenInput = $($(tokenSel)[0]);
            newPasswordInput = $($(newPasswordSel)[0]);

            const validationSettings = $.extend(
                {
                    rules: {
                        newPassword: {
                            required: true,
                            minlength: 6,
                        },
                        confirmNewPassword: {
                            required: true,
                            equalTo: newPasswordSel,
                        },
                    },
                    ignore: '', // validate hidden fields
                },
                general.jqValidation(),
            );

            validator = passwordForm.validate(validationSettings);

            $('[data-fields-cancel-url]').click(function () {
                const url = $(this).attr('data-fields-cancel-url');
                validator.resetForm();
                redirectTo(url);
                return false;
            });

            $('[data-fields-reset-password-url]').click(function () {
                var $this = $(this),
                    url = $this.attr('data-fields-reset-password-url');

                upload(url);
                return false;
            });

            newPasswordInput.focus();
        },
    };
})();

export default passwordView;
