import Uri from 'jsuri';

import { ISize } from '@pressreader/geometry';

import { forOwn } from './for.own';

type ImageEncoding = 'jpeg' | 'webp' | 'png' | 'gif';

type ImageAlign = 'topLeft' | 'center' | 'bottomRight';

type ImageFitMode = 'max' | 'pad' | 'crop' | 'stretch';

interface IImageTransformationParams extends Partial<ISize> {
    scale?: number;
    align?: ImageAlign;
    fitMode?: ImageFitMode;
    encoding?: ImageEncoding;
    quality?: number;
}

function buildImageUrl(serverBaseUrl: string, imageId: string, params?: IImageTransformationParams): string {
    const uri = new Uri(serverBaseUrl);
    uri.setPath(`${uri.path()}${imageId}`);
    if (params) {
        forOwn(params, (v, k) => {
            if (v !== null && v !== undefined) {
                uri.addQueryParam(k, v);
            }
        });
    }
    return uri.toString();
}

export { IImageTransformationParams, ImageFitMode, buildImageUrl };
