import { LocalizableError } from './localizable.error';

interface IResponseJSON {
    message: string;
    resourceKey: string;
}

interface IError {
    status: number;
    responseText: string;
    responseJSON: IResponseJSON;
}

/**
 * The HTTP error object.
 * This class is originally derived from Error-object and because of this has several constraints, look at the base class description for details.
 */
export class HttpError extends LocalizableError {
    status: number;
    responseText: string;
    responseJSON: IResponseJSON;

    /**
     * @constructor
     * @param {number} status - HTTP response status code.
     * @param {string} responseText - The origin HTTP response content.
     * @param {Object} [responseJSON=null] - The object represents the parsed HTTP-response content (response is interpreted as JSON string).
     * @param {string} [responseJSON.message]
     * @param {string} [responseJSON.resourceKey]
     * @param {string} [responseJSON.StatusText]
     * @param {Object} [responseJSON.Parameters]
     * @param {string} [responseJSON.Parameters.articleId]
     * @param {string} [responseJSON.Parameters.issueId]
     * @param {string} [responseJSON.Parameters.message]
     * @param {string} [responseJSON.Parameters.Text]
     */
    constructor({ status, responseText, responseJSON }: IError) {
        const message = responseJSON?.message || responseText || '';
        const resourceKey = responseJSON?.resourceKey;

        super(message, resourceKey);

        this.status = status;
        this.responseText = responseText;
        this.responseJSON = responseJSON;
    }
}
