import { IEvent } from 'eventbus/events';

enum EventTypes {
    ActiveViewChanged = '[Viewer] ActiveViewChanged',
    IssueViewChanged = '[Viewer] IssueViewChanged',
    CommonViewChanged = '[Viewer] CommonViewChanged',
}

class ActiveViewChangedEvent implements IEvent {
    readonly type = EventTypes.ActiveViewChanged;

    constructor(public payload: { viewName: string }) {}
}

class IssueViewChangedEvent implements IEvent {
    readonly type = EventTypes.IssueViewChanged;

    constructor(public payload: { viewName: string; cid: string; issueDate: string }) {}
}

class CommonViewChangedEvent implements IEvent {
    readonly type = EventTypes.CommonViewChanged;

    constructor(public payload: { viewName: string }) {}
}

export { EventTypes, ActiveViewChangedEvent, IssueViewChangedEvent, CommonViewChangedEvent };
