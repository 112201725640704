/* eslint-env es6 */

import * as ko from 'knockout';
import ClickEventStream from 'nd.eventstream.click';

ko.bindingHandlers.click = {
    init(elem, valueAccessor, allBindings, viewModel, bindingContext) {
        // we override standard ko click binding here as we faced numerous issues related to using 'click' in
        // touch-friendly environments with nd.gestures + touchmove prevention. See nd.eventstream.click for more details.
        const subscription = ClickEventStream.create(elem).subscribe(event => {
            const data = bindingContext.$data;
            const handlerReturnValue = valueAccessor().call(viewModel, data, event);
            if (handlerReturnValue !== true) {
                // Normally we want to prevent default action.
                // Developer can override this be explicitly returning true.
                if (event.preventDefault) {
                    event.preventDefault();
                } else {
                    event.returnValue = false;
                }

                // support knockout-style event bubbling prevention
                const bubble = allBindings.get('clickBubble') !== false;
                if (!bubble) {
                    event.cancelBubble = true;
                    if (event.stopPropagation) {
                        event.stopPropagation();
                    }
                }
            }
        });

        ko.utils.domNodeDisposal.addDisposeCallback(elem, () => {
            subscription.unsubscribe();
        });
    },
};
