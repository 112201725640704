import { IEvent } from '@pressreader/src/eventbus/events';

import { IEcommerceData } from 'analytics/ecommerce/models/models';

enum EventTypes {
    AddPaymentInfo = '[Ecommerce] Add Payment Info',
    AddToCart = '[Ecommerce] Add to Cart',
    BeginCheckout = '[Ecommerce] Begin Checkout',
    CheckoutProgress = '[Ecommerce] Checkout Progress',
    PromoClick = '[Ecommerce] Select Content / Promotion Click',
    Purchase = '[Ecommerce] Purchase',
    RemoveFromCart = '[Ecommerce] Remove from Cart',
    SetCheckoutOption = '[Ecommerce] Set Checkout Option',
    PurchaseCompleted = '[Purchase] PurchaseCompleted',
}

abstract class BaseEvent<T extends IEcommerceData = IEcommerceData> implements IEvent<T> {
    abstract type: string;
    constructor(public payload: T) {}
}

class AddPaymentInfoEvent extends BaseEvent<IEcommerceData> {
    readonly type = EventTypes.AddPaymentInfo;
}

class AddToCartEvent extends BaseEvent<IEcommerceData> {
    readonly type = EventTypes.AddToCart;
}

class BeginCheckoutEvent extends BaseEvent<IEcommerceData> {
    readonly type = EventTypes.BeginCheckout;
}

class CheckoutProgressEvent extends BaseEvent<IEcommerceData> {
    readonly type = EventTypes.CheckoutProgress;
}

class PromoClickEvent extends BaseEvent<IEcommerceData> {
    readonly type = EventTypes.PromoClick;
}

class PurchaseEvent extends BaseEvent<IEcommerceData> {
    readonly type = EventTypes.Purchase;
}

class RemoveFromCartEvent extends BaseEvent<IEcommerceData> {
    readonly type = EventTypes.RemoveFromCart;
}

class SetCheckoutOptionEvent extends BaseEvent<IEcommerceData> {
    readonly type = EventTypes.SetCheckoutOption;
}

class PurchaseCompletedEvent implements IEvent {
    readonly type = EventTypes.PurchaseCompleted;

    constructor(public payload: { transaction?: { Id; Revenue; Currency; Items?: Array<{ Id; Name; Price }> }; promoCode: string }) {}
}

export {
    EventTypes,
    BaseEvent,
    AddPaymentInfoEvent,
    AddToCartEvent,
    BeginCheckoutEvent,
    CheckoutProgressEvent,
    PromoClickEvent,
    PurchaseEvent,
    RemoveFromCartEvent,
    SetCheckoutOptionEvent,
    PurchaseCompletedEvent,
};
