import 'shim/globalshim';
import 'shim/serverpages.mappings';

import 'jquery';
import 'knockout';
import 'jquery-validation';
import '@pressreader/modernizr';
import 'nd.core';
import { svcAuth, baseUrl } from '@pressreader/services';
import 'nd.printing.core';
import '@pressreader/src/deprecated/nd.config';
import '@pressreader/src/nd.baseloader';
import 'nd.res';
import 'nd.res.provider.pd';
import 'nd.views.general';
import accountView from 'nd.views.account';
import 'nd.views.receipt';
import '@pressreader/src/nd.views.notifications';
import 'nd.views.emailsUnsubscribe';
import 'deprecated/nd.usersettings';
import '@pressreader/src/nd.layout';
import '@pressreader/src/nd.alert';
import '@pressreader/src/nd.widgets';
import '@pressreader/src/nd.ko';
import 'ko/bindings/all';
import '@pressreader/src/ko/components/spinner';
import '@pressreader/src/ko/components/dropdown';
import 'nd.knockout.validators';
import '@pressreader/src/nd.knockout.extenders';
import '@pressreader/src/nd.knockout.components';
import 'contentservice/handlers/init';
import '@pressreader/src/nd.ui.menu';
import '@pressreader/src/nd.ui.menu.items';
import '@pressreader/src/nd.ui.menu.items.controllers';
import '@pressreader/src/nd.ui.menu.renderers';
import '@pressreader/src/nd.ui.menu.controllers';
import 'nd.ui.menu.account';
import 'nd.gestures';
import { init as initAuth } from 'authentication/nd.authentication';
import 'authentication/nd.authentication.provider';
import 'nd.gigya';
import 'nd.janrain';
import '@pressreader/src/iscroll';
import 'nd.user';
import '@pressreader/src/nd.userinfo';
import '@pressreader/src/nd.ui.utils';
import 'analytics/analytics';
import '@pressreader/src/nd.ui.dialog';
import '@pressreader/src/shared/browser/screen.size.watchdog';
import 'gdpr/consent';
import ndResetPasswordView from 'nd.views.resetPassword';
import { accountConsentLoader } from 'gdpr/accountconsentloader';
import { init as initAppCore, startAppAction } from '@pressreader/appcore';
import { dispatch } from '@pressreader/appstore';
import { load as loadScripts } from 'nd.scripts';

baseUrl(window.serviceUrl);
svcAuth.initAuthTickets();

// sets scripts base url to load ondemand scripts
__webpack_public_path__ = window.scriptBaseUrl;

async function init() {
    await initAppCore();

    initAuth();

    loadScripts();

    // start application flow
    dispatch(startAppAction({}));
}

init();

ndResetPasswordView.init();
accountConsentLoader.init();
accountView.init();
