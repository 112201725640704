import { getIssueInfo, getIssueInfoById } from '@pressreader/content-api';
import ndConfig from '@pressreader/src/deprecated/nd.config';
import ndAlert from '@pressreader/src/nd.alert';

import ndAuthentication from 'authentication/nd.authentication';
import ndPurchase from 'purchase/nd.purchase';

interface IResponseDataExpected {
    Parameters: {
        issueId: string;
        message: string;
        articleId?: string;
        subscriptionBehaviour: unknown;
        redirectUrl?: string;
    };
    Status: number;
    StatusText: string;
}

export async function authenticationRequired(data: IResponseDataExpected) {
    const alertPromise = data?.Parameters?.message ? ndAlert().show(data.Parameters.message) : Promise.resolve();

    await Promise.all([alertPromise, ndConfig.loaded()]);

    if (data?.Parameters?.redirectUrl) {
        window.location.href = data.Parameters.redirectUrl;
        return Promise.reject();
    }
    const prePurchaseEnabled = ndConfig.get('features.purchase.prepurchase.enabled', false);
    if (prePurchaseEnabled && data?.Parameters?.issueId) {
        const issueId = data.Parameters.issueId;
        const issueInfo = issueId.length >= 20 ? await getIssueInfo(issueId) : await getIssueInfoById(Number(issueId));
        if (!issueInfo.SubscriptionsInfo.IsFreeContent) {
            await ndPurchase.purchase({ issueId: issueInfo.Issue.Issue, articleId: data?.Parameters?.articleId });
            return;
        }
    }
    return ndAuthentication.signIn().then(() => ({ skipRetry: false }));
}
