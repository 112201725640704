import * as ko from 'knockout';
import validateInfo from 'ko/bindings/validate.shared';
        

        function ValidateBindingHandler() {

            this.init = function(elm, valueAccessor, allBindingAccessor, viewModel, bindingContext) {

                var value = valueAccessor(),
                    innerContext = bindingContext.extend({});

                if (typeof value === "object") {
                    if (!value.__validationAttempted) value.__validationAttempted = ko.observable(false);
                    innerContext[validateInfo.validationAttemptedBindingName] = value.__validationAttempted;
                }
                else if (value) {
                    if (!viewModel.__validationAttempted) viewModel.__validationAttempted = ko.observable(false);
                    innerContext[validateInfo.validationAttemptedBindingName] = viewModel.__validationAttempted;
                }

                ko.applyBindingsToDescendants(innerContext, elm);
                return { controlsDescendantBindings: true };
            }
        }

        ko.bindingHandlers['validate'] = new ValidateBindingHandler();
    