/**
 * Represents an action, that can be executed in the client context.
 */
abstract class Action {
    available(): boolean {
        return true;
    }

    enabled(): boolean {
        return true;
    }

    abstract activate(params: any): void;
}

export default Action;
