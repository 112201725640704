import { stringify } from 'qs';
import isEmpty from 'lodash/isEmpty';

import { FetchError } from './fetch.error';
import { publishNdStateEvent } from './fetch.events';
import { getContentType, isValidData, serializeData } from './fetch.utils';

interface IFetchOptions extends Omit<RequestInit, 'body' | 'headers'> {
    url?: string;
    timeout?: number;
    cancellableKey?: string;
    contentType?: string;
    accept?: string;
    headers?: Record<string, string>;
    data?: unknown;
    query?: Record<string, unknown>;
    withCredentials?: boolean;
}

const abortControllers = new Map<string, AbortController>();

async function fetchShim<TResult>(options: IFetchOptions): Promise<TResult> {
    const { url, timeout, cancellableKey, contentType, accept, data, query = {}, withCredentials = false, ...rest } = options;

    const headersContentType = rest.headers ? rest.headers['Content-Type'] : undefined;
    const defaultContentType = getContentType(data, contentType || headersContentType);
    const headers = {
        ...rest.headers,
        ...(contentType ? { 'Content-Type': defaultContentType } : {}),
        ...(accept ? { Accept: accept } : {}),
    };
    const body = isValidData(data) ? serializeData(data, defaultContentType) : null;
    const controller = new AbortController();
    if (cancellableKey) {
        abortControllers.get(cancellableKey)?.abort();
        abortControllers.set(cancellableKey, controller);
    }
    const init: RequestInit = { ...rest, body, headers, signal: controller.signal };
    const queryString = isEmpty(query) ? null : stringify(query, { arrayFormat: 'brackets' });
    const finalUrl = queryString ? `${url}?${queryString}` : url;

    if (withCredentials) {
        init.credentials = 'include';
    }

    const timeoutPromise = timeout
        ? new Promise<never>((_, reject) =>
              setTimeout(() => {
                  controller.abort();
                  const statusText = 'Request timed out';
                  const error = new FetchError(408, statusText, Promise.resolve({ Message: statusText, ResourceKey: null }));

                  reject(error);
              }, timeout),
          )
        : null;

    const requestPromise = fetch(finalUrl, init).then(response => {
        if (!response.ok) {
            throw new FetchError(response.status, response.statusText, response.json());
        }

        publishNdStateEvent(response.headers.get('ndstate'));

        const contentType = response.headers.get('content-type');

        if (contentType?.includes('application/json')) {
            return response.json();
        } else {
            return response.text();
        }
    });

    try {
        const result = await Promise.race([requestPromise, ...(timeoutPromise ? [timeoutPromise] : [])]);

        if (cancellableKey) {
            abortControllers.delete(cancellableKey);
        }

        return result;
    } catch (e) {
        if (cancellableKey) {
            abortControllers.delete(cancellableKey);
        }

        if (e instanceof FetchError) {
            throw await e.getHttpError();
        } else {
            throw e;
        }
    }
}

export { fetchShim, IFetchOptions };
