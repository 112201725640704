import invertBy from 'lodash/invertBy';

enum ExternalProvider {
    FACEBOOK = 'facebook',
    TWITTER = 'twitter',
    GOOGLE = 'google',
    EVERNOTE = 'evernote',
    INSTAPAPER = 'instapaper',
    ONENOTE = 'onenote',
    SIP = 'library',
    ADFS = 'adfs',
    BELL = 'bell',
    PIANO = 'piano',
    OPENATHENS = 'openathens',
    EMAIL = 'email',
    BARCODE = 'barcode',
    VIDM = 'vidm',
    OPENIDCONNECT = 'openidconnect',
    URLREFERRER = 'urlreferrer',
    SPYDUS = 'spydus',
    RBDIGITAL = 'rbdigital',
    FINNA = 'finna',
    AXIELL = 'axiell',
    CAPITA = 'capita',
    SAML = 'saml',
    LCF = 'lcf',
    SIERRA = 'sierra',
    APPLE = 'apple',
    AUTH0 = 'auth0',
}

const EXTERNAL_SYSTEM = Object.freeze({
    FACEBOOK: 'facebook',
    TWITTER: 'twitter',
    GOOGLE: 'google',
    INSTAPAPER: 'instapaper',
    ONENOTE: 'onenote',
    EVERNOTE: 'evernote',
    LIBRARY: 'library',
    BELL: 'bell',
    PIANO: 'piano',
    RBDIGITAL: 'rbdigital',
    APPLE: 'apple',
    AUTH0: 'auth0',
});

const THIRD_PARTY_PROVIDERS = {
    [ExternalProvider.SIP]: 0,
    [ExternalProvider.RBDIGITAL]: 1,
    [ExternalProvider.ADFS]: 2,
    [ExternalProvider.OPENATHENS]: 3,
    [ExternalProvider.EMAIL]: 4,
    [ExternalProvider.BARCODE]: 5,
    [ExternalProvider.VIDM]: 6,
    [ExternalProvider.OPENIDCONNECT]: 7,
    [ExternalProvider.SPYDUS]: 8,
    [ExternalProvider.FINNA]: 9,
    [ExternalProvider.AXIELL]: 11,
    [ExternalProvider.CAPITA]: 12,
    [ExternalProvider.SAML]: 13,
    [ExternalProvider.LCF]: 14,
    [ExternalProvider.SIERRA]: 15,

    byValue: {
        0: ExternalProvider.SIP,
        2: ExternalProvider.ADFS,
        3: ExternalProvider.OPENATHENS,
        4: ExternalProvider.EMAIL,
        5: ExternalProvider.BARCODE,
        6: ExternalProvider.VIDM,
        7: ExternalProvider.OPENIDCONNECT,
        8: ExternalProvider.SPYDUS,
        9: ExternalProvider.FINNA,
        11: ExternalProvider.AXIELL,
        12: ExternalProvider.CAPITA,
        13: ExternalProvider.SAML,
        14: ExternalProvider.LCF,
        15: ExternalProvider.SIERRA,
    },
};

const EXTERNAL_PROFILE_STATUSES = {
    NONE: 0,
    PENDING: 1,
    CONFIRMED: 3,
};

/**
 * Map provider => system
 */
const EXTERNAL_PROVIDER_SYSTEM = Object.freeze({
    [ExternalProvider.FACEBOOK]: EXTERNAL_SYSTEM.FACEBOOK,
    [ExternalProvider.TWITTER]: EXTERNAL_SYSTEM.TWITTER,
    [ExternalProvider.GOOGLE]: EXTERNAL_SYSTEM.GOOGLE,
    [ExternalProvider.INSTAPAPER]: EXTERNAL_SYSTEM.INSTAPAPER,
    [ExternalProvider.ONENOTE]: EXTERNAL_SYSTEM.ONENOTE,
    [ExternalProvider.EVERNOTE]: EXTERNAL_SYSTEM.EVERNOTE,
    [ExternalProvider.BELL]: EXTERNAL_SYSTEM.BELL,
    [ExternalProvider.PIANO]: EXTERNAL_SYSTEM.PIANO,
    [ExternalProvider.AUTH0]: EXTERNAL_SYSTEM.AUTH0,
    [ExternalProvider.SIP]: EXTERNAL_SYSTEM.LIBRARY,
    [ExternalProvider.ADFS]: EXTERNAL_SYSTEM.LIBRARY,
    [ExternalProvider.VIDM]: EXTERNAL_SYSTEM.LIBRARY,
    [ExternalProvider.OPENIDCONNECT]: EXTERNAL_SYSTEM.LIBRARY,
    [ExternalProvider.OPENATHENS]: EXTERNAL_SYSTEM.LIBRARY,
    [ExternalProvider.CAPITA]: EXTERNAL_SYSTEM.LIBRARY,
    [ExternalProvider.AXIELL]: EXTERNAL_SYSTEM.LIBRARY,
    [ExternalProvider.FINNA]: EXTERNAL_SYSTEM.LIBRARY,
    [ExternalProvider.SPYDUS]: EXTERNAL_SYSTEM.LIBRARY,
    [ExternalProvider.LCF]: EXTERNAL_SYSTEM.LIBRARY,
    [ExternalProvider.SIERRA]: EXTERNAL_SYSTEM.LIBRARY,
    [ExternalProvider.RBDIGITAL]: EXTERNAL_SYSTEM.RBDIGITAL,
    [ExternalProvider.SAML]: EXTERNAL_SYSTEM.LIBRARY,
    [ExternalProvider.APPLE]: EXTERNAL_SYSTEM.APPLE,
});

/**
 * Map system => providers
 */
const EXTERNAL_SYSTEM_PROVIDERS = Object.freeze(invertBy(EXTERNAL_PROVIDER_SYSTEM));

enum EXTERNAL_AUTH_TYPE {
    SIGNUP = 'signup',
    SHARING = 'sharing',
}

/**
 * Authorization errors
 */
const EXTERNAL_AUTH_ERROR = Object.freeze({
    /** User cancelled authorization process */
    CANCELLED: 'Cancelled',

    /** User denied authorization */
    AUTHORIZATION_DENIED: 'AuthorizationDenied',

    /** Access Token Expired */
    ACCESSTOKEN_EXPIRED: 'TokenExpired',

    /** Sign in account exists */
    SIGNIN_ACCOUNT_EXISTS: 'SignInAccountExists',

    /** The last account for sign in, alternative sign in method required */
    LAST_SIGNIN_ACCOUNT: 'LastAccountForSignIn',

    /** External user id in use by another user */
    USERNAME_IN_USE: 'UserNameInUse',

    /** There is another profile with different external user id */
    EXTERNAL_USERID_MISMATCH: 'AccessTokenFromDiffAccount',

    /** External profile linked to another account */
    LINKED_TO_ANOTHER_ACCOUNT: 'LinkedToAnotherAccount',

    /** Unexpecetd error */
    UNEXPECTED_ERROR: 'UnexpectedError',
});

type LibraryCardAuthProvider =
    | ExternalProvider.SIP
    | ExternalProvider.SPYDUS
    | ExternalProvider.FINNA
    | ExternalProvider.AXIELL
    | ExternalProvider.CAPITA
    | ExternalProvider.LCF
    | ExternalProvider.SIERRA;

type LibraryExternalAuthProvider =
    | ExternalProvider.ADFS
    | ExternalProvider.OPENATHENS
    | ExternalProvider.OPENIDCONNECT
    | ExternalProvider.VIDM
    | ExternalProvider.SAML;

type LibraryAuthProvider = LibraryCardAuthProvider | ExternalProvider.BARCODE | ExternalProvider.EMAIL | LibraryExternalAuthProvider;

type SocialExternalAuthProvider =
    | ExternalProvider.BELL
    | ExternalProvider.FACEBOOK
    | ExternalProvider.TWITTER
    | ExternalProvider.GOOGLE
    | ExternalProvider.INSTAPAPER
    | ExternalProvider.ONENOTE
    | ExternalProvider.EVERNOTE
    | ExternalProvider.RBDIGITAL;

export {
    ExternalProvider,
    EXTERNAL_SYSTEM,
    EXTERNAL_SYSTEM_PROVIDERS,
    EXTERNAL_PROVIDER_SYSTEM,
    EXTERNAL_AUTH_TYPE,
    EXTERNAL_AUTH_ERROR,
    THIRD_PARTY_PROVIDERS,
    EXTERNAL_PROFILE_STATUSES,
    LibraryAuthProvider,
    LibraryExternalAuthProvider,
    LibraryCardAuthProvider,
    SocialExternalAuthProvider,
};
