import { Reducer } from 'redux';

import { ActionCreator, IAction, ICorrelatedAction } from './models/action';

let actionCorrelationSeqId = 1;

export function createActionCreator<TPayload = void>(type: string): ActionCreator<TPayload> {
    const createAction = (payload: TPayload) =>
        ({
            type,
            payload,
        } as IAction<TPayload>);
    createAction.toString = () => type;
    return createAction;
}

export function createGenericActionCreator<TBasePayload>(type: string) {
    const createAction = <TPayload extends TBasePayload>(payload: TPayload) =>
        ({
            type,
            payload,
        } as IAction<TPayload>);
    createAction.toString = () => type;
    return createAction;
}

export function createCorrelatedActionCreator<TPayload = void, TParentPayload = void>(type: string) {
    const createAction = (payload: TPayload, parent?: ICorrelatedAction<TParentPayload>) =>
        ({
            type,
            correlationId: parent?.correlationId ?? actionCorrelationSeqId++,
            payload,
        } as ICorrelatedAction<TPayload>);
    createAction.toString = () => type;
    return createAction;
}

function createReducer<TState>(initialState: TState, handlers: Record<string, (state: TState, action: IAction<any>) => TState>): Reducer<TState>;
function createReducer<TState>(initialState: TState, ...handlers: [string, (state: TState, action: IAction<any>) => TState][]): Reducer<TState>;
function createReducer<TState>(initialState: TState, ...params: unknown[]): Reducer<TState> {
    if (!params?.length) {
        return (state = initialState) => state;
    }
    let handlers: Record<string, (state: TState, action: IAction<unknown>) => TState>;
    if (Array.isArray(params[0])) {
        handlers = {};
        for (const [actionType, handler] of params as [string, (state: TState, action: IAction<unknown>) => TState][]) {
            handlers[actionType] = handler;
        }
    } else {
        handlers = params[0] as Record<string, (state: TState, action: IAction<unknown>) => TState>;
    }
    return (state = initialState, action) => handlers[action.type]?.(state, action as any) ?? state;
}

export { createReducer };

export function createActionHandler<TState, TPayload>(
    actionCreator: ActionCreator<TPayload>,
    handler: (state: TState, action: IAction<TPayload>) => TState,
): [string, typeof handler] {
    return [actionCreator.toString(), handler];
}
