import * as ko from 'knockout';

import ndLogger from 'nd.logger';
import { analytics } from 'analytics/analytics';

/*
Sample Usage
Insert this data-bind to any DOM element to track...
<a data-bind="analyticsEvent: { domEvents: 'click,mouseover', category: 'cat2', action: 'act2', label: 'lab2', value: 2 }"
domEvents [string list, required]: List (comma-separated) of DOM events to track (https://developer.mozilla.org/en-US/docs/Web/Events#event_listing)
category [string, optional]: Generic value, default 'DOM'
action [string, optional]: Generic value, defaults to the DOM event
label [string, optional]: Generic value
value [number, optional]: Generic value
*/
/*
For Google Analytics, you may need to enable the support for generic/custom categories
    <addgroup name="Analytics">
      <addgroup name="Google">
        <add name="ClientId" value="XXXXXXXXX" />
        <add name="Enabled" value="true" />
        <add name="Debug" value="true" />
        <addgroup name="Events">
          <addgroup name="Categories">
            <addgroup name="*">
              <add name="Enabled" value="true" type="System.Boolean" />
            </addgroup>
        </addgroup>
    </addgroup>
*/
const analyticsEvent = {
    init: (element: HTMLElement, valueAccessor: any) => {
        const data = valueAccessor();

        const domEvents: string[] = data.domEvents ? data.domEvents.split(',') : [];
        if (!domEvents.length) {
            ndLogger.log(`[analyticsEvent] Not DOM events defined for element ${element}`);
            return;
        }

        if (isNaN(data?.value || 0)) {
            ndLogger.log(`[analyticsEvent] Invalid value parameter for element ${element}`);
            return;
        }

        domEvents.forEach(domEvent => {
            ndLogger.log(`[analyticsEvent] Registering analyticsEvent for DOM event '${domEvent}' on element ${element}`);
            element.addEventListener(domEvent, event => {
                ndLogger.log(`[analyticsEvent] Executing DOM event '${domEvent}' on element ${element} with data ${event}`);
                analytics.event({
                    category: data?.category || 'DOM',
                    action: data?.action || domEvent,
                    label: data?.label || '',
                    value: +data?.value || 0,
                });
            });
        });
    },
};

// binding auto registration
ko.bindingHandlers.analyticsEvent = analyticsEvent;
