import userInfo from '@pressreader/src/nd.userinfo';

import ndUser from 'nd.user';

// User-ID and Cross Device
// https://support.google.com/analytics/topic/6009743
// Set up User-ID
// https://support.google.com/analytics/answer/3123666?hl=en&ref_topic=3123660
function SetupUserId(callback: (user: string) => void) {
    const setUser = async () => {
        await userInfo.load();
        callback(userInfo.enAccountNumber);
    };
    ndUser.onUserStateChanged(setUser);
    setUser();
}

export { SetupUserId };
