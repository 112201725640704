import 'nd.core';

var _provider = {
    showNewspaper: function (cid) {
        return false;
    },
    showIssue: function (issue) {
        return false;
    },
    showIssueDetails: function (params) {
        return false;
    },
    showIssuePage: function (pageNumber, issue) {
        return false;
    },
    redirect: function (url, state) {
        return false;
    },
    navigateRelative: function (relativeUrl) {
        return false;
    },
    navigateAbsolute: function (absoluteUrl) {
        return false;
    },
    showDocument: function (docId) {
        return false;
    },
};

export default $.nd.navigation = {
    setProvider: function (provider) {
        $.extend(_provider, provider);
        return this;
    },
    showNewspaper: function (cid) {
        if (cid) {
            _provider.showNewspaper(cid);
        }
        return this;
    },
    showIssue: function (issue) {
        _provider.showIssue.apply(_provider, arguments);
        return this;
    },
    /**
     * Displays the details of a specific issue.
     *
     * @param {Object} params - The parameters to identify the issue.
     * @param {string} params.issue - The key of the latest issue.
     * @param {string} params.country - The slug of the country where the publication is from.
     * @param {string} params.newspaper - The slug of the publication.
     */
    showIssueDetails: function (params) {
        _provider.showIssueDetails(params);
        return this;
    },
    showIssuePage: function (pageNumber, issue /*optional*/) {
        _provider.showIssuePage.apply(_provider, arguments);
        return true;
    },
    showArticle: function (articleId, issue /*optional*/) {
        _provider.showArticle.apply(_provider, arguments);
        return this;
    },
    redirect: function (url) {
        _provider.redirect(url);
        return this;
    },
    navigateRelative: function (relativeUrl) {
        _provider.navigateRelative(relativeUrl);
        return this;
    },
    navigateAbsolute: function (absoluteUrl) {
        _provider.navigateAbsolute(absoluteUrl);
        return this;
    },
    showDocument: function (docId) {
        _provider.showDocument(docId);
        return this;
    },
};
