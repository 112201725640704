import { createActionCreator } from '@pressreader/appstore';
import {
    AnySignInWithSystem,
    AnySignUpAsSystemData,
    AuthSystem,
    IAuthenticationAnalyticsContext,
    IAuthError,
    IUser,
    IUserSettings,
    LibraryExternalAuthProvider,
    SocialExternalAuthProvider,
} from '@pressreader/authentication-types';

import { AUTHENTICATION_CORE_MODULE_NAME } from '../../constants';

const prefix = `${AUTHENTICATION_CORE_MODULE_NAME}/common/`;
export enum UserChangeReason {
    Unknown,

    Login,
    Logout,
    SignUp,
}

interface IInitializedPayload {
    user: IUser;
    userSettings: IUserSettings;
}

interface ILoginPayload {
    loginAs: AnySignInWithSystem;
}

interface ILoginBeganPayload {
    analyticsCtx?: IAuthenticationAnalyticsContext;
}

interface ILoginDonePayload {
    system: AuthSystem;

    user: IUser;
    userSettings: IUserSettings;

    provider?: SocialExternalAuthProvider | LibraryExternalAuthProvider;

    analyticsCtx?: IAuthenticationAnalyticsContext;
}

interface ILoginFailedPayload {
    system: AuthSystem;

    error: IAuthError;
}

interface ILogOutFailedPayload {
    error: IAuthError;
}

interface ISignUpPayload {
    signUpAs: AnySignUpAsSystemData;
}

interface ISignUpBeganPayload {
    analyticsCtx?: IAuthenticationAnalyticsContext;
}

interface ISignUpDonePayload {
    /** Registered user. */
    user: IUser;
    userSettings: IUserSettings;

    emailValidationRequired: boolean;

    analyticsCtx?: IAuthenticationAnalyticsContext;
}

interface ISignUpFailedPayload {
    error: IAuthError;
}

interface IUserChangeBeganPayload {
    reason: UserChangeReason;
}

interface IUserChangeDonePayload {
    reason: UserChangeReason;
}

interface IUserChangeFailedPayload {
    reason: UserChangeReason;
    error: IAuthError;
}

interface IRecoverPwdPayload {
    email: string;
}

interface IPwdRecoveryBeganPayload {
    email: string;
}

interface IPwdRecoveryDonePayload {
    email: string;
}

interface IPwdRecoveryFailedPayload {
    email: string;
    error: string;
}

interface IUserAvatarChangedPayload {
    imageId: string;
}

export const authInitialized = createActionCreator<IInitializedPayload>(`${prefix}initialized`);

export const loginAction = createActionCreator<ILoginPayload>(`${prefix}login`);
export const loginBegan = createActionCreator<ILoginBeganPayload>(`${prefix}login_began`);
export const loginDone = createActionCreator<ILoginDonePayload>(`${prefix}login_done`);
export const loginFailed = createActionCreator<ILoginFailedPayload>(`${prefix}login_failed`);

export const logOutRequestAction = createActionCreator(`${prefix}logout_request`);
export const logOutBegan = createActionCreator(`${prefix}logout_began`);
export const logOutDone = createActionCreator(`${prefix}logout_done`);
export const logOutFailed = createActionCreator<ILogOutFailedPayload>(`${prefix}logout_failed`);

export const signUpAction = createActionCreator<ISignUpPayload>(`${prefix}signup`);
export const signUpBegan = createActionCreator<ISignUpBeganPayload>(`${prefix}signup_began`);
export const signUpDone = createActionCreator<ISignUpDonePayload>(`${prefix}signup_done`);
export const signUpFailed = createActionCreator<ISignUpFailedPayload>(`${prefix}signup_failed`);

export const resetAuthStateAction = createActionCreator(`${prefix}reset_auth_state`);

export const userChangeBegan = createActionCreator<IUserChangeBeganPayload>(`${prefix}user_change_began`);
export const userChangeDone = createActionCreator<IUserChangeDonePayload>(`${prefix}user_change_done`);
export const userChangeFailed = createActionCreator<IUserChangeFailedPayload>(`${prefix}user_change_failed`);

export const recoverPwdAction = createActionCreator<IRecoverPwdPayload>(`${prefix}recover_pwd`);
export const pwdRecoveryBegan = createActionCreator<IPwdRecoveryBeganPayload>(`${prefix}pwd_recovery_began`);
export const pwdRecoveryDone = createActionCreator<IPwdRecoveryDonePayload>(`${prefix}pwd_recovery_done`);
export const pwdRecoveryFailed = createActionCreator<IPwdRecoveryFailedPayload>(`${prefix}pwd_recovery_failed`);

export const userAvatarChanged = createActionCreator<IUserAvatarChangedPayload>(`${prefix}user_avatar_changed`);
