import { isFunction, trimEnd } from 'lodash';
import * as Resumable from 'resumablejs';

import { httpPost, httpPut, svcAuth } from '@pressreader/services';
import { isNullOrWhitespace } from '@pressreader/utils';

import res from 'deprecated/nd.res';

const defaultOptions = {
    testChunks: false,
    minFileSize: 1,
    permanentErrors: [0, 400, 403, 404, 413, 415, 500, 501],
    headers: {},
};

interface IFileUploadParams {
    onFileAdded?: (IFileUploadInfo) => void;
    onUploadStart?: (string) => void;
    onProgress?: (Number) => void;
}

interface IFileUploadResult {
    fileId: string;
    contentType?: string;
}

interface IFileUploadInfo {
    file?: File;
    id?: string;
    fileId?: string;
    resumable?: Resumable;
}

/**
 * File uploader class (using resumable.js).
 * @see https://github.com/23/resumable.js
 */
class FileUpload<TResult extends IFileUploadResult> {
    private createFileUrl = '';
    private finalizeUrl = '';
    private readonly storage: string = '';
    private readonly serverUrl: string = '';
    private readonly options: any;
    /**
     * Creates new instance of the {FileUpload} class.
     *
     * @param {String} serverUrl - file upload URL on the server.
     * @param {String} storage - File server storage name.
     * @param {Object} options - Resumable.js plugin options.
     */
    constructor(serverUrl: string, storage: string, options: any) {
        if (isNullOrWhitespace(serverUrl)) {
            throw new Error('serverUrl is empty');
        }

        if (isNullOrWhitespace(storage)) {
            throw new Error('storage is empty');
        }

        this.storage = storage;
        this.serverUrl = trimEnd(serverUrl, '/');
        this.options = { ...defaultOptions, ...options };
    }

    /**
     * Start file uploading process - shows select file dialog.
     *
     */
    upload(params?: IFileUploadParams): Promise<TResult> {
        return Promise.all([svcAuth.authorized(), res.loaded()])
            .then(([token]) => {
                this.createFileUrl = `${this.serverUrl}/create/${this.storage}`;
                this.options.headers['Authorization'] = `Bearer ${token}`;
            })
            .then(() =>
                this.createResumable({
                    uploadInfo: {},
                    onFileAdded: params?.onFileAdded,
                    onUploadStart: params?.onUploadStart,
                    onProgress: params?.onProgress,
                }),
            )
            .then(uploadInfo => this.openDialog(uploadInfo))
            .then(uploadInfo => this.createFile(uploadInfo))
            .then(uploadInfo => this.startUploading(uploadInfo))
            .then(uploadInfo => this.finalizeUploading(uploadInfo));
    }

    /**
     * Opens new file dialog.
     */
    private openDialog(uploadInfo: IFileUploadInfo): Promise<IFileUploadInfo> {
        return new Promise(resolve => {
            const fileInput = this.createFileInput(this.options.allowedExtensions);
            fileInput.addEventListener('input', (event: Event) => {
                uploadInfo.file = (event.target as HTMLInputElement).files[0];
                resolve(uploadInfo);
            });
            fileInput.focus();
            fileInput.dispatchEvent(new MouseEvent('click'));
        });
    }

    /**
     * Creates a new file on the server and returns its id.
     */
    private createFile(uploadInfo: IFileUploadInfo): Promise<IFileUploadInfo> {
        return httpPost({
            url: this.createFileUrl,
            data: { contentType: uploadInfo.file.type },
            requestConfig: {
                contentType: 'application/json',
            },
        }).then((result: { id: string }) => {
            uploadInfo.id = result.id;
            uploadInfo.fileId = `${this.storage}/${result.id}`;
            uploadInfo.resumable.opts.target = `${this.serverUrl}/append/${this.storage}/${result.id}`;
            this.finalizeUrl = `${this.serverUrl}/finalize/${this.storage}/${result.id}`;
            return uploadInfo;
        });
    }

    /**
     * Creates resumable and adds file to it.
     */
    private createResumable(params: {
        uploadInfo: IFileUploadInfo;
        onFileAdded?: (IFileUploadInfo) => void;
        onUploadStart?: (string) => void;
        onProgress?: (Number) => void;
    }): Promise<IFileUploadInfo> {
        return new Promise((resolve, reject) => {
            const resumable = new Resumable(this.options);
            if (!resumable.support) {
                reject(new Error('Resumable.js not supported'));
            }

            if (isFunction(params.onFileAdded)) {
                resumable.on('fileAdded', () => {
                    params.onFileAdded(params.uploadInfo);
                });
            }

            if (isFunction(params.onUploadStart)) {
                resumable.on('uploadStart', () => params.onUploadStart(params.uploadInfo.fileId));
            }

            if (isFunction(params.onProgress)) {
                resumable.on('progress', () => {
                    params.onProgress(Math.floor(resumable.progress() * 100));
                });
            }

            params.uploadInfo.resumable = resumable;
            resolve(params.uploadInfo);
        });
    }

    /**
     * Uploads file to server
     */
    private startUploading(uploadInfo: IFileUploadInfo): Promise<IFileUploadInfo> {
        return new Promise((resolve, reject) => {
            const resumable = uploadInfo.resumable;

            resumable.on('complete', () => {
                resolve(uploadInfo);
            });

            resumable.on('fileError', (file, message) => {
                reject(new Error(message));
            });

            resumable.on('fileError', (file, message) => {
                reject(new Error(message));
            });

            resumable.on('fileAdded', () => {
                resumable.upload();
            });

            resumable.addFile(uploadInfo.file);
        });
    }

    /**
     * Finalizes uploading.
     */
    private finalizeUploading(uploadInfo: IFileUploadInfo): Promise<TResult> {
        return httpPut({
            url: this.finalizeUrl,
        });
    }

    /**
     * Creates new file input.
     */
    private createFileInput(allowedExtensions: string): HTMLInputElement {
        const fileInput = document.createElement('input');
        fileInput.setAttribute('type', 'file');
        fileInput.style.opacity = '0';
        fileInput.style.display = 'none';
        if (isNullOrWhitespace(allowedExtensions)) {
            fileInput.setAttribute('accept', allowedExtensions);
        }
        return fileInput;
    }
}

export { FileUpload, IFileUploadParams, IFileUploadResult, IFileUploadInfo };
