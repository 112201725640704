import { newGuid } from '@pressreader/utils';

const sessionIdKey = 'analytics:sessionid';
const sessionIdTimeKey = 'analytics:sessionid:time';
const EXPIRATION_SESSION_PERIOD = 20 * 60 * 1000;

function updateSessionIdTime() {
    try {
        sessionStorage.setItem(sessionIdTimeKey, new Date().getTime().toString());
    } catch {
        // ignore any errors here
    }
}

function updateSessionId(sessionId: string) {
    try {
        sessionStorage.setItem(sessionIdKey, sessionId);
    } catch {
        // ignore any errors here
    }
}
const getAnalyticsSessionId = () => {
    let sessionId;
    if (window.sessionStorage) {
        const sessionIdTime = sessionStorage.getItem(sessionIdTimeKey);
        const sessionTime = sessionIdTime ? new Date(Number(sessionIdTime)) : new Date();
        if (new Date().getTime() - sessionTime.getTime() > EXPIRATION_SESSION_PERIOD) {
            sessionId = newGuid();
        } else {
            sessionId = sessionStorage.getItem(sessionIdKey);
            if (!sessionId) {
                sessionId = newGuid();
            }
        }
        updateSessionId(sessionId);
        updateSessionIdTime();
    } else {
        sessionId = newGuid();
    }
    return sessionId;
};

const resetAnalyticsSessionId = (): string => {
    const sessionId = newGuid();
    if (window.sessionStorage) {
        updateSessionId(sessionId);
        updateSessionIdTime();
    }
    return sessionId;
};

export { getAnalyticsSessionId, resetAnalyticsSessionId };
