// eslint-env es6

import { isNullOrWhitespace } from '@pressreader/utils';

import ndServiceApi from 'nd.services.api';

const serviceBasePath = 'subscriptions/behaviourprofiles';
const cache = {};

const BEHAVIOUR_CASE = Object.freeze({
    SUBSCRIPTION_NOT_VALID: 'SubscriptionNotValid',
    MANAGE_SUBSCRIPTION: 'ManageSubscription',
});

/**
 * Subscription Behaviour Case configuration
 *
 * @param {String} profileName - name of profile
 * @param {String} caseName - name of case, @see BEHAVIOUR_CASE
 * @returns Promise.<SubscriptionBehaviourCase>
 */
function getBehaviourCase(profileName, caseName) {
    if (isNullOrWhitespace(profileName)) {
        throw new Error('profileName required');
    }
    if (isNullOrWhitespace(caseName)) {
        throw new Error('behaviourCase required');
    }

    const cacheKey = `${profileName}-${caseName}`;
    if (cache[cacheKey] !== undefined) {
        return Promise.resolve(cache[cacheKey]);
    }

    return ndServiceApi.get(serviceBasePath, `${profileName}/cases/${caseName}`).then(behaviourCase => {
        cache[cacheKey] = behaviourCase;
        return behaviourCase;
    });
}

export { getBehaviourCase, BEHAVIOUR_CASE };
