/* eslint-env es6 */

'use strict';

import * as ko from 'knockout';

/**
 * This extender adds two methods to the observable:
 * commit: marks the latest written value to this observable as 'committed'.
 * undo: reverts the latest written value to the latest committed.
 * @param {Observable} target - knockout observable instance.
 */
function undoExtender(target) {
    let actualValue = target();
    let committedValue = actualValue;

    target.subscribe(newVal => {
        actualValue = newVal;
    });

    target.commit = function commit() {
        committedValue = actualValue;
    };

    target.undo = function undo() {
        target(committedValue);
    };
}

ko.extenders.undo = undoExtender;

export default undoExtender;
