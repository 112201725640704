/* eslint-env es6 */
'use strict';

import { find, isObject, isFunction } from 'lodash';
import * as ko from 'knockout';

const __px = '[ko-component:nd-swich]';

/**
 * A particular option in swich component
 */
class SwitchOption {
    constructor({ value, label = '', selected = false, onToggle }) {
        this.label = label;
        this.value = value;
        this.selected = ko.observable(selected);
        if (isFunction(onToggle)) {
            this.selected.subscribe(selValue => {
                // console.log(__px, `"${this.label}" toggle state computed, selected status: ${selValue}`);
                onToggle(selValue);
            });
        }
    }

    get unwrappedValue() {
        return ko.unwrap(this.value);
    }

    toggle(val) {
        // console.log(__px, `"${this.label}" toggled with value ${val}`);
        if (val === undefined) {
            val = !this.selected();
        }

        this.selected(val);
    }
}

function toOptionConfig(value) {
    if (!ko.isObservable(value) && isObject(value)) {
        return value;
    }

    return { value, label: value };
}

function createSwitchOptions(options) {
    const unwrappedOpts = ko.unwrap(options);
    if (!Array.isArray(unwrappedOpts)) {
        throw new Error(`${__px} Invalid configuration: expected array of options`);
    }

    return unwrappedOpts.map(segmentCfg => {
        const cfg = toOptionConfig(segmentCfg);
        return new SwitchOption(cfg);
    });
}

/**
 * A view model for switch component.
 */
class SwitchModel {
    constructor({ options, value }) {
        if (!ko.isObservable(value)) {
            throw new Error(`${__px} Invalid configuration: expected observable as "value"`);
        }

        this._switchOptions = createSwitchOptions(options);
        this.options = ko.observableArray(this._switchOptions);
        if (ko.isObservable(options)) {
            options.subscribe(newSegments => {
                this.updateSwitchOptions(newSegments);
            });
        }

        this.selectedOption = ko
            .pureComputed(() => {
                const currValue = value();
                // console.log(__px, 'selectedOption recomputed, current', currValue);
                return currValue;
            })
            .extend({ deferred: true });

        this.selectOne = (targetSegment /* , event*/) => {
            // console.log(__px, 'segment clicked', targetSegment.selected(), event);
            value(targetSegment.unwrappedValue);
        };

        value.subscribe(val => {
            this.updateSelection(val);
        });

        this.updateSelection(value);
    }

    selectOption(optionToSelect) {
        if (!optionToSelect) {
            return;
        }

        // Do noting if processing item is already selected
        if (optionToSelect.selected()) {
            return;
        }

        this._switchOptions.forEach(option => {
            option.toggle(option === optionToSelect);
        });
    }

    updateSelection(val) {
        const selectionVal = ko.unwrap(val);
        const optToSelect = find(this._switchOptions, s => s.unwrappedValue === selectionVal);
        this.selectOption(optToSelect);
    }

    updateSwitchOptions(newCfgs) {
        const currentSelection = this.selectedOption();
        const newOptions = createSwitchOptions(newCfgs);
        this._switchOptions.splice(0, this._switchOptions.length, ...newOptions);
        this.options.valueHasMutated();
        this.updateSelection(currentSelection);
    }
}

/**
 * Knockout component for switching between several options.
 * @description Component configuration
 * @param {Object} params Component configuration object.
 * @param {Array|ko.observableArray} params.options An array of options.
 *      If an observable array provided the component will track changes and adjust options accordingly.
 *      Each element might be either a primitive value, observable or an object.
 *      If object, the component will use its "label" field as a displayed name of the corresponding option
 *      and "value" as value of the option.
 *      If primitive or observable provided, it's used as both label and value.
 * @param {ko.observable} params.value An observable to supply currently selected value.
 * @example
 * ``` HTML
 *
 *  <div data-bind="component: { name: 'nd-switch', params: switchParams }"></div>
 *
 * ``` JS
 *  const switchParams = {
 *      options: [
 *          'simple text',
 *          {
 *              label: 'Arbitrary object',
 *              value: 123,
 *          },
 *          ko.observable('observable value'),
 *      ],
 *      value: ko.observable(123),  // select second option by default
 *  }
 *
 *  // Update options after some time
 *  setTimeout(() => {
 *      switchParams.options.push('another simple value');
 *  }, 1000);
 */
const switchComponentDefinition = {
    viewModel: SwitchModel,
    template: {
        ndTemplate: 'v7.Client.Switch.ko.Component',
    },
};

export const COMPONENT_NAME = 'nd-switch';

// Component registration function
export default function registerComponent(koInstance) {
    if (koInstance.components.isRegistered(COMPONENT_NAME)) {
        // eslint-disable-next-line no-console
        console.warn(`Knockout component "${COMPONENT_NAME}" already registered.`);
        return;
    }

    koInstance.components.register(COMPONENT_NAME, switchComponentDefinition);
}
