import isEmpty from 'lodash/isEmpty';

import { ISize } from '@pressreader/geometry';
import { ImageHeightToScreenHeightRatio, IScreenInfo } from '@pressreader/ui-types';

const ImagePreferredHeightRatio = 2 / 3;
const ImagePreferredScale = 1;

const IMAGE_MAX_SCALE = 4;
const IMAGE_MAX_RATIO = 4;

/** The logic is ported from Android app. */
function getBestCoverImage<TImage extends { url: string; size: ISize | null }>(images: TImage[], screenInfo: IScreenInfo) {
    if (isEmpty(images)) {
        return null;
    }

    // for landscape orientation image should be returned same as for portrait
    const maxWidth = Math.min(screenInfo.screenWidth, screenInfo.screenHeight);
    const maxHeight = Math.max(screenInfo.screenWidth, screenInfo.screenHeight) * ImageHeightToScreenHeightRatio;

    const result = images.reduce<{ image: TImage; rate: number } | null>((bestImage, image) => {
        if (!image.size || image.size.width === null || image.size.height === null) {
            return bestImage;
        }

        const scale = maxWidth / image.size.width;
        const heightRatio = image.size.height / image.size.width;

        if (image.size.height * scale <= maxHeight && scale <= IMAGE_MAX_SCALE && heightRatio < IMAGE_MAX_RATIO) {
            // calculate rate of the image, includes how close it is to preferred ratio, scale and height
            let rate = (1 / Math.abs(heightRatio - ImagePreferredHeightRatio)) * 0.5; // ratio is not as important as scale
            rate *= 1 / Math.abs(scale - ImagePreferredScale); // scale the most important (scale closer to preferred looks better)
            rate *= 1 - 1 / Math.abs(IMAGE_MAX_SCALE - scale); // the more image is needed to scale, the less preferred it is

            if (!bestImage || rate > bestImage.rate) {
                return { image, rate };
            }
        }

        return bestImage;
    }, null);

    return result?.image || null;
}

function getBestImage<TImage extends { url: string; size: ISize | null }>(images: TImage[]) {
    if (!images?.length) {
        return null;
    }

    let result: TImage | null = null;
    let resultSize = 0;

    for (const image of images) {
        if (!image.size || image.size.height === null || image.size.width === null) {
            continue;
        }

        const heightRatio = image.size.height / image.size.width;
        if (heightRatio > IMAGE_MAX_RATIO) {
            continue;
        }
        const size = image.size.width * image.size.height;
        if (resultSize < size) {
            result = image;
            resultSize = size;
        }
    }

    return result;
}

export { getBestCoverImage, getBestImage };
