import { createActionCreator } from '@pressreader/appstore';

import { CONFIG_MODULE_NAME } from '../constants';

const prefix = CONFIG_MODULE_NAME + '/';

/** @deprecated Left for compatibility with native.adapter. */
export interface IExtendConfigPayload {
    values: Record<string, unknown>;
}

/** @deprecated Left for compatibility with native.adapter. */
export interface IConfigExtendedPayload {
    config: Record<string, unknown>;
}

export interface IConfigLoadDonePayload {
    config: Record<string, unknown>;
}

/** @deprecated Left for compatibility with native.adapter. */
export const extendConfigAction = createActionCreator<IExtendConfigPayload>(`${prefix}extend_config`);
/** @deprecated Left for compatibility with native.adapter. */
export const configExtended = createActionCreator<IConfigExtendedPayload>(`${prefix}config_extended`);
/** @deprecated Left for compatibility with native.adapter. */
export const forceConfigLoadDoneAction = createActionCreator(`${prefix}force_config_load_done`);

export const configChanged = createActionCreator(`${prefix}config_changed`);

export const loadConfigAction = createActionCreator(`${prefix}load_config`);

export const configLoadBegan = createActionCreator(`${prefix}config_load_began`);
export const configLoadDone = createActionCreator<IConfigLoadDonePayload>(`${prefix}config_load_done`);
export const configLoadFailed = createActionCreator(`${prefix}config_load_failed`);
