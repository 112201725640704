import ndLogger from 'nd.logger';
import ndRes from 'nd.res';

export class ShowPasswordButton {
    private static DOM = {
        class: {
            pri: 'pri',
            priHidden: 'pri-hidden',
            priVisible: 'pri-visible',
            btnIcon: 'btn-icon',
            btnHelp: 'btn-help',
        },
        resources: {
            showPassword: 'Dialogs.SignIn.ShowPassword',
            hidePassword: 'Dialogs.SignIn.HidePassword',
        },
    };

    public static async initDOM(passwordContainerSelector: string) {
        const container = document.querySelector(`${passwordContainerSelector}`);
        const passwordFields = container.querySelectorAll('[type=password]');

        if (passwordFields) {
            try {
                const showPasswordElm = document.createElement('span') as HTMLElement;
                const cls = ShowPasswordButton.DOM.class;
                showPasswordElm.setAttribute('aria-hidden', 'true');
                showPasswordElm.classList.add(...[cls.pri, cls.priHidden]);
                passwordFields.forEach(passwordField => {
                    const aWrapper = document.createElement('a') as HTMLElement;
                    aWrapper.classList.add(...[cls.btnHelp, cls.btnIcon]);
                    aWrapper.title = ndRes.val(ShowPasswordButton.DOM.resources.showPassword);
                    aWrapper.appendChild(showPasswordElm);
                    passwordField.after(aWrapper);

                    ShowPasswordButton.addListeners(passwordField as HTMLInputElement, aWrapper, showPasswordElm);
                });
            } catch {
                ndLogger.error('Unable to load show password button UI');
            }
        }
    }

    private static addListeners(passwordField: HTMLInputElement, aWrapper: HTMLElement, showPasswordElm: HTMLElement) {
        if (aWrapper && showPasswordElm) {
            const cls = ShowPasswordButton.DOM.class;
            aWrapper.addEventListener('click', () => {
                if (passwordField.type === 'password') {
                    passwordField.type = 'text';
                    aWrapper.title = ndRes.val(ShowPasswordButton.DOM.resources.showPassword);
                    showPasswordElm.classList.replace(cls.priHidden, cls.priVisible);
                } else {
                    passwordField.type = 'password';
                    aWrapper.title = ndRes.val(ShowPasswordButton.DOM.resources.hidePassword);
                    showPasswordElm.classList.replace(cls.priVisible, cls.priHidden);
                }
                passwordField.focus();
            });
        }
    }
}
