import { Task } from 'redux-saga';
import { cancel, fork, put, select } from 'redux-saga/effects';

import { GeneratorResult, takeIf } from '@pressreader/appstore';
import { error } from '@pressreader/logger';
import { AnyPopup, AnyPopupOptions, IAlertPopup, IDialogPopup, IMenuPopup } from '@pressreader/popups-types';
import { newGuid } from '@pressreader/utils';

import { IPopupResult } from '../types/popup.result';
import { IPopupSagas } from '../types/popup.sagas';

import { closePopupAction, popupChanged, popupClosed, popupOpened } from './actions';
import { selectPopupById } from './selectors';

function* closePopup(action: ReturnType<typeof closePopupAction>) {
    const { id, result, isBack, isCancelled } = action.payload;
    const popup: ReturnType<typeof selectPopupById> = yield select(selectPopupById, { id });
    yield put(popupClosed({ id, isCancelled, result }));

    if (popup?.parentId && !isBack) {
        yield put(closePopupAction({ id: popup.parentId, result, isCancelled }));
    }
}

/**
 * You can use store related API here from global modules only. Don't use selectors and such from your dialog/menu module.
 * Doing so will mess up initialization and tree shaking.
 */
function* openPopup<TResultData>(
    popupOptions: AnyPopupOptions,
    sagas?: IPopupSagas<TResultData>,
): GeneratorResult<IPopupResult<TResultData | undefined>> {
    const id = newGuid();
    yield put(popupOpened({ popup: { ...popupOptions, id } }));

    let task: Task | undefined;
    if (sagas?.onOpened) {
        try {
            task = yield fork(sagas.onOpened, id);
        } catch (e) {
            error(`Error running onOpened saga for ${popupOptions.name}.`, e);
        }
    }

    let result: IPopupResult<TResultData | undefined>;
    while (true) {
        const action: ReturnType<closePopupAction<TResultData>> = yield takeIf<ReturnType<closePopupAction<TResultData>>['payload']>(
            closePopupAction,
            a => a.payload.id === id,
        );
        result = { popupId: id, cancelled: action.payload.isCancelled === true, data: action.payload.result };

        let canClose = true;
        if (sagas?.onClosing) {
            try {
                canClose = (yield sagas.onClosing(result)) !== false;
            } catch (e) {
                error(`Error running onClosing saga for ${popupOptions.name}`, e);
                canClose = false;
            }
        }

        if (canClose) {
            yield closePopup(action);
            break;
        }
    }

    if (task) {
        yield cancel(task);
    }

    return result;
}

function* updatePopup<TPopup extends AnyPopup>(
    popupId: string,
    changes: Partial<Omit<TPopup, keyof IDialogPopup | keyof IMenuPopup | keyof IAlertPopup>>,
): GeneratorResult<void> {
    const popup: ReturnType<typeof selectPopupById> = yield select(selectPopupById, { id: popupId });
    if (!popup) {
        return;
    }
    yield put(popupChanged({ popup: { ...popup, ...changes } }));
}

export { openPopup, updatePopup };
