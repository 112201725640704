import * as $ from "jquery";
import * as ko from "knockout";
import $ndMenu from "nd.ui.menu";

$ndMenu.items = (function () {

    var self = {};

    self.types = {
        generic: 0,
        language: 1,
        translate: 2,
        checkbox: 3,
        radio: 4,
        issueDate: 5,
        custom: 6
    };

    var menuItemState = {
        default: 'default',
        waiting: 'waiting',
        selected: 'selected'
    };

    function _Item(controller, title, css) {
        this._controller = controller;
        this._title = title;
        this._css = css;
        this.enabled = ko.computed(this._enabled, this).extend({ async: true });
    }

    _Item.prototype = {
        _name: null,
        _css: null,
        _type: self.types.generic,
        _controller: null,
        _template: "default",
        _title: null,
        _meta: null,
        _click: null,
        name: function (name) {
            if (!arguments.length) return this._name;
            this._name = name;
            return this;
        },
        css: function (css) {
            if (!arguments.length) return this._css;
            this._css = css;
            return this;
        },
        bind: function (handler) {
            if (!this._click) this._click = $.Callbacks();
            this._click.add(handler);
            return this;
        },
        type: function () {
            return this._type;
        },
        title: function (title) {
            return this._title;
        },
        meta: function () {
            return this._meta;
        },
        controller: function () {
            return this._controller;
        },
        _enabled: function () {

            if (!this._controller) return Promise.resolve(false);

            return Promise.all([this._controller.available(), this._controller.enabled()])
                .then(function (arr) {
                    var isAvailable = arr[0];
                    var isEnabled = arr[1];
                    return isAvailable && isEnabled;
                });

        },
        activated: function () {
            var ctrl = this._controller;
            return (ctrl && ctrl.activated()) || false;
        },
        onClick: function () {

            if (!this.controller || !this._click) {
                return;
            }

            var self = this;

            this._enabled().then(function (enabled) {
                if (!enabled) {
                    return;
                }
                self._click.fire(self);
            });
        },
        template: function (template) {
            if (!arguments.length) return this._template;
            this._template = template;
            return this;
        }
    };
    self.item = _Item;

    function _ItemWithSpinner(settings) {
        if (settings) {
            _Item.apply(this, [settings.controller, settings.title]);
            this.state = ko.observable(menuItemState.default);
            this.settings = settings;
            this.isWaiting = ko.pureComputed(function () {
                return this.state() === menuItemState.waiting;
            }, this);
            this.cssClass = ko.pureComputed(function () {
                if (this.settings && this.settings.css) {
                    return this.settings.css[this.state()] || this.settings.css[menuItemState.default];
                }
                return this._css;
            }, this);

        }
        this._template = "default_spinner";
    }

    $.extend(_ItemWithSpinner.prototype, _Item.prototype, {
        onClick: function (item) {
            var activateAction = item.settings && item.settings.activate;
            if (activateAction) {
                item.state(menuItemState.waiting);
                Promise.resolve(activateAction()).then(function () {
                    item.state(menuItemState.selected);
                }).catch(function () {
                    item.state(menuItemState.default);
                });
            }
        }
    });
    self.ItemWithSpinner = _ItemWithSpinner;

    function _Language() {
        _Item.apply(this, arguments);
        this._type = self.language;
        this._template = "language";
    }

    $.extend(_Language.prototype, _Item.prototype, {
        enableLanguage: null,
        language: null
    });
    self.language = _Language;

    function _Translate() {
        _Item.apply(this, arguments);
        this._type = self.translate;
        this._template = "translate";
    }

    $.extend(_Translate.prototype, _Item.prototype, {});
    self.translate = _Translate;

    function _IssueDate() {
        _Item.apply(this, arguments);
        this._type = self.issueDate;
        this._template = "issueDate";
    }

    $.extend(_IssueDate.prototype, _Item.prototype, {});
    self.issueDate = _IssueDate;

    function _Checkbox() {
        _Item.apply(this, arguments);
        this._type = self.types.checkbox;
        this._template = "checkbox";
    }

    $.extend(_Checkbox.prototype, _Item.prototype, {
        checked: null
    });
    self.checkbox = _Checkbox;

    function _Radio() {
        _Item.apply(this, arguments);
        this._type = self.types.radio;
        this._template = "radio";
    }

    $.extend(_Radio.prototype, _Item.prototype, {
        checked: null
    });
    self.radio = _Radio;

    function _Custom(controller, title, template) {
        _Item.call(this, controller, title);
        this._type = self.types.custom;
        this._jsTemplate = template;
    }

    $.extend(_Custom.prototype, _Item.prototype, {
        _jsTemplate: null,
        jsTemplate: function () {
            return this._jsTemplate;
        }
    });
    self.custom = _Custom;

    function _TogglePervasive() {
        _Item.apply(this, arguments);
        this._type = self.translate;
        this._template = "togglePervasive";
        this._subtitle = null;
    }

    $.extend(_TogglePervasive.prototype, _Item.prototype, {
        setSubtitle(value) {
            this._subtitle = value;
        },
        subtitle() {
            return this._subtitle;
        },
    });
    self.togglePervasive = _TogglePervasive;

    function _ToggleFacelift() {
        _Item.apply(this, arguments);
        this._type = self.translate;
        this._template = "toggleFacelift";
        this._faceliftEnabled = false;
        this._subtitle = null;
    }

    $.extend(_ToggleFacelift.prototype, _Item.prototype, {
        setSubtitle(value) {
            this._subtitle = value;
        },
        subtitle() {
            return this._subtitle;
        },
        setFaceliftEnabled(value) {
            this._faceliftEnabled = value;
        },
        faceliftEnabled() {
            return this._faceliftEnabled;
        },
    });
    self.toggleFacelift = _ToggleFacelift;

    function _ToggleFestive() {
        _Item.apply(this, arguments);
        this._type = self.translate;
        this._template = "toggleFestive";
        this._festiveEnabled = false;
        this._subtitle = null;
    }

    $.extend(_ToggleFestive.prototype, _Item.prototype, {
        setSubtitle(value) {
            this._subtitle = value;
        },
        subtitle() {
            return this._subtitle;
        },
        setFestiveEnabled(value) {
            this._festiveEnabled = value;
        },
        festiveEnabled() {
            return this._festiveEnabled;
        },
    });
    self.toggleFestive = _ToggleFestive;

    function _ToggleHotspot() {
        _Item.apply(this, arguments);
        this._type = self.translate;
        this._template = "toggleHotspot";
        this._hotspotEnabled = false;
        this._subtitle = null;
    }

    $.extend(_ToggleHotspot.prototype, _Item.prototype, {
        setSubtitle(value) {
            this._subtitle = value;
        },
        subtitle() {
            return this._subtitle;
        },
        setHotspotEnabled(value) {
            this._hotspotEnabled = value;
        },
        hotspotEnabled() {
            return this._hotspotEnabled;
        },
    });
    self.toggleHotspot = _ToggleHotspot;

    return self;

})();

export default $ndMenu.items;


