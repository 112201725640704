import { createSelector } from 'reselect';

import { selectTargetPlatform } from '@pressreader/appcore';
import { selectGetConfigValue } from '@pressreader/config';
import { NavigationConfig, ViewName } from '@pressreader/navigation-types';
import { TargetPlatform } from '@pressreader/types';

import { INavigationConfigDto } from '../api/types';
import { NAVIGATION_CORE_MODULE_NAME } from '../constants';

import { IState } from './reducer';

const getState = (state: Record<string, unknown>) => state[NAVIGATION_CORE_MODULE_NAME] as IState;

export const selectHandleRouting = createSelector(getState, state => state.handleRouting);

export const selectNavigationConfig = createSelector(selectGetConfigValue, selectTargetPlatform, (getConfigValue, platform): NavigationConfig => {
    const configDto = getConfigValue<INavigationConfigDto>('app.common.navigation');

    const featureFlagName = platform === TargetPlatform.Pervasive ? 'features.pervasive.home.view.name' : 'features.home.view.name';
    const homeViewName = getConfigValue<ViewName>(featureFlagName);

    if (!configDto) {
        // in case configuration is missing (for example: voyager project)
        return { landingUrl: null, homeViewName };
    }

    return {
        landingUrl: getConfigValue<string | null>(configDto.landing_url_feature_key, null),
        homeViewName,
    };
});

export const selectViewName = createSelector(getState, state => state.viewName);
export const selectPrevNavConfig = createSelector(getState, state => state.prevNavConfig);
export const selectNavConfig = createSelector(getState, state => state.navConfig);

export const selectLocation = createSelector(getState, state => state.location);
export const selectLocationState = createSelector(selectLocation, location => location.state);
export const selectNavigationHistoryState = createSelector(selectLocationState, selectViewName, (state, viewName) => ({
    state,
    stateLess: {},
    viewName: viewName === null ? ViewName.Error : viewName,
}));
