import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';

import { selectGetConfigValue } from '@pressreader/config';
import {
    CONFIG_INTERFACE_CURRENT_LANGUAGE,
    CONFIG_INTERFACE_LANGUAGES,
    CONFIG_INTERFACE_LANGUAGES_ENABLED,
    CONFIG_INTERFACE_PREFERRED_LANGUAGE,
    CONFIG_USER_LOCATION,
} from '@pressreader/routing';

import { LanguageConfigDto, mapLanguageConfigDto } from '../../types/mapper/language.mapper';
import { mapTranslationConfig, TranslationConfigDto } from '../../types/mapper/translation.config.mapper';

const DEFAULT_LANGUAGE_LOCALE = 'en-us';
const selectInterfaceLanguagesEnabled = createSelector(selectGetConfigValue, getConfigValue =>
    getConfigValue<boolean>(CONFIG_INTERFACE_LANGUAGES_ENABLED, true),
);

const selectInterfaceLanguages = createSelector(selectGetConfigValue, getConfigValue =>
    getConfigValue<LanguageConfigDto[]>(CONFIG_INTERFACE_LANGUAGES, []).map(mapLanguageConfigDto),
);
const selectInterfaceLanguageCode = createSelector(selectGetConfigValue, getConfigValue =>
    getConfigValue<string>(CONFIG_INTERFACE_CURRENT_LANGUAGE, 'en'),
);
const selectPreferredLanguageCodes = createSelector(selectGetConfigValue, getConfigValue =>
    getConfigValue<string[]>(CONFIG_INTERFACE_PREFERRED_LANGUAGE, []),
);

const selectPreferredInterfaceLanguage = createSelector(selectPreferredLanguageCodes, preferredLanguageCodes => {
    return preferredLanguageCodes[0] ?? DEFAULT_LANGUAGE_LOCALE;
});

const selectRootTranslationConfig = createSelector(selectGetConfigValue, getConfigValue =>
    mapTranslationConfig(getConfigValue<TranslationConfigDto>('translation')),
);

const selectTranslationLangAndPairsByCodeSelector = createSelector(selectRootTranslationConfig, translationConfig =>
    memoize((code: string) => {
        if (!translationConfig) {
            return [];
        }

        const lang = translationConfig.languages.find(l => l.code === code);
        if (!lang) {
            return [];
        }

        const pairsCodes = translationConfig.languagePairs[code];
        if (!pairsCodes) {
            return [];
        }

        const pairs = pairsCodes.map(code => translationConfig.languages.find(l => l.code === code)).filter(lang => !!lang);
        return [lang, ...pairs];
    }),
);

const selectUserLocation = createSelector(selectGetConfigValue, getConfigValue =>
    getConfigValue<{
        currentUserCountry: string;
        currentUserRegion: string;
    }>(CONFIG_USER_LOCATION),
);

const selectIsCurrentLanguageDefault = createSelector(
    selectGetConfigValue,
    getConfigValue => getConfigValue<string>(CONFIG_INTERFACE_CURRENT_LANGUAGE, 'en') === DEFAULT_LANGUAGE_LOCALE,
);

export {
    selectInterfaceLanguagesEnabled,
    selectInterfaceLanguages,
    selectPreferredLanguageCodes,
    selectInterfaceLanguageCode,
    selectTranslationLangAndPairsByCodeSelector,
    selectPreferredInterfaceLanguage,
    selectUserLocation,
    selectIsCurrentLanguageDefault,
};
