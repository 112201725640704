// eslint-env es6

import api from 'nd.services.api';
import { find, keyBy, memoize, reduce } from 'lodash';

// supported card types
// note: values should match CardTypeEnum of server side
const CREDITCARD_TYPES = Object.freeze({
    AMEX: 'Amex',
    DISCOVER: 'Discover',
    DINERS_CLUB: 'DinersClub',
    MASTERCARD: 'MasterCard',
    VISA: 'Visa',
    JCB: 'JCB',
});

const digitFormatPlaceholder = '0';

// For information about another credit card types see:
// https://www.regular-expressions.info/creditcard.html
// https://baymard.com/checkout-usability/credit-card-patterns

// length means length of numeric (non-formatted) value of card number
const defaultFormat = { length: 16, format: '0000 0000 0000 0000' };
const cardTypes = [
    {
        type: CREDITCARD_TYPES.AMEX,
        pattern: /^3[47]/,
        formats: [{ length: 15, format: '0000 000000 00000' }],
        luhn: true,
    },
    {
        type: CREDITCARD_TYPES.DINERS_CLUB,
        pattern: /^(36|38|30[0-5])/,
        formats: [{ length: 14, format: '0000 000000 0000' }],
        luhn: true,
    },
    {
        type: CREDITCARD_TYPES.DISCOVER,
        pattern: /^(6011|65|64[4-9]|622)/,
        formats: [defaultFormat],
        luhn: true,
    },
    {
        type: CREDITCARD_TYPES.JCB,
        pattern: /^35/,
        formats: [defaultFormat],
        luhn: true,
    },
    {
        type: CREDITCARD_TYPES.MASTERCARD,
        pattern: /^(5[1-5]|677189|222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
        formats: [defaultFormat],
        luhn: true,
    },
    {
        type: CREDITCARD_TYPES.VISA,
        pattern: /^4/,
        formats: [{ length: 13, format: '0000 0000 0000 0' }, defaultFormat, { length: 19, format: '0000 0000 0000 0000 000' }],
        luhn: true,
    },
];

const cardTypesMap = keyBy(cardTypes, 'type');

/**
 * Max length of allowed card number (including formatting delimiters)
 */
const MAX_FORMATTED_CARDNUMBER_LENGTH = reduce(
    cardTypes,
    (maxlen, t) => {
        const typeMaxLength = reduce(t.formats, (len, fmt) => (fmt.format.length > len ? fmt.format.length : len), 0);
        return typeMaxLength > maxlen ? typeMaxLength : maxlen;
    },
    0,
);

/**
 * Recognizes card type by card number
 * @param {String} cardNumber
 * @return {String|undefined} code of card type
 */
function recognizeCardType(cardNumber) {
    if (!cardNumber) {
        return undefined;
    }

    const cartType = find(cardTypes, t => t.pattern.test(cardNumber));
    return cartType !== undefined ? cartType.type : undefined;
}

const serviceBasePath = 'v1/creditcards';

/**
 * @typedef CreditCardType
 * @type Object
 * @property {String} name - type name
 * @property {String} cssClass - css class name
 * @property {String} image - image name
 */

/**
 * Gets list of supported card types.
 *
 * @returns {Promise.<Array.<CreditCardType>>} card types
 */
function _getSupportedCardTypes() {
    return api.get(serviceBasePath, 'cardtypes');
}

const getSupportedCardTypes = memoize(_getSupportedCardTypes);

/**
 * @typedef KeyValuePair
 * @type Object
 * @property {String} key
 * @property {String} value
 */

/**
 * Gets list of supported expiration months.
 *
 * @returns {Promise.<Array.<KeyValuePair>>} card types
 */
function _getExpirationMonths() {
    return api.get(serviceBasePath, 'expirationmonths');
}

const getExpirationMonths = memoize(_getExpirationMonths);

/**
 * Gets list of supported expiration years.
 *
 * @returns {Promise.<Array.<KeyValuePair>>} card types
 */
function _getExpirationYears() {
    return api.get(serviceBasePath, 'expirationyears');
}

const getExpirationYears = memoize(_getExpirationYears);

function luhnCheck(num) {
    const digits = String(num);
    let even = false;
    let sum = 0;
    for (let i = digits.length - 1; i >= 0; i--) {
        let digit = parseInt(digits.charAt(i), 10);
        if (even) {
            digit *= 2;
            if (digit > 9) {
                digit -= 9;
            }
        }
        sum += digit;
        even = !even;
    }

    return sum % 10 === 0;
}

const nonDigitsPattern = /\D/g;

/**
 * Checks if card number syntatically valid.
 * @param {String} cardNumber - card number (digits only)
 * @param {String} [cardType]
 * @returns {Boolean} validation result
 */
function validateCardNumber(cardNumber, cardType) {
    if (!cardNumber) {
        return false;
    }
    if (cardType === undefined) {
        cardType = recognizeCardType(cardNumber);
    }

    const type = cardTypesMap[cardType];
    return (
        type &&
        // check for length
        find(type.formats, f => cardNumber.length === f.length) !== undefined &&
        // luhn
        (!type.luhn || luhnCheck(cardNumber))
    );
}

function pickFormat(input, formats) {
    const inputLength = input.length;
    if (inputLength === 0 || formats.length === 1) {
        return formats[0];
    }

    // take the longest format
    return reduce(formats, (prev, f) => (inputLength > prev.length ? f : prev), formats[0]);
}

function formatNumber(input, mask) {
    const maskLength = mask.length;
    const inputLength = input.length;

    const formatted = [];

    let inputPos = 0;
    let formattedPos = 0;
    // all extra characters which exceeds format are stripped
    while (inputPos < inputLength && formattedPos < maskLength) {
        if (mask.charAt(formattedPos) === digitFormatPlaceholder) {
            formatted.push(input.charAt(inputPos));
            inputPos++;
        } else {
            formatted.push(mask.charAt(formattedPos));
        }
        formattedPos++;
    }
    return formatted.join('');
}

/**
 * Formats card number, like "1234 5687 9122 4558".
 * @param {String} input - digits only
 * @return {String} formatted string
 */
function formatCardNumber(input) {
    if (!input) {
        return input;
    }
    const number = input.replace(nonDigitsPattern, '');
    const cardType = recognizeCardType(number);
    const type = cardTypesMap[cardType];

    const format = type ? pickFormat(input, type.formats) : defaultFormat;
    return formatNumber(input, format.format);
}

/**
 * Converts card number into digits only string
 * @param {String} input
 * @return {String} digits string
 */
function parseCardNumber(input) {
    if (!input) {
        return input;
    }
    return input.replace(nonDigitsPattern, '');
}

/**
 * @typedef ValidationResult
 * @type Object
 * @property {Boolean} isValid
 * @property {Boolean} isYearValid
 * @property {Boolean} isMonthValid
 */
const positiveValidationResult = Object.freeze({ isValid: true, isYearValid: true, isMonthValid: true });
const invalidYearResult = Object.freeze({ isValid: false, isYearValid: false, isMonthValid: true });
const invalidMonthResult = Object.freeze({ isValid: false, isYearValid: true, isMonthValid: false });

/**
 * Validates card expiration
 * @param {Number} year
 * @param {Number} month
 * @return {ValidationResult}
 */
function validateCardExpiration(year, month) {
    year = parseInt(year, 10);
    month = parseInt(month, 10);

    const today = new Date();
    const curYear = today.getFullYear();
    if (year > curYear || (year === curYear && month >= today.getMonth() + 1)) {
        return positiveValidationResult;
    }

    return year < curYear ? invalidYearResult : invalidMonthResult;
}

export {
    recognizeCardType,
    getSupportedCardTypes,
    getExpirationMonths,
    getExpirationYears,
    validateCardNumber,
    formatCardNumber,
    parseCardNumber,
    validateCardExpiration,
    MAX_FORMATTED_CARDNUMBER_LENGTH,
};
