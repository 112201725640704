import * as ko from 'knockout';
import * as $ from 'jquery';
import { browser as ndBrowser, device as ndDevice } from '@pressreader/utils';
import '@pressreader/src/nd.event';
import '@pressreader/src/es6-shim/math.shim';
import { Deferred } from '@pressreader/src/promise/deferred';
import { cssPrefixes } from '@pressreader/types';
import { Modernizr } from '@pressreader/modernizr';

// make ko public, by default it hides itself if it detects requirejs
window.ko = window.ko || ko;

// window for browser
var root = window;

// timeout optimizations
if (!root.setImmediate) {
    root.setImmediate = function (handler, args) {
        return root.setTimeout(handler, 0, args);
    };
    root.clearImmediate = function (immediateID) {
        root.clearTimeout(immediateID);
    };
}

(function () {
    var requestAnimationFrame =
        window.requestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function (callback) {
            window.setTimeout(callback, 1000 / 60);
        };
    window.requestAnimationFrame = requestAnimationFrame;
})();

$.returnFalse = function () {
    return false;
};
$.returnTrue = function () {
    return true;
};

var _v = ndBrowser.version;
// backward compatibility
$.browser = ndBrowser;
$.device = ndDevice;

// jquery discontinued "$.props"
$.props = $.props || {};
$.props.mousewheel = 'mousewheel';
$.props.clip_del = ', ';

$.support.propagetEventsToFrames = !(ndBrowser.msie || ndBrowser.mozilla);

$.support.canvas = !!document.createElement('canvas').getContext;

$.features = {
    fTextRendering: { className: 'f-text-rendering', enabled: true },
};

// check some features supporting
// without check browser
(function () {
    // see more for feature detections
    // also we could use Modernizr
    // http://diveintohtml5.org/detect.html

    // set defaults
    $.props.transitionEndEvents = '';

    var div = document.createElement('div');

    function norm(prefix, property) {
        //camelCase??
        if (prefix && prefix.length > 0) return prefix + property;
        // make first letter lowercase
        return property.substr(0, 1).toLowerCase() + property.substr(1);
    }

    function checkTransform3dSupport() {
        // temporary: even though IE10 does support 3d transofrm, it behaves incorrectly when elements were rendered not in a visible area
        if (ndBrowser.msie) return false;
        if ($.browser.iOS && (window.navigator.userAgent || '').indexOf('OS 11_') >= 0) {
            // There's a bug in new iOS 11 similar to the one reported for IE10 (see above).
            // TODO: check if the bug still exists after iOS update.
            return false;
        }

        div.style[$.props.transform] = '';
        div.style[$.props.transform] = 'rotateY(90deg)';
        return div.style[$.props.transform] !== '';
    }

    for (var idx = 0; idx < cssPrefixes.length; idx++) {
        var normedProp;
        var prop = cssPrefixes[idx].prop;
        var css = cssPrefixes[idx].css;
        var fun = cssPrefixes[idx].fun;

        // Check Transform
        normedProp = norm(prop, 'Transform');
        if (div.style[normedProp] != undefined) {
            $.support.transform = true;
            $.props.transform = normedProp;
            $.props.transformOrigin = norm(prop, 'TransformOrigin');
            $.props.cssTransform = css + 'transform';
            $.support.transform3d = checkTransform3dSupport();
        }

        // Check Transition
        normedProp = norm(prop, 'Transition');
        if (div.style[normedProp] != undefined) {
            $.support.transition = true;
            $.props.transition = normedProp;
            $.props.transitionProperty = norm(prop, 'TransitionProperty');
            $.props.transitionDuration = norm(prop, 'TransitionDuration');
            $.props.transitionTimingFunction = norm(prop, 'TransitionTimingFunction');
            if (prop == 'ms') {
                $.props.transitionEndEvents = 'MSTransitionEnd';
            } else {
                $.props.transitionEndEvents = norm(fun, 'TransitionEnd');
            }
        }
    }

    // TEST: $.support.transform
    //$.support.transform = false;

    if ($.props.transitionEndEvents.length > 0 && $.props.transitionEndEvents != norm('', 'TransitionEnd')) {
        $.props.transitionEndEvents += ' transitionend transitionEnd';
    }

    // Check for some events
    $.support.touchEvents = 'ontouchstart' in div;
    $.support.gestureEvents = 'ongesturestart' in div;
    $.support.msTouchEvents = 'onmspointermove ' in div;
    $.support.TouchEvents = 'onpointermove ' in div;
    $.support.touch = $.support.touchEvents || $.support.TouchEvents || $.support.msTouchEvents;

    $.support.retina = (function () {
        var mediaQuery =
            '(-webkit-min-device-pixel-ratio: 1.5),\
					  (min--moz-device-pixel-ratio: 1.5),\
					  (-o-min-device-pixel-ratio: 3/2),\
					  (min-resolution: 1.5dppx)';

        if (window.devicePixelRatio > 1) return true;

        if (window.matchMedia && window.matchMedia(mediaQuery).matches) return true;

        return false;
    })();
    //$.support.retina = true;

    //$.support.transform = false;
    //$.support.transform3d = false;
    //$.support.transition = false;

    // enable gpu optimizations for all browsers besides IE
    // disable it for particular platform/version
    if ($.support.transform3d) {
        $.support.required3dTransform = !ndBrowser.msie;
        $.support.keepEmptyTransform = true;
    }

    //$.support.required3dTransform = false;
    //$.support.keepEmptyTransform = false;

    div = null;
})();

if (ndBrowser.msie) {
    if (ndBrowser.msie_mode < 8) {
        $.props.clip_del = ' '; // for old IE the commas should be omitted
    }
    if (ndBrowser.msie_mode < 9) {
        // enable html5 markup for old ie
        var e =
                'abbr,article,aside,audio,canvas,datalist,details,eventsource,figure,footer,header,hgroup,mark,menu,meter,nav,output,progress,section,time,video'.split(
                    ',',
                ),
            i = e.length;
        while (i--) {
            document.createElement(e[i]);
        }
    }

    if (_v >= '7.0') {
        $.support.imageRendering = true;
        $.props.imageRendering = 'msInterpolationMode';
        $.props.imageRenderingOptimizeSpeed = 'nearest-neighbor';
        $.props.imageRenderingOptimizeQuality = 'bicubic';
    } else {
        $.support.noGzip = true;
    }
} else if (ndBrowser.mozilla) {
    $.props.mousewheel = 'DOMMouseScroll';
    if (_v >= '1.9.2') {
        $.support.imageRendering = true;
        $.props.imageRendering = 'imageRendering';
        $.props.imageRenderingOptimizeSpeed = 'optimizeSpeed';
        $.props.imageRenderingOptimizeQuality = 'optimizeQuality';
    }
}
if (ndDevice && ndDevice.current && window.devicePixelRatio) {
    ndBrowser[ndDevice.current + 'devicePixelRatio' + window.devicePixelRatio] = true;
}
if (window.screen && (ndDevice.iPhone || ndDevice.iPad)) {
    var screens = {
        iPhone3: [480, 320],
        iPhone4: [960, 640],
        iPhone5: [1136, 640],
        iPhone6: [1334, 750],
        iPhone6plus: [1920, 1080],
        iPad: [1024, 768],
        iPad2: [1024, 768],
    };

    for (var deviceName in screens) {
        var screenSize = screens[deviceName];
        /* convert screen size to pixel size */
        var size1 = screenSize[0] / 2,
            size2 = screenSize[1] / 2,
            screenSize1 = window.screen.height,
            screenSize2 = window.screen.width;

        /* device orientation can change x and y */
        if ((size1 == screenSize1 && size2 == screenSize2) || (size1 == screenSize2 && size2 == screenSize1)) {
            ndBrowser[deviceName] = true;
        }
    }
}

$.fix = function (event) {
    return event;
};

(function () {
    var classNames = [];
    for (var name in $.features) {
        var feature = $.features[name];
        if (feature.enabled) classNames.push(feature.className);
    }
    if (!classNames.length) return;
    var className = classNames.join(' ');
    var elm = document.documentElement;
    if (!elm) elm = document.body;
    if (!elm.className) elm.className = className;
    else elm.className += ' ' + className;
})();

$.fn.extend({
    createChild: function (tagName, posOrCss) {
        if (this.length) {
            var elm = document.createElement(tagName || 'div');
            var $elm = $(elm);
            if (posOrCss) {
                if (typeof posOrCss === 'string') elm.style.position = posOrCss;
                else if (typeof posOrCss === 'object') $elm.css(posOrCss);
            }
            this[0].appendChild(elm);
            return $elm;
        }
    },
    visible: function () {
        return this[0] && this[0].offsetHeight !== 0 && this[0].offsetWidth !== 0;
    },
    relativeOffset: function (toElm) {
        return $.relativeOffset(this[0], toElm);
    },
    relativeOffsetTop: function (toElm) {
        return $.relativeOffsetTop(this[0], toElm);
    },
    relativeOffsetLeft: function (toElm) {
        return $.relativeOffsetLeft(this[0], toElm);
    },
    beforeUpdateInnerHtml: function () {
        return this;
    },
    release: function () {
        return this;
    },
    optimizeForTransform: function (flag) {
        // to optimize call: elm.optimizeForTransform();
        // to disable optimization: elm.optimizeForTransform(false);
        //var disable = flag === false || flag === "disable";

        if ($.support.required3dTransform) {
            for (var i = 0; i < this.length; i++) {
                var transform = this[i].style[$.props.transform];
                if (!transform || transform === 'none') {
                    // add dummy transform (3d) to make layer to be hardware accelerated
                    this[i].style[$.props.transform] = 'rotateY(0)';
                }
            }
        }
        return this;
    },
    optimizeShowHideForSingleElement: function (initialSate, display) {
        this._visible = initialSate;
        this._jqshow = this.show;
        this._jqhide = this.hide;
        this._display = display || '';
        this.show = this._ndshow;
        this.hide = this._ndhide;
        return this;
    },
    _ndshow: function () {
        if (!this._visible) {
            //this._jqshow();
            this.fastCss('display', this._display);
            this._visible = true;
        }
        return this;
    },
    _ndhide: function () {
        if (this._visible) {
            //this._jqhide();
            this.fastCss('display', 'none');
            this._visible = false;
        }
        return this;
    },

    keepEmptyTransformForElm: function (flag) {
        for (var i = 0; i < this.length; i++) {
            this[i]._keepEmptyTransformForElm = flag;
        }
        return this;
    },

    fastAppend: function (children) {
        if (!this.length) return this;
        if (typeof children === 'string') {
            var tmp = document.createElement('div');
            tmp.innerHTML = children;
            if (tmp.childNodes.length === 1) {
                this[0].appendChild(tmp.firstChild);
                return this;
            }
            var fr = document.createDocumentFragment();
            var child = tmp.firstChild;
            while (child) {
                fr.appendChild(child);
                child = tmp.firstChild; //don't use "child.nextSibling", it will be null because the child was removed from parent
            }
            this[0].appendChild(fr);
            return this;
        }
        if (children.length == 1) {
            this[0].appendChild(children[0]);
        } else {
            var fr = document.createDocumentFragment();
            for (var i = 0; i < children.length; i++) {
                fr.appendChild(children[i]);
            }
            this[0].appendChild(fr);
        }
        return this;
    },
    fastAppendTo: function (parent) {
        parent.fastAppend(this);
        return this;
    },
    fastDetach: function () {
        if (arguments.length > 0) return this.detach.apply(this, arguments);
        var children = this;
        for (var i = 0; i < children.length; ++i) {
            var child = children[i];
            if (child.parentNode) {
                child.parentNode.removeChild(child);
            }
        }
        return this;
    },
    fastRemove: function () {
        if (arguments.length > 0) return this.remove.apply(this, arguments);
        this.fastDetach();
        this.length = 0;
        return this;
    },
    fastHtml: function (html) {
        for (var i = 0; i < this.length; i++) {
            this[i].innerHTML = html;
        }
    },
    fastCss: function (name, val, check) {
        if (!check) {
            for (var i = 0; i < this.length; i++) {
                $.fastCss(this[i], name, val);
            }
        } else {
            for (var i = 0; i < this.length; i++) {
                $.simpleCssAndCheck(this[i], name, val);
            }
        }

        return this;
    },
    simpleCssAndCheck: function (name, val) {
        for (var i = 0; i < this.length; i++) {
            $.simpleCssAndCheck(this[i], name, val);
        }
        return this;
    },
});

$.extend({
    simpleCssAndCheck: function (elm, name, val) {
        var old = elm.style[name];
        if (old !== val) elm.style[name] = val;
    },
    fastCss: function (elm, name, val) {
        elm.style[name] = val;
    },
    fastHtml: function (html) {
        //return $(html);
        var children = [];
        var p = document.createElement('div');
        p.innerHTML = html;
        var child = p.firstChild;
        while (child) {
            children.push(child);
            child = child.nextSibling;
        }
        return $(children);
    },
    windowWidth: function () {
        // for webkit use window.innerWidth, $(window).width() returns wrong value after rotation (iphone, ipad)
        //return $.browser.webkit ? window.innerWidth : $(window).width();
        // updated fix with iOs 9 release: window.innerWidth returns old value after rotation
        return $(window).width();
    },
    windowHeight: function () {
        // for webkit use window.innerHeight, $(window).height() returns wrong value after rotation (iphone, ipad)
        return ndBrowser.webkit ? window.innerHeight : $(window).height();
    },
    relativeOffset: function (fromElm, toElm) {
        // toElm - should be parent of current node, could be jquery object or dom node
        var offsetTop = 0,
            offsetLeft = 0;
        if (fromElm && toElm) {
            if (toElm.jquery) toElm = toElm[0];
            if (fromElm.jquery) fromElm = fromElm[0];
            if (fromElm && toElm) {
                var p = fromElm;
                var toElmParent = toElm.offsetParent;
                if (p == toElmParent) {
                    offsetTop -= toElm.offsetTop;
                    offsetLeft -= toElm.offsetLeft;
                } else {
                    while (p && p != toElm) {
                        offsetTop += p.offsetTop;
                        offsetLeft += p.offsetLeft;
                        p = p.offsetParent;
                        if (p && p == toElmParent) {
                            // remove extra offset
                            offsetTop -= toElm.offsetTop;
                            offsetLeft -= toElm.offsetLeft;
                            break;
                        }
                    }
                }
            }
        }
        return { left: offsetLeft, top: offsetTop };
    },
    relativeOffsetTop: function (fromElm, toElm) {
        return this.relativeOffset(fromElm, toElm).top;
    },
    relativeOffsetLeft: function (fromElm, toElm) {
        return this.relativeOffset(fromElm, toElm).left;
    },
    execInTryCatch: function (func, defaultVal) {
        try {
            return func();
        } catch (E) {}
        return defaultVal;
    },
});

function _clip(val) {
    return typeof val == 'number' ? val + 'px' : typeof val == 'string' && val.length > 0 ? val : 'auto';
}

var nd = ($.nd = $.nd || {}),
    _id = 0;
$.extend(nd, {
    animationEmptyStep: { empty: '100px' },
    id: function () {
        return _id++;
    },
    makeImageUrl: function (name) {
        return this.imagesBaseUrl() + name;
    },
    hostName: function () {
        return window.NDHostName || window.location.hostname;
    },
    scriptsBaseUrl: function () {
        return window.NDScriptsUrl || '';
    },
    imagesBaseUrl: function () {
        return window.NDImagesUrl || (window.basePath || '') + 'images/';
    },
    isEmpty: function (s) {
        return !s || !s.length;
    },
    makeClip: function (top, right, bottom, left) {
        var d = $.props.clip_del;
        return 'rect(' + _clip(top) + d + _clip(right) + d + _clip(bottom) + d + _clip(left) + ')';
    },
    extendObjectWithEvents: function (obj, names) {
        if (typeof names === 'string') {
            names = names.split(',');
        }
        names.forEach(eventName => {
            const fireName = `fire${eventName.substring(0, 1).toUpperCase()}${eventName.substring(1)}`;
            obj[eventName] = (...args) => {
                const [data, fn] = args;
                if (args.length) {
                    $.nd.event.bind(obj, eventName, data, fn);
                } else {
                    $.nd.event.trigger($.nd.event.makeEvent(null, eventName), obj, null);
                }
                return obj;
            };

            obj[fireName] = (...args) => {
                const [e, data] = args;
                $.nd.event.trigger($.nd.event.makeEvent(e, eventName), obj, data);
                return obj;
            };
        });
    },
    extendObjectWithProperties: function (obj, names, enableGet, enableSet, enableChangeEvent) {
        if (arguments.length == 2) {
            // set default
            enableGet = true;
            enableSet = true;
            enableChangeEvent = false;
        }
        this.extendObjectWithPropertiesExt({
            obj: obj,
            names: names,
            enableGet: enableGet,
            enableSet: enableSet,
            enableChangeEvent: enableChangeEvent,
        });
    },
    extendObjectWithPropertiesExt: function (cfg) {
        var obj = cfg.obj,
            names = cfg.names,
            enableGet = cfg.enableGet,
            enableSet = cfg.enableSet,
            enableChangeEvent = cfg.enableChangeEvent,
            enableChangedEvent = cfg.enableChangedEvent;

        // use "Changed" instead of "Change"
        if (enableChangedEvent) enableChangeEvent = false;

        if (typeof names == 'string') names = names.split(',');

        // make compatible with jquery ie use space as separator
        if (names.length === 1) names = names[0].split(' ');

        for (var i = 0; i < names.length; i++) {
            var name = names[i],
                eventName = name + (enableChangedEvent ? 'Changed' : 'Change'),
                fireName = 'fire' + eventName.substring(0, 1).toUpperCase() + eventName.substring(1),
                fieldName = '_' + name;

            if (enableGet && enableSet) {
                if (enableChangeEvent || enableChangedEvent) {
                    obj[name] = new Function(
                        'val',
                        'if(arguments.length==0)return this.field; var old=this.field; if(old!=val){this.field=val;this.fire(old,val);}return this;'
                            .replace(/field/g, fieldName)
                            .replace(/fire/g, fireName),
                    );
                    obj[eventName] = new Function(
                        'data',
                        'fn',
                        '$.nd.event.bind(this,"eventName",data,fn);return this;'.replace(/eventName/g, eventName),
                    );
                    obj[fireName] = new Function(
                        'oldVal',
                        'val',
                        'var e=$.Event("eventName");e.value=val;e.oldValue=oldVal;$.nd.event.trigger(e,this);return this;'.replace(
                            /eventName/g,
                            eventName,
                        ),
                    );
                } else
                    obj[name] = new Function(
                        'val',
                        'if(arguments.length==0)return this.field;this.field=val;return this;'.replace(/field/g, fieldName),
                    );
            } else if (enableGet) obj[name] = new Function('return this.field;'.replace(/field/g, fieldName));
            else if (enableSet) obj[name] = new Function('val', 'this.field=val;return this;'.replace(/field/g, fieldName));
        }
    },
    proxy: function (target, fun) {
        function _prxy() {
            fun.apply(target, arguments);
        }
        return _prxy;
    },
    now: function () {
        return new Date().getTime();
    },
    isParentNodeNull: function (node) {
        // if the node is not added to dom the parentNode will be null for all browsers except old ie (below 9)
        // in ie the parentNode will have nodeType == 1 (fragment)
        var p = node.parentNode;
        return !p || p.nodeType == 11;
    },
    img_onerror: function (img) {
        try {
            if (!img || !img.src) img = this;
            if (img && img.src && img.src.indexOf('?') > 0) {
                if (!img.errors) img.errors = 0;
                if (img.errors < 5) {
                    ++img.errors;
                    if (!img.original_src) img.original_src = img.src;
                    _.defer(
                        function (img, src) {
                            // changing src directly from onerror handler prevents onerror event to trigger in the future
                            img.src = src;
                        },
                        img,
                        img.original_src + '&error=' + img.errors,
                    );
                }
            }
        } catch (E) {}
    },
});
$.nd.data = {};

$.fx.step['empty'] = $.noop;

function adjustNumber(val) {
    var eps = 0.000001;
    var rounded = Math.round(val);
    return Math.abs(val - rounded) < eps ? rounded : val;
}

// see http://ricostacruz.com/jquery.transit/source.html
// it has some nice functions to work with matrix including transform string parsing
$.nd.matrix = function (val) {
    /// <summary>
    /// Parse string 'matrix(A,B,C,D,X,Y)'
    /// </summary>
    if (typeof val === 'string') {
        // this matrix doesn't support 3d transform
        // however it could parse 3d matrix if it doesn't use third dimension

        if (val.length > 0 && val !== 'none') {
            if (val.indexOf('matrix3d') >= 0) {
                // we have 3d matrix
                var m = /\(([^\)]*)?/.exec(val)[1].split(',');

                // each column has 4 rows
                var offset = 0;
                this.m11 = parseFloat(m[offset + 0]);
                this.m21 = parseFloat(m[offset + 1]);
                offset += 2; // exclude two rows of first column
                this.m12 = parseFloat(m[offset + 2]);
                this.m22 = parseFloat(m[offset + 3]);
                offset += 2; // exclude two rows of second column
                offset += 4; // exclude third column
                this.tx = parseInt(m[offset + 4], 10);
                this.ty = parseInt(m[offset + 5], 10);

                this._scaleX = adjustNumber(Math.sqrt(this.m11 * this.m11 + this.m12 * this.m12));
                this._scaleY = adjustNumber(Math.sqrt(this.m21 * this.m21 + this.m22 * this.m22));
                this._scaleZ = 1;

                if (this.m22 !== 0) this._rotate = adjustNumber(Math.atan(this.m21 / this.m22) * 57.2957795); //rad to deg
            } else {
                var m = /\(([^,]*),([^,]*),([^,]*),([^,]*),([^,p]*)(?:px)?,([^)p]*)(?:px)?/.exec(val);
                this.m11 = parseFloat(m[1]);
                this.m21 = parseFloat(m[2]);
                this.m12 = parseFloat(m[3]);
                this.m22 = parseFloat(m[4]);
                this.tx = parseInt(m[5], 10);
                this.ty = parseInt(m[6], 10);

                this._scaleX = adjustNumber(Math.sqrt(this.m11 * this.m11 + this.m12 * this.m12));
                this._scaleY = adjustNumber(Math.sqrt(this.m21 * this.m21 + this.m22 * this.m22));
                this._scaleZ = 1;

                if (this.m22 !== 0) this._rotate = adjustNumber(Math.atan(this.m21 / this.m22) * 57.2957795); //rad to deg
            }
        }
    }
};
$.nd.matrix.create = function (val) {};
$.nd.matrix.toTranslateString = function (tx, ty, tz) {
    // if tz is null => disable 3d
    var disable3d = tz === null;
    var use3d = arguments.length === 3;
    tx = tx || 0;
    ty = ty || 0;
    tz = tz || 0;
    if (!disable3d && ($.support.required3dTransform || (use3d && $.support.transform3d))) {
        return 'translate3d(' + tx + 'px,' + ty + 'px,' + tz + 'px)';
    }
    return 'translate(' + tx + 'px,' + ty + 'px)';
};
$.nd.matrix.toScaleString = function (tx, ty, tz) {
    var disable3d = tz === null;
    var use3d = arguments.length === 3;
    if (arguments.length == 1) {
        ty = tx;
    }
    tx = tx || 1;
    ty = ty || 1;
    tz = tz || 1;
    if (!disable3d && ($.support.required3dTransform || (use3d && $.support.transform3d))) {
        return 'scale3d(' + tx.toFixed(10) + ',' + ty.toFixed(10) + ',' + tz.toFixed(10) + ')';
    }
    return 'scale(' + tx.toFixed(10) + ',' + ty.toFixed(10) + ')';
};
$.nd.matrix.prototype = {
    m11: 1,
    m12: 0,
    //m13: 0, //tx for 2d
    //m14: 0, //tx for 3d

    m21: 0,
    m22: 1,
    //m23: 0, //ty for 2d
    //m24: 0, //ty for 3d

    //m31: 0,
    //m32: 0,
    //m33: 1,
    //m34: 0, //tz for 3d

    //m41: 0, // const for 3d?
    //m42: 0, // const for 3d?
    //m43: 0, // const for 3d?
    //m44: 1, // const for 3d?

    tx: 0,
    ty: 0,
    //tz: 0,

    _scaleX: 1,
    _scaleY: 1,
    _scaleZ: 1,
    _rotate: 0,

    scale: function (val) {
        if (arguments.length == 0) return { scaleX: this._scaleX, scaleY: this._scaleY, scaleZ: this._scaleZ };
        this._scaleX = val.scaleX || 1;
        this._scaleY = val.scaleY || 1;
        this._scaleZ = val.scaleZ || 1;
        return this;
    },
    scaleX: function (val) {
        if (arguments.length == 0) return this._scaleX;
        this._scaleX = parseFloat(val) || 1;
        return this;
    },
    scaleY: function (val) {
        if (arguments.length == 0) return this._scaleY;
        this._scaleY = parseFloat(val) || 1;
        return this;
    },
    translate: function (val) {
        if (arguments.length == 0) return { left: this.tx, top: this.ty };
        this.tx = val.left || val.tx || 0;
        this.ty = val.top || val.ty || 0;
        return this;
    },
    translateLeft: function (val) {
        if (arguments.length == 0) return this.tx;
        this.tx = parseInt(val, 10) || 0;
        return this;
    },
    translateTop: function (val) {
        if (arguments.length == 0) return this.ty;
        this.ty = parseInt(val, 10) || 0;
        return this;
    },
    toTranslateString: function (use3d) {
        return this.toString();
        //if (!$.support.keepEmptyTransform && this.isEmpty())
        //	return "none";
        //if ((use3d && $.support.transform3d) || (use3d !== false && $.support.required3dTransform)) {
        //	// use 3d tansform for iphone
        //	return 'translate3d(' + this.tx + 'px,' + this.ty + 'px,0)';
        //}
        //return 'translate(' + this.tx + 'px,' + this.ty + 'px)';
    },
    toMatrixString: function () {
        //if (!$.support.keepEmptyTransform && this.isEmpty())
        //	return "none";
        //javascript:alert((function(){var m = new WebKitCSSMatrix(); m.m00 = 100;m.m01 = 101;m.m02 = 102;; return m;})())
        //javascript:alert((function(){var m = new WebKitCSSMatrix(); m = m.translate(10, 20, 30); return m.toString();})())
        //javascript:alert((function(){var m = new WebKitCSSMatrix("matrix(0.009,0.10,0.01,0.11,100,200)"); m = m.translate(10, 20, 30); return m.toString();})())
        /*
        if ((use3d && $.support.transform3d) || (use3d !== false && $.support.required3dTransform)) {
        return 'matrix('
        + this.m11 + ',' + this.m21 + ',' + this.m31 + ',' + this.m41 + ','
        + this.m12 + ',' + this.m22 + ',' + this.m32 + ',' + this.m42 + ','
        + this.m13 + ',' + this.m23 + ',' + this.m33 + ',' + this.m43 + ','
        // it is translation
        + this.m14 + ',' + this.m24 + ',' + this.m34 + ',' + this.m44 + ','
        + this.tx + ',' + this.ty
        + ')';
        }
        */
        //return 'matrix(' + this.m11 + ',' + this.m21 + ',' + this.m12 + ',' + this.m22 + ',' + this.tx + ',' + this.ty + ')';
        return this.toString();
    },
    toString: function () {
        if (!$.support.keepEmptyTransform && this.isEmpty()) return 'none';

        var str = '';
        if (this.tx !== 0 || this.ty !== 0) str += ' ' + $.nd.matrix.toTranslateString(this.tx, this.ty);

        if (this._scaleX !== 1 || this._scaleY !== 1) str += ' ' + $.nd.matrix.toScaleString(this._scaleX, this._scaleY);

        if (this._rotate !== 0) str += ' rotate(' + this._rotate.toFixed(10) + 'deg)';

        return str;
    },
    isEmpty: function () {
        return this.tx === 0 && this.ty === 0 && this.m11 === 1 && this.m12 === 0 && this.m21 === 0 && this.m22 === 1;
    },
};

///////////////////////////////////////////////
// Define translate, translateLeft and translateTop
// this methods will use transformation if it is supported by browser
// otherwise left and top
//
// Animation:
// translateLeft and translateTop - could be used in animation
//
// Examples:
// elm.translateLeft(10);
// elm.css("translateLeft",10);
// var left = elm.translateLeft();
// var pos = elm.translate();
// var left = pos.left;
// var top = pos.top;
// elm.translate({left: 10, top: 20});
// elm.translate(10, 20);
(function () {
    $.cssHooks['transform'] = $.cssHooks[$.props.transform] = {
        set: function (elm, value) {
            // reset cached matrix
            elm._matrix = null;

            if ($.support.required3dTransform) {
                // override "transform" to
                // update 2d transform string to 3d version

                if (value === 'none' || value === '') {
                    if (!$.support.keepEmptyTransform || elm._keepEmptyTransformForElm === false) return 'none';

                    // add rotateY to make it 3d transform, it will not affect other transformations
                    // but will force browser to use gpu
                    value = 'rotateY(0)';
                }
            }

            // return the value to make jquery apply it to element
            return value;
        },
    };

    var transformProperties = ['translate', 'translateLeft', 'translateTop', 'scale', 'scaleX', 'scaleY'];
    $.each(transformProperties, function (idx, name, extra) {
        // animation
        if (name === 'translateLeft' || name === 'translateTop' || name === 'scaleX' || name === 'scaleY') {
            $.fx.step[name] = function (fx) {
                $.cssHooks[name].set(fx.elem, fx.now + fx.unit);
            };
        }
        if ($.support.transform) {
            $.cssHooks[name] = {
                get: function (elm) {
                    var matrix = new $.nd.matrix($.css(elm, 'transform'));
                    elm._matrix = matrix;
                    return matrix[name]();
                },
                set: function (elm, value, useExistsMatrix) {
                    if (useExistsMatrix === true) var matrix = elm._matrix || new $.nd.matrix();
                    else var matrix = new $.nd.matrix($.css(elm, 'transform'));
                    // update value
                    matrix[name](value);
                    // apply changes
                    $.style(elm, $.props.transform, matrix.toString());
                    //elm.style[$.props.transform] = matrix.toString();
                    elm._matrix = matrix;
                },
            };
        } else {
            if (name.indexOf('translate') == 0) {
                // use position for translate
                $.cssHooks[name] = {
                    get: function (elm) {
                        var matrix = new $.nd.matrix();
                        // update value
                        matrix['translate']($(elm).position());
                        return matrix[name]();
                    },
                    set: function (elm, value) {
                        if (name === 'translateLeft') {
                            $(elm).css('left', value);
                        } else if (name === 'translateTop') {
                            $(elm).css('top', value);
                        } else {
                            $(elm).css({
                                left: value.left,
                                top: value.top,
                            });
                        }
                    },
                };
            } else {
                // not supported
                $.cssHooks[name] = {
                    get: function () {
                        var matrix = new $.nd.matrix();
                        return matrix[name]();
                    },
                    set: function () {},
                };
            }
        }
    });

    $.each(['translate', 'translateLeft', 'translateTop'], function (idx, name, extra) {
        $.fn[name + 'Optimized'] = function (val) {
            return this.each(function (idx, elm) {
                $.cssHooks[name].set(elm, val, /*useExistsMatrix*/ true);
            });
        };
    });

    $.fn.extend({
        translateLeft: function (val, optimize) {
            if (arguments.length > 0) {
                if (optimize) {
                    return this.translateLeftOptimized(val);
                    //if ($.support.transform) {
                    //    return this.each(function(idx,elm) {
                    //            $.cssHooks["translateLeft"].set(elm, val,/*useExistsMatrix*/ true);
                    //    });
                    //} else {
                    //    return this.fastCss("left", val);
                    //}
                }
                return this.css('translateLeft', val);
            }
            return this.css('translateLeft');
        },
        translateTop: function (val, optimize) {
            if (arguments.length > 0) {
                if (optimize) {
                    return this.translateTopOptimized(val);
                }
                return this.css('translateTop', val);
            }
            return this.css('translateTop');
        },
        translate: function (left, top, optimize) {
            if (arguments.length > 0) {
                var val = { left: left, top: top };
                if (optimize) {
                    return this.translateOptimized(val);
                }
                return this.css('translate', val);
            }
            return this.css('translate');
        },
        cssBatch: function (css) {
            var transformCss = {};
            var otherCss = {};
            _.each(css, function (value, key, list) {
                if (_.indexOf(transformProperties, key) < 0) {
                    otherCss[key] = value;
                } else {
                    transformCss[key] = value;
                }
            });
            if (!$.isEmptyObject(transformCss)) {
                // apply transform in single call
                // it is required while animation with transition
                this.each(function (idx, elm) {
                    try {
                        var matrix = new $.nd.matrix($.css(elm, 'transform'));
                        // update value
                        _.each(transformCss, function (value, key, list) {
                            matrix[key](value);
                        });
                        // apply changes
                        $.style(elm, $.props.transform, matrix.toString());
                    } catch (E) {
                        $.writeError.error(E);
                    }
                });
            }
            if (!$.isEmptyObject(otherCss)) this.css(otherCss);
            return this;
        },
        resetTransition: function () {
            // use different version of values to reset transition
            // to make work in different browsers
            this.css('transition', 'none 0'); // in some browsers it is required to add 0
            this.css('transition', 'none'); // in firefox we need put just "none" otherwise it will not work
            return this;
        },
    });

    //jQuery.fn.stop = function (clearQueue, gotoEnd) {
    //  return originalAnimateMethod.apply(this, [clearQueue, gotoEnd, leaveTransforms]);
    //};

    // use original jQuery.animate to animate following properties
    // because css animation doesn't work for html properties animations
    var disableNdAnimationForProperties = ['scrollTop', 'scrollLeft'];
    function isDisabledNdAnimationForProperty(propertyObject) {
        for (var i = 0; i < disableNdAnimationForProperties.length; i++) {
            if (disableNdAnimationForProperties[i] in propertyObject) {
                return true;
            }
        }
        return false;
    }
    var enbaleNdAnimation = true;
    if (enbaleNdAnimation && $.support.transition) {
        (function ($, originalAnimateMethod, originalStopMethod) {
            var DATA_KEY = 'nd_an',
                CUBIC_BEZIER_OPEN = 'cubic-bezier(',
                CUBIC_BEZIER_CLOSE = ')',
                easings = {
                    bounce: CUBIC_BEZIER_OPEN + '0.0, 0.35, .5, 1.3' + CUBIC_BEZIER_CLOSE,
                    linear: 'linear',
                    swing: 'ease-in-out',

                    // Penner equation approximations from Matthew Lein's Ceaser: http://matthewlein.com/ceaser/
                    easeInQuad: CUBIC_BEZIER_OPEN + '0.550, 0.085, 0.680, 0.530' + CUBIC_BEZIER_CLOSE,
                    easeInCubic: CUBIC_BEZIER_OPEN + '0.550, 0.055, 0.675, 0.190' + CUBIC_BEZIER_CLOSE,
                    easeInQuart: CUBIC_BEZIER_OPEN + '0.895, 0.030, 0.685, 0.220' + CUBIC_BEZIER_CLOSE,
                    easeInQuint: CUBIC_BEZIER_OPEN + '0.755, 0.050, 0.855, 0.060' + CUBIC_BEZIER_CLOSE,
                    easeInSine: CUBIC_BEZIER_OPEN + '0.470, 0.000, 0.745, 0.715' + CUBIC_BEZIER_CLOSE,
                    easeInExpo: CUBIC_BEZIER_OPEN + '0.950, 0.050, 0.795, 0.035' + CUBIC_BEZIER_CLOSE,
                    easeInCirc: CUBIC_BEZIER_OPEN + '0.600, 0.040, 0.980, 0.335' + CUBIC_BEZIER_CLOSE,
                    easeOutQuad: CUBIC_BEZIER_OPEN + '0.250, 0.460, 0.450, 0.940' + CUBIC_BEZIER_CLOSE,
                    easeOutCubic: CUBIC_BEZIER_OPEN + '0.215, 0.610, 0.355, 1.000' + CUBIC_BEZIER_CLOSE,
                    easeOutQuart: CUBIC_BEZIER_OPEN + '0.165, 0.840, 0.440, 1.000' + CUBIC_BEZIER_CLOSE,
                    easeOutQuint: CUBIC_BEZIER_OPEN + '0.230, 1.000, 0.320, 1.000' + CUBIC_BEZIER_CLOSE,
                    easeOutSine: CUBIC_BEZIER_OPEN + '0.390, 0.575, 0.565, 1.000' + CUBIC_BEZIER_CLOSE,
                    easeOutExpo: CUBIC_BEZIER_OPEN + '0.190, 1.000, 0.220, 1.000' + CUBIC_BEZIER_CLOSE,
                    easeOutCirc: CUBIC_BEZIER_OPEN + '0.075, 0.820, 0.165, 1.000' + CUBIC_BEZIER_CLOSE,
                    easeInOutQuad: CUBIC_BEZIER_OPEN + '0.455, 0.030, 0.515, 0.955' + CUBIC_BEZIER_CLOSE,
                    easeInOutCubic: CUBIC_BEZIER_OPEN + '0.645, 0.045, 0.355, 1.000' + CUBIC_BEZIER_CLOSE,
                    easeInOutQuart: CUBIC_BEZIER_OPEN + '0.770, 0.000, 0.175, 1.000' + CUBIC_BEZIER_CLOSE,
                    easeInOutQuint: CUBIC_BEZIER_OPEN + '0.860, 0.000, 0.070, 1.000' + CUBIC_BEZIER_CLOSE,
                    easeInOutSine: CUBIC_BEZIER_OPEN + '0.445, 0.050, 0.550, 0.950' + CUBIC_BEZIER_CLOSE,
                    easeInOutExpo: CUBIC_BEZIER_OPEN + '1.000, 0.000, 0.000, 1.000' + CUBIC_BEZIER_CLOSE,
                    easeInOutCirc: CUBIC_BEZIER_OPEN + '0.785, 0.135, 0.150, 0.860' + CUBIC_BEZIER_CLOSE,
                },
                rfxnum = /^([+-]=)?([\d+-.]+)(.*)$/,
                canceledAnimationObject = {};

            $.fn.animate = function (prop, speed, easing, callback) {
                if ((speed && typeof speed === 'object' && 'step' in speed) || isDisabledNdAnimationForProperty(prop)) {
                    // disable if step is using
                    return originalAnimateMethod.apply(this, arguments);
                }

                var optall = jQuery.speed(speed, easing, callback),
                    elements = this,
                    callbackQueue = 0,
                    validationTimeout = 0,
                    duration = optall.duration,
                    isCanceled = false,
                    isComplete = false,
                    fireComplete = function () {
                        if (validationTimeout) {
                            clearTimeout(validationTimeout);
                            validationTimeout = 0;
                        }

                        if (!isCanceled) {
                            // we're done, trigger the user callback
                            if (elements) {
                                if (typeof optall.complete === 'function') {
                                    //optall.complete.apply(elements[0], arguments);
                                    elements.each(optall.complete, [false]);
                                }
                            } else {
                                $.writeLog('second fireAnimation');
                            }
                        }
                        elements = null;
                        isComplete = true;
                    },
                    cancelAnimation = function () {
                        $.writeLog('Animation canceled');
                        // ensure events will not fired
                        isCanceled = true;
                        // clear some code
                        fireComplete();
                    },
                    propertyCallback = function (arg) {
                        if (arg === canceledAnimationObject) {
                            cancelAnimation();
                            return;
                        }
                        callbackQueue--;
                        if (callbackQueue === 0) {
                            fireComplete();
                        }
                    },
                    setupValidationTimeout = function (beforeCompleteCallback) {
                        if (validationTimeout) {
                            // cancel previous timeout
                            clearTimeout(validationTimeout);
                            validationTimeout = 0;
                        }
                        // real transition duration could be different with configured one, so set timeout a bit later
                        var timeoutInterval = (duration + 10) * 2;
                        // make sure complete will be called
                        validationTimeout = setTimeout(function () {
                            validationTimeout = 0;
                            // some browsers call this function just before endAnimation
                            // so call in next frame to allow endAnimation event to be fired
                            setImmediate(function () {
                                if (!isComplete) {
                                    if (beforeCompleteCallback) {
                                        beforeCompleteCallback();
                                    }
                                    if (!isComplete) {
                                        fireComplete();
                                    }
                                }
                            });
                        }, timeoutInterval);
                    };

                this[optall.queue === true ? 'queue' : 'each'](function () {
                    var self = jQuery(this),
                        opt = jQuery.extend({}, optall),
                        cssEasing = easings[opt.easing || 'swing'] ? easings[opt.easing || 'swing'] : opt.easing || 'swing';
                    var copy = {};
                    var hidden = self.is(':hidden');
                    for (var i in prop) {
                        if (i === 'leaveTransforms') continue;
                        var val = prop[i],
                            parts = rfxnum.exec(val);

                        if (!parts) {
                            if (i == 'opacity') {
                                if (val == 'show' && hidden) {
                                    self.show().css('opacity', 0);
                                    val = 1;
                                    hidden = false;
                                } else if (val == 'hide') {
                                    if (hidden) continue; // ignore current animation

                                    self.css('opacity', 1);
                                    val = 0;
                                }
                            }
                        } else {
                            var end = parseFloat(parts[2]);

                            // If a +=/-= token was provided, we're doing a relative animation
                            if (parts[1]) {
                                var start = $.css(this, i);
                                end = (parts[1] === '-=' ? -1 : 1) * end + parseFloat(start);
                            }
                            val = end;
                        }
                        copy[i] = val;
                    }

                    if (hidden) return true; // do not animate hidden object

                    if ($.isEmptyObject(copy)) return true; // there is nothing to animate

                    function finishAnimation() {
                        if (!self) {
                            return;
                        }

                        self.unbind($.props.transitionEndEvents, data.handler);
                        self.resetTransition();

                        // if we used the fadeOut shortcut make sure elements are display:none
                        if (prop.opacity === 'hide') {
                            self.hide().css('opacity', '');
                        } else if (prop.opacity === 'show') {
                            self.css('opacity', '');
                        }

                        // reset
                        self.data(DATA_KEY, null);

                        // run the main callback function
                        propertyCallback.call(self);

                        self = null;
                    }

                    var data = {
                        prop: prop,
                        handler: function (event) {
                            if (event === canceledAnimationObject) {
                                // call from stop method
                                cancelAnimation();
                                self = null;
                                return;
                            }

                            if (!self) {
                                return;
                            }

                            // Make sure has valid event: webkit fire transitionEndEvent
                            // if animation is finished in any child node even the current animation is active
                            // so check that target is the same
                            // don't forget to add event to "cssCallback = function () {", should be like this ""cssCallback = function (event) {""
                            if (self[0] !== event.target) {
                                return;
                            }

                            finishAnimation();

                            self = null;
                        },
                    };

                    if (!opt.duration) {
                        self.cssBatch(copy);
                        return true;
                    }

                    callbackQueue++;
                    self.data(DATA_KEY, data).css('transition', 'all ' + opt.duration + 'ms ' + cssEasing);

                    // has to be done in a timeout to ensure transition properties are set
                    setImmediate(function () {
                        // ensure the animation is not canceled
                        if (self && self.data(DATA_KEY)) {
                            // the time since previous setup could be could be comparable to animation duration
                            // so resetup timeout to make sure it will happen after animation finish
                            setupValidationTimeout(finishAnimation);
                            self.bind($.props.transitionEndEvents, data.handler).cssBatch(copy);
                        }
                    });
                    return true;
                });

                //what about optall.queue??
                if (callbackQueue === 0) {
                    // nothing is animating
                    // so fire complete animation
                    setImmediate(fireComplete);
                } else {
                    setupValidationTimeout();
                }

                return this;
            };

            $.fn.stop = function (clearQueue, gotoEnd) {
                if (clearQueue) this.queue([]);

                this.each(function () {
                    var self = jQuery(this),
                        data = self.data(DATA_KEY);

                    if (!data) {
                        // dom transition
                        originalStopMethod.apply(self, [clearQueue, gotoEnd]);
                    } else {
                        var restore = {};
                        if (!gotoEnd) {
                            // restore current values
                            for (var i in data.prop) {
                                restore[i] = $.css(this, i);
                            }
                        }

                        self.unbind($.props.transitionEndEvents, data.handler).resetTransition().css(restore).data(DATA_KEY, null);

                        data.handler(canceledAnimationObject);
                    }
                });
            };
        })($, $.fn.animate, $.fn.stop);
    } else {
        (function ($, originalAnimateMethod, originalStopMethod) {
            // override  animation methods
            // to make work jquery.animate-enhanced.js with our css properties (translateLeft and translateTop)

            // the jquery.animate-enhanced.js works if both
            // transition and transform are supported
            // however it is not support ie 10 yet
            var isEnhancedAnimationPluginSupported =
                $.support.transition && $.support.transform && !ndBrowser.ms && $.fn.animate_enhanced !== undefined;

            $.fn.animate = function (prop, speed, easing, callback) {
                var useEnhancedAnimation = false;

                if (isEnhancedAnimationPluginSupported && arguments.length > 0) {
                    var opt = speed && typeof speed === 'object' ? speed : {};

                    useEnhancedAnimation = true;

                    if ('step' in opt) {
                        // disable if step is using
                        useEnhancedAnimation = false;
                    }
                }

                if (useEnhancedAnimation) {
                    var copy = {};

                    var use3d = prop.useTranslate3d;
                    if (use3d != undefined) {
                        // remove prev value
                        delete prop['useTranslate3d'];
                    }

                    use3d = (use3d && $.support.transform3d) || (use3d !== false && $.support.required3dTransform);

                    if (use3d) {
                        copy.useTranslate3d = use3d;
                    }

                    for (var name in prop) {
                        if (copy[name] === undefined && prop[name] !== undefined) {
                            var newName = name;
                            if (name === 'translateLeft') {
                                newName = 'left';
                            } else if (name === 'translateTop') {
                                newName = 'top';
                            }
                            copy[newName] = prop[name];
                        }
                    }
                    arguments[0] = copy;

                    return $.fn.animate_enhanced.apply(this, arguments);
                    //return originalAnimateMethod.apply(this, arguments);
                } else {
                    if (prop && 'leaveTransforms' in prop) delete prop['leaveTransforms'];
                }
                return originalAnimateMethod.apply(this, arguments);
            };

            // required for jquery.animate-enhanced.js
            $.fn.animate.defaults = originalAnimateMethod.defaults || {};
        })($, $.fn.animate, $.fn.stop);
    }
})();
// Define translateLeft and translateTop
///////////////////////////////////////////////

//////////////////////////////////////
// Delegate
(function () {
    function getItems(obj, delegate) {
        if (obj && delegate) {
            var objData = $.data(obj);
            if (objData) {
                var items = objData[delegate._id];
                if (!items) {
                    items = objData[delegate._id] = [];
                }
                return items;
            }
        }
        return null;
    }
    function add(obj, delegate, method) {
        if (method) {
            var items = getItems(obj, delegate);
            if (items) {
                items.push(method);
            }
        }
    }
    function remove(obj, delegate, method) {
        if (method) {
            var items = getItems(obj, delegate);
            if (items) {
                for (var i = 0; i < items.length; ++i) {
                    if (items[i] === method) {
                        items.splice(i, 1);
                    }
                }
            }
        }
    }
    function fire(obj, delegate, args) {
        var items = getItems(obj, delegate);
        for (var i = 0; i < items.length; ++i) {
            items[i].apply(obj, args);
        }
    }

    $.nd.delegateProxy = function (proxy, delegate) {
        return function () {
            if (arguments.length === 1 && typeof arguments[0] === 'function') add(proxy, delegate, arguments[0]);
            else fire(proxy, delegate, arguments);
            return this;
        };
    };
    $.nd.delegate = function (independent) {
        /// <summary>
        ///     Create function to use as object's simple event ie delegate
        ///     Using:
        ///     function ClassA{
        ///         this.click = $.nd.delegate();
        ///     };
        ///     ClassA.prototype={
        ///         dblclick: $.nd.delegate(),
        ///         execute:function(){
        ///             this.click();
        ///             this.click({x:0});
        ///             this.dblclick({x:0}, 1, 2,3 );
        ///         }
        ///     };
        ///
        ///     var classA = new ClassA();
        ///     var handler=function(arg1){alert("click on " + this + ", arg1 - "+ arg1);};
        ///     classA.click(handler);
        ///     classA.dblclick(function(arg0, arg1, arg2, arg3){alert("dblclick on " + this);});
        ///     classA.execute();
        ///     classA.click("again");
        ///     // unsubscribe
        ///     classA.click(handler,"unsubscribe");
        /// </summary>
        /// <returns type="Function" />

        if (independent) {
            // create independent, ie it is not using 'this' keyword as target
            var delegate = function () {
                if (arguments.length === 1 && typeof arguments[0] === 'function') add(delegate, delegate, arguments[0]);
                else if (arguments.length === 2 && typeof arguments[0] === 'function' && arguments[1] === 'unsubscribe')
                    remove(delegate, delegate, arguments[0]);
                else fire(delegate, delegate, arguments);
                return delegate;
            };
        } else {
            var delegate = function () {
                if (arguments.length === 1 && typeof arguments[0] === 'function') add(this, delegate, arguments[0]);
                else if (arguments.length === 2 && typeof arguments[0] === 'function' && arguments[1] === 'unsubscribe')
                    remove(this, delegate, arguments[0]);
                else fire(this, delegate, arguments);
                return this;
            };
        }
        delegate._id = 'd' + $.guid++;

        return delegate;
    };
})();

// work with dom events
(function () {
    // custom binder
    (function () {
        function getHandler(h, d) {
            function dh(e) {
                // cannot use event.data in IE (it has "string" type and convert all values to string)
                e._data = d;

                // Add which for click: 1 === left; 2 === middle; 3 === right
                // Note: button is not normalized, so don't use it
                if (!e.which && e.button) {
                    e.which = e.button & 1 ? 1 : e.button & 2 ? 3 : e.button & 4 ? 2 : 0;
                }
                if (!e.timeStamp) {
                    e.timeStamp = $.nd.now();
                }
                e = $.nd.fixTouchEvent(e);

                var elm = dh.elm;
                var ret = h.call(elm, e);
                if (ret !== undefined) {
                    e.result = ret;
                    if (ret === false) {
                        if (e.preventDefault) e.preventDefault();
                        else e.returnValue = false;
                        if (e.stopPropagation) e.stopPropagation();
                        else e.cancelBubble = true;
                    }
                    return ret;
                }
            }
            return dh;
        }

        //this is simple bind method (for performance)
        $.fn.ndbind = function (types, data, fun) {
            types = types.split(' ');

            for (var i = 0; i < this.length; i++) {
                var dh = getHandler(fun || data, fun && data);
                var elm = this[i];
                dh.elm = elm;

                var type,
                    i = 0;
                while ((type = types[i++])) {
                    elm['drag_' + type + '_handle'] = dh;

                    if (elm.addEventListener) elm.addEventListener(type, dh, false);
                    else if (elm.attachEvent) elm.attachEvent('on' + type, dh);
                }
                dh = elm = undefined;
            }
            return this;
        };
        $.fn.ndunbind = function (type, data, fun) {
            for (var i = 0; i < this.length; i++) {
                var elm = this[i];
                var handle = elm['drag_' + type + '_handle'];
                elm['drag_' + type + '_handle'] = undefined;
                handle.elm = null;
                if (elm.removeEventListener) elm.removeEventListener(type, handle, false);
                else if (elm.detachEvent) elm.detachEvent('on' + type, handle);
            }
            return this;
        };
    })();

    // handle mousewheel events (old version), for new version see below
    (function () {
        var defaults = {
            minIntervalBetweenEventsToAcceptEventForTrackPad: 0, //50
            minIntervalBetweenEventsToFireEventForTrackPad: 0, //500
        };

        var isTrackPad;

        function handler(e) {
            var data = e.data || e._data;
            if (!data) return;

            var config = data.config || {};

            var timeSinceLastEvent = e.timeStamp - (data.lastEventTime || 0);
            var timeSinceLastFire = e.timeStamp - (data.lastFireTime || 0);
            data.lastEventTime = e.timeStamp;

            var originalEvent = e.originalEvent || e;

            // for mac's trackpad and safari see http://old.nabble.com/Mouse-wheel-event-precision-td28825238.html
            /*
            var delta;   // one scroll wheel "click" (corresponds to zoom level zoom level)
            if (isSafari5) {
            delta = e.wheelDelta / 120 / 120;
            } else if (e.wheelDelta) {
            delta = e.wheelDelta/120;
            if (window.opera && window.opera.version() < 9.2) {
            delta = -delta;
            }
            } else if (e.detail) {
            delta = -e.detail / 3;
            }
            */

            // wheelDelta - IE/Webkit/Opera
            if (originalEvent.wheelDelta) {
                if (!originalEvent.wheelDeltaX && !originalEvent.wheelDeltaY) {
                    // by default scroll vertical
                    e.wheelDeltaX = 0;
                    e.wheelDeltaY = originalEvent.wheelDelta;
                }

                if (isTrackPad === undefined) {
                    // try to detect trackpad or magic mouse
                    // it's behavior is different with other mouse devices (has too many events )

                    // it has different wheelDelta
                    //
                    if (Math.abs(originalEvent.wheelDelta) === 120) {
                        isTrackPad = false;
                    } else {
                        if (timeSinceLastEvent < 20) {
                            isTrackPad = true;
                            //console.log("SET isTrackPad - timeSinceLastEvent: " + timeSinceLastEvent + ", e.wheelDelta - " + originalEvent.wheelDelta);
                        }
                    }
                }
            } else if (originalEvent.detail) {
                e.wheelDelta = -1 * originalEvent.detail;
                // hscroll supported in FF3.1+
                if (originalEvent.axis && originalEvent.axis === originalEvent.HORIZONTAL_AXIS) {
                    // horizontal scroll
                    e.wheelDeltaX = e.wheelDelta;
                    e.wheelDeltaY = 0;
                } else {
                    // by default scroll verticale
                    e.wheelDeltaX = 0;
                    e.wheelDeltaY = e.wheelDelta;
                }
            }

            if (isTrackPad) {
                if (timeSinceLastEvent < config.minIntervalBetweenEventsToAcceptEventForTrackPad) {
                    // fix safari behavior when it fires several events for single swipe
                    return;
                }
                if (timeSinceLastFire < config.minIntervalBetweenEventsToFireEventForTrackPad) {
                    // fix safari behavior when it fires several events for single swipe
                    return;
                }
            }

            // norm, required for text view
            if (e.wheelDelta != 0) e.wheelDelta = e.wheelDelta > 0 ? 120 : -120;

            if (e.wheelDeltaX != 0) e.wheelDeltaX = e.wheelDeltaX > 0 ? 120 : -120;

            if (e.wheelDeltaY != 0) e.wheelDeltaY = e.wheelDeltaY > 0 ? 120 : -120;

            // fix bug in safari
            if (!e.wheelDeltaX && e.wheelDeltaY) {
                e.wheelDelta = e.wheelDeltaY;
            } else if (e.wheelDeltaX && !e.wheelDeltaY) {
                e.wheelDelta = e.wheelDeltaX;
            }

            // -1 scrol up, +1 down, 0 - unknown
            //e.wheelDir = (e.wheelDelta || (e.detail ? -1 * e.detail : 0)) >= 0 ? -1 : 1;
            e.wheelDir = !e.wheelDelta ? 0 : e.wheelDelta > 0 ? -1 : 1;
            e.wheelDirVerticale = !e.wheelDeltaY ? 0 : e.wheelDeltaY > 0 ? -1 : 1;
            e.wheelDirHorizontal = !e.wheelDeltaX ? 0 : e.wheelDeltaX > 0 ? -1 : 1;

            //$.writeLog("wheelDeltaY: " + e.wheelDeltaY + ", wheelDeltaX: " + e.wheelDeltaX + ", wheelDelta: " + e.wheelDelta + ", isTrackPad: " + isTrackPad);

            data.lastFireTime = e.timeStamp;
            return data.fn.call(this, e);
        }
        function mouseenter(e) {
            $(this).on($.props.mousewheel, e.data, handler);
        }
        function mouseleave() {
            $(this).off($.props.mousewheel, handler);
        }

        function mousewheel(self, cfg, userConfig) {
            userConfig = $.extend({}, defaults, userConfig || {});

            if (cfg && cfg.handler) {
                if (cfg.whenMouseOver) {
                    return self.on('mouseenter', { fn: cfg.handler, config: userConfig }, mouseenter).on('mouseleave', mouseleave);
                }
                return self.on($.props.mousewheel, { fn: cfg.handler, config: userConfig }, handler);
            }
            return self; //.trigger(name);
        }

        $.fn.mousewheel = function (cfg, fn) {
            if (typeof cfg === 'function') {
                fn = cfg;
                cfg = null;
            }
            return mousewheel(this, { handler: fn }, cfg);
        };
        $.fn.mousewheelWhenMouseOver = function (cfg, fn) {
            if (typeof cfg === 'function') {
                fn = cfg;
                cfg = null;
            }
            return mousewheel(this, { handler: fn, whenMouseOver: true }, cfg);
        };
    })();

    // Handle wheel (standard compatible mode)
    (function () {
        // api
        var defaults = {
            accumulate: false,
            accumulateMinInterval: 100,
            accumulateMaxTime: 0,
            handler: null,
            target: null,
        };
        $.fn.wheel = function (cfg) {
            return wheel(this, cfg);
        };

        function wheel(self, config) {
            config = $.extend({}, defaults, config || {});

            if (config.handler) {
                return self.each(function () {
                    addWheelListener(this, function (event) {
                        var result = onwheel(this, event, config);
                        if (typeof event.preventDefault === 'function') {
                            event.preventDefault();
                        }
                        return result;
                    });
                });
            }
            return self;
        }
        function onwheel(elm, event, config) {
            var now = new Date().getTime();

            if (!config._data) {
                config._data = {
                    deltaMode: event.deltaMode,
                    target: event.target,
                    deltaX: 0,
                    deltaY: 0,
                    delatZ: 0,
                    deltaXCount: 0,
                    deltaYCount: 0,
                    delatZCount: 0,
                    screenX: event.screenX,
                    screenY: event.screenY,
                    clientX: event.clientX,
                    clientY: event.clientY,
                    pageX: event.pageX,
                    pageY: event.pageY,
                };
                config._startTime = now;
            }
            config._lastEventTime = now;

            // accumulate data
            var data = config._data;
            data.deltaX += event.deltaX;
            data.deltaY += event.deltaY;
            data.delatZ += event.delatZ;

            // keep some statistics
            if (event.deltaX !== 0) {
                data.deltaXCount++;
            }
            if (event.deltaY !== 0) {
                data.deltaYCount++;
            }
            if (event.deltaZ !== 0) {
                data.deltaZCount++;
            }

            if (config.accumulate) {
                // Check restrictions
                if (!config._timeoutCallback) {
                    config._timeoutCallback = ontimeout.bind(config);
                }

                if (config._timeout) {
                    clearTimeout(config._timeout);
                    config._timeout = 0;
                }

                if (config.accumulateMaxTime) {
                    var delta = now - config._startTime;
                    if (delta > config.accumulateMaxTime) {
                        fireEventFromData(config);
                        return false;
                    }
                }

                config._timeout = setTimeout(config._timeoutCallback, config.accumulateMinInterval);

                return false;
            } else {
                fireEventFromData(config);
                return false;
            }
        }
        function ontimeout() {
            var config = this;
            config._timeout = 0;

            fireEventFromData(config);
        }
        function fireEventFromData(config) {
            if (config._data) {
                var now = new Date().getTime();

                if (config.accumulateMinTimeSinceProcessed) {
                    var delta = now - config._lastProcessedTime;
                    if (delta < config.accumulateMinTimeSinceProcessed) {
                        config._data = null;
                        return;
                    }
                }

                config.handler.call(config.target, config._data);

                if (config._data.processed) {
                    config._lastProcessedTime = now;
                }
                config._lastFireTime = now;
                config._data = null;
            }
        }

        // creates a global "addWheelListener" method
        // example: addWheelListener( elem, function( e ) { console.log( e.deltaY ); e.preventDefault(); } );
        // code taken from https://developer.mozilla.org/en-US/docs/Web/Reference/Events/wheel?redirectlocale=en-US&redirectslug=DOM%2FMozilla_event_reference%2Fwheel
        var addWheelListener = (function (window, document) {
            var prefix = '',
                _addEventListener,
                onwheel,
                support;

            // detect event model
            if (window.addEventListener) {
                _addEventListener = 'addEventListener';
            } else {
                _addEventListener = 'attachEvent';
                prefix = 'on';
            }

            // detect available wheel event
            support =
                'onwheel' in document.createElement('div')
                    ? 'wheel' // Modern browsers support "wheel"
                    : document.onmousewheel !== undefined
                    ? 'mousewheel' // Webkit and IE support at least "mousewheel"
                    : 'DOMMouseScroll'; // let's assume that remaining browsers are older Firefox

            function _addWheelListener(elem, eventName, callback, useCapture) {
                elem[_addEventListener](
                    prefix + eventName,
                    support == 'wheel'
                        ? callback
                        : function (originalEvent) {
                              !originalEvent && (originalEvent = window.event);

                              // create a normalized event object
                              var event = {
                                  // keep a ref to the original event object
                                  originalEvent: originalEvent,
                                  target: originalEvent.target || originalEvent.srcElement,
                                  type: 'wheel',
                                  deltaMode: originalEvent.type == 'MozMousePixelScroll' ? 0 : 1,
                                  deltaX: 0,
                                  deltaY: 0,
                                  delatZ: 0,
                                  screenX: originalEvent.screenX,
                                  screenY: originalEvent.screenY,
                                  clientX: originalEvent.clientX,
                                  clientY: originalEvent.clientY,
                                  pageX: originalEvent.pageX,
                                  pageY: originalEvent.pageY,
                                  preventDefault: function () {
                                      originalEvent.preventDefault ? originalEvent.preventDefault() : (originalEvent.returnValue = false);
                                  },
                              };

                              // calculate deltaY (and deltaX) according to the event
                              if (support == 'mousewheel') {
                                  if (originalEvent.wheelDeltaX) event.deltaX = (-1 / 40) * originalEvent.wheelDeltaX;

                                  if (originalEvent.wheelDeltaY) event.deltaY = (-1 / 40) * originalEvent.wheelDeltaY;

                                  if (!originalEvent.wheelDeltaX && !originalEvent.wheelDeltaY) event.deltaY = (-1 / 40) * originalEvent.wheelDelta;
                              } else {
                                  event.deltaY = originalEvent.detail;
                              }

                              // it's time to fire the callback
                              return callback(event);
                          },
                    useCapture || false,
                );
            }

            function addWheelListener(elem, callback, useCapture) {
                _addWheelListener(elem, support, callback, useCapture);

                // handle MozMousePixelScroll in older Firefox
                if (support == 'DOMMouseScroll') {
                    _addWheelListener(elem, 'MozMousePixelScroll', callback, useCapture);
                }
            }
            return addWheelListener;
        })(window, document);
    })();

    $.nd.fixTouchEvent = function (event) {
        // check originalEvent??

        var touches = event.touches;

        // For some events "touches" is empty but "changedTouches" is not empty
        // and reverse
        if (!touches || touches.length === 0) {
            touches = event.changedTouches;
        }

        if (touches && touches.length > 0) {
            var touch = touches[0];
            event.screenX = touch.screenX;
            event.screenY = touch.screenY;

            if (!isReadOnly(event, 'pageX')) {
                event.pageX = touch.pageX;
                event.pageY = touch.pageY;
            }

            if (!isReadOnly(event, 'touchX')) {
                event.touchX = touch.pageX;
                event.touchY = touch.pageY;
            }
        }
        return event;
    };

    $.nd.matchSelector = function (eventTarget, selector, stopElement) {
        if (eventTarget) {
            //check target itself
            if (jQuery.filter(selector, [eventTarget]).length > 0) return true;

            if (stopElement.length) {
                // should be one element?
                stopElement = stopElement[0];
            }
            stopElement = stopElement || document.body || document.documentElement;

            // check parents
            for (var cur = eventTarget; cur != stopElement; cur = cur.parentNode || stopElement) {
                if (jQuery.filter(selector, [cur]).length > 0) return true;
            }

            //if ($(eventTarget).parents().filter(selector).length)
            //  return true;
        }
        return false;
    };

    $.fn.tap = function (selector, handler) {
        //$(".span").tap(function(){});
        //$("body").tap(".span", function(){});
        if (typeof selector === 'function') {
            handler = selector;
            selector = undefined;
        }
        return this.doubletap({ tap: handler, selector: selector });
    };
    $.fn.doubletap = function () {
        // the code is taken from http://www.sanraul.com/2010/08/01/implementing-doubletap-on-iphones-and-ipads/
        // however some changes was added
        // 1. using $.support.touchEvents to detect touch supporting
        // 2. reset lastTouch after doubletap is detected
        // 3. "onTapCallback(evt)"  => "onTapCallback(evt || event)" // to make work in ie
        // 4. TODO: fix the problem "the double tab is also fired if you touch the surface with two fingers and a little delay"

        // use $("#id").doubletap({doubleTap: doubleTap, tap: onTap, delay: 100});

        if (arguments.length == 0) return this;

        var cfg = arguments[0];
        if (arguments.length > 1 || typeof arguments[0] === 'function') {
            // compatible with prev ver
            // use $("#id").doubletap(onDoubleTap, onTap);
            cfg = {
                doubleTap: arguments[0],
                tap: arguments[1],
            };
        }

        function tap(touch) {
            if (cfg.tap) {
                if (cfg.selector) {
                    if (!$.nd.matchSelector(touch.target(), cfg.selector, touch.element())) return;
                }
                var evt = touch.event();
                evt.data = cfg.data;
                evt = $.nd.fixTouchEvent(evt);
                return cfg.tap.call(touch.target(), evt);
            }
        }
        function doubleTap(touch) {
            if (cfg.doubleTap) {
                if (cfg.selector) {
                    if (!$.nd.matchSelector(touch.target(), cfg.selector, touch.element())) return;
                }
                var evt = new $.Event('doubletap', $.nd.fixTouchEvent(touch.event()));
                evt.data = cfg.data;
                evt.originalEvent = null;
                return cfg.doubleTap.call(touch.target(), evt);
            }
        }

        var self = this;
        this.on('singleTapConfirmed', function (evt) {
            if (cfg.tap) {
                if (cfg.selector) {
                    if (!$.nd.matchSelector(evt.target, cfg.selector, self)) return;
                }
                evt.data = cfg.data;
                evt = $.nd.fixTouchEvent(evt);
                return cfg.tap.call(evt.target, evt);
            }
        });

        $.nd.gestures.bind(this, {
            singleTapConfirmed: tap,
            doubleTap: doubleTap,
        });

        return this;
    };
})();

(function () {
    $.nd.easing = $.nd.easing || {};

    // bezier visualizer
    // http://www.netzgesta.de/dev/cubic-bezier-timing-function.html
    // js visualizer
    // http://freeman2.com/graph06e.htm

    // named easing
    // the name should be bezier curve (for TransitionTimingFunction)
    // also for each name should be function for jquery animation
    $.nd.easing.nd1 = 'cubic-bezier(0.33,0.66,0.66,1)';
    $.easing[$.nd.easing.nd1] = function (p, n, firstNum, diff) {
        return Math.sin((Math.PI / 2) * p);
    };
    $.nd.easing.nd1_reverse = 'cubic-bezier(0.33,0.0,0.33,1)';
    $.easing[$.nd.easing.nd1_reverse] = function (p, n, firstNum, diff) {
        //??
        return Math.sin(Math.PI * (p - 0.5)) / 2 + 0.5;
    };
    /*
    //var easing = "cubic-bezier(0.0, 1.0472, 1.0472, 0.0)"; //sin( pi * t )
    //var easing = "cubic-bezier(0.6, 1.0, 0.7, 1.0)"; //sin( pi / 2 * t )
    var easing = "cubic-bezier(0.33,0.66,0.66,1)"; //sin( pi / 2 * t )
    $.easing[easing] = function (p, n, firstNum, diff) {
    return Math.sin(Math.PI / 2 * p);
    };
    */
})();

/**
 * Logging has been moved to "nd.logger" module,
 * but there are still some dependencies in the main module (nd.core),
 * that is why these methods are mocked. */
$.showError = $.noop;
$.writeLog = function () {
    if (arguments.length) {
        $.writeLog.messages = $.writeLog.messages || [];
        $.writeLog.messages.push(arguments[0]);
    }
};
$.writeError = function () {
    if (arguments.length) {
        $.writeError.messages = $.writeError.messages || [];
        $.writeError.messages.push(arguments[0]);
    }
};

/**
 * In Ipad there is an issue:
 * - when user opens a keyboard (focuses <input> or <textarea>) it moves whole content
 * - when input field loses its focus - keyboard closing
 * - but content doesn't move to its initial position
 * This method fixes this issue
 */
$.nd.fixTouchScroll = function () {
    if (Modernizr.touch && $(window).scrollTop() > 0) {
        $(window).scrollTop(0);
    }
};

$.nd.noop = function () {};

$.nd.momentum = function (velocity, t, maxDistUpper, maxDistLower) {
    if (velocity > 8) velocity = 8;
    else if (velocity < -8) velocity = -8;

    var dt = Math.log(0.15 / Math.abs(velocity)) / Math.log(0.998);
    if (dt <= 0) dt = 0;
    else dt = Math.round(dt);
    var factor = (1 - Math.pow(0.998, dt + 1)) / (1 - 0.998);
    var dx = velocity * factor;

    // Proportionally reduce speed if we are outside of the boundaries
    var newDx;
    if (dx > 0 && dx > maxDistUpper) {
        newDx = maxDistUpper;
        dt = (newDx * dt) / dx;
        dx = newDx;
    } else if (dx < 0 && dx < -maxDistLower) {
        newDx = -maxDistLower;
        dt = (newDx * dt) / dx;
        dx = newDx;
    }

    return { dist: dx, time: Math.abs(dt), returnTime: 0 };
};

(function () {
    var EmptyArray = [];

    var __ndCallBaseByName = function (name) {
        if (this.__ndBaseType) {
            var b = this.__ndBaseType.prototype[name];
            if (b) {
                var arg = null;
                if (arguments.length > 1) {
                    arg = $.makeArray(arguments);
                    arg.shift();
                }
                var __ndBaseType = this.__ndBaseType;
                try {
                    // change base type
                    this.__ndBaseType = this.__ndBaseType.prototype.__ndBaseType;
                    b.apply(this, arg || EmptyArray); // arg cannot be null for IE8
                } finally {
                    // restore base type
                    this.__ndBaseType = __ndBaseType;
                }
            }
        }
    };

    var _ndUniqueId = 0;
    function ndUniqueId() {
        if (!this._ndUniqueId) this._ndUniqueId = ++_ndUniqueId;
        return this._ndUniqueId;
    }

    Function.prototype.ndInheritFrom = function (baseType) {
        this.__ndBaseType = baseType;
        for (var name in baseType.prototype) this.prototype[name] = baseType.prototype[name];

        //add base type to prototype to allow call overriden methods
        if (this.prototype) {
            this.prototype.__ndBaseType = baseType;
            this.prototype.__ndCallBaseByName = __ndCallBaseByName;
        }

        return this;
    };
    Function.prototype.ndExtend = function (proto) {
        this.prototype.ndUniqueId = ndUniqueId;
        for (var name in proto) this.prototype[name] = proto[name];
        return this;
    };

    Function.prototype.ndBaseCostructor = function (obj) {
        if (obj && this.__ndBaseType) {
            //obj.constructor.__ndBaseType.prototype.constructor.call(obj);
            var arg = null;
            if (arguments.length > 1) {
                arg = $.makeArray(arguments);
                arg.shift();
            }
            this.__ndBaseType.apply(obj, arg || EmptyArray); // arg cannot be null for IE8
        }
    };
})();

//Expressions
(function () {
    var cache = {};

    $.getPathExp = function (path) {
        return cache[path] || (cache[path] = new Function('obj', 'try{if(obj!=null&&obj!=undefined) return obj.' + path + ';}catch(E){}'));
    };
})();

// TODO: probably this fun doesn't make sense anymore, should be investigated and removed
$.required = function (moduleName, callback) {
    var def = new Deferred();
    var iter = 0;
    var getModuleExp = $.getPathExp(moduleName);
    var check = function () {
        var resolved = false;
        var module = getModuleExp(root);
        if (module) {
            if (callback) {
                callback(module);
            }
            def.resolve(module);
            resolved = true;
        }
        if (iter >= 10 || resolved) {
            moduleName = null;
            callback = null;
            check = null;
            getModuleExp = null;
            return;
        }
        setTimeout(check, iter++ * 10);
    };
    check();
    return def.promise();
};

$.nd.longBitwiseAnd = function (a, b) {
    var w = 4294967296; // 2^32
    var aHI = a / w;
    var aLO = a % w;
    var bHI = b / w;
    var bLO = b % w;
    return (aHI & bHI) * w + (aLO & bLO);
};
$.nd.isExternalArticleId = function (aid) {
    var externalArticleMask = 140737488355328;
    var tmpRes = $.nd.longBitwiseAnd(aid, externalArticleMask);
    return tmpRes == externalArticleMask;
};

function isReadOnly(obj, propertyName) {
    try {
        var originalValue = obj[propertyName];
        obj[propertyName] = originalValue;
        return false;
    } catch (err) {
        return true;
    }
}

const CssMatrix = $.nd.matrix;

export { CssMatrix };
