enum SubscriptionMode {
    Trial = 0,
    SingleCopy = 1,
    Regular = 2,
    Print = 3,
    AppDefault = 4,
    AppSingleCopy = 5,
    AppRegular = 6,
    Bundle = 7,
    Unknown = 8,
}

enum SubscriptionStatus {
    Valid = 1,
    Exceed = 2,
    Expired = 4,
    Suspended = 8,
    Canceled = 16,
    PaymentDeclined = 32,
    NotValid = 60,
    NoBalance = 64,
}

export { SubscriptionMode, SubscriptionStatus };
