import { Subject } from 'rxjs';

const ndStateSubject = new Subject<string>();

export function publishNdStateEvent(ndStateHeader: string): void {
    if (!ndStateHeader) {
        return;
    }
    ndStateSubject.next(ndStateHeader);
}

export const onNdStateChanged = ndStateSubject.asObservable();
