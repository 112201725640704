/**
 * IMPORTANT: changes here should be also reflected in `./utils.ts` file.
 */
enum TargetPlatform {
    /** Default mode */
    Web = 'web',
    /** Accessiblity mode */
    Pervasive = 'pervasive',
    /** Local server mode */
    Voyager = 'voyager',
}

enum MimeType {
    JPEG = 'image/jpeg',
    PNG = 'image/png',
    GIF = 'image/gif',
    WEBP = 'image/webp',
    PDF = 'application/pdf',
    EPUB_ZIP = 'application/epub+zip',
}

export { MimeType, TargetPlatform };
