import { getConfigValue } from '@pressreader/config';
import { getPropertyValue } from '@pressreader/utils';

import { getBasePath } from './routing.utils';

// Ref https://github.com/aspnet/AspNetWebStack/blob/1231b77d79956152831b75ad7f094f844251b97f/src/System.Web.WebPages/RequestExtensions.cs#L12
function isUrlLocalToHost(url: string) {
    return (
        !!url &&
        ((url[0] === '/' && (url.length === 1 || (url[1] !== '/' && url[1] !== '\\'))) || // "/" or "/foo" but not "//" or "/\"
            (url.length > 1 && url[0] === '~' && url[1] === '/')) // "~/" or "~/foo"
    );
}

const isNullEmptyOrLocal = (attemptedValue: string) => {
    return !attemptedValue || isUrlLocalToHost(attemptedValue);
};

const isSameHost = (attemptedValue: string) => {
    try {
        const url = new URL(attemptedValue);
        return url.host.toLocaleLowerCase() === window.location.host.toLocaleLowerCase();
    } catch (error) {
        return false;
    }
};

const validationDisabledByConfig = () => !getConfigValue<boolean>('ReturnURLValidation.Enabled', true);

const allowedByConfig = (attemptedValue: string) => {
    const allowedURLs = getConfigValue<{ [key: string]: boolean }>('ReturnURLValidation.AllowedURLs') || {};
    return getPropertyValue<boolean>({ object: allowedURLs, name: attemptedValue }) === true;
};

export function fixToLocalURL(attemptedValue: string) {
    if (isNullEmptyOrLocal(attemptedValue) || isSameHost(attemptedValue) || validationDisabledByConfig() || allowedByConfig(attemptedValue)) {
        return attemptedValue;
    }
    return getBasePath();
}
