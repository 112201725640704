/* eslint-env es6 */

import * as $ from 'jquery';
import { max, map } from 'lodash';

const MIN_ZINDEX = 1000;

/**
 * Returns current visible tint z-index.
 * In case if there are several visible tints (several dialogs are visible) - it returns max(z-index)
 *
 * @returns {Number}
 */
function getCurrentTintZIndex() {
    const visibleTints = $('.dialog-tint:visible');
    let visibleTintZIndex = max(map(visibleTints, item => parseInt($(item).css('z-index'), 10) || MIN_ZINDEX));

    visibleTintZIndex = max([visibleTintZIndex, MIN_ZINDEX]);

    return visibleTintZIndex;
}

/**
 * Returns current visible dialog z-index.
 * In case if there are several visible dialogs - it returns max(z-index)
 *
 * @returns {Number}
 */
function getCurrentDialogZIndex() {
    return getCurrentTintZIndex() + 1;
}

/**
 * Returns z-index for the tint which is not yet rendered.
 * Use this method in dialogs/menus to get the correct tint z-index.
 *
 * @returns {Number}
 */
function getNextTintZIndex() {
    // if there is a visible tint - this means that there is a visible dialog as well
    // that is why z-index must be increased by 2 for the next tint.
    return getCurrentTintZIndex() + 2;
}

function getNextDialogZIndex() {
    return getNextTintZIndex() + 1;
}

export { getCurrentTintZIndex, getCurrentDialogZIndex, getNextTintZIndex, getNextDialogZIndex };
