// eslint-env es6

import TranslateTransformation from 'profile/background-image/models/translate.transformation';
import BackgroundTransformations from 'profile/background-image/models/background.transformations';

class BackgroundSettings {
    constructor(imageId, transformedImageId, transformations = new BackgroundTransformations(100, new TranslateTransformation())) {
        this.imageId = imageId;
        this.transformedImageId = transformedImageId;
        this.transformations = transformations;
    }

    static fromDto(data) {
        const settings = new BackgroundSettings(
            data && data.imageId,
            data && data.transformedImageId,
            (data && data.transformations && BackgroundTransformations.fromDto(data.transformations)) ||
                new BackgroundTransformations(100, new TranslateTransformation()),
        );
        return settings;
    }
}

export { BackgroundSettings };
