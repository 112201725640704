import { getConfigValue } from '@pressreader/config';
import { getBasePath } from '@pressreader/routing';
import { TargetPlatform } from '@pressreader/types';
import { cookies } from '@pressreader/utils';

import { COOKIE_FACELIFT_ENABLED_KEY, COOKIE_TARGETPLATFORM_EXPIRES_IN_DAYS, COOKIE_TARGETPLATFORM_KEY } from './constants';

const validPlatforms: string[] = [TargetPlatform.Web, TargetPlatform.Pervasive, TargetPlatform.Voyager];
const URL_QUERY_ACCESSIBILITY_MATCH = 'accessibility=true';

export function validateTargetPlatform(targetPlatform: string | null): targetPlatform is TargetPlatform {
    return !!targetPlatform && validPlatforms.indexOf(targetPlatform) >= 0;
}

export function checkPervasiveUrlQueryParam() {
    const targetPlatform = getConfigValue<TargetPlatform>('features.app.target.platform', TargetPlatform.Web);
    const supportedPlatformsJoined = getConfigValue('features.app.platforms.supported', targetPlatform);
    const supportedPlatforms = supportedPlatformsJoined.split(',').filter(validateTargetPlatform);

    if (
        (window.location.search || '').toLowerCase().indexOf(URL_QUERY_ACCESSIBILITY_MATCH) >= 0 &&
        supportedPlatforms.indexOf(TargetPlatform.Pervasive) >= 0
    ) {
        cookies.set(COOKIE_TARGETPLATFORM_KEY, TargetPlatform.Pervasive, {
            path: getBasePath(),
            expiresInDays: COOKIE_TARGETPLATFORM_EXPIRES_IN_DAYS,
        });
    }
}

export function resolveTargetPlatform() {
    let targetPlatform = getConfigValue('features.app.target.platform', TargetPlatform.Web);

    const cookieTargetPlatform = cookies.get(COOKIE_TARGETPLATFORM_KEY, null);
    if (targetPlatform !== cookieTargetPlatform && validateTargetPlatform(cookieTargetPlatform)) {
        // check whether platform set in cookie is among supported platforms
        const supportedPlatformsJoined = getConfigValue('features.app.platforms.supported', targetPlatform);
        const supportedPlatforms = supportedPlatformsJoined.split(',').filter(validateTargetPlatform);

        if (supportedPlatforms.indexOf(cookieTargetPlatform) >= 0) {
            targetPlatform = cookieTargetPlatform;
        }
    }

    return targetPlatform;
}

export function resolveFaceliftEnabled() {
    const betaFaceliftEnabled = getConfigValue<boolean>('features.beta.facelift.enabled', false);

    if (betaFaceliftEnabled) {
        return true;
    }

    const faceliftAllowed = getConfigValue<boolean>('features.app.facelift.allowed', false);

    if (faceliftAllowed) {
        const faceliftEnabled = cookies.get(COOKIE_FACELIFT_ENABLED_KEY, null);
        return faceliftEnabled === 'true';
    }

    return false;
}

export function resolveExperimentalEnabled() {
    return getConfigValue<boolean>('features.app.facelift.allowed', false);
}
