import * as $ from 'jquery';
import * as ko from 'knockout';

import { foldAccents } from '@pressreader/utils';

import 'nd.core';
import $utils from 'nd.utils';
import iScroll from 'iscroll';
import ClickEventStream from 'nd.eventstream.click';

var widgets = ($.nd.widgets = {});

$.nd.widgets.tmpl = {};
$.nd.widgets.tmpl.iif = function (val, matchVal, trueResult, falseResult) {
    return val === matchVal ? trueResult : falseResult;
};
// add "sif" for safari, it has a bug with "if" (unable to parse)
$.nd.widgets.tmpl['if'] = $.nd.widgets.tmpl.sif = function (val, trueResult, falseResult) {
    return val ? trueResult : falseResult;
};
$.nd.widgets.tmpl.convertToPercent = function (val) {
    return val + '%';
};
// hooks for attr, should be lowercase
$.attrHooks.classname = {
    set: function (elem, value) {
        // if class name could start with  '-' or '+'
        // in this case we should add or remove class

        // Example:
        // //add class "sel"
        // $(elm).attr("className", "+sel");
        // //remove class "sel"
        // $(elm).attr("className", "-sel");
        if (value && value.length) {
            if (value[0] === '-') {
                $(elem).removeClass(value.substr(1));
                return true;
            }
            if (value[0] === '+') {
                $(elem).addClass(value.substr(1));
                return true;
            }
        }
        elem.className = value;
        return true;
    },
};
$.attrHooks.jquery = {
    set: function (elem, value) {
        // Example:
        // call fadeIn on elm
        // $(elm).attr("jquery", "fadeIn");
        // call fadeOut on elm
        // $(elm).attr("jquery", "fadeOut");
        var self = $(elem);
        if (self[value]) self[value]();
        return true;
    },
};

$.nd.ObservableCollection = function (source) {
    this.items = [];
    this._items = $.observable(this.items);
    this._onDataChangedHandler = this.onDataChanged.bind(this);
    this.setSource(source || []);
};
$.nd.ObservableCollection.prototype = {
    items: null,
    sourceChanged: $.nd.delegate(),
    changed: $.nd.delegate(),
    getItems: function () {
        return this.items;
    },
    getSource: function () {
        return this._source;
    },
    getSourceForRendering: function () {
        return this._source;
    },
    add: function (data) {
        return this.insert(this._source.length, data);
    },
    insert: function (index, data) {
        $.observable(this._source).insert(index, data);
        return this;
    },
    remove: function (idx, numberToRemove) {
        $.observable(this._source).remove(idx, numberToRemove);
        return this;
    },
    removeItem: function (item) {
        var items = this._source;
        for (var i = items.length - 1; i >= 0; --i) {
            if (items[i] === item) {
                this.remove(i);
            }
        }
        return this;
    },
    updateSourceItems: function (sourceItems) {
        $.observable(this._source).refresh(sourceItems || []);
        return this.reset();
    },
    setSource: function (source) {
        if (this._source) {
            $([this._source]).unbind('arrayChange', this._onDataChangedHandler);
        }
        this._source = source;
        if (source) {
            $([source]).bind('arrayChange', this._onDataChangedHandler);
        }
        return this.reset().update();
    },
    onDataChanged: function (ev, eventArgs) {
        if (eventArgs) {
            // the original collection changed
            this.sourceChanged();
            this.reset().update();
        }
        return true;
    },
    update: function (appendDiff) {
        var coll = this._getArray();
        // replace items with new collection
        if (appendDiff) {
            var diff = coll.slice(this.items.length, coll.length);
            this._items.insert(this.items.length, diff);
        } else {
            this._items.refresh(coll);
        }
        // at that time we cannot fire notifications (there is some problems in template engine)
        var self = this;
        setTimeout(function () {
            self.changed(appendDiff || false);
        }, 0);

        return this;
    },
    reset: function () {
        this._top = this.pageSize;
        this._maxItems = this._source.length;
        return this;
    },
    renderMore: function () {
        //			if (this.pageSize) {
        //				var items = this._getArray(true);
        //				if (items.length > this._top) {
        //					var nextTop = Math.min(this._top + this.pageSize, items.length);
        //					var itemsToRender = items.slice(this._top, nextTop);

        //					RenderMoreItems(this, this.tmplItem, itemsToRender);

        //					this._top = nextTop;
        //				}
        ///			}
        if (this._top < this._maxItems) {
            this._top += this.pageSize;
            return this.update(true);
        }
        return this;
    },
    setPageSize: function (pageSize) {
        this.pageSize = pageSize;
        this._top = this.pageSize;
        return this.reset().update();
    },
    resetFilter: function (update) {
        return this.setFilter(null, null);
    },
    setFilter: function (filter, filterPath) {
        if (this.filter != filter) {
            this.filter = filter;
            this.filterPath = filterPath;
            return this.reset().update();
        }
        return this;
    },
    isFilterActive: function () {
        return this.filter && this.filter.length && this.filterPath;
    },
    setSort: function (sortPath, sortOrder) {
        this.sortPath = sortPath;
        this.sortOrder = sortOrder;
        return this.reset().update();
    },
    _applyPaging: function (coll) {
        if (this._top && this._top < coll.length) {
            //console.log("apply pagging: " + this._top);
            return coll.slice(0, this._top);
        }
        return coll;
    },
    _applyFilter: function (coll) {
        if (!this.filter || !this.filter.length || !this.filterPath) {
            this._maxItems = coll.length;
            return coll;
        }
        var match = new RegExp(foldAccents(this.filter), 'i'); // don't use "g" flag, it has a bug, two consecutive call to test the same string will return different results, see http://stackoverflow.com/questions/6739136/consecutive-calls-to-regexp-test-fail-for-pattern-with-global-option
        var filtred = [],
            filterPathExp = $.getPathExp(this.filterPath);
        for (var i = 0, l = coll.length; i < l; i++) {
            var item = coll[i];
            var field = filterPathExp(item);
            if (field && match.test(foldAccents(field.toString()))) {
                filtred.push(item);
            }
        }
        this._maxItems = filtred.length;
        return filtred;
    },
    _applySort: function (items) {
        if (this.sortPath) {
            return this._sortImpl(items, this.sortPath, this.sortOrder);
        }
        return items;
    },
    _sortImpl: function (items, path, order) {
        var copy = items.slice(0);
        order = !order || order >= 0 ? 1 : -1;
        var sortPathEpr = $.getPathExp(path);
        if (order > 0) {
            copy.sort(function (l, r) {
                var lv = sortPathEpr(l);
                var rv = sortPathEpr(r);
                if (typeof lv === 'string' && typeof lv === 'string') {
                    (lv = lv.toLowerCase()), (rv = rv.toLowerCase());
                    return lv.localeCompare(rv);
                }
                if (lv == rv) return 0;
                return lv > rv ? 1 : -1;
            });
        } else {
            copy.sort(function (l, r) {
                var lv = sortPathEpr(l);
                var rv = sortPathEpr(r);
                if (typeof lv === 'string' && typeof lv === 'string') {
                    lv = lv.toLowerCase();
                    rv = rv.toLowerCase();
                    return lv.localeCompare(rv) * -1;
                }
                if (lv == rv) return 0;
                return lv > rv ? -1 : 1;
            });
        }
        return copy;
    },
    _getArray: function () {
        var items = this.getSourceForRendering() || [];
        items = this._applyFilter(items);
        items = this._applySort(items);
        items = this._applyPaging(items);
        return items;
    },
};

$.nd.ObservableCollectionNew = function (source) {
    this._source = source;
    this.items = ko.observableArray();
};
$.nd.ObservableCollectionNew.prototype = {
    changed: $.nd.delegate(),
    render: function (doNotResetScroll) {
        var items = this._source || [];
        items = this._applyFilter(items);
        this._maxItems = items.length;
        items = this._applySort(items);
        items = this._applyPaging(items);
        this.items(items);

        var self = this;
        setTimeout(function () {
            self.changed(!!doNotResetScroll);
        }, 0);
        return this;
    },
    canRenderMore: function () {
        return this._top < this._maxItems;
    },
    renderMore: function () {
        if (this.canRenderMore()) {
            this._top += this._pageSize;

            var items = this._source || [];
            items = this._applyFilter(items);
            items = this._applySort(items);
            items = this._applyPaging(items);

            // replace items with new collection
            var diff = items.slice(this.items().length, items.length);

            for (var i = 0, j = diff.length; i < j; i++) {
                this.items().push(diff[i]);
            }
            this.items.valueHasMutated();

            var self = this;
            setTimeout(function () {
                self.changed(true);
            }, 0);
        }
        return this;
    },
    updateSource: function (source) {
        this._source = source;
        return this;
    },
    setPageSize: function (pageSize) {
        this._pageSize = this._top = pageSize;
        return this;
    },
    setFilter: function (filter, filterPath) {
        this._filter = filter;
        this._filterPath = filterPath;

        return this;
    },
    setSort: function (sortPath, sortOrder) {
        this._sortPath = sortPath;
        this._sortOrder = sortOrder;

        return this;
    },
    reset: function () {
        this._top = this._pageSize;
        this._maxItems = this._source.length;
        this._filter = this._filterPath = ''; //this._sortPath = this._sortOrder =
        return this;
    },
    _applyPaging: function (coll) {
        if (this._top && this._top < coll.length) {
            //console.log("apply pagging: " + this._top);
            return coll.slice(0, this._top);
        }
        return coll;
    },
    _applyFilter: function (coll) {
        if (!this._filter || !this._filter.length || !this._filterPath) {
            return coll;
        }
        var match = new RegExp($utils.escapeRegExp(foldAccents(this._filter)), 'i'); // don't use "g" flag, it has a bug, two consecutive call to test the same string will return different results, see http://stackoverflow.com/questions/6739136/consecutive-calls-to-regexp-test-fail-for-pattern-with-global-option
        var filtred = [],
            filterPathExp = $.getPathExp(this._filterPath);

        for (var i = 0, l = coll.length; i < l; i++) {
            var item = coll[i];
            var field = filterPathExp(item);
            if (field && match.test(foldAccents(field.toString()))) {
                filtred.push(item);
            }
        }

        return filtred;
    },
    _applySort: function (items) {
        if (this._sortPath) {
            return this._sortImpl(items, this._sortPath, this._sortOrder);
        }
        return items;
    },
    _sortImpl: function (items, path, order) {
        var copy = items.slice(0);
        order = !order || order >= 0 ? 1 : -1;
        var sortPathEpr = $.getPathExp(path);
        if (order > 0) {
            copy.sort(function (l, r) {
                var lv = sortPathEpr(l);
                var rv = sortPathEpr(r);
                if (typeof lv === 'string' && typeof lv === 'string') {
                    (lv = lv.toLowerCase()), (rv = rv.toLowerCase());
                    return lv.localeCompare(rv);
                }
                if (lv == rv) return 0;
                return lv > rv ? 1 : -1;
            });
        } else {
            copy.sort(function (l, r) {
                var lv = sortPathEpr(l);
                var rv = sortPathEpr(r);
                if (typeof lv === 'string' && typeof lv === 'string') {
                    (lv = lv.toLowerCase()), (rv = rv.toLowerCase());
                    return lv.localeCompare(rv) * -1;
                }
                if (lv == rv) return 0;
                return lv > rv ? -1 : 1;
            });
        }
        return copy;
    },
};

widgets.getTmplItemColl = function (tmplItem, collName) {
    if (tmplItem && tmplItem.data) return tmplItem.data[collName];
    return null;
};

widgets.getTmplItemCollNew = function (tmplItem, collName) {
    if (tmplItem) return tmplItem[collName];
    return null;
};

(function () {
    $.nd.domContentUpdating = $.nd.delegate(true);
    $.nd.domContentUpdated = $.nd.delegate(true);

    function onDomContentUpdating(elm) {
        $.nd.domContentUpdating($(this));
    }
    function onDomContentUpdated() {
        $.nd.domContentUpdated($(this));
    }
    function onDomContentUpdatedEnqueue() {
        addToPending(this);
    }
    function addToPending(elm, isParent) {
        // add parent node, we don't want call handler for every node in collection
        var parentNode = isParent ? elm : elm.parentNode;
        if (parentNode && (!_pendingNodes.length || _pendingNodes[_pendingNodes.length - 1] !== parentNode)) {
            _pendingNodes.push(parentNode);
        }
    }

    //		function fixKoBindingHandler(handlers, name) {
    //			var _binding = ko.bindingHandlers[name];
    //			if (_binding.init) {
    //				var _binding_init_Original = _binding.init;
    //				_binding.init = function (element, valueAccessor, allBindingsAccessor) {
    //					var locked = element._koBindingLocked;
    //					element._koBindingLocked = true;
    //					_binding_init_Original.apply(this, arguments);
    //					if (!locked) {
    //						element._koBindingLocked = false;
    //						setTimeout(function () { $.nd.domContentUpdated($(element)); }, 0);
    //					}
    //				};
    //			}
    //		}
    //		for (var name in ko.bindingHandlers) {
    //			fixKoBindingHandler(ko.bindingHandlers, name);
    //		}

    var _pendingNodes = [];
    var _timeout;
    function executePendingEvents() {
        _timeout = 0;
        for (var i = 0; i < _pendingNodes.length; i++) {
            $.nd.domContentUpdated($(_pendingNodes[i]));
        }
        _pendingNodes.length = 0;
    }

    if (typeof ko !== 'undefined') {
        (function () {
            ko.safeCleanNode = function (node) {
                try {
                    node._safeCleanNode = true;

                    if (node.nodeType === 1 && node.parentNode) {
                        $.nd.domContentUpdating($(node));
                    }
                    return ko.cleanNode(node);
                } finally {
                    delete node['_safeCleanNode'];
                }
            };
            var jcleanData = $.cleanData;
            $.cleanData = function (nodes) {
                // ko.cleanNode will call $.cleanData to clean jquery data
                // but we don't want to do that for current node
                if (nodes.length === 1 && nodes[0] && nodes[0]._safeCleanNode === true) return this; // ignore

                return jcleanData.call($, nodes);
            };
        })();
    }

    $.each('remove empty html domManip'.split(' '), function (i, name) {
        var original = $.fn[name];
        $.fn[name] = function () {
            if (!this.length) {
                return this;
            }

            if (!_timeout) {
                _timeout = setTimeout(executePendingEvents, 0);
            }

            // reenable
            //// disable updating event
            if (this.length === 1) {
                //if (this[0].parentNode) {
                //	onDomContentUpdating(this[0].parentNode);
                //}
                $.nd.domContentUpdating(this);
            } else {
                //if (this[0].parentNode) {
                //	onDomContentUpdating(this[0].parentNode);
                //}
                this.each(onDomContentUpdating);
            }

            var parentNode = this[0].parentNode;

            original.apply(this, arguments);

            addToPending(parentNode, true);

            //				if (name === "remove") {
            //					// some child was removed from parent
            //					// so notify that parent node is changed
            //					if (_updatingCollection) {
            //						addToPending(parentNode, true);
            //					} else {
            //						$.nd.domContentUpdated($(parentNode));
            //					}
            //				} else {
            //					if (_updatingCollection) {
            //						this.each(onDomContentUpdatedEnqueue);
            //					} else {
            //						this.each(onDomContentUpdated);
            //					}
            //				}

            return this;
        };
    });
})();

// scroller widget
(function () {
    var expandDo = 'scroller',
        configAttribute = 'nd-scrollable',
        selector = '[nd-scrollable]';

    // example
    // <div nd-scrollable="yes">
    // </div>
    // <div nd-scrollable='{"delayLoadImages": true}'>
    //  <div>
    //		<ul>
    //			<li><span delay-image-src="http://images.pressdisplay.com/docserver/..."></li>
    //			<li><span delay-image-src="http://images.pressdisplay.com/docserver/..."></li>
    //		</ul>
    //  </div>
    // </div>

    $.nd.domContentUpdating(function (jObj) {
        // the method is called before updating child nodes (removing?)
        // so destroy scrollers for all child nodes
        destroyScroller(jObj);
    });
    $.nd.domContentUpdated(function (jObj) {
        // the method is called after updating child nodes (new nodes added?)
        // so enable scroll for new child nodes
        enableScroller(jObj);

        // TODO:
        // also parent nodes could have scroller
        // so we need notify it that content is changed
        var disableParentUpdate = JSON.parse(jObj.attr('nd-no-parent-update') || 'false');
        if (!disableParentUpdate) {
            checkParentScroller(jObj);
        }

        // TODO:
        // in enableScroller we should subscribe size changed, see Dialog???

        // loadImagesForScroller??
    });

    function destroyScroller(jObj) {
        jObj.find(selector).each(function () {
            var self = $(this),
                scroll = self.data(expandDo);
            if (scroll) {
                scroll.__self = null;
                scroll.destroy();
                self.data(expandDo, null);
            }
        });
    }
    function enableScroller(jObj) {
        // attention: scroller should have relative position and fixed size (height)
        jObj.find(selector)
            .css('position', 'relative')
            .each(function () {
                var self = $(this);

                if ($.browser.iOS && typeof self.attr('enableNativeScroll') !== 'undefined' && '-webkit-overflow-scrolling' in document.body.style) {
                    self.css({ '-webkit-overflow-scrolling': 'touch', overflow: 'auto' });
                    return;
                }

                // make sure the scroller is not inited
                if (self.data(expandDo)) {
                    self = null;
                    return;
                }

                var cfg = this.getAttribute(configAttribute);
                if (cfg && cfg.length > 0 && cfg[0] === '{') {
                    cfg = JSON.parse(cfg);
                } else {
                    cfg = { delayLoadImages: false };
                }

                function fireScrolled(finalPos) {
                    var self = scroll.__self;
                    if (self) {
                        var top = -(finalPos ? finalPos.top : scroll.y);
                        var height = scroll.wrapperH; // //scroll.wrapper.offsetHeight;
                        var bottom = top + height;
                        var contentHeight = scroll.scrollerH; //scroll.scroller.offsetHeight;
                        var scale = bottom / contentHeight; //1
                        var belowBottomScreens = (contentHeight - bottom) / height;

                        if (contentHeight && height) {
                            self.triggerHandler('scrolled', { scroller: scroll, scrolled: scale, belowBottomScreens: belowBottomScreens });
                        }
                    }
                }

                var scroll = new iScroll(this, {
                    //hideScrollbar: true,
                    useTransition: true,
                    // override onBeforeScrollStart to fix the problem with input
                    onBeforeScrollStart: function (event) {
                        if (!event) {
                            event = window.event;
                        }
                        var targetElement = event.target || event.srcElement;

                        // do not prevent default if target is input or select
                        this.onStartDefaultPreserved = false;
                        var exceptions = { a: 1, input: 1, select: 1, textarea: 1, button: 1 };
                        if (exceptions[targetElement.nodeName.toLowerCase()] || $(targetElement).attr('nd-text-select')) {
                            this.onStartDefaultPreserved = true;
                            return;
                        }

                        // this is default iScroll's implementation
                        if (event.preventDefault) event.preventDefault();
                        else event.returnValue = false;
                    },
                    onScrollStart: function (event) {
                        if (!event) {
                            event = window.event;
                        }

                        event = $.nd.fixTouchEvent(event);
                        var scroll = this;
                        //scroll._cancelNextClick = true;
                        scroll._screenX = event.screenX;
                        scroll._screenY = event.screenY;
                    },
                    onBeforeScrollEnd: function (event) {
                        if (!event) {
                            event = window.event;
                        }
                        var targetElement = event.target || event.srcElement;

                        fireScrolled();

                        event = $.nd.fixTouchEvent(event);
                        var scroll = this;
                        if (scroll.moved) {
                            if (targetElement && targetElement.nodeName == 'INPUT') {
                                return;
                            }
                            // prevent default action (this will cancel click in iphone)
                            if (event.preventDefault) event.preventDefault();
                            else event.returnValue = false;
                        } else {
                            // reset
                            scroll._cancelNextClick = false;
                        }
                    },
                    onAnimationStart: function (finalPos) {
                        // onAnimationStart is newspaperdirect's function that was added to iScroll
                        // to preload images
                        fireScrolled(finalPos);
                        loadImagesForScroller(this, finalPos, 'onAnimationStart');
                    },
                    onAnimationEnd: function () {
                        loadImagesForScroller(this, null, 'onAnimationEnd');
                    },
                    onScrollEnd: function () {
                        fireScrolled();
                        if (!this.animating) {
                            // don't load images if an animation is active (its will be loaded on "onAnimationEnd")
                            loadImagesForScroller(this, null, 'onScrollEnd');
                        }
                    },
                });

                scroll._cfg = cfg;

                self.attr('resize-consumer', 'yes').resize(function (event) {
                    scroll.refresh();
                    loadImagesForScroller(scroll, null, 'resize');
                });

                var interval;
                self.find('img')
                    .filter(function (index) {
                        return !this.complete;
                    })
                    .on('load.iscroll', function () {
                        if (interval) {
                            clearInterval(interval);
                        }
                        interval = setInterval(function () {
                            // if disposed skip refreshing
                            if (scroll.wrapper) scroll.refresh();
                            clearInterval(interval);
                        }, 3000);
                        $(this).off('load.iscroll');
                    });

                //self.find('img').on("load", function () {
                //    scroll.refresh();
                //});

                self.attr('content-changed-consumer', 'yes').on('contentChanged', function (event) {
                    scroll.refresh();
                    loadImagesForScroller(scroll, null, 'contentChanged');
                });

                // make sure a click event will not fired in child nodes if panel was scrolled
                var subscription = ClickEventStream.create(self[0]).subscribe(function (event) {
                    scroll._cancelNextClick = false;
                    if (!scroll._cancelNextClick) {
                        //also make sure cursor/touch wasn't moved (sometimes when the browser is busy, ie after we update content in panel, the _cancelNextClick is false)
                        if (Math.abs(event.screenX - scroll._screenX) > 2 || Math.abs(event.screenY - scroll._screenY) > 2) {
                            scroll._cancelNextClick = true;
                        }
                    }
                    if (scroll._cancelNextClick) {
                        event.stopPropagation();
                    }
                });

                self.data('_subscription', subscription);
                scroll.__self = self;
                self.data(expandDo, scroll);

                self = null;
            });
        jObj = null;
    }

    function checkParentScroller(jObj) {
        jObj.parents(selector).each(function () {
            var self = $(this),
                scroll = self.data(expandDo);
            if (scroll) {
                if (scroll._ndAppendingChilds) {
                    // appending content to scroll, ignore current event
                    scroll._ndAppendingChilds = false;
                    return;
                }

                // reset position
                scroll._nd_scrollTo = true;
                scroll.scrollTo(0, 0, 0);
                scroll._nd_scrollTo = false;
                // refresh
                scroll.refresh();
                // check images
                loadImagesForScroller(scroll, null, 'checkParentScroller');
            }
        });
    }

    function imageOnLoadedToFixRetina() {
        this.width = Math.floor(this.offsetWidth / 2);
        this.onload = null;
    }

    var regexParameterCache = {};

    function getParameterByName(url, name) {
        var regex = regexParameterCache[name];
        if (!regex) {
            regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            regexParameterCache[name] = regex;
        }
        var results = regex.exec(url);
        return results == null ? '' : decodeURIComponent(results[1]);
    }
    function loadImage(img, srcInfo) {
        if (srcInfo && srcInfo.retina) {
            if (srcInfo.targetWidth) {
                img.width = srcInfo.targetWidth;
            } else {
                img.onload = imageOnLoadedToFixRetina;
            }
        }
        img.src = srcInfo.src;
        return img;
    }

    function parseImageUrl(src) {
        var retina = getParameterByName(src, 'retina');
        if (retina) {
            src = src.replace('&retina=' + retina, '');
            var targetWidth = getParameterByName(src, 'targetWidth');
            if (targetWidth) {
                src = src.replace('&targetWidth=' + targetWidth, '');
            }
        }
        return {
            src: src,
            retina: retina,
            targetWidth: targetWidth,
        };
    }

    function resetImage(img) {
        img.src = null;
        delete img['width']; //reset width for retina
        return img;
    }

    function loadImagesForScroller(scroll, finalPos, eventName, inAnimationFrame) {
        var _cfg = scroll._cfg;
        if (!_cfg || !_cfg.delayLoadImages) {
            return;
        }

        if (scroll._ndUpdating) {
            return;
        }

        // disable preloading images while animation because it is very slow on ipad
        // however on desktop it works fine
        if (finalPos) {
            return;
        }

        if (!inAnimationFrame) {
            if (scroll._pendingForLoadImagesForScroller) {
                return;
            }
            scroll._pendingForLoadImagesForScroller = true;

            requestAnimationFrame(function () {
                scroll._pendingForLoadImagesForScroller = false;
                loadImagesForScroller(scroll, finalPos, eventName, true);
            });
            return;
        }

        //could use the "elementFromPoint" to find the first visible image???

        var parent = $(scroll.scroller);
        // ensure the element is visible, it is required to correct position calculations
        if (!parent.is(':visible')) {
            parent.length = 0;
            parent = null;
            scroll = null;
            return;
        }

        var top = -scroll.y;
        var height = scroll.wrapperH;
        var contentHeight = scroll.scrollerH;

        //var wrapper = $(scroll.wrapper);
        //if (Math.abs(height - wrapper.height()) > 10) {
        //	alert("diff: " + height + ", " + wrapper.height());
        //}
        //if (Math.abs(contentHeight - parent.height()) > 10) {
        //	alert("diff2: " + contentHeight + ", " + parent.height());
        //}

        var bottom = top + height;

        var elementsToLoadImages = [];

        if (height * 2 > contentHeight) {
            // if content is less that two screens then
            // load all images
            var delayedImages = parent.find('span[delay-active!="1"][delay-image-src]');
            for (var i = 0, l = delayedImages.length; i < l; i++) {
                elementsToLoadImages.push(delayedImages[i]);
            }
            loadImagesForElements(elementsToLoadImages);
            parent.length = 0;
            parent = null;
            scroll = null;
            delayedImages = null;
            return;
        }

        if (finalPos) {
            var top1 = -finalPos.top;
            var bottom1 = top1 + height;

            if (top1 > top) {
                // animation from top to bottom
                // add extra images
                top1 = Math.max(top1 - height, top);
            } else {
                // animation from bottom - top
                // add extra images
                bottom1 = Math.min(bottom1 + height, bottom);
            }

            top = top1;
            bottom = bottom1;
        }

        var elementsToCleanUp = [];

        // unload invisible images
        if (!finalPos) {
            // don't unload images if final position is set (finalPos is pos that will be after animation finished)

            // binary search??
            var delayedImages = parent.find('span[delay-active="1"]');
            for (var i = 0, l = delayedImages.length; i < l; i++) {
                var elm = delayedImages[i];
                //var offset = elm._offset;
                var offset = null; // don't use cache, the values became outdated if filter widget is used
                if (!offset) {
                    offset = elm._offset = $.relativeOffset(elm, scroll.scroller);
                    offset.height = elm.offsetHeight;
                    offset.bottom = offset.top + offset.height;
                }

                if (offset.top > bottom || offset.bottom < top) {
                    elementsToCleanUp.push(elm);
                }
            }
        }

        var delayedImages = parent.find('span[delay-active!="1"][delay-image-src]');
        for (var i = 0, l = delayedImages.length; i < l; i++) {
            var elm = delayedImages[i];
            //var offset = elm._offset;
            var offset = null; // don't use cache, the values became outdated if filter widget is used
            if (!offset) {
                offset = elm._offset = $.relativeOffset(elm, scroll.scroller);
                offset.height = elm.offsetHeight;
                offset.bottom = offset.top + offset.height;
            }
            if (offset.top <= bottom && offset.bottom >= top) {
                elementsToLoadImages.push(delayedImages[i]);
            }
        }

        cleanUpElemenets(elementsToCleanUp);
        loadImagesForElements(elementsToLoadImages);

        parent.length = 0;
        parent = null;
        scroll = null;
        delayedImages = null;
    }

    function cleanUpElemenets(elements) {
        for (var i = 0, l = elements.length; i < l; ++i) {
            var elm = elements[i];
            elm.setAttribute('delay-active', '0');
            elm.removeChild(elm.firstChild);
            //elm.innerHTML = "";
        }
        elements.length = 0;
    }

    function loadImagesForElements(elements) {
        var useBackground = $.browser.iOS === true;
        var useImageElm = false;

        for (var i = 0, l = elements.length; i < l; ++i) {
            var elm = elements[i];

            var src = elm.getAttribute('delay-image-src');
            var srcInfo = parseImageUrl(src);
            if (srcInfo.retina) {
                if (!srcInfo.targetWidth) {
                    useBackground = false;
                    useImageElm = true;
                }
                src = srcInfo.src;
            }

            if (useBackground && srcInfo.targetWidth) {
                elm.innerHTML =
                    '<div style="height: ' +
                    elm.offsetHeight +
                    'px; background: url(' +
                    src +
                    ') top center no-repeat;background-size: ' +
                    srcInfo.targetWidth +
                    'px;"></div>';
            } else if (useBackground) {
                elm.innerHTML = '<div style="height: ' + elm.offsetHeight + 'px; background: url(' + src + ') top center no-repeat;"></div>';
            } else if (useImageElm) {
                var img = document.createElement('img');
                elm.appendChild(loadImage(img, srcInfo));
            } else if (srcInfo.targetWidth) {
                elm.innerHTML = '<img src="' + src + '" width="' + srcInfo.targetWidth + '">';
            } else {
                elm.innerHTML = '<img src="' + src + '">';
            }
            elm.setAttribute('delay-active', '1');
        }
        elements.length = 0;
    }

    widgets.loadImagesForScroller = loadImagesForScroller;
    widgets.findScrollerElm = function (jObj) {
        if (jObj.data(expandDo)) {
            return jObj;
        }
        var result;
        // check parent
        jObj.parents(selector).each(function () {
            var self = $(this);
            if (self.data(expandDo)) {
                result = self;
                return false;
            }
        });
        jObj = null;
        return result;
    };
    widgets.findScroller = function (jObj) {
        var result = jObj.data(expandDo);
        if (!result) {
            // check parent
            jObj.parents(selector).each(function () {
                var self = $(this),
                    scroll = self.data(expandDo);
                if (scroll) {
                    result = scroll;
                    return false;
                }
            });
        }
        jObj = null;
        return result;
    };
})();

// template filter widget
(function () {
    var expandDo = 'tmplFilter',
        configAttribute = 'nd-tmpl-filter',
        selector = '[nd-tmpl-filter]';

    // example
    // <input nd-tmpl-filter='{"targetSelector":".newspapersTemplate", "collName": "newspapers","fieldName": "name"}' type="text">
    // <ul class="pop-list newspapersTemplate">
    //	{{tmpl($item.data.newspapers||[]) "#newspaperTemplate"}}
    // </ul>

    $.nd.domContentUpdating(function (jObj) {
        // the method is called before updating child nodes (removing?)
        // so destroy scrollers for all child nodes
        //destroyScroller(jObj);
    });
    $.nd.domContentUpdated(function (jObj) {
        // the method is called after updating child nodes (new nodes added?)
        // so enable filter for child nodes
        enableFilter(jObj);
    });

    function enableFilter(jObj) {
        jObj.find(selector).each(function () {
            var self = $(this);
            // make sure the filter is not inited
            if (self.data(expandDo)) {
                return;
            }

            var cfg = JSON.parse(this.getAttribute(configAttribute));
            var targetSelector = cfg.targetSelector;
            var collName = cfg.collName;
            var fieldName = cfg.fieldName;

            var resetSelector = cfg.resetSelector;
            if (resetSelector) {
                $(resetSelector).on('click', function () {
                    self.val('');
                    applyFilter();
                });
            }

            var item = $(targetSelector).tmplItem();
            var coll;
            function getColl() {
                if (!coll) {
                    // find up-to-date  item
                    item = $(targetSelector).tmplItem();
                    coll = widgets.getTmplItemColl(item, collName);
                    if (coll) {
                        coll.sourceChanged(function () {
                            self.val('');
                            this.resetFilter();
                        });
                    }
                }
                return coll;
            }

            self.on('touchstart mousedown', function (e) {
                e.stopPropagation();
                $(this).focus();
            });
            var _timer;
            self.on('keyup', function (event) {
                // execute async to allow fast typing
                if (_timer) {
                    clearTimeout(_timer);
                }
                var key = event.keyCode;
                if (key == 13 || key == 10) {
                    // try to close keyboard in ipad
                    $(this).blur();
                    // apply filter immediately
                    applyFilter();
                    return false;
                }
                _timer = setTimeout(timeout_applyFilter, 500);
            });

            function timeout_applyFilter() {
                _timer = 0;
                applyFilter();
            }
            function applyFilter() {
                if (_timer) {
                    clearTimeout(_timer);
                    _timer = 0;
                }
                if (getColl()) {
                    getColl().setFilter(self.val(), fieldName);
                }
            }

            self.data(expandDo, 'inited');
        });
    }
})();

// pagination widget
(function () {
    var expandDo = 'tmplPagination',
        configAttribute = 'nd-tmpl-pagination',
        selector = '[nd-tmpl-pagination]';

    // example
    // <ul class="pop-list newspapersTemplate" nd-tmpl-pagination='{"pageSize":10}'>
    //	{{tmpl($item.data.newspapers||[]) "#newspaperTemplate"}}
    // </ul>

    $.nd.domContentUpdating(function (jObj) {
        // the method is called before updating child nodes (removing?)
        // so destroy scrollers for all child nodes
        destroy(jObj);
    });

    $.nd.domContentUpdated(function (jObj) {
        init(jObj);
    });

    function destroy(jObj) {
        jObj.find(selector).each(function () {
            var self = $(this),
                dispose = self.data(expandDo);

            if (dispose) {
                dispose();
                var subscription = self.data('_subscription');
                if (subscription) {
                    subscription.unsubscribe();
                }
            }
        });
    }
    function init(jObj) {
        jObj.find(selector).each(function () {
            var self = $(this);
            // make sure the filter is not inited
            if (self.data(expandDo)) {
                return;
            }

            var item = self.tmplItem();
            if (item) {
                var cfg = JSON.parse(this.getAttribute(configAttribute));
                var pageSize = cfg.pageSize;
                var collName = cfg.collName;

                var scrollerElm = widgets.findScrollerElm(self);
                if (scrollerElm) {
                    var coll;
                    var getColl = function () {
                        if (!coll) {
                            // find up-to-date  item
                            item = self.tmplItem();
                            coll = widgets.getTmplItemColl(item, collName);
                            if (coll) {
                                coll.setPageSize(pageSize);

                                coll.changed(function (appended) {
                                    var scroller = widgets.findScroller(scrollerElm);
                                    if (scroller) {
                                        if (!appended) {
                                            // reset position
                                            scroller._nd_scrollTo = true;
                                            scroller.scrollTo(0, 0, 0);
                                            scroller._nd_scrollTo = false;
                                        }
                                        scroller.refresh();
                                        widgets.loadImagesForScroller(scroller);
                                    }
                                });
                            }
                        }
                        return coll;
                    };
                    getColl();

                    scrollerElm.bind('scrolled', function (event, arg) {
                        if (!self.data(expandDo)) {
                            // "self" elm was removed from dom
                            // so ignore this event;
                            // TODO: unsubscribe
                            return;
                        }

                        if (arg.belowBottomScreens < 3) {
                            if (arg.scroller._nd_scrollTo) return;

                            var coll = getColl();
                            if (coll) {
                                // prevent scroll reset
                                arg.scroller._ndAppendingChilds = true;

                                coll.renderMore(pageSize);
                                //arg.scroller.refresh();
                                return;
                            }
                        }
                    });
                }
            }

            function dispose() {
                scrollerElm = null;
                self = null;
            }
            self.data(expandDo, dispose);
        });
    }
})();

// new pagination widget
(function () {
    var expandDo = 'tmplPagination',
        configAttribute = 'nd-tmpl-pagination-new',
        selector = '[nd-tmpl-pagination-new]';

    // example
    // <ul class="pop-list newspapersTemplate" nd-tmpl-pagination='{"pageSize":10}'>
    //	{{tmpl($item.data.newspapers||[]) "#newspaperTemplate"}}
    // </ul>

    $.nd.domContentUpdating(function (jObj) {
        // the method is called before updating child nodes (removing?)
        // so destroy scrollers for all child nodes
        destroy(jObj);
    });
    $.nd.domContentUpdated(function (jObj) {
        init(jObj);
    });
    function destroy(jObj) {
        jObj.find(selector).each(function () {
            var dispose = $(this).data(expandDo);
            if (dispose) {
                dispose();
            }
        });
    }
    function init(jObj) {
        jObj.find(selector).each(function () {
            var self = $(this);
            // make sure the filter is not inited
            if (self.data(expandDo)) {
                return;
            }

            var item = ko.dataFor(self[0]); //self.tmplItem();
            if (item) {
                var cfg = JSON.parse(this.getAttribute(configAttribute));
                var pageSize = cfg.pageSize;
                var collName = cfg.collName;

                var scrollerElm = widgets.findScrollerElm(self);
                if (scrollerElm) {
                    var coll;
                    var getColl = function () {
                        if (!coll) {
                            // find up-to-date  item
                            item = ko.dataFor(self[0]); //self.tmplItem();
                            coll = widgets.getTmplItemCollNew(item, collName);
                            if (coll) {
                                coll.setPageSize(pageSize);

                                coll.changed(function (appended) {
                                    if (!scrollerElm) return;

                                    var scroller = widgets.findScroller(scrollerElm);
                                    if (scroller) {
                                        if (!appended) {
                                            // reset position
                                            scroller._nd_scrollTo = true;
                                            scroller.scrollTo(0, 0, 0);
                                            scroller._nd_scrollTo = false;
                                        }
                                        scroller.refresh();
                                        widgets.loadImagesForScroller(scroller);
                                    }
                                });
                            }
                        }
                        return coll;
                    };
                    getColl();
                    ///var _originalItems = item.data ? item.data[collName] : null;

                    scrollerElm.on('scrolled', function (event, arg) {
                        if (!self.data(expandDo)) {
                            // "self" elm was removed from dom
                            // so ignore this event;
                            // TODO: unsubscribe
                            return;
                        }

                        if (arg.belowBottomScreens < 3) {
                            if (arg.scroller._nd_scrollTo) return;

                            var coll = getColl();
                            if (coll) {
                                // prevent scroll reset
                                arg.scroller._ndAppendingChilds = true;

                                coll.renderMore(pageSize);
                                //arg.scroller.refresh();
                                return;
                            }
                        }
                    });
                }
            }

            function dispose() {
                if (scrollerElm) {
                    scrollerElm.off('scrolled');
                    scrollerElm = null;
                }
                self = null;
            }
            self.data(expandDo, dispose);
        });
    }
})();
