enum ReCaptchaVersion {
    v2 = 2,
    v3 = 3,
}

interface IReCaptchaConfig {
    enabled: boolean;
    siteKey: string | null;
}

export { IReCaptchaConfig, ReCaptchaVersion };
