export enum Align {
    Center = 0, // default
    Left = 1,
    Right = 2,
    Top = 3,
    Bottom = 4,
    ScreenCenter = 10,
    ScreenLeft = 11,
    ScreenRight = 12,
    ScreenTop = 13,
    ScreenBottom = 14,
}
