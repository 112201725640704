import * as ko from 'knockout';
import validateInfo from 'ko/bindings/validate.shared';
        

        function validationContainerBindingHandler() {

            var errorCssClass = "error",
                validationMsgClassName = "validation-msg",
                bindingHandlers = ko.bindingHandlers;

            return {
                init: function (elm, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                    var value = valueAccessor();
                    if (value) {
                        // supports validation
                        if (value.hasErrors) {
                            var span = document.createElement("span");
                            span.className = validationMsgClassName;
                            span.appendChild(document.createElement("span"));

                            elm.insertBefore(span, null);
                        } else {
                            console.warn('Init "validationContainer" binding: ignored because it bound to observable that has no validation extension.');
                        }
                    }
                },

                update: function (elm, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {

                    var value = valueAccessor();
                    if (!value) { return; }
                    if (!value.hasErrors) {
                        console.warn('Update "validationContainer" binding: ignored because it bound to observable that has no validation extension.');
                        return;
                    }

                    var validationSpan = elm.getElementsByClassName(validationMsgClassName)[0],
                        innerSpan = validationSpan.lastChild,
                        validationAttempted = bindingContext[validateInfo.validationAttemptedBindingName],
                        textAccessor = value.validationMessage,
                        visibleAccessor = function () {
                            var hasErrors = value.hasErrors(),
                                attemptedDefined = !!validationAttempted,
                                isDirtyDefined = !!value.isDirty,
                                attempted = attemptedDefined ? validationAttempted() : false,
                                isDirty = isDirtyDefined ? value.isDirty() : false;

                            // if both attempted and isDirty undefined, ignore them
                            // in order to switch visible state properly
                            if (!attemptedDefined && !isDirtyDefined)
                                return hasErrors;

                            return hasErrors && (attempted || isDirty);
                        },
                        hasErrorClass = visibleAccessor();

                    bindingHandlers.text.update(innerSpan, textAccessor);
                    bindingHandlers.visible.update(validationSpan, visibleAccessor);
                    validationAttempted && validationAttempted.subscribe(function () {
                        bindingHandlers.visible.update(validationSpan, visibleAccessor);
                    });

                    ko.utils.toggleDomNodeCssClass(elm, errorCssClass, hasErrorClass);
                }
            }
        }

        ko.bindingHandlers['validationContainer'] = validationContainerBindingHandler();
    