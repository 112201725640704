import { IRect } from '@pressreader/geometry';

export enum ScreenOrientation {
    Landscape,
    Portrait,
}

export enum ScreenSizeType {
    XSmall,
    Small,
    Medium,
    Large,
    XLarge,
}

export type DeviceType = 'phone' | 'tablet' | 'desktop';

export interface IScreenInfo {
    /** Width of the screen. */
    screenWidth: number;

    /** Height of the screen. */
    screenHeight: number;

    screenType: ScreenSizeType;

    /** Usable width of the screen without browser interface features. */
    availWidth: number;

    /** Usable height of the screen without browser interface features. */
    availHeight: number;

    /** NOTE: this value is omitted since on mobile devices notifying about height change will trigger a lot of re-renders. */
    // height: number;
    orientation: ScreenOrientation;

    devicePixelRatio: number;

    headerHeight: number;

    /** Viewport of the usable screen region (availWidth x availHeight). */
    viewport: IRect;
}

export interface ISwiperConfig {
    threshold: number;
}
