import { merge } from 'lodash';

import { ISubscriptionProductDto } from '@pressreader/commerce-api';
import { extractISODateString, isIssueKey } from '@pressreader/content-utils';
import { ViewName } from '@pressreader/navigation-types';
import ndUtilsTranslation from '@pressreader/src/nd.utils.translation';
import { getActiveViewName } from '@pressreader/src/nd.viewer.views';
import { logError } from '@pressreader/src/shared/errors/error.handlers';
import { getUserLocale } from '@pressreader/src/user/locale';
import { hashCode } from '@pressreader/utils';

import ndUser from 'nd.user';
import ndViewerApp from 'nd.viewer.app';
import { IAnalyticsContext } from 'analytics/models/models';
import { getCurrentSubscription } from 'subscriptions/services';

class AnalyticsContext implements IAnalyticsContext {
    cid?: string;
    issueDate?: string;
    pageNumber?: string | number;
    hotspotSiteID?: string;
    zoomFactor?: number;
    experiment?: { name: string; variant: string };
    userCountry?: string;
    currentLanguage?: string;
    translatedLanguage?: string;
    promoCode?: string;
    subscriptionPlan?: string;
    contentName?: string;
    articleSource?: string;

    merge(other: IAnalyticsContext): IAnalyticsContext {
        other = other || {};
        this.refresh();
        return merge({}, this, other);
    }

    async refresh() {
        this.cid = undefined;
        this.contentName = undefined;
        this.issueDate = undefined;
        this.pageNumber = undefined;
        this.articleSource = undefined;

        if (getActiveViewName() === ViewName.PagesView || getActiveViewName() === ViewName.TextView) {
            this.cid = ndViewerApp.cid();

            this.contentName = ndViewerApp.contentName();

            const issue = ndViewerApp.issue();
            if (isIssueKey(issue)) {
                this.issueDate = extractISODateString(issue);
            }

            this.pageNumber = ndViewerApp.page();
        }

        const locale = await getUserLocale();
        this.userCountry = locale.country;

        this.currentLanguage = locale.language;

        updateTranslatedLanguageInfo();
    }
}

/**
 * Shared Analytics context.
 * Keeps common data, that will be sent to analytics with next trip.
 */
const currentAnalyticsContext = new AnalyticsContext();

// todo: find a better place for this code
const checkSumLength = 4;

function checkSum(input: string): string {
    const hash = hashCode(input);
    return String(`0000${hash}`).slice(-checkSumLength);
}

function getSubscriptionAnalyticsName({ subscriptionId, subscriptionName }: ISubscriptionProductDto) {
    const compactedSubscriptionName = subscriptionName.replace(/\s/g, '');
    const hash = checkSum(subscriptionId);
    return `${compactedSubscriptionName}-${hash}`;
}

function updateTranslatedLanguageInfo() {
    const currentLanguage = ndUtilsTranslation.current.language();
    if (currentLanguage) {
        currentAnalyticsContext.translatedLanguage = currentLanguage.code();
    } else {
        currentAnalyticsContext.translatedLanguage = undefined;
    }
}

// listen to context changes
ndUser.onUserStateChanged(() => {
    getCurrentSubscription()
        .then(subscription => {
            currentAnalyticsContext.subscriptionPlan = subscription ? getSubscriptionAnalyticsName(subscription) : undefined;
        })
        .catch(logError);
});

ndUtilsTranslation.bind('currentLanguageChanged', () => updateTranslatedLanguageInfo());

// initial setup
currentAnalyticsContext.refresh();

export { IAnalyticsContext, currentAnalyticsContext };
