import { call, fork, put, race, select, take, takeEvery, takeLeading } from 'redux-saga/effects';
import { ObservedValueOf } from 'rxjs';

import { appStarted, selectTargetPlatform } from '@pressreader/appcore';
import { createEventChannel } from '@pressreader/appstore';
import {
    getCurrentUserProfile,
    isUserAuthenticated,
    onLoginBeganEvent,
    onLoginDoneEvent,
    onLoginFailedEvent,
    onLogOutBeganEvent,
    onLogOutDoneEvent,
    onLogOutFailedEvent,
    onSignUpBeganEvent,
    onSignUpDoneEvent,
    onSignUpFailedEvent,
    recoverPasswordService,
    signInService,
    signOutService,
    signUpService,
} from '@pressreader/authentication-api';
import { AuthSignInSystemType, IUser, IUserSettings, UserChangeReason } from '@pressreader/authentication-types';
import { ensureConfigLoaded } from '@pressreader/config';
import { error } from '@pressreader/logger';
import { svcAuth } from '@pressreader/services';
import { ExtractReturnType, TargetPlatform } from '@pressreader/types';
import { feedSettings, globalSettings } from '@pressreader/usersettings';
import { getErrorMessage } from '@pressreader/utils';

import {
    authInitialized,
    loginAction,
    loginBegan,
    loginDone,
    loginFailed,
    logOutBegan,
    logOutDone,
    logOutFailed,
    logOutRequestAction,
    pwdRecoveryBegan,
    pwdRecoveryDone,
    pwdRecoveryFailed,
    recoverPwdAction,
    signUpAction,
    signUpBegan,
    signUpDone,
    signUpFailed,
    userChangeBegan,
    userChangeDone,
    userChangeFailed,
} from './actions';
import { createAnonymousUser, createDefaultUserSettings } from './utils';

async function loadUserSettings() {
    const shareEmailTask = isUserAuthenticated() ? globalSettings.get<string | null>('shareEmail', null) : Promise.resolve<string | null>(null);

    // Important: feedSettings for anonymous user have default locales
    const loadFeedSettingsTask = feedSettings.get();

    const [shareEmail, { following, locales }] = await Promise.all([shareEmailTask, loadFeedSettingsTask]);
    const userSettings: IUserSettings = {
        feedSettings: {
            locales,
            following,
        },
        shareEmail,
    };

    return userSettings;
}

async function loadUserInfo() {
    if (!isUserAuthenticated()) {
        return { user: createAnonymousUser(), userSettings: createDefaultUserSettings() };
    }

    const [profile, userSettings] = await Promise.all([getCurrentUserProfile(), loadUserSettings()]);

    let name: string = profile.nickname;
    if (name.indexOf('nickname') === 0) {
        name = profile.displayName || profile.nickname;
    }

    const user: IUser = {
        id: profile.profileId || profile.profileName,
        enAccountNumber: profile.enAccountNumber,
        userKey: `${svcAuth.userKey()}`,
        name: name,
        displayName: profile.displayName,
        avatarImageId: profile.avatarImageId,
        photo: { url: profile.photoUrl ?? '' },
        email: profile.email,
        isAuthenticated: true,
        enableServiceEmails: profile.enableServiceEmails,
        enablePromotionalEmails: profile.enablePromotionalEmails,
        refNumber: profile.refNumber,
    };

    return { user, userSettings };
}

function* login(action: ReturnType<typeof loginAction>) {
    const { loginAs } = action.payload;

    if (loginAs.system !== 'email') {
        return;
    }

    try {
        yield call(signInService, {
            userName: loginAs.email,
            password: loginAs.password,
            enableAutologin: loginAs.autologin,
        });
    } catch (e) {
        error(e);
    }
}

function* logOut() {
    try {
        yield call(signOutService);
    } catch (e) {
        error(e);
    }
}

function* signUp(action: ReturnType<typeof signUpAction>) {
    const { signUpAs } = action.payload;

    if (signUpAs.system !== AuthSignInSystemType.Email) {
        return;
    }
    try {
        yield call(signUpService, {
            emailAddress: signUpAs.email,
            password: signUpAs.password,
            firstName: signUpAs.firstName,
            lastName: signUpAs.lastName,
            enablePromotional: signUpAs.emailPromo,
            enableService: signUpAs.emailService,
            libraryUser: signUpAs.libraryUser,
        });
    } catch (e) {
        error(e);
    }
}

function* recoverPwd(action: ReturnType<typeof recoverPwdAction>) {
    const { email } = action.payload;

    try {
        yield put(pwdRecoveryBegan({ email }));

        yield call(recoverPasswordService, { userName: email });

        yield put(pwdRecoveryDone({ email }));
    } catch (e) {
        yield put(pwdRecoveryFailed({ email, error: getErrorMessage(e) }));
    }
}

function getChangeReason(raceResult: { login?: unknown; logout?: unknown; signup?: unknown }): UserChangeReason {
    if (raceResult.login) {
        return UserChangeReason.Login;
    }
    if (raceResult.logout) {
        return UserChangeReason.Logout;
    }
    if (raceResult.signup) {
        return UserChangeReason.SignUp;
    }

    return UserChangeReason.Unknown;
}

function* watchUserChange() {
    while (true) {
        const began: {
            login?: ReturnType<typeof loginBegan>;
            logout?: ReturnType<typeof logOutBegan>;
            signup?: ReturnType<typeof signUpBegan>;
        } = yield race({
            login: take(loginBegan),
            logout: take(logOutBegan),
            signup: take(signUpBegan),
        });

        yield put(userChangeBegan({ reason: getChangeReason(began) }));

        const complete: {
            done: {
                login: ReturnType<typeof loginDone>;
                logout: ReturnType<typeof logOutDone>;
                signup: ReturnType<typeof signUpDone>;
            };
            failed: {
                login: ReturnType<typeof loginFailed>;
                logout: ReturnType<typeof logOutFailed>;
                signup: ReturnType<typeof signUpFailed>;
            };
        } = yield race({
            done: race({
                login: take(loginDone),
                logout: take(logOutDone),
                signup: take(signUpDone),
            }),
            failed: race({
                login: take(loginFailed),
                logout: take(logOutFailed),
                signup: take(signUpFailed),
            }),
        });

        if (complete.done) {
            yield call(ensureConfigLoaded); // config can be changed when user got changed, ensure it is loaded
            yield put(userChangeDone({ reason: getChangeReason(complete.done) }));
        }

        if (complete.failed) {
            const failedAction = complete.failed.login || complete.failed.logout || complete.failed.signup;
            yield put(userChangeFailed({ reason: getChangeReason(complete.failed), error: failedAction.payload.error }));
        }
    }
}

function* onAppStarted() {
    const platform: ReturnType<typeof selectTargetPlatform> = yield select(selectTargetPlatform);

    if (platform === TargetPlatform.Voyager) {
        // voyager doesn't support authentication
        const user = createAnonymousUser();

        const userSettings = createDefaultUserSettings();

        yield put(authInitialized({ user, userSettings }));
        return;
    }

    const { user, userSettings }: ExtractReturnType<typeof loadUserInfo> = yield call(loadUserInfo);

    yield put(authInitialized({ user, userSettings }));
}

function* handleLoginDoneEvent({ payload: { system, provider } }: ObservedValueOf<typeof onLoginDoneEvent>) {
    const { user, userSettings }: ExtractReturnType<typeof loadUserInfo> = yield call(loadUserInfo);
    yield put(loginDone({ user, userSettings, system, provider }));
}
function* handleSignUpDoneEvent({ payload: { sponsorId, analyticsCtx } }: ObservedValueOf<typeof onSignUpDoneEvent>) {
    const { user, userSettings }: ExtractReturnType<typeof loadUserInfo> = yield call(loadUserInfo);
    yield put(signUpDone({ user, userSettings, emailValidationRequired: !!sponsorId, analyticsCtx }));
}

function* watchAuthEvents() {
    yield takeEvery(createEventChannel(onLoginBeganEvent), function* () {
        yield put(loginBegan({}));
    });

    yield takeEvery(createEventChannel(onLoginDoneEvent), handleLoginDoneEvent);

    yield takeEvery(createEventChannel(onLoginFailedEvent), function* ({ payload: { error, system } }) {
        yield put(loginFailed({ error, system }));
    });

    yield takeEvery(createEventChannel(onSignUpBeganEvent), function* () {
        yield put(signUpBegan({}));
    });

    yield takeEvery(createEventChannel(onSignUpDoneEvent), handleSignUpDoneEvent);

    yield takeEvery(createEventChannel(onSignUpFailedEvent), function* ({ payload: { error } }) {
        yield put(signUpFailed({ error }));
    });

    yield takeEvery(createEventChannel(onLogOutBeganEvent), function* () {
        yield put(logOutBegan());
    });

    yield takeEvery(createEventChannel(onLogOutDoneEvent), function* () {
        yield put(logOutDone());
    });

    yield takeEvery(createEventChannel(onLogOutFailedEvent), function* ({ payload: { error } }) {
        yield put(logOutFailed({ error }));
    });
}

function* saga() {
    yield* watchAuthEvents();

    yield fork(watchUserChange);

    yield takeEvery(appStarted, onAppStarted);
    yield takeLeading(loginAction, login);
    yield takeLeading(logOutRequestAction, logOut);
    yield takeLeading(signUpAction, signUp);
    yield takeLeading(recoverPwdAction, recoverPwd);
}

export { saga };
