import { Auth0Lock } from 'auth0-lock';
import { Subject } from 'rxjs';

import config from '@pressreader/src/deprecated/nd.config';
import { DialogStatus } from '@pressreader/src/nd.ui.dialogstatus';

import res from 'nd.res';

import { IIdentity } from './adapter';
import { ScreensetType } from './screenSets';

const Auth0Screensets = {
    [ScreensetType.signIn]: 'signIn',
};

export class Auth0 implements IIdentity {
    private loginSuccess = new Subject<any>();
    private modalClosed = new Subject<DialogStatus>();
    private _enabled: boolean;
    private _authenticated: boolean;
    private _logoutUrl: string;
    private _lock: any;

    private resMapping = {
        title: 'Dialogs.Signin.Header',
        signUpTitle: 'Dialogs.Signup.Header',
        signUpTerms: 'Dialogs.Signup.TermsOfUse',
        signUpLabel: 'Buttons.SignUp',
        signUpSubmitLabel: 'Buttons.SignUp',
    };

    async load(): Promise<void> {
        await Promise.all([config.loaded(), res.loaded()]);

        const auth0Config = config.get('Authentication.Auth0');
        if (!auth0Config || !auth0Config.enabled) {
            return Promise.reject();
        }
        this._enabled = true;
        this._logoutUrl = auth0Config.logoutUrl;

        for (const key of Object.keys(this.resMapping)) {
            this.resMapping[key] = res.val(this.resMapping[key]) || '';
        }

        auth0Config.options.languageDictionary = {
            ...auth0Config.options.languageDictionary,
            ...this.resMapping,
        };

        try {
            this._lock = new Auth0Lock(auth0Config.clientId, auth0Config.domain, auth0Config.options);

            this._lock.on('authenticated', (result: any) => {
                this._authenticated = true;
                this._lock.hide();
                this.loginSuccess.next(result);
            });

            this._lock.on('hide', () => this.modalClosed.next(this._authenticated ? DialogStatus.Ok : DialogStatus.Cancel));

            this._lock.checkSession(
                {
                    responseType: 'token id_token',
                    scope: 'openid profile email',
                    timeout: 5000,
                    redirectUri: auth0Config.callbackUrl,
                },
                (err: any, result) => {
                    if (!err && result.accessToken) {
                        this.loginSuccess.next(result);
                    }
                },
            );
        } catch (error) {
            return Promise.reject();
        }

        return Promise.resolve();
    }

    signIn(): void {
        this.showScreenSet(ScreensetType.signIn);
    }

    signOut(): void {
        this._lock.logout({
            returnTo: this._logoutUrl,
        });
    }

    closeModal(): void {
        this._lock.hide();
    }

    showScreenSet(screenType: ScreensetType): void {
        const screen = Auth0Screensets[screenType];
        if (!screen) {
            throw new Error(`screen ${screenType} is not defined.`);
        }

        switch (screenType) {
            case ScreensetType.signIn:
                this._lock.show();
                break;
            default:
                throw new Error('Method not implemented.');
        }
    }

    showInlineScreenSet(screenType: ScreensetType, containerSelector: string): void {
        throw new Error('Method not implemented.');
    }

    get onLoginSuccess() {
        return this.loginSuccess.asObservable();
    }

    get onModalClosed() {
        return this.modalClosed.asObservable();
    }

    get enabled() {
        return this._enabled;
    }
}
