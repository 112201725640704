import * as $ from 'jquery';
import { escapeRegExp, isBoolean, isString, isEmpty } from 'lodash';
import $ndRes from 'deprecated/nd.res';
import * as dateUtils from 'utils/date';
import 'nd.core';
$.nd.utils = {
    /*
     * @text - input text
     * @maxWordLength - number of symbols to insert hyphen after. Default value = 25.
     * @returns - text with soft html hyphens (&shy;)
     **/
    hyphenate: function (text, maxWordLength) {
        text = text || '';
        maxWordLength = maxWordLength || 25;
        var words = text.split(' ');
        var maxSegmentLength = 10000;
        for (var i = 0; i < words.length; i++) {
            var word = words[i];
            if (word) {
                var _segments = [];
                words[i] = word;
                if (word.length > maxWordLength) {
                    /* "test<p>test2</p>test3" => ["test", "<p>", "test2", "</p>", "test3"] */
                    var separator = '\xB6';
                    var arr = word
                        .replace(/</g, separator + '<')
                        .replace(/>/g, '>' + separator)
                        .replace(/&/g, separator + '&')
                        .replace(/;/g, ';' + separator)
                        .split('\xB6');
                    for (var ii = 0; ii < arr.length; ii++) {
                        if (arr[ii].indexOf('<') > -1 || arr[ii].indexOf('>') > -1 || arr[ii].length < maxWordLength) {
                            /* add tags (<p>, <div>, etc.) as-is */
                            _segments.push(arr[ii]);
                        } else {
                            /* add soft hyphenation after each (maxWordLength) chars */
                            var str = arr[ii];
                            while (str.length > maxWordLength) {
                                _segments.push(str.substr(0, maxWordLength) + '&shy;');
                                str = str.substr(maxWordLength);
                            }
                            _segments.push(str);
                        }
                    }

                    words[i] = _segments.join('');
                }
            }
        }
        return words.join(' ');
    },
    getLastVisitTime: function (data) {
        var now = new Date(data.DividerTime);
        var lastVisit = new Date(new Date().getTime() - data.Minutes * 60000);

        return {
            lastVisitDate: $ndRes.formatDate(now, 'DateFormat.LastVisitDate', 'dd MMM yyyy'),
            lastVisitTime: $ndRes.formatDate(now, 'DateFormat.LastVisitTime', 'h:mm TT'),
            timeElapsed: $ndRes.format('TextView.SinceLastVisit', dateUtils.getPrettyTimePeriod(lastVisit, 1)),
        };
    },
    getUrlParameters: () =>
        window.location.search
            .slice(1)
            .split('&')
            .reduce((acc, e) => {
                const match = e.match(/([^=]+)(=)(.*)|(.+)/);
                if (match) {
                    let { 1: key, 3: value, 4: altKey } = match;
                    key = decodeURIComponent(key || altKey || '');
                    value = decodeURIComponent(value || '');
                    if (key) {
                        if (acc.hasOwnProperty(key)) {
                            if (Array.isArray(acc[key])) {
                                acc[key].push(value);
                            } else {
                                acc[key] = [acc[key], value];
                            }
                        } else {
                            acc[key] = value;
                        }
                    }
                }
                return acc;
            }, {}),
    removeInvisibleHyphens: function (text) {
        return (text || '').replace(/\xAD/g, '');
    },
    convertToDate: function (str) {
        var d = new Date(str);
        if (isNaN(d.getTime())) {
            /* Safari returns 'Invalid Date' for string: "2015-07-15T00:00:00" */
            str = str.replace(/-/g, '/').replace('T', ' ');
            d = new Date(str);
        }
        return d;
    },
    escapeRegExp: function (searchQuery) {
        return escapeRegExp(searchQuery);
    },
    addUrlScheme: function (url) {
        if (!url) {
            return url;
        }

        if (url.indexOf('http:') == 0 || url.indexOf('https:') == 0) {
            return url;
        }
        return 'http://' + url;
    },
    /*
     * Creates filter function.
     *
     * Usage:
     *     var filter = $utils.createFilter('#$%^&!#*');
     *     filter('Austria', 'aus');//true
     *     filter('Austria', 'au1s');//false
     *     filter('Austria', null);//true
     **/
    createFilter: function (ignoreSymbols) {
        var _ignoreSymbols = (function (str) {
            var symbols = {};
            for (var i = 0; i < str.length; i++) {
                symbols[str[i].toLowerCase()] = '';
            }
            return symbols;
        })(ignoreSymbols || '');

        var extractCleanString = function (str) {
            return str.replace(/.?/g, function (s) {
                return (s || '').toLowerCase() in _ignoreSymbols ? '' : s.toLowerCase();
            });
        };

        return function (fullText, partText) {
            if (fullText && partText) {
                var searchWords = partText.replace(new RegExp(/\s+/g), ' ').split(' ').filter(extractCleanString);
                var matchPattern = searchWords.join('.*');

                var matches = fullText.toUpperCase().match(matchPattern.toUpperCase(), 'i');
                return !!(matches && matches.length);
            }

            return true;
        };
    },

    isCompactScreen: function () {
        return $('html').is('.screen-small, .screen-xsmall');
    },
    convertToBool: function (val) {
        if (isBoolean(val)) {
            return val;
        }
        if (isString(val)) {
            return val.trim().toLowerCase() === 'true';
        }
        return !!val;
    },

    isNil: function (o) {
        return o === undefined || o === null;
    },

    // Returns an object property value by first found key from the keys.
    findFirstKeyEntry(keys, object) {
        if (isEmpty(keys) || !object) return null;

        for (let i = 0; i < keys.length; i++) {
            if (keys[i] in object) {
                return object[keys[i]];
            }
        }
        return null;
    },

    arrayToHashSet(keys) {
        if (isEmpty(keys)) return {};

        return keys.reduce((accumulator, value) => {
            accumulator[value] = true;
            return accumulator;
        }, {});
    },

    showResourceNames: function () {
        var param = this.getUrlParameters();
        return this.convertToBool(param.showResourceNames);
    }
};

const toBool = $.nd.utils.convertToBool;

export { toBool };

export default $.nd.utils;
