import { storeManager } from '@pressreader/appstore';

import { MODULE_NAME } from '../constants';

import { reducer } from './reducer';
import { saga } from './sagas';

storeManager.addFeature(MODULE_NAME, reducer, saga);

export { startAppAction, toggleFaceliftUiAction, appStarted, changeTargetPlatformAction, coreModuleLoadDone, legacyPlatformChanged } from './actions';
export * from './selectors';
