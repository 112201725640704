import { isObject } from 'lodash';
import { interval } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import ndAlert from '@pressreader/src/nd.alert';
import { newGuid } from '@pressreader/utils';

import ndRes from 'nd.res';
import api from 'nd.services.api';

const serviceBasePath = 'v1/useraccounts';
const cancellableKey = newGuid();

/**
 * @typedef Location
 * @type Object
 * @property {Number} locationId - Id of location accordingly to the GeoNames database
 * @property {String} displayName - display name of location (country, city)
 * @property {String} country - country
 * @property {String} city - city, including region (state, province etc.)
 * @property {String} streetAddress - street address
 * @property {String} zipCode - Zip Code
 * @property {Number} lat - location latitude
 * @property {Number} lng - location longitude
 */

/**
 * @typedef Address
 * @type Object
 * @property {String} AddressLine1
 * @property {String} AddressLine2
 * @property {String} City
 * @property {String} CountryName
 * @property {String} CountryCode
 * @property {String} RegionCode
 * @property {String} RegionName
 * @property {String} PostalCode
 */

/**
 * @typedef AccountProfile
 * @type Object
 * @property {String} enAccountNumber - encrypted account number
 * @property {String} email - registration email (logon name)
 * @property {String} lastPasswordChangeDate - date of last password change
 * @property {String} displayName - display name
 * @property {String} firstName - first name
 * @property {String} lastName - last name
 * @property {String} phone - private contact phone
 * @property {String} website - user's website
 * @property {String} title - user's job title
 * @property {String} company - user's company
 * @property {String} publicEmail - public contact email
 * @property {String} publicPhone - public contact phone
 * @property {String} nickname - nickname
 * @property {String} profileName - name of profile, used for forming url of public profile (aka channel)
 * @property {String} photoUrl - path to user photo, relative to pressreader.com site
 * @property {Location} location - user's location
 * @property {Address} Address - user's Address
 */

function normalizeProfile(profile) {
    // deliberate side-effect, there is no need to create new object,
    // because argument isn't used anywhere else
    if (profile.lastPasswordChangeDate !== null) {
        profile.lastPasswordChangeDate = new Date(profile.lastPasswordChangeDate);
    }

    return profile;
}

/**
 * Gets profile of current user
 *
 * @returns {Promise.<AccountProfile>} profile
 */
const getProfile = () => api.get(serviceBasePath, 'current/profile').then(normalizeProfile);

/**
 * Gets profile of current user
 */
const getProfileConfig = () => api.get(serviceBasePath, 'current/profile/config');

/**
 * Updates profile of current user
 *
 * @param {Object} data - profile data to update
 * @returns Promise
 */
function updateProfile(data) {
    if (!isObject(data)) {
        return Promise.reject('data missing');
    }

    return api.put(serviceBasePath, 'current/profile', JSON.stringify(data), {
        contentType: 'application/json',
        disableRetry: true,
    });
}

/**
 * Updates location of current user
 *
 * @param {Address} data - location data to update
 * @returns Promise
 */
function updateAddress(data) {
    if (!isObject(data)) {
        return Promise.reject('data missing');
    }

    return api.put(serviceBasePath, 'current/address', JSON.stringify(data), {
        contentType: 'application/json',
        disableRetry: true,
    });
}

/**
 * Sets logon name for current user
 *
 * @param {String} logonName - logonName
 * @returns Promise
 */
function setLogonName(logonName) {
    if (!logonName) {
        return Promise.reject('email required');
    }

    return api.put(serviceBasePath, 'current/logonName', JSON.stringify({ logonName }), {
        contentType: 'application/json',
        disableRetry: true,
    });
}

/**
 * Sets email (logon name) for current user
 *
 * @param {String} email - email
 * @returns Promise
 */
function setEmail(email) {
    if (!email) {
        return Promise.reject('email required');
    }

    return api.put(serviceBasePath, 'current/email', JSON.stringify({ email }), {
        contentType: 'application/json',
        disableRetry: true,
    });
}

/**
 * Sets password for current user
 *
 * @param {String} newPassword - new password
 * @param {String} [currentPassword] - current password, not required if password assigned for the first time
 * @returns Promise
 */
function setPassword({ newPassword, currentPassword }) {
    if (!newPassword) {
        return Promise.reject(new Error('Password required'));
    }

    return api.put(serviceBasePath, 'current/password', JSON.stringify({ newPassword, currentPassword }), {
        contentType: 'application/json',
        disableRetry: true,
    });
}

/**
 * @typedef NotificationSettings
 * @type Object
 * @property {Boolean} enablePromotional - indicates whether promotional notifications enabled
 * @property {Boolean} enableTechnical - indicates whether technical notifications enabled
 */

/**
 * Gets notification settings of current user
 *
 * @returns {Promise.<NotificationSettings>} settings
 */
function getNotificationSettings() {
    return api.get(serviceBasePath, 'current/notificationsettings');
}

/**
 * Sets notification settings for current user
 *
 * @returns {Promise}
 */
function setNotificationSettings({ enablePromotional = false, enableTechnical = false }) {
    return api.put(serviceBasePath, 'current/notificationsettings', JSON.stringify({ enablePromotional, enableTechnical }), {
        contentType: 'application/json',
        disableRetry: true,
    });
}

/**
 * @typedef Address
 * @type Object
 *
 * @property {String} countryCode - country code
 * @property {String} regionCode - region (province, state) code
 * @property {String} regionName - manually entered region name
 * @property {String} city - city
 * @property {String} addressLine1 - address line
 * @property {String} postalCode - postal code (zip code)
 */

/**
 * @typedef CreditCard
 * @type Object
 *
 * @property {String} cardholderName - full name of card holder
 * @property {String} lastFourDigits - last four digits of card number
 * @property {String} cardType - type of credit card (Visa, Master etc.)
 * @property {Number} expirationMonth - a month of card expiration
 * @property {Number} expirationYear - a year of card expiration
 * @property {Address} billingAddress - a billing address associated with credit card
 */

function deleteCreditCard(): Promise<void> {
    return api.delete(serviceBasePath, 'current/creditcard', null, { disableRetry: true });
}

/**
 * Gets credit card of current user
 *
 * @returns {Promise.<CreditCard>} creditCard
 */
function getCreditCard() {
    return api.get(serviceBasePath, 'current/creditcard');
}

type UpdateCreditCardResult = {
    status: 'Approved' | 'Pending' | 'Declined' | 'Error';
    challengeUrl: string;
    updateId: string;
};

/**
 * Updates credit card of current user
 *
 * @param {Object} data - card data to update
 * @param {Number} data.expirationMonth - month of expiration period
 * @param {Number} data.expirationYear - year of expiration period
 * @param {String} data.cardNumber - clean card number (digits only)
 * @param {Number} data.cvv - CVV code of card
 * @param {Address} data.billingAddress - billing address
 * @returns Promise
 */
function updateCreditCard(data): Promise<UpdateCreditCardResult> {
    if (!isObject(data)) {
        return Promise.reject('data missing');
    }

    return api.put(serviceBasePath, 'current/creditcard', JSON.stringify(data), {
        contentType: 'application/json',
        disableRetry: true,
    });
}

// legacy API do not use for new features
async function updateCreditCardWithConfirmation(data): Promise<UpdateCreditCardResult> {
    let result = await updateCreditCard(data);

    if (result.status !== 'Pending') {
        return result;
    }

    let wnd = window.open(result.challengeUrl);
    if (!wnd) {
        await ndAlert().confirm(ndRes.val('v7.Client.Dialogs.ConfirmCreditCard'), ndRes.val('Buttons.Cancel.Text'), ndRes.val('Buttons.Confirm'));
        wnd = window.open(result.challengeUrl);
    }

    result = await awaitPendingCreditCardUpdate(result.updateId);
    wnd?.close();

    return result;
}

function checkCreditCardUpdate(updateId): Promise<UpdateCreditCardResult> {
    return api.get(serviceBasePath, `current/creditcard/updates/${updateId}`);
}

function awaitPendingCreditCardUpdate(updateId): Promise<UpdateCreditCardResult> {
    return new Promise(resolve => {
        // start polling
        const pollingIntervalMilliseconds = 1000;
        interval(pollingIntervalMilliseconds)
            .pipe(
                switchMap(() => checkCreditCardUpdate(updateId)),
                filter(({ status }) => status !== 'Pending'),
                take(1),
            )
            .subscribe(resolve);
    });
}

/**
 * @typedef ValidationFailure
 * @type Object
 *
 * @property {String} message - validation error message
 * @property {String} [resourceKey] - validation error message resource key
 * @property {String} [propertyName] - validation error message
 */

/**
 * @typedef ValidationResult
 * @type Object
 *
 * @property {Boolean} isValid - indicates if validation passed with positive result
 * @property {Array.<ValidationFailure>} errors - validation errors
 */

/**
 * Validates nickname for correctness and uniqueness.
 * @param {String} nickname
 * @returns Promise.<ValidationResult> validation result
 */
function validateNickname(nickname: string) {
    return api.post(serviceBasePath, 'current/profile/nickname/validate', JSON.stringify(nickname), {
        contentType: 'application/json',
        disableRetry: true,
        cancellableKey: `nickname_${cancellableKey}`,
    });
}

/**
 * Validates profile name for correctness and uniqueness.
 * @param {String} profileName
 * @returns Promise.<ValidationResult> validation result
 */
function validateProfileName(profileName) {
    return api.post(serviceBasePath, 'current/profile/profilename/validate', JSON.stringify(profileName), {
        contentType: 'application/json',
        disableRetry: true,
    });
}

/**
 * Uploads a photo of current user
 *
 * @param {File} file - native browser file object, supplied by input of type "file"
 * @returns {Promise} - when file uploaded
 */
function uploadPhoto(file) {
    const url = `${api.baseUrl()}social/profiles/current/photo`;

    const formData = new window.FormData();
    formData.append('PhotoFile', file);

    return api.post({
        requestConfig: { withCredentials: true },
        url: url,
        data: formData,
    });
}

/**
 * Delete account of current user
 *
 * @returns {Promise}
 */
function deleteAccount() {
    return api.delete(serviceBasePath, 'current', null, { disableRetry: true });
}

export {
    getProfile,
    getProfileConfig,
    updateProfile,
    updateAddress,
    setLogonName,
    setEmail,
    setPassword,
    getNotificationSettings,
    setNotificationSettings,
    deleteCreditCard,
    getCreditCard,
    updateCreditCardWithConfirmation,
    updateCreditCard,
    uploadPhoto,
    validateNickname,
    validateProfileName,
    deleteAccount,
};
