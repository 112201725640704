﻿import { ISize } from '@pressreader/geometry';

import * as defaultProvider from './imagesource.provider';
import { Issue, NewspaperOrCid } from './types';

const provider = { ...defaultProvider };

export function setProvider(newProvider: Partial<typeof defaultProvider>) {
    Object.assign(provider, newProvider);
}

export function getImageScaleForWidth(img: { width: number }, width: number, maxScale: number) {
    if (width > 0 && img.width > 0) {
        const scale = (width * 100) / img.width;
        return Math.min(scale, maxScale);
    }
    return 0;
}

export function getImageScaleForHeight(img: { height: number }, height: number, maxScale: number) {
    if (height > 0 && img.height > 0) {
        const scale = (height * 100) / img.height;
        return Math.min(scale, maxScale);
    }
    return 0;
}

export function getPictureUrlByIssueForWidth(issue: Issue, uid: string, key: string, imageWidth: number, requiredWidth: number) {
    const scale = Math.floor((requiredWidth * 100) / imageWidth);
    return getPictureUrlByIssueForScale(issue, uid, key, scale);
}

export function getPictureUrlByIssueForHeight(issue: Issue, uid: string, key: string, imageHeight: number, requiredHeight: number) {
    const scale = Math.floor((requiredHeight * 100) / imageHeight);
    return getPictureUrlByIssueForScale(issue, uid, key, scale);
}

export function getPictureUrlByIssueForSize(issue: Issue, uid: string, key: string, imageSize: ISize, requiredSize: ISize) {
    const imageWidth = imageSize.width;
    const requiredWidth = requiredSize.width;
    const imageHeight = imageSize.height;
    const requiredHeight = requiredSize.height;
    const scaleWidth = Math.floor((requiredWidth * 100) / imageWidth);
    const scaleHeight = Math.floor((requiredHeight * 100) / imageHeight);
    return getPictureUrlByIssueForScale(issue, uid, key, Math.min(scaleWidth, scaleHeight));
}

export function getPictureUrlByIssueForScale(issue: Issue, uid: string, key: string, scale: number, ver?: string) {
    return typeof issue === 'string'
        ? provider.getPictureUrlByIssueForScale(issue, uid, key, scale, ver)
        : provider.getPictureUrlByIssueForScale(issue.issue, uid, key, scale, ver);
}

export function getThumbUrlByIssueForWidth(issue: Issue, width: number, pageNumber = 1, supportRetina = false) {
    if (!issue) {
        return null;
    }
    return typeof issue === 'string'
        ? provider.getThumbUrlByIssueForWidth(issue, width, pageNumber, supportRetina)
        : provider.getThumbUrlByIssueForWidth(issue.issue, width, pageNumber, supportRetina);
}

export function getFirstPageThumbUrlByIssueForWidth(issue: Issue, width: number, pageNumber: number, supportRetina: boolean) {
    return typeof issue === 'string'
        ? provider.getFirstPageThumbUrlByIssueForWidth(issue, width, pageNumber, supportRetina)
        : provider.getFirstPageThumbUrlByIssueForWidth(issue.issue, width, pageNumber, supportRetina);
}

export function getThumbUrlByIssueForHeight(issue: Issue, height: number, pageNumber?: number, supportRetina?: boolean) {
    return typeof issue === 'string'
        ? provider.getThumbUrlByIssueForHeight(issue, height, pageNumber, supportRetina)
        : provider.getThumbUrlByIssueForHeight(issue.issue, height, pageNumber, supportRetina);
}

export function getThumbUrlByCidForWidth(cid: NewspaperOrCid, width: number, pageNumber = 1, supportRetina = false) {
    if (typeof cid === 'string') {
        return provider.getThumbUrlByCidForWidth(cid, width, pageNumber, supportRetina);
    }
    if (cid.issueInfo) {
        return getThumbUrlByIssueForWidth(cid.issueInfo, width, pageNumber, supportRetina);
    }
    return provider.getThumbUrlByCidForWidth(cid.cid, width, pageNumber, supportRetina);
}

export function getThumbUrlByCidForHeight(cid: NewspaperOrCid, height: number) {
    if (typeof cid === 'string') {
        return provider.getThumbUrlByCidForHeight(cid, height);
    }
    if (cid.issueInfo) {
        return getThumbUrlByIssueForHeight(cid.issueInfo, height);
    }
    return provider.getThumbUrlByCidForHeight(cid.cid, height);
}

export function getThumbUrlByCidAndDateForHeight(cid: string, date: Date | string, height: number, supportRetina: false) {
    return provider.getThumbUrlByCidAndDateForHeight(cid, date, height, 1, supportRetina);
}

export function getThumbUrlByCidAndDateForWidth(cid: string, date: Date | string, width: number, pageNumber = 1) {
    return provider.getThumbUrlByCidAndDateForWidth(cid, date, width, pageNumber);
}

export function getThumbUrlForScale(issue: string, scale: number, pageNumber: number, ver: string) {
    return provider.getThumbUrlForScale(issue, scale, pageNumber, ver);
}

export function getPageImageUrl(issue: string, pageNumber: number, pageSize: ISize, fitToSize: Partial<ISize>, layer?: string) {
    return provider.getPageImageUrl(issue, pageNumber, pageSize, fitToSize, layer);
}

export function getPageImageScale(pageNumber: number, pageSize: ISize, fitToSize: ISize) {
    return provider.getPageImageScale(pageNumber, pageSize, fitToSize);
}

export function getThumbUrlByRegionKey(regionKey: string, file: string, scale: number, ver: string) {
    return provider.getThumbUrlByRegionKey(regionKey, file, scale, ver);
}

export function getFirstImage<T extends { Width: number; Height: number }>(images: T[]): T | null {
    // find first image
    let firstImage = null;
    if (images) {
        const firstImageWidth = 600;
        const imagesHeight = 120;

        const sizeScaleMin = 0.7;
        const sizeScaleMax = 2;

        const MaxImageScale = 400;

        for (const img of images) {
            if (img.Width <= 10 || img.Height <= 10 || img.Height / img.Width > 10 || img.Width / img.Height > 10) {
                continue;
            }

            const sizeScale = img.Width / img.Height;
            if (sizeScale > sizeScaleMin && sizeScale < sizeScaleMax) {
                const imageScale = getImageScaleForWidth({ width: img.Width }, firstImageWidth, MaxImageScale);
                const imageScale2 = getImageScaleForHeight({ height: img.Height }, imagesHeight, MaxImageScale);

                if (imageScale <= 0 || imageScale2 <= 0) {
                    continue;
                }

                if (firstImage === null) {
                    firstImage = img;
                    continue;
                } else {
                    if (img.Width > firstImage.Width || img.Height > firstImage.Height) {
                        firstImage = img;
                        continue;
                    }
                }
            }
        }
    }

    return firstImage;
}

export function getThumbUrlForWidthByIssueKey(issueKey: string, width: number, expungeVersion?: number) {
    let url = getFirstPageThumbUrlByIssueForWidth(issueKey, width, 1, true);
    if (url && expungeVersion) {
        url += `&x=${expungeVersion}`;
    }
    return url;
}
