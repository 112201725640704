import { select, take } from 'redux-saga/effects';

import { OperationStatus } from '@pressreader/appstore';

import { configLoadDone, configLoadFailed } from './actions';
import { selectStatus } from './selectors';

export function* ensureConfigLoaded() {
    const status: ReturnType<typeof selectStatus> = yield select(selectStatus);
    if (status === OperationStatus.Done || status === OperationStatus.Failed) {
        return;
    }
    yield take([configLoadDone, configLoadFailed]);
}
