import uiMenu from '@pressreader/src/nd.ui.menu';
import menuControllers from '@pressreader/src/nd.ui.menu.controllers';
import ManageAccountMenuItemController from 'contextmenu/account/manage.account.controller';
import SignOutMenuItemController from 'contextmenu/account/signout.controller';
import menuRenderers from '@pressreader/src/nd.ui.menu.renderers';

const MenuListRenderer = menuRenderers.list;
const MenuView = uiMenu.view;
const MenuController = menuControllers.base;

const childControllers = [ManageAccountMenuItemController, SignOutMenuItemController];

class AccountMenuItemController extends MenuController {
    constructor() {
        super();
        this._menu = new MenuView(this);
    }

    renderer() {
        const renderer = new MenuListRenderer(this).title('').headerEnable(false);

        const availableControllers = childControllers.map(Controller => new Controller(this)).map(c => c.available().then(a => (a ? c : null)));

        return Promise.all(availableControllers)
            .then(controllers => controllers.filter(x => x !== null).map(x => x.item()))
            .then(items => {
                items.forEach(item => {
                    renderer.addItem(item);
                });
                return renderer;
            });
    }
}

export default AccountMenuItemController;
