import _ from 'nd.core';

import { configLoaded, getConfigValue } from '@pressreader/config';

import provider from '@pressreader/src/nd.logger.provider';

$.isDebug = !!(window.config && config.isDebug);

$.nd.logger = {
    log: $.writeLog,
    error: $.writeError,
};

configLoaded().then(function () {
    $.showError = function (err, message) {
        var enabled = getConfigValue('ui.logging.error', false);
        if ($.isDebug || enabled) {
            message = message || '';
            if (err) {
                try {
                    for (var field in err) {
                        try {
                            var val = err[field];
                            if (val) message += '\r\n ' + field + ': ' + val;
                        } catch (EE) {}
                    }
                    message += '\r\n' + err.toString();
                } catch (E) {}
            }
            if (message && message.length > 0) {
                alert(message);
            }
        }
    };

    $.nd.logger.log =
        $.writeLog =
        $.log =
            (function (oldMessages) {
                var enabled = getConfigValue('ui.logging.log', false);
                if (!$.isDebug && !enabled) {
                    return $.noop;
                }

                var func = function (message) {
                    return provider.log(message);
                };

                for (var i = 0; i < oldMessages.length; i++) {
                    func(oldMessages[i]);
                }

                return func;
            })($.writeLog.messages || []);

    $.nd.logger.error = $.writeError = (function (oldMessages) {
        var enabled = getConfigValue('ui.logging.error', false);
        if (!$.isDebug && !enabled) {
            return $.noop;
        }

        var func = function (message, error) {
            if (error) {
                if (!error.stack) {
                    message += '\n' + error;
                    if (error.trace) message += '\n' + error.trace;
                } else {
                    message += '\n' + error.stack;
                }
            }

            if (provider.trace) {
                provider.trace();
            } else if (window.printStackTrace) {
                try {
                    message += '\n' + window.printStackTrace().join('\n');
                } catch (e) {}
            }

            return provider.error(message);
        };

        for (var i = 0; i < oldMessages.length; i++) {
            func(oldMessages[i]);
        }

        return func;
    })($.writeError.messages || []);
});

export default $.nd.logger;
