/* eslint-env es6 */
'use strict';

/**
 * Catalog API
 * Provides methods for work with objects of catalog
 * - Groups of categories (like Countries, Languages, Sections)
 * - Publications
 * - Locales
 *
 * Singleton per application.
 */

import CatalogProvider from 'catalog/services/catalog.provider';
import CatalogService from 'catalog/services/catalog.service';

const catalog = new CatalogService(new CatalogProvider('catalog'));

// compose exportable api
export const getMetadata = () => catalog.getMetadata();
export const getPublication = cid => catalog.getPublication(cid);
export const getPublications = cids => catalog.getPublications(cids);
export const findPublications = query => catalog.findPublications(query);
export const getIssue = (cid, issueDate) => catalog.getIssue(cid, issueDate);
export const getCategoryGroup = name => catalog.getCategoryGroup(name);
export const resolveNavigationPath = (pathUri, prefer) => catalog.resolveNavigationPath(pathUri, prefer);
export const getLanguages = () => catalog.getLanguages();
export const getCountries = () => catalog.getCountries();
export const getCategories = () => catalog.getCategories();
export const getLocales = () => catalog.getLocales();
export const extendCategoriesWithTitlePublication = categories => catalog.extendCategoriesWithTitlePublication(categories);
export const getPublicationOwner = cid => catalog.getPublicationOwner(cid);
