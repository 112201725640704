import { eventChannel } from 'redux-saga';
import { Observable } from 'rxjs';

export function createEventChannel<T extends NonNullable<unknown>>(observable: Observable<T>) {
    return eventChannel<T>(emit => {
        const subscription = observable.subscribe(event => {
            emit(event);
        });

        // Return the unsubscribe function for cleanup
        return () => {
            subscription.unsubscribe();
        };
    });
}
