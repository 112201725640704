/* eslint-env es6 */

'use strict';

import * as ko from 'knockout';
import ndGestures from 'nd.gestures';

/**
 * Utilises ngGestures enableTouchScroll
 * REASON: vertical scrolling is disabled for touch devices at nd.viewer.js (look for event.preventDefault() for "touchmove" event)
 *
 * @example:
 * <div data-bind="enableTouchScroll: true">
 * </div>
 **/
const bindingHandler = {
    init: element => {
        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            ndGestures.disableTouchScroll(element);
        });
    },
    update: (element, valueAccessor) => {
        const value = valueAccessor();

        if (ko.unwrap(value)) {
            ndGestures.enableTouchScroll(element);
        } else {
            ndGestures.disableTouchScroll(element);
        }
    },
};

ko.bindingHandlers.enableTouchScroll = bindingHandler;
