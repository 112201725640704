/* eslint-env es6 */

import * as $ from 'jquery';

const ignoreEventProperty = '__nd_gestures_ignore';

/**
 * Marks the specified event as ignored by 'nd.gestures' module.
 * @param {Event} e - event, being ignored.
 */
function ignoreEvent(e) {
    e[ignoreEventProperty] = true;
}

/**
 * Marks the specified (DOM/jQuery) element as ignored by 'nd.gestures' module.
 * all touch events that fired over this element will be ignored.
 * @param {Element|jQuery} elem - element to ignore.
 */
function ignoreElement(elem) {
    $(elem).data('suppress-gestures', true);
}

/**
 * Indicates that the specified event should be ignored during 'nd.gestures' processing.
 * @param {Event} e - event instance to check.
 * @returns {boolean} - true - this event should be ignored, otherwise false.
 */
function isIgnored(e) {
    if (e[ignoreEventProperty] || (e.originalEvent && e.originalEvent[ignoreEventProperty]) || $(e.target).data('suppress-gestures')) {
        return true;
    }

    return false;
}

export { ignoreEvent, ignoreElement, isIgnored };
