/* eslint-disable @typescript-eslint/no-empty-function */
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { httpGet } from '@pressreader/services';
import config from '@pressreader/src/deprecated/nd.config';
import ndAlert from '@pressreader/src/nd.alert';
import { DialogStatus } from '@pressreader/src/nd.ui.dialogstatus';
import ndProgress from '@pressreader/src/nd.ui.progress';
import { loadExternalScript } from '@pressreader/utils';

import ndLogger from 'nd.logger';
import res from 'nd.res';
import templates from 'nd.templates';
import { getSott } from 'authentication/nd.authentication.service';

import { IIdentity } from '../adapter';
import { ScreensetType } from '../screenSets';

import { LoginRadiusDialog, State as DialogState } from './dialog';
import { ShowPasswordButton } from './modules';
import { ForgotPasswordWidget, LoginWidget, LRWidgetType, RegistrationWidget, ResetPasswordWidget, SocialLoginWidget } from './widgets';

const LOGIN_CONTAINER_ID = 'login-container';
const SOCIALLOGIN_CONTAINER_ID = 'sociallogin-container';
const FORGOTPASSWORD_CONTAINER_ID = 'forgotpassword-container';
const REGISTRATION_CONTAINER_ID = 'registration-container';
const RESETPASSWORD_CONTAINER_ID = 'resetpassword-container';

const SOCIALLOGIN_TEMPLATE_ID = 'sociallogintemplate';
const SOCIALLOGIN_INTERFACE = '.social-login-interface';

const LRStates = {
    [ScreensetType.signIn]: DialogState.Signin,
    [ScreensetType.register]: DialogState.Signup,
    [ScreensetType.resetPasswordRequestCode]: DialogState.ResetPassword,
};

const LRForms = {
    [LRWidgetType.LOGIN]: DialogState.Signin,
    [LRWidgetType.REGISTRATION]: DialogState.Signup,
    [LRWidgetType.RESET_PASSWORD]: DialogState.ResetPassword,
};

// Login radius error codes
// Reference: https://www.loginradius.com/docs/api/v2/getting-started/response-codes/customer-identity-api-codes/
export enum LRErrorCode {
    LINK_ALREADY_USED = 974,
    LINK_EXPIRED = 975,
    LINK_TOKEN_INVALID = 1063,
    NEW_PASSWORD_INVALID = 1015,
    INVAlID_USER_PASSWORD = 966,
    USER_DOES_NOT_EXIST = 938,
    EMAIL_ADDRESS_TAKEN = 936,
}

interface ISSOTokenResult {
    isauthenticated: boolean;
    token: string;
}

interface IJwtResult {
    signature: string;
}

export interface ILoginRadiusOptions {
    apiKey: string;
    appName: string;
    debugMode?: boolean;
    forgotPasswordUrl?: string;
    formValidationMessage?: boolean;
    integrationName: string;
    sott?: string;
    tokenType: string;
    verificationUrl: string;
    resetPasswordUrl: string;
    hashTemplate?: boolean;
    accessTokenResponse?: boolean;
}

export class LoginRadius implements IIdentity {
    public readonly onLoginSuccess: Observable<any>;
    public readonly onModalClosed: Observable<any>;
    public readonly onLogoutSuccess: Observable<any>;
    public enabled = false;

    private dialog: LoginRadiusDialog;

    private lrObject: any;
    private lrOptions: ILoginRadiusOptions;
    private endPoints: any;
    private ssoConfig: any;

    private loginWidget: LoginWidget;
    private socialLoginWidget: SocialLoginWidget;
    private forgotPasswordWidget: ForgotPasswordWidget;
    private registrationWidget: RegistrationWidget;
    private resetPasswordWidget: ResetPasswordWidget;

    private loginSuccess: Subject<any>;
    private modalClosed: Subject<DialogStatus>;
    private logoutSuccess: Subject<void>;

    private initialState: DialogState;

    constructor() {
        this.loginSuccess = new Subject<any>();
        this.modalClosed = new Subject<DialogStatus>();
        this.logoutSuccess = new Subject<void>();

        this.onLoginSuccess = this.loginSuccess.asObservable();
        this.onModalClosed = this.modalClosed.asObservable();
        this.onLogoutSuccess = this.logoutSuccess.asObservable();

        this.showScreenSet = this.showScreenSet.bind(this);
    }

    public async load() {
        await Promise.all([config.loaded(), templates.loaded(), res.loaded()]);

        const loginRadiusConfig = config.get('Authentication.LoginRadius');
        if (!loginRadiusConfig || !loginRadiusConfig.enabled) {
            return Promise.reject();
        }
        this.enabled = true;

        if (!window.LoginRadiusV2) {
            this.dialog = new LoginRadiusDialog();

            try {
                const { Options, Url, sso, endPoints } = loginRadiusConfig;
                const [, sott] = await Promise.all([loadExternalScript(Url), getSott()]);

                this.lrOptions = {
                    ...Options,
                    verificationUrl: Options.verificationUrl || window.location,
                    resetPasswordUrl: Options.resetPasswordUrl || window.location,
                    sott,
                };

                this.endPoints = endPoints || {};
                this.ssoConfig = sso || {};

                this.initialize();

                if (this.ssoConfig.enabled) {
                    setTimeout(() => {
                        this.initializeSSO();
                    }, 1000);
                }
            } catch (error) {
                ndLogger.error(error);
                return Promise.reject();
            }
        }

        return Promise.resolve();
    }

    public signIn() {
        this.showModal();
    }

    public signOut() {
        this.lrObject.init('logout');
        if (this.ssoConfig.enabled) {
            httpGet({
                url: this.ssoConfig.logoutUrl,
                requestConfig: {
                    accept: 'application/json',
                    cache: 'no-store',
                },
            });
        }
        this.logoutSuccess.next();
    }

    public closeModal() {
        this.dialog.close();
    }

    public showScreenSet(screenType: ScreensetType) {
        const state = LRStates[screenType];
        if (state === undefined) {
            throw new Error(`screen ${screenType} is not defined.`);
        }
        this.showModal(state);
    }

    public showInlineScreenSet(screenType: ScreensetType, containerSelector: string) {
        this.showScreenSet(screenType);
    }

    private initialize() {
        this.lrObject = new window.LoginRadiusV2(this.lrOptions);

        // Add hooks first, then initialize widgets
        // Reference: https://www.loginradius.com/docs/libraries/js-libraries/javascript-hooks
        this.lrObject.$hooks.register('afterFormRender', async (name: string) => {
            const LRWidgetTypeKey = Object.keys(LRForms).find(e => e.toLowerCase() === name.toLowerCase());
            if (LRForms[LRWidgetTypeKey] === this.initialState) {
                this.dialog.state = this.initialState;
                ndProgress.hide();
            }

            if (name === LRWidgetType.LOGIN) {
                await ShowPasswordButton.initDOM(`#${LOGIN_CONTAINER_ID}`);
            } else if (name === LRWidgetType.REGISTRATION) {
                await ShowPasswordButton.initDOM(`#${REGISTRATION_CONTAINER_ID}`);
            }
        });

        // Custom button label
        this.lrObject.$hooks.call('setButtonsName', {
            forgotpassword: res.val('Dialogs.Signin.SendEmail'),
            login: res.val('Buttons.Login.Text'),
            registration: res.val('Buttons.SignUp'),
        });

        // Custom field label
        this.lrObject.$hooks.call('customizeFormLabel', {
            emailid: res.val('Accounting.EmailAddress'),
            password: res.val('Accounting.Password'),
        });

        // Custom error messages
        this.lrObject.$hooks.call('mapErrorMessages', [
            {
                code: LRErrorCode.NEW_PASSWORD_INVALID,
                description: res.val(
                    'Errors.PasswordUnique',
                    'Your new password is too similar to your old passwords. Please provide a new password.',
                ),
                message: res.val('Errors.PasswordUnique', 'Your new password is too similar to your old passwords. Please provide a new password.'),
            },
            {
                code: LRErrorCode.INVAlID_USER_PASSWORD,
                description: res.val('Dialogs.Signin.InvalidLoginOrPass', 'Invalid user name or password.'),
                message: res.val('Dialogs.Signin.InvalidLoginOrPass', 'Invalid user name or password.'),
            },
            {
                code: LRErrorCode.USER_DOES_NOT_EXIST,
                description: res.val('Dialogs.Signin.InvalidLoginOrPass', 'Invalid user name or password.'),
                message: res.val('Dialogs.Signin.InvalidLoginOrPass', 'Invalid user name or password.'),
            },
            {
                code: LRErrorCode.EMAIL_ADDRESS_TAKEN,
                description: res.val('Errors.LogonEmailUnique', 'Account with this email already exists'),
                message: res.val('Errors.LogonEmailUnique', 'Account with this email already exists'),
            },
        ]);

        this.loginWidget = new LoginWidget(LOGIN_CONTAINER_ID, this.lrObject);
        this.loginWidget.onSuccess.subscribe(response => {
            this.loginSuccess.next(response);
            this.closeModal();
        });
        this.loginWidget.onError.subscribe(this.onError.bind(this));

        this.socialLoginWidget = new SocialLoginWidget(SOCIALLOGIN_CONTAINER_ID, this.lrObject, SOCIALLOGIN_TEMPLATE_ID, SOCIALLOGIN_INTERFACE);
        this.socialLoginWidget.onSuccess.subscribe(response => {
            this.loginSuccess.next(response);
            this.closeModal();
        });
        this.socialLoginWidget.onError.subscribe(this.onError.bind(this));

        this.forgotPasswordWidget = new ForgotPasswordWidget(FORGOTPASSWORD_CONTAINER_ID, this.lrObject);
        this.forgotPasswordWidget.onSuccess.subscribe(response => {
            const message = res.val('Accounting.PasswordRecovery.EmailConfirmationIsSent');
            ndAlert().show(message);
            this.loginSuccess.next(response);
            this.closeModal();
        });
        this.forgotPasswordWidget.onError.subscribe(this.onError.bind(this));

        this.registrationWidget = new RegistrationWidget(REGISTRATION_CONTAINER_ID, this.lrObject);
        this.registrationWidget.onSuccess.subscribe(response => {
            this.loginSuccess.next(response);
            this.closeModal();
        });
        this.registrationWidget.onError.subscribe(this.onError.bind(this));

        this.resetPasswordWidget = new ResetPasswordWidget(RESETPASSWORD_CONTAINER_ID, this.lrObject);
        this.resetPasswordWidget.onSuccess.subscribe(response => {
            this.closeModal();
            const message = res.val('Accounting.PasswordRecovery.PasswordChanged');
            ndAlert().show(message);
            ndAlert().onClosed(() => this.signIn());
        });
        this.resetPasswordWidget.onError.subscribe(this.onError.bind(this));

        const screen = this.lrObject.util.getQueryParameterByName('vtype');
        if (screen && screen === 'reset') {
            this.showModal(DialogState.ResetPassword);
        }
    }

    private showModal(state = DialogState.Signin) {
        this.initialState = state;

        this.dialog.onShow.pipe(first()).subscribe(() => this.initForms());
        this.dialog.onClose.pipe(first()).subscribe(dialogStatus => this.modalClosed.next(dialogStatus));

        this.dialog.show();
    }

    private async initForms() {
        await ndProgress.show();

        this.loginWidget.initForm();
        this.socialLoginWidget.initForm();
        this.forgotPasswordWidget.initForm();
        this.registrationWidget.initForm();
        this.resetPasswordWidget.initForm();
    }

    /**
     * Looks for SSO token in hosted solution and sets it to local storage.
     * Only works when third-party cookies are enabled
     * https://www.loginradius.com/docs/single-sign-on/tutorial/web-sso/overview/
     */
    private async initializeSSO() {
        const ssoTokenCallRes = await httpGet<ISSOTokenResult>({
            url: this.ssoConfig.loginUrl,
            requestConfig: {
                withCredentials: true,
                accept: 'application/json',
                cache: 'no-store',
            },
        });
        if (ssoTokenCallRes.isauthenticated) {
            const ssoToken = ssoTokenCallRes.token;

            const jwtTokenCall = httpGet<IJwtResult>({
                url: `${this.endPoints.token}?apiKey=${this.lrOptions.apiKey}&jwtapp=${this.lrOptions.integrationName}&access_token=${ssoToken}`,
                requestConfig: {
                    accept: 'application/json',
                    cache: 'no-store',
                },
            });

            const accountCall = httpGet({
                url: `${this.endPoints.account}?apiKey=${this.lrOptions.apiKey}&access_token=${ssoToken}`,
                requestConfig: {
                    accept: 'application/json',
                    cache: 'no-store',
                },
            });

            const [jwtToken, account] = await Promise.all([jwtTokenCall, accountCall]);

            if (jwtToken.signature) {
                this.loginSuccess.next({
                    jwttoken: jwtToken.signature,
                    access_token: ssoToken,
                    Profile: account,
                });
            }
        }
    }

    private onError(result: any) {
        this.dialog.errorMessage = result[0].Message;
        this.dialog.resize();
    }
}
