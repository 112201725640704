'use strict';

import * as ko from 'knockout';
import { Subject } from 'rxjs';
import ndConfig from '@pressreader/src/deprecated/nd.config';

import { getCountries, getRegions } from '@pressreader/src/account/services/address.service';
import { getAvatarImageId, getAvatarHtml } from '@pressreader/account-profile';
import { alertError } from '@pressreader/src/shared/errors/error.handlers';

import { getProfile, updateProfile, updateAddress } from 'account/services/account.service';

/**
 * Master model of current user profile
 * shared across many view models serving different pieces of profile or displaying it.
 */
class AccountProfileModel {
    constructor(config) {
        this.isLoading = ko.observable(false);
        this.isLoaded = ko.observable(false);

        this.nickname = ko.observable();
        this.email = ko.observable();
        this.displayName = ko.observable();
        this.logonName = ko.observable();
        this.lastPasswordChangeDate = ko.observable();

        this.firstName = ko.observable();
        this.lastName = ko.observable();
        this.dateOfBirth = ko.observable();
        this.publicPhone = ko.observable();

        this.photoUrl = ko.observable();
        this.avatarImageId = ko.observable();
        this.avatar = ko.observable('');

        this.addressLine1 = ko.observable();
        this.addressLine2 = ko.observable();
        this.city = ko.observable();
        this.countryCode = ko.observable();
        this.regionCode = ko.observable();
        this.regionName = ko.observable();
        this.postalCode = ko.observable();
        this.mobileNumber = ko.observable();
        this.zipCode = ko.observable();

        const avatarUpdater = () => {
            this.avatar(
                getAvatarHtml({
                    nickname: this.nickname(),
                    imageId: this.avatarImageId(),
                    fallbackAvatarUrl: this.photoUrl(),
                }),
            );
        };
        this.nickname.subscribe(avatarUpdater);
        this.avatarImageId.subscribe(avatarUpdater);
        this.photoUrl.subscribe(avatarUpdater);

        this.logonNameVisible = ko.observable(config.logonName.enabled);
        this.logonNameRequired = ko.observable(config.logonName.required);
        this.logonNameEditable = ko.observable(config.logonName.editable);

        this.nicknameVisible = ko.observable(config.nickname.enabled);
        this.nicknameRequired = ko.observable(config.nickname.required);
        this.nicknameEditable = ko.observable(config.nickname.editable);

        this.emailAddressVisible = ko.observable(config.emailAddress.enabled);
        this.emailAddressEditable = ko.observable(config.emailAddress.editable);

        this.firstNameRequired = ko.observable(config.firstName.required);
        this.firstNameEditable = ko.observable(config.firstName.editable);

        this.lastNameRequired = ko.observable(config.lastName.required);
        this.lastNameEditable = ko.observable(config.lastName.editable);

        this.nameVisible = ko.observable(config.name.enabled);
        this.nameEditable = ko.observable(config.name.editable);

        this.publicPhoneVisible = ko.observable(config.phone.enabled);
        this.publicPhoneRequired = ko.observable(config.phone.required);
        this.publicPhoneEditable = ko.observable(config.phone.editable);

        this.mobileNumberVisible = ko.observable(config.mobileNumber.enabled);
        this.mobileNumberRequired = ko.observable(config.mobileNumber.required);
        this.mobileNumberEditable = ko.observable(config.mobileNumber.editable);

        this.addressLine1Visible = ko.observable(config.addressLine1.enabled);
        this.addressLine1Required = ko.observable(config.addressLine1.required);
        this.addressLine1Editable = ko.observable(config.addressLine1.editable);

        this.addressLine2Visible = ko.observable(config.addressLine2.enabled);
        this.addressLine2Required = ko.observable(config.addressLine2.required);
        this.addressLine2Editable = ko.observable(config.addressLine2.editable);

        this.cityVisible = ko.observable(config.city.enabled);
        this.cityRequired = ko.observable(config.city.required);
        this.cityEditable = ko.observable(config.city.editable);

        this.countryVisible = ko.observable(config.country.enabled);
        this.countryRequired = ko.observable(config.country.required);
        this.countryEditable = ko.observable(config.country.editable);

        this.regionVisible = ko.observable(config.region.enabled);
        this.regionRequired = ko.observable(config.region.required);
        this.regionEditable = ko.observable(config.region.editable);

        this.postalCodeVisible = ko.observable(config.postalCode.enabled);
        this.postalCodeRequired = ko.observable(config.postalCode.required);
        this.postalCodeEditable = ko.observable(config.postalCode.editable);

        this.dateOfBirthVisible = ko.observable(config.dateOfBirth.enabled);
        this.dateOfBirthRequired = ko.observable(config.dateOfBirth.required);
        this.dateOfBirthEditable = ko.observable(config.dateOfBirth.editable);

        this.passwordEditable = ko.observable(config.password.editable);

        this.externalAuthentication = ko.observable(ndConfig.get('Authentication.EnableExternalAuthenticationScreenSet', false));

        this.availableCountries = ko.observableArray();
        this.availableRegions = ko.observableArray();

        this.displayNickName = ko.pureComputed({
            deferEvaluation: true,
            owner: this,
            read: () => {
                return ndConfig.get('ui.TopToolbar.ShowLogonName', false) ? this.email() : this.nickname();
            },
        });

        this._updates = new Subject();
        this._updatesObservable = this._updates.asObservable();
    }

    get updates() {
        return this._updatesObservable;
    }

    _populate(data, avatarImageId, countries, regions) {
        this.availableCountries(countries);
        this.availableRegions(regions);

        this.email(data.email);
        this.lastPasswordChangeDate(data.lastPasswordChangeDate);
        this.displayName(data.displayName);
        this.logonName(data.logonName);
        this.firstName(data.firstName);
        this.lastName(data.lastName);
        this.publicPhone(data.publicPhone);
        this.mobileNumber(data.mobileNumber);
        this.nickname(data.nickname);
        this.photoUrl(data.photoUrl);
        this.avatarImageId(avatarImageId || data.avatarImageId);
        this.dateOfBirth(data.dateOfBirth);

        this.addressLine1(data.address?.addressLine1);
        this.addressLine2(data.address?.addressLine2);
        this.city(data.address?.city);
        this.countryCode(data.address?.countryCode);
        this.regionCode(data.address?.regionCode);
        this.regionName(data.address?.regionName);
        this.postalCode(data.address?.postalCode);

        this._updates.next();
    }

    _toUpdateDto() {
        return {
            nickname: this.nickname(),
            firstName: this.firstName(),
            lastName: this.lastName(),
            email: this.email(),
            publicPhone: this.publicPhone(),
            mobileNumber: this.mobileNumber(),
            dateOfBirth: this.dateOfBirth(),
        };
    }

    _toUpdateAddressDto() {
        return {
            addressLine1: this.addressLine1(),
            addressLine2: this.addressLine2(),
            city: this.city(),
            countryCode: this.countryCode(),
            regionCode: this.regionCode(),
            regionName: this.regionName(),
            postalCode: this.postalCode(),
        };
    }

    async load() {
        this.isLoading(true);
        try {
            const [profile, avatarImageId, countries] = await Promise.all([getProfile(), getAvatarImageId(), getCountries()]);
            const regions = profile.address?.countryCode ? await getRegions(profile.address.countryCode) : [];
            this._populate(profile, avatarImageId, countries, regions);
            this.isLoaded(true);
        } finally {
            this.isLoading(false);
        }
    }

    /**
     * Updates profile
     * @param {Object} data
     * @returns Promise
     */
    async update(data) {
        try {
            if (data.address) {
                const dto = { ...this._toUpdateAddressDto(), ...data.address };
                await updateAddress(dto);
            } else {
                const dto = { ...this._toUpdateDto(), ...data };
                await updateProfile(dto);
            }
            this.load();
        } catch (error) {
            alertError(error);
        }
    }
}

export default AccountProfileModel;
