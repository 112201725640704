interface SimpleCacheOptions {
    expire?: number;
}

interface SimpleCache {
    (options?: SimpleCacheOptions): {
        contains(key: string): boolean;
        get(key: string): any;
        set(key: string, value: any, expirationTime?: number): void;
        remove(key: string): void;
        clear(): void;
    };
}

const createSimpleCache: SimpleCache = (options: SimpleCacheOptions = {}) => {
    const _map: { [key: string]: any } = {};
    const _timeouts: { [key: string]: number } = {};
    const _defaultExpirationTime: number = parseInt((options.expire ?? 0).toString(), 10) ?? 0;

    const contains = (key: string): boolean => {
        _validateKey(key);
        return key in _map;
    };

    const get = (key: string): any => {
        _validateKey(key);
        return _map[key];
    };

    const set = (key: string, value: any, expirationTime?: number): void => {
        _validateKey(key);
        _removeTimeout(key);
        _map[key] = value;
        const expire = expirationTime || _defaultExpirationTime;
        if (expire) {
            _timeouts[key] = window.setTimeout(() => remove(key), expire);
        }
    };

    const remove = (key: string): void => {
        _validateKey(key);
        if (contains(key)) {
            delete _map[key];
            _removeTimeout(key);
        }
    };

    const clear = (): void => {
        Object.keys(_map).forEach(key => delete _map[key]);
        Object.keys(_timeouts).forEach(key => clearTimeout(_timeouts[key]));
    };

    const _validateKey = (key: string): void => {
        if (!key || key.trim() === '') {
            throw new Error('key must be a non-empty string');
        }
    };

    const _removeTimeout = (key: string): void => {
        const timeout = _timeouts[key];
        if (timeout) {
            clearTimeout(timeout);
            delete _timeouts[key];
        }
    };

    return {
        contains,
        get,
        set,
        remove,
        clear,
    };
};

export { createSimpleCache };
