﻿import isFunction from 'lodash/isFunction';
import keys from 'lodash/keys';

import { fetchShim, IFetchOptions } from './fetch';
import { FetchError } from './fetch.error';

interface IRequestOptions extends IFetchOptions {
    serviceName?: string;
    maxRetries?: number;
}

/* eslint-disable prefer-rest-params */

type ServiceNamePredicate = (key: string) => boolean;

interface IRetryPolicy {
    maxRetries: number;
}

const defaultHeaders = {
    'Content-Type': 'application/json',
};

const defaults: IFetchOptions = {
    headers: defaultHeaders,
    timeout: 10000,
};

const _serviceNamePredicateCache: Record<string, ServiceNamePredicate> = {};

function isPromiseLike<TResult>(val: any): val is PromiseLike<TResult> {
    return val && typeof val.then === 'function';
}

function createServiceNamePredicate(serviceName: string) {
    serviceName = serviceName || '';
    let result = _serviceNamePredicateCache[serviceName];
    if (!result) {
        // The predicate should be ignore case and should ignore trailing slash.
        const lastIdx = serviceName.length - 1;
        const lowerCaseServiceName = serviceName.toLowerCase();

        const keys: Record<string, true> = {};

        keys[serviceName] = true;
        keys[lowerCaseServiceName] = true;

        if (serviceName[lastIdx] === '/') {
            keys[serviceName.substr(0, lastIdx)] = true;
            keys[lowerCaseServiceName.substr(0, lastIdx)] = true;
        } else {
            keys[`${serviceName}/`] = true;
            keys[`${lowerCaseServiceName}/`] = true;
        }

        result = (key: string) => keys[key] || keys[key.toLowerCase()] || false;
        _serviceNamePredicateCache[serviceName] = result;
    }
    return result;
}

function findServiceNameKey<T>(preloadedData: T, predicate: ServiceNamePredicate) {
    // Do not cache keys, they are deleted from object after first use.
    return keys(preloadedData).find(predicate);
}

function getPreload<T>() {
    const ndLoader = (window as any).NDLoader;
    const promise: PromiseLike<T> = ndLoader && isFunction(ndLoader.getPreload) && ndLoader.getPreload();
    if (promise) {
        // This promise is simplified promise. See ndloader.js. So we need to make a true Promise out of it.
        return new Promise<T>((resolve, reject) => {
            promise.then(resolve, reject);
        });
    }
    const preload: T = (window as any)._preload;
    return preload ? Promise.resolve(preload) : Promise.reject();
}

function resetPreload() {
    (window as any)._preload = null;
    const ndLoader = (window as any).NDLoader;
    if (isFunction(ndLoader?.setPreload)) {
        ndLoader.setPreload(null);
    }
}

function lookupPreloaded<TResult>(serviceName: string, preloadedData: Record<string, TResult | PromiseLike<TResult>>) {
    const predicate = createServiceNamePredicate(serviceName);
    const key = findServiceNameKey(preloadedData, predicate);

    if (key) {
        const data = preloadedData[key];
        // Use preloaded data just once.
        delete preloadedData[key];

        if (isPromiseLike<TResult>(data)) {
            // the data is a PromiseLike.
            return new Promise<TResult>((resolve, reject) => {
                data.then(resolve, reject);
            });
        }
        return Promise.resolve(data);
    } else if (predicate('auth')) {
        // the user has been changed, invalidate old data
        resetPreload();
    }
    return Promise.reject();
}

async function executeFetch<TResult>(options: IRequestOptions) {
    const { serviceName } = options;
    if (!serviceName) {
        return await fetchShim<TResult>(options);
    }
    try {
        const preloadedData = await getPreload<Record<string, TResult | PromiseLike<TResult>>>();
        return await lookupPreloaded<TResult>(serviceName, preloadedData);
    } catch {
        return await fetchShim<TResult>(options);
    }
}

async function executeFetchWithRetry<TResult>(options: IRequestOptions, policy?: IRetryPolicy) {
    if (!policy?.maxRetries) {
        return await executeFetch<TResult>(options);
    }

    for (let retryNumber = 0; retryNumber <= policy.maxRetries; ++retryNumber) {
        try {
            return await executeFetch<TResult>(options);
        } catch (e) {
            if (retryNumber >= policy.maxRetries) {
                throw e;
            }
            if (e instanceof FetchError && e.status < 500) {
                // disable retry for not critical errors
                // ex: 404 (ie valid application response)
                throw e;
            }
        }
    }

    throw new Error();
}

function apiFetch<TResult>(options: IRequestOptions, retryPolicy?: IRetryPolicy) {
    if (!retryPolicy) {
        if (options.maxRetries) {
            retryPolicy = { maxRetries: options.maxRetries };
        }
    }
    if (retryPolicy) {
        return executeFetchWithRetry<TResult>(options, retryPolicy);
    }
    return executeFetch<TResult>(options);
}

let _baseUrl = '';
const _defaultUrl = 'https://ingress.pressreader.com/services/';

function baseUrl(): string;
function baseUrl(url: string): void;
function baseUrl(): string | void {
    if (arguments.length) {
        _baseUrl = _checkHttps(arguments[0]);
        return;
    }
    if (_baseUrl) {
        return _baseUrl;
    }

    _baseUrl = _checkHttps(_defaultUrl);
    return _baseUrl;
}

function isPageHttps() {
    return window.location.protocol === 'https:';
}

function getToken<TResult>(ticket: string[], lng: string) {
    const url = `${baseUrl()}auth/`;

    return getJson<TResult>({
        serviceName: 'auth',
        url: url,
        query: { ticket, lng },
        maxRetries: 3,
    });
}

function loadConfig<TResult>(token: string) {
    // This is default impl.
    const url = `${baseUrl()}config`;
    return getJson<{ config: TResult }>({
        serviceName: 'config',
        url: url,
        maxRetries: 3,
        headers: { Authorization: `Bearer ${token}` },
    });
}

function getJson<TResult>(options: IRequestOptions) {
    return apiFetch<TResult>({ ...defaults, ...options });
}

/**
 * Sends an Fetch request to the server
 * @param options fetch request configuration
 * @param retryPolicy request's retryPolicy (maxRetries)
 */
function fetchRequest<TResult>(options: IRequestOptions, retryPolicy?: IRetryPolicy) {
    if (!options.method || options.method === 'GET') {
        return apiFetch<TResult>({ ...defaults, ...options });
    }
    return apiFetch<TResult>(options, retryPolicy);
}

function _checkHttps(url: string) {
    if (isPageHttps()) {
        return url.replace(/^http:\/\//i, 'https://');
    }
    return url;
}

export { IRetryPolicy, baseUrl, isPageHttps, getToken, loadConfig, getJson, fetchRequest };
