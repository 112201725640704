import endsWith from 'lodash/endsWith';
import isError from 'lodash/isError';
import isString from 'lodash/isString';
import memoize from 'lodash/memoize';
import reduce from 'lodash/reduce';
import startsWith from 'lodash/startsWith';

import { getConfigValue } from '@pressreader/config';

import {
    CONFIG_INTERFACE_CURRENT_LANGUAGE,
    CONFIG_INTERFACE_LANGUAGES,
    FEATURE_FLAG_MULTILANGUAGE_ENABLED,
    FEATURE_FLAG_MULTILANGUAGE_PAGES,
} from './constants';

// TODO: move to generic utils
export function extractValue<T = unknown>(obj: Record<string, unknown> = {}, propName: string) {
    const val = obj[propName];
    delete obj[propName];
    return val as T;
}

export const PATH_SEPARATOR = '/';
export const QUERY_SEPARATOR = '?';
export const QUERY_PAIR_SEPARATOR = '&';
export const QUERY_NAME_VALUE_SEPARATOR = '=';

export function echo<T>(val: T) {
    return val;
}

export function echoAsync<T>(val: T) {
    return Promise.resolve(val);
}

/**
 * Ensure that given object is a Error or convert to one if not.
 * @param  err Error object or error message.
 * @return Error object.
 */
export function ensureError(err: string | Error) {
    if (isError(err)) {
        return err;
    }

    return new Error(err);
}

/**
 * Clone given object casting its own enumerable properties to strings.
 * Properties which values equal to null or undefined might be either omitted or substituted with empty string.
 * Used in url generating and route creating.
 * @param  obj Object to clone.
 * @param  forceEmpty If set to true, properties with null and undefined values are substituted by empty string, omitted otherwise.
 * @return Cloned object.
 */
export function clonePlain<T extends Record<string | number | symbol, unknown>>(obj: T, forceEmpty = false) {
    return reduce(
        obj,
        (plain, value, key: keyof T) => {
            if (value !== undefined && value !== null) {
                plain[key] = String(value);
            } else if (forceEmpty) {
                plain[key] = '';
            }

            return plain;
        },
        {} as Record<keyof T, string>,
    );
}

/**
 * Ensure that given string does not start which given separator and end with one.
 * If given string equals to the separator, it's returned "as is".
 * @param  str String to check.
 * @param  separator Separator string.
 * @return A string which starts from something other than a separator and ends with one.
 */
export function normalizeSeparation(str: string, separator: string) {
    if (str === separator) {
        return str;
    }

    if (startsWith(str, separator)) {
        str = str.substring(separator.length);
    }

    if (endsWith(str, separator)) {
        return str;
    }

    return `${str}${separator}`;
}

/**
 * Split string by last occurrence of given separator.
 * @param  str String to split.
 * @param  separator Pattern to split by.
 * @return Array of string segments.
 */
export function splitByLast(str: string, separator: string): [string, string] {
    const delimiterIndex = str.lastIndexOf(separator);
    if (delimiterIndex < 0) {
        return [str, ''];
    }

    return [str.substring(0, delimiterIndex), str.substring(delimiterIndex + 1)];
}

/**
 * Parse url query string (param1=1&param2=2).
 * @param  queryString Query string to parse.
 * @return Parsed name-value map.
 */
export function parseQuery(queryString: string) {
    const query: Record<string, string> = {};
    if (!isString(queryString) || !queryString.length || queryString === QUERY_SEPARATOR) {
        return query;
    }

    if (queryString.indexOf(QUERY_SEPARATOR) === 0) {
        queryString = queryString.substring(1);
    }

    return queryString.split(QUERY_PAIR_SEPARATOR).reduce((parsed, pairStr) => {
        const [name, value] = pairStr.split(QUERY_NAME_VALUE_SEPARATOR, 2);
        parsed[name] = value;
        return parsed;
    }, query);
}

// Characters which should remain unencoded refer https://tools.ietf.org/html/rfc3986#section-3.3 for more details
const uriPathSafe = new RegExp('([^@$&+:])*', 'gi');

/**
 * Perform a "percent"-encoding for a path segment.
 * Unlike encodeURIComponent keeps certain characters, such as 'at' (@) unencoded, similar to encodeURI.
 * @param input component of a URI.
 * @return Encoded component of a URI.
 */
export function encodePathComponent(input: string) {
    return input.replace(uriPathSafe, part => encodeURIComponent(part));
}

/**
 * Perform a "persent"-encoding for a query string parameter.
 * Encode all unsafe symbols in the exact way as encodeURIComponent does.
 * @param uriComponent Decoded component of a URI.
 * @return Encoded component of a URI.
 */
export const encodeQueryParameter = encodeURIComponent;

/**
 * Decode query string and also additionaly take care of replacing '+' with space symbol.
 * https://www.w3.org/Addressing/URL/4_URI_Recommentations.html "Query strings" paragraph.
 * @param str Encoded query string parameter.
 * @return Decoded query string parameter.
 */
export function decodeQueryParameter(str: string) {
    if (isString(str)) {
        str = str.replace(/\+/g, '%20').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    }
    return decodeURIComponent(str);
}

/**
 * Concatenate url path segments and optional query string.
 * @param  path Array of url path segments.
 * @param  queryPairs Array of name-value pairs representing url query string.
 * @return Combined url string.
 */
export function combineRouteUrl(path: string[] = [], queryPairs: [string, string][] = []) {
    let combined = path.map(encodePathComponent).join(PATH_SEPARATOR);

    if (queryPairs.length) {
        const queryStr = queryPairs
            .map(([name, value]) => `${encodeQueryParameter(name)}${QUERY_NAME_VALUE_SEPARATOR}${encodeQueryParameter(value)}`)
            .join(QUERY_PAIR_SEPARATOR);
        combined = `${combined}${QUERY_SEPARATOR}${queryStr}`;
    }

    // Return the combined string, replacing encoded commas (%2C) with actual commas.
    // This is done to keep the URL more readable and user-friendly
    return combined.replace(/%2C/g, ',');
}

/**
 * window.basePath is specified in global scope by the server.
 * Legacy code alert!!
 */
export function getBasePath(): string {
    return (window as any).basePath || '/';
}

/**
 * Builds absolute path to be used in browser.
 * Add base path to given url.
 * If page does not have a basePath, a leading slash is added.
 * @param  path - relative path.
 * @return absolute path.
 */
export function withAbsolutePath(path: string) {
    if (startsWith(path, PATH_SEPARATOR)) {
        path = path.substring(1);
    }

    return `${getBasePath()}${path}`;
}

/**
 * Removes base path to given url.
 * If page does not have a basePath, a leading slash is added.
 * @param  path - absolute path.
 * @return relative path.
 */
export function withRelativePath(path: string) {
    const base = getBasePath();
    return startsWith(path, base) ? path.substring(base.length) : path;
}

/**
 * Return current path and search relative to basePath.
 * Without starting '/'.
 * Url is returned "as is", no encoding/decoding is applied.
 * @return Relative url
 */
export function getCurrentPath() {
    const loc = document.location;
    const path = withRelativePath(loc.pathname);
    return `${path}${loc.search}`;
}

/**
 * Return true if current document location is root of application.
 * @return true if root
 */
export function isRootLocation() {
    const loc = document.location;
    const path = loc.pathname.replace(getBasePath(), '');
    return path.length === 0;
}

const slugNotAllowedRegExp = /[;/\\?:@&=+$,<>#%.!*'"()[\]{}^`~–‘’“”»«]+/g;

export const generateSlug = memoize((value: string) => {
    if (!isString(value)) {
        throw new Error(`Cannot generate slug for non-string value. Given ${value}`);
    }

    const slug = value
        .trim()
        .replace(slugNotAllowedRegExp, '')
        .replace(/[| -]+/g, '-');

    return encodeURI(slug).toLowerCase();
});

export function updateUlrWithLangCode(url: string, langCode: string) {
    const multiLanguageEnabled = getConfigValue<boolean>(FEATURE_FLAG_MULTILANGUAGE_ENABLED, false);
    if (!multiLanguageEnabled) {
        return url;
    }

    const parts = withRelativePath(url)
        .split(PATH_SEPARATOR)
        .filter(e => e);
    const interfaceLanguages: { id: string; slug: string }[] = getConfigValue(CONFIG_INTERFACE_LANGUAGES, []);
    const interfaceLanguage = interfaceLanguages.find(l => l.id === langCode);

    if (parts.length > 0) {
        const firstPart = parts[0].toLowerCase();
        if (interfaceLanguages.find(l => l.id === firstPart && !!l.slug)) {
            // Remove prefix for current language
            parts.shift();
        }
    }
    if (interfaceLanguage?.slug) {
        parts.unshift(interfaceLanguage.slug);
    }
    return getBasePath() + parts.join(PATH_SEPARATOR);
}

export function updatePathPartsByLangCode(path: string[]) {
    const multiLanguageEnabled = getConfigValue<boolean>(FEATURE_FLAG_MULTILANGUAGE_ENABLED, false);
    if (!multiLanguageEnabled) {
        return path;
    }

    const viewsWithLocaleInPath = getConfigValue(FEATURE_FLAG_MULTILANGUAGE_PAGES, '').split(',');
    const lang = getConfigValue(CONFIG_INTERFACE_CURRENT_LANGUAGE, '');
    const languages: { id: string; slug: string }[] = getConfigValue(CONFIG_INTERFACE_LANGUAGES, []);
    const interfaceLang = languages.find(l => l.id === lang);

    const pathPrefix = interfaceLang?.slug;

    const nextPage = path.length > 0 ? path[0].split('?')[0] : '/';
    if (viewsWithLocaleInPath.indexOf(nextPage) >= 0 && pathPrefix) {
        path.unshift(pathPrefix);
    }
    return path;
}

export function updatePathByLangCode(path: string) {
    const newPath = updatePathPartsByLangCode((path ?? '').split('/'));
    return newPath.join('/');
}
