import * as $ from "jquery";
import * as ndStates from "nd.states";
import menuRenderers from "nd.ui.menu.renderers";
import "nd.ui.menu.items.controllers";
import "nd.stats";
import { eventbus } from 'eventbus/eventbus';
import { EventTypes, IssueContextMenuEvent } from 'contextmenu/events/events';

	function _Base() { }

	var _isItemSelected = false;
	eventbus.ofType(EventTypes.IssueContextMenu).subscribe(() => {
		_isItemSelected = true;
	});

	_Base.prototype = {
		_menu: null,
		_visible: false,
		_history: null,
		show: function(config) {
			if (this._visible) { return Promise.resolve(); }
			ndStates.busy.set();
			ndStates.modal.set();
		    return Promise.resolve(this.renderer(config))
		            .then(function(renderer) {
		                    this._show(config, renderer);
		                    this._visible = true;
		                    return this;
		                }.bind(this));
		},
		showSubmenu: function(renderer, config) {
			if (!config) {
				config = this._history[0].config;
			}
			renderer.canGoBack(true);
			this._show(config, renderer);
		},
		_show: function(config, renderer) {
			var params = { config: config, renderer: renderer };
			if (!this._history) this._history = [params];
			else this._history.push(params);
			this._menu.show(params.config, params.renderer);
			$.nd.stats.pauseStats();
			$(this).trigger("onshow");
			return this;
		},
		back: function() {
			if (!this._history || this._history.length <= 1) return this;
			this._history.pop();
			var params = this._history.pop();
			this._show(params.config, params.renderer);
			return this;
		},
		hide: function() {
            if (!this._visible) return;
            if (!_isItemSelected) {
                eventbus.send(new IssueContextMenuEvent({ action: 'close' }));
			}
            _isItemSelected = false;
            $(this).trigger("onhide");
			this._menu.hide();
			this.reset();
			this._visible = false;
			ndStates.busy.reset();
			ndStates.modal.reset();
			$.nd.stats.resumeStats();
			return this;
		},
		onHide: function (fn) {
			$(this).one("onhide", fn);
		},
		onShow: function (fn) {
			$(this).one("onshow", fn);
		},
		visible: function() {
			return this._visible;
		},
		reset: function() {
			this._menu.empty();
			delete this._history;
			return this;
		},
		menu: function() {
			return this._menu;
		},
		renderer: function() {},
	};

	function _Bookmarks() {
		this._menu = new $.nd.ui.menu.view(this);
		this.controller = null;
	}
	$.extend(_Bookmarks.prototype, _Base.prototype, {
		_renderer: null,
		renderer: function () {
			if (!this._renderer) {
				var controller = $.nd.ui.menu.items.controllers.get("bookmarks");
				if (controller) {
					this.controller = new controller(this);
					if (this.controller.available()) {
						this._renderer = new menuRenderers.custom(this.controller, "v7.Client.Dialogs.Menu.Bookmarks");
					}
				}
			}

			return this._renderer;
		},

		showMenu: function (config) {
			this.show(config);
			if (this.controller) {
			    this.controller.show(config.profileId);
			}
		}
	});

	function SingleItemController(config) {
		this._menu = new $.nd.ui.menu.view(this);
		this._config = {};
		$.extend(this._config, this._defaultConfig, config);
	}

	$.extend(SingleItemController.prototype, _Base.prototype, {
		_defaultConfig: {},
		_renderer: null,
		itemsControllerParams: function (value) {
			if (value && value.length) {	// duck typing
				this._itemsControllerParams = value;
			}
			return this;
		},
		itemsControllerCTOR: function(value) {
			if (arguments.length == 0) {
				return this._itemsControllerCTOR;
			}
			if ($.isFunction(value)) {
				this._itemsControllerCTOR = value;
			}
			return this;
		},
		initItem: function() {
			function applyToConstructor(constructor, argArray) {
				var args = [null].concat(argArray);
				var factory = constructor.bind.apply(constructor, args);
				return new factory();
			}

			if (this._itemsControllerCTOR) {
				var instance = applyToConstructor(this._itemsControllerCTOR, this._itemsControllerParams);
				if (instance.available()) {
					this.item = instance.item();
				}
			}
			return this;
		},
		renderer: function() {
			if (!this._renderer && this.item) {
				this._renderer = new menuRenderers.list(this).title(""); // todo: use custom renderer if possible
				//this._renderer.addItem(this.item);
				this._renderer.render = function() {
				};

			}
			return this._renderer;
		},
		show: function (config) {
			if (!this.item) {
				this.initItem();
			}
			if (this._visible || !this.item)
				return;
			ndStates.busy.set();
			ndStates.modal.set();
			this._show(config, this.renderer(config));
			this.item.controller().activate(this.item);
			$(this).trigger("onshow");
			return this;
		},
		_show: function (config, renderer) {
			if (!this._history) {
				this._history = [];
			}
			this._history.push({ config: config, renderer: renderer });
			// first two history items are: fake history from SingleItem controller and history from Item on upper level
			renderer.canGoBack(this._history.length > 2);
			this._menu.show(config, renderer);
			this._visible = true;
			$.nd.stats.pauseStats();
			return this;
		},
		toggleVisibleFlag: function(visible) {
			this._visible = visible;
		},
		showSubmenu: function (renderer, config) {
			if (!config) {
				config = this._history[0].config;
			}
			this._show(config, renderer);
		},
		dispose: function () {
			this._menu.dispose();
			delete this._menu;
			delete this._config;
			if (this.item) {
				delete this.item;
			}
		}
	});


	$.nd.ui.menu.controllers = {
		base: _Base,
		//search: new _Search(),
		bookmarks: new _Bookmarks(),
		singleItem: SingleItemController
    };
    
    export default $.nd.ui.menu.controllers;


