'use strict';

import MenuItemControllers from '@pressreader/src/nd.ui.menu.items.controllers';
import MenuItems from '@pressreader/src/nd.ui.menu.items';
import ndRes from 'nd.res';
import { eventbus } from '@pressreader/src/eventbus/eventbus';
import { ContextMenuEvent, ContextMenuEventCompleted, ContextMenuEventFailed } from 'contextmenu/events/events';

const MenuItemControllerBase = MenuItemControllers.base;
const MenuItem = MenuItems.item;

/**
 * Base class for context menu controllers.
 */
class ContextMenuItemController extends MenuItemControllerBase {
    constructor(parent, view, context) {
        super(parent);
        this._view = view;
        this._context = context;
    }

    available(...args) {
        if (!this.action) {
            return Promise.resolve(false);
        }

        // if method invoked with arguments
        const [view, context] = args.length === 2 ? args : [this._view, this._context];
        return Promise.resolve(this.action.available(view, context));
    }

    enabled(...args) {
        if (!this.action) {
            return Promise.resolve(false);
        }

        // if method invoked with arguments
        const [view, context] = args.length === 2 ? args : [this._view, this._context];
        return Promise.resolve(this.action.enabled(view, context));
    }

    activate(...args) {
        if (!this.action) {
            return Promise.resolve();
        }

        const [view, context] = args.length === 2 ? args : [this._view, this._context];
        const previewType = context.preview?.previewType || 'article';
        const elementsShared = previewType === 'page' ? context.pageNumbers || [context.page] : [context.article?.ArticleId];
        const eventsPayloads = elementsShared.map(element => ({
            action: args[0]._name,
            label: previewType,
            value: element,
        }));
        eventsPayloads.forEach(eventPayload => eventbus.send(new ContextMenuEvent(eventPayload)));

        // hide context menu first x
        if (this._parent && this._parent.hide) {
            this._parent.hide();
        }

        // activate could return plain value, so wrap it just in case.
        let result = this.action.activate(view, context);
        if (typeof result?.then !== 'function') {
            result = Promise.resolve(result);
        }
        return result
            .then(data => {
                eventsPayloads.forEach(eventPayload => eventbus.send(new ContextMenuEventCompleted(eventPayload)));
                return data;
            })
            .catch(e => {
                eventsPayloads.forEach(eventPayload => eventbus.send(new ContextMenuEventFailed(eventPayload)));
                return e;
            });
    }

    activated(...args) {
        if (!this.action) {
            return Promise.resolve(false);
        }

        const [view, context] = args.length === 2 ? args : [this._view, this._context];
        return this.action.activated(view, context);
    }

    _getItem(itemName, resourceName, cssClass) {
        if (!this._item) {
            this._item = new MenuItem(this, ndRes.val(resourceName)).name(itemName).css(cssClass).bind(this.activate.bind(this));
        }

        return this._item;
    }
}

export default ContextMenuItemController;
