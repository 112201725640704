/* eslint-env es6 */

'use strict';

const clickAndTouchHandled = '__clickAndTouchHandled';

const clickAndTouch = {
    /**
     * Marks the specified event as handled by clickAndTouch binding.
     * @param {TouchEvent} event - event to be marked as handled.
     */
    handle(event) {
        event[clickAndTouchHandled] = true;
    },

    /**
     * Checks whether the specified event was handled by clickAndTouch binding.
     * @param {TouchEvent} event - event to be verified.
     * @returns {boolean} - true, if event was processed by clickAndTouch handler,
     * otherwise false.
     */
    isHandled(event) {
        return !!event[clickAndTouchHandled];
    },
};

export default { clickAndTouch };
