import * as $ from "jquery";
import Rectangle from "layout/rectangle";
import ndEvent from "nd.event";
import "nd.core";

	var PanelPos = function (val) {
		this.val = val;
		this.isAuto = val == "auto";
		this.isFixed = !this.isAuto;
	};
	PanelPos.auto = new PanelPos("auto");
	PanelPos.fix = function (val) {
		if (!val && val != 0) return PanelPos.auto;
		if (val.isAuto == undefined) return new PanelPos(val);
		return val;
	};
	var PanelSize = function (val) {
		this.val = val;
		this.isAuto = val == "auto";
		this.isFill = val == "fill";
		this.isFixed = !this.isAuto && !this.isFill;
	};
	PanelSize.auto = new PanelSize("auto");
	PanelSize.fill = new PanelSize("fill");
	PanelSize.fix = function (val) {
		if (!val && val != 0) return PanelSize.auto;
		if (val.isAuto == undefined) return new PanelSize(val);
		return val;
	};
	var PanelType = {
		Verticale: 0,
		Horizontal: 1
	};


	var Panel = function (def, layout, parent) {
		this._layout = layout;
		this._parent = parent;
		this._childs = [];
		this._rect = new Rectangle();
		this._layerType = PanelType.Verticale;

		this._visible = true;
		for (var name in def)
			this["_" + name] = def[name];

		this._verticale = this._layerType === PanelType.Verticale;
		this._horizontal = this._layerType === PanelType.Horizontal;

		this._height = PanelSize.fix(this._height);
		this._width = PanelSize.fix(this._width);
		this._left = PanelPos.fix(this._left);
		this._top = PanelPos.fix(this._top);
		this.init();
	};
	Panel.prototype = Panel.fn = {
		init: function () {
			if (!this._elm) {
				// try to mount the panel to the existing DOM-element
				var id = "Panel_" + this.name();
				var element = document.getElementById(id);
				if (element !== null) {
                    this._elm = $(element);
				} else {
                    var p = this._parent ? this._parent.elm() : $(document.body);
                    this._elm = p.createChild("div", "absolute");
                    this._elm[0].id = id;
				}
			}
			if (this._left.isFixed)
				this.left(this._left.val);
			if (this._top.isFixed)
				this.top(this._top.val);
			if (this._width.isFixed)
				this.width(this._width.val);
			if (this._height.isFixed.val)
				this.height(this._height);
			this.visible(this._visible);
		},
		_update: function () {
			var node = this.node();
			this._rect.left = node.offsetLeft;
            this._rect.top = node.offsetTop;

			if (!this._width.isFill)
                this._rect.width = node.offsetWidth;

			if (!this._height.isFill)
                this._rect.height = node.offsetHeight;

			return this;
		},
		resize: function (data, fn) {
			if (arguments.length > 0)
				ndEvent.bind(this, "resize", data, fn);
			else
				ndEvent.trigger($.Event("resize"), this);
			return this;
		},
		//onresize: function() {
		//	$(this).trigger($.Event("resize")); return this;
		//},
		oncontentchanged: function () {
			if (!this._updating) {
				// do not reflow if updating
				this._update()._layout.reflow();
			}
			return this;
		},
		left: function (value) {
			if (arguments.length) {
				// very slow
				//this._rect.left = this.elm().css("left", value)[0].offsetLeft;
				//return this.oncontentchanged();
				if (this._rect.left != value) {
					this.elm()[0].style.left = value + "px";
					this._rect.left = value;
					return this.oncontentchanged();
				}
				return this;
			}
			return this._rect.left;
		},
		top: function (value) {
			if (arguments.length) {
				// very slow
				//this._rect.top = this.elm().css("top", value)[0].offsetTop;
				//return this.oncontentchanged();
				if (this._rect.top != value) {
					this.elm()[0].style.top = value + "px";
					this._rect.top = value;
					return this.oncontentchanged();
				}
			}
			return this._rect.top;
		},
		width: function (value) {
			if (arguments.length) {
				// very slow
				//this._rect.width = this.elm().css("width", value)[0].offsetWidth;
				//return this.oncontentchanged();
				if (this._rect.width != value) {
					this.elm()[0].style.width = value + "px";
					this._rect.width = value;
					return this.oncontentchanged();
				}
				return this;
			}
			return this._rect.width;
		},
		height: function (value) {
			if (arguments.length) {
				// very slow
				//this._rect.height = this.elm().css("height", value)[0].offsetHeight;
				//return this.oncontentchanged();
				if (this._rect.height != value) {
					this.elm()[0].style.height = value + "px";
					this._rect.height = value;
					return this.oncontentchanged();
				}
				return this;
			}
			return this._rect.height;
		},
		show: function () {
			return this.visible(true);
		},
		hide: function () {
			return this.visible(false);
		},
		visible: function (val) {
			if (arguments.length) {
				if (this._visible != val) {
					this._visible = val;
					this.elm()[val ? "show" : "hide"]();
					return this.oncontentchanged();
				}
				return this
			}
			return this._visible; //this.elm().visible();
		},
		node: function () {
			return this._elm[0];
		},
		createChild: function (def) {
			var l = new Panel(def, this._layout, this);
			def = null;
			if (l.name()) {
				this[l.name()] = function () { return l; };
			}
			this._childs.push(l);
			return l;
		},
		reflow: function (l, t, w, h) {
			this._updating = true;

			var resized = false;

			if (!this._left.isFixed)
				this.left(l);
			if (!this._top.isFixed)
				this.top(t);

			if (this._width.isFill && this.width() !== w) {
				this.width(w);
				resized = true;
			}
			if (this._height.isFill && this.height() !== h) {
				this.height(h);
				resized = true;
			}

			if (this._childs.length > 0) {
				var rect = this.rect(),
					width = rect.width,
					height = rect.height,
					left = 0,
					top = 0;

				if (this.verticale()) {
					for (var i = 0, length = this._childs.length; i < length; i++) {
						var child = this._childs[i];
						if (!child.visible())
							continue;

						if (!_isResizeEvent) {
							child._update();
						}

						var childrect = child.rect().clone();

						if (!child._top.isFixed)
							childrect.top = top;

						if (child._width.isFill)
							childrect.width = width;


						if (child._height.isFill) {
							if (child._absolute) {
								childrect.height = height - childrect.top;
							}
							else {
								var fillHeight = height - top;

								for (var j = i + 1; j < length; j++) {
									var nextChild = this._childs[j];
									if (nextChild.visible() && !nextChild._absolute) {

										if (!_isResizeEvent)
											nextChild._update();

										fillHeight -= nextChild.height();
									}
								}
								childrect.height = fillHeight;
							}
						}
						child.reflow(childrect.left, childrect.top, childrect.width, childrect.height);

						// panels with absolute = true doesn't change layout
						if (!child._absolute)
							top += child.height();
					}
				} else {
					// todo:
				}
			}

			if (resized) {
				this.resize();
			}

			this._updating = false;

			return this;
		}
	};
    $.nd.extendObjectWithProperties(Panel.fn, 'name,elm,parent,layout,rect,verticale,horizontal', true);
    var fieldName;
	$.each(('name,elm,parent,layout,rect,verticale,horizontal').split(','), function (i, name) { Panel.fn[name] = new Function('return this.field;'.replace(/field/g, fieldName = "_" + name)); });

	var _isResizeEvent = false;

	var Layout = (function() {

		var _size = {
			width: 0,
			height: 0
		},
			_root,
			_orientation,
            _frozen = false,
            _reflowOnUnFreeze = false;

		var _api = {
            nativeScroll: false,
			root: function(rootElmDef) {
				if (rootElmDef && !_root) {
					_root = new Panel(rootElmDef, this, null);
					window.addEventListener("resize", resizer);
					if ($.browser.iOS) window.addEventListener("orientationchange", resizer); // needed for only old iPad
					if (window.orientation != undefined) _orientation = window.orientation;
					this.reflow();
				}
				return _root;
			},
            freeze: function(val) {
                _frozen = !!val;
                if (!_frozen && _reflowOnUnFreeze) {
                    _reflowOnUnFreeze = false;
                    _api.reflow();
                }
            },
			reflow: function() {

                if (_frozen) {
                    _reflowOnUnFreeze = true;
                    return;
                }

				var w = $.windowWidth(),
					h = $.windowHeight();

				//console.info("reflow; h: " + _size.height + " -> " + h + "; w: " + _size.width + " -> " + w);

				if (w > 0 && h > 0) {
					_size.width = w;
					_size.height = h;

                    if (this.root()) {
                        var rootOffset = this.root().elm().offset();
                        h -= rootOffset.top;
                        _size.height = h;
                        this.root().reflow(0, 0, w, h);
                    }

					this.resize();
				}
				return this;
			},
			size: function() {
				return _size;
			},
			resize: function(data, fn) {
				if (arguments.length > 0)
					ndEvent.bind(this, "resize", data, fn);
				else
					ndEvent.trigger($.Event("resize"), this);
				return this;
			}
		};

		// Chrome on IOS does not trigger resize event when the onscreen keyboard is displayed,
		// however window.innerHeight also changes "silently".
		// Here is a watcher for $.windowHeight() based on setInterval,
		// which triggers 'resize' event on window whenever the height is changed for more then 1 px.
		if ($.browser.chromeIOS) {
			(function () {

				var sizeChangedHandler = function () {
					//TODO: construct event properly.
					window.dispatchEvent(new Event('resize'));
				};

				var watcher = {
					last: undefined,
					watchFn: function () {
						return $.windowHeight();
					},
					listenerFn: function (inner, oldInner) {
						if (Math.abs(inner - oldInner) <= 1) return;
						sizeChangedHandler();
					}
				};
				watcher.last = watcher.watchFn();

				function innerHeightDigest() {
					var newVal = watcher.watchFn();
					if (newVal !== watcher.last) watcher.listenerFn(newVal, watcher.last);
					watcher.last = newVal;
				}
				//TODO: clear interval when Layout is disposed.
				var timerId = setInterval(innerHeightDigest, 500);
			})();
		}

		var viewPort = (function () {

			function VPPropertyList() {
				for (var i = 0; i < arguments.length; i++) {
					this[arguments[i]] = undefined;
				}
			}

			$.extend(VPPropertyList.prototype, {
				getString: function () {
					var res = [];
					for (var prop in this) {
						if (this.hasOwnProperty(prop) && !$.isFunction(this[prop])) {
							res.push(prop + "=" + (this[prop] || ""));
						}
					}
					return res.join(",");
				}
			});

			var vp = {
				properties: new VPPropertyList("initial-scale", "maximum-scale", "user-scalable", "width", "height"),
				setPropertyValue: function (key, value) {
                    if (this._frozen) {
                        return;
                    }
					vp.properties[key] = value;
				},
				applyViewport: function () {
                    if (this._frozen) {
                        return;
                    }
					//console.info("applyViewport invoked: " + this.properties.getString());
					$("meta[name='viewport']", document.head).attr("content", this.properties.getString());
				},
				hasChanges: function () {
					var currentVpProps = $("meta[name='viewport']", document.head).attr("content").split(",");
					var equals = true;
					for (var i = 0; i < currentVpProps.length; i++) {
						var pair = currentVpProps[i].split("=");
						equals = this.properties[pair[0]] == pair[1];
						if (!equals) {
							break;
						}
					}
					return !equals;
                },
                freeze: function (val) {
                    if (arguments.length) {
                        this._frozen = val;
                        return this;
                    }

                    return this._frozen;
                }
			};

			vp.properties["initial-scale"] = 1;
			vp.properties["maximum-scale"] = 1;
			vp.properties["user-scalable"] = "no";
			vp.properties["width"] = "device-width";

			vp.setPropertyValue("height", $.windowHeight());
			vp.applyViewport();
			//window.viewport = _vp;
			return vp;
		})();

		var resizer = (function (vp, layout) {
			// Attention! This resizer has a side effect. It modifies document viewport. Consider that while using.

			var RESIZE_HANDLE_DELAY = 600,
				RESIZE_DELTA_PERCENT = 20,
				timer = undefined,
				lastCheckedSize = {
					height: $.windowHeight(),
					width: $.windowWidth()
				};

			function absChange(a, b) {
				return 100 * Math.abs(1 - a / b);
			}

			function handleAction() {
				timer = undefined; // reset timer

				var needsReflow = false,
					currentSize = {
						height: $.windowHeight(),
						width: $.windowWidth()
					};

				//console.info("resize; h: " + lastCheckedSize.height + " -> " + currentSize.height + "; w: " + lastCheckedSize.width + " -> " + currentSize.width);

				// It's a desktop or surface: resize no matter what the changed percentages are.
				// It's Chrome on IOS: resize because it does not consider viewport height 
				// and returns wrong values for both windows.innerHeight and jquery height methods
				if ((!$.browser.iOS && !$.browser.android) || $.browser.chromeIOS) {
					needsReflow = true;
				} else {
					var widthChanged = !!(currentSize.width !== lastCheckedSize.width);

					if (widthChanged || currentSize.height > lastCheckedSize.height) {	// if the width was changed or height is increased, reflow is needed
						needsReflow = true;
					} else { // width was not changed, try to determine if it was the onscreen keyboard which caused resizing
						var heightChangedPart = absChange(currentSize.height, lastCheckedSize.height);
						if (heightChangedPart <= RESIZE_DELTA_PERCENT && heightChangedPart !== 0) {
							// if the height was changed for less then {constant}%,
							// assume it was not a keyboard, reflow is needed

							needsReflow = true;
						} else {
							// if the height was changed for more then {constant}%,
							// assume is was onscreen keyboard, no reflow is needed

							needsReflow = false;
							vp.setPropertyValue("height", lastCheckedSize.height);
						}
					}
				}

				if (needsReflow === true) vp.setPropertyValue("height", currentSize.height);
				if (vp.hasChanges()) vp.applyViewport();

				if (needsReflow === true) {
					//console.info("reflow needed");
					layout.reflow();
					lastCheckedSize = currentSize;
				} 
				//else console.info("no reflow");
				
			}

			var handlerObj = {
				handleEvent: function (evt) {
					if (!timer) timer = setTimeout(function () { handleAction(evt); }, RESIZE_HANDLE_DELAY);
				}
			};

			return handlerObj;
        })(viewPort, _api);

        _api.viewPort = viewPort;

		return _api;
	})();

	$.nd.layout = Layout;
	$.nd.layout.size = PanelSize;
	$.nd.layout.pos = PanelPos;
	$.nd.layout.panel = Panel;
    $.nd.layout.panelType = PanelType;
    
    export { 
        PanelSize,
        PanelPos,
        Panel,
        PanelType,
    };

	export default Layout;
