import { applyMiddleware, combineReducers, compose, createStore, Reducer, ReducersMapObject, Store } from 'redux';
import createSagaMiddleware, { SagaMiddleware, Task } from 'redux-saga';
import { cancel } from 'redux-saga/effects';
import debounce from 'lodash/debounce';

import { IStoreStorage, StoreStoragesMapObject } from './storage/storage';
import { createPersistentStorage, IPersistentStorage, PersistentStorageType } from './storage/utils';

const PERSIST_DEBOUNCE_TIME = 1000;

export class StoreManager {
    private _store: Store;
    private _reducers: ReducersMapObject;

    private _persistent: { [storageType: string]: IPersistentStorage } = {
        [PersistentStorageType.Local]: createPersistentStorage('pr.store', PersistentStorageType.Local),
        [PersistentStorageType.Session]: createPersistentStorage('pr.store', PersistentStorageType.Session),
    };

    private _storages: { [storageType: string]: StoreStoragesMapObject } = {
        [PersistentStorageType.Local]: {},
        [PersistentStorageType.Session]: {},
    };

    private _sagaMiddlware: SagaMiddleware<Record<string, unknown>>;
    private _sagas: { [featureName: string]: Task };

    constructor() {
        this._reducers = { none: (state = 0) => state };

        const initialReducer = combineReducers(this._reducers);
        const initialState = {};

        this._sagaMiddlware = createSagaMiddleware();
        this._sagas = {};

        let composeEnhancers = compose;
        if (process.env['NODE_ENV'] !== 'production') {
            composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        }
        this._store = createStore(initialReducer, initialState, composeEnhancers(applyMiddleware(this._sagaMiddlware)));

        this._store.subscribe(debounce(this._onStateChanged.bind(this), PERSIST_DEBOUNCE_TIME));
    }

    get store() {
        return this._store;
    }

    addFeature<S>(featureName: string, reducer?: Reducer<S>, saga?: () => any) {
        if (reducer) {
            this._reducers[featureName] = reducer;
            this._onReducersChanged();
        }

        if (saga) {
            this._sagas[featureName] = this._sagaMiddlware.run(saga);
        }
    }

    addStoreStorage<S, SS>(storageType: PersistentStorageType, featureName: string, storage: IStoreStorage<SS, S>) {
        this._storages[storageType][featureName] = storage;
        this._persistent[storageType].hydrate(featureName, storage);
    }

    removeFeature(featureName: string) {
        if (featureName in this._reducers) {
            delete this._reducers[featureName];
            this._onReducersChanged();
        }

        if (featureName in this._storages) {
            delete this._storages[featureName];
        }

        if (featureName in this._sagas) {
            cancel(this._sagas[featureName]);
            delete this._sagas[featureName];
        }
    }

    runSaga(saga: () => any) {
        return this._sagaMiddlware.run(saga);
    }

    private _onReducersChanged() {
        this._store.replaceReducer(combineReducers(this._reducers));
    }

    private _onStateChanged() {
        const state = this._store.getState();

        if (state) {
            this._persistent[PersistentStorageType.Local].persist(state, this._storages[PersistentStorageType.Local]);
            this._persistent[PersistentStorageType.Session].persist(state, this._storages[PersistentStorageType.Session]);
        }
    }
}
