import * as ko from 'knockout';
import ndConfig from '@pressreader/src/deprecated/nd.config';
import resources from 'nd.res';

import { getActivations, deactivate, reactivate } from '@pressreader/src/account/services/clientactivation.service';
import { logAndAlertError } from '@pressreader/src/shared/errors/error.handlers';

class ClientActivationViewModel {
    constructor({ activation, expandEventStream }) {
        this._expandEventStream = expandEventStream;
        this._expandEventStreamSubscription = expandEventStream.subscribe(other => {
            if (other !== this) {
                this.collapse();
            }
        });

        this.isExpanded = ko.observable(false);
        this.id = activation.id;
        this.clientName = activation.clientName;
        this.clientVersion = activation.clientVersion;
        this.isActive = activation.isActive;
        this.status = resources.val(
            activation.isActive ? 'AccountingMyAccountPage.PressReader.Active' : 'AccountingMyAccountPage.PressReader.Inactive',
        );

        this.activationDate =
            activation.activationDate !== null
                ? resources.formatDate(activation.activationDate, 'Accounting.Order.FormatChargeDate', 'd MMM yyyy')
                : '';

        this.lastRequestDate =
            activation.lastRequestDate !== null
                ? resources.formatDate(activation.lastRequestDate, 'Accounting.Order.FormatChargeDate', 'd MMM yyyy')
                : '';

        this.updateDate =
            activation.updateDate !== null ? resources.formatDate(activation.updateDate, 'Accounting.Order.FormatChargeDate', 'd MMM yyyy') : '';
    }

    expand() {
        this.isExpanded(true);
        this._expandEventStream.next(this);
    }

    collapse() {
        this.isExpanded(false);
    }

    dispose() {
        this._expandEventStreamSubscription = undefined;
    }
}

class ClientActivationsViewModel {
    constructor({ resources, expandEventStream }) {
        this._expandEventStream = expandEventStream;
        this._resources = resources;
        // todo: seems to be not used, check caryfully
        this.includeInactive = ndConfig.get('Accounting.Controls.UserProfile.ShowInactive', false);
        this.activations = ko.observableArray();
        this.isLoading = ko.observable(false);
    }

    load() {
        this.isLoading(true);
        getActivations({ includeInactive: this.includeInactive })
            .then(activations => {
                // dispose existing list
                this.activations().forEach(s => s.dispose());

                this.activations(
                    activations.map(
                        activation =>
                            new ClientActivationViewModel({
                                activation,
                                resources: this._resources,
                                expandEventStream: this._expandEventStream,
                            }),
                    ),
                );
            })
            .catch(logAndAlertError)
            .finally(() => this.isLoading(false));
    }

    deactivateItem(activation) {
        deactivate(activation.id)
            .then(() => {
                this.load();
            })
            .catch(logAndAlertError);
    }

    activateItem(activation) {
        reactivate(activation.id)
            .then(() => {
                this.load();
            })
            .catch(logAndAlertError);
    }
}

export default ClientActivationsViewModel;
