if (!Date.now) {
    Date.now = function now() {
        return new Date().getTime();
    };
}

if (!window.performance) {
    const wa = window as any;
    wa.performance = {};
}

if (!window.performance.now) {
    let nowOffset = Date.now();

    if (window.performance.timing && window.performance.timing.navigationStart) {
        nowOffset = window.performance.timing.navigationStart;
    }

    window.performance.now = () => {
        return Date.now() - nowOffset;
    };
}
