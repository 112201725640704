import { ILanguage } from '@pressreader/types';

import { TranslationConfig } from '../translation.config';

interface TranslationConfigLanguageDto {
    name: string;
    pairs: string[];
}

interface TranslationConfigDto {
    enable: boolean;

    languages: { [code: string]: TranslationConfigLanguageDto };
}

function mapLang(code: string, langDto: TranslationConfigLanguageDto): ILanguage {
    return {
        code,
        title: langDto.name,
        originalTitle: langDto.name,
        slug: code,
    };
}

function mapTranslationConfig(configDto: TranslationConfigDto | undefined): TranslationConfig | null {
    if (!configDto) {
        return null;
    }
    const langCodes = Object.keys(configDto.languages);
    return {
        enabled: configDto.enable,
        languages: langCodes.map(code => mapLang(code, configDto.languages[code])),
        languagePairs: langCodes.reduce<{ [code: string]: string[] }>((map, code) => {
            map[code] = configDto.languages[code].pairs;
            return map;
        }, {}),
    };
}

export { TranslationConfigDto, mapTranslationConfig };
