import { createActionHandler, createReducer } from '@pressreader/appstore';
import { AnyPopup } from '@pressreader/popups-types';

import { popupChanged, popupClosed, popupOpened } from './actions';

interface IState {
    popups: AnyPopup[];
}

const initialState: IState = {
    popups: [],
};

const reducer = createReducer<IState>(
    initialState,
    createActionHandler(popupOpened, (state, { payload }) => ({
        ...state,
        popups: [...state.popups, payload.popup],
    })),
    createActionHandler(popupClosed, (state, { payload }) => ({
        ...state,
        popups: state.popups.filter(popup => popup.id !== payload.id),
    })),
    createActionHandler(popupChanged, (state, { payload }) => {
        const { popup } = payload;
        const idx = state.popups.findIndex(p => p.id === popup.id);
        if (idx < 0) {
            return state;
        }
        const popups = [...state.popups];
        popups[idx] = popup;
        return {
            ...state,
            popups,
        };
    }),
);

export { reducer, IState };
