import reduce from 'lodash/reduce';

import { ViewName } from '@pressreader/navigation-types';
import { routingManager } from '@pressreader/routing';

interface ViewManager {
    canLeaveView(params: Record<string, unknown>): Promise<boolean>;
    resetState?(): void;
    getResetParams?(): Record<string, unknown>;
}

interface Viewer {
    getActiveView(): ViewManager | null;
    getActiveViewName(): ViewName | null;
    findView(viewName: string | null): ViewManager | undefined;
}

interface ViewConfig {
    [key: string]: Record<string, unknown>;
}

let viewer: Viewer | null = null;

const viewsConfig: Partial<Record<ViewName, ViewConfig>> = {};

const setViewer = (newViewer: Viewer) => {
    viewer = newViewer;
};

interface Params {
    stateParams: Record<string, unknown>;
    stateLessParams: Record<string, unknown>;
}

const getParams = (viewName: ViewName, params: Record<string, unknown>): Params => {
    const viewConfig = viewsConfig[viewName] || {};

    return reduce<Record<string, unknown>, Params>(
        params,
        (allParams, value, key) => {
            const config = viewConfig[key] || {};

            if ('stateLess' in config) {
                allParams.stateLessParams[key] = value;
            } else {
                allParams.stateParams[key] = value;
            }

            return allParams;
        },
        { stateParams: {}, stateLessParams: {} },
    );
};

const registerView = (viewName: ViewName, config: ViewConfig) => {
    viewsConfig[viewName] = config;
};

const getViewUrl = (viewName: ViewName, params: Record<string, unknown> = {}) => {
    params['viewName'] = viewName;

    return routingManager.generateUrl(params).then(function (url) {
        return (url && url.url) || '';
    });
};

let isLanding = true;

const setIsLanding = (value: boolean) => {
    isLanding = value;
};

export { viewer, viewsConfig, setViewer, getParams, registerView, getViewUrl, isLanding, setIsLanding };
