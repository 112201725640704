enum LandingViewName {
    JetBluePassenger = 'jetblue.passengers',
}

enum ViewName {
    TopNews = 'topnews',
    PagesView = 'pageview',
    TextView = 'textview',
    FTSArticles = 'ftsarticles',
    Bookmarks = 'bookmarks',
    Bookmark = 'bookmark',
    // PublicBookmarks:"publicbookmarks",
    ManageBookmarks = 'managebookmarks',
    EmailAlerts = 'emailalerts',
    Votes = 'votes',
    Home = 'home',
    Radio = 'radio',
    Monitors = 'monitors',
    Profile = 'profile',
    Similar = 'similar',
    EmptyView = 'emptyview',
    Categories = 'categories',
    External = 'external',
    Help = 'help',
    Opinion = 'opinion',
    OpinionsHub = 'opinionshub',
    OpinionsTrends = 'opinionstrend',
    Post = 'post',
    HotSpotsHub = 'hotspotshub',
    ReadingMap = 'readingmap',
    StoreHub = 'catalog',
    Error = 'error',
    Account = 'account',
    Purchase = 'purchaseview',
    Receipt = 'receipt',
    Article = 'article',
    Onboarding = 'onboarding',
    IssueDetails = 'issuedetails',
    LatestIssues = 'latestissues',
    Auth = 'auth',
    MyProfile = 'myprofile',
    Payment = 'payment',
    SiteMap = 'sitemap',
    DocumentView = 'documentview',
    GiftCard = 'giftcard',
    PromotionBilling = 'promotionbilling',
    Landing = 'landing',
    // integrations
    MasterCard = 'mastercard',
    AmexSignUp = 'amexsignup ',
    GartnerConf = 'gartnerconf',
    BookDetails = 'bookdetails',
    Wttc = 'wttc',
    FijiAir = 'fijiair',
    Tishman = 'tishman',
    Vna = 'vna',
    Search = 'search',
    BookView = 'bookview',
    FindHotspot = 'findhotspot',
}

enum CatalogView {
    Catalog = 'catalog',
    Books = 'books',
    Newspapers = 'newspapers',
    Magazines = 'magazines',
    PDFBooks = 'pdfbooks',
}

export { LandingViewName, ViewName, CatalogView };
