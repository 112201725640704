import { localSettings, globalSettings } from '@pressreader/usersettings';

import $ndUser from 'nd.user';
import 'nd.core';

// also use getGlobalStorage as provider for user's data loader
$ndUser.loader.setLoadFunction(globalSettings.loadSettings);

$.nd.userSettings = {
    local: localSettings,
};
