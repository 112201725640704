// eslint-env es6

import { resolveAction } from 'actions/action.service';

class BehaviourActionViewModel {
    constructor({ name, title, decoration, clientAction, params }) {
        this.name = name;
        this.title = title;
        this.decoration = decoration;
        this._action = resolveAction(clientAction, params);
    }

    execute(context) {
        return this._action.activate(context);
    }
}

export default BehaviourActionViewModel;
