import * as $ from 'jquery';
import { ReadingMapUploader } from 'readingstats/uploaders/readingmap';

import 'nd.core';

    var self = {};

    var currentFrame = null;
    /**
     * waiting timeout before statistics gets recorded - fixes issue when user scrolls frames
     * @type {Number}
     */
    var minTimeout = 1500;
    var minTimerId = null;

    /**
     * max possible period of statistics collecting
     * if user reads the same frame more that this amount of time - it means that they probably went for a break
     * @type {Number}
     */
    var maxTimeout = 60000;
    var maxTimerId = null;

    var _uploader = new ReadingMapUploader();
    var frames = [];

    var Frame = function(issueId) {
        this.startTime = null;
        this.duration = null;
        this.readingDuration = 0;
        this.pages = null;
        this.issueId = issueId;
    };

    Frame.prototype = {
        _getAreas : function() {
            var areas = [];
            var layout = $.nd.viewer.pagesViewLayout;
            if ( !layout )
                return [];
            var totalSquare = 0;
            if(layout && $.isFunction(layout.activePages)) {
                var pages = layout.activePages();
                
                for(var pageIndex in pages) if(pages.hasOwnProperty(pageIndex)) {
                    var page = pages[pageIndex];
                    if(page.isVisible()) {
                        var area = page.getNormalizedVisibleRect();
                        if (area) {
                            totalSquare += area.visibleAreaSquare;
                            areas.push(area);
                        }
                    }
                }

                var len = areas.length;
                for(var i=0; i < len; i++) {
                    var a = areas[i];
                    a.visibilityRate = (a.visibleAreaSquare / totalSquare).toPrecision(4);
                }
            }

            return areas;
        },
        start: function () {
            this.startTime = new Date();
            this.pages = this._getAreas();
            return this;
        },
        stop: function () {
            if(this.endTime) return;
            
            this.endTime = new Date();
            this.duration = parseInt(this.endTime - this.startTime);
            this.readingDuration = this.duration + this.readingDuration;
        }
    };

    var sendCurrentFrame = function(force) {
        if(currentFrame) {
            currentFrame.stop();
            if(currentFrame.duration > 1000 && currentFrame.pages && currentFrame.pages.length) {
                frames.push(currentFrame);
                if(_uploader) {
                    _uploader.upload(frames, force);
                    frames = [];
                }
            }
        }
        currentFrame = null;
    };

    var startNewFrame = function() {
        currentFrame = new Frame($.nd.viewer.pagesViewLayout.context().issue).start();
    };

    var updateFrame = function() {
        sendCurrentFrame();
        startNewFrame();
    };
    self.updateFrame = updateFrame;

    function minTimerTimeout(){
	updateFrame();
	minTimerId = 0;
    }
    function maxTimerTimeout(){
	sendCurrentFrame();
	maxTimerId = 0;
    }

    var updateMap = function() {
	if(minTimerId){
        	clearTimeout(minTimerId);
		minTimerId = 0;
	}
	if(maxTimerId){
        	clearTimeout(maxTimerId);
		maxTimerId = 0;
	}

        if(!self.paused) {
            minTimerId = setTimeout(minTimerTimeout, minTimeout);
            maxTimerId = setTimeout(maxTimerTimeout, maxTimeout);
        }
    };

    /**
     * Temporary disables page statistics (when something blocks the view or opens TextView)
     */
    self.pause = function() {
        if(!self.paused) {
            self.paused = 1;
            if(currentFrame && !currentFrame.haltTime) {
                currentFrame.haltTime = new Date();
            }
        }
    };

    /**
     * Continues reading map statistics collection
     */
    self.resume = function() {
        if(self.paused) {
            self.paused = 0;
            if (currentFrame && currentFrame.haltTime) {
                currentFrame.readingDuration = parseInt(currentFrame.haltTime - new Date());
                delete currentFrame.haltTime;
            }
        }
    }

    let readingStatsInitialized = false;
    function initReadingMapStats() {
        if (readingStatsInitialized) {
            return;
        }

        $.required("$.nd.viewer.pagesView", function (module) {
            readingStatsInitialized = true;

            module.layoutUpdated(updateMap);
            $(window).on('unload', function () { sendCurrentFrame(true); });
        });
    }

    export {
        initReadingMapStats,
    };

    export default self;
