import { IIssueInfoImageMeta, IReplicaPage } from '@pressreader/content-types';
import { ISize } from '@pressreader/geometry';
import { NullableFields } from '@pressreader/types';

import { adjustSpreadPageSizes, calculatePageSizes, getMaxUnrestrictedPageScale } from './size.math';

function buildPageImageMeta(data: {
    singleLayer: boolean;
    sizes: ReturnType<typeof calculatePageSizes>;
    maxUnrestrictedScale: number;
}): IIssueInfoImageMeta {
    return {
        singleLayer: data.singleLayer,
        maxUnrestrictedScale: data.maxUnrestrictedScale || 0,
        sizes: data.sizes.map(s => s.size),
        tiled: data.sizes.reduce<IIssueInfoImageMeta['tiled']>((map, s) => {
            if (s.tiled) {
                map[`${Math.floor(s.size.width)}x${Math.floor(s.size.height)}`] = true;
            }

            return map;
        }, {}),
        scales: data.sizes.reduce<IIssueInfoImageMeta['scales']>((map, s) => {
            map[`${Math.floor(s.size.width)}x${Math.floor(s.size.height)}`] = s.scale;
            return map;
        }, {}),
    };
}

function mapIssueInfoSinglePageImageMeta(
    page: IReplicaPage,
    meta: {
        singleLayer: boolean;
        nonTiledSizes: NullableFields<ISize, 'width'>[];
        tiledSizes: ISize[];
        maxPageSize: ISize | null;
        maxUnrestrictedPageSize: ISize;
    },
): IIssueInfoImageMeta {
    const sizes = calculatePageSizes(page, meta);
    const maxUnrestrictedScale = getMaxUnrestrictedPageScale(page.size, meta.maxUnrestrictedPageSize, meta.nonTiledSizes);

    return buildPageImageMeta({
        singleLayer: meta.singleLayer,
        sizes,
        maxUnrestrictedScale,
    });
}

function mapIssueInfoSpreadPageImageMeta(
    leftPage: IReplicaPage,
    rightPage: IReplicaPage,
    meta: {
        singleLayer: boolean;
        nonTiledSizes: NullableFields<ISize, 'width'>[];
        tiledSizes: ISize[];
        maxPageSize: ISize | null;
        maxUnrestrictedPageSize: ISize;
    },
): { left: IIssueInfoImageMeta; right: IIssueInfoImageMeta } {
    const { left, right } = adjustSpreadPageSizes(calculatePageSizes(leftPage, meta), calculatePageSizes(rightPage, meta));

    const leftMaxUnrestrictedScale = getMaxUnrestrictedPageScale(leftPage.size, meta.maxUnrestrictedPageSize, meta.nonTiledSizes);
    const rightMaxUnrestrictedScale = getMaxUnrestrictedPageScale(rightPage.size, meta.maxUnrestrictedPageSize, meta.nonTiledSizes);

    return {
        left: buildPageImageMeta({ singleLayer: meta.singleLayer, sizes: left, maxUnrestrictedScale: leftMaxUnrestrictedScale }),
        right: buildPageImageMeta({ singleLayer: meta.singleLayer, sizes: right, maxUnrestrictedScale: rightMaxUnrestrictedScale }),
    };
}

function setReplicaPageImageMeta(
    options: {
        singleLayer: boolean;
        maxFirstPageSize: ISize;
        maxNonFirstPageSize: ISize;
        allowedNonTiledSizes: NullableFields<ISize, 'width'>[];
        allowedTiledSizes: ISize[];
    },
    pages: IReplicaPage[],
): void {
    const { singleLayer, maxFirstPageSize, maxNonFirstPageSize, allowedNonTiledSizes, allowedTiledSizes } = options;

    for (let i = 0; i < pages.length; i++) {
        const page = pages[i];

        const maxPageSize = !page.isLocked ? null : page.pageNumber === 1 ? maxFirstPageSize : maxNonFirstPageSize;
        const maxUnrestrictedPageSize = page.pageNumber === 1 ? maxFirstPageSize : maxNonFirstPageSize;

        if (page.spread) {
            const nextPage = pages[i + 1];
            const leftPage = page.spread === 'left' ? page : nextPage;
            const rightPage = page.spread === 'left' ? nextPage : page;

            const { left: leftImageMeta, right: rightImageMeta } = mapIssueInfoSpreadPageImageMeta(leftPage, rightPage, {
                singleLayer,
                nonTiledSizes: allowedNonTiledSizes,
                tiledSizes: allowedTiledSizes,
                maxPageSize,
                maxUnrestrictedPageSize,
            });

            leftPage.imageMeta = leftImageMeta;
            rightPage.imageMeta = rightImageMeta;
            i++;
        } else {
            page.imageMeta = mapIssueInfoSinglePageImageMeta(page, {
                singleLayer,
                nonTiledSizes: allowedNonTiledSizes,
                tiledSizes: allowedTiledSizes,
                maxPageSize,
                maxUnrestrictedPageSize,
            });
        }
    }
}

export { setReplicaPageImageMeta };
