import { storeManager } from '@pressreader/appstore';

import { NAVIGATION_CORE_MODULE_NAME } from '../constants';

import { reducer } from './reducer';
import { saga } from './sagas';

storeManager.addFeature(NAVIGATION_CORE_MODULE_NAME, reducer, saga);

export * from './actions';
export * from './selectors';
