import debounce from 'lodash/debounce';

import { getScreenSizeType } from '@pressreader/ui-common';
import { ScreenSizeType } from '@pressreader/ui-types';

const screenTypeToClasses = {
    [ScreenSizeType.XLarge]: 'screen-medium-up screen-large-up screen-xlarge',
    [ScreenSizeType.Large]: 'screen-medium-up screen-large screen-large-up',
    [ScreenSizeType.Medium]: 'screen-medium screen-medium-up',
    [ScreenSizeType.Small]: 'screen-small',
    [ScreenSizeType.XSmall]: 'screen-xsmall',
} as const;

let lastScreenSizeClass = 'none';

function setScreenSizeClass() {
    const htmlElement = document.getElementsByTagName('html').item(0);
    const screenSizeType = getScreenSizeType();

    const actualClass = screenTypeToClasses[screenSizeType];

    if (actualClass !== lastScreenSizeClass) {
        htmlElement.classList.remove(...lastScreenSizeClass.split(' '));
        htmlElement.classList.add(...actualClass.split(' '));

        lastScreenSizeClass = actualClass;
    }
}

setScreenSizeClass();

window.addEventListener('resize', debounce(setScreenSizeClass, 300));
