import last from 'lodash/last';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import memoize from 'lodash/memoize';
import isDate from 'lodash/isDate';

enum TokenType {
    DateComponent = 1,
    Text = 2,
}

interface IToken {
    type: TokenType;
    letter: string;
    value: string;
    escape: boolean;
}

const escape = '\\';

type DateFormat = (input: Date) => string;
type DateComponentExtractor = (input: Date) => number;
type DateComponentFormat = (input: number) => string;

function leadZero(input: number): string {
    return input < 10 ? `0${input}` : input.toString();
}

/**
 * Date component value extractors.
 */
const extractors = {
    year: (date: Date) => date.getFullYear(),
    month: (date: Date) => date.getMonth(),
    day: (date: Date) => date.getDate(),
    weekday: (date: Date) => date.getDay(),
    hours24: (date: Date) => date.getHours(),
    hours12: (date: Date) => date.getHours() % 12 || 12,
    minutes: (date: Date) => date.getMinutes(),
    seconds: (date: Date) => date.getSeconds(),
};

const longMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const shortMonths = longMonths.map(m => m.substr(0, 3));

const longDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const shortDays = longDays.map(d => d.substr(0, 3));
const minDays = longDays.map(d => d.substr(0, 2));

class DateComponent {
    public readonly extract: DateComponentExtractor;
    public readonly format: DateComponentFormat;

    constructor(extractor: DateComponentExtractor, formatter: DateComponentFormat) {
        this.extract = extractor;
        this.format = formatter;
    }
}

/**
 * Format placeholders configuration.
 */
const components: Record<string, DateComponent> = {
    y: new DateComponent(extractors.year, year => (year % 100).toString()),
    yy: new DateComponent(extractors.year, year => leadZero(year % 100).toString()),
    yyyy: new DateComponent(extractors.year, year => year.toString()),
    d: new DateComponent(extractors.day, day => day.toString()),
    dd: new DateComponent(extractors.day, day => leadZero(day)),
    ddd: new DateComponent(extractors.weekday, weekday => shortDays[weekday]),
    dddd: new DateComponent(extractors.weekday, weekday => longDays[weekday]),
    M: new DateComponent(extractors.month, month => (month + 1).toString()),
    MM: new DateComponent(extractors.month, month => leadZero(month + 1)),
    MMM: new DateComponent(extractors.month, month => shortMonths[month]),
    MMMM: new DateComponent(extractors.month, month => longMonths[month]),
    H: new DateComponent(extractors.hours24, hours => hours.toString()),
    HH: new DateComponent(extractors.hours24, hours => leadZero(hours)),
    h: new DateComponent(extractors.hours12, hours => hours.toString()),
    hh: new DateComponent(extractors.hours12, hours => leadZero(hours)),
    TT: new DateComponent(extractors.hours24, hours => (hours < 12 ? 'AM' : 'PM')),
    m: new DateComponent(extractors.minutes, minutes => minutes.toString()),
    mm: new DateComponent(extractors.minutes, minutes => leadZero(minutes)),
    s: new DateComponent(extractors.seconds, seconds => seconds.toString()),
    ss: new DateComponent(extractors.seconds, seconds => leadZero(seconds)),
};

/**
 * The letters supported in format placeholders.
 */
const placeholders = reduce<string, Record<string, boolean>>(
    keys(components),
    (res, key) => {
        res[key.charAt(0)] = true;
        return res;
    },
    {},
);

/**
 * Aggregates single characters into tokens - date fragments and text fragments.
 *
 * @param {IToken[]} tokens - list of parsed tokens
 * @param {string} character - next character
 */
function aggregate(tokens: IToken[], character: string): void {
    const recent = last(tokens);

    // accumulate if
    if (
        recent !== undefined &&
        // repetition (all placeholders go under this condition)
        (recent.letter === character ||
            // or
            // last fragment is text and next character is not placeholder
            // or
            // last fragment is text and next character is escaped placeholder
            (recent.type === TokenType.Text && (!(character in placeholders) || recent.escape)))
    ) {
        if (character !== escape) {
            recent.value += character;
            recent.escape = false;
        } else {
            recent.escape = true;
        }
    } else {
        // new character
        // open new component
        if (character in placeholders) {
            tokens.push({ type: TokenType.DateComponent, letter: character, value: character, escape: false });
        } else if (character === escape) {
            tokens.push({ type: TokenType.Text, letter: '', value: '', escape: true });
        } else {
            tokens.push({ type: TokenType.Text, letter: '', value: character, escape: false });
        }
    }
}

function _tokenize(format: string): IToken[] {
    const length = format.length;
    const tokens: IToken[] = [];
    for (let i = 0; i < length; i++) {
        aggregate(tokens, format.charAt(i));
    }
    return tokens;
}

const tokenize = memoize(_tokenize);

/**
 * Creates a function, specific to the given date format,
 * which converts a date into a string accordingly to format specification.
 * @param {string} format - format specification
 * @param {IDictionary<DateComponentFormat>} customFormats - custom format providers
 * @return {DateFormat} - date format function
 */
function compile(format: string, customFormats?: Record<string, DateComponentFormat>): DateFormat {
    // Stage 1: Tokenization.
    // Break down the format specification into fragments (tokens).
    const tokens: IToken[] = tokenize(format);

    // Stage 2: Compilation.
    // Create fragments evaluators.
    // Each fragment evaluator is a function making text representation of fragment.
    const fragments = tokens.map((token: IToken): DateFormat => {
        if (token.type === TokenType.DateComponent) {
            // Date component fragment.
            const component = components[token.value];
            if (!component) {
                throw new Error(`Invalid date format '${format}': '${token.value}' component is not supported`);
            }

            // Create a function, specific to the fragment specification (like, 'MMM', 'yyyy' etc.) and custom formats provided.
            if (customFormats && token.value in customFormats) {
                return date => customFormats[token.value](component.extract(date));
            } else {
                return date => component.format(component.extract(date));
            }
        } else {
            // Static text fragment.
            return () => token.value;
        }
    });

    return (input: Date) => {
        if (!isDate(input)) {
            throw new TypeError('Input is not a date');
        }

        return fragments.map(evaluate => evaluate(input)).join('');
    };
}

/**
 * Creates date formatting function for given format specification.
 * @param {string} format - format specification, e.g. "dd.MM.yyyy", "dd MMM \M\o\n\t\h yyyy"
 * @param {IDictionary<DateComponentFormat>} customFormats - dictionary of custom date components formats, where:
 *                                                           key is a date component placeholder (like 'MMMM'),
 *                                                           value is a function that formats a date component expressed as a number
 *                                                           (year, month [0..11], day [0..28|29|30|31], weekday [0..6], hour [0..23], etc.)
 * @return {DateFormat} function that formats given date
 */
function createDateFormat(format: string, customFormats?: Record<string, DateComponentFormat>): DateFormat {
    if (!format) {
        throw new Error('Date format must not be empty');
    }

    return compile(format, customFormats);
}

export { longMonths, shortMonths, longDays, shortDays, minDays, createDateFormat };
