import { filter, map } from 'lodash';

import config from '@pressreader/src/deprecated/nd.config';
import { loadExternalScript } from '@pressreader/utils';

export async function load() {
    await config.loaded();

    const CDNRsources: any = config.get('CDNResources') || [];
    const promises = map(
        filter(CDNRsources, x => (x as any).enabled),
        x =>
            loadExternalScript((x as any).src, {
                async: (x as any).async,
                ...x,
            }),
    );
    await Promise.all(promises);
}
