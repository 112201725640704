import * as ko from 'knockout';
import resources from 'nd.res';
import { getPaymentYears, getPaymentHistory } from '@pressreader/commerce-api';
import { logAndAlertError } from '@pressreader/src/shared/errors/error.handlers';
import { parseDateString } from '@pressreader/utils';
import { productDescriptions, productTypes } from '@pressreader/src/account/viewmodels/constants';
import { getBasePath } from '@pressreader/routing';

function createMutableValue(value, placeholder) {
    return placeholder
        ? { current: ko.observable(value), old: ko.observable(value ? value : ''), placeholder }
        : { current: ko.observable(value), old: ko.observable(value ? value : '') };
}

function createSelectList(items, selectedValue) {
    return {
        list: items,
        selected: createMutableValue(selectedValue),
    };
}

class PaymentHistoryViewModel {
    constructor() {
        this.isLoading = ko.observable(false);
        this.years = ko.observable({ list: [], selected: createMutableValue(0) });
        this.year = ko.observable();
        this.items = ko.observableArray();

        this._subscriptions = [];
        this._subscriptions.push(
            this.year.subscribe(year =>
                getPaymentHistory(year)
                    .then(this.mapHistoryItems)
                    .then(items => this.items(items))
                    .catch(logAndAlertError),
            ),
        );
    }

    mapHistoryItems(items) {
        return items.map(item => {
            const result = {
                id: item.id,
                productName: null,
                productDescription: null,
                formattedDate: item.formattedDate,
                formattedAmount: item.formattedAmount,
                hideBillingInfo: item.hideBillingInfo,
            };

            switch (item.productType) {
                case productTypes.subscription: {
                    result.productName = item.productName;
                    result.productDescription = productDescriptions.subscription;
                    break;
                }
                case productTypes.singleIssue: {
                    // Get the first item (backend always must send this item)
                    if (item && Array.isArray(item.orderItems) && item.orderItems[0]) {
                        const orderItem = item.orderItems[0];
                        result.productName = orderItem.publicationName;

                        const issueDateFormatted = resources.formatDate(parseDateString(orderItem.issueDate), 'v7.Client.DateFormat.Issue');
                        // Payment Date = Issue (Magazine) Date, then is single issue, else "Archive"
                        result.productDescription = orderItem.isArchive ? productDescriptions.archiveIssue : productDescriptions.singleIssue;

                        result.productDescription += ` (${issueDateFormatted})`;
                    } else {
                        result.productName = productDescriptions.singleIssue;
                        result.productDescription = item.productName;
                    }

                    break;
                }
                case productTypes.bundle: {
                    result.productName = item.productName.trim();
                    result.productDescription = item.orderItems
                        .map(orderItem => {
                            const issueDateFormatted = resources.formatDate(parseDateString(orderItem.issueDate), 'v7.Client.DateFormat.Issue');
                            let name = orderItem.publicationName;
                            if (issueDateFormatted === '') {
                                name += ` (${productDescriptions.subscription})`;
                            } else {
                                name += orderItem.isArchive ? productDescriptions.archiveIssue : productDescriptions.singleIssue;
                                name += `, (${issueDateFormatted})`;
                            }
                            return name;
                        })
                        .join(', ');
                    break;
                }
                default: {
                    throw new Error('Invalid productType');
                }
            }

            return result;
        });
    }

    openReceipt(item) {
        if (!item.hideBillingInfo) {
            window.location.assign(`${getBasePath()}Accounting/Receipt/${item.id}`);
        }
        return false;
    }

    async load() {
        this.isLoading(true);
        try {
            const years = await getPaymentYears();

            this.years(
                createSelectList(
                    years.map(m => ({
                        Key: +m,
                        Value: `${m}`,
                    })),
                    years.length > 0 ? `${years[0]}` : '',
                ),
            );

            if (years.length > 0) {
                this.year(years[0]);
            }
        } catch (err) {
            logAndAlertError(err);
        } finally {
            this.isLoading(false);
        }
    }

    dispose() {
        this._subscriptions.forEach(s => s.dispose());
        this._subscriptions.length = 0;
    }
}

export default PaymentHistoryViewModel;
