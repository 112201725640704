import { createActionCreator } from '@pressreader/appstore';
import { ILoginFlowContext, SignInDialogVersion } from '@pressreader/authentication-types';

import { AUTHENTICATION_CORE_MODULE_NAME } from '../../constants';

const prefix = `${AUTHENTICATION_CORE_MODULE_NAME}/login/`;

interface IAuthenticationRequestPayload {
    dialogVersion?: SignInDialogVersion;
    introTextResourceId?: string;
    startWith?: ILoginFlowContext;
}

interface IAuthenticationDonePayload {
    authenticationResult: boolean;
}

interface IAuthenticationErrorPayload {
    error: string;
}

export const authenticationRequestAction = createActionCreator<IAuthenticationRequestPayload>(`${prefix}authentication_request`);
export const authenticationDone = createActionCreator<IAuthenticationDonePayload>(`${prefix}authentication_done`);
export const authenticationErrorEvent = createActionCreator<IAuthenticationErrorPayload>(`${prefix}authentication_error`);
