import ndConfig from '@pressreader/src/deprecated/nd.config';
import ndUtils from '@pressreader/src/nd.utils';

import ndLogger from 'nd.logger';
import { currentAnalyticsContext } from 'analytics/context';
import { IEventInfo, IPageViewInfo, ITracker, ITransactionInfo } from 'analytics/models/models';
import { googleAnalytics } from 'analytics/trackers/googleanalytics';
import { marfeelAnalytics } from 'analytics/trackers/marfeelanalytics';
import { matherAnalytics } from 'analytics/trackers/matheranalytics';
import { tagManager } from 'analytics/trackers/tagmanager';

class Analytics implements ITracker {
    private _debug: boolean;
    private _enabled = false;
    private _loaded: Promise<boolean>;
    private trackers: ITracker[];

    constructor() {
        const params = ndUtils.getUrlParameters();
        this._debug = DEBUG && !!params['debug'];

        const filterTrackers = () => {
            // Filter the enabled trackers...
            this.trackers = this.trackers.filter(tracker => tracker.enabled());

            // tracker.any() ?
            this._enabled = this.trackers.length > 0;

            if (this._debug) {
                ndLogger.log(`se2sky.analytics - Trackers Found: ${this.trackers.map(o => o.toString()).join(', ') || 'None'}`);
            }
        };

        this._loaded = (async () => {
            // REGISTER HERE THE ANALYTICS TARGETS SUPPORTED BY SE2SKY
            this.trackers = [];
            this.trackers.push(tagManager);
            this.trackers.push(googleAnalytics);
            this.trackers.push(matherAnalytics);
            this.trackers.push(marfeelAnalytics);

            await Promise.all(this.trackers.map(m => m.loaded()));
            filterTrackers();

            if (ndConfig.get('Analytics.CustomSourceTrackingEvent', false) && (params['source'] || params['medium'])) {
                if (this._debug) {
                    ndLogger.log(`se2sky.analytics - CustomSourceTracking - source=${params['source']}, medium=${params['medium']}`);
                }
                this.customSourceTracking(params['source'], params['medium']);
            }

            return true;
        })();
    }

    toString() {
        return `Analytics`;
    }

    loaded() {
        return this._loaded;
    }

    enabled() {
        return this._enabled;
    }

    pageView(info: IPageViewInfo = {}) {
        info.context = currentAnalyticsContext.merge(info.context);
        info.title = info.title || (info.context && info.context.contentName ? info.context.contentName : '') || document.title;
        info.page = info.page || `${window.location.pathname}${window.location.search}${window.location.hash}`;

        this._loaded.then(() => {
            this.trackers.forEach(t => t.pageView(info));
        });
    }

    event(info: IEventInfo) {
        info.context = currentAnalyticsContext.merge(info.context);

        this._loaded.then(() => {
            this.trackers.forEach(t => t.event(info));
        });
    }

    transaction(info: ITransactionInfo) {
        info.context = currentAnalyticsContext.merge(info.context);

        this._loaded.then(() => {
            this.trackers.forEach(t => t.transaction(info));
        });
    }

    timing(category: string, variable: string, timeSpent: string, label: string) {
        this._loaded.then(() => {
            this.trackers.forEach(t => t.timing(category, variable, timeSpent, label));
        });
    }

    exception(description: string, fatal: boolean): void {
        this._loaded.then(() => {
            this.trackers.forEach(t => t.exception(description, fatal));
        });
    }

    customSourceTracking(source: string, medium: string): void {
        this._loaded.then(() => {
            this.trackers.forEach(t => t.customSourceTracking(source, medium));
        });
    }
}

const analytics = new Analytics();

export { analytics };
