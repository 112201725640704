import ConsentViewModel from 'gdpr/consent';

class AccountConsentLoader {
    accountConsentData: HTMLElement;

    public init(): void {
        document.addEventListener(
            'DOMContentLoaded',
            () => {
                this.accountConsentData = document.getElementById('account-consent-template-data');
                if (this.accountConsentData) {
                    this.handle();
                }
            },
            {
                once: true,
            },
        );
    }

    private async handle(): Promise<void> {
        const cookiesConsentGranted = this.accountConsentData.getAttribute('data-cookies-consent-granted').toLocaleLowerCase() === 'true';
        const promoEmailConsentGranted = this.accountConsentData.getAttribute('data-promo-email-consent-granted').toLocaleLowerCase() === 'true';
        const termsOfUseConsentGranted = this.accountConsentData.getAttribute('data-tou-consent-granted').toLocaleLowerCase() === 'true';
        const returnUrl = this.accountConsentData.getAttribute('data-return-url');
        const viewConfig = {
            returnUrl: returnUrl,
            cookiesConsentGranted: cookiesConsentGranted,
            promoEmailConsentGranted: promoEmailConsentGranted,
            termsOfUseConsentGranted: termsOfUseConsentGranted,
        };
        const accountConsentViewModel = new ConsentViewModel(viewConfig);
        const consentViewContainer = document.getElementById('consentViewContainer');

        ko.applyBindings(accountConsentViewModel, consentViewContainer);
    }
}

const accountConsentLoader = new AccountConsentLoader();

export { accountConsentLoader };
