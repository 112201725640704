import { ISessionInfoDto } from '@pressreader/analytics-api';
import { ISessionInfo } from '@pressreader/analytics-types';

function mapSessionInfoToDto(info: ISessionInfo): ISessionInfoDto {
    return {
        id: info.id,
        referer: info.referrer,
    };
}

export { mapSessionInfoToDto };
