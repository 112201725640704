import { localizedString } from '@pressreader/resources';

const printContentClass = 'print-content';

const previewEvents = ['mousemove', 'touchstart'];

function removePreviewElement() {
    const elements = document.getElementsByClassName(printContentClass);
    for (let i = 0; i < elements.length; i++) {
        elements[i].remove();
    }
}

function addPreviewHandler() {
    window.removeEventListener('beforeprint', beforePrintHandler);
    previewEvents.forEach(e => window.addEventListener(e, previewHandler));
}

function previewHandler() {
    window.addEventListener('beforeprint', beforePrintHandler);
    previewEvents.forEach(e => window.removeEventListener(e, previewHandler));
    removePreviewElement();
}

function beforePrintHandler() {
    removePreviewElement();
    const msg = localizedString('Errors.BrowserPrintingNotSupported', 'Browser Printing Not Supported');
    const previewElement = document.createElement('div');
    previewElement.className = printContentClass;
    previewElement.innerHTML = `<div id="NoPrintContent">${msg}</div>`;
    document.body.appendChild(previewElement);
}

function init() {
    window.addEventListener('beforeprint', beforePrintHandler);
}

export { init, printContentClass, addPreviewHandler, removePreviewElement };
