/* eslint-env es6 */

/**
 * Low-level catalog provider.
 * Represents server API on a client side
 */

import { isObject, isDate, extend, padStart } from 'lodash';

import { isNullOrWhitespace } from '@pressreader/utils';

import ndServiceApi from 'nd.services.api';

const multiValueSeparator = ',';
const defaultPageSize = 100;

function packBoolFilter(filters, name, value) {
    if (value === true) {
        filters[name] = '1';
    } else if (value === false) {
        filters[name] = '0';
    }
}

/**
 * Packs filters into short form to be passed over url
 *
 * @param {Object} filters - original filters object
 * @returns {Object} packed object
 */
function packFilters(filters) {
    const f = {};
    if (!isNullOrWhitespace(filters.mainCid)) {
        f.main = filters.mainCid;
    }
    if (!isNullOrWhitespace(filters.text)) {
        f.q = filters.text;
    }
    if (Array.isArray(filters.cids)) {
        f.cid = filters.cids.join(multiValueSeparator);
    }
    if (Array.isArray(filters.hasCategories)) {
        f.has = filters.hasCategories.join(multiValueSeparator);
    }
    if (Array.isArray(filters.excCategories)) {
        f.exc = filters.excCategories.join(multiValueSeparator);
    }
    if (Array.isArray(filters.inCategories) && filters.inCategories.length > 0) {
        // new model - array of arrays
        if (Array.isArray(filters.inCategories[0])) {
            f.in = filters.inCategories.map(values => values.join(multiValueSeparator));
        } else {
            f.in = filters.inCategories.join(multiValueSeparator);
        }
    }
    packBoolFilter(f, 'supplement', filters.isSupplement);
    packBoolFilter(f, 'free', filters.isFree);
    packBoolFilter(f, 'cre', filters.cre);

    return f;
}

// todo: this function pretends to be shared, find a better place
/**
 * Formats date for request to the server
 */
function formatDateForServer(date) {
    if (!isDate(date)) {
        throw new TypeError('Date object expected');
    }
    const yyyy = date.getFullYear();
    const mm = padStart(date.getMonth() + 1, 2, '0');
    const dd = padStart(date.getDate(), 2, '0');
    return `${yyyy}-${mm}-${dd}`;
}

class CatalogProvider {
    /**
     * Constructs new provider
     * @param {String} basePath - path to the catalog API controller
     */
    constructor(basePath) {
        if (!basePath) {
            throw new Error('basePath parameter required');
        }
        this._basePath = basePath;
    }

    /**
     * Gets catalog metadata.
     *
     * @returns {Object} metadata
     */
    getMetadata() {
        return ndServiceApi.get(this._basePath, 'metadata');
    }

    /**
     * Gets single publication by CID.
     *
     * @param {String} cid - publication CID
     * @returns {Object} publication data
     */
    getPublication(cid) {
        return ndServiceApi.get(this._basePath, `publications/${cid}`);
    }

    /**
     * Gets publication owner.
     *
     * @param {String} cid - publication CID
     * @returns {Object} information about owner
     */
    getPublicationOwner(cid) {
        return ndServiceApi.get(this._basePath, `publications/${cid}/owner`);
    }

    /**
     * Gets a single issue of the publication by CID and issue date.
     * If issue date was not specified returns the latest issue of the publication.
     *
     * @param {String} cid - publication CID
     * @param {Date} [issueDate] - issue date
     * @returns {Promise.<Object>} issue data
     */
    getIssue(cid, issueDate) {
        return ndServiceApi.get(this._basePath, `publications/${cid}/issues/${issueDate ? formatDateForServer(issueDate) : 'latest'}`);
    }

    /**
     * @typedef {Object} Filters
     * @property {String} mainCid - CID of publication to find,
     *                              then other filters will be applied to the supplements of found publication.
     *                              Main publication will be included into result set at fist position
     *                              regardless of other filters
     * @property {Array.<String>} cids - specific publications' CIDs
     * @property {Array.<Number>} hasCategories - Id of categories that publication should belong to (All)
     * @property {Array.<Number> | Array.<Array.<Number>>} inCategories - Id of categories that publication should belong to (Any)
     *                                                                    In case if array contains arrays will be provided search
     *                                                                    for (Any) in sub arrays and (All) for top array.
     *                                                                    For example: [[a,b], [c,d,e]] => (a|b) & (c|d|e)
     * @property {Boolean} isFree - if true find free publications only, if false - payable only
     * @property {Boolean} isSupplement - if true find supplements only, if false - non-supplements
     * @property {Boolean} cre - if true filters publications with regional awareness
     * @property {String} text - text for full text search
     */

    /**
     * Finds publication by specified criteria
     *
     * Example:
     * findPublications({ filters: { cids: ['9ac3', 'ta59'] }, orderBy: { name: 'name', order: 'desc' }, take: 5 });
     *
     * @param {Object} query - search query
     * @param {Filters} [filters] - object {@link Filters}
     * @param {Object} [query.orderBy] - sort order
     * @param {String} [query.orderBy.name] - fields to sort by - 'name', 'rank', 'latestIssueDate'
     * @param {String} [query.orderBy.order] - sort order 'asc' | 'desc', @see shared/data/sorting
     * @param {Number} [query.offset] - skip number of items
     * @param {Number} [query.limit] - take number of items
     * @returns {Promise.<Object>} promise of paged collection
     */
    findPublications(query) {
        const qs = {
            offset: query.offset,
            limit: query.limit || defaultPageSize,
        };

        if (query.orderBy) {
            qs.orderBy = `${query.orderBy.name} ${query.orderBy.order}`;
        }

        if (isObject(query.filters)) {
            extend(qs, packFilters(query.filters));
        }

        return ndServiceApi.get(this._basePath, 'publications', qs);
    }

    /**
     * Gets category group by name.
     *
     * Used to get lists of categories, like "Countries".
     *
     * @param {String} name - name of group
     * @returns {Promise.<Object>} category group
     */
    getCategoryGroup(name) {
        return ndServiceApi.get(this._basePath, `categorygroups/${name}`);
    }

    /**
     * Resolves navigation path components.
     *
     * Splits path into components (by "/"), then for each component tries to find category or publication
     * that has slug matched to the component.
     *
     * Returns array of resolved catalog objects in the order of components in path.
     * Each resolved object headed with itemType attribute which indicate type of resolved item - "Category" or "Publication"
     *
     * @param {String} pathUri - navigation path expressed as string, like "canada/ontario/the-province"
     * @param {String} prefer - specifies catalog item type to prefer ('Category'|'Publication')
     *                                    when both Category and Publication has the same slug
     * @returns {Promise.<Array<Object>>} promise of resolved items
     */
    resolveNavigationPath(pathUri, prefer) {
        if (isNullOrWhitespace(pathUri)) {
            throw new Error('pathUri required');
        }

        return ndServiceApi.get(this._basePath, 'navigationpath', { pathUri, prefer });
    }

    /**
     * Get available locales.
     *
     * Locale is a combination of country and language
     * that has count publications filtered with certain criteria (smart == true) > pre-configured value (10)
     *
     * @returns {Promise.<Object>} promise fulfilled with available Locales object in 3rd normal form
     */
    getLocales() {
        return ndServiceApi.get(this._basePath, 'locales');
    }
}

export default CatalogProvider;
