import { extend } from "lodash";

    "user strict";

    // ReSharper disable InconsistentNaming
    var _configData = null,
        _version = 0;

    var cache = {};

    function defaultConfigData() {
        return {
            config: {},
        };
    }

    function configData(data) {
        if (!arguments.length)
            return _configData;
        _configData = data;
        if (data && !_configData.config)
            _configData.config = {};

        cache = {};

        return ++_version;
    }

    function getConfig() {
        return _configData ? _configData.config : {};
    }

    function getItem(path, defVal) {
        var fromConfig = path in cache
            ? cache[path]
            : cache[path] = get(getConfig(), path);

        return fromConfig === undefined ? defVal : fromConfig;
    }

    // unfortunately lodash.get does not work well with 'complex.properties.values' cases,
    // so we have to introduce our own
    /**
     * Gets value at complex object by path.
     *
     * Please note, that only one scenario of complex key supported:
     * when it's the last one in path.
     *
     * Examples
     * Supported:
     *  *  ['features']['landing.view'];
     *  *  ['features']['landing.view.items-per-page'];
     *
     * Not supported:
     *  * ['features.landing']['view']
     *  * ['features']['landing.view']['sub-view']
     *
     * @param node - root node
     * @param path - path to value
     * @returns {*}
     */
    function get(node, path) {
        if (node === undefined || node === null) {
            return node;
        }

        // Fix path for case-insensitive search
        const fixCase = (name) =>
            Object.keys(node).find(e => e.toLowerCase() === name.toLowerCase())
                || name;    // In case the path contains "." or when it don't really exists in the config...

        path = fixCase(path);

        if (path in node) {
            return node[path];
        }

        const dotPos = path.indexOf('.');
        if (dotPos !== -1) {
            let nextSegment = path.substring(0, dotPos);
            nextSegment = fixCase(nextSegment);
            if (nextSegment in node) {
                return get(node[nextSegment], path.substring(dotPos + 1));
            }
        }

        return undefined;
    }


    function extendConfig(myconfig) {
        if (!_configData)
            _configData = defaultConfigData();
        for (var entry in myconfig) {
            var item = _configData.config[entry];
            if (!item) {
                _configData.config[entry] = myconfig[entry];
                continue;
            }
            extend(item, myconfig[entry]);
        }

        cache = {};
        ++_version;
    }

    function version() {
        return _version;
    }

    export default {
        get: getItem,
        extend: extendConfig,
        configData,
        version,
        getConfig,
    };
