/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-console */
import config from '@pressreader/src/deprecated/nd.config';
import userInfo from '@pressreader/src/nd.userinfo';

import user from 'nd.user';
import { IEventInfo, IPageViewInfo, ITracker, ITransactionInfo } from 'analytics/models/models';

function initMatherAnalytics(w, d, c) {
    w._snaq = w._snaq || [];
    w._snaq.push(['setCollectorUrl', c.CollectorUrl]);
    w._snaq.push(['setAppId', c.AppId]);
    w._snaq.push(['setCustomerId', c.CustomerId]);
    w._snaq.push(['setMarket', c.Market]);
    w._snaq.push(['setCookieDomain', c.CookieDomain]);

    if (c.ActivityTracking.Enabled) {
        w._snaq.push(['enableActivityTracking', c.ActivityTracking.MinimumVisitLength, c.ActivityTracking.HeartBeat]);
    }

    // eslint-disable-next-line prefer-spread
    const a = [].concat.apply([], w._snaq);
    const src = a[a.indexOf('setCustomerId') + 1] + '/' + a[a.indexOf('setMarket') + 1] + '/sp.js';
    const sp = d.createElement('script');
    sp.type = 'text/javascript';
    sp.async = true;
    sp.defer = true;
    sp._mather_tag = d.scripts[d.scripts.length - 1];
    sp._mather_sp = sp.uniqueID;
    sp.src = ('https:' === d.location.protocol ? 'https' : 'http') + '://js.matheranalytics.com/s/' + src;
    const s = d.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(sp, null);
}

declare let window: any;

class MatherAnalytics implements ITracker {
    private _debug: boolean;
    private _enabled = false;
    private _loaded: Promise<boolean>;

    constructor() {
        this._loaded = (async () => {
            await config.loaded();

            const cfg = config.get('analytics.Mather');

            this._debug = !!cfg.Debug;
            if (this._debug) {
                console.log('matheranalytics - cfg ', cfg);
            }

            if (!cfg || !cfg.Enabled) {
                return false;
            }

            this._enabled = true;

            if (!window._snaq) {
                initMatherAnalytics(window, document, cfg);
            }

            return true;
        })();
    }

    toString() {
        return `MatherAnalytics`;
    }

    loaded() {
        return this._loaded;
    }

    enabled() {
        return this._enabled;
    }

    pageView(info: IPageViewInfo = {}) {
        if (!this._enabled) {
            if (this._debug) {
                console.log('NOT ENABLED pageView', info);
            }
            return;
        }

        const fields: any = {
            page: info.page ? info.page : `${window.location.pathname}${window.location.search}${window.location.hash}`,
        };
        if (info.title) {
            fields.title = info.title;
        }
        if (info.page) {
            fields.page = info.page;
        }

        if (user.isLogin()) window._snaq.push(['setUserId', userInfo.getProfileId]);
        else window._snaq.push(['setUserId', '']);

        if (fields.title) window._snaq.push(['trackPageView', fields.title]);
        else window._snaq.push(['trackPageView']);
    }

    event(info: IEventInfo) {}

    transaction(transaction: ITransactionInfo) {}

    exception(description: string, fatal: boolean): void {}

    timing(category: string, variable: string, timeSpent: string, label: string) {}

    customSourceTracking(source: string, medium: string): void {}
}

const matherAnalytics = new MatherAnalytics();

export { matherAnalytics };
