import memoize from 'lodash/memoize';

import { OperationStatus, select, subscribe } from '@pressreader/appstore';
import { dateFormat } from '@pressreader/utils';

import { selectResources } from './store/selectors';
import {
    getLocalizedDate,
    getLocalizedIssueDate,
    getLocalizedMonthFullName,
    getLocalizedMonthShortName,
    getLocalizedSafeRTLString,
    getLocalizedString,
    getLocalizedStringWithDefault,
    getLocalizedStringWithFallback,
    getLocalizedWeekDayFullName,
    getLocalizedWeekDayMinName,
    getLocalizedWeekDayShortName,
} from './common';
import { getResources } from './services';
import { selectStatus } from './store';

const customDateFormats = {
    MMM: getMonthShortName,
    MMMM: getMonthFullName,
    ddd: getWeekDayShortName,
    dddd: getWeekDayFullName,
};

const cachedDateFormat = memoize((format: string) => dateFormat.createDateFormat(format, customDateFormats));

function localizedString(resourceId: string, ...values: (string | { toString(): string })[]) {
    const resources = select(selectResources);
    return getLocalizedString(resources)(resourceId, ...values);
}

function localizedStringWithFallback(resourceId: string, fallbackResourceId: string, ...values: (string | { toString(): string })[]) {
    const resources = select(selectResources);
    return getLocalizedStringWithFallback(resources)(resourceId, fallbackResourceId, ...values);
}

function localizedStringWithDefault(resourceId: string, defaultValue: string, ...values: (string | { toString(): string })[]) {
    const resources = select(selectResources);
    return getLocalizedStringWithDefault(resources)(resourceId, defaultValue, ...values);
}

async function localizedStringForLocale(resourceId: string, locale: string, ...values: (string | { toString(): string })[]) {
    const { resources } = await getResources({ resourceNames: [resourceId], locale, includeStatic: false });
    return getLocalizedString(resources)(resourceId, ...values);
}

/**
 * @example 'dddd, dd-MM-yyyy'
 *          'ddd, dd-MM-yyyy'
 *          'dd-MM-yyyy'
 *          'd-MMM-yy'
 *          'd-MMMM-y'
 *          'h:mm TT'
 */
function localizedDate(date: Date, resourceId: string) {
    const resources = select(selectResources);
    return getLocalizedDate(resources)(date, resourceId);
}

/**
 * Uses `v7.Client.DateFormat.Issue` for formatting.
 * @param short - Set true to only format date and mounth if the year is current year
 */
function localizedIssueDate(date: Date, short?: boolean) {
    const resources = select(selectResources);
    return getLocalizedIssueDate(resources)(date, short);
}

function formatDate(date: Date, format: string) {
    return cachedDateFormat(format)(date);
}

function formatMultipleValues(resname: string, ...values: { name: string; value: string | { toString(): string } }[]) {
    // to use ResourceManager.format2("resname",{name:"cpage",value:1},{name:"pages",value:99})
    if (!arguments.length) {
        return '';
    }
    let val = localizedString(resname);
    if (val?.length) {
        // replace params
        // ie. "Page: from {firstPage} to {lastPage}" => "Page: from 1 to 10"
        for (const item of values) {
            if (item?.name) {
                val = val.replace(new RegExp('(\\{' + item.name + '})', 'g'), item.value?.toString() || '');
            }
        }
    }
    return val || '';
}

/**
 * Check if there is any rtl symbol in the input string and wrap with unicode bidirectional embedding symbols if necessary.
 * @param id - resource identifier.
 * @param defaultValue - the value to return if resource does not exist.
 */
function getSafeRTLString(id: string, defaultValue?: string) {
    const resources = select(selectResources);
    return getLocalizedSafeRTLString(resources)(id, defaultValue);
}

function getMonthShortName(month: number) {
    const resources = select(selectResources);
    return getLocalizedMonthShortName(resources)(month);
}

function getMonthFullName(month: number) {
    const resources = select(selectResources);
    return getLocalizedMonthFullName(resources)(month);
}

function getWeekDayFullName(weekDay: number) {
    const resources = select(selectResources);
    return getLocalizedWeekDayFullName(resources)(weekDay);
}

function getWeekDayShortName(weekDay: number) {
    const resources = select(selectResources);
    return getLocalizedWeekDayShortName(resources)(weekDay);
}

function getWeekDayMinName(weekDay: number) {
    const resources = select(selectResources);
    return getLocalizedWeekDayMinName(resources)(weekDay);
}

async function loaded() {
    const status = select(selectStatus);
    if (status === OperationStatus.Done) {
        return Promise.resolve();
    }
    if (status === OperationStatus.Failed) {
        return Promise.reject(new Error('Load config failed.'));
    }
    return new Promise<void>((resolve, reject) => {
        // We have to check this again since Promise body is called async and store's content could've changed.
        const status = select(selectStatus);
        if (status === OperationStatus.Done) {
            resolve();
        } else if (status === OperationStatus.Failed) {
            reject(new Error('Load config failed.'));
        } else {
            const unsubscribe = subscribe(selectStatus, s => {
                if (s === OperationStatus.Done) {
                    resolve();
                } else if (s === OperationStatus.Failed) {
                    reject(new Error('Load config failed.'));
                } else {
                    return;
                }
                unsubscribe();
            });
        }
    });
}

function isLoaded() {
    const status = select(selectStatus);
    return status === OperationStatus.Done;
}

export { customDateFormats };
export {
    localizedString,
    localizedStringWithFallback,
    localizedStringWithDefault,
    localizedStringForLocale,
    localizedDate,
    localizedIssueDate,
    getMonthShortName,
    getMonthFullName,
    getWeekDayFullName,
    getWeekDayShortName,
    getWeekDayMinName,
    formatDate,
    formatMultipleValues,
    getSafeRTLString,
};
export { loaded, isLoaded };
