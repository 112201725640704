import "nd.core";

    if (!window.console) {
        window.console = {
            log: function (message) {
                setTimeout(function () {
                    throw new Error(message);
                }, 0);
            },
            error: function (error) {
                this.log(error);
            }
        };
    }

    export default {
        log: function(msg) {
            console && console.log(msg);
        },
        error: function(msg) {
            if(console) {
                console.error && console.error(msg);
                !console.error && console.log(msg);
            }
        },
        trace: function() {
        }
    };

