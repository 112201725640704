import { removeFN } from '@pressreader/utils';

import { Action, HistoryState, Location, State, To, TransitionEvent } from '../types';
import { createHref, createKey, createSubscribers, parsePath } from '../utils';

const subscribers = createSubscribers<TransitionEvent>();
const globalHistory = window.history;
let action: Action = 'POP';

window.addEventListener('popstate', pop);
globalHistory.replaceState({ state: {}, key: createKey() }, '');

function pushState(to: To, state: State = {}) {
    const nextAction: Action = 'PUSH';
    const nextLocation = getNextLocation(to, state);
    const [historyState, url] = getHistoryStateAndUrl(nextLocation);

    globalHistory.pushState(historyState, '', url);
    transition(nextAction);
}

function replace(to: To, state: State = {}, action: Action) {
    const nextLocation = getNextLocation(to, state);
    const [historyState, url] = getHistoryStateAndUrl(nextLocation);

    globalHistory.replaceState(historyState, '', url);
    transition(action);
}

function replaceState(to: To, state: State = {}) {
    const nextAction: Action = 'REPLACE';

    replace(to, state, nextAction);
}

function replaceStateWithReload(to: To, state: State = {}) {
    const nextAction: Action = 'REPLACE_RELOAD';

    replace(to, state, nextAction);
}

function historyGo(delta: number) {
    globalHistory.go(delta);
}

function historyBack() {
    globalHistory.back();
}

function historyForward() {
    globalHistory.forward();
}

function subscribeToTransition(event: TransitionEvent) {
    subscribers.push(event);
}

function unsubscribeFromTransition(event: TransitionEvent) {
    subscribers.remove(event);
}

function getLocation(): Location {
    const { pathname, search, hash, href } = window.location;
    const historyState = globalHistory.state || {};
    return {
        pathname,
        search,
        hash,
        href,
        state: historyState.state || null,
        key: historyState.key || 'default',
    };
}

function pop() {
    const nextAction: Action = 'POP';

    transition(nextAction);
}

function transition(nextAction: Action) {
    const location = getLocation();
    action = nextAction;
    subscribers.call({ action, location });
}

function getNextLocation(to: To, state: State = {}): Location {
    const { pathname, href } = window.location;

    return {
        pathname,
        href,
        hash: '',
        search: '',
        ...(typeof to === 'string' ? parsePath(to) : to),
        state,
        key: createKey(),
    };
}

function getHistoryStateAndUrl(nextLocation: Location): [HistoryState, string] {
    return [
        {
            state: removeFN(nextLocation.state),
            key: nextLocation.key,
        },
        createHref(nextLocation),
    ];
}

export {
    action,
    getLocation,
    pushState,
    replaceState,
    replaceStateWithReload,
    historyGo,
    historyBack,
    historyForward,
    subscribeToTransition,
    unsubscribeFromTransition,
};
