import Uri from 'jsuri';

import { ISize } from '@pressreader/geometry';
import { getImageUrl } from '@pressreader/images';
import { httpDelete, httpGet, httpPost } from '@pressreader/services';
import { getFirstLettersOfTwoWords, ImageFitMode } from '@pressreader/utils';

const serviceUrl = 'v1/accounts/current/avatar';
const FIT_MODE: ImageFitMode = 'crop';
const QUALITY = 80;

const avatarSizes: Record<'small' | 'normal' | 'medium' | 'large' | 'huge' | 'giant' | 'ginormous', ISize> = {
    small: {
        width: 20,
        height: 20,
    },
    normal: {
        width: 22,
        height: 22,
    },
    medium: {
        width: 28,
        height: 28,
    },
    large: {
        width: 44,
        height: 44,
    },
    huge: {
        width: 48,
        height: 48,
    },
    giant: {
        width: 120,
        height: 120,
    },
    ginormous: {
        width: 160,
        height: 160,
    },
};

function getAvatarHtml({
    nickname,
    imageId,
    size,
    fallbackAvatarUrl,
    style,
}: {
    nickname: string;
    imageId: string;
    size?: ISize;
    fallbackAvatarUrl: string;
    style?: string;
}) {
    const imageUrl = imageId ? getAvatarUrl(imageId, size) : fallbackAvatarUrl ? prepareFallbackUrl(fallbackAvatarUrl, size) : null;

    if (imageUrl) {
        const { widthAttr = '', heightAttr = '' } = size ? { widthAttr: `width="${size.width}"`, heightAttr: `height="${size.height}"` } : {};
        const styleAttr = style ? `style="${style}"` : '';

        return `<img src="${imageUrl}" ${widthAttr} ${heightAttr} ${styleAttr} alt="${nickname || 'Avatar'}">`;
    }

    if (nickname) {
        return `<i>${getFirstLettersOfTwoWords(nickname)}</i>`;
    }

    return '';
}

async function getAvatarImageId() {
    const { imageId } = await httpGet<{ imageId: string }>(serviceUrl);
    return imageId;
}

async function updateAvatar(imageId: string) {
    return await httpPost<{ imageId: string }>(serviceUrl, '', JSON.stringify({ imageId }), { contentType: 'application/json; charset=utf-8' });
}

function deleteAvatar() {
    return httpDelete<void>(serviceUrl);
}

function prepareFallbackUrl(imageUrl: string, size?: ISize) {
    if (!imageUrl) {
        return imageUrl;
    }

    if (imageUrl.substring(0, 4) !== 'http') {
        imageUrl = window.basePath + imageUrl;
    }

    const uri = new Uri(imageUrl);
    if (size?.width && size.width > 0) {
        uri.addQueryParam('width', size.width);
    }
    if (size?.height && size.height > 0) {
        uri.addQueryParam('height', size.height);
    }
    uri.addQueryParam('fitMode', FIT_MODE);
    uri.addQueryParam('quality', QUALITY);

    return uri.toString();
}

function getAvatarUrl(imageId: string, size?: ISize) {
    return getImageUrl(imageId, { ...(size ?? {}), fitMode: FIT_MODE, quality: QUALITY });
}

export { avatarSizes, getAvatarImageId, getAvatarUrl, prepareFallbackUrl, getAvatarHtml, updateAvatar, deleteAvatar };
