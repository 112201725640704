import { Subject } from 'rxjs';

interface LogEvent {
    error: string;
}

export const errorLogSubject = new Subject<LogEvent>();

export function publishErrorEvent(error: string): void {
    errorLogSubject.next({ error });
}

/**
 * Use only for logger service
 */
export const errorLogObservable = errorLogSubject.asObservable();
