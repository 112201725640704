/* eslint-env es6 */

import * as ko from 'knockout';
import { isFunction } from 'lodash';
import ClickEventStream from 'nd.eventstream.click';

ko.bindingHandlers.uploadFile = {
    init(element, valueAccessor /* , allBindingsAccessor, data, bindingContext */) {
        if (!ko.isObservable(valueAccessor())) {
            throw new Error('Binding requires ko.observable');
        }

        const subscription = ClickEventStream.create(element)
            // input file selection event not firing upon selecting the same file
            .subscribe(() => {
                element.value = '';
            });

        const changeEventHandler = () => {
            const files = element.files;
            const value = valueAccessor();
            if (ko.isObservable(value) && files.length) {
                value(files);
            }
        };

        element.addEventListener('change', changeEventHandler);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            subscription.unsubscribe();
            element.removeEventListener('change', changeEventHandler);
        });
    },

    update(element, valueAccessor, allBindingsAccessor /* , data, bindingContext */) {
        const accessor = valueAccessor();
        const files = ko.unwrap(accessor);
        const filesHandler = allBindingsAccessor.get('filesHandler');

        if (files && isFunction(filesHandler)) {
            filesHandler(files);
        }
    },
};
