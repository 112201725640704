import * as $ from 'jquery';
import { routingManager } from '@pressreader/routing';
import $ndConfig from 'deprecated/nd.config';
import ndLoading from 'shell/loading.indicator';
import { eventbus } from 'eventbus/eventbus';
import { ActiveViewChangedEvent } from 'viewer/events/events';
import 'viewer/events/dispatcher';
import { Deferred } from 'promise/deferred';
import { ViewName } from '@pressreader/navigation-types';
import { setViewer } from '@pressreader/navigation-core';

if (!$.nd.viewer) $.nd.viewer = {};

var views = [],
    activeView,
    _skipAnimation,
    changingView = Promise.resolve();

// public api
var api = {
    getActiveView: function () {
        var name = api.getActiveViewName();
        return name ? views[name] : null;
    },
    getActiveViewName: function () {
        return getViewName(activeView);
    },
    changeActiveView: function (viewName, cfg /*optional*/) {
        return changeActiveView(views[viewName], cfg);
    },
    registerView: function (viewName, viewImpl, panel) {
        if (viewImpl.getNavigationHistoryConfig) {
            /* TODO: cross-reference, navHistory<->viewer.views */
            $.nd.navHistory.registerView(viewName, viewImpl.getNavigationHistoryConfig());
        }
        if (viewImpl.getTopBarConfig && $.nd.viewer.topBar) {
            $.nd.viewer.topBar.registerView(viewName, viewImpl.getTopBarConfig());
        }
        if (viewImpl.registerRoutes) {
            viewImpl.registerRoutes(routingManager);
        }
        views[viewName] = viewImpl;
        viewImpl.__viewName = viewName;
        if (panel) {
            viewImpl.__viewPanel = panel;
        }
        return this;
    },
    activeViewChanging: function (data, fn) {
        $.nd.event.bind(api, 'activeViewChanging', data, fn);
    },
    activeViewChanged: function (data, fn) {
        $.nd.event.bind(api, 'activeViewChanged', data, fn);
    },
    findView: function (viewName) {
        return views[viewName];
    },
    tryChangeActiveView: tryChangeActiveView,
    showError: function (config) {
        return api.tryChangeActiveView($.extend(config, { viewName: ViewName.Error }));
    },
};

function getViewName(view) {
    return view ? view.__viewName : null;
}

function tryChangeActiveView(config) {
    var view = config && config.viewName && views[config.viewName.toLowerCase()];
    if (view) {
        return changeActiveView(view, config).catch(function (error) {
            /* make sure view that failed to render
             * will not send any additional requests
             * and render loader (nd.loader.show) */
            disableView(view);
            throw error || new Error('Cannot render view ' + config.viewName);
        });
    }
    var errorMessage = config && config.viewName ? 'Not supported view ' + config.viewName : 'View is not specified';
    return Promise.reject(new Error(errorMessage));
}

/* The method is actually a queue of 'changeActiveView' methods.
 * It waits for the last 'change' action to complete, then starts changing the view. */
function changeActiveView(view, cfg) {
    var df = new Deferred();
    cfg = cfg || {};

    changingView.finally(function () {
        if (!view) {
            ndLoading.hide();
            df.reject();
        } else if (activeView === view) {
            var reset;
            if ($.isFunction(view.reset)) {
                reset = view.reset(cfg);
            }

            (reset || Promise.resolve())
                .then(function () {
                    changeViewFinalStep(activeView, view).then(df.resolve).catch(df.reject);
                })
                .catch(df.reject);
        } else {
            changeViewStep1(view, cfg).then(df.resolve).catch(df.reject);
        }
    });

    changingView = df.promise();
    return changingView;
}

function disableView(view) {
    if (view && $.isFunction(view.disable)) {
        view.disable();
    }
}

/* Initial step of the whole view switch process. */
function changeViewStep1(view, cfg) {
    var df = new Deferred();

    disableView(activeView);

    var currentView = activeView,
        pendingView = view,
        pendingViewCfg = cfg;

    /* There are a lot of calls of:
     * $.nd.viewer.views.getActiveViewName()
     * during the rendering.
     * We have to set activeView to be able to process this requests correctly.
     * activeView will be switched back (to the prev view) if there was and error. */
    activeView = pendingView;
    df.then(
        /* ignore success view switch */
        $.noop,
        /* set activeView back if there were errors */
        function () {
            activeView = currentView;
        },
    );

    $.nd.event.trigger('activeViewChanging', api, getViewName(pendingView));

    if (!currentView) {
        pendingView.__viewPanel.show().elm().keepEmptyTransformForElm(false).translateTop(0);

        pendingView
            .showView(pendingViewCfg)
            .then(function () {
                changeViewFinalStep(currentView, pendingView).then(df.resolve).catch(df.reject);
            })
            .catch(df.reject);
    } else {
        currentView.__viewPanel.visible(false);

        // show loading screen and before start rendering
        // the rendering could block browser for long time
        ndLoading.show();

        // execute async to show loading panel
        changeViewStep2(currentView, pendingView, pendingViewCfg).then(df.resolve).catch(df.reject);
    }

    return df.promise();
}

function skipAnimation() {
    return (_skipAnimation = _skipAnimation || (_skipAnimation = $ndConfig.get('ui.Views.noChangeViewAnimation')));
}

/* Show view, wait for it to render. */
function changeViewStep2(currentView, pendingView, cfg) {
    var rendered = new Deferred();
    cfg.callback = function () {
        var skip = skipAnimation() || cfg.skipAnimation;
        var result = (skip ? changeViewStep3AnimationComplete : changeViewStep3)(currentView, pendingView);
        return result.then(rendered.resolve).catch(rendered.reject);
    };

    var classNames = getRootClassNames(cfg.viewName);
    // apply the root style in order to avoid visible effects during view appearing
    $(pendingView.__viewPanel.elm()).addClass(classNames);
    pendingView.__viewPanel.show().elm().translateTop('-10000px');

    return Promise.all([pendingView.showView(cfg), rendered.promise()]);
}

/* Animate pending view */
function changeViewStep3(currentView, pendingView) {
    var df = new Deferred();
    ndLoading.hide();

    var oldPanel = currentView.__viewPanel;
    var newPanel = pendingView.__viewPanel;
    var animatingPanel;

    var dy = oldPanel.height();
    if (currentView !== pendingView) {
        dy *= -1;
        newPanel.elm().translateTop(-dy).css('zIndex', 1);
        animatingPanel = newPanel;
    } else {
        oldPanel.elm().css('zIndex', 1);
        newPanel.elm().translateTop(0).css('zIndex', 0);
        animatingPanel = oldPanel;
    }

    var duration = 500,
        easing = $.nd.easing.nd1;
    var executed = false;
    animatingPanel.elm().animate({ translateTop: '+=' + dy + 'px' }, duration, easing, function () {
        if (!executed) {
            // ensure executed once
            executed = true;
            changeViewStep3AnimationComplete(currentView, pendingView).then(df.resolve).catch(df.reject);
        }
    });
    return df.promise();
}

/* Complete animation of a pendingView, hide active view. */
function changeViewStep3AnimationComplete(currentView, pendingView) {
    var oldPanel = currentView.__viewPanel,
        newPanel = pendingView.__viewPanel;

    currentView.hide();
    oldPanel.visible(false);

    newPanel.elm().keepEmptyTransformForElm(false).translateTop(0).css('zIndex', 1);

    oldPanel.elm().keepEmptyTransformForElm(false).translateTop(0).css('zIndex', 0);

    return changeViewFinalStep(currentView, pendingView);
}

/* Promote pendingView to activeView */
function changeViewFinalStep(currentView, pendingView) {
    applyRootClassNames(getViewName(pendingView));

    ndLoading.hide();

    if (currentView !== pendingView) {
        var viewName = getViewName(pendingView);
        $.nd.event.trigger('activeViewChanged', api, viewName);

        if (viewName !== ViewName.EmptyView) {
            eventbus.send(new ActiveViewChangedEvent({ viewName: viewName }));
        }
    }

    return Promise.resolve();
}

var currentClassNames;
function applyRootClassNames(name) {
    var classNames = getRootClassNames(name);
    if (classNames !== currentClassNames) {
        var root = $(document.documentElement || document.body);
        if (currentClassNames) {
            root.removeClass(currentClassNames);
        }
        root.addClass(classNames);

        currentClassNames = classNames;
    }
}

var map = (function () {
    var v = {};
    v[ViewName.TopNews] = 'l-textview';
    v[ViewName.PagesView] = 'l-pageview';
    v[ViewName.TextView] = 'l-textview';
    v[ViewName.FTSArticles] = 'l-textview';
    v[ViewName.Bookmarks] = 'l-textview';
    v[ViewName.ManageBookmarks] = 'l-serv';
    v[ViewName.EmailAlerts] = 'l-serv';
    //v[ViewName.Votes] = "votes";
    //v[ViewName.Home] = "home";
    v[ViewName.Radio] = 'l-radio';
    v[ViewName.Monitors] = 'l-textview';
    //v[ViewName.Profile] = "profile";
    v[ViewName.Similar] = 'l-textview';
    //v[ViewName.EmptyView] = "empty";
    v[ViewName.External] = 'l-textview';
    v[ViewName.Help] = 'l-help';
    v[ViewName.HotSpotsHub] = 'l-hotspots l-hotspots-home';
    v[ViewName.OpinionsHub] = 'l-opinions l-opinions-home';
    v[ViewName.StoreHub] = 'l-store';
    v[ViewName.Post] = 'l-post has-js';
    v[ViewName.Purchase] = 'l-purchase';
    v[ViewName.IssueDetails] = 'l-store';
    v[ViewName.LatestIssues] = 'l-store';
    return v;
})();

function getRootClassNames(name) {
    return map[name] || '';
}

$.nd.viewer.views = api;

export const getActiveViewName = api.getActiveViewName;

setViewer(api);

export default api;
