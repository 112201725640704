// eslint-env es6

import { isString, isObject, isFunction } from 'lodash';

const implementations = {};

/**
 * @module Sign In implementations registry
 */

/**
 * Returns sign in implementation for the system
 *
 * @param {String} system - external system name, like "facebook"
 * @returns {Object} signin service
 */
function getImplementation(system) {
    if (!isString(system)) {
        throw new TypeError('system name is not specified');
    }

    const implementation = implementations[system];

    if (implementation === undefined) {
        throw new TypeError(`SignIn implementation of ${system} system not registered`);
    }

    return implementation;
}

/**
 * Register specific implementation of auth provider
 *
 * @param {String} system - system name, e.g. "facebook"
 * @param {Object} implementation - provider implementation
 * @returns Promise.<String> url
 */
function registerImplementation(system, implementation) {
    if (system === undefined) {
        throw new Error('system is not specified');
    }

    if (!isString(system)) {
        throw new TypeError('system name has to be a string');
    }

    if (!isObject(implementation) || !isFunction(implementation.signIn)) {
        throw new TypeError('provider has to be an object with method "signIn" expected');
    }

    implementations[system] = implementation;
}

export { getImplementation, registerImplementation };
