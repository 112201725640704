import { LoginRadiusWidget, LRWidgetType } from './base';

export class SocialLoginWidget extends LoginRadiusWidget<any, any> {
    private customInterface: string;

    constructor(container: string, lrObject: any, template: string, customInterface: string) {
        super(container);
        this.widgetType = LRWidgetType.SOCIAL_LOGIN;
        this.lrObject = lrObject;
        this.widgetOptions.templateName = template;
        this.customInterface = customInterface;
    }

    public initForm() {
        this.lrObject.customInterface(this.customInterface, this.widgetOptions);
        super.initForm();
        (window as any).social_lrobj = this.lrObject;
    }
}
