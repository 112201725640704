import { httpGet } from '@pressreader/services';

import { httpGetContent, httpPostContent } from '../../http';
import { IIssueLayoutDto, IIssuePageKeysDto, IIssueRuledPriceDto, IIssueTocDto } from '../types/issue.layout';
import { IIssueInfoDto } from '../types/issueinfo';
import { IPageMetaDto } from '../types/page.meta';

function getIssuePagesMeta(issue: string, pageNumbers?: number[]) {
    return httpGet<IPageMetaDto[]>('issues', `${issue}/pages`, pageNumbers?.length ? { pageNumbers: pageNumbers.join(',') } : undefined);
}

function getIssueToc(params: { issue: string; version?: number; expungeVersion?: number; lng?: string; isExternal?: boolean }) {
    return httpGet<IIssueTocDto>('toc', params);
}

function getIssueLayout(params: { issue: string; version?: number; expungeVersion?: number }) {
    return httpGet<IIssueLayoutDto>('layout', params);
}

function getIssueInfoById(issueId: number) {
    return httpGetContent<IIssueInfoDto>('IssueInfo', 'GetIssueInfoById', { issueId });
}

function getIssueInfo(issue: string) {
    return httpGetContent<IIssueInfoDto>('IssueInfo', 'GetIssueInfo', { issue });
}

function getIssueInfoByCid(cid: string, issueDate?: string) {
    return httpGetContent<IIssueInfoDto>('IssueInfo', 'GetIssueInfoByCid', { cid, issueDate });
}

function getIssueInfoByBookmark(bookmarkId: string) {
    return httpGetContent<IIssueInfoDto>('IssueInfo', 'GetIssueInfoByBookmark', { bookmarkId });
}

function getPageKeys(issue: string, pageNumber: number, bookmark?: string, preview?: boolean) {
    return httpGetContent<IIssuePageKeysDto>('IssueInfo', 'GetPageKeys', { issue, pageNumber, bookmark, preview });
}

function getIssueRuledPrice(issueId: number) {
    return httpGetContent<IIssueRuledPriceDto>('IssueInfo', 'GetIssueRuledPrice', { issueId: issueId });
}

function confirmIssueForReading(issueId: number) {
    return httpPostContent<void>('issues', 'Confirm', { issueId });
}

function mapIssueInfoDto(dto: IIssueInfoDto) {
    return {
        issue: dto.Issue.Issue,
        cid: dto.Issue.CID,
        profileId: dto.Issue.ProfileId,
        displayDate: dto.Issue.IssueDateDisplayName,
        issueName: dto.Newspaper.Name,
        isRightToLeft: dto.Newspaper.IsRightToLeft,
        pagesCount: dto.Pages,
    };
}

/** @deprecated Please use `getIssueInfo` returning a full `IIssueInfoDto` instead. */
async function getIssueInfoCompact(issue: string) {
    const dto = await getIssueInfo(issue);
    return mapIssueInfoDto(dto);
}

/** @deprecated Please use `getIssueInfoByCid` returning a full `IIssueInfoDto` instead. */
async function getIssueInfoCompactByCid(cid: string, issueDate?: string) {
    const dto = await getIssueInfoByCid(cid, issueDate);
    return mapIssueInfoDto(dto);
}

export {
    getIssuePagesMeta,
    getIssueToc,
    getIssueInfoById,
    getIssueInfo,
    getIssueInfoByCid,
    getIssueInfoByBookmark,
    getIssueRuledPrice,
    getPageKeys,
    getIssueLayout,
    confirmIssueForReading,
};
export { getIssueInfoCompact, getIssueInfoCompactByCid };
