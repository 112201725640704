/* eslint-env es6 */

import ndPurchase from 'purchase/nd.purchase';
import customInvalidSubscriptionDialog from '@pressreader/src/subscriptions/components/invalidsubscription.dialog';

export function issuePurchaseRequired(data) {
    const requestedContent = {};

    if (data.Parameters) {
        requestedContent.articleId = data.Parameters.articleId;
        requestedContent.issueId = data.Parameters.issueId;

        // custom scenario
        if (data.Parameters.subscriptionBehaviour) {
            return customInvalidSubscriptionDialog.show(data.Parameters.subscriptionBehaviour);
        }
    }
    return ndPurchase.purchase(requestedContent);
}
