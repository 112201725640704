import * as _ from 'lodash';

import { ViewName } from '@pressreader/navigation-types';
import { isLanguageRtl } from '@pressreader/utils';
import { svcAuth } from '@pressreader/services';
import { localSettings, feedSettings } from '@pressreader/usersettings';

import ndConfig from 'deprecated/nd.config';
import ndRes from 'deprecated/nd.res';
import ndViewerApp from 'nd.viewer.app';
import ndViewerViews from 'nd.viewer.views';
import ndUser from 'nd.user';
import ndUtils from 'nd.utils';

var _Settings = (function () {
    var self = {};

    var auto = localSettings.get('Translation.Auto', {
        enable: false,
        language: 'en',
        exceptions: null,
    });
    self.auto = auto;

    var current = {
        enable: false,
        language: null,
    };
    self.current = current;

    function save() {
        localSettings.set('Translation.Auto', auto);
    }

    self.save = save;

    return self;
})();

var _Language = function (code, name, pairs) {
    this._code = code;
    if (name) this._name = name;
    this._pairs = pairs || [];
    this._rtl = isLanguageRtl(code);
};
_Language.byTitle = function (a, b) {
    if (a.title() > b.title()) return 1;
    else if (a.title() < b.title()) return -1;
    else return 0;
};
_Language.prototype = {
    _code: null,
    _name: null,
    _pairs: null,
    _rtl: false,
    code: function () {
        return this._code;
    },
    name: function () {
        return this._name;
    },
    title: function () {
        return ndRes.val('Languages.' + this._code, this._name);
    },
    pairs: function (sort) {
        return sort ? this._pairs.slice(0).sort(_Language.byTitle) : this._pairs;
    },
    isRtl: function () {
        return this._rtl;
    },
    isValidPair: function (code) {
        for (var i = 0; i < this._pairs.length; ++i) {
            if (this._pairs[i]._code === code) {
                return true;
            }
        }

        return false;
    },
    clone: function () {
        var pairs = this._pairs ? this._pairs.slice(0) : null;
        for (var i = 0; i < pairs.length; ++i) {
            pairs[i] = pairs[i].clone();
        }

        return new _Language(this._code, this._name, pairs);
    },
};

var _LanguagesManager = (function () {
    var self = {},
        _languages = [],
        _languages2 = [],
        _map = {},
        _ready = false;

    ndConfig.loaded().then(_update);
    ndConfig.onConfigUpdated(_update);

    function _update() {
        _languages = [];
        _languages2 = [];
        _map = {};
        var cfg = ndConfig.get('translation');
        for (var lng in cfg.languages) {
            var language = cfg.languages[lng];
            language = new _Language(lng, language.name, language.pairs.slice(0));
            _languages.push(language);
            _map[lng] = language;
        }
        var map = {};
        for (var i = 0; i < _languages.length; ++i) {
            var pairs = _languages[i].pairs();
            for (var j = 0; j < pairs.length; ++j) {
                var code = pairs[j];
                var language = _map[code];
                if (!language) language = _map[code] = new _Language(code);
                pairs[j] = language;
                if (code in map) continue;
                map[code] = language;
            }
        }
        for (var code in map) {
            _languages2.push(map[code]);
        }
        _ready = true;
    }

    self.language = function (code) {
        return _map[code];
    };

    self.fromLanguages = function () {
        return _languages;
    };

    self.toLanguages = function () {
        return _languages2;
    };

    self.ready = function () {
        return _ready;
    };

    self.isValidPair = function (from, to) {
        var language = _map[from];
        if (!language) return false;
        return language.isValidPair(to);
    };

    return self;
})();

var _Translation = (function () {
    var self = {};

    var _callbacks = null;
    var _currentLanguageChangedCallbacks = null;
    var _isOriginalLanguageEnabled = false;
    var _currentView;

    (function () {
        ndViewerApp.issueLoading(_resetCurrentLanguage);
        ndViewerViews.activeViewChanging(_activeViewChanging);

        //when user signs out, we disable auto translation if enableAutoTranslationForAnonymous=false
        svcAuth.bind('online', function () {
            ndConfig.loaded().then(function () {
                if (!ndUser.isLogin() && !ndConfig.get('translation').enableAutoTranslationForAnonymous) {
                    if (_Settings.auto.enable) {
                        _Settings.auto.enable = false;
                        _Settings.save();
                    }
                }
            });
        });
    })();

    function _isItSwitchTextViewAndPagesView(viewName) {
        return (
            (_currentView == ViewName.PagesView && viewName == ViewName.TextView) ||
            (_currentView == ViewName.TextView && viewName == ViewName.PagesView)
        );
    }

    function _isItRadioView(viewName) {
        return _currentView == ViewName.Radio || viewName == ViewName.Radio;
    }

    function _activeViewChanging(data, viewName) {
        if (!_isItSwitchTextViewAndPagesView(viewName) && !_isItRadioView(viewName)) {
            //should not reset current language after switch between TextView and PagesView.
            _resetCurrentLanguage();
        }
        _currentView = viewName;
    }

    function _resetCurrentLanguage() {
        if (_Settings.current.language) {
            _Settings.current.language = null;
            _isOriginalLanguageEnabled = false;
            if (_currentLanguageChangedCallbacks) _currentLanguageChangedCallbacks.fire();
        }
    }

    var enable = (self.enable = function () {
        return svcAuth.online() && _LanguagesManager.ready();
    });

    var available = (self.available = function () {
        ndConfig.get('translation').enable;
    });

    var toLanguages = (self.toLanguages = function (code) {
        if (code) {
            var language = _LanguagesManager.language(code);
            if (!language) return null;
            return language.pairs();
        } else return _LanguagesManager.toLanguages();
    });

    var fromLanguages = (self.fromLanguages = function () {
        return _LanguagesManager.fromLanguages();
    });

    var language = (self.language = function (code) {
        return _LanguagesManager.language(code);
    });

    self.currentLanguage = function () {
        return _getCurrentLanguage();
    };

    function isAutoTranslated() {
        return !_isOriginalLanguageEnabled && !!_getAutoLanguage(_contentLanguage());
    }

    self.isAutoTranslated = isAutoTranslated;

    function isTranslated() {
        return !_isOriginalLanguageEnabled && !!_Settings.current.language;
    }

    self.isTranslated = isTranslated;

    function _getCurrentLanguage() {
        if (!enable()) return null;
        if (_isOriginalLanguageEnabled) {
            return null;
        }
        var currentLanguage = _Settings.current.language;
        if (currentLanguage) {
            return currentLanguage;
        }
        return _getAutoLanguage(_contentLanguage());
    }

    function _isContentLanguageAvailable() {
        var view = ndViewerViews.getActiveViewName();
        if (view == ViewName.TextView || view == ViewName.PagesView) {
            return true;
        }

        return false;
    }

    function _getAutoLanguage(code) {
        var auto = _Settings.auto;
        if (auto.enable && auto.language) {
            if (_isContentLanguageAvailable()) {
                if (auto.language == code) return null;
                if (!_LanguagesManager.isValidPair(code, auto.language)) return null;
                if (!auto.exceptions) return auto.language;
                for (var i = 0; i < auto.exceptions.length; ++i) {
                    if (code == auto.exceptions[i]) return null;
                }
            }
            return auto.language;
        }
        return null;
    }

    var auto = (self.auto = {
        enable: function (enable) {
            if (!arguments.length) return _Settings.auto.enable;
            if (_Settings.auto.enable != enable) {
                _Settings.auto.enable = enable;
                _Settings.save();
                if (enable) {
                    _Settings.current.language = null;
                    _isOriginalLanguageEnabled = false;
                }
                _fireCallbacks();
            }
            return auto;
        },
        language: function (code) {
            if (!arguments.length) return language(_Settings.auto.language);
            if (_Settings.auto.language != code) {
                _Settings.auto.language = code;
                _Settings.save();
                if (_Settings.auto.enable) {
                    _Settings.current.language = null;
                    _isOriginalLanguageEnabled = false;
                    _fireCallbacks();
                }
            }
            return auto;
        },
        exceptions: function (exceptions) {
            if (!arguments.length) return _Settings.auto.exceptions;
            var oldval = _Settings.auto.exceptions;
            if (oldval) oldval = oldval.sort().join(',');
            var newval = exceptions;
            if (newval) newval = newval.sort().join(',');
            if (oldval != newval) {
                _Settings.auto.exceptions = exceptions;
                _Settings.save();
                if (_Settings.auto.enable) _fireCallbacks();
            }
            return auto;
        },
    });

    var current = (self.current = {
        language: function (code) {
            if (!arguments.length) return language(_Settings.current.language);
            _Settings.current.language = code;
            if (code) {
                _isOriginalLanguageEnabled = false;
            }
            _fireCallbacks();
            return current;
        },
    });

    function _fireCallbacks() {
        if (_currentLanguageChangedCallbacks) _currentLanguageChangedCallbacks.fire();
        if (_callbacks) _callbacks.fire();
    }

    var bind = (self.bind = function (evt, callback) {
        switch (evt) {
            case 'currentLanguageChanged':
                if (!_currentLanguageChangedCallbacks) _currentLanguageChangedCallbacks = $.Callbacks('unique');
                _currentLanguageChangedCallbacks.add(callback);
                break;
            default:
                if (!_callbacks) _callbacks = $.Callbacks('unique');
                _callbacks.add(callback);
        }
        return self;
    });

    var unbind = (self.unbind = function (evt, callback) {
        switch (evt) {
            case 'currentLanguageChanged':
                if (!_currentLanguageChangedCallbacks) return self;
                if (!callback) _currentLanguageChangedCallbacks.empty();
                else _currentLanguageChangedCallbacks.remove(callback);
                break;
            default:
                if (!_callbacks) return self;
                if (!arguments.length) _callbacks.empty();
                else _callbacks.remove(callback);
        }
        return self;
    });

    self.enableOriginalLanguage = function () {
        _isOriginalLanguageEnabled = true;
        current.language(null);
        return self;
    };

    function _contentLanguage() {
        return ndViewerApp.contentLanguage();
    }

    function _mergeLanguages(locales) {
        var result = [],
            codes = [];
        for (var i = 0; i < locales.length; ++i) {
            var lang = self.language(locales[i].language);
            if (!lang) {
                continue;
            }
            var pairs = lang.pairs(false);
            result = result.concat(
                _.filter(pairs, function (val) {
                    if (!codes[val.code()]) {
                        codes[val.code()] = 1;
                        return val;
                    }
                }),
            );
        }

        return _.sortBy(result, function (val) {
            return val.title();
        });
    }

    self.getTranslationLanguages = function () {
        if (_currentView !== ViewName.PagesView && _currentView !== ViewName.TextView) {
            return feedSettings.get().then(function (json) {
                var locales = json ? json.locales || [] : [];
                return _mergeLanguages(locales);
            });
        } else {
            var language = self.language(ndViewerApp.contentLanguage());
            if (language) {
                var pairs = language.pairs(true);
                var result = pairs.length === 0 ? [] : [language].concat(pairs);
                return Promise.resolve(result);
            }
        }

        return Promise.resolve([]);
    };

    return self;
})();

_Translation.bind('currentLanguageChanged', $.nd.viewer.pages.loadToc);
export default ndUtils.translation = _Translation;
