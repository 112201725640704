// eslint-env es6

import { isString, isObject, isFunction } from 'lodash';

const providers = {};

/**
 * @module Register of authorization providers implementations
 */

/**
 * Delegates authorization call to registered implementation
 * @param {String} provider - external provider name, like "facebook"
 * @returns {Object} auth provider
 */
function getProvider(provider) {
    if (!isString(provider)) {
        throw new TypeError('provider name is not specified');
    }

    const implementation = providers[provider];

    if (implementation === undefined) {
        throw new TypeError(`${provider} provider implementation not registered`);
    }

    return implementation;
}

/**
 * Register specific implementation of auth provider
 *
 * @param {String} provider - provider name, e.g. "facebook"
 * @param {Object} implementation - provider implementation
 * @returns Promise.<String> url
 */
function registerProvider(provider, implementation) {
    if (provider === undefined) {
        throw new Error('provider is not specified');
    }

    if (!isString(provider)) {
        throw new TypeError('provider name has to be a string');
    }

    if (!isObject(implementation) || !isFunction(implementation.authorize)) {
        throw new TypeError('provider has to be an object with method "authorize" expected');
    }

    providers[provider] = implementation;
}

export { getProvider, registerProvider };
