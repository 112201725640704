import * as ko from 'knockout';
import ndRes from 'nd.res';
import ndConfig from '@pressreader/src/deprecated/nd.config';
import { setUserBundleAutoRenew } from '@pressreader/commerce-api';
import ndAlert from '@pressreader/src/nd.alert';
import { formatDate } from '@pressreader/resources';
import { getBasePath } from '@pressreader/routing';

function CurrentBundleItemViewModel(serverData) {
    this.cid = ko.observable(serverData.CID);
    this.newspaperName = ko.observable(serverData.NewspaperName);
    this.issueDate = ko.observable(serverData.IssueDate ? new Date(parseInt(serverData.IssueDate.replace(/\/Date\((.*?)\)\//gi, '$1'))) : null);
    this.formattedIssueDate = ko.observable(serverData.FormattedIssueDate);
}

export default function CurrentBundleViewModel(serverData) {
    this.productTypes = {
        undefined: 0,
        prepaidIssueDates: 1,
        prepaidIssues: 2,
        prepaidIssueDateList: 3,
    };

    this.statuses = {
        Unknown: 0,
        Active: 1,
        Canceled: 2,
        PaymentDeclined: 3,
        Finished: 4,
        StoppedByAutoRenew: 5,
    };

    this.balance = ko.observable(serverData.Balance);
    this.bundleName = ko.observable(serverData.BundleName);
    this.started = ko.observable(serverData.FormattedStarted);
    this.finished = ko.observable(serverData.FormattedFinished);
    this.issueStartDate = ko.observable(serverData.FormattedIssueStartDate);
    this.issueStopDate = ko.observable(serverData.FormattedIssueStopDate);
    this.productType = ko.observable(serverData.ProductType);
    this.status = ko.observable(serverData.Status);
    this.renewDate = ko.observable(serverData.FormattedRenewDate);
    this.isRenewable = ko.observable(serverData.Renewable);
    this.userProductId = ko.observable(serverData.UserProductId);

    this.showStopAutoRenewButton = ko.computed(function () {
        return ndConfig.get('Accounting.ShowStopAutoRenewButton', false) && this.isRenewable();
    }, this);

    this.isPrepaidIssueDateList = ko.computed(function () {
        return this.productType() === this.productTypes.prepaidIssueDateList;
    }, this);

    this.isPrepaidIssue = ko.computed(function () {
        return this.productType() === this.productTypes.prepaidIssues;
    }, this);

    this.isActive = ko.computed(function () {
        return this.status() === this.statuses.Active;
    }, this);

    this.items = ko.observableArray(
        ko.utils.arrayMap(serverData.Items, function (item) {
            return new CurrentBundleItemViewModel(item);
        }),
    );

    this.formattedDetail = ko.computed(function () {
        var length = this.items().length;
        if (this.isPrepaidIssueDateList() && length > 0) {
            return (
                this.items()[0].newspaperName() +
                ' | ' +
                this.items()[0].formattedIssueDate() +
                (length > 1 ? ' and ' + (length - 1) + ' more...' : '')
            );
        } else {
            return (
                length +
                ' Title' +
                (length > 1 ? 's' : '') +
                ' | ' +
                (this.isPrepaidIssue() ? this.balance() + ' Balance Remaining' : this.issueStartDate() + ' - ' + this.issueStopDate())
            );
        }
    }, this);

    this.formattedIssuesAvailableDates = ko.computed(function () {
        return this.issueStartDate() + ' - ' + this.issueStopDate();
    }, this);

    this.formattedTitle = ko.computed(function () {
        return this.isPrepaidIssueDateList ? ndRes.val('Accounting.BundleInfo.PurchasedIssues') : ndRes.val('Accounting.BundleInfo.PurchasedTitles');
    }, this);

    this.navigate = function (issue) {
        if (this.isPrepaidIssueDateList()) {
            const dateStr = formatDate(issue.issueDate(), 'yyyyMMdd');
            return issue.cid() + dateStr + '00000000001001';
        } else {
            return `${getBasePath()}issue.cid()`;
        }
    };

    this.confirmStopAutoRenew = async () => {
        await ndAlert().confirm(
            ndRes.val('Accounting.Bundle.StopAutoRenewMessage'),
            ndRes.val('Buttons.Cancel.Text'),
            ndRes.val('Buttons.Yes.Text'),
            true,
        );
        try {
            await setUserBundleAutoRenew(this.userProductId(), false);
        } catch (error) {
            ndAlert().alert(ndRes.val('Errors.Post.SaveFailed'));
        }
    };
}
