import * as $ from 'jquery';
import * as ndStates from 'nd.states';
import $ndRes from 'deprecated/nd.res';
import { templates as ndTemplates } from 'deprecated/nd.templates';
import $ndLayout from 'nd.layout';
import $navHistory from 'nd.navigationhistory';

var mainPanel = null;
var tintPanel = null;
var isVisible = false;
var timer = null;
var parentTint = null;

function createTintPanel() {
    parentTint = $('.dialog-tint:visible');
    parentTint.hide();
    if (!tintPanel) {
        tintPanel = $(document.body).createChild('div').addClass('dialog-tint').css({
            'z-index': 1007,
        });
    }
    return tintPanel;
}

function showImpl() {
    createTintPanel();
    if (!mainPanel) {
        mainPanel = $(document.body).createChild('div').addClass('alert-wrapper').css({
            position: 'fixed',
            top: 0,
            left: 0,
            'z-index': 1007,
            visibility: 'hidden',
        });
        $ndLayout.resize(adjustWithDelay);
        $navHistory.oneNavigate(hide);
    }

    ndTemplates.renderTo('v7.Client.WaitingPopup', null, mainPanel);
    ko.applyBindings(null, mainPanel[0]);
    var panel = mainPanel.children().css({ opacity: 0, margin: 0 });
    isVisible = true;
    adjust();
    panel.css({
        transitionProperty: 'opacity',
        transitionTimingFunction: 'ease-out',
        transitionDuration: '300ms',
        opacity: 1,
        visibility: 'visible',
    });
    ndStates.modal.set();
}

function adjustWithDelay() {
    // execute async to avoid unnecessary calls
    if (timer) {
        clearTimeout(timer);
    }

    timer = setTimeout(adjust, 200);
}

function adjust() {
    if (!mainPanel || !isVisible) return;

    var aHeight = 0;
    var aHeightExtra = 0;
    var aHeaderHeight = 0;
    var aControlsHeight = 0;
    var aBodyHeightOrigin = 0;
    var aBodyHeight = 0;
    var aWidth = 0;
    var aMinWidth = 0;

    mainPanel.find('.alert').each(function () {
        aWidth = $(this).outerWidth();
        aMinWidth = parseInt($(this).css('min-width'));
        if (aWidth > aMinWidth) {
            $(this).addClass('alert-textleft');
            //mainPanel.addClass('alert-textleft');
        }
    });

    if (mainPanel.find('.alert-label').length > 0) {
        mainPanel.find('.alert-label').show();
        mainPanel.addClass('alert-labeled');
    }

    mainPanel.find('header:visible').each(function () {
        aHeaderHeight = $(this).outerHeight(true);
    });

    mainPanel.find('.controls:visible').each(function () {
        aControlsHeight = $(this).outerHeight(true);
    });

    var ww = $.windowWidth();
    var wh = $.windowHeight();
    mainPanel.find('.alert-body').each(function () {
        $(this).css('height', 'auto'); // reset
        aBodyHeightOrigin = $(this).outerHeight();
        aHeight = $(this).parent('.alert').height();
        aHeightExtra = $(this).parent('.alert').outerHeight() - aHeight;

        if ($(this).parent('.alert').outerHeight() > wh) {
            aHeight = wh - aHeightExtra;
        } else {
            $(this).parent('.alert').css('height', 'auto'); // reset
        }

        aBodyHeight = aHeight - aHeaderHeight - aControlsHeight;
        $(this).height(aBodyHeight);

        if (aBodyHeight < aBodyHeightOrigin) {
            $(this).parent('.alert').addClass('alert-scaled');
        } else {
            $(this).parent('.alert').removeClass('alert-scaled');
        }
    });

    screenCenter(ww, wh);
    refreshScroll();
}

function screenCenter(ww, wh) {
    var panel = mainPanel.children();
    panel.css({
        left: (ww - panel.width()) >> 1,
        top: (wh - panel.height()) >> 1,
    });
}

function refreshScroll() {
    mainPanel.find('[nd-scrollable]').each(function () {
        var $scroll = $data('scroller');
        if ($scroll && $.isFunction($scroll.refresh)) {
            $scroll.refresh();
        }
    });
}

function show() {
    return Promise.all([$ndRes.loaded(), ndTemplates.loaded()]).then(showImpl);
}

function hide() {
    if (!isVisible) {
        return;
    }
    ndStates.modal.reset();
    tintPanel.remove();
    tintPanel = null;
    mainPanel.remove();
    mainPanel = null;
    isVisible = false;
    parentTint.show();
}

export default {
    show: show,
    hide: hide,
};
