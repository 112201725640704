import { OperationStatus, select, subscribe } from '@pressreader/appstore';

import { selectGetConfigValue, selectStatus } from './store';

export function configLoaded() {
    const status = select(selectStatus);
    if (status === OperationStatus.Done) {
        return Promise.resolve();
    }
    if (status === OperationStatus.Failed) {
        return Promise.reject(new Error('Load config failed.'));
    }
    return new Promise<void>((resolve, reject) => {
        // We have to check this again since Promise body is called async and store's content could've changed.
        const status = select(selectStatus);
        if (status === OperationStatus.Done) {
            resolve();
        } else if (status === OperationStatus.Failed) {
            reject(new Error('Load config failed.'));
        } else {
            const unsubscribe = subscribe(selectStatus, s => {
                if (s === OperationStatus.Done) {
                    resolve();
                } else if (s === OperationStatus.Failed) {
                    reject(new Error('Load config failed.'));
                } else {
                    return;
                }
                unsubscribe();
            });
        }
    });
}

function getConfigValue<T>(path: string, defaultValue: T): T;
function getConfigValue<T>(path: string): T | undefined;
function getConfigValue<T>(path: string, defaultValue?: T) {
    return select(selectGetConfigValue)(path, defaultValue);
}

function getConfigValueAsync<T>(path: string, defaultValue: T): Promise<T>;
function getConfigValueAsync<T>(path: string): Promise<T | undefined>;
async function getConfigValueAsync<T>(path: string, defaultValue?: T) {
    await configLoaded();
    return getConfigValue(path, defaultValue);
}

export { getConfigValue, getConfigValueAsync };
