export function initCookieBot() {
    const id = 'Cookiebot';
    let script: HTMLScriptElement = document.getElementById(id) as HTMLScriptElement;
    if (!script) {
        script = document.createElement('script');
        script.id = id;
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://consent.cookiebot.com/uc.js';
        script.setAttribute('data-cbid', '06b589d2-9594-4323-af24-0b61ef0a0f2d');
        document.head.insertBefore(script, document.head.firstChild);
    }
}
