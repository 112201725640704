import { IOSInfo } from '@pressreader/analytics-types';

import { IOSInfoDto } from '@pressreader/analytics-api';
import { IResult } from 'ua-parser-js';

function buildOSInfo(ua: IResult): IOSInfo {
    return {
        name: ua.os.name ?? '',
        version: ua.os.version ?? '',
    };
}

function mapOSInfoToDto(info: IOSInfo): IOSInfoDto {
    return {
        name: info.name,
        version: info.version,
    };
}

export { buildOSInfo, mapOSInfoToDto };
