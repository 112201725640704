import isFunction from 'lodash/isFunction';

import { isPromiseLike } from '@pressreader/types';

export type RouteData = Record<string, unknown>;
export type RouteConstraintValidateFn = (routeKey: string, routeData: RouteData) => boolean | Promise<unknown>;

export class RouteConstraint {
    readonly validate: RouteConstraintValidateFn;
    readonly failureReason: string;

    constructor(validate: RouteConstraintValidateFn = () => false, failureReason = 'value does not meet expectations') {
        if (!isFunction(validate)) {
            throw new Error('Route constraint argument must be a function');
        }
        this.validate = validate;
        this.failureReason = failureReason;
    }

    /**
     * Determine whether the URL parameter contains a valid value for the constraint.
     * @param routeKey - The name of the parameter that is being checked.
     * @param routeData - A dictionary that contains the parameters for the URL.
     * @returns A Promise fulfilled if the Url parameter contains a valid value, rejected promise otherwise.
     */
    matchAsync(routeKey: string, routeData: RouteData): Promise<unknown> {
        const validationResult = this.validate(routeKey, routeData);
        if (isPromiseLike(validationResult)) {
            return validationResult;
        }

        return new Promise<void>((resolve, reject) => {
            if (validationResult) {
                resolve();
            } else {
                reject(`'${routeKey}' constraint: ${this.failureReason}; Value: ${routeData[routeKey]}`);
            }
        });
    }
}

/**
 * Checks if given object is good enough to be a route constraint.
 * Some duck typing here.
 * "instanceof" call does not work here because of IE10, where inheritance chain is sort of broken in transpiled code.
 * @param candidate A parameter to check
 * @return True if given parameter can be used as a route constraint, false otherwise.
 *
 */
function isConstraint(candidate: any): candidate is RouteConstraint {
    return candidate && typeof candidate.matchAsync === 'function';
}

/**
 * Creates route constraint object for given implementation.
 * @param constraint - constraint implementation.
 */
export function createConstraint(constraint: RouteConstraintValidateFn | RouteConstraint): RouteConstraint {
    if (!constraint) {
        throw new Error('Constraint object or function required');
    }

    if (isConstraint(constraint)) {
        return constraint;
    }

    return new RouteConstraint(constraint);
}
