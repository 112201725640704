import ndConfig from '@pressreader/src/deprecated/nd.config';

import { IIdentity } from 'integration/adapter';
import identityFactory from 'integration/identity.factory';

let promise: Promise<IIdentity>;

export function loadExternalAuthenticationProvider() {
    if (!promise) {
        promise = (async () => {
            await ndConfig.loaded();
            const providerName = ndConfig.get('Authentication.ExternalAuthenticationProvider', '');
            if (providerName) {
                const provider = identityFactory.create(providerName);
                await provider.load();
                return provider;
            }
            return null;
        })();
    }
    return promise;
}
