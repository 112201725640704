/* eslint-env es6 */
/* eslint-disable */
import * as jQuery from 'jquery';
import * as ko from 'knockout';
import resources from 'nd.res';

var validators = {};

	// Required validator
	(function ($, validators) {

		function RequiredValidator(option) {

			if (typeof option !== "boolean" &&
                typeof option !== "string" &&
                !ko.isObservable(option)) {
                throw new TypeError("true/false or validation message are valid options for required.");
            }
                
            var isRequired = function () {
                if (typeof option === "boolean") {
                    return option;
                }

                if (ko.isObservable(option)) {
                    return option();
                }

                return true;
            };

			var message = typeof option === "string" ? option : resources.val("Accounting.Validation.Client.RequiredField", "This field is required."),
				validateFn = function (val) {

					if (!isRequired()) return true;

					var stringTrimRegEx = /^\s+|\s+$/g,
						testVal = val;

					if (val === undefined || val === null) {
						return false;
					}

					if (typeof (val) === "string") {
						testVal = val.replace(stringTrimRegEx, '');
					}

					return ((testVal + '').length > 0);
				};

			return {
				validate: validateFn,
				message: message
			};
		}

		validators.required = RequiredValidator;
	})(jQuery, validators);

	// MinLength validator
	(function (validators) {

		function MinLengthValidator(option) {

			var minLength,
				message;

			if ($.type(option) === 'object') {
				minLength = option.val;
				message = option.message;
			}
			else {
				minLength = parseInt(option, 10);
				message = "A value greater than or equal to " + minLength + " is required.";
			}

			if (isNaN(minLength)) throw new TypeError("An integer is required for minLength.");

			var validateFn = function (val) {
					if (val === undefined || val === null) {
						return false;
					}
					if (typeof val !== 'string') throw new TypeError("A string is required to the minLength validation.");

					return val.length >= minLength;
				};

			return {
				validate: validateFn,
				message: message
			}
		}

		validators.minLength = MinLengthValidator;

	})(validators);


	// Email validator
	(function (validators) {

		function EmailValidator(option) {

			if (typeof option !== "boolean" &&
				typeof option !== "string")
				throw new TypeError("true/false or validation message are valid options for email validator.");

			// jquery email validation regular expression
			var emailRegExp = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i,
				needValidation = typeof option === "boolean" ? option : true,
				message = typeof option === "string" ? option : "A valid Email is required.",
				validateFn = function (val) {
					if (!needValidation || val === '' || val === undefined || val === null) return true;

					return emailRegExp.test(val);
				};

			return {
				validate: validateFn,
				message: message
			}
		}

		validators.email = EmailValidator;

	})(validators);

	// Email list validator
	(function (validators, emailValidator) {

		function EmailListValidator(option) {
			if (typeof option !== "object")
				throw new TypeError("configuration object is only valid option for email list validator.");

			var delimiters = option.delimiters;

			if (!delimiters) throw new TypeError("Delimiters option is required for email list validator.");

			delimiters = "[" + delimiters.replace(" ", "\\s") + "]+";

			var message = option.message || "Please specify a list of valid EMail addresses separated by space, comma or semicolon.",
				regexp = new RegExp(delimiters),
				validateFn = function (val) {

					if (!val) return true;

					var emailAddresses = val.split(regexp);
					for (var i = 0, len = emailAddresses.length; i < len; i++) {
						var current = emailAddresses[i];
						if (!current) continue;
						if (!emailValidator.validate(current)) return false;
					}

					return true;
				};

			return {
				validate: validateFn,
				message: message
			}
		}

		validators.emailList = EmailListValidator;

	})(validators, validators.email(true));


	(function(validatorsRepo) {

		var defaultMessage = 'HEX color required';
		function hexColorValidator(options) {
			if (typeof options !== 'object') {
				options = {
					includeHash: options,
				};
			}
			var includeHashSymbol = !!options.includeHash;
			var allowEmpty = !!options.allowEmpty;
			var message = options.message || defaultMessage;
			var hexColorRegexp = new RegExp('^' + (includeHashSymbol ? '#' : '') + '[\\da-f]{6}$', 'i');
			return {
				validate: function(hexVal) {
					return (hexVal === '' && allowEmpty) || hexColorRegexp.test(hexVal);
				},
				message: message,
			};
		}

		validatorsRepo.hexColor = hexColorValidator;

	})(validators);

	(function(validatorsRepo) {

		var defaultMessage = 'The value is invalid';

        /**
         * Creates custom validator
         * @param {Object|Function} options
         * @param {Function} options.handler - validation handler
         * @param {String|ko.observable} options.message - validation error message
         */
		function customHandlerValidator(options) {
			var validateFn;
			var message;

			if (typeof options === 'function') {
				validateFn = options;
				message = defaultMessage;
			} else if (typeof options === 'object') {
				validateFn = options.handler;
				message = options.message || defaultMessage;
			} else {
				throw new Error('Invalid validator configuration: object or function expected');
			}

			return {
				validate: function(value) {
					return validateFn(value);
				},
				message: message,
			};
		}

		validatorsRepo.customValidator = customHandlerValidator;

	})(validators);


	/**
	 * This validator is for internal usage in "autocomplete" ko-component
	 * and is not supposed to be used anywhere outside its scope.
	 */
	(function(validatorsRepo) {
		var defaultMessage = 'Please select a value from the list';	// TODO: to resources
		function autocompletionValidator(options) {

			var message;
			var autocompletedValue;
			if (typeof options === 'object') {
				message = options.message;
				autocompletedValue = options.autocompletedValue;
			} else if (typeof options === 'function') {	// must be an observable
				message = defaultMessage;
				autocompletedValue = options;
			}

			return {
				message: message,
				validate: function(val) {
					return val === autocompletedValue.peek();
				},
			};
		}

		validatorsRepo.autocompleted = autocompletionValidator;
	})(validators);

	export default validators;

