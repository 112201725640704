// eslint-env es6

class TranslateTransformation {
    constructor(x = 0, y = 0) {
        this.x = x;
        this.y = y;
    }

    static fromDto(data) {
        const translateTransform = new TranslateTransformation(data && data.x, data && data.y);
        return translateTransform;
    }
}

export default TranslateTransformation;
