﻿import hash from 'hash-sum';
import { firstValueFrom, Subject } from 'rxjs';

import { AuthenticationResult } from '@pressreader/authentication-types';
import { getParsedJwt } from '@pressreader/utils';

import { initAuthentication, refreshBearerToken, saveAuthorizationTickets } from './authentication.api';
import { getInterfaceLanguage } from './interfacelanguage';
import { getToken } from './services';

declare const window: Window & {
    _preload: Preload;
};

type EventType = 'online' | 'offline' | 'tokenExpired' | 'tokenUpdated';

const TokenFieldName = 'ab'; // token
const UserKeyFieldName = 'sub'; // userKey
const ExpirationFieldName = 'exp'; // expiration
// const SponsorIdFieldName = 'ag'; // sponsorId

interface AuthEvent {
    subject: Subject<void>;
    subscriptions: {
        callback(): void;
        unsubscribe(): void;
    }[];
}

interface Preload {
    confirmed: boolean;
}

interface TokenDto {
    Token: string;
    UserKey: string;
    BearerToken: string;
    ExpiresIn: number;
}

const events: Partial<Record<EventType, AuthEvent>> = {};
let _userKey: number | null = null;
let _bearerToken: string | null = null;
let _useGeoLocation = false;
let _updateTokenTimeoutId: number | null = null;
let isOnline = false;
let _initialized = false;
let _jwtHash = '';
let isPending = true;

const bearerTokenSubject = new Subject<string>();

bearerTokenSubject.subscribe({
    next() {
        isPending = false;
    },
    error() {
        isPending = false;
    },
});
let authPromise = firstValueFrom(bearerTokenSubject);

function resetPromise() {
    if (isPending) {
        // No need to reset: the promise is still unresolved.
        return;
    }
    isPending = true;
    authPromise = firstValueFrom(bearerTokenSubject);
}

function initAuthTickets() {
    initializeAuthAndPreload().catch(_onTokenFailed);
}

//TODO Check init.help.page.ts for usage:
// const hostName = window.location.hostname;
// const ticket = await activationProvider.getTicketByHost(hostName);
// svcAuth.online(ticket);
function online(): boolean;
function online(ticket: string): void;
function online(...params: unknown[]): boolean | void {
    // go online
    if (!arguments.length) {
        return isOnline;
    }
    // Keep for backward compatibility for init.help.page.ts
    const ticket = params[0] as string;
    getToken<TokenDto>([ticket], getInterfaceLanguage()).then(data => _onTokenReceived(data.BearerToken), _onTokenFailed);
}

function _onTokenFailed(reason: unknown) {
    resetPromise();
    bearerTokenSubject.error(reason);
}

function _onTokenReceived(bearer: string) {
    const jwtToken = getParsedJwt(bearer);
    if (!jwtToken) {
        return;
    }
    delete jwtToken[TokenFieldName];
    delete jwtToken[ExpirationFieldName];
    const newJwtHash = hash(jwtToken);

    _userKey = Number(jwtToken[UserKeyFieldName]);
    _bearerToken = bearer;

    resetPromise();

    if (!isOnline) {
        isOnline = true;
        // token was updated, no need to notify status update
        _fireCallbacks('online');
    }

    if (_jwtHash !== newJwtHash) {
        updateTokenWithTimeout(bearer);
        _jwtHash = newJwtHash;
        _fireCallbacks('tokenUpdated');
    }

    bearerTokenSubject.next(bearer);
}

function getExpirationTime(bearerToken: string) {
    const jwtToken = getParsedJwt(bearerToken);
    return jwtToken ? Number(jwtToken[ExpirationFieldName]) : 0;
}

function updateTokenWithTimeout(bearerToken: string) {
    if (_updateTokenTimeoutId) {
        window.clearTimeout(_updateTokenTimeoutId);
    }
    let exp = getExpirationTime(bearerToken);
    if (!exp) {
        exp = Math.round(Date.now() / 1000) + 5 * 60; // Default 5 minutes
    }
    // set the timer to run 1 minute before expiration
    // The maximum allowed delay in browsers is a 32 bit positive integer: 2**31 - 1.
    // Anything greater causes an overflow making the delay a negative number triggering the callback immediately.
    const refreshTime = Math.min(Math.pow(2, 31) - 1, exp * 1000 - Date.now() - 1 * 60 * 1000);
    _updateTokenTimeoutId = window.setTimeout(updateBearerToken, refreshTime);
}

async function updateBearerToken(forceUseGeoLocation?: boolean) {
    const result = await refreshBearerToken(_useGeoLocation || forceUseGeoLocation);
    updateAuthorizationTickets(result);
}

async function authorized() {
    const bearerToken = await authPromise;
    const exp = getExpirationTime(bearerToken);
    if (exp && exp * 1000 > Date.now()) {
        return bearerToken;
    }
    await updateBearerToken();
    return await authPromise;
}

/**
 * @deprecated please use bearerToken()
 */
function token() {
    const jwtToken = getParsedJwt(bearerToken());
    return jwtToken ? encodeURIComponent(jwtToken[TokenFieldName]) : '';
}

function userKey() {
    return _userKey;
}

function bearerToken() {
    return _bearerToken;
}

function bind(eventType: EventType, callback: () => void) {
    let event = events[eventType];
    if (!event) {
        event = events[eventType] = {
            subject: new Subject<void>(),
            subscriptions: [],
        };
    }
    if (event.subscriptions.map(s => s.callback).indexOf(callback) < 0) {
        const { unsubscribe } = event.subject.subscribe(callback);
        event.subscriptions.push({ callback, unsubscribe });
    }
}

function unbind(eventType: EventType, callback: () => void) {
    const event = events[eventType];
    if (!event) {
        return;
    }
    const idx = event.subscriptions.findIndex(s => s.callback === callback);
    if (idx < 0) {
        return;
    }
    event.subscriptions[idx].unsubscribe();
    event.subscriptions.splice(idx, 1);
}

function _fireCallbacks(eventType: EventType) {
    const event = events[eventType];
    if (event) {
        event.subject.next();
    }
}

async function initializeAuthAndPreload() {
    if (!_initialized) {
        _initialized = true;
        const result = await initAuthentication();
        if (result.useGeoLocation === 1) {
            await updateBearerToken(true);
        } else {
            updateAuthorizationTickets(result);
        }
    }
}

function updateAuthorizationTickets(response: AuthenticationResult) {
    _useGeoLocation = response.useGeoLocation === 1;
    saveAuthorizationTickets(response.tickets);
    _onTokenReceived(response.bearerToken);
}

export { initAuthTickets, online, authorized, token, userKey, bearerToken, bind, unbind, updateBearerToken, updateAuthorizationTickets };
