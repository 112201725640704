import { openAlertOkAsync } from '@pressreader/popups-alert';
import { httpDelete, httpGet, httpPost, httpPut, IRequestConfig } from '@pressreader/services';
import { HttpError } from '@pressreader/types';

interface IResponse {
    status: number;
    responseJSON: {
        StatusText: string;
        Parameters: {
            message: string;
            Text: string;
        };
    };
}
type ErrorHandler = (response: IResponse['responseJSON']) => Promise<{ skipRetry: boolean; skipThrowException?: boolean } | void>;

const DEFAULT_MAX_RETRIES = 2;
const errorHandlers: Record<string, ErrorHandler> = {};

/**
 * Sends request and handle failed response in case of 401 and 403 errors.
 * Server side intercepts ContentAccessDeniedException exception and returns ContentAccessResult result with following fields:
 * {
 *   Status: Number;
 *   StatusText: String
 *   Parameters: Object
 * }
 * We use StatusText to handle failures using "failureHandlers" approach, registered from files in handlers folder.
 * See @see PressReader.AzureServices.ExceptionHandling.ContentAccessErrorFilter on the server side for details.
 */
async function sendRequest<TResult>(request: () => Promise<TResult>, retriesOnFailResolved: number): Promise<TResult> {
    try {
        return await request();
    } catch (e) {
        if (!e) {
            // In offline mode undefined is returned
            throw new Error('Undefined response');
        }
        const httpReponse = e as IResponse;
        const response = httpReponse.responseJSON;

        const asError = e as HttpError;
        if (asError && asError.status === 404) {
            throw asError;
        }

        if (httpReponse.status !== 401 && httpReponse.status !== 403) {
            // Error handlers logic works only with results with status codes 401 and 403
            throw response;
        }

        // try to fix error using failureHandler and send request again
        const errorHandler = errorHandlers[response.StatusText?.toLowerCase()];
        if (errorHandler) {
            try {
                const errorHandlerResult = await errorHandler(response);
                const skipRetry = (typeof errorHandlerResult === 'object' && errorHandlerResult?.skipRetry) ?? false;
                if (!skipRetry && retriesOnFailResolved > 0) {
                    return sendRequest(request, retriesOnFailResolved - 1);
                }
                const skipThrowException = (typeof errorHandlerResult === 'object' && errorHandlerResult?.skipThrowException) ?? false;
                if (!skipThrowException && !skipRetry) {
                    throw new Error('No retries left.');
                }
            } catch (e) {
                const error = e as { message: string };
                if (error?.message) {
                    await openAlertOkAsync({ message: error.message, isHtml: true });
                }
                throw e;
            }
        } else {
            // show alert, if we get message from the server in wrapped response
            if (response?.Parameters) {
                const { message: Message, Text } = response.Parameters;
                // checking for html tags
                const re = new RegExp(/(<([^>]+)>)/gi);
                const message = !Message || re.test(Text) ? Text : Message;

                if (message) {
                    await openAlertOkAsync({ message, isHtml: true });
                }
            }
        }

        throw response;
    }
}

/**
 * Sends get request to the server.
 * @param urlServiceSection - start part of the url.
 * @param urlPathSection - end part of the url.
 * @param data - request parameters.
 */
function get<TResult>(
    urlServiceSection: string,
    urlPathSection: string,
    data: unknown,
    requestConfig?: IRequestConfig,
    retriesOnFailResolved = DEFAULT_MAX_RETRIES,
) {
    return sendRequest(() => httpGet<TResult>(urlServiceSection, urlPathSection, data, requestConfig), retriesOnFailResolved);
}

/**
 * Sends post request to the server synchronously.
 * @param urlServiceSection - start part of the url.
 * @param urlPathSection - end part of the url.
 * @param data - request parameters.
 */
function post<TResult>(
    urlServiceSection: string,
    urlPathSection: string,
    data?: unknown,
    requestConfig?: IRequestConfig,
    retriesOnFailResolved = DEFAULT_MAX_RETRIES,
) {
    return sendRequest(() => httpPost<TResult>(urlServiceSection, urlPathSection, data, requestConfig), retriesOnFailResolved);
}

/**
 * Sends put request to the server.
 * @param urlServiceSection - start part of the url.
 * @param urlPathSection - end part of the url.
 * @param data - request parameters.
 */
function put<TResult>(
    urlServiceSection: string,
    urlPathSection: string,
    data: unknown,
    requestConfig?: IRequestConfig,
    retriesOnFailResolved = DEFAULT_MAX_RETRIES,
) {
    return sendRequest(() => httpPut<TResult>(urlServiceSection, urlPathSection, data, requestConfig), retriesOnFailResolved);
}

/**
 * Sends delete request to the server.
 * @param urlServiceSection - start part of the url.
 * @param urlPathSection - end part of the url.
 * @param data - request parameters.
 */
function remove<TResult>(
    urlServiceSection: string,
    urlPathSection: string,
    data: unknown,
    requestConfig?: IRequestConfig,
    retriesOnFailResolved = DEFAULT_MAX_RETRIES,
) {
    return sendRequest(() => httpDelete<TResult>(urlServiceSection, urlPathSection, data, requestConfig), retriesOnFailResolved);
}

function registerErrorHandler(errorName: string, errorHandler: ErrorHandler, overwrite = false) {
    const name = errorName.toLowerCase();
    if (name in errorHandlers && !overwrite) {
        return;
    }
    errorHandlers[name] = errorHandler;
}

export { registerErrorHandler };
export { get as httpGetContent, post as httpPostContent, put as httpPutContent, remove as httpDeleteContent };
