/* eslint-env es6 */
import { Subject } from 'rxjs';

import { httpPost } from '@pressreader/services';
import { globalSettings } from '@pressreader/usersettings';
import { httpGetContent, httpPostContent } from '@pressreader/content-api';

import servicesApi from 'nd.services.api';
import ndUser from 'nd.user';
import { UserProfile } from 'nd.data.userprofile';
import { UserProfileFull } from 'nd.data.userprofile.full';
import FollowersInfo from 'nd.data.userprofile.followersinfo';
import { logError } from 'shared/errors/error.handlers';
import { createSimpleCache } from '@pressreader/utils';
// TODO: have to rid of direct ajax call, should be moved into some reusable method

const followersUpdatesSubject = new Subject();
const followersUpdates = followersUpdatesSubject.asObservable();

const cacheExpire = { expire: 1000 };
const cache = createSimpleCache(cacheExpire);

const cacheKeys = Object.freeze({
    profile: 'profile',
    fullProfile: 'fullprofile',
    profileName: 'profilename',
    profileCurrent: 'current',
});

function uploadProfilePhoto(file) {
    const formData = new window.FormData();
    formData.append('PhotoFile', file);

    return httpPost({
        requestConfig: {
            withCredentials: true,
        },
        url: `${servicesApi.baseUrl()}social/UploadPhoto`,
        data: formData,
    });
}

function getProfile(profileId) {
    const key = `${cacheKeys.profile}.${profileId}`;
    let promise = cache.get(key);
    if (!promise) {
        promise = httpGetContent('social', `profiles/${profileId}/brief`).then(data => new UserProfile(data.SocialInfo, data.OwnedPublications));
        cache.set(key, promise);
    }
    return promise;
}

function getProfileFull(profileId) {
    const key = `${cacheKeys.fullProfile}.${profileId}`;
    let promise = cache.get(key);
    if (!promise) {
        promise = httpGetContent('v2/users', `${profileId}/full`).then(data => new UserProfileFull(data));
        cache.set(key, promise);
    }
    return promise;
}

function resetProfilesCache(profileId) {
    if (profileId === undefined) {
        cache.clear();
    } else {
        cache.remove(`${cacheKeys.fullProfile}.${profileId}`);
        cache.remove(`${cacheKeys.profile}.${profileId}`);
    }
}

function getProfileName(encryptedAccountNumber) {
    const key = `${cacheKeys.profileName}.${encryptedAccountNumber}`;
    let promise = cache.get(key);
    if (!promise) {
        promise = httpGetContent('social', 'GetUserProfileName', { enAccountNumber: encryptedAccountNumber });
        cache.set(key, promise);
    }
    return promise;
}

function getProfilePhotoUrl(profileId) {
    return httpGetContent('social', `profiles/${profileId}/photo/url`);
}

function getAvatarImageId(profileId) {
    return httpGetContent('v2/users', `${profileId}/avatar`);
}

function getCurrentProfile() {
    if (!ndUser.isLogin()) {
        return Promise.reject('Cannot get profile for anonymous user.');
    }
    const key = `${cacheKeys.profileCurrent}`;
    let promise = cache.get(key);
    if (!promise) {
        promise = httpGetContent('social', 'profiles/current').then(data => new UserProfile(data));
        cache.set(key, promise);
    }
    return promise;
}

function processFollowUnfollow(profileId, result) {
    if (!result || !result.ok) {
        return Promise.reject();
    }

    return getCurrentProfile().then(current => {
        if (current) {
            const currentProfileId = current.profileId;
            resetProfilesCache(currentProfileId);
            getProfileFull(currentProfileId)
                .then(data => {
                    followersUpdatesSubject.next({
                        profileId: currentProfileId,
                        followers: data.followers,
                    });
                })
                .catch(logError);
        }
        resetProfilesCache(profileId);
        globalSettings.reset();

        const info = new FollowersInfo(result.info);
        followersUpdatesSubject.next({
            profileId: profileId,
            followers: info,
        });
        return info;
    });
}

function followUser(profileId) {
    return httpPostContent('social', `profiles/${profileId}/follow`).then(result => processFollowUnfollow(profileId, result));
}

function unFollowUser(profileId) {
    return httpPostContent('social', `profiles/${profileId}/unfollow`).then(result => processFollowUnfollow(profileId, result));
}

function getUserFollowers(profileId, filter, skip, take, orderBy) {
    return httpGetContent('social', `profiles/${profileId}/followers`, { filter, skip, take, orderBy }).then(data =>
        UserProfileFull.toPageSource(data),
    );
}

function getUsersFollowed(profileId, filter, skip, take, orderBy) {
    return httpGetContent('social', `profiles/${profileId}/follows`, { filter, skip, take, orderBy }).then(data =>
        UserProfileFull.toPageSource(data),
    );
}

function getStaticMapUrl(width, height, zoom, lat, lng) {
    return `${servicesApi.baseUrl()}map?width=${width}&height=${height}&zoom=${zoom}&centerLat=${lat}&centerLng=${lng}`;
}

function searchUsersChannels(filter, offset, limit, orderBy) {
    // call to channels/search with a filter less than 3 characters long fails with 500 status
    if (filter && filter.length < 3) {
        return Promise.resolve({
            items: [],
            meta: { offset, limit, totalCount: 0 },
        });
    }

    return servicesApi.get('channels', 'search', { offset, limit, filter, orderBy });
}

ndUser.onUserStateChanged(() => {
    // have to reset cached data once current user was changed
    // because followers-related information should be re-queried
    cache.remove(`${cacheKeys.profileCurrent}`);
    resetProfilesCache();
});

export default {
    followersUpdates,
    uploadProfilePhoto,
    getProfileFull,
    getProfile,
    getProfileName,
    getProfilePhotoUrl,
    getAvatarImageId,
    getCurrentProfile,
    getUserFollowers,
    getUsersFollowed,
    followUser,
    unFollowUser,
    getStaticMapUrl,
    searchUsersChannels,
};
