import isDate from 'lodash/isDate';
import memoize from 'lodash/memoize';
import { Subject } from 'rxjs';

import { dateFormat, embedRTL, format } from '@pressreader/utils';

const ISSUE_DATE_FORMAT_RESOURCE_ID = 'v7.Client.DateFormat.Issue';
const ISSUE_DATE_NOYEAR_FORMAT_RESOURCE_ID = 'v7.Client.DateFormat.Issue.NoYear';

const CURRENT_YEAR = new Date().getFullYear();

const resourceUsageSubject = new Subject<string>();

function toString(value: string | { toString(): string }) {
    return value?.toString ? value.toString() : '';
}

function getResourceUsageObservable() {
    return resourceUsageSubject.asObservable();
}

function getLocalizedString(resources: Record<string, string>) {
    return (resourceId: string, ...values: (string | { toString(): string })[]) => {
        resourceUsageSubject.next(resourceId);

        const resValue = resources[resourceId.toLowerCase()];
        return resValue === undefined ? resourceId : !values.length ? resValue : format(resValue, ...values.map(toString));
    };
}

function getLocalizedStringWithFallback(resources: Record<string, string>) {
    return (resourceId: string, fallbackResourceId: string, ...values: (string | { toString(): string })[]) => {
        resourceUsageSubject.next(resourceId);

        const resValue = resources[resourceId.toLowerCase()] ?? resources[fallbackResourceId.toLocaleLowerCase()];
        return resValue === undefined ? resourceId : !values.length ? resValue : format(resValue, ...values.map(toString));
    };
}

function getLocalizedStringWithDefault(resources: Record<string, string>) {
    return (resourceId: string, defaultValue: string, ...values: (string | { toString(): string })[]) => {
        resourceUsageSubject.next(resourceId);

        const resValue = resources[resourceId.toLowerCase()] ?? defaultValue;
        return values.length ? format(resValue, ...values.map(toString)) : resValue;
    };
}

const getLocalizedDate = memoize((resources: Record<string, string>) => {
    const customDateFormats = {
        MMM: getLocalizedMonthShortName(resources),
        MMMM: getLocalizedMonthFullName(resources),
        ddd: getLocalizedWeekDayShortName(resources),
        dddd: getLocalizedWeekDayMinName(resources),
    };
    const cachedDateFormat = memoize((format: string) => dateFormat.createDateFormat(format, customDateFormats));

    return (date: Date, resourceId: string) => {
        resourceUsageSubject.next(resourceId);

        if (!isDate(date) || isNaN(date.getTime())) {
            return '';
        }

        const format = resources[resourceId.toLowerCase()] ?? resourceId;
        return cachedDateFormat(format)(date);
    };
});

function getLocalizedIssueDate(resources: Record<string, string>) {
    const localizedDate = getLocalizedDate(resources);

    return (date: Date, short?: boolean) => {
        if (short) {
            if (date.getFullYear() === CURRENT_YEAR) {
                return localizedDate(date, ISSUE_DATE_NOYEAR_FORMAT_RESOURCE_ID);
            }
        }

        return localizedDate(date, ISSUE_DATE_FORMAT_RESOURCE_ID);
    };
}

const getLocalizedSafeRTLString = memoize((resources: Record<string, string>) => {
    const rtlEscapedItems = new Map<string, string>();

    return ((resourceId: string, defaultValue?: string) => {
        const id = resourceId.toLowerCase();

        resourceUsageSubject.next(id);

        let result = rtlEscapedItems.get(id);
        if (result) {
            return result;
        }

        const val = resources[id];
        if (!val) {
            return defaultValue;
        }

        result = embedRTL(val);
        rtlEscapedItems.set(id, result);
        return result;
    }) as {
        (id: string, defaultName: string): string;
        (id: string, defaultName?: string): string | undefined;
    };
});

function getLocalizedMonthShortName(resources: Record<string, string>) {
    const localizedSafeRTLString = getLocalizedSafeRTLString(resources);

    return (month: number) => {
        // num from 0 to 11
        const name = dateFormat.shortMonths[month];
        return localizedSafeRTLString(`Calendar.${name}`, name);
    };
}

function getLocalizedMonthFullName(resources: Record<string, string>) {
    const localizedSafeRTLString = getLocalizedSafeRTLString(resources);

    return (month: number) => {
        // num from 0 to 11
        const name = dateFormat.longMonths[month];
        return localizedSafeRTLString(`Calendar.${name}`, name);
    };
}

function getLocalizedWeekDayFullName(resources: Record<string, string>) {
    const localizedSafeRTLString = getLocalizedSafeRTLString(resources);

    return (weekDay: number) => {
        // num from 0 to 6
        const name = dateFormat.longDays[weekDay];
        return localizedSafeRTLString(`Calendar.${name}`, name);
    };
}

function getLocalizedWeekDayShortName(resources: Record<string, string>) {
    const localizedSafeRTLString = getLocalizedSafeRTLString(resources);

    return (weekDay: number) => {
        // num from 0 to 6
        const name = dateFormat.shortDays[weekDay];
        return localizedSafeRTLString(`Calendar.${name}`, name);
    };
}

function getLocalizedWeekDayMinName(resources: Record<string, string>) {
    const localizedSafeRTLString = getLocalizedSafeRTLString(resources);

    return (weekDay: number) => {
        // num from 0 to 6
        const name = dateFormat.minDays[weekDay];
        return localizedSafeRTLString(`Calendar.${name}`, name);
    };
}

export { getResourceUsageObservable };
export {
    getLocalizedString,
    getLocalizedStringWithFallback,
    getLocalizedStringWithDefault,
    getLocalizedDate,
    getLocalizedIssueDate,
    getLocalizedSafeRTLString,
    getLocalizedMonthShortName,
    getLocalizedMonthFullName,
    getLocalizedWeekDayFullName,
    getLocalizedWeekDayShortName,
    getLocalizedWeekDayMinName,
};
