/* eslint-env es6 */
'use strict';

import * as $ from 'jquery';
import * as ko from 'knockout';

import { loadingIndicatorMarkup } from 'shell/loading.indicator';

class SpinnerModel {
    constructor({ type = 'large' }, container) {
        const $element = $(container).children().first();

        const containerClassName = type === 'large' ? 'pr-spinner-l' : 'pr-spinner-s';
        $element.addClass(containerClassName);
    }
}

/**
 * Knockout spinner component.
 *
 * @description Component configuration.
 * @param {Object} params Component configuration object.
 * @param {String} [params.type='large'] - type of spinner
 * @param {ko.observable} params.visible - An observable expression that controls spinner visibility.
 *
 * @example
 *
 * ``` HTML
 *  <spinner params="type: 'small', visible: isLoading"/>
 *  <spinner params="type: 'small', visible: isLoading"></spinner>
 */
const spinnerComponentDefinition = {
    synchronous: true,
    viewModel: {
        createViewModel: (params, componentInfo) => new SpinnerModel(params, componentInfo.element),
    },
    template: `<div class="pr-spinner">${loadingIndicatorMarkup}</div>`,
};

const COMPONENT_NAME = 'spinner';

/**
 * Component registration
 */
if (!ko.components.isRegistered(COMPONENT_NAME)) {
    ko.components.register(COMPONENT_NAME, spinnerComponentDefinition);
}
