import { load, ReCaptchaInstance } from 'recaptcha-v3';

import { getConfigValueAsync } from '@pressreader/config';

let instance: ReCaptchaInstance;

async function getConfig() {
    const [enabled, { siteKey }] = await Promise.all([
        getConfigValueAsync<boolean>('features.recaptcha.v3.enabled', false),
        getConfigValueAsync<{ siteKey: string | null }>('recaptcha', { siteKey: null }),
    ]);
    return { enabled, siteKey };
}

async function initReCaptcha() {
    if (instance) {
        return;
    }
    const { enabled, siteKey } = await getConfig();
    if (!enabled || !siteKey) {
        return;
    }
    instance = await load(siteKey);
}

async function executeReCaptcha(action: 'signup' | 'signin') {
    await initReCaptcha();
    if (!instance) {
        return '';
    }
    return await instance.execute(action);
}

export { executeReCaptcha, initReCaptcha };
