import { Lock, LockHandle } from 'core/lock';
import { Callbacks } from 'nd.callbacks';

// usage:
// $.nd.states.busy.set();
// do work
// $.nd.states.busy.reset();
class State extends Callbacks {
    readonly lock = new Lock();

    private _lockHandles: LockHandle[] = [];

    constructor() {
        super();
        this.lock.onLocked.subscribe(() => this._fire('set'));
        this.lock.onUnlocked.subscribe(() => this._fire('reset'));
    }

    set() {
        this._lockHandles.push(this.lock.lock());
    }

    reset() {
        if (!this._lockHandles.length) {
            return;
        }
        const lockHandle = this._lockHandles.pop();
        lockHandle.release();
    }

    get isSet() {
        return this.lock.isLocked;
    }

    get resetPromise() {
        if (!this.lock.isLocked) {
            return Promise.resolve();
        }
        return new Promise<void>(resolve => {
            this.lock.onUnlockedOnce(resolve);
        });
    }
}

export const busy = new State();
export const busyState = busy;
export const busyStateLock = busy.lock;
export const modal = new State();
export const modalState = modal;
