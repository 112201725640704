// eslint-env es6

/**
 * "InvalidSubscription" issue resolution dialog.
 */

import { getBehaviourCase, BEHAVIOUR_CASE } from 'subscriptions/services/behaviourprofile.service';
import BehaviourCaseViewModel from 'subscriptions/viewmodels/behaviourcase.viewmodel';
import BehaviourActionViewModel from 'subscriptions/viewmodels/behaviouraction.viewmodel';
import { reduce, startsWith } from 'lodash';
import ndDialog from 'nd.ui.dialog';
import { DialogStatus } from 'nd.ui.dialogstatus';

class ViewModel extends BehaviourCaseViewModel {
    constructor({ dialog, behaviourCase }) {
        super({
            properties: behaviourCase.properties,
            // exclude custom actions
            // "custom" means that action is not exposed through "actions" property of viewmodel
            // but available via direct call of $action(name) method
            actions: behaviourCase.actions.filter(action => !startsWith(action.name, '$')),
        });

        // custom actions
        this.$actions = reduce(
            behaviourCase.actions,
            (acc, action) => {
                if (startsWith(action.name, '$')) {
                    acc[action.name.substr(1)] = new BehaviourActionViewModel(action);
                }
                return acc;
            },
            {},
        );

        this._dialog = dialog;
    }

    $action(name) {
        const action = this.$actions[name];
        if (action === undefined) {
            throw new Error(`action ${name} is not defined`);
        }

        this.action(action);
    }

    close() {
        this._dialog.hide(DialogStatus.Cancel);
    }

    action(action) {
        Promise.resolve(action.execute())
            .then(() => this._dialog.hide(DialogStatus.Ok))
            .catch(error => this._dialog.hide(DialogStatus.Unknown, { error }));
    }
}

/**
 * Shows dialog
 * @param {String} behaviourProfileName - name of subscription behavior profile to apply
 * @returns {Promise.<Object>} promise of shown dialog result
 */
function showDialog(behaviourProfileName) {
    return new Promise((resolve, reject) => {
        getBehaviourCase(behaviourProfileName, BEHAVIOUR_CASE.SUBSCRIPTION_NOT_VALID)
            .then(behaviourCase => {
                // eslint-disable-next-line eqeqeq
                if (behaviourCase == null) {
                    reject(new Error(`${BEHAVIOUR_CASE.SUBSCRIPTION_NOT_VALID} case not configured for ${behaviourProfileName}`));
                }
                // eslint-disable-next-line eqeqeq
                if (behaviourCase.templateId == null) {
                    reject(new Error(`template not configured for ${BEHAVIOUR_CASE.SUBSCRIPTION_NOT_VALID} of ${behaviourProfileName} profile`));
                }

                const parentDialog = ndDialog.getParent();
                const dialog = ndDialog.createInstance(parentDialog);
                const viewModel = new ViewModel({ dialog, behaviourCase });

                const config = {
                    templateName: behaviourCase.templateId,
                    model: viewModel,
                    name: 'InvalidSubscriptionDialog',
                };

                dialog.onHide((elm, result) => {
                    if (result && result.status === DialogStatus.Ok) {
                        resolve(true);
                    } else {
                        reject(result && result.error ? result.error : undefined);
                    }
                });

                dialog.onRestore(() => dialog.resize());

                dialog.show(config);
            })
            .catch(reject);
    });
}

export default {
    show: showDialog,
};
