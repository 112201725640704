import * as $ from 'jquery';

import { Align } from '@pressreader/popups-types';

import $ndUi from 'nd.ui';
import $ndLayout from 'nd.layout';
import $ndGestures from 'nd.gestures';
import { getNextTintZIndex, getCurrentDialogZIndex } from 'nd.layout.zindex';
import 'nd.knockout.extenders';
import ndUiUtils from 'nd.ui.utils';

var _mgr = (function () {
    var self = {},
        list = [];

    $ndLayout.resize(resize);

    function add(menu) {
        list.push(menu);
    }
    self.add = add;

    function remove(menu) {
        for (var i = 0; i < list.length; ++i) {
            if (list[i] !== menu) continue;
            // remove the menu and all submenus (they were pushed after the menu item to remove)
            list.length = i;
            return;
        }
    }
    self.remove = remove;

    function hide() {
        for (var i = 0; i < list.length; ++i) {
            list[i]._hide();
        }
    }
    self.hide = hide;

    function resize() {
        for (var i = 0; i < list.length; ++i) {
            list[i].resize();
        }
    }
    self.resize = resize;

    return self;
})();

function _Menu(controller) {
    this._controller = controller;
}

_Menu.prototype = {
    _controller: null,
    _tintPanel: null,
    _menuPanel: null,
    _shieldPanel: null,
    _config: null,
    _position: null,
    _elm: null,

    empty: function () {
        delete this._config;
        return this;
    },
    show: function (config, renderer) {
        _mgr.add(this);

        this._config = config;

        if (config.context) {
            this._showTintPanel(config.context);
        }

        const shieldPanel = this._getShieldPanel();
        shieldPanel.parent().bind('contextmenu', false);
        shieldPanel.show();

        this._showMenuPanel(config, renderer);
    },
    hide: function () {
        _mgr.remove(this);
        this._hideMenuPanel();

        const shieldPanel = this._getShieldPanel();
        shieldPanel.parent().unbind('contextmenu', false);
        shieldPanel.hide();

        this._hideTintPanel();
        if (!ndUiUtils.isPageVerticallyScrollable()) {
            window.scrollTo(0, 0);
        }
    },
    _hide: function () {
        this._controller.hide();
    },
    dispose: function () {
        if (this._elm) {
            $ndGestures.unbind(this._elm);
        }
        this.empty();
        if (this._menuPanel) {
            this._menuPanel.remove();
        }
        if (this._tintPanel) {
            this._tintPanel.remove();
        }
        delete this._controller;
        delete this._menuPanel;
        delete this._tintPanel;
        delete this._config;
    },
    _showTintPanel: function (config) {
        var l = config.left,
            w = config.width,
            ww = $.windowWidth();

        if (l < 0) {
            w += l;
            l = 0;
        }
        if (l + w > ww) w = ww - l;
        this._getTintPanel()
            .css({
                left: l + 'px',
                top: config.top + 'px',
                width: w + 'px',
                height: config.height + 'px',
            })
            .show();
    },
    _hideTintPanel: function () {
        this._getTintPanel().hide();
    },
    _getTintPanel: function () {
        if (!this._tintPanel) {
            var p = this._config.parentSelector ? $(this._config.parentSelector) : $(document.body);
            this._tintPanel = p.createChild('div').css('z-index', getNextTintZIndex()).addClass('dialog-tint').hide();
        }
        return this._tintPanel;
    },
    _showMenuPanel: function (config, renderer) {
        this._elm = this._getMenuPanel().show();
        renderer.render(this._elm);
        this._elm = this._elm
            .children()
            .css('opacity', 0)
            .bind('touchstart mousedown PointerDown MSPointerDown', function (event) {
                event = event.originalEvent || event;
                if (event.stopPropagation) event.stopPropagation();
                event.cancelBubble = true;
            });
        this._adjust(this._elm, config);
        this._elm.css({
            transitionProperty: 'opacity',
            transitionTimingFunction: 'ease-out',
            transitionDuration: '300ms',
            opacity: 1,
        });
    },
    _hideMenuPanel: function () {
        this._getMenuPanel()
            .css({
                transitionProperty: '',
                transitionTimingFunction: '',
                transitionDuration: '',
            })
            .hide()
            .empty();
    },
    _getShieldPanel: function () {
        if (!this._shieldPanel) {
            const p = this._config.parentSelector ? $(this._config.parentSelector) : $(document.body);
            this._shieldPanel = p.createChild('div').addClass('dialog-tint').hide().css({
                position: 'fixed',
                top: 0,
                left: 0,
                'z-index': getNextTintZIndex(),
            });
        }

        this._shieldPanel.width('100%').height('100%');
        return this._shieldPanel;
    },
    _getMenuPanel: function () {
        if (!this._menuPanel) {
            var p = this._config.parentSelector ? $(this._config.parentSelector) : $(document.body);
            var self = this;
            this._menuPanel = p.createChild('div').hide().css({
                position: 'fixed',
                top: 0,
                left: 0,
                overflow: 'hidden',
                'z-index': getCurrentDialogZIndex(),
            });
            $ndGestures.enableTouchScroll(this._menuPanel);
            this._menuPanel.bind('click', function (event) {
                if (event.target == self._menuPanel[0]) {
                    self._hide();
                }
            });
        }
        //this._menuPanel.width($.windowWidth()).height($.windowHeight());
        this._menuPanel.width('100%').height('100%');
        return this._menuPanel;
    },
    position: function () {
        return this._position;
    },
    resize: function () {
        if (!this._config) return;
        this._getShieldPanel(); // to adjust size
        var menu = this._getMenuPanel().children();
        menu./*find("div[nd-scrollable='yes']").*/ height('auto').resize();
        this._adjust(menu, this._config);
    },
    /*
     * @menu - html element where menu is rendered
     * @config: {
     *     position: {
     *         x: 100,  //[function|value] actual "left" position of the dialog
     *         y: 100,  //[function|value] actual "top" position of the dialog
     *         align: {
     *             x: ndMenu.align.center,      //[function|value]
     *             y: ndMenu.align.screenCenter //[function|value]
     *         }
     *     }
     * }
     **/
    _adjust: function (menu, config) {
        var $menuBody = menu.find('.pop-body'),
            winHeight = $.windowHeight(),
            winWidth = $.windowWidth(),
            alignX = Align.Center,
            alignY = Align.Center,
            left = 0,
            top = 0;

        // reset height to compute box size
        $menuBody.height('auto');
        menu.height('auto');

        var menuHeight = menu.outerHeight() || 0,
            menuWidth = menu.outerWidth() || 0;

        // nice bottom margin to not stick menu to the bottom edge,
        // because the menu has rounded border which looks pretty ugly being sticked to border
        var niceMargin = 20;

        if (config.position) {
            if (config.position.align) {
                var align = config.position.align;
                if (align.x !== undefined) {
                    alignX = ($.isFunction(align.x) ? align.x() : align.x) || 0;
                }
                if (align.y !== undefined) {
                    alignY = ($.isFunction(align.y) ? align.y() : align.y) || 0;
                }
            }

            var positionY = config.position.y;
            if (positionY !== undefined) {
                top = ($.isFunction(positionY) ? positionY() : positionY) || 0;
            }
            var positionX = config.position.x;
            if (positionX !== undefined) {
                left = ($.isFunction(positionX) ? positionX() : positionX) || 0;
            }
        }

        if (alignY === Align.Top || alignY === Align.ScreenTop) {
            /* nothing really changes here, _Align.top - is default state */
        } else if (alignY === Align.Center) {
            top = top - menuHeight / 2 - niceMargin;
            // overflow Y
            if (winHeight - top < menuHeight) {
                top = winHeight - menuHeight - niceMargin;
            }
        } else if (alignY === Align.Bottom) {
            top = top - menuHeight;
        } else if (alignY === Align.ScreenCenter) {
            top = winHeight / 2 - menuHeight / 2;
        } else if (alignY === Align.ScreenBottom) {
            top = winHeight - menuHeight;
        }

        if (top < niceMargin) {
            top = niceMargin;
        }

        if (alignX === Align.Left || alignX === Align.ScreenLeft) {
            /* default position */
        } else if (alignX === Align.Center) {
            left -= menuWidth / 2;
        } else if (alignX === Align.Right) {
            left -= menuWidth;
        } else if (alignX === Align.ScreenCenter) {
            left = winWidth / 2 - menuWidth / 2;
        } else if (alignX === Align.ScreenRight) {
            left -= menuWidth;
        }

        if (left < niceMargin) {
            left = niceMargin;
        } else if (left + menuWidth + niceMargin > winWidth) {
            left = winWidth - menuWidth - niceMargin;
        }

        // check vertical oversize
        var vDelta = winHeight - menuHeight - top - niceMargin;
        var oversizeY = vDelta <= 0;

        if (oversizeY) {
            // menu must be resized to fit to the screen
            var toolbar = menu.find('.toolbar');
            var toolbarHeight = toolbar.length ? toolbar.outerHeight() : 0;
            var proposedMenuHeight = winHeight - top - niceMargin;
            if (proposedMenuHeight > menuHeight) {
                proposedMenuHeight = menuHeight;
            }
            var bodyHeight = proposedMenuHeight - toolbarHeight;
            $menuBody.height(bodyHeight + 'px');
            menu.height(proposedMenuHeight + 'px');
        }

        this._position = {
            left: left,
            top: top,
            right: left + menuWidth,
            bottom: top + menuHeight,
        };
        menu.css({
            top: top + 'px',
            left: left + 'px',
        });
    },
    elm: function () {
        return this._elm;
    },
};
var _api = {
    view: _Menu,
    align: Align,
    manager: _mgr,
};

export const view = _Menu;
export const manager = _mgr;

export default $ndUi.menu = _api;
