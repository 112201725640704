/* eslint-env es6 */

'use strict';

import { merge } from 'lodash';
import * as ko from 'knockout';
import * as validate from 'ko/nd/validate';
import clickAndTouchEvent from 'ko/nd/clickandtouch';
import ndTemplateloader from 'ko/nd/template.loader';
import ExternalTemplateEngine from 'ko/nd/externalTemplateEngine';
import registerSwitchComponent from 'ko/components/switch';
import registerResetBinding from 'ko/bindings/reset';

const koNd = merge({}, validate, { event: clickAndTouchEvent });

// eslint-disable-next-line no-import-assign
ko.nd = koNd;

// Register custom component loader with higher than default priority
ko.components.loaders.unshift(ndTemplateloader);

registerSwitchComponent(ko);
registerResetBinding(ko);

// Redefine the standard engine by customed one that allow load templates supplied from external resource.
ko.setTemplateEngine(new ExternalTemplateEngine());

export default koNd;
