import { isNullOrWhitespace } from '@pressreader/utils';

import { IIdentity } from './adapter';
import { Auth0, Auth0SSO, Gigya, Janrain, LoginRadius, Piano } from './';

class IdentityFactory {
    create(provider: string): IIdentity {
        if (isNullOrWhitespace(provider)) {
            throw new TypeError('Provider value is expected to be a non-empty string');
        }

        switch (provider) {
            case 'Janrain':
                return new Janrain();
            case 'Gigya':
                return new Gigya();
            case 'Piano':
                return new Piano();
            case 'LoginRadius':
                return new LoginRadius();
            case 'Auth0':
                return new Auth0();
            case 'Auth0SSO':
                return new Auth0SSO();
            default:
                throw new TypeError(`Provider ${provider} is not supported`);
        }
    }
}

export default new IdentityFactory();
