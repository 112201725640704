enum EcommerceSteps {
    SubscriptionPlans = 'subscriptionPlans',
    Bundles = 'bundles',
    PaymentInformation = 'paymentInformation',
    Confirmation = 'confirmation',
    Finish = 'finish',
}

enum EcommerceTypes {
    AddPaymentInfo = 'AddPaymentInfo',
    AddToCart = 'AddToCart',
    BeginCheckout = 'BeginCheckout',
    CheckoutProgress = 'CheckoutProgress',
    Purchase = 'Purchase',
    RemoveFromCart = 'RemoveFromCart',
    SelectContent = 'SelectContent',
    SetCheckoutOption = 'SetCheckoutOption',
}

interface IEcommerceData {
    affiliation: string;
    cardType: string;
    currency: string;
    items: IEcommerceItem[];
    tax: number;
    transactionId: string;
    value: number;

    promotions: IEcommercePromotion[];
    checkout_step: string;
    checkout_option: string;
    payment_type: string;
    shipping: string;
}

interface IEcommerceItem {
    id: string;
    name: string;
    brand?: string;
    category?: string;
    variant?: string;
    subTotal?: number;
    discount?: number;
    total?: number;
    quantity?: number;
    coupon?: string;
}

interface IEcommercePromotion {
    id: string;
    name: string;
}

export { EcommerceSteps, EcommerceTypes, IEcommerceData, IEcommerceItem, IEcommercePromotion };
