import 'nd.core';

import { select, observeSelector, dispatch } from '@pressreader/appstore';
import { forceConfigLoadDoneAction, selectConfig, extendConfigAction, configLoaded, getConfigValue, getConfigValueAsync } from '@pressreader/config';
import ndConfigCore from 'nd.config.core';

function getView() {
    return self;
}

/** @deprecated Should be removed. Called only from native.adapter. */
function setLoaded() {
    dispatch(forceConfigLoadDoneAction());
}

/** @deprecated Should be removed. Called only from native.adapter. */
function extend(config: Record<string, unknown>) {
    dispatch(extendConfigAction({ values: config }));
}

const configUpdatedCallbacks: (() => void)[] = [];

function onConfigUpdated(callback: () => void) {
    if (configUpdatedCallbacks.indexOf(callback) >= 0) {
        return;
    }
    configUpdatedCallbacks.push(callback);
    observeSelector(selectConfig).subscribe(() => callback());
}

observeSelector(selectConfig).subscribe(config => ndConfigCore.configData({ config }));

function getConfig() {
    return select(selectConfig);
}

/** @deprecated Please use selectors from `@pressreader/appcore` */
const self = ($.nd.config = {
    get: getConfigValue,
    getAsync: getConfigValueAsync,
    getView: getView,
    loaded: configLoaded,
    setLoaded,
    extend,
    onConfigUpdated,
    getConfig,
});
export default self;
