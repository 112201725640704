import { call } from 'redux-saga/effects';

import { GeneratorResult, storeManager } from '@pressreader/appstore';
import { openPopup } from '@pressreader/popups-core';

import { IAlertOkOptions, loadModule, POPUPS_ALERT_OK_NAME } from './private';

function* openAlertOk(options: IAlertOkOptions): GeneratorResult<void> {
    yield call(loadModule);

    yield openPopup<void>({
        ...options,
        type: 'alert',
        name: POPUPS_ALERT_OK_NAME,
        canDismiss: true,
    });
}

async function openAlertOkAsync(options: IAlertOkOptions) {
    await loadModule();
    await new Promise<void>(resolve => {
        storeManager.runSaga(function* () {
            yield openPopup<void>({
                ...options,
                type: 'alert',
                name: POPUPS_ALERT_OK_NAME,
                canDismiss: true,
            });
            resolve();
        });
    });
}

export { openAlertOk, openAlertOkAsync };
