import $ndConfig from '@pressreader/src/deprecated/nd.config';
import ndAlert from '@pressreader/src/nd.alert';
import { addPreviewHandler, printContentClass, removePreviewElement } from '@pressreader/src/nd.printing.noprint';

import ndRes from 'nd.res';

const printingNotSupportedByBrowser = (additionalMessage = '') =>
    ndAlert().show(
        ndRes.val(
            'Errors.PrintingNotSupportedByBrowser',
            "This browser doesn't support printing, use another browser if you want to use this feature.",
        ) + additionalMessage,
    );

const print = async (pageTitle, renderFunc) => {
    removePreviewElement();

    const previewElement = document.createElement('div');
    previewElement.className = printContentClass;

    const renderResult = renderFunc(previewElement, () => null);
    if (typeof renderResult?.then === 'function') {
        await renderResult;
    }

    const tempTitle = document.title;
    document.title = pageTitle;

    document.body.appendChild(previewElement);

    addPreviewHandler();

    if (typeof window.print === 'function') {
        try {
            window.print();
        } catch (error) {
            printingNotSupportedByBrowser(` (${error})`);
        }
    } else {
        printingNotSupportedByBrowser();
    }

    document.title = tempTitle;

    return Promise.resolve(true);
};

const printElement = (pageTitle, htmlElement) => {
    print(pageTitle, previewElement => previewElement.appendChild(htmlElement.cloneNode(true)));
};

const getDefaultPaperSize = () => {
    const currentCountry = $ndConfig.get('location.currentUserCountry');
    const countryPaperSizeName = $ndConfig.get('onlineprinting.CountryPaperSizes', [])[currentCountry];

    return countryPaperSizeName || 'A4';
};

export default {
    getDefaultPaperSize,
    print,
    printElement,
};
