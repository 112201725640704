/* eslint-env es6 */

'use strict';

import ndConfig from 'deprecated/nd.config';

function getUserLocale() {
    return ndConfig.getAsync('res').then(res => {
        if (res.locale) {
            const [language, country] = res.locale.split('-');
            return { language, country };
        }
        return { language: undefined, country: undefined };
    });
}

export { getUserLocale };
