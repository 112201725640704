class Deferred<T = any> {
    static resolve<T = any>(value?: T) {
        return Promise.resolve(value);
    }

    static reject<T = any, TResult = never>(reason?: T) {
        return Promise.reject<TResult>(reason);
    }

    private readonly _promise: Promise<T>;
    private _resolve: (value?: T) => Promise<T>;
    private _reject: (reason?: any) => Promise<T>;
    private _state = 'pending';

    constructor() {
        this._promise = new Promise<T>((resolve, reject) => {
            this._resolve = (value?: T) => {
                resolve(value);
                return this._promise;
            };
            this._reject = (reason?: any) => {
                reject(reason);
                return this._promise;
            };
        });
        this._promise.then(
            () => {
                this._state = 'resolved';
            },
            () => {
                this._state = 'rejected';
            },
        );
        (this._promise as any).state = () => this._state;
    }

    // Has to be a property, since there is a number of places that look like: .then(dfd.resolve, dfd.reject);
    get resolve() {
        return this._resolve;
    }

    // Has to be a property, since there is a number of places that look like: .then(dfd.resolve, dfd.reject);
    get reject() {
        return this._reject;
    }

    then<TResult1 = T, TResult2 = never>(
        onfulfilled?: (value: T) => TResult1 | PromiseLike<TResult1>,
        onrejected?: (reason: any) => TResult2 | PromiseLike<TResult2>,
    ) {
        return this._promise.then(onfulfilled, onrejected);
    }

    catch<TResult = never>(onrejected?: (reason: any) => TResult | PromiseLike<TResult>) {
        return this._promise.catch(onrejected);
    }

    finally(onfinally?: () => void) {
        return this._promise.finally(onfinally);
    }

    promise() {
        return this._promise;
    }

    state() {
        return this._state;
    }
}

export { Deferred };
