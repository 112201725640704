import 'nd.views.general';
import { httpPost } from '@pressreader/services';

var alertsView = (function () {
    function updateAlert(field, url) {
        httpPost({
            url,
            data: { cid: $(field).val(), alertChecked: field.checked },
            requestConfig: {
                contentType: 'application/json',
            },
        }).catch(function (error) {
            alert(error.responseText);
        });
    }

    function deleteMonitor(form, url) {
        httpPost({
            url,
            data: { monitorId: $(form).find('#monitorId').val() },
            requestConfig: {
                contentType: 'application/json',
            },
        }).catch(function (error) {
            alert(error.responseText);
        });
        $(form).parents('li:first').remove();
    }

    function getNotificationUTCTime(notificationTime) {
        var today = new Date();
        var dateObj = new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), notificationTime, 0, 0);
        return dateObj.getUTCHours();
    }

    function updateMonitor(form, url) {
        var data = { monitorId: $(form).find('#monitorId').val() };
        var frequency = $(form).find('#frequency').val();
        data.frequency = frequency;
        if (isTimeVisible(frequency)) {
            data.notificationTime = getNotificationUTCTime($(form).find('#notificationTime').val());
        }

        httpPost({
            url,
            data,
            requestConfig: {
                contentType: 'application/json',
            },
        }).then(function (data) {
            $(form).find('.sl-button > .sli-meta').text(data);
            $(form)
                .find('.js-field')
                .each(function () {
                    var old = $(this).attr('data-field-old-value');
                    if (typeof old !== 'undefined' && old !== false) {
                        $(this).attr('data-field-old-value', $(this).val());
                    }
                });
        }).catch(function (error) {
            alert(error.responseText);
        });
    }

    function isTimeVisible(frequency) {
        return !(frequency == '0' || frequency == '1024');
    }

    function setFrequencyList() {
        $('.js-monitor-open').click(function () {
            if (isTimeVisible($(this).find('.js-monitor-frequency').val())) {
                $(this).find('.js-frequency-panel').show();
            } else {
                $(this).find('.js-frequency-panel').hide();
            }
        });

        $('.js-monitor-frequency').on('change', function () {
            if (isTimeVisible($(this).val())) {
                $(this).parent().next('.js-frequency-panel').show();

                var notificationTime = $(this).parent().next().find('#notificationTime');
                $(notificationTime).val(1);
                var val = $(notificationTime).find('option:selected').text();
                $(notificationTime).next().text(val);
            } else {
                $(this).parent().next('.js-frequency-panel').hide();
            }
        });

        $('.js-monitor-frequency').each(function () {
            var frequency = $(this).attr('data-monitor-frequency');
            $(this).val(frequency);
            $(this).attr('data-field-old-value', frequency);
            var val = $('option:selected', this).text();
            $(this).next().text(val);

            if (isTimeVisible(frequency)) {
                $(this).parent().next('.js-frequency-panel').show();
            } else {
                $(this).parent().next('.js-frequency-panel').hide();
            }
        });
    }

    function setTimeSentList() {
        $('.js-monitor-time-sent').each(function () {
            var value = $(this).attr('data-monitor-time-sent');
            if (value) {
                var today = new Date();
                var dateObj = new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), parseInt(value, 10), 0, 0);

                dateObj.setHours(dateObj.getHours() - new Date().getTimezoneOffset() / 60);
                var hours = dateObj.getHours();
                $(this).val(hours);
                $(this).attr('data-field-old-value', hours);
                var val = $('option:selected', this).text();
                $(this).next().text(val);
            }
        });
    }

    var api = {
        init: function () {
            $('[data-fields-alerts-url]').click(function (event) {
                if (event.target.tagName.toLowerCase() === 'input') {
                    $(event.target).parent('label').toggleClass('checked');
                    updateAlert(event.target, $(this).attr('data-fields-alerts-url'));
                }
            });

            $('[data-fields-deletemonitor-url]').click(function () {
                var url = $(this).attr('data-fields-deletemonitor-url');
                var form = $(this).parents('form');
                deleteMonitor(form, url);
            });

            $('[data-fields-updatemonitor-url]').click(function () {
                var url = $(this).attr('data-fields-updatemonitor-url');
                var form = $(this).parents('form');
                updateMonitor(form, url);
            });

            setFrequencyList();
            setTimeSentList();
        },
    };

    return api;
})();

$(function () {
    alertsView.init();
});
