import { merge } from 'lodash';

enum EventTypes {
    Exception = 'Exception',
}

class ExceptionPayload {
    description: string;
    fatal: boolean;
    constructor(params: ExceptionPayload) {
        merge(this, params);
    }
}

export { EventTypes, ExceptionPayload };
