import { confirmIssueForReading } from '@pressreader/content-api';
import { localizedString } from '@pressreader/resources';
import ndAlert from '@pressreader/src/nd.alert';
import { DialogStatus } from '@pressreader/src/nd.ui.dialogstatus';

import { getCurrentSubscription } from 'subscriptions/services';
import { SubscriptionMode } from 'subscriptions/SubscriptionInfo';

export async function issueReadingConfirmationRequired(data) {
    const alert = ndAlert();

    let message: string;
    const { subscriptionMode, maxIssues, balance, subscriptionExpirationDateFormatted } = await getCurrentSubscription();
    if (subscriptionMode === SubscriptionMode.Bundle) {
        message = localizedString('Dialogs.ConfirmIssueReading');
    } else {
        message = localizedString('Dialogs.ConfirmIssueReading.IssuesRemaining', maxIssues, balance, subscriptionExpirationDateFormatted);
    }

    const customVm = {
        message,
        messageHeader: localizedString('Dialogs.ConfirmIssueReading.Text'),
        messageType: 'html',
        noButtonTitle: localizedString('Buttons.Cancel'),
        yesButtonTitle: localizedString('Buttons.Confirm.Text'),
        noButtonClick: () => alert.hide(DialogStatus.Cancel),
        yesButtonClick: () => alert.hide(DialogStatus.Ok),
    };

    return alert
        .custom(customVm)
        .then(() => confirmIssueForReading(data.Parameters.issueId))
        .then(result => (result ? Promise.resolve() : Promise.reject(new Error('User did not confirm issue reading'))));
}
