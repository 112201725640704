/**
 * The requirejs is needed by external html templates.
 * There is a Sponsorship templates that load old injection.js file which utilises requirejs functionaliy.
 */

import * as req from 'req';

let { require: requireShim, define: defineShim } = req;

const w = window as any;

if (w.require) {
    defineShim = w.define;
    requireShim = w.require;
} else {
    w.define = defineShim;
    w.require = requireShim;
}

export { defineShim, requireShim };
