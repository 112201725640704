export let getResources: (args?: {
    resourceNames?: string[];
    templateNames?: string[];
    locale?: string;
    includeStatic?: boolean;
}) => Promise<{ resources: Record<string, string>; templates: Record<string, string> }>;

export function setGetResourcesService(value: typeof getResources) {
    getResources = value;
}
