import * as ko from 'knockout';
import { isEmpty } from 'lodash';

import { extractCid, extractDateString } from '@pressreader/content-utils';
import { getCurrentSubscription } from 'subscriptions/services';

import provider from 'purchase/nd.purchase.provider';
import ndNavigation from '@pressreader/src/nd.navigation';
import ndConfig from '@pressreader/src/deprecated/nd.config';
import { SubscriptionMode } from 'subscriptions/SubscriptionInfo';
import ndDialog from '@pressreader/src/nd.ui.dialog';
import { DialogStatus } from '@pressreader/src/nd.ui.dialogstatus';
import { alertError, logAndAlertError, logError } from '@pressreader/src/shared/errors/error.handlers';
import { ecommerce } from 'accounting/ecommerce';
import { EcommerceSteps } from 'analytics/ecommerce/models/models';
import { loadExternalAuthenticationProvider } from 'nd.externalauthenticationprovider';
import { fixToLocalURL, getBasePath } from '@pressreader/routing';

/**
 * Enum for product types.
 * @readonly
 * @enum {number}
 */
const productType = {
    subscription: 0,
    issue: 1,
    bundle: 2,
};

const countriesWithRequiredRegion = ['AU', 'CA', 'US'];

function createDialogModel({ requestedContent, purchaseInfo, dialog, isArchive }) {
    const states = {
        main: 0, // default
        confirm: 1,
        allProducts: 2,
    };

    const model = {
        states: states,
        isArchive: isArchive,
        requestedContent: requestedContent,
        currentState: ko.observable(),
        billingInfo: purchaseInfo.BillingInfo,
        products: ko.observableArray(purchaseInfo.Products),
        selectedProduct: ko.observable(),
        basketInfo: ko.observable(null),
        currentSubscriptionMode: purchaseInfo.CurrentSubscriptionMode,
        currentSubscriptionBalance: purchaseInfo.CurrentSubscriptionBalance,
        currentSubscriptionProductId: purchaseInfo.CurrentSubscriptionProductId,
        issueProducts: ko.observableArray(purchaseInfo.Products.filter(current => current.Type === productType.issue)),
        subscriptionProducts: ko.observableArray(purchaseInfo.Products.filter(current => current.Type === productType.subscription)),
        allSubscriptionProducts: ko.observableArray(
            (purchaseInfo.AllProducts || []).filter(current => current.Type === productType.subscription && current.Price && current.Price.Value),
        ),
        isSubmitting: ko.observable(false),
        isBillingInfoValid: ko.observable(purchaseInfo.BillingInfo != null),
        submit: data => {
            if (!data.billingInfo) {
                return;
            }

            const countryCode = data.billingInfo.CountryCode;
            const regionCode = data.billingInfo.RegionCode;

            if (countryCode && countriesWithRequiredRegion.indexOf(countryCode) >= 0 && !regionCode) {
                model.isBillingInfoValid(false);

                alertError({ resourceKey: 'Dialogs.Purchase.Validation.RegionRequiredErrorMessage' });

                return;
            }

            data.isSubmitting(true);

            provider
                .checkoutWithConfirmation({ promoCode: undefined })
                .then(info => {
                    ecommerce.handle(EcommerceSteps.Finish, info.OrderId);
                    dialog.hide(DialogStatus.Ok);
                })
                .catch(() => logAndAlertError({ resourceKey: 'Checkout.DeclinedMessage' }))
                .finally(() => data.isSubmitting(false));
        },
        close: () => {
            dialog.hide(DialogStatus.Cancel);
        },
    };

    model.selectProduct = product => {
        model.selectedProduct(product);
        if (product.id === model.currentSubscriptionProductId) {
            return;
        }
        provider
            .addProductToBasket({
                id: product.Id,
                type: product.Type,
                issue: requestedContent.issueId,
                articleId: requestedContent.articleId,
            })
            .then(() => {
                model.selectedProduct(product);
                model.currentState(states.confirm);

                provider.getPurchaseContext().then(purchaseContext => {
                    model.basketInfo(purchaseContext.Basket);

                    dialog.resize();
                });
            })
            .catch(logError);
    };

    model.backIsVisible = () => model.currentState() !== states.main;
    model.back = () => {
        if (!model.backIsVisible()) {
            return model.close();
        }
        model.currentState(states.main);
        return dialog.resize();
    };
    model.changeProduct = () => {
        model.currentState(states.main);
        dialog.resize();
    };
    model.changeBillingInfo = () => {
        let params = '';

        if (model.requestedContent) {
            if (model.requestedContent.issueId) {
                params += `&RequestedContent.issueId=${model.requestedContent.issueId}`;
            }
            if (model.requestedContent.articelId) {
                params += `&RequestedContent.articleId=${model.requestedContent.articelId}`;
            }
        }

        if (model.selectedProduct()) {
            params += `&preselectedproduct.id=${model.selectedProduct().Id}`;
            params += `&preselectedproduct.type=${model.selectedProduct().Type}`;
        }

        ndNavigation.redirect(`${window.basePath}Accounting/Payment/?GoToStep=2${params}`);
    };
    model.showAllProducts = () => {
        model.currentState(states.allProducts);
        dialog.resize();
    };

    model.currentState.subscribe(newValue => {
        switch (newValue) {
            case states.main:
                ecommerce.handle(EcommerceSteps.SubscriptionPlans);
                break;

            case states.confirm:
                ecommerce.handle(EcommerceSteps.PaymentInformation);
                break;

            default:
                throw new Error(`Current State ${newValue} Not Implemented`);
        }
    });
    model.currentState(states.main);

    return model;
}

function showPurchaseDialog({ requestedContent = {}, purchaseInfo = {}, isArchive } = {}) {
    return new Promise((resolve, reject) => {
        const dialog = ndDialog.createInstance();
        const viewModel = createDialogModel({ dialog, requestedContent, purchaseInfo, isArchive });
        const config = {
            templateName: 'v7.Client.Dialogs.Purchase',
            model: viewModel,
            name: 'PurchaseDialog',
        };
        dialog.onHide((_, result) => {
            if (result.status === DialogStatus.Ok) {
                return resolve(result.context);
            }
            return reject({ userCancelled: true });
        });
        dialog.show(config);
    });
}

function upgradeSubscription(url) {
    const returnUrl = fixToLocalURL(url);
    window.location.assign(returnUrl || `${getBasePath()}Accounting/UpgradeSubscription/`);
    return Promise.reject('UpgradeSubscription page');
}

async function getExternalPurchaseHandler() {
    const extAuthProvider = await loadExternalAuthenticationProvider();
    return extAuthProvider?.purchaseDialogEnabled ? extAuthProvider.showPurchaseDialog : null;
}

export function purchase(requestedContent) {
    const externalAuthEnabled = ndConfig.get('Authentication.EnablePublisherAuthenticationWebService', false);
    let externalUpgradeSubscriptionPage = ndConfig.get('Authentication.ExternalUpgradeSubscriptionPage', null);

    // Custom URL Variables
    if (externalUpgradeSubscriptionPage) {
        externalUpgradeSubscriptionPage = externalUpgradeSubscriptionPage
            .replace('__CID__', extractCid(requestedContent.issueId))
            .replace('__ISSUEDATE__', extractDateString(requestedContent.issueId));
    }

    if (externalAuthEnabled && externalUpgradeSubscriptionPage) {
        return upgradeSubscription(externalUpgradeSubscriptionPage);
    }

    return getExternalPurchaseHandler().then(externalPurchaseHandler => {
        if (typeof externalPurchaseHandler === 'function') {
            return externalPurchaseHandler();
        }
        return getCurrentSubscription().then(subscriptionInfo => {
            if (!subscriptionInfo) {
                return upgradeSubscription(externalUpgradeSubscriptionPage);
            }
            return provider.getPurchaseInfo(requestedContent).then(purchaseInfo => {
                switch (subscriptionInfo.subscriptionMode) {
                    case SubscriptionMode.Bundle:
                        return upgradeSubscription(externalUpgradeSubscriptionPage);
                    case SubscriptionMode.SingleCopy:
                        return showPurchaseDialog({ requestedContent, purchaseInfo, isArchive: false });
                    default: {
                        const canPurchaseAdditionIssues = subscriptionInfo.isAdditionalIssuesAllowed && purchaseInfo.Products.length > 0;

                        return isEmpty(purchaseInfo.CurrentSubscriptionProductId) ||
                            (subscriptionInfo.subscriptionBalance === 0 && !canPurchaseAdditionIssues)
                            ? upgradeSubscription(externalUpgradeSubscriptionPage)
                            : showPurchaseDialog({ requestedContent, purchaseInfo, isArchive: false });
                    }
                }
            });
        });
    });
}

export function purchasearchive(requestedContent) {
    return provider
        .getArchivePurchaseInfo(requestedContent)
        .then(purchaseInfo => showPurchaseDialog({ requestedContent, purchaseInfo, isArchive: true }));
}

export default {
    purchase,
    purchasearchive,
};
