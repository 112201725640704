// eslint-env es6

import ndConfig from '@pressreader/src/deprecated/nd.config';

const defaultSignInDialogTemplateId = 'v7.Client.Dialogs.Signin';
const defaultSignInPageTemplateId = 'v7.Client.SignIn';

/**
 * @typedef ExternalSystemSignInConfiguration
 * @type Object
 * @property {String} name - name of systems
 * @property {String} titleResourceId - id of resource that hold displayable title of the system
 */

/**
 * Gets list of external systems available for signin
 *
 * @returns {Array.<ExternalSystemSignInConfiguration>} list of systems
 */
function getExternalSystems() {
    return ndConfig.get('ui.SignIn.ExternalSystems') || [];
}

/**
 * Gets id of template for signin dialog
 * @returns {String} template id
 */
function getSignInDialogTemplateId() {
    const customTemplate = ndConfig.get('ui.SignIn.customTemplate');
    return customTemplate ? `${defaultSignInDialogTemplateId}.${customTemplate}` : defaultSignInDialogTemplateId;
}

/**
 * Gets id of template for signin dialog
 * @returns {String} template id
 */
function getSignInPageTemplateId(system) {
    // system implicitly specified
    if (system) {
        return `${defaultSignInPageTemplateId}.${system}`;
    }

    const customTemplate = ndConfig.get('ui.SignIn.customTemplate');
    return customTemplate ? `${defaultSignInPageTemplateId}.${customTemplate}` : defaultSignInPageTemplateId;
}

function socialNetworkSignInAllowed() {
    return ndConfig.get('Registration.SocialNetworkSignInAllowed', false);
}

export { getExternalSystems, getSignInDialogTemplateId, getSignInPageTemplateId, socialNetworkSignInAllowed };
