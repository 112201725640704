/* eslint-env es6 */

'use strict';

import LocalizableError from './localizable.error';

/**
 * The HTTP error object.
 * This class is originally derived from Error-object and because of this has several constraints, look at the base class description for details.
 */
class HttpError extends LocalizableError {
    /**
     * @constructor
     * @param {number} status - HTTP response status code.
     * @param {string} responseText - The origin HTTP response content.
     * @param {Object} [responseJSON=null] - The object represents the parsed HTTP-response content (response is interpreted as JSON string).
     * @param {string} [responseJSON.message]
     * @param {string} [responseJSON.resourceKey]
     * @param {string} [responseJSON.StatusText]
     * @param {Object} [responseJSON.Parameters]
     * @param {string} [responseJSON.Parameters.articleId]
     * @param {string} [responseJSON.Parameters.issueId]
     * @param {string} [responseJSON.Parameters.message]
     * @param {string} [responseJSON.Parameters.Text]
     */
    constructor({ status, responseText, responseJSON = null }) {
        const message = (responseJSON && responseJSON.message) || responseText || '';
        const resourceKey = responseJSON && responseJSON.resourceKey;

        super(message, resourceKey, null);

        this.status = status;
        this.responseText = responseText;
        this.responseJSON = responseJSON;
    }
}

export default HttpError;
