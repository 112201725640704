import { ISize } from '@pressreader/geometry';

const maxMobileScreenWidth = 480;

export function getScreenSize(): ISize {
    return { width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth, height: window.innerHeight };
}

export function isMobileScreen() {
    const size = getScreenSize();
    return size.width < maxMobileScreenWidth || size.height < maxMobileScreenWidth;
}
