import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { dispatch, waitForAction } from '@pressreader/appstore';
import { authorizeUser, createEmailExternalProfile } from '@pressreader/authentication-api';
import { logOutDone, logOutRequestAction } from '@pressreader/authentication-core';
import { EXTERNAL_AUTH_ERROR, EXTERNAL_AUTH_TYPE } from '@pressreader/authentication-types';
import { svcAuth } from '@pressreader/services';
import { AuthenticationType } from '@pressreader/src/authentication/authenticationtypes';
import { eventbus } from '@pressreader/src/eventbus/eventbus';
import { authorize as externalSystemsAuthorize } from '@pressreader/src/externalsystems/auth.service';
import { signIn as externalSystemsSignIn } from '@pressreader/src/externalsystems/signin.service';
import { logError } from '@pressreader/src/shared/errors/error.handlers';

import './analytics/eventsubscriber';

import { loadExternalAuthenticationProvider } from 'nd.externalauthenticationprovider';
import ndUser from 'nd.user';
import authProvider from 'authentication/nd.authentication.provider';

import { AccountChangedEvent, SignInFailedEvent, SignInSucceededEvent, SignUpSucceededEvent } from './events/events';
import { authorizeExtAuthStatusMessages } from './authentication.messages.res';

enum AuthenticationMethod {
    SignIn = 'signin',
    SignUp = 'signup',
}

/**
 * @typedef {object} IAuthenticationAnalyticsContext
 */
interface IAuthenticationAnalyticsContext {
    consentToMarketingEmails?: boolean;
    verificationRequired?: boolean;
}
interface IAuthenticationContext {
    method: AuthenticationMethod;
    system: string;

    analytics?: IAuthenticationAnalyticsContext;
}

type onAccountWasChangedSubscriptionData = { event: any; authContext?: IAuthenticationContext };
const onAccountWasChangedSubscription = new Subject<onAccountWasChangedSubscriptionData>();

const userChangedEvents = {
    before: 1,
    after: 2,
};

function reauthorize() {
    svcAuth.initAuthTickets();
    return svcAuth.authorized();
}

async function signOut({ supressReauthorize }: { supressReauthorize?: boolean } = {}) {
    const extAuthProvider = await loadExternalAuthenticationProvider();
    const useExternalLogoutUrl = typeof extAuthProvider?.signOut === 'function' ? extAuthProvider.signOut() : '';

    dispatch(logOutRequestAction());

    try {
        await waitForAction(`${logOutDone}`);
    } catch {
        // Ignore timeout
    }

    if (supressReauthorize) {
        return useExternalLogoutUrl;
    }

    await reauthorize();
}

async function doSignUp(newUser): Promise<{ verificationRequired: boolean }> {
    const signInResult = await authProvider.register(newUser);
    await authorizeUser({ signInResult, enableAutologin: false, authContext: null });
    if (signInResult.SponsorId) {
        await createEmailExternalProfile(newUser.emailAddress, signInResult.SponsorId);
    }
    eventbus.send(new SignUpSucceededEvent({ system: AuthenticationType.Application }));
    return { verificationRequired: !!signInResult.SponsorId };
}

function doSignInExternal({
    system,
    provider,
    enableAutologin,
    isWindowMode,
    returnUrl,
}: {
    system: string;
    provider?: any;
    enableAutologin: any;
    isWindowMode?: boolean;
    returnUrl?: string;
}) {
    const method = provider
        ? externalSystemsAuthorize({ provider: provider, authtype: EXTERNAL_AUTH_TYPE.SIGNUP })
        : externalSystemsSignIn({ system: system, context: { isWindowMode, returnUrl } });
    return method
        .then(result => {
            if (!result) {
                // Sign Up dialog was invoked which authorizes user itself
                return Promise.resolve();
            }

            const { authResult, signInResult } = result;

            return authorizeUser({
                signInResult: signInResult,
                enableAutologin: !!enableAutologin,
                authContext: {
                    system: system || provider,
                    method: authResult && authResult.isNewUser ? AuthenticationMethod.SignUp : AuthenticationMethod.SignIn,
                },
            }).then(() => {
                const action =
                    authResult && authResult.isNewUser
                        ? new SignUpSucceededEvent({ system: AuthenticationType.Social })
                        : new SignInSucceededEvent({ system: AuthenticationType.Social });
                eventbus.send(action);
            });
        })
        .catch(error => {
            if (!error || error.code === EXTERNAL_AUTH_ERROR.CANCELLED) {
                return Promise.reject();
            }

            eventbus.send(new SignInFailedEvent({ system: AuthenticationType.Social }));
            return Promise.reject(error);
        });
}

function signInByKey({ authKey }, enableAutologin) {
    return authProvider
        .signinByKey(authKey)
        .then(signInResult =>
            authorizeUser({
                signInResult: signInResult,
                enableAutologin: !!enableAutologin,
                authContext: null,
            }),
        )
        .then(result => {
            eventbus.send(new SignInSucceededEvent({ system: AuthenticationType.Library }));
            return result;
        })
        .catch(error => {
            eventbus.send(new SignInFailedEvent({ system: AuthenticationType.Library }));
            return Promise.reject(error);
        });
}

function doRecoverPassword({ userName }) {
    return authProvider.recoverPassword(userName);
}

function getSignInLibraries() {
    return authProvider.signInLibraries();
}

/**
 * Forces external sign in procedure.
 * One of parameters should be specified - either system or provider.
 *
 * @param {Object} options - authorization options
 * @param {String} [options.system] - name of external system, like 'facebook'
 * @param {String} [options.provider] - name of external provider, like 'facebook', 'adfs'
 * @returns {Promise.<Object>} - authentication result
 */
function externalSignIn({ system, provider, profile }) {
    if (!system && !provider) {
        throw new Error('Neither system nor provider specified');
    }

    const linkToCurrentAccount = ndUser.isLogin();

    const method = provider
        ? externalSystemsAuthorize({
              provider: provider,
              authtype: EXTERNAL_AUTH_TYPE.SIGNUP,
              context: { linkToCurrentAccount: linkToCurrentAccount },
          })
        : externalSystemsSignIn({
              system: system,
              context: { profile: profile, linkToCurrentAccount: linkToCurrentAccount },
          });

    return method
        .then(result => {
            if (!result) {
                return Promise.resolve();
            }

            const { authResult, signInResult } = result;
            const rememberMe = true;
            return authorizeUser({
                signInResult: signInResult,
                enableAutologin: rememberMe,
                authContext: {
                    system: system || provider,
                    method: authResult && authResult.isNewUser ? AuthenticationMethod.SignUp : AuthenticationMethod.SignIn,
                },
            });
        })
        .catch(error => Promise.reject(error));
}

function authorizeExternalUser(signInResult) {
    if (!signInResult) {
        return Promise.reject(new Error('signInResult not specified'));
    }

    return authProvider.getExternalRequestKey().then(externalRequestKey => {
        return authProvider
            .authorizeExternal({ Key: externalRequestKey.Key, Data: signInResult })
            .then((response = {}) => {
                if (response.Status !== 'Success') {
                    const errorMessage = (authorizeExtAuthStatusMessages[response.Status] || (() => 'Unknown error'))();
                    return Promise.reject(new Error(errorMessage));
                }

                return signInByKey(
                    {
                        authKey: externalRequestKey.Key,
                    },
                    true,
                );
            })
            .catch(error => {
                logError(error);
                return Promise.reject(error);
            });
    });
}

function getSott() {
    return authProvider
        .getSott()
        .then(sott => {
            return sott.Token;
        })
        .catch(error => {
            logError(error);
            return Promise.reject(error);
        });
}

onAccountWasChangedSubscription.pipe(filter(x => x.event === userChangedEvents.after)).subscribe(() => {
    eventbus.send(new AccountChangedEvent());
});

const onBeforeAccountChanged = onAccountWasChangedSubscription.pipe(filter(x => x.event === userChangedEvents.before));
const onAfterAccountChanged = onAccountWasChangedSubscription.pipe(filter(x => x.event === userChangedEvents.after));

export {
    doRecoverPassword,
    reauthorize,
    externalSignIn,
    onBeforeAccountChanged,
    onAfterAccountChanged,
    signOut,
    doSignInExternal,
    signInByKey,
    authorizeExternalUser,
    getSott,
    getSignInLibraries,
    AuthenticationMethod,
    IAuthenticationAnalyticsContext,
    doSignUp,
};
