import { createActionHandler, createReducer } from '@pressreader/appstore';
import { TargetPlatform } from '@pressreader/types';

import { Platform } from '../types';

import { coreModuleLoadDone, legacyPlatformChanged } from './actions';

interface State {
    targetPlatform: TargetPlatform;
    faceliftEnabled: boolean;
    experimentalEnabled?: boolean;
    legacyPlatform: Platform;
}

const initialState: State = {
    targetPlatform: TargetPlatform.Web,

    /** Whether Facelift UI is enabled. */
    faceliftEnabled: false,

    experimentalEnabled: false,

    legacyPlatform: Platform.Web,
};

const reducer = createReducer(
    initialState,
    createActionHandler(coreModuleLoadDone, (state, action) => ({
        ...state,
        targetPlatform: action.payload.targetPlatform,
        faceliftEnabled: action.payload.faceliftEnabled,
        experimentalEnabled: action.payload.experimentalEnabled,
    })),
    createActionHandler(legacyPlatformChanged, (state, { payload }) => ({
        ...state,
        legacyPlatform: payload.platform,
    })),
);

export { State, reducer };
