import * as ko from 'knockout';
import { find } from 'lodash';

import { dispatch } from '@pressreader/appstore';
import { logOutRequestAction } from '@pressreader/authentication-core';
import ndConfig from '@pressreader/src/deprecated/nd.config';
import alert from '@pressreader/src/nd.alert';
import ndUiProgress from '@pressreader/src/nd.ui.progress';
import { logAndAlertError } from '@pressreader/src/shared/errors/error.handlers';

import ndLogger from 'nd.logger';
import res from 'nd.res';
import { getCurrentSubscription } from 'subscriptions/services';

import { GdprConsentType, tryDecomposeConsent } from '../models/gdprcontext';
import { deleteAccount } from '../services/account.service';
import { getCurrentUserConsents, updateCurrentUserConsent, userConsentsUpdates } from '../services/gdpr.service';

class ConsentSectionViewModel {
    termsOfUseGranted: KnockoutObservable<boolean>;
    cookiesGranted: KnockoutObservable<boolean>;
    isAccountDeleteAllowed: KnockoutComputed<Promise<boolean>>;
    consentsUpdatesSubscription;

    constructor() {
        this.termsOfUseGranted = ko.observable(false);
        this.cookiesGranted = ko.observable(false);

        this.consentsUpdatesSubscription = userConsentsUpdates.subscribe(() => {
            this.load().catch(ndLogger.error);
        });

        this.isAccountDeleteAllowed = ko
            .pureComputed(() => ndConfig.loaded().then(() => ndConfig.get('features.account.delete', false)))
            .extend({ async: true });

        this.load().catch(ndLogger.error);
    }

    deleteAccount() {
        return getCurrentSubscription()
            .then(subscription => {
                if (!subscription || subscription.subscriptionMonthlyFee === 0) {
                    return Promise.resolve();
                }

                // Notify user about non zero balance.
                return res
                    .valAsync('Accounting.Management.Account.Delete.NonZeroBalanceWarning')
                    .then(message => alert().confirm(message, null, null, null, 'html'));
            })
            .then(() =>
                res
                    .valAsync('Accounting.Management.Account.Delete.ConfirmationMessage')
                    .then(message => alert().confirm(message, null, null, null, 'html')),
            )
            .then(() =>
                res
                    .valAsync('Accounting.Management.Account.Delete.DoubleCheckConfirmationMessage')
                    .then(message => alert().confirm(message, null, null, null, 'html')),
            )
            .then(ndUiProgress.show)
            .then(() => deleteAccount())
            .then(() => this.signOut())
            .catch(logAndAlertError)
            .finally(ndUiProgress.hide);
    }

    public revokeCookiesConsent(): void {
        res.valAsync('Accounting.Management.Consent.Cookies.Revoke.ConfirmationMessage')
            .then(message => alert().confirm(message))
            .then(ndUiProgress.show)
            .then(() => updateCurrentUserConsent(GdprConsentType.Cookies, false))
            .then(() => this.signOut())
            .catch(logAndAlertError)
            .finally(ndUiProgress.hide);
    }

    public revokeUserAgreement(): void {
        res.valAsync('Accounting.Management.Consent.Agreement.Revoke.ConfirmationMessage')
            .then(message => alert().confirm(message))
            .then(ndUiProgress.show)
            .then(() => Promise.all(tryDecomposeConsent(GdprConsentType.TermsOfUse).map(x => updateCurrentUserConsent(x, false))))
            .then(() => this.signOut())
            .catch(logAndAlertError)
            .finally(ndUiProgress.hide);
    }

    private load(): Promise<void> {
        const consentTypeAttribute = 'consentType';
        const isGrantedAttribute = 'isGranted';

        return getCurrentUserConsents().then(consents => {
            const termsOfUse = find(consents, c => c[consentTypeAttribute] === GdprConsentType.TermsOfUse);
            const cookies = find(consents, c => c[consentTypeAttribute] === GdprConsentType.Cookies);

            this.termsOfUseGranted(termsOfUse && termsOfUse[isGrantedAttribute]);
            this.cookiesGranted(cookies && cookies[isGrantedAttribute]);
        });
    }

    private signOut(): void {
        dispatch(logOutRequestAction());
    }
}

export default ConsentSectionViewModel;
