/**
 * A workaround that allows creating custom errors.
 * See support for new.target at https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html.
 * Usage: class MyError extends CustomError { feel free to add new properties and methods here }
 * ...
 * throw new MyError();
 * ...
 * if (error instanceof MyError) {...}
 */
export class CustomError extends Error {
    constructor(message?: string) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
    }
}
