/* eslint-disable no-undef */
/* eslint-env es6 */

import * as $ from 'jquery';
import * as ko from 'knockout';
import * as _ from 'lodash';
import ndKo from '@pressreader/src/nd.ko';
import ndGestures from 'nd.gestures';
import { Modernizr } from '@pressreader/modernizr';

/**
 * ClickAndTouch custom binding
 **/
function clickAndTouchBindingHandler() {
    return {
        init: function init(element, valueAccessor, allBindingAccessor, viewModel, bindingContext) {
            const $elem = $(element);
            const listenedEvents = Modernizr.touch ? 'singleTapConfirmed singleTap' : 'click';

            const action = function onSingleTap(evt) {
                const data = bindingContext.$data;

                // in case of custom _Touch event
                // pass original event to the handlers
                // in order to keep IDOMEvent interface that's expected by event handlers
                const originalEvent = _.isFunction(evt.lastEvent) ? evt.lastEvent() : evt;

                const result = valueAccessor().call(viewModel, data, originalEvent);
                ndKo.event.clickAndTouch.handle(evt);

                // tell nd.gestures to stop calling handlers
                // if this one stopped propagation.
                if (originalEvent.isPropagationStopped() || originalEvent.isImmediatePropagationStopped() || !result) {
                    return false;
                }

                return true;
            };

            if (Modernizr.touch) {
                ndGestures.bind($elem, {
                    singleTap: action,
                });
            }

            // some panels may trigger singleTapConfirmed event directly
            // w/o using nd.gestures
            // example: scrollZones
            $elem.on(listenedEvents, action);

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                if (Modernizr.touch) {
                    ndGestures.unbind($elem);
                }
                $elem.off(listenedEvents);
            });
        },
    };
}

// registering click and touch binding handler.
ko.bindingHandlers.clickAndTouch = clickAndTouchBindingHandler();
