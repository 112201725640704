import { createActionHandler, createReducer, OperationStatus } from '@pressreader/appstore';

import { ILegacyTemplate } from '../types';

import { resourcesLoadBegan, resourcesLoadDone, resourcesLoadFailed } from './actions';

interface IState {
    resources: Record<string, string>;
    templates: Record<string, ILegacyTemplate>;
    status: OperationStatus;
}

const initialState: IState = {
    resources: {},
    templates: {},
    status: OperationStatus.None,
};

const reducer = createReducer(
    initialState,
    createActionHandler(resourcesLoadBegan, state => ({
        ...state,
        status: OperationStatus.Pending,
    })),
    createActionHandler(resourcesLoadDone, (state, action) => ({
        ...state,
        resources: action.payload.resources,
        templates: action.payload.templates,
        status: OperationStatus.Done,
    })),
    createActionHandler(resourcesLoadFailed, state => ({
        ...state,
        status: OperationStatus.Failed,
    })),
);

export { IState, reducer };
