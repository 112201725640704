/* eslint-disable @typescript-eslint/no-empty-function */
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import config from '@pressreader/src/deprecated/nd.config';
import { DialogStatus } from '@pressreader/src/nd.ui.dialogstatus';
import { loadExternalScript } from '@pressreader/utils';

import ndViewerApp from 'nd.viewer.app';

import { IIdentity } from './adapter';
import identityFactory from './identity.factory';
import { ScreensetType } from './screenSets';

const SET_CUSTOM_VARIABLE = 'setCustomVariable';
const OFFER_PARAMS = 'offer_params';
const JWT_KEY = 'jwt_token';

interface IPianoTerm {
    aid: string;
    term_id: string;
    resource?: object;
    name?: string;
    description?: string;
    type?: string;
}

interface IPianoConversion {
    aid: string;
    term?: IPianoTerm;
    term_conversion_id?: string;
    type?: string;
}

interface IPianoApiResponse {
    code: number;
    count?: number;
    data?: object[];
    limit: number;
    offset: number;
    total: number;
    conversions?: IPianoConversion[];
}

const pianoScreensets = {
    [ScreensetType.signIn]: 'login',
};

export class Piano implements IIdentity {
    private _enabled = false;
    private _experience = null;

    private loginSuccess = new Subject<any>();
    private modalClosed = new Subject<DialogStatus>();

    private identityAdapter?: IIdentity = null;

    private pianoEventHandlers: { [key: string]: (...args: any[]) => void } = {
        loggedIn: this.loggedIn.bind(this),
        closedCallback: this.closedCallback.bind(this),
    };

    private pianoEndpoints = {
        Janrain: {
            verify: (accountInfo: any) =>
                this.pianoCallApi('/anon/janrain/verifyAccountInfo', {
                    janrain_capture_token: accountInfo.accessToken,
                    url: window.location.href,
                }),
        },
        Gigya: {
            verify: (accountInfo: any) =>
                this.pianoCallApi('/anon/gigya/verifyAccountInfo', {
                    uid: accountInfo.UID,
                    signature: accountInfo.UIDSignature,
                    signature_timestamp: accountInfo.signatureTimestamp,
                    url: window.location.href,
                }),
        },
        LoginRadius: {
            verify: async (accountInfo: any) => {
                localStorage.setItem(JWT_KEY, accountInfo.jwttoken);
                this.setExternalJWT();

                // Dummy call to force Piano update their database...
                try {
                    await this.pianoCallApi('/anon/user/get', {
                        uid: accountInfo.UID,
                        user_token: accountInfo.jwttoken,
                    });
                } catch {
                    // Do nothing
                }
                // Dummy call to force Piano update their database...

                return Promise.resolve({
                    access: {
                        user_token: accountInfo.jwttoken,
                        user: {
                            uid: accountInfo?.Profile?.Uid,
                            email: accountInfo?.Profile?.Email[0].Value,
                            firstName: accountInfo?.Profile?.FirstName,
                            lastName: accountInfo?.Profile?.LastName,
                        },
                    },
                });
            },
        },
    };

    constructor() {
        this.showPurchaseDialog = this.showPurchaseDialog.bind(this);
        this.signIn = this.signIn.bind(this);
    }

    public async load() {
        await config.loaded();

        const pianoConfig = config.get('Authentication.Piano');
        if (!pianoConfig || !pianoConfig.enabled) {
            return Promise.reject();
        }
        this._enabled = true;

        if (!window.tp?.pianoId) {
            try {
                const { experience } = pianoConfig;
                if (experience && experience.enabled) {
                    this._experience = experience;
                    await loadExternalScript(`${experience.url}?aid=${experience.aid}`);
                } else {
                    await loadExternalScript(pianoConfig.url);
                }

                await this.initialize(pianoConfig);

                this.setExternalJWT();

                if (pianoConfig.identityProvider) {
                    await this.initializeIdentityProvider(pianoConfig.identityProvider);
                }
            } catch (error) {
                return Promise.reject();
            }
        }

        return Promise.resolve();
    }

    get onLoginSuccess() {
        return this.loginSuccess.asObservable();
    }

    get onModalClosed() {
        return this.modalClosed.asObservable();
    }

    get enabled() {
        return this._enabled;
    }

    get purchaseDialogEnabled() {
        return this._experience !== null && this._experience.purchase !== null;
    }

    public signOut() {
        window.tp.pianoId.logout();
    }

    public signIn() {
        this.showScreenSet(ScreensetType.signIn);
    }

    public closeModal() {}

    public showScreenSet(screenType: ScreensetType) {
        const screen = pianoScreensets[screenType];
        if (!screen) {
            throw new Error(`screen ${screenType} is not defined.`);
        }

        window.tp.pianoId.show({
            screen: screen,
        });
    }

    public showInlineScreenSet(screenType: ScreensetType, containerSelector: string) {
        const screen = pianoScreensets[screenType];
        if (!screen) {
            throw new Error(`screen ${screenType} is not defined.`);
        }

        window.tp.pianoId.show({
            displayMode: 'inline',
            containerSelector: containerSelector,
            screen: screen,
        });
    }

    public showPurchaseDialog() {
        return new Promise<void>((resolve, reject) => {
            if (!this._experience || !this._experience.purchase) {
                reject();
            }

            const { purchase } = this._experience;
            for (const key in purchase || {}) {
                window.tp.push([key, purchase[key]]);
            }
            window.tp.push([SET_CUSTOM_VARIABLE, 'cid', ndViewerApp.cid()]);

            window.tp.push([
                'addHandler',
                'checkoutClose',
                (event: any) => {
                    switch (event.state) {
                        case 'checkoutCompleted':
                        case 'alreadyHasAccess':
                        case 'voucherRedemptionCompleted':
                            resolve();
                            break;
                        case 'close':
                            reject({ userCancelled: true });
                    }
                },
            ]);

            window.tp.push([
                'addHandler',
                'loginRequired',
                (params: any) => {
                    localStorage.setItem(OFFER_PARAMS, JSON.stringify(params));

                    this.loginSuccess.pipe(first()).subscribe(_ => {
                        let offerParams = localStorage.getItem(OFFER_PARAMS);
                        if (offerParams) {
                            localStorage.removeItem(OFFER_PARAMS);
                            offerParams = JSON.parse(offerParams);
                            window.tp.offer.startCheckout(offerParams);
                        }
                    });

                    window.tp.offer.close();
                    this.signIn();
                },
            ]);

            window.tp.experience.execute();
        });
    }

    private initialize({ Global }) {
        return new Promise<void>(resolve => {
            for (const key in Global || {}) {
                if (typeof Global[key] !== 'object') {
                    window.tp.push([key, Global[key]]);
                }
            }

            for (const key in Global.Id || {}) {
                this.pianoEventHandlers[key] = Global.Id[key];
            }

            window.tp.push([
                'addHandler',
                'checkoutComplete',
                (conversion: any) => {
                    this.loginSuccess.next({
                        token: window.tp.user.getProvider().getToken(),
                        user: {
                            uid: conversion.uid,
                            email: conversion.email,
                        },
                    });
                },
            ]);
            window.tp.push([
                'init',
                () => {
                    window.tp.pianoId.init(this.pianoEventHandlers);
                    resolve();
                },
            ]);
        });
    }

    private async initializeIdentityProvider(provider: string) {
        this.identityAdapter = identityFactory.create(provider);
        await this.identityAdapter.load();

        // override default identity functions
        this.signIn = this.identityAdapter.signIn.bind(this.identityAdapter);
        this.signOut = this.identityAdapter.signOut.bind(this.identityAdapter);
        this.closeModal = this.identityAdapter.closeModal.bind(this.identityAdapter);
        this.showScreenSet = this.identityAdapter.showScreenSet.bind(this.identityAdapter);
        this.showInlineScreenSet = this.identityAdapter.showInlineScreenSet.bind(this.identityAdapter);

        this.identityAdapter.onModalClosed.subscribe(dialogStatus => this.closedCallback.bind(this, dialogStatus)());
        this.identityAdapter.onLoginSuccess.subscribe(accountInfo => this.verifyAccountInfo(provider, accountInfo));
        this.identityAdapter.onLogoutSuccess?.subscribe(this.removeExternalJWT);
    }

    private closedCallback(dialogStatus: DialogStatus) {
        this.modalClosed.next(dialogStatus);
    }

    private loggedIn(result: any) {
        this.loginSuccess.next(result);
    }

    private async verifyAccountInfo(provider: string, accountInfo: any) {
        const verify = this.pianoEndpoints[provider].verify;
        const pianoResponse: any = await verify(accountInfo);
        if (pianoResponse.access) {
            this.loginSuccess.next({
                token: pianoResponse.access.user_token,
                user: {
                    uid: pianoResponse.access.user.uid,
                    email: pianoResponse.access.user.email,
                    firstName: pianoResponse.access.user.first_name,
                    lastName: pianoResponse.access.user.last_name,
                },
            });
        }
    }

    private async pianoCallApi(endpoint: string, data?: any): Promise<IPianoApiResponse> {
        return new Promise((resolve, reject) => {
            try {
                window.tp.api.callApi(endpoint, data, (res: IPianoApiResponse) => {
                    if (res.code > 0) {
                        reject(new Error(`Piano API failure: ${JSON.stringify(res)}`));
                    } else {
                        resolve(res);
                    }
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    private setExternalJWT() {
        const jwt = localStorage.getItem(JWT_KEY);
        if (jwt) {
            window.tp.push([
                'init',
                () => {
                    window.tp.push(['setExternalJWT', jwt]);
                },
            ]);
        }
    }

    private removeExternalJWT() {
        if (localStorage.getItem(JWT_KEY)) {
            localStorage.removeItem(JWT_KEY);
        }
        window.tp.push(['setExternalJWT', null]);
    }
}
