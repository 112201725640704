/* eslint-env es6 */
/* eslint-disable */
import * as $ from 'jquery';
import * as _ from 'lodash';

import { extractDateString } from '@pressreader/content-utils';
import { ViewName } from '@pressreader/navigation-types';
import { getPictureUrlByIssueForScale, getThumbUrlForScale } from '@pressreader/images';

import { isRestricted } from 'nd.contentrestrictions';
import '@pressreader/src/deprecated/nd.config';
import 'nd.imagesource';
import { Deferred } from '@pressreader/src/promise/deferred';

$.nd.viewer = $.nd.viewer || {};

var _uiIsRtl = true;

var ViewerApp = ($.nd.viewer.app = (function () {
    var _provider = {
        loadIssue: function (cid, issue, pageNumber) {},
        requestPageConfirmation: function (page) {},
        showPage: function (pageNumber) {},
        getPageInfo: function (pageNumber) {},
    };

    var _api = {};

    // init events
    $.nd.extendObjectWithEvents(_api, 'issueLoading,issueLoaded,pageChanged,pageLoaded,pageKeysLoaded,bgColorChange,layoutLoaded');
    $.nd.extendObjectWithProperties(
        _api,
        'id,cid,issue,issueDate,issueEngine,page,bgcolor,heights,magnifierPageSizes,enableSmart,layoutAvailable,partialLayoutAvailable,layoutVersion,layoutValidForSmartFlow,expungeVersion,isExternal,calendarCache,contentName,bookmark,contentIsRtl,contentLanguage,contentCulture,newspaperInfo',
        true,
        true,
    );
    $.nd.extendObjectWithProperties(_api, 'articles', true, true);
    $.nd.extendObjectWithProperties(_api, 'coudlIssueBeRead', true, true, true);
    $.nd.extendObjectWithProperties(_api, 'sponsoredBy', true, true, true);
    $.nd.extendObjectWithProperties(_api, 'subscriptionsInfo', true, true);
    $.nd.extendObjectWithProperties(_api, 'issueRestrictions', true, true);

    _api.init = function () {
        return this;
    };
    _api.setProvider = function (provider) {
        _provider = provider;
    };
    _api.getProvider = function () {
        return _provider;
    };
    _api.initAndLoad = function (data) {
        var self = _api;
        $.each(data, function (key, value) {
            if (typeof self[key] === 'function') self[key](value);
            else if (typeof self[key] !== 'undefined') self[key] = value;
        });
        self.onIssueLoading();
        self.onIssueLoaded();
    };
    _api.loadIssue = function (config) {
        var self = _api;
        _provider.loadIssue(self.cid(), self.issue());
    };
    _api.show = function (config) {
        if (config.viewName) {
            _api.isExternal(config.viewName === ViewName.External);
        }

        this.page(config.page);

        if (config.issue) {
            if (this.issue() != config.issue) _provider.loadIssue(config.cid, config.issue, config.page, config.bookmark);
            else this.showPage(config.page);
        } else if (config.cid) {
            if (this.cid() !== config.cid || (this.issue() && extractDateString(this.issue()) !== config.issueDate)) {
                _provider.loadIssue(config.cid, config.issue, config.page, config.bookmark, config.issueDate);
            } else {
                this.showPage(config.page);
            }
        } else if (config.page) this.showPage(config.page);
        else if (config.bookmark) {
            _provider.loadIssue(config.cid, config.issue, config.page, config.bookmark);
        }
        return this;
    };
    _api.requestPageConfirmation = function (page) {
        return _provider.requestPageConfirmation(page);
    };
    _api.showPage = function (pageNumber) {
        if (this.issue()) {
            _provider.showPage(parseInt(pageNumber));
        }
        return this;
    };
    _api.getPageInfo = function (pageNumber) {
        return _provider.getPageInfo(parseInt(pageNumber));
    };
    _api.getLeftPageNumber = function (page) {
        page = page || this.page();
        return page == 1 ? page : page - (page % 2);
    };
    _api.getRightPageNumber = function (page) {
        page = page || this.page();
        return page - (page % 2) + 1;
    };
    _api.onIssueLoading = function () {
        this.issueLoading();
        return this;
    };
    _api.onIssueLoaded = function () {
        this.issueLoaded();
        this.pageChanged();
        //this.fireIssueLoaded();
        //this.firePageChanged();
        return this;
    };
    _api.onPagesLoaded = function () {
        //return this.firePageLoaded();
        //if (this._page)
        //    ViewerPages.gotoToPage(this._page);;
        return this.pageLoaded();
    };
    _api.onPageKeysLoaded = function () {
        return this.pageKeysLoaded();
    };
    //
    _api.gotoToPage = function (page, articleId) {
        ViewerPages.gotoToPage(page, articleId);
        return this;
    };
    _api.zooms = function () {
        return _zooms;
    };
    _api.uiIsRtl = function () {
        return _uiIsRtl;
    };
    _api.isSingleLayer = function () {
        return this.issueEngine() === 1;
    };
    _api.hasLayout = function () {
        return this.articles() && this.articles().length > 0;
    };
    _api.isIssueRestrictedBy = function (restriction) {
        return _.get(this.issueRestrictions(), restriction, false);
    };
    _api.hasVisiblePages = function () {
        return _.some(this.pages.getPages(), function (page) {
            return !page.isRestrictedMode();
        });
    };
    _api.hasRestriction = function (restrictionType) {
        if (this.issue()) {
            return isRestricted(restrictionType, null, this.issue());
        }

        return Promise.resolve(false);
    };

    var _zooms = {
        switchToDefault: function () {
            return this.current(this.defaultZoom());
        },
        switchToMDefault: function () {
            return this.current(this.defaultMZoom());
        },
        defaultZoom: function () {
            return this._defaultZoom;
        },
        defaultMZoom: function () {
            return this._defaultMZoom;
        },
        zooms: function () {
            return this._zooms;
        },
        current: function (zoom) {
            if (arguments.length == 0) return this._current || this.defaultZoom();
            if (this._current) this._current.isCurrent = false;
            this._current = zoom || this.defaultZoom();
            if (this._current) this._current.isCurrent = true;
            return this;
        },
        next: function () {
            return this._current ? this._zooms[this._current._idx + 1] : null;
        },
        prev: function () {
            return this._current ? this._zooms[this._current._idx - 1] : null;
        },
        _indexOf: function (zoom, zooms) {
            for (var i = 0; i < zooms.length; i++) {
                if (zooms[i].maxHeight == zoom.maxHeight) return i;
            }
            return 0;
        },
        findZoom: function (zoomScale, useAllZooms, curr) {
            curr = curr || this._current;
            var zooms = useAllZooms ? this._all_zooms : this._zooms;
            if (curr) {
                var maxHeight = curr.maxHeight * zoomScale;
                var incr = zoomScale > 1 ? 1 : -1;
                var idx = this._indexOf(curr, zooms) + incr;
                var next = zooms[idx];
                if (next) {
                    var diff = Math.abs(maxHeight - next.maxHeight);
                    while (next) {
                        idx += incr;
                        var _next = zooms[idx];
                        if (!_next) return next;
                        var _diff = Math.abs(maxHeight - _next.maxHeight);
                        if (_diff > diff) return next;
                        next = _next;
                        diff = _diff;
                    }
                    return next || curr;
                }
            }
            return curr;
        },
        _comp: function (i1, i2) {
            return i1 > i2 ? 1 : i1 < i2 ? -1 : 0;
        },
        compareZooms: function (z1, z2) {
            if (z1 && z2) {
                if (z1 != z2) {
                    if (z1.isMZoom) {
                        if (z2.isMZoom) return this._comp(z1.zoomIdx, z2.zoomIdx); // both are magnifier zoom
                        return 1; // first one is magnifier, the second is not, magnifier is always bigger
                    }
                    if (z2.isMZoom) return -1; // second one is magnifier, the first is not, magnifier is always bigger
                    // both zooms are not magnifier
                    // could compare maxHeight????
                    return this._comp(z1.maxHeight, z2.maxHeight);
                }
                return 0;
            }
            return undefined;
        },
        maxZoom: function () {
            return this._zooms[this._zooms.length - 1];
        },
        _updateZooms: function (w, h) {
            var heights = ViewerApp.heights(),
                msizes = ViewerApp.magnifierPageSizes();
            var page = ViewerPages.firstPage();

            w = Math.floor(w);
            h = Math.floor(h);

            var defaultZoom = { isDefaultZoom: true, maxWidth: w, maxHeight: h, _idx: 0, fitToHeight: true, disableRestrictedScales: true };
            var zooms = [];
            var all_zooms = [];
            zooms.push(defaultZoom);
            all_zooms.push(defaultZoom);

            var lastScale = page.calculateScale(w, h, true);
            var maxScale = page.calculateMagnifierScale(0);
            // scale diff between magnifier scales
            var scaleDiff = page.calculateMagnifierScale(1) / page.calculateMagnifierScale(0);
            //normalize scaleDiff
            scaleDiff = (maxScale - lastScale) / Math.ceil((maxScale - lastScale) / scaleDiff);

            var pageHeight = page.height();
            for (var i = 0; i < heights.length; i++) {
                var _h = heights[i];
                if (_h <= h) continue;
                var _scale = (100 * _h) / pageHeight;
                if (_scale >= maxScale) break;
                var zoom = { maxWidth: 0, maxHeight: _h, _idx: zooms.length };
                all_zooms.push(zoom);
                if (_scale / lastScale >= scaleDiff && maxScale / _scale >= scaleDiff) {
                    zooms.push(zoom);
                    lastScale = _scale;
                }
            }

            for (var i = 0; i < msizes.length; i++) {
                var zoom = { zoomIdx: i, isDefaultMZoom: 1 === i, isMZoom: true, _idx: zooms.length, maxHeight: msizes[i].H };
                zooms.push(zoom);
                all_zooms.push(zoom);
            }

            for (var i = 0; i < zooms.length; i++) {
                var z = zooms[i];
                if (z.isDefaultZoom) this._defaultZoom = z;
                else if (z.isDefaultMZoom) this._defaultMZoom = z;
            }

            this._zooms = zooms;
            this._all_zooms = all_zooms;

            if (!this._defaultZoom) {
                this._defaultZoom = zooms[0];
                this._defaultZoom.isDefaultZoom = true;
            }
            if (!this._defaultMZoom) {
                this._defaultMZoom = zooms[zooms.length - 1];
                this._defaultMZoom.isDefaultMZoom = true;
            }

            var curr;
            if (this._current) {
                // make sure we check all zooms
                var zooms = all_zooms;

                if (this._current.isDefaultZoom) curr = this._defaultZoom;
                else if (this._current.isDefaultMZoom) curr = this._defaultMZoom;
                else if (this._current.isMZoom) {
                    for (var i = zooms.length - 1; i >= 0; i--) {
                        if (zooms[i].zoomIdx == this._current.zoomIdx) {
                            curr = zooms[i];
                            break;
                        }
                    }
                    if (!curr) curr = this._defaultMZoom;
                } else {
                    var _last;
                    for (var i = 0; i < zooms.length; i++) {
                        if (zooms[i].maxHeight >= this._current.maxHeight) {
                            curr = zooms[i];
                            break;
                        }
                        if (zooms[i].isMZoom) break;
                        _last = zooms[i];
                    }
                    if (!curr) curr = _last || this._defaultZoom;
                }
            }

            return this.current(curr || this._defaultZoom);
        },
    };

    return _api;
})()); // End ViewerApp

var PageTypes = {
    Single: 1,
    DoubleLeft: 2,
    DoubleRight: 3,
};

function viewOrSelf(page, viewName) {
    return viewName !== undefined && page ? page[viewName]() : page;
}

function ViewerPage(pages, idx) {
    this._pages = pages;
    this._idx = idx;
    this._views = [];

    this.isInited(false);
}
ViewerPage.prototype = ViewerPage.fn = {
    disable: function () {
        return this.pageInfo(null).isActive(false).isInited(false);
    },
    setPageType: function (pageType, isLast) {
        this.isSpread(pageType != PageTypes.Single)
            .isSpreadLeft(pageType == PageTypes.DoubleLeft)
            .isSpreadRight(pageType == PageTypes.DoubleRight);
        this.pageType(pageType)
            .isFirst(this._idx == 0)
            .isLast(isLast);
        //this.isInited(info ? true : false);
        if (this.isLast()) {
            this._nextPage = null;
        }
        // these fields depends on RTL which could changed
        this._leftPage = this._rightPage = null;
        return this;
    },
    pageInfo: function (info, pageType, isLast) {
        if (arguments.length == 0) return this._info;
        this._info = info;
        if (arguments.length == 3) {
            this.setPageType(pageType, isLast);
        }
        return this;
    },
    bgcolor: function () {
        var c = ViewerApp.bgcolor() || '#fff';
        if (c.length > 0 && c.charAt(0) != '#') c = '#' + c;
        return c;
    },
    isSingleLayer: function () {
        return ViewerApp.isSingleLayer();
    },
    addView: function (name, view) {
        if (view) {
            this._views.push(view);
            if (name)
                this[name] = function () {
                    return view;
                };
        }
        return this;
    },
    leftPageIfSpread: function (viewName) {
        return viewOrSelf(this._isSpreadRight ? this.leftPage() : this, viewName);
    },
    rightPageIfSpread: function (viewName) {
        return viewOrSelf(this._isSpreadLeft ? this.rightPage() : this, viewName);
    },
    otherSpread: function (viewName) {
        return viewOrSelf(this._isSpreadLeft ? this.nextPage() : this._isSpreadRight ? this.prevPage() : null, viewName);
    },
    nextPage: function (viewName) {
        var n = this._nextPage;
        if (!n) n = this._nextPage = this._pages.nextPage(this);
        return viewOrSelf(n, viewName);
    },
    nextSpread: function (viewName) {
        var n = this.nextPage();
        if (n && n._isSpreadRight) n = n.nextPage();
        return viewOrSelf(n, viewName);
    },
    prevPage: function (viewName) {
        var n = this._prevPage;
        if (!n) n = this._prevPage = this._pages.prevPage(this);
        return viewOrSelf(n, viewName);
    },
    leftPage: function (viewName) {
        var n = this._leftPage;
        if (!n) n = this._leftPage = this._pages.leftPage(this);
        return viewOrSelf(n, viewName);
    },
    rightPage: function (viewName) {
        var n = this._rightPage;
        if (!n) n = this._rightPage = this._pages.rightPage(this);
        return viewOrSelf(n, viewName);
    },
    calculateZoomScale: function (zoom, allowRestrictedScales) {
        return zoom.isMZoom
            ? this.calculateMagnifierScale(zoom.zoomIdx)
            : this.calculateScale(zoom.maxWidth, zoom.maxHeight, allowRestrictedScales || !zoom.disableRestrictedScales);
    },
    getZoomInfo: function (zoom) {
        var allowRestrictedScales = false;

        if (zoom.isDefaultZoom) {
            if (this.isPotentiallyRestrictedMode()) {
                // allow restricted scale if page has tint
                // so image will be upscaled
                allowRestrictedScales = true;
            } else if (this.hasPreviewKey()) {
                // or if we have preview key
                allowRestrictedScales = true;
            }
        }

        var scale = this.calculateZoomScale(zoom, allowRestrictedScales);
        var confirmRequired = this.confirmRequiredForZoom(zoom, scale);

        if (confirmRequired && allowRestrictedScales) {
            if (this.hasPreviewKey()) {
                confirmRequired = false;
                var usePreviewKey = true;
            }
        }
        var size = this.calculateSize(scale);

        return {
            zoom: zoom,
            scale: scale,
            size: size,
            zoomMode: zoom.isMZoom === true,
            confirmRequired: confirmRequired,
            usePreviewKey: usePreviewKey,
        };
    },
    calculateScale: function (maxWidth, maxHeight, enableRestrictedScale) {
        // todo: replace heights with pageSizes, it contains more scales
        var maxScale = 100000;
        var page = this;
        if (!maxWidth || maxWidth < 0) maxWidth = 100000;
        if (!maxHeight || maxHeight < 0) maxHeight = 100000;
        if (page.maxUnrestrictedScale() && !page.key() && !enableRestrictedScale) maxScale = page.maxUnrestrictedScale();

        var pageWidth = page.width();
        var pageHeight = page.height();

        var hs = ViewerApp.heights();
        for (var i = hs.length - 1; i >= 0; i--) {
            var h = hs[i];
            if (h <= maxHeight) {
                var scale = Math.floor((100 * h) / pageHeight);
                if (scale <= maxScale && (maxWidth >= 100000 || Math.floor((pageWidth * scale) / 100) <= maxWidth)) return scale;
            }
        }
        return Math.floor((100 * hs[0]) / pageHeight);
    },
    calculateThumbScale: function (width, height, withRestrictions = false) {
        if (!width && !height) {
            return 0;
        }
        const page = this;
        const pageWidth = page.width();
        const pageHeight = page.height();
        const pageInfo = page.pageInfo();
        const maxSize = pageInfo.maxUnrestrictedPageSize;
        const pageSizes = pageInfo.pageSizes;

        if (page.pageNumber() === 1 || !withRestrictions || !maxSize || (!maxSize.W && !maxSize.H) || !pageSizes || !pageSizes.length) {
            // Assuming there's no restrictions for the front page.
            if (width) {
                return Math.floor((width * 100) / pageWidth);
            } else if (height) {
                return Math.floor((height * 100) / pageHeight);
            }
        }

        let size;
        pageSizes.forEach(s => {
            if (!s.W && !s.H) {
                return;
            }
            let w;
            let h;
            if (s.W) {
                w = s.W;
                h = (w * pageHeight) / pageWidth;
            } else {
                h = s.H;
                w = (h * pageWidth) / pageHeight;
            }
            if ((maxSize.W && w > maxSize.W) || (maxSize.H && h > maxSize.H)) {
                return;
            }
            if (
                !size ||
                (width && Math.abs(width - w) < Math.abs(width - size.w) && w > size.w) ||
                (height && Math.abs(height - h) < Math.abs(height - size.h) && h > size.h)
            ) {
                size = { w, h };
            }
        });
        return size ? Math.floor((size.w * 100) / pageWidth) : 0;
    },
    calculateMagnifierScale: function (zoomIdx) {
        var zoomSize = ViewerApp.magnifierPageSizes()[zoomIdx];
        return zoomSize ? Math.floor(Math.min((zoomSize.W * 100) / this.width(), (zoomSize.H * 100) / this.height())) : 0;
    },
    setKeyInfo: function (key, confirmationRequired) {
        if (confirmationRequired) {
            this.previewKey(key);
            this.key(null);
        } else {
            this.previewKey(null);
            this.key(key);
        }
    },
    hasKey: function () {
        return typeof this.key() === 'string';
    },
    hasPreviewKey: function () {
        return typeof this.previewKey() === 'string';
    },
    confirmRequired: function (scale) {
        var maxScale = this.maxUnrestrictedScale();
        return !(maxScale <= 0 || scale <= maxScale || this.hasKey());
    },
    confirmRequiredForZoom: function (zoom, scale /*optional*/) {
        if (!zoom.isDefaultZoom && this.isPotentiallyRestrictedMode()) {
            // PotentiallyRestrictedMode has a tint,
            // so force request confirmation if the user is trying to zoom even it is unrestricted
            return true;
        }
        return this.confirmRequired(scale || this.calculateZoomScale(zoom));
    },
    isRestrictedMode: function () {
        var hasAnyKey = this.hasKey() || this.hasPreviewKey();
        // key is required to show page
        return !hasAnyKey;
    },
    isPotentiallyRestrictedMode: function () {
        if (ViewerApp.coudlIssueBeRead()) {
            return false;
        }
        return this.isRestrictedMode();
    },
    calculateSize: function (scale) {
        // for thumbs the floor is being used??
        // for large images the round is used
        return { width: Math.round((scale * this.width()) / 100), height: Math.round((scale * this.height()) / 100) };
    },
    buildUrl: function (scale, layer, supportRetina, zoomInfo /*optional*/) {
        var key = this.key(),
            ver = this.ver(),
            maxScale = this.maxUnrestrictedScale();

        if (zoomInfo && zoomInfo.usePreviewKey) {
            key = key || this.previewKey();
        }

        // try to build url to large scale for retina display
        if (supportRetina && $.support.retina && !this.isPotentiallyRestrictedMode()) {
            var size = this.calculateSize(scale);
            var retinaScale = this.calculateScale(size.width * 2.1, size.height * 2.1, !!key);
            if (retinaScale && retinaScale > scale) {
                scale = retinaScale;
                var retinaParams = '&retina=2&targetWidth=' + size.width;
            }
        }

        var restricted = key && maxScale > 0 && scale > maxScale,
            urls = restricted ? $.nd.config.get('imageServers.largePages') : $.nd.config.get('imageServers.pages');

        return (
            urls[this._idx % urls.length] +
            '?file=' +
            ViewerApp.issue() +
            '&page=' +
            this.pageNumber() +
            '&scale=' +
            scale +
            (layer ? '&layer=' + layer : '') +
            (ver ? '&ver=' + ver : '') +
            (restricted ? '&ticket=' + encodeURIComponent(key) : '') +
            (retinaParams || '')
        );
    },
    buildMagnifierUrl: function (scale, area, singleLayer) {
        var key = this.key(),
            ver = this.ver(),
            maxScale = this.maxUnrestrictedScale(),
            restricted = key && maxScale > 0 && scale > maxScale,
            urls = restricted ? $.nd.config.get('imageServers.zoomPages') : $.nd.config.get('imageServers.pages'),
            idx,
            areaQuery,
            layer;

        if (!area) {
            idx = this._idx % urls.length;
            areaQuery = '';
            layer = 'bg';
        } else {
            idx = area.id % urls.length;
            areaQuery = '&left=' + area.l + '&top=' + area.t + '&right=' + area.r + '&bottom=' + area.b;
            layer = 'fg';
        }

        // disable layers
        if (singleLayer === true) layer = null;

        return (
            urls[idx] +
            '?file=' +
            ViewerApp.issue() +
            '&page=' +
            this.pageNumber() +
            '&scale=' +
            scale +
            areaQuery +
            (layer ? '&layer=' + layer : '') +
            (ver ? '&ver=' + ver : '') +
            (restricted ? '&ticket=' + encodeURIComponent(key) : '')
        );
    },
    buildThumbUrl: function (scale) {
        return getThumbUrlForScale(ViewerApp.issue(), scale, this.pageNumber(), this.ver());
    },
    buildPictureUrl: function (scale, uid, key) {
        return getPictureUrlByIssueForScale(ViewerApp.issue(), uid, key, scale, this.ver());
    },
    articles: function () {
        var layout = this.layout();
        return layout ? layout.Articles : null;
    },
    layout: function (pageLayout) {
        if (arguments.length > 0) {
            this._pageLayout = pageLayout;
            // layout is required
            this.isInited(pageLayout ? true : false);
            return this;
        }
        return this._pageLayout;
    },
    pageName: function () {
        return this.layout().PageName;
    },
    pageNumber: function () {
        return this.layout().PageNumber;
    },
    section: function () {
        return this.layout().SectionName;
    },
    width: function () {
        return this.layout().Width;
    },
    height: function () {
        return this.layout().Height;
    },
    loaded: function () {
        return this._info != null;
    },
    areas: function () {
        var areas = [];
        var h = this.height();
        var w = this.width();
        for (var y = 0; y < h; y += 200) {
            var b = Math.min(h, y + 200);
            for (var x = 0; x < w; x += 200) {
                var a = { l: x, t: y, r: Math.min(w, x + 200), b: b, id: areas.length };
                areas[a.id] = a;
            }
        }
        return areas;
    },
};
// properties with events
$.nd.extendObjectWithProperties(ViewerPage.fn, 'isActive,isInited,isCurrent,key,previewKey', true, true, true);
$.nd.extendObjectWithProperties(ViewerPage.fn, 'pageType,isSpread,isSpreadLeft,isSpreadRight,isFirst,isLast', true, true, false);
// get properties from _info
$.each('ver,maxUnrestrictedScale'.split(','), function (i, name) {
    ViewerPage.fn[name] = new Function('return this._info?this._info.field:undefined;'.replace(/field/g, name));
});

var ViewerPages = ($.nd.viewer.pages = (function (ViewerApp) {
    var _pages = [],
        _pageViewFactories = [];
    var loadDfd;

    var _ts = null;

    function callPages(methodName, args) {
        args = args || [];
        for (var i = 0, length = _pages.length; i < length; i++) {
            var page = _pages[i],
                fun = page[methodName];
            if (fun) fun.apply(page, args);
        }
        return _api;
    }

    var _activePageValidationActive = false,
        _activePageValidation_newPages,
        _lastGotoPage;

    function page_isActiveChange(event) {
        var page = this,
            rtl = ViewerApp.contentIsRtl(),
            isActive = page && page.isActive(),
            pages = _activePageValidation_newPages || _api.activePages() || [],
            newPages = [];

        newPages.lastGotoPage = _lastGotoPage;

        for (var i = 0; i < pages.length; i++) {
            var p = pages[i];
            if (!page) newPages.push(p);
            else if ((rtl && p._idx > page._idx) || (!rtl && p._idx < page._idx)) newPages.push(p);
            else if (p._idx == page._idx) {
                if (isActive) newPages.push(page);
                page = null;
            } else {
                if (isActive) newPages.push(page);
                newPages.push(p);
                page = null;
            }
        }

        if (isActive && page) newPages.push(page);

        if (_activePageValidationActive) {
            _activePageValidation_newPages = newPages;
        } else {
            _api.activePages(newPages);

            _lastGotoPage = null;
        }
    }
    function activePageValidationStarted() {
        _activePageValidation_newPages = null;
        _activePageValidationActive = true;
    }
    function activePageValidationComplited() {
        _activePageValidationActive = false;
        if (_activePageValidation_newPages) {
            _api.activePages(_activePageValidation_newPages);
            _activePageValidation_newPages = null;
        }
    }

    ViewerApp.issueLoading(function () {
        _api.pagesCount(0).loaded(false).activePages([]);
        _api.loading(true);
        callPages('disable');

        // reset layout
        ViewerApp.articles(null);
        _pages.length = 0;

        if (loadDfd) {
            loadDfd.reject(new Error('issueLoading was called while previous load op was in progress. ViewerApp state was reset.'));
        }

        loadDfd = new Deferred();

        _ts = new Date().getTime();
    });
    ViewerApp.issueLoaded(function () {
        loadToc();
    });

    function loadToc() {
        if (!ViewerApp.issue()) {
            return;
        }

        // initialize loadDfd if it wasn't initalized in the issueLoading->issueLoaded flow (loadToc called separately)
        if (!loadDfd) {
            loadDfd = new Deferred();
        }

        var ts = _ts;

        $.nd.data.toc.reset();
        $.nd.data.toc.pages(function (pages) {
            var pagesCount = pages.length;
            var provider = ViewerApp.getProvider();
            var articles = [];
            $.each(pages, function (idx, pageInfo) {
                var page = getPageOrCreate(pageInfo.PageNumber, pagesCount);
                page.layout(pageInfo);
                page.pageInfo(provider.getPageInfo(pageInfo.PageNumber));

                if (pageInfo.Articles) {
                    articles = articles.concat(pageInfo.Articles);
                }
            });
            ViewerApp.articles(articles);
            ViewerApp.layoutLoaded();

            _api.pagesCount(pagesCount);

            //call requestPageKeys before notify
            var requestKeysDfd = provider.requestPageKeys ? provider.requestPageKeys(true) : Promise.resolve();
            requestKeysDfd.finally(function () {
                // ViewerApp.issueLoading could be called
                // after 'requestKeysDfd' initiated loading and before this callback is invoked
                // an exception will be thrown because ViewerApp state is reset by 'issueLoading'
                if (ts !== _ts) {
                    return;
                }

                _api.loaded(true);
                _api.loading(false);

                loadDfd.resolve();
                loadDfd = null;
            });
        });
    }

    function getPageOrCreate(pageNumber, pagesCount) {
        var pages = _pages;
        var idx = pageNumber - 1;
        var page = pages[idx];
        if (!page) {
            var rtl = ViewerApp.contentIsRtl(),
                dl = rtl ? PageTypes.DoubleRight : PageTypes.DoubleLeft,
                dr = rtl ? PageTypes.DoubleLeft : PageTypes.DoubleRight;

            var isLast = pageNumber == pagesCount,
                pageType =
                    idx == 0 || (isLast && idx % 2 == 1)
                        ? PageTypes.Single // first or last and left
                        : idx % 2 == 1
                        ? dl
                        : dr; // doubles

            page = new ViewerPage(_api, idx, pageNumber);
            $.each(_pageViewFactories, function (i, factory) {
                page.addView(factory.name, factory.fun(page));
            });
            page.isActive(false);
            page.isActiveChange(page_isActiveChange);
            page.setPageType(pageType, isLast);

            pages.push(page);
        }
        return page;
    }

    function first(a) {
        return a.length > 0 ? a[0] : undefined;
    }
    function last(a) {
        return a.length > 0 ? a[a.length - 1] : undefined;
    }

    var _api = {
        startActivePageValidation: function () {
            activePageValidationStarted();
        },
        finishActivePageValidation: function () {
            activePageValidationComplited();
        },
        setActivePageNumbers: function (pageNumbers) {
            // pageNumbers - array of page numbers
            activePageValidationStarted();

            var oldActivePages = this.activePages();
            var dict = {};
            for (var idx = pageNumbers.length - 1; idx >= 0; --idx) {
                var pageNumber = pageNumbers[idx];
                var page = this.getPage(pageNumber);
                page.isActive(true);
                dict[pageNumber] = true;
            }
            for (var idx = oldActivePages.length - 1; idx >= 0; --idx) {
                var page = oldActivePages[idx];
                if (!dict[page.pageNumber()]) {
                    page.isActive(false);
                }
            }
            activePageValidationComplited();
        },
        firstPage: function () {
            return this.pagesCount() > 0 ? _pages[0] : null;
        },
        lastPage: function () {
            return _pages[this.pagesCount() - 1];
        },
        nextPage: function (page) {
            return page && !page.isLast() ? _pages[page._idx + 1] : null;
        },
        prevPage: function (page) {
            return page && !page.isFirst() ? _pages[page._idx - 1] : null;
        },
        mostLeftPage: function () {
            return this[ViewerApp.contentIsRtl() ? 'lastPage' : 'firstPage']().leftPageIfSpread();
        },
        mostRightPage: function () {
            return this[ViewerApp.contentIsRtl() ? 'firstPage' : 'lastPage']().rightPageIfSpread();
        },
        leftPage: function (page) {
            return this[ViewerApp.contentIsRtl() ? 'nextPage' : 'prevPage'](page);
        },
        rightPage: function (page) {
            return this[ViewerApp.contentIsRtl() ? 'prevPage' : 'nextPage'](page);
        },
        getPageByIdx: function (idx) {
            return _pages[idx];
        },
        getPage: function (pageNumber) {
            return _pages[pageNumber - 1];
        },
        getPages: function () {
            return _pages || [];
        },
        getViews: function (viewName) {
            return _.map(this.getPages(), function (page) {
                return page[viewName]();
            });
        },
        getFirstActivePage: function () {
            /// <returns type='ViewerPage' />
            var pages = this.activePages();
            return pages ? first(pages) : undefined;
        },
        getLastActivePage: function () {
            var pages = this.activePages();
            return pages ? last(pages) : undefined;
        },
        resetKeyInfos: function () {
            $.each(_pages, function (i, page) {
                page.previewKey(null);
                page.key(null);
            });
        },
        addViewFactory: function (name, fun) {
            if (name && fun) {
                _pageViewFactories.push({ name: name, fun: fun });
                $.each(_pages, function (i, page) {
                    page.addView(name, fun(page));
                });
                name = fun = undefined;
            }
            return this;
        },
        gotoToPage: function (page, articleId) {
            var e = $.Event('gotoPageCalled');
            e.articleId = articleId;

            if (typeof page == 'number') page = this.getPage(page);
            else if (typeof page == 'string') page = this.getPage(parseInt(page, 10));
            _lastGotoPage = e.page = page;
            return this.fireGotoPageCalled(e);
        },
        load: function () {
            if (loadDfd) {
                return loadDfd.promise();
            }

            return Promise.resolve();
        },
        loadToc: loadToc,
    }; //_api
    $.nd.extendObjectWithEvents(_api, 'gotoPageCalled');
    $.nd.extendObjectWithProperties(_api, 'pagesCount,loaded,loading,activePages', true, true, true);

    return _api;
})(ViewerApp));

ViewerApp.pages = ViewerPages;

export const pages = ViewerPages;

export default ViewerApp;
