import { getConfigValueAsync } from '@pressreader/config';
import { httpGet } from '@pressreader/services';

import { ConfigDto } from './types/config-dto';
import { ResDto } from './types/res-dto';
import { staticResources } from './static-resources';

interface ResParams {
    id: number;
    locale: string;
    ts: string;
    version: number;
    resourceNames?: string[];
    templateNames?: string[];
}

export async function getResources(args?: { resourceNames?: string[]; templateNames?: string[]; locale?: string; includeStatic?: boolean }) {
    const dto = await getConfigValueAsync<ConfigDto>('res');

    if (!dto) {
        throw new Error('Resources and templates are not found.');
    }

    const { id, locale, ts } = dto;

    const params: ResParams = {
        id,
        locale,
        ts,
        version: 2,
    };
    if (args?.resourceNames) {
        params.resourceNames = args.resourceNames;
    }
    if (args?.templateNames) {
        params.templateNames = args.templateNames;
    }
    if (args?.locale) {
        params.locale = args.locale;
    }

    const result = await httpGet<ResDto>('res', params);

    const resources = args?.includeStatic === false ? result.res : { ...staticResources, ...result.res };

    return { resources, templates: result.templates };
}
