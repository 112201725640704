/* eslint-env es6 */

import svcApi from 'nd.services.api';
import { ReplaySubject } from 'rxjs';

// ReplaySubject is required here to guarantee that subscribers will receive
// at least one technical email status change, even if they had subscribed before this event happened.
// Buffer size is set to 1 (means this subject will hold only one event happened before) but user code
// should be tolerance to many events received from this observable.
const techEmailsStatusSubject = new ReplaySubject(1 /* buffer size */);

const technicalEmailStatusChangeSubscription = techEmailsStatusSubject.asObservable();

/**
 * Sends user-specific email settings back to server.
 * @param {Boolean} enablePromotional - true, if user wants to receive promotional emails.
 * @param {Boolean} enableTechnical - true, if user wants to receive technical emails.
 * @returns {Promise} - when fulfilled, user-specific email settings have been successfully stored.
 */
function save(emailSettings, encryptedAccountId) {
    let url = 'emailsettings';
    if (encryptedAccountId) {
        url += `?encryptedAccountId=${encryptedAccountId}`;
    }

    return svcApi.post('user', url, emailSettings).then(() => {
        // notify all subscribers that status of technical emails has been changed.
        techEmailsStatusSubject.next({ technicalEmailsEnabled: emailSettings.enableTechnical });
    });
}

function load(encryptedAccountId) {
    let url = 'emailsettings';
    if (encryptedAccountId) {
        url += `?encryptedAccountId=${encryptedAccountId}`;
    }

    return svcApi.get('user', url).then(data => {
        const result = {
            enablePromotional: data.EnablePromotional,
            enableTechnical: data.EnableTechnical,
        };

        techEmailsStatusSubject.next({ technicalEmailsEnabled: result.enableTechnical });
        return result;
    });
}

export { save, load, technicalEmailStatusChangeSubscription };
