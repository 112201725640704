enum CategoryGroupName {
    Countries = 'Countries',
    Languages = 'Languages',
    PublicationTypes = 'PublicationTypes',
    Categories = 'BaseContentCatalog',
}

enum Category {
    // client-specific categories
    Undefined = '',
    Favorites = 'mypublications',
    TopMagazines = 'topmagazines',
    MagazinesSlug = 'magazines',
    TopNewspapers = 'topnewspapers',
    NewspapersSlug = 'newspapers',
    PDFBooksSlug = 'pdfbooks',
    TopPublications = 'toppublications',
    TopFree = 'topfreepublications',
    Featured = 'featured',
    RecentlyRead = 'recentlyread',
    All = 'all', // DEPRECATED
    Trial = 'trial', // DEPRECATED
    FeaturedHotspot = 'featuredhotspot',
    Books = 'books',
    BooksGenres = 'genres',
    BooksSpecialCollection = 'special_collection',
    BooksAuthors = 'authors',
    BooksSeries = 'series',
    BaseContent = 'basecontent',
    Publications = 'publications',
    BooksMyBooks = 'library',
    NationalNews = 'national-news',
    LocalNews = 'local-news',
    PopularWorldwide = 'popular-worldwide',
    PopularInCountry = 'popular-in-country',
    AllNews = 'all-news',

    Countries = 656,
    Languages = 1032,
    Categories = 584,
    PublicationTypes = 8388608,
    Magazines = 150994944,
    Newspapers = 142606336,
    PDFBooks = 164071058,
    International = 938,
    News = 1124,
}

type CategoryId = string | number | Category;

type CatalogItemType = 'Category' | 'Publication';

enum SortOrder {
    Ascending = 'asc',
    Descending = 'desc',
    Default = 'default',
}

enum SortablePublicationAttribute {
    Name = 'name',
    Rank = 'rank',
    LatestIssueDate = 'latestIssueDate',
    SearchRank = 'searchrank',
}

const DefaultPublicationSortOrder = {
    [SortablePublicationAttribute.Name]: SortOrder.Ascending,
    [SortablePublicationAttribute.Rank]: SortOrder.Descending,
    [SortablePublicationAttribute.LatestIssueDate]: SortOrder.Descending,
};

const DefaultCatalogRootNames = [
    CategoryGroupName.PublicationTypes,
    CategoryGroupName.Countries,
    CategoryGroupName.Languages,
    CategoryGroupName.Categories,
];

const DefaultFtsRootNames = [CategoryGroupName.Countries, CategoryGroupName.Languages];

enum CatalogLayoutMode {
    List = 0,
    SmallGrid = 1,
    Grid = 2,
    Newsstand = 3,
}

enum CatalogSortType {
    ByAlphabet = 0,
    MostPopular = 1,
    MostRecent = 2,
}

export {
    CategoryGroupName,
    Category,
    CategoryId,
    CatalogItemType,
    SortablePublicationAttribute,
    DefaultPublicationSortOrder,
    DefaultCatalogRootNames,
    DefaultFtsRootNames,
    CatalogLayoutMode,
    CatalogSortType,
    SortOrder,
};
