/* eslint-env es6 */

import { map } from 'lodash';
import { UserProfile } from 'nd.data.userprofile';

class FollowersInfo {
    constructor(data) {
        if (data) {
            this.followedByMe = data.IsFollowing;
            this.followedByUsersTotal = data.FollowersTotal;
            this.followsUsersTotal = data.FollowsTotal;
            this.followedByUsers = map(data.Followers, f => new UserProfile(f));
            this.followsUsers = map(data.Follows, f => new UserProfile(f));
        }
    }
}

export default FollowersInfo;
