import isDate from 'lodash/isDate';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import padStart from 'lodash/padStart';

import { isNullOrWhitespace } from '@pressreader/utils';

const issueKeyLength = 26;
const cidDateKeyLength = 12;

const fixedPart1 = '000000';
const fixedPart2 = '001001';

/**
 * Builds issue key like 'CCCCYYYYMMDD000000VV001001', e.g. 10472016042900000000001001
 * Where
 * CCCC      - CID, e.g. '1042'
 * YYYYMMDD - string representation of issue date, e.g. '20160429'
 * 000000   - constant
 * VV       - issue version, e.g. '00'
 * 001001   - constant
 *
 * @param cid - CID of publication
 * @param issueDate - date of issue
 * @param issueVersion - version of issue
 */
function buildIssueKey(cid: string, issueDate: Date, issueVersion: string | number) {
    if (isNullOrWhitespace(cid)) {
        throw new Error('cid required');
    }
    if (cid.length > 4) {
        throw new Error('cid length exeeds 4 characters');
    }

    if (!isDate(issueDate)) {
        throw new TypeError('issuedDate has to be a Date');
    }

    if (!isString(issueVersion) && !isNumber(issueVersion)) {
        throw new TypeError('issueVersion has to be either null or undefined or a String or a Number');
    }

    if (isNumber(issueVersion) && (issueVersion < 0 || issueVersion > 99)) {
        throw new Error('issueVersion has to be a number in [0..99] range');
    }

    if (isString(issueVersion) && issueVersion.length > 2) {
        throw new Error('issueVersion exeeds 2 characters length');
    }

    const yyyy = issueDate.getFullYear();
    const mm = padStart(`${issueDate.getMonth() + 1}`, 2, '0');
    const dd = padStart(`${issueDate.getDate()}`, 2, '0');
    const vv = padStart(`${issueVersion}`, 2, '0');
    return `${cid}${yyyy}${mm}${dd}${fixedPart1}${vv}${fixedPart2}`;
}

/** Tests if given input is issue key. */
function isIssueKey(input: unknown) {
    return (
        isString(input) &&
        !isNullOrWhitespace(input) &&
        input.length === issueKeyLength &&
        input.substring(12, 18) === fixedPart1 &&
        input.substring(20, 26) === fixedPart2
    );
}

/** Validates issue key. If input is not looks like issue key throws Exception. */
function validate(input: string) {
    if (!isIssueKey(input)) {
        throw new Error(`${input} is not valid issue key`);
    }
}

/**
 * Extracts cid and date component from issue key.
 *
 * @returns - issue cid and date as string, like 104220160510
 */
function extractCidDateKeyString(issueKey: string) {
    validate(issueKey);
    return issueKey.substring(0, 12);
}

/**
 * Extracts date component from issue key.
 *
 * @returns issue date as string, like 20160510
 */
function extractDateString(issueKey: string) {
    if (issueKey && issueKey.length === cidDateKeyLength) {
        // it is a cidDateKey
        return issueKey.substring(4, 12);
    }

    validate(issueKey);
    return issueKey.substring(4, 12);
}

/** Extracts date from issue key. */
function extractDate(issueKey: string) {
    validate(issueKey);
    const year = parseInt(issueKey.substring(4, 8), 10);
    const month = parseInt(issueKey.substring(8, 10), 10);
    const day = parseInt(issueKey.substring(10, 12), 10);

    // we don't validate date components as issue key itself is built from date
    // so such validation would be unnecessary overhead

    return new Date(year, month - 1, day);
}

/**
 * Extracts CID from issue key.
 *
 * @param issueKey - issue key or cidDate key
 * @returns CID
 */
function extractCid(issueKey: string) {
    if (issueKey && issueKey.length === cidDateKeyLength) {
        // it is a cidDateKey
        return issueKey.substring(0, 4);
    }

    validate(issueKey);
    return issueKey.substring(0, 4);
}

/**
 * Builds issue key like 'CCCCYYYYMMDD', e.g. 104720160429
 * Where
 * CCCC      - CID, e.g. '1042'
 * YYYYMMDD - string representation of issue date, e.g. '20160429'
 *
 * @param cid - CID of publication
 * @param issueDate - date of issue
 */
function buildCidDateKey(cid: string, issueDate?: Date) {
    if (isNullOrWhitespace(cid)) {
        throw new Error('cid required');
    }

    if (cid.length > 4) {
        throw new Error('cid length exeeds 4 characters');
    }

    if (!issueDate) {
        return `${cid}latest`;
    }

    if (!isDate(issueDate)) {
        throw new TypeError('issuedDate has to be a Date');
    }

    const yyyy = issueDate.getFullYear();
    const mm = padStart(`${issueDate.getMonth() + 1}`, 2, '0');
    const dd = padStart(`${issueDate.getDate()}`, 2, '0');
    return `${cid}${yyyy}${mm}${dd}`;
}

/** Converts issueDate from Date to string in yyyyMMdd format. */
function buildIssueDateStr(issueDate: Date) {
    const yyyy = issueDate.getFullYear();
    const mm = padStart(`${issueDate.getMonth() + 1}`, 2, '0');
    const dd = padStart(`${issueDate.getDate()}`, 2, '0');
    return `${yyyy}${mm}${dd}`;
}

function getDateFromIssueDateStr(issueDateStr: string) {
    if (!issueDateStr || issueDateStr.length !== 8) {
        throw new Error(`${issueDateStr} is not valid issue date string`);
    }
    const year = parseInt(issueDateStr.substring(0, 4), 10);
    const month = parseInt(issueDateStr.substring(4, 6), 10);
    const day = parseInt(issueDateStr.substring(6, 8), 10);
    return new Date(year, month - 1, day);
}

function extractISODateString(issueKey: string) {
    const str = extractDateString(issueKey);
    return `${str.substring(0, 4)}-${str.substring(4, 6)}-${str.substring(6)}`;
}

export {
    buildIssueKey,
    extractCidDateKeyString,
    extractCid,
    extractDateString,
    extractISODateString,
    extractDate,
    buildCidDateKey,
    buildIssueDateStr,
    getDateFromIssueDateStr,
    isIssueKey,
};
