import { CustomError, HttpError } from '@pressreader/types';

export type IFetchErrorJson = {
    Message: string;
    message?: string;
    ResourceKey: string | null;
};

export interface IFetchError extends Error {
    status: number;
    statusText: string;
    json: Promise<IFetchErrorJson>;
}

export class FetchError extends CustomError implements IFetchError {
    public status: number;
    public statusText: string;
    public json: Promise<IFetchErrorJson>;

    constructor(status: number, statusText: string, json: Promise<IFetchErrorJson>) {
        super(statusText);

        this.status = status;
        this.statusText = statusText;
        this.json = json;
    }

    async getHttpError() {
        let json: IFetchErrorJson = { Message: 'Unknown error', ResourceKey: null };

        try {
            json = await this.json;
        } catch {
            // ignore
        }

        let responseJSON;
        if (json && typeof json === 'string') {
            responseJSON = { message: json, resourceKey: null };
        } else {
            const { Message, message, ResourceKey, ...rest } = json;
            responseJSON = { ...rest, message: message ?? Message, resourceKey: ResourceKey };
        }

        return new HttpError({
            status: this.status,
            responseText: this.statusText,
            responseJSON,
        });
    }
}
