import * as ko from 'knockout';
import { alertError } from '@pressreader/src/shared/errors/error.handlers';
import SectionBaseViewModel from 'account/viewmodels/section.base.viewmodel';
import { setLogonName } from 'account/services/account.service';

class LogonNameSectionViewModel extends SectionBaseViewModel {
    constructor({ profile, expandEventStream }) {
        super({ master: profile, properties: ['logonName', 'logonNameVisible', 'logonNameEditable', 'logonNameRequired'], expandEventStream });

        this.logonName = ko.observable(profile.logonName()).extend({
            required: profile.logonNameRequired,
            trackDirtyState: true,
        });

        this.displayName = ko.pureComputed(() => this.logonName());
    }

    async _save() {
        try {
            this.master.update(super.getData());
            await setLogonName(this.logonName());
        } catch (error) {
            alertError(error);
        }
    }
}

export default LogonNameSectionViewModel;
