import ndConfig from '@pressreader/src/deprecated/nd.config';
import { isEmpty, last, find, map, memoize, uniq, sortBy } from 'lodash';

const getAvailableFirstPageWidths = memoize((pageSizes, firstPageSizes) => {
    const allWidths = uniq(map([].concat(pageSizes, firstPageSizes), s => s.w));
    return sortBy(allWidths, (a, b) => a > b);
});

export default {
    _getThumbUrl: function () {
        return (ndConfig.get('imageServers.thumbs') || [])[0];
    },
    getThumbUrlByRegionKey: function (regionKey, file, scale, ver) {
        var url = this._getThumbUrl();
        if (!url) return null;
        return url + '?regionKey=' + regionKey + '&file=' + file + '&scale=' + scale + (ver ? '&ver=' + ver : '');
    },
    getThumbUrlByCidForHeight: function (cid, height, pageNumber) {
        var url = this._getThumbUrl();
        if (!url) return null;
        return url + '?cid=' + cid + '&page=' + (pageNumber || 1) + '&height=' + height;
    },
    getThumbUrlByCidAndDateForHeight: function (cid, date, height, pageNumber, supportRetina) {
        var url = this._getThumbUrl();
        if (!url) {
            return null;
        }
        if (typeof date !== 'string') {
            var month = date.getMonth() + 1;
            var day = date.getDate();
            date = date.getFullYear() + (month < 10 ? '0' : '') + month + (day < 10 ? '0' : '') + day;
        }
        if (supportRetina && $.support.retina) {
            height *= 2;
            height = height + '&retina=2';
        }
        return url + '?cid=' + cid + '&date=' + date + '&page=' + (pageNumber || 1) + '&height=' + height;
    },
    getThumbUrlByCidAndDateForWidth: function (cid, date, width, pageNumber) {
        var url = this._getThumbUrl();
        if (!url) {
            return null;
        }
        if (typeof date !== 'string') {
            var month = date.getMonth() + 1;
            var day = date.getDate();
            date = date.getFullYear() + (month < 10 ? '0' : '') + month + (day < 10 ? '0' : '') + day;
        }
        return url + '?cid=' + cid + '&date=' + date + '&page=' + (pageNumber || 1) + '&width=' + (width || 160);
    },
    getThumbUrlByIssueForWidth: function (issue, width, pageNumber, supportRetina) {
        const url = this._getThumbUrl();
        if (!url) return null;
        let retinaParam = '';
        width = parseInt(width, 10) || 160;
        if (supportRetina && $.support.retina) {
            width *= 2;
            retinaParam = '&retina=2';
        }
        width = this._adjustPageImageWidth(width);
        return url + '?file=' + issue + '&page=' + (pageNumber || 1) + '&width=' + width + retinaParam;
    },
    getFirstPageThumbUrlByIssueForWidth: function (issue, width, pageNumber, supportRetina) {
        const url = this._getThumbUrl();
        if (!url) return null;
        let retinaParam = '';
        width = parseInt(width, 10) || 160;
        if (supportRetina && $.support.retina) {
            width *= 2;
            retinaParam = '&retina=2';
        }
        width = this._adjustFirstPageImageWidth(width);
        return url + '?file=' + issue + '&page=' + (pageNumber || 1) + '&width=' + width + retinaParam;
    },
    getThumbUrlByIssueForHeight: function (issue, height, pageNumber, supportRetina) {
        var url = this._getThumbUrl();
        if (!url) return null;
        let retinaParam = '';
        height = parseInt(height, 10);
        if (supportRetina && $.support.retina) {
            height *= 2;
            retinaParam = '&retina=2';
        }
        height = this._adjustPageImageHeight(height);
        return url + '?file=' + issue + '&page=' + (pageNumber || 1) + '&height=' + height + retinaParam;
    },
    getThumbUrlForScale: function (issue, scale, pageNumber, ver) {
        var url = this._getThumbUrl();
        if (!url) return null;
        return url + '?file=' + issue + '&page=' + (pageNumber || 1) + '&scale=' + scale + (ver ? '&ver=' + ver : '');
    },
    getPictureUrlByIssueForScale: function (issue, uid, key, scale, ver) {
        var url = this._getThumbUrl();
        if (!url) return null;

        return url + '?' + 'regionguid=' + uid + '&scale=' + scale + '&file=' + issue + '&regionKey=' + key + (ver ? '&ver=' + ver : '');
    },
    getPageImageUrl: function (issue, pageNumber, pageSize, fitToSize, layer) {
        var zoom = this._calculateZoom(pageNumber, pageSize, fitToSize.width, fitToSize.height);
        if (!zoom) return null;
        var url = (ndConfig.get('imageServers.pages') || [])[0];
        if (!url) return null;

        return (
            url + '?file=' + issue + '&page=' + pageNumber + '&scale=' + zoom + (layer ? '&layer=' + layer : '') //+ (ver ? "&ver=" + ver : "")
        );
    },
    getPageImageScale: function (pageNumber, pageSize, fitToSize) {
        var zoom = this._calculateZoom(pageNumber, pageSize, fitToSize.width, fitToSize.height);
        return zoom;
    },
    _calculateZoom: function (pageNumber, pageSize, maxW, maxH) {
        var pageWidth = pageSize.width / 100;
        var pageHeight = pageSize.height / 100;

        if (maxW && maxW > 0) var exactScaleWidth = Math.floor(maxW / pageWidth);

        if (maxH && maxH > 0) var exactScaleHeight = Math.floor(maxH / pageHeight);

        var pageSizes = ndConfig.get('pageSizes.pageSizes') || [];
        var firstPageSizes = ndConfig.get('pageSizes.firstPageSizes') || [];
        var allSizes = [pageSizes];
        if (pageNumber == 1) allSizes.push(firstPageSizes);

        var closetScaleWidth = 0;
        if (exactScaleWidth) {
            for (var j = 0; j < allSizes.length; ++j) {
                var sizes = allSizes[j];
                for (var i = sizes.length - 1; i >= 0; i--) {
                    var w = sizes[i].w;
                    if (!w || w > maxW) continue;

                    var scale = Math.floor(w / pageWidth);

                    if (scale > exactScaleWidth) continue;

                    var h = sizes[i].h;
                    if (h) {
                        scale = Math.min(scale, Math.floor(h / pageHeight));
                        if (scale > exactScaleWidth) continue;
                    }
                    closetScaleWidth = Math.max(closetScaleWidth, scale);
                }
            }
        }
        var closetScaleHeight = 0;
        if (exactScaleHeight) {
            for (var j = 0; j < allSizes.length; ++j) {
                var sizes = allSizes[j];
                for (var i = sizes.length - 1; i >= 0; i--) {
                    var h = sizes[i].h;

                    if (!h || h > maxH) continue;

                    var scale = Math.floor(h / pageHeight);

                    if (scale > exactScaleHeight) continue;

                    var w = sizes[i].w;
                    if (w) {
                        scale = Math.min(scale, Math.floor(w / pageWidth));

                        if (scale > exactScaleHeight) continue;
                    }

                    closetScaleHeight = Math.max(closetScaleHeight, scale);
                }
            }
        }
        var scale = Math.min(closetScaleWidth || closetScaleHeight, closetScaleHeight || closetScaleWidth);

        return scale;
    },

    _adjustPageImageWidth(width) {
        let result = width;
        const pageSizes = ndConfig.get('pageSizes.pageSizes') || [];
        const pageWidths = pageSizes.filter(item => item.w > 0);
        if (!isEmpty(pageWidths)) {
            const availableWidth = find(pageWidths, item => item.w > width);
            // If there are no bigger widths, then return the biggest available.
            result = availableWidth ? availableWidth.w : last(pageWidths).w;
        }
        return result;
    },

    _adjustFirstPageImageWidth(width) {
        let result = width;
        const pageSizes = ndConfig.get('pageSizes.pageSizes') || [];
        const firstPageSizes = ndConfig.get('pageSizes.firstPageSizes') || [];
        const widths = getAvailableFirstPageWidths(pageSizes, firstPageSizes);
        if (!isEmpty(widths)) {
            const availableWidth = find(widths, item => item > width);
            // If there are no bigger widths, then return the biggest available.
            result = availableWidth ? availableWidth : last(widths);
        }
        return result;
    },

    _adjustPageImageHeight(height) {
        let result = height;
        const pageSizes = ndConfig.get('pageSizes.pageSizes') || [];
        const pageHeights = pageSizes.filter(item => item.h > 0);
        if (!isEmpty(pageHeights)) {
            const availableHeight = find(pageHeights, item => item.h > height);
            // If there are no bigger height, then return the biggest available.
            result = availableHeight ? availableHeight.h : last(pageHeights).h;
        }
        return result;
    },
};
