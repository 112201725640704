import { Subject } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';

import { svcAuth } from '@pressreader/services';

import { IResponseDto, load, merge } from './global.api';

const settingsChangedSubject = new Subject<void>();
const onChanged = settingsChangedSubject.asObservable();
let _globalStoragePromise: Promise<IResponseDto>;

function loadSettings() {
    if (!_globalStoragePromise) {
        _globalStoragePromise = load();
        _globalStoragePromise.then(() => {
            settingsChangedSubject.next();
        }, noop);
    }
    return _globalStoragePromise;
}

function reset() {
    _globalStoragePromise = null;
}

svcAuth.bind('tokenUpdated', reset);

async function set<T>(key: string, value: T) {
    let json = await loadSettings();

    if (!json) {
        json = {
            DefaultLocales: null,
            FollowingUsers: [],
            MyNewspapers: [],
            UserSettings: {},
        };
    } else if (!json.UserSettings) {
        json.UserSettings = {};
    }

    // If the type is object and the old and the new values are not the same object, make sure they're actually different.
    if (json.UserSettings[key] !== value && isEqual(json.UserSettings[key], value)) {
        return;
    }

    json.UserSettings[key] = value;

    const changes = { [key]: value };

    const success = await merge(changes);
    if (!success) {
        // throw new Error('Merging user settings failed.');
        return;
    }

    reset();
    await loadSettings();
}

async function get<T>(key: string, defaultValue?: T) {
    const json = await loadSettings();
    const result = (json?.UserSettings?.[key] ?? defaultValue) as T;

    // Cloning it to make sure the value stored here doesn't get changed from outside.
    return cloneDeep(result);
}

async function getDefaultLocales() {
    const json = await loadSettings();
    const result = json?.DefaultLocales;

    // Cloning it to make sure the value stored here doesn't get changed from outside.
    return cloneDeep(result);
}

export { onChanged, loadSettings };
export { get, set, reset, getDefaultLocales };
