import * as ko from "knockout";
import * as jQuery from "jquery";
import validators from "nd.knockout.validators";
import "ko/extenders/undo";
import { Deferred } from 'promise/deferred';

    var extenders = [];

    // Validation extenders
    //
    //
    (function (ko, $, extenders, validators) {

        // A container for results of validation on the concrete field
        //
        //
        function ValidationResults() {

            var valMap = {},
                self = {
                    append: function (key, valResult) {

                        valMap[key] = {
                            isValid: valResult.isValid,
                            message: valResult.message
                        };
                    },
                    hasNoErrors: function () {
                        var isValid = true;

                        $.each(valMap, function (key, valResult) {
                            if (!valResult.isValid) {
                                isValid = false;
                                return false;
                            }

                            return undefined;
                        });

                        return isValid;
                    },
                    getMessages: function () {

                        // at the moment since the design decision has not been made
                        // this function returns the first message only
                        var message = '';
                        $.each(valMap, function (key, valResult) {
                            if (!valResult.isValid) {
                                message = valResult.message;
                                return false;
                            }

                            return undefined;
                        });

                        return message;
                    }
                };

            return {
                append: self.append,
                hasNoErrors: self.hasNoErrors,
                getMessages: self.getMessages
            }
        }

        var validatorExtender = function (Validator, validatorName) {

            return function (target, option) {

                var currentValue = target(),
                    valResults = target._validationResults,
                    validator = new Validator(option),
                    validateFn = function (val) {
                        var isValid = validator.validate(val);

                        valResults.append(validatorName, {
                            isValid: isValid,
                            message: isValid ? '' : validator.message,
                        });

                        target.hasErrors(!valResults.hasNoErrors());
                        target.validationMessage(valResults.getMessages());
                    };

                // prepare target (add all necessary observables)
                if (!valResults) {
                    valResults = target._validationResults = new ValidationResults();
                }

                target.hasErrors = ko.observable(false);
                target.validationMessage = ko.observable('');

                // initial state validation
                validateFn(currentValue);

                target.subscribe(validateFn);

                return target;
            }
        };

        // Attach all validators as extenders
        $.each(validators, function (validatorName, Validator) {
            extenders.push({
                name: validatorName,
                extender: validatorExtender(Validator, validatorName)
            });
        });
    })(ko, jQuery, extenders, validators);

    // Track Dirty State extender
    //
    //
    (function (ko, extenders) {

        function isDirtyExtender() {

            return function (target, option) {

                if (typeof option !== "boolean") throw new TypeError("true/false are the only valid options for isDirty.");

                target.isDirty = ko.observable(false);
                target.subscribe(function () {
                    target.isDirty(true);
                });

                return target;
            };
        }

        extenders.push({
            name: 'trackDirtyState',
            extender: isDirtyExtender()
        });

    })(ko, extenders);

    // Max length extender
    //
    //
    (function (ko, extenders) {

        function maxLengthExtender() {

            return function (target, option) {

                var maxLength = parseInt(option);

                if (isNaN(maxLength) || maxLength <= 0)
                    throw new TypeError("an positive integer is required for the Max Length.");

                var result = ko.computed({
                    read: target,
                    write: function (newValue) {
                        var current = target(),
                            valueToWrite = newValue ? newValue.substring(0, Math.min(newValue.length, maxLength)) : newValue;

                        if (valueToWrite !== current) {
                            target(valueToWrite);
                        } else {
                            //if the rounded value is the same, but a different value was written, force a notification for the current field
                            if (newValue !== current) {
                                target.notifySubscribers(valueToWrite);
                            }
                        }
                    }
                });

                //initialize with current value to make sure it is rounded appropriately
                result(target());

                //return the new computed observable
                return result;
            }
        }

        extenders.push({
            name: "maxLength",
            extender: maxLengthExtender()
        });

    })(ko, extenders);

    // Async extender (to support working with promises in ko.observables
    //
    //
    (function (ko, $, extenders) {

        function asyncExtender() {
            return function asyncComputed(evaluator, owner) {
                var result = ko.observable(),
                    currentDeferred;
                result.inProgress = ko.observable(false); // Track whether we're waiting for a result

                ko.computed(function onAsyncComputedValueRequested() {
                    // Abort any in-flight evaluation to ensure we only notify with the latest value
                    if (currentDeferred) {
                        currentDeferred.reject();
                    }

                    var evaluatorResult = evaluator.call(owner);
                    // Cope with both asynchronous and synchronous values
                    if (evaluatorResult && (typeof evaluatorResult.then === "function")) { // Async
                        result.inProgress(true);

                        currentDeferred = new Deferred();
                        currentDeferred.then(function (data) {
                            result.inProgress(false);
                            result(data);
                        }, function (error) {
                            result.inProgress(false);
                            if (error) {
                                console.error(error);
                            }
                        });

                        evaluatorResult.then(currentDeferred.resolve, currentDeferred.reject);

                    } else {
                        // Sync
                        result(evaluatorResult);
                    }
                });

                return result;
            }

        }


        extenders.push({
            name: 'async',
            extender: asyncExtender()
        })

    })(ko, jQuery, extenders);


    // Add extenders to the Knockout context
    //
    //
    (function (ko, extenders) {

        for (var i = 0, len = extenders.length; i < len; i++) {
            var current = extenders[i];

            if (ko.extenders[current.name])
                throw new Error("Cannot add " + current.name + " to the Knockout extenders set. An extender with the same name already exists.");

            ko.extenders[current.name] = current.extender;
        }
    })(ko, extenders);


