import Uri from 'jsuri';
import { findKey } from 'lodash';

import { AuthenticationType } from '@pressreader/src/authentication/authenticationtypes';
import { SignUpSucceededEvent } from '@pressreader/src/authentication/events/events';
import { eventbus } from '@pressreader/src/eventbus/eventbus';
import { cookies } from '@pressreader/utils';

import { EcommerceSteps } from 'analytics/ecommerce/models/models';
import { getPurchaseInfo } from 'analytics/ecommerce/services/services';
import { ExceptionEvent } from 'exceptions/events/events';
import { ExceptionPayload } from 'exceptions/models/models';
import { AddPaymentInfoEvent, AddToCartEvent, BeginCheckoutEvent, PurchaseEvent } from 'purchase/events/events';

interface Promo {
    code: string;
    product?: string;
    subtotal?: number;
    discount?: number;
    total?: number;
}

class ECommerce {
    dataElement = () => document.getElementById('ecommerce');

    private getStep(): EcommerceSteps {
        const pageData = this.dataElement();
        if (pageData) {
            const key = findKey(EcommerceSteps, k => k === pageData.getAttribute('data-step'));
            return EcommerceSteps[key];
        }
        return null;
    }

    private getPromo(): Promo {
        const uri = new Uri(window.location.href);
        const codeOnUri = uri.getQueryParamValue('PromoCode');
        const productOnUri = uri.getQueryParamValue('PromoProduct');
        if (codeOnUri && productOnUri) {
            return {
                code: codeOnUri,
                product: productOnUri,
            };
        }

        const c = <HTMLInputElement>document.getElementById('PromoCode');
        const p = <HTMLInputElement>document.getElementById('PromoProduct');
        const s = <HTMLInputElement>document.getElementById('PromoSubTotal');
        const d = <HTMLInputElement>document.getElementById('PromoDiscount');
        const t = <HTMLInputElement>document.getElementById('PromoTotal');
        if (c && p && s && d && t) {
            return {
                code: c.value,
                product: p.value,
                subtotal: parseFloat(s.value),
                discount: parseFloat(d.value),
                total: parseFloat(t.value),
            };
        }
        return null;
    }

    public async handle(step: EcommerceSteps, successOrderId: string, promo: Promo) {
        step = step || this.getStep();

        const data = await getPurchaseInfo(successOrderId || new Uri(window.location.href).getQueryParamValue('SuccessOrderId'));

        promo = promo || this.getPromo();
        if (data.items && data.items.length > 0 && promo) {
            data.items.forEach(e => {
                if (e.id === promo.product) {
                    e.coupon = e.coupon || promo.code;
                    e.subTotal = e.subTotal || promo.subtotal;
                    e.discount = e.discount || promo.discount;
                    e.total = e.total || promo.total;
                }
            });
        }

        const notifySignUp = () => {
            if (cookies.get('isNewUser') === 'true') {
                eventbus.send(new SignUpSucceededEvent({ system: AuthenticationType.Application }));
            }
        };

        switch (step) {
            case EcommerceSteps.SubscriptionPlans:
                notifySignUp();
                break;

            case EcommerceSteps.Bundles:
                notifySignUp();
                eventbus.send(new BeginCheckoutEvent(data));
                break;

            case EcommerceSteps.PaymentInformation:
                if (
                    // Avoid add to cart from bundles
                    data &&
                    data.items &&
                    data.items.length > 0 &&
                    // Avoid add to cart from pay-as-go
                    !new Uri(window.location.href).getQueryParamValue('RequestedContent.issueId')
                ) {
                    eventbus.send(new AddToCartEvent(data));
                }

                // Check if there are errors:
                // eslint-disable-next-line no-case-declarations
                const errs = document.getElementsByClassName('validation-summary-errors');
                if (errs) {
                    for (let index = 0; index < errs.length; index++) {
                        const desc = errs[index].textContent.trim();
                        if (desc) {
                            eventbus.send(
                                new ExceptionEvent(
                                    new ExceptionPayload({
                                        description: desc,
                                        fatal: false,
                                    }),
                                ),
                            );
                        }
                    }
                }
                break;

            case EcommerceSteps.Confirmation:
                if (data && data.items && data.items.length > 0) {
                    eventbus.send(new AddPaymentInfoEvent(data));
                }
                break;

            case EcommerceSteps.Finish:
                if (data && data.items && data.items.length > 0) {
                    eventbus.send(new PurchaseEvent(data));
                }
                break;

            default:
                throw new Error('Not Implemented');
        }
    }

    // This should be removed when the purchase process become spa
    public init(): void {
        document.addEventListener(
            'DOMContentLoaded',
            () => {
                if (this.dataElement()) {
                    this.handle(null, null, null);
                }
            },
            {
                once: true,
            },
        );
    }
}

const ecommerce = new ECommerce();

export { ecommerce };
