﻿import last from 'lodash/last';
import memoize from 'lodash/memoize';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

import { getConfigValue } from '@pressreader/config';
import { ISize } from '@pressreader/geometry';

const getAvailableFirstPageWidths = memoize((pageSizes: { w: number }[], firstPageSizes: { w: number }[]) => {
    const allWidths = uniq([...pageSizes, ...firstPageSizes].map(s => s.w));
    return sortBy(allWidths, (a, b) => a > b);
});

function _getThumbUrl(): string | undefined {
    return getConfigValue<string[]>('imageServers.thumbs', [])[0];
}

export function getThumbUrlByRegionKey(regionKey: string, file: string, scale: number, ver: string) {
    const url = _getThumbUrl();
    if (!url) {
        return null;
    }
    return `${url}?regionKey=${regionKey}&file=${file}&scale=${scale}${ver ? `&ver=${ver}` : ''}`;
}

export function getThumbUrlByCidForWidth(cid: string, width: number, pageNumber = 1, supportRetina = false) {
    const url = _getThumbUrl();
    let retinaParam = '';
    if (!url) {
        return null;
    }
    if (supportRetina) {
        width *= 2;
        retinaParam = '&retina=2';
    }

    width = _adjustPageImageWidth(width);

    // TODO:
    // 2. try to get issue  to
    // 3. ask alexey to make allow make request by width for issue (right now there is version for cid and for cid+issuedate)
    return `${url}?cid=${cid}&page=${pageNumber || 1}&width=${width}${retinaParam}`;
}

export function getThumbUrlByCidForHeight(cid: string, height: number, pageNumber = 1) {
    const url = _getThumbUrl();
    if (!url) {
        return null;
    }
    return `${url}?cid=${cid}&page=${pageNumber}&height=${height}`;
}
export function getThumbUrlByCidAndDateForHeight(cid: string, date: Date | string, height: number, pageNumber = 1, supportRetina = false) {
    const url = _getThumbUrl();
    if (!url) {
        return null;
    }
    let dateParam = '';

    if (typeof date !== 'string') {
        const month = date.getMonth() + 1;
        const day = date.getDate();
        dateParam = date.getFullYear() + (month < 10 ? '0' : '') + month + (day < 10 ? '0' : '') + day;
    } else {
        dateParam = date;
    }

    let retinaParam = '';
    if (supportRetina) {
        height *= 2;
        retinaParam = '&retina=2';
    }
    return `${url}?cid=${cid}&date=${dateParam}&page=${pageNumber}&height=${height}${retinaParam}`;
}

export function getThumbUrlByCidAndDateForWidth(cid: string, date: Date | string, width = 160, pageNumber = 1) {
    const url = _getThumbUrl();
    if (!url) {
        return null;
    }
    let dateParam = '';

    if (typeof date !== 'string') {
        const month = date.getMonth() + 1;
        const day = date.getDate();
        dateParam = date.getFullYear() + (month < 10 ? '0' : '') + month + (day < 10 ? '0' : '') + day;
    } else {
        dateParam = date;
    }

    return `${url}?cid=${cid}&date=${dateParam}&page=${pageNumber}&width=${width}`;
}

export function getThumbUrlByIssueForWidth(issue: string, width: number, pageNumber: number, supportRetina: boolean) {
    const url = _getThumbUrl();
    if (!url) {
        return null;
    }
    let retinaParam = '';
    if (supportRetina) {
        width *= 2;
        retinaParam = '&retina=2';
    }
    width = _adjustPageImageWidth(width);
    return `${url}?file=${issue}&page=${pageNumber || 1}&width=${width}${retinaParam}`;
}

export function getFirstPageThumbUrlByIssueForWidth(issue: string, width: number, pageNumber: number, supportRetina: boolean) {
    const url = _getThumbUrl();
    if (!url) {
        return null;
    }
    let retinaParam = '';
    if (supportRetina) {
        width *= 2;
        retinaParam = '&retina=2';
    }
    width = _adjustFirstPageImageWidth(width);
    return `${url}?file=${issue}&page=${pageNumber || 1}&width=${width}${retinaParam}`;
}

export function getThumbUrlByIssueForHeight(issue: string, height: number, pageNumber = 1, supportRetina = false) {
    const url = _getThumbUrl();
    if (!url) {
        return null;
    }
    let retinaParam = '';
    if (supportRetina) {
        height *= 2;
        retinaParam = '&retina=2';
    }
    height = _adjustPageImageHeight(height);
    return `${url}?file=${issue}&page=${pageNumber}&height=${height}${retinaParam}`;
}

export function getThumbUrlForScale(issue: string, scale: number, pageNumber: number, ver: string) {
    const url = _getThumbUrl();
    if (!url) {
        return null;
    }
    return `${url}?file=${issue}&page=${pageNumber || 1}&scale=${scale}${ver ? `&ver=${ver}` : ''}`;
}

export function getPictureUrlByIssueForScale(issue: string, uid: string, key: string, scale: number, ver?: string) {
    const url = _getThumbUrl();
    if (!url) {
        return null;
    }

    return `${url}?regionguid=${uid}&scale=${scale}&file=${issue}&regionKey=${key}${ver ? `&ver=${ver}` : ''}`;
}

export function getPageImageUrl(issue: string, pageNumber: number, pageSize: ISize, fitToSize: Partial<ISize>, layer?: string) {
    const zoom = _calculateZoom(pageNumber, pageSize, fitToSize.width, fitToSize.height);
    if (!zoom) {
        return null;
    }
    const url = getConfigValue<string[]>('imageServers.pages', [])[0];
    if (!url) {
        return null;
    }

    return `${url}?file=${issue}&page=${pageNumber}&scale=${zoom}${layer ? `&layer=${layer}` : ''}`;
}

export function getPageImageScale(pageNumber: number, pageSize: ISize, fitToSize: ISize) {
    const zoom = _calculateZoom(pageNumber, pageSize, fitToSize.width, fitToSize.height);
    return zoom;
}

function _calculateZoom(pageNumber: number, pageSize: ISize, maxW: number | undefined, maxH: number | undefined) {
    const pageWidth = pageSize.width / 100;
    const pageHeight = pageSize.height / 100;

    let exactScaleWidth: number | undefined;
    if (maxW && maxW > 0) {
        exactScaleWidth = Math.floor(maxW / pageWidth);
    }

    let exactScaleHeight: number | undefined;
    if (maxH && maxH > 0) {
        exactScaleHeight = Math.floor(maxH / pageHeight);
    }

    const pageSizes: { w: number; h: number }[] = getConfigValue('pageSizes.pageSizes') || [];
    const firstPageSizes: { w: number; h: number }[] = getConfigValue('pageSizes.firstPageSizes') || [];
    const allSizes = [pageSizes];
    if (pageNumber === 1) {
        allSizes.push(firstPageSizes);
    }

    let closetScaleWidth = 0;
    if (exactScaleWidth && maxW) {
        for (const sizes of allSizes) {
            for (let i = sizes.length - 1; i >= 0; i--) {
                const w = sizes[i].w;
                if (!w || w > maxW) {
                    continue;
                }

                let scale = Math.floor(w / pageWidth);

                if (scale > exactScaleWidth) {
                    continue;
                }

                const h = sizes[i].h;
                if (h) {
                    scale = Math.min(scale, Math.floor(h / pageHeight));
                    if (scale > exactScaleWidth) {
                        continue;
                    }
                }
                closetScaleWidth = Math.max(closetScaleWidth, scale);
            }
        }
    }
    let closetScaleHeight = 0;
    if (exactScaleHeight && maxH) {
        for (const sizes of allSizes) {
            for (let i = sizes.length - 1; i >= 0; i--) {
                const h = sizes[i].h;

                if (!h || h > maxH) {
                    continue;
                }

                let scale = Math.floor(h / pageHeight);

                if (scale > exactScaleHeight) {
                    continue;
                }

                const w = sizes[i].w;
                if (w) {
                    scale = Math.min(scale, Math.floor(w / pageWidth));

                    if (scale > exactScaleHeight) {
                        continue;
                    }
                }

                closetScaleHeight = Math.max(closetScaleHeight, scale);
            }
        }
    }
    const scale = Math.min(closetScaleWidth || closetScaleHeight, closetScaleHeight || closetScaleWidth);

    return scale;
}

function _adjustPageImageWidth(width: number) {
    let result = width;
    const pageSizes = getConfigValue<{ w: number }[]>('pageSizes.pageSizes', []);

    const pageWidths = pageSizes.filter(item => item.w > 0);
    if (pageWidths.length) {
        const availableWidth = pageWidths.find(item => item.w > width);
        // If there are no bigger widths, then return the biggest available.
        result = availableWidth ? availableWidth.w : last(pageWidths)!.w;
    }
    return result;
}

function _adjustFirstPageImageWidth(width: number) {
    let result = width;
    const pageSizes = getConfigValue<{ w: number }[]>('pageSizes.pageSizes', []);
    const firstPageSizes = getConfigValue<{ w: number }[]>('pageSizes.firstPageSizes', []);
    const widths = getAvailableFirstPageWidths(pageSizes, firstPageSizes);
    if (widths.length) {
        const availableWidth = widths.find(item => item > width);
        // If there are no bigger widths, then return the biggest available.
        result = availableWidth ? availableWidth : last(widths)!;
    }
    return result;
}

function _adjustPageImageHeight(height: number) {
    let result = height;
    const pageSizes = getConfigValue<Array<{ h: number }>>('pageSizes.pageSizes', []);
    const pageHeights = pageSizes.filter(item => item.h > 0);
    if (pageHeights.length) {
        const availableHeight = pageHeights.find(item => item.h > height);
        // If there are no bigger height, then return the biggest available.
        result = availableHeight ? availableHeight.h : last(pageHeights)!.h;
    }
    return result;
}
