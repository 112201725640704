import { get as globalGet, getDefaultLocales, set as globalSet } from './global';
import { IResponseDto } from './global.api';

type IFeedSettingsDto = IResponseDto['UserSettings']['FeedSettings'];

interface IFeedSettings extends IFeedSettingsDto {
    following: { ProfileId: string }[];
    locales: { country: string; language: string }[];
}

async function get() {
    let json = await globalGet<IFeedSettings>('FeedSettings');
    if (!json) {
        json = { locales: [], following: null, sections: null };
    } else if (!json.locales) {
        json.locales = [];
    }

    if (!json.locales.length) {
        let locales = await globalGet<string[]>('DefaultLocales');
        if (!locales?.length) {
            locales = await getDefaultLocales();
            if (!locales?.length) {
                locales = ['en-US'];
            }
        }

        for (const locale of locales) {
            const [language, country] = locale.toLowerCase().split('-');
            json.locales.push({ language, country });
        }
    }

    const users = await globalGet<{ ProfileId: string }[]>('FollowingUsers');
    if (users?.length) {
        json.following = (json.following ?? []).filter(item => users.some(item2 => item.ProfileId === item2.ProfileId));

        for (const item of users) {
            if (json.following.some(item2 => item.ProfileId === item2.ProfileId)) {
                json.following.push(item);
            }
        }
    } else {
        json.following = [];
    }

    return json;
}

function set(settings: IFeedSettings) {
    return globalSet('FeedSettings', settings);
}

export { get, set };
