import * as $ from 'jquery';
import 'nd.ui.menu.items';
import 'nd.navigationhistory';
import { ViewName } from '@pressreader/navigation-types';

var _Controllers = (function () {
    var self = {};

    function register(name, controller) {
        var parts = name.split('.');
        var node = self;
        for (var i = 0; i < parts.length - 1; ++i) {
            var part = parts[i];
            if (part in node) node = node[part];
            else node = node[part] = {};
        }
        node[parts[parts.length - 1]] = controller;
    }
    self.register = register;

    function have(name) {
        var parts = name.split('.');
        var node = self;
        for (var i = 0; i < parts.length; ++i) {
            var part = parts[i];
            if (part in node) node = node[part];
            else return false;
        }
        return true;
    }
    self.have = have;

    function get(name) {
        var parts = name.split('.');
        var node = self;
        for (var i = 0; i < parts.length; ++i) {
            var part = parts[i];
            if (part in node) node = node[part];
            else return null;
        }
        return node;
    }
    self.get = get;

    return self;
})();

function _Base(parent) {
    this._parent = parent;
}
_Base.prototype = {
    _parent: null,
    _item: null,
    item: function () {
        if (!this._item) this._item = new $.nd.ui.menu.items.item(this).bind(this.activate.bind(this));
        return this._item;
    },
    available: function () {
        return false;
    },
    enabled: function () {
        return false;
    },
    activate: function (item) {},
};
_Controllers.register('base', _Base);

function _Language() {
    _Base.apply(this, arguments);
}
$.extend(_Language.prototype, _Base.prototype, {});
_Controllers.register('language', _Language);

function _Feedback() {
    _Base.apply(this, arguments);
}
$.extend(_Feedback.prototype, _Base.prototype, {});
_Controllers.register('feedback', _Feedback);

function _About() {
    _Base.apply(this, arguments);
}
$.extend(_About.prototype, _Base.prototype, {});
_Controllers.register('about', _About);

function _Help() {
    _Base.apply(this, arguments);
}
$.extend(_Help.prototype, _Base.prototype, {});
_Controllers.register('help', _Help);

function _EmailAlertsController() {
    _Base.apply(this, arguments);
}
$.extend(_EmailAlertsController.prototype, _Base.prototype, {
    item: function () {
        if (!this._item) this._item = new $.nd.ui.menu.items.item(this, $.nd.res.val('Menu.Account.EmailAlerts')).bind(this.activate.bind(this));
        return this._item;
    },
    available: function () {
        return true;
    },
    enabled: function () {
        return true;
    },
    activate: function () {
        this._parent.hide();
        $.nd.navHistory.goView(ViewName.EmailAlerts, {}, true);
    },
});
_Controllers.register('emailAlerts', _EmailAlertsController);

export default $.nd.ui.menu.items.controllers = _Controllers;
