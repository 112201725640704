export enum AuthSignInSystemType {
    Library = 'library',
    Email = 'email',

    Bell = 'bell',

    Facebook = 'facebook',
    Twitter = 'twitter',
    Google = 'google',
    Instapaper = 'instapaper',
    Onenote = 'onenote',
    Evernote = 'evernote',

    /* There is no authorization via RBDigital from the Client */
    RBDigital = 'rbdigital',
}

export type AuthSignInExternalSystemType =
    | AuthSignInSystemType.Bell
    | AuthSignInSystemType.Facebook
    | AuthSignInSystemType.Twitter
    | AuthSignInSystemType.Google
    | AuthSignInSystemType.Instapaper
    | AuthSignInSystemType.Onenote
    | AuthSignInSystemType.Evernote
    | AuthSignInSystemType.RBDigital;

export interface IAuthSignInSystem {
    type: AuthSignInSystemType;

    /** Whether SignIn System is featured. Usualy it is only one  system displayed at the top. */
    featured: boolean;

    /** Whether SignIn System is pribary. Usually means these systems shown first. */
    isPrimary: boolean;

    titleResourceId: string;
    ctaResourceId?: string;
}
