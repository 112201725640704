import * as ko from 'knockout';
import * as $ from 'jquery';
        

        function ResizableBindingHandler() {
            this.update = function (element, valueAccessor, allBindingsAccessor, data, bindingContext) {
                var accessor = valueAccessor();
                var value = ko.unwrap(accessor);
                if (accessor.enforce) {
                    if ($.isFunction(accessor.resizeHandler))
                        accessor.resizeHandler(element);
                }
            }
        }

        ko.bindingHandlers['resizable'] = new ResizableBindingHandler();
    