import * as ko from 'knockout';
import * as $ from 'jquery';
        

        /*
         * Usage:
         * <div data-bind="nd-afterRender: $root.onRender">
         * </div>
         *
         * function onRender(element, viewModel) {}
         *
         * "onRender" function will be called when the element got rendered
         * */
        function AfterElementRender() {
            this.init = function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                var handler = valueAccessor();

                if(!$.isFunction(handler)) {
                    return;
                }

                handler(element, viewModel);
            };
        }

        ko.bindingHandlers['nd-afterRender'] = new AfterElementRender();
    