import * as $ from 'jquery';
import 'nd.core';

const BaseProvider = function () {
};
BaseProvider.prototype = {
    _loader: null,
    load: function () {
        // this method should be overriden to load data
        // when data is loaded should call this.onload(data);
    },
    onload: function (data) {
        this._loader.setData(data);
    },
    onFail: function (data) {
        this._loader.setFail(data);
    },
};

$.nd.baseLoader = function () {};
$.nd.baseLoader.prototype = {
    _provider: null,
    _loadPending: false,
    _loading: false,
    _triggerIfLoaded: function () {
        if (this._data) {
            $.nd.event.trigger('loaded', this, [this._data]);
        }
    },
    _triggerIfFailed: function () {
        if (this._data) {
            $.nd.event.trigger('failed', this, [this._data]);
        }
    },
    createBaseProvider: function (loadMethod) {
        const provider = new BaseProvider();
        if (loadMethod) {
            provider.load = loadMethod;
        }
        return provider;
    },
    setLoadFunction: function (loadMethod) {
        const provider = $.nd.user.loader.createBaseProvider();
        provider.load = function () {
            return loadMethod().then((data) => {
                provider.onload(data);
            });
        };
        this.setProvider(provider);
        return this;
    },
    setProvider: function (provider) {
        this._provider = provider;
        this._provider._loader = this;
        if (this._loadPending) {
            if (this._loadPending.length) {
                // treat _loadPending as arguments for load method
                this.load.apply(this, this._loadPending);
            } else {
                this.load();
            }
            this._loadPending = false;
        }
        return this;
    },
    setData: function (data) {
        this._loading = false;
        this._data = data;
        this._triggerIfLoaded();
    },
    setFail: function (data) {
        this._loading = false;
        this._data = data;
        this._triggerIfFailed();
    },
    getData: function () {
        return this._data;
    },
    load: function () {
        if (!this._data) {
            if (!this._provider) {
                this._loadPending = arguments;
            } else if (!this._loading) {
                this._loading = true;
                this._provider.load.apply(this._provider, arguments);
            }
        }
        return this;
    },
    reload: function () {
        this._data = null;
        this.load.apply(this, arguments);
        return this;
    },
    bind: function (fn) {
        $.nd.event.bind(this, 'loaded', fn);
        // this.load();
        this._triggerIfLoaded();
        return this;
    },
    bindFail: function (fn) {
        $.nd.event.bind(this, 'failed', fn);
        return this;
    },
};

$.nd.baseLoader.baseProvider = BaseProvider;
