import { startAppAction } from '@pressreader/appcore';
import { createActionHandler, createReducer } from '@pressreader/appstore';
import { NavConfig, ViewName } from '@pressreader/navigation-types';

import { getLocation } from '../api';
import { Location } from '../types';

import { navConfigUpdated, navigateBegan, navigateDone, navigateFailed, updateLocationAction } from './actions';

interface IState {
    handleRouting: boolean;

    viewName: ViewName | null;

    prevNavConfig: NavConfig | null;
    navConfig: NavConfig | null;
    location: Location;
}

const initialState: IState = {
    handleRouting: false,

    viewName: null,

    prevNavConfig: null,
    navConfig: null,
    location: getLocation(),
};

const reducer = createReducer(
    initialState,
    createActionHandler(startAppAction, (state, action) => ({
        ...state,
        handleRouting: action.payload.handleRouting ?? true,
    })),
    createActionHandler(navigateBegan, (state, action) => ({
        ...state,
        viewName: action.payload.config.viewName,
    })),
    createActionHandler(navigateDone, (state, { payload }) => {
        const prevConfig = payload.prevConfig;
        return {
            ...state,
            viewName: payload.config.viewName,
            prevNavConfig: !prevConfig || payload.config.replaced ? prevConfig : { ...prevConfig },
            navConfig: { ...payload.config },
        };
    }),
    createActionHandler(navigateFailed, (state, action) => ({
        ...state,
        viewName: action.payload.config.viewName,
    })),
    createActionHandler(navConfigUpdated, (state, action) => ({
        ...state,
        navConfig: { ...action.payload.newNavConfig },
    })),
    createActionHandler(updateLocationAction, (state, action) => ({
        ...state,
        location: { ...action.payload.location },
    })),
);

export { reducer, IState };
