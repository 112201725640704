import { EXTERNAL_AUTH_TYPE } from '@pressreader/authentication-types';

import { getProvider } from 'externalsystems/providers';

interface IAuthParameters {
    provider: string;
    authtype: string;
    context?: any;
}

/**
 * @typedef AuthResult
 * @type Object
 * @property {bool} isNewUser - indicates that new user was created during authorization
 */

/**
 * @typedef SignInResult
 * @type Object
 * @property {Number} ExpiresIn - seconds to expire
 * @property {String} Token - PressReader authentication token
 * @property {String} UserKey - authenticated user key
 */

/**
 * @typedef CombinedAuthResult
 * @type Object
 * @property {Object} authResult - result of external authorization
 * @property {Object} signInResult - result of signing in into PressReader
 */

/**
 * Single entry point to process authorization with external systmes
 *
 * Performs request for authorization to external system,
 * waits user input if required by authorization flow
 * process response
 * @param {String} provider - name of external system, @see ExternalProvider
 * @param {String} authtype - authorization type, @see EXTERNAL_AUTH_TYPE
 * @param {Object} [context] - operation, which for authorization is requested
 * @param {String} [context.command] - operation, which for authorization is requested
 * @returns {Promise.<CombinedAuthResult|true|Error>}
 *  - authorization result,
 *  - true - positive result in case of sharing
 *  - authorization error
 */
function authorize({ provider, authtype = EXTERNAL_AUTH_TYPE.SHARING, context = {} }: IAuthParameters) {
    if (!provider) {
        throw new Error('Provider not specified');
    }
    const providerImpl = getProvider(provider);
    return providerImpl.authorize({ provider, authtype, context });
}

export { authorize };
