import Alert from 'nd.alert';

function offlineReaderRequired(response: any) {
    const message = response.Parameters.Text;
    return Alert()
        .alert(message)
        .then(() => Promise.reject());
}

export { offlineReaderRequired };
