import * as ko from 'knockout';
import resources from 'nd.res';
import { validate } from '@pressreader/src/ko/nd/validate';
import ConsentSectionViewModel from 'account/viewmodels/consent.section.viewmodel';
import SectionBaseViewModel from 'account/viewmodels/section.base.viewmodel';
import { validateNickname, validateProfileName } from 'account/services/account.service';
import { find, memoize } from 'lodash';
import ndRes from 'nd.res';

class ProfileSectionBaseViewModel extends SectionBaseViewModel {
    /**
     * Updates master model with section data.
     * Changes will come back through subscriptions in base model
     */
    _save() {
        return this.master.update(super.getData());
    }
}

class NameSectionViewModel extends ProfileSectionBaseViewModel {
    constructor({ profile, expandEventStream }) {
        super({
            master: profile,
            properties: [
                'firstName',
                'lastName',
                'nameVisible',
                'nameEditable',
                'firstNameEditable',
                'firstNameRequired',
                'lastNameEditable',
                'lastNameRequired',
            ], // eslint-disable-line max-len
            expandEventStream,
        });

        this.displayName = ko.pureComputed(() => `${this.firstName() || ''} ${this.lastName() || ''}`);

        this.firstName = ko.observable(this.master.firstName()).extend({
            required: profile.firstNameEditable() && profile.firstNameRequired(),
            trackDirtyState: true,
        });

        this.lastName = ko.observable(this.master.lastName()).extend({
            required: profile.lastNameEditable() && profile.lastNameRequired(),
            trackDirtyState: true,
        });
    }
}

class DateOfBirthSectionViewModel extends ProfileSectionBaseViewModel {
    constructor({ profile, expandEventStream }) {
        super({
            master: profile,
            properties: ['dateOfBirth', 'dateOfBirthVisible', 'dateOfBirthRequired', 'dateOfBirthEditable'],
            expandEventStream,
        });

        this.dateOfBirth = ko.observable(this.master.dateOfBirth()).extend({
            required: profile.dateOfBirthRequired,
            trackDirtyState: true,
        });
        this.displayName = profile.dateOfBirth;
    }
}

class NicknameSectionViewModel extends ProfileSectionBaseViewModel {
    constructor({ profile, expandEventStream }) {
        super({ master: profile, properties: ['nickname', 'nicknameVisible', 'nicknameEditable', 'nicknameRequired'], expandEventStream });
        this.nickname = ko.observable(profile.nickname()).extend({
            required: profile.nicknameRequired,
        });

        const f = async nick => {
            if (nick) {
                try {
                    return await validateNickname(nick);
                } catch (error) {
                    // Nothing
                }
            }
            return {
                errors: [{ resourceKey: 'Dialogs.Validators.Required' }],
                isValid: false,
            };
        };
        const mf = memoize(f);

        const validationMessage = ko.observable();
        this.nicknameValidation = ko
            .computed(() => (this.nickname() !== undefined && this.nickname() !== this.master.nickname() ? mf(this.nickname()) : undefined))
            .extend({
                async: true,
                customValidator: {
                    message: validationMessage,
                    handler: validationResult => {
                        if (validationResult === undefined || validationResult.isValid) {
                            return true;
                        }
                        validationMessage(ndRes.val(validationResult.errors[0].resourceKey, validationResult.errors[0].message));
                        return false;
                    },
                },
            });
    }

    save() {
        if (validate(this)) {
            super.save();
        }
    }
}

class PublicPhoneSectionViewModel extends ProfileSectionBaseViewModel {
    constructor({ profile, expandEventStream }) {
        super({
            master: profile,
            properties: ['publicPhone', 'publicPhoneVisible', 'publicPhoneEditable', 'publicPhoneRequired'], // eslint-disable-line max-len
            expandEventStream,
        });
    }
}

class UserPhotoSectionViewModel extends ProfileSectionBaseViewModel {
    constructor({ profile }) {
        super({ master: profile, properties: [] });

        this.nickname = profile.nickname;
        this.photoUrl = profile.photoUrl;
        this.avatarImageId = profile.avatarImageId;
    }
}

class AddressLine1SectionViewModel extends ProfileSectionBaseViewModel {
    constructor({ profile, expandEventStream }) {
        super({
            master: profile,
            properties: ['addressLine1', 'addressLine1Visible', 'addressLine1Required', 'addressLine1Editable'],
            expandEventStream,
        });

        this.addressLine1 = ko.observable(this.master.addressLine1()).extend({
            required: profile.addressLine1Required,
            trackDirtyState: true,
        });
        this.displayName = this.addressLine1;
        this.isAddress = true;
    }
}

class AddressLine2SectionViewModel extends ProfileSectionBaseViewModel {
    constructor({ profile, expandEventStream }) {
        super({
            master: profile,
            properties: ['addressLine2', 'addressLine2Visible', 'addressLine2Required', 'addressLine2Editable'],
            expandEventStream,
        });

        this.addressLine2 = ko.observable(this.master.addressLine1()).extend({
            required: profile.addressLine2Required,
            trackDirtyState: true,
        });

        this.displayName = this.addressLine2;
        this.isAddress = true;
    }
}

class CitySectionViewModel extends ProfileSectionBaseViewModel {
    constructor({ profile, expandEventStream }) {
        super({ master: profile, properties: ['city', 'cityVisible', 'cityRequired', 'cityEditable'], expandEventStream });

        this.city = ko.observable(this.master.city()).extend({
            required: profile.cityRequired,
            trackDirtyState: true,
        });
        this.displayName = this.city;
        this.isAddress = true;
    }
}

class PostalCodeSectionViewModel extends ProfileSectionBaseViewModel {
    constructor({ profile, expandEventStream }) {
        super({ master: profile, properties: ['postalCode', 'postalCodeVisible', 'postalCodeRequired', 'postalCodeEditable'], expandEventStream });

        this.postalCode = ko.observable(this.master.postalCode()).extend({
            required: profile.postalCodeRequired,
            trackDirtyState: true,
        });
        this.displayName = this.postalCode;
        this.isAddress = true;
    }
}

class CountrySectionViewModel extends ProfileSectionBaseViewModel {
    constructor({ profile, expandEventStream }) {
        super({
            master: profile,
            properties: ['countryCode', 'countryVisible', 'countryEditable', 'countryRequired', 'availableCountries'],
            expandEventStream,
        });

        this.countryCode = ko.observable(this.master.countryCode()).extend({
            required: profile.countryRequired,
            trackDirtyState: true,
        });

        this.displayName = ko.pureComputed(() => {
            const country = find(this.availableCountries(), x => x.code === this.countryCode());
            return country ? country.name : this.countryCode();
        });
        this.isAddress = true;
    }
}

class RegionSectionViewModel extends ProfileSectionBaseViewModel {
    constructor({ profile, expandEventStream }) {
        super({
            master: profile,
            properties: ['regionCode', 'regionName', 'regionVisible', 'regionRequired', 'regionEditable', 'availableRegions'],
            expandEventStream,
        });

        this.regionCode = ko.observable(this.master.regionCode());

        this.displayName = ko.pureComputed(() => {
            if (this.regionName()) {
                return this.regionName();
            }
            const region = find(this.availableRegions(), x => x.code === this.regionCode());
            return region ? region.name : this.regionCode();
        });

        this.regionCode.subscribe(() => {
            const region = find(this.availableRegions(), x => x.code === this.regionCode());
            if (region?.code) {
                this.regionName(region.name);
            }
        });

        this.useDropDown = ko.pureComputed(() => this.availableRegions().length > 0);
        this.isAddress = true;
    }
}

class MobileNumberSectionViewModel extends ProfileSectionBaseViewModel {
    constructor({ profile, expandEventStream }) {
        super({
            master: profile,
            properties: ['mobileNumber', 'mobileNumberVisible', 'mobileNumberRequired', 'mobileNumberEditable'],
            expandEventStream,
        });

        this.mobileNumber = ko.observable(this.master.mobileNumber()).extend({
            required: profile.mobileNumberRequired,
            trackDirtyState: true,
        });
        this.displayName = profile.mobileNumber;
    }
}

export {
    NameSectionViewModel,
    DateOfBirthSectionViewModel,
    NicknameSectionViewModel,
    PublicPhoneSectionViewModel,
    UserPhotoSectionViewModel,
    AddressLine1SectionViewModel,
    AddressLine2SectionViewModel,
    CitySectionViewModel,
    CountrySectionViewModel,
    RegionSectionViewModel,
    PostalCodeSectionViewModel,
    MobileNumberSectionViewModel,
    ConsentSectionViewModel,
};
