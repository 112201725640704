import * as ko from 'knockout';
import * as $ from 'jquery';
        

        function OldValueTwoWayBindingHandler() {
            this.update = function (element, valueAccessor) {
                ko.bindingHandlers.attr.update(element, function () {
                    return { 'data-field-old-value': valueAccessor() }
                });
            };

            this.init = function(element, valueAccessor) {
                var $element = $(element);
                $element.on('oldValueChanged', function() {
                    valueAccessor()($element.attr('data-field-old-value'));
                });

                ko.utils.domNodeDisposal.addDisposeCallback(element, function onElementDispose() {
                    $element.off('oldValueChanged');
                    $element = null;
                });
            };
        }

        ko.bindingHandlers['oldValue'] = new OldValueTwoWayBindingHandler();
    