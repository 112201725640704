import { dispatch, runAction } from '@pressreader/appstore';
import { configLoadDone, configLoadFailed, loadConfigAction, setGetConfigService } from '@pressreader/config';
import { error } from '@pressreader/logger';
import { loadResourcesAction, resourcesLoadDone, resourcesLoadFailed, setGetResourcesService } from '@pressreader/resources';
import { svcAuth } from '@pressreader/services';

import { getConfig } from './api/get-config';
import { getResources } from './api/get-resources';
import { initCookieBot } from './cookiebot';
import { coreModuleLoadDone } from './store';
import { checkPervasiveUrlQueryParam, resolveExperimentalEnabled, resolveFaceliftEnabled, resolveTargetPlatform } from './utils';

let initialized = false;

export async function init() {
    if (initialized) {
        return;
    }

    svcAuth.bind('tokenUpdated', () => {
        dispatch(loadConfigAction());
    });

    initialized = true;

    setGetConfigService(getConfig);
    setGetResourcesService(getResources);

    try {
        await runAction(loadConfigAction(), configLoadDone, configLoadFailed);
    } catch (e) {
        // eslint-disable-next-line no-restricted-globals
        error('Cannot load config.', e);
        return;
    }

    try {
        await runAction(loadResourcesAction(), resourcesLoadDone, resourcesLoadFailed);
    } catch (e) {
        // eslint-disable-next-line no-restricted-globals
        error('Cannot load resources.', e);
        return;
    }

    checkPervasiveUrlQueryParam();
    const targetPlatform = resolveTargetPlatform();
    const faceliftEnabled = resolveFaceliftEnabled();
    const experimentalEnabled = resolveExperimentalEnabled();

    initCookieBot();
    dispatch(coreModuleLoadDone({ targetPlatform, faceliftEnabled, experimentalEnabled }));
}
