import { components, isObservable, observable } from 'knockout';

import { avatarSizes, deleteAvatar, getAvatarHtml, updateAvatar } from '@pressreader/account-profile';
import { ISize } from '@pressreader/geometry';

import Res from 'deprecated/nd.res';

import Alert from 'nd.alert';
import Logger from 'nd.logger';
import User from 'nd.user';
import ImageUpload, { IImageUploadResult } from 'storage/image.upload';

const DEFAULT_SIZE = avatarSizes.ginormous;
const MIN_ALLOWED_WIDTH = 16;
const MIN_ALLOWED_HEIGHT = 16;

const imageUploader = new ImageUpload('user-pictures');

class AvatarViewModel {
    public avatarImageId: KnockoutObservable<string>;
    public isAlterable: KnockoutObservable<boolean>;
    public isLoading: KnockoutObservable<boolean>;
    public nickname: KnockoutObservable<string>;
    public photoUrl: KnockoutObservable<string>;
    public avatarHtml: KnockoutObservable<string>;
    public deleteAvatarAvailable: KnockoutComputed<boolean>;
    public readonly size: ISize;

    constructor(options: { profile: any; alterable: boolean; size: ISize }) {
        this.isLoading = observable(false);
        this.avatarHtml = observable('');

        this.size = options.size || DEFAULT_SIZE;

        if (this.size.width < MIN_ALLOWED_WIDTH) {
            throw new Error(`Specified width (${options.size.width}) less than allowed ${MIN_ALLOWED_WIDTH}`);
        }

        if (this.size.height < MIN_ALLOWED_HEIGHT) {
            throw new Error(`Specified height (${options.size.height}) less than allowed ${MIN_ALLOWED_HEIGHT}`);
        }

        this.isAlterable = isObservable(options.alterable) ? options.alterable : observable(!!options.alterable);
        this.nickname = isObservable(options.profile.nickname) ? options.profile.nickname : observable(options.profile.nickname);
        this.photoUrl = isObservable(options.profile.photoUrl) ? options.profile.photoUrl : observable(options.profile.photoUrl);
        this.avatarImageId = isObservable(options.profile.avatarImageId)
            ? options.profile.avatarImageId
            : observable(options.profile.avatarImageId || '');
        this.isLoading = observable(false);

        const avatarUpdater = () => {
            this.avatarHtml(
                getAvatarHtml({
                    nickname: this.nickname(),
                    imageId: this.avatarImageId(),
                    size: this.size,
                    fallbackAvatarUrl: this.photoUrl(),
                }),
            );
        };
        this.nickname.subscribe(avatarUpdater);
        this.avatarImageId.subscribe(avatarUpdater);
        this.photoUrl.subscribe(avatarUpdater);
        this.deleteAvatarAvailable = ko.pureComputed(
            () => this.isAlterable() && !this.isLoading() && (this.avatarImageId() !== '' || this.photoUrl() !== ''),
        );

        avatarUpdater();
    }

    changeAvatar(): Promise<string> {
        if (!this.isAlterable()) {
            throw new Error('User photo is not alterable.');
        }

        if (this.isLoading()) {
            return Promise.reject(new Error('Alter is not available at the moment.'));
        }

        return this.upload({
            onUploadStart: () => {
                this.isLoading(true);
            },
        })
            .then(uploadResult => updateAvatar(uploadResult.fileId))
            .then(res => {
                this.avatarImageId(res.imageId);
                User.userStateChanged();
                return res.imageId;
            })
            .catch(error => {
                const message = error && error.message ? error.message : Res.val('Errors.ErrorOccurred', 'An error occurred.');
                Alert().alert(message, Res.val('Buttons.Ok.Text', 'OK'), true);
                Logger.error(message);
                throw error;
            })
            .finally(() => {
                this.isLoading(false);
            });
    }

    deleteAvatar() {
        this.isLoading(true);
        return deleteAvatar()
            .then(() => {
                this.avatarImageId('');
                this.photoUrl('');
                User.userStateChanged();
            })
            .catch(e => {
                Alert().alert(e.message || Res.val('Errors.ErrorOccurred', 'An error occurred.'), Res.val('Buttons.Ok.Text', 'OK'), true);
                throw e;
            })
            .finally(() => {
                this.isLoading(false);
            });
    }

    dispose(): void {
        this.nickname = null;
        this.avatarImageId = null;
        this.isAlterable = null;
    }

    private upload(uploadConfig: any = {}): Promise<IImageUploadResult> {
        return imageUploader.upload(uploadConfig);
    }
}

/**
 * Editable User Avatar Knockout component.
 *
 * @description Component configuration.
 * @param {Object} params Component configuration object.
 * @param {Object} params.profile - reference to user profile object
 *                                  or profile view model with observable properties 'nickname' and 'avatarImageId'.
 * @param {Number} [params.width=160] - width of photo.
 * @param {Number} [params.height=160] - height of photo.
 * @param {observable|Boolean} [params.alterable] - An observable expression that controls avatar alterability.
 *
 * @example
 *
 * ``` HTML
 *  <useravatar params="profile: profile, editable: true, width: 120, height: 120" />
 *  <useravatar params="profile: { nickname: another.nickname, avatarImageId: another.avatarImageId }, alterable: false" />
 *
 */

/**
 * Component registration
 */

const COMPONENT_NAME = 'userphoto';

const componentConfig = {
    viewModel: { createViewModel: params => new AvatarViewModel(params) },
    template: { ndTemplate: 'v7.Client.UserPhoto.ko.Component' },
};

if (!components.isRegistered(COMPONENT_NAME)) {
    components.register(COMPONENT_NAME, componentConfig);
}
