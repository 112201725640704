/* eslint-env es6 */
/* eslint-disable */
import '@pressreader/src/ko/bindings/afterrender';
import 'ko/bindings/attrAsync';
import '@pressreader/src/ko/bindings/autosize';
import '@pressreader/src/ko/bindings/clamp';
import '@pressreader/src/ko/bindings/click';
import 'ko/bindings/clickandtouch';
import '@pressreader/src/ko/bindings/clipboard';
import '@pressreader/src/ko/bindings/enableTouchScroll';
import '@pressreader/src/ko/bindings/oldvalue';
import '@pressreader/src/ko/bindings/ontransitionend';
import '@pressreader/src/ko/bindings/resizable';
import '@pressreader/src/ko/bindings/scrolledtobottomedge';
import '@pressreader/src/ko/bindings/slidevisible';
import 'ko/bindings/allowBindings';
import '@pressreader/src/ko/bindings/swipeclick';
import '@pressreader/src/ko/bindings/textwithdefault';
import '@pressreader/src/ko/bindings/twowaytext';
import '@pressreader/src/ko/bindings/uploadfile';
import '@pressreader/src/ko/bindings/validate';
import '@pressreader/src/ko/bindings/validationcontainer';
import '@pressreader/src/ko/bindings/repaint';
import '@pressreader/src/ko/bindings/resourceText';
import 'ko/bindings/externallinks';
import 'ko/bindings/fadeInVisible';
import 'ko/bindings/visibilitysensor';
import 'ko/bindings/analyticsEvent';
