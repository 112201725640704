import { interval } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import ndAlert from '@pressreader/src/nd.alert';

import ndRes from 'nd.res';
import ndServicesApi from 'nd.services.api';

enum PurchaseInfoProductTypeDto {
    Subscription = 0,
    Issue = 1,
    Bundle = 2,
}

enum PurchaseInfoPeriodTypeDto {
    Month = 0,
    Day = 1,
}

interface IPurchaseInfoPeriodDto {
    Type: PurchaseInfoPeriodTypeDto;
    Value: number;
}

interface IPurchaseInfoPriceDto {
    Currency: string;
    Value: number;
}

interface IPurchaseInfoPromoInfoDto {
    AdjustedPrice?: number;
    AdjustedPriceFormatted?: string;
    DurationDays: number;
    Id: string;
    NextId: string;
}

interface IPurchaseInfoProductDto {
    NumberOfIssues: number;
    IsUnlimitedIssues: boolean;
    IsAutoRenewable: boolean;
    IsPopularChoice: boolean;
    FormattedIssueDate: string;
    SubscriptionMaxMonitoringAlerts: number;
    SubscriptionMaxAutoDelivery: number;
    SubscriptionBackIssues: number;
    SubscriptionIssues: number;
    SubscriptionAdditionalIssueFormattedPrice: string;
    FormattedPrice: string;
    Price: IPurchaseInfoPriceDto;
    Name: string;
    Type: PurchaseInfoProductTypeDto;
    Id: string;
    Title?: string;
    Description?: string;
    Features?: string[];
    PromoInfo: IPurchaseInfoPromoInfoDto;
    NextSubscription: IPurchaseInfoProductDto;
    Period: IPurchaseInfoPeriodDto;
}

interface IPurchaseInfoIssueDto {
    IssueId: string;
    PublicationName: string;
    IssueDate: string;
    CouldPotentiallyBeRead: boolean;
}

interface IPurchaseInfoBillingInfoDto {
    AddressLine1: string;
    AddressLine2: string;
    CardName: string;
    CardholderName: string;
    CardRightDigits: string;
    City: string;
    RegionName: string;
    RegionCode: string;
    CountryName: string;
    CountryCode: string;
    PostalCode: string;
}

interface IPurchaseInfoDto {
    CurrentSubscriptionProductId: string;

    AllProducts: IPurchaseInfoProductDto[];
    Products: IPurchaseInfoProductDto[];
    BillingInfo: IPurchaseInfoBillingInfoDto;
    Bundles: IPurchaseInfoProductDto[];

    IssueInfo: IPurchaseInfoIssueDto;
}

interface IEligibilityStatus {
    isEligible: boolean;
    notEligibleReason: NotEligibleReasonType;
    promoCode: string;
}

interface ICheckoutResponse {
    OrderId: string;
    Status: 'Approved' | 'Pending' | 'Declined' | 'Error';
    ChallengeUrl: string;
    Result: unknown;
}

enum NotEligibleReasonType {
    None,
    TrialWasUsed,
    CreditCardWasUsed,
}

function getPurchaseInfo(requestedContent): Promise<IPurchaseInfoDto> {
    // product is not supported field for this request
    requestedContent = { ...requestedContent };
    delete requestedContent.product;

    return ndServicesApi.get('purchase', 'GetPurchaseInfo', requestedContent);
}

function getArchivePurchaseInfo(requestedContent): Promise<IPurchaseInfoDto> {
    return ndServicesApi.get('purchase', 'GetArchivePurchaseInfo', requestedContent);
}

function addProductToBasket(product): Promise<boolean> {
    return ndServicesApi.post('purchase', 'AddToBasket', product);
}

function getPurchaseContext(requestedContent): Promise<any> {
    return ndServicesApi.get('purchase', 'GetPurchaseContext', requestedContent);
}

function checkout(requestedContent): Promise<ICheckoutResponse> {
    return ndServicesApi.post('purchase', 'Checkout', requestedContent);
}

function confirm(requestedContent): Promise<void> {
    return ndServicesApi.post('purchase', 'Confirm', requestedContent);
}

function checkPendingOrder(orderId: string): Promise<ICheckoutResponse> {
    return ndServicesApi.get('purchase', `pending-order/${orderId}`);
}

function awaitPendingOrder(orderId: string): Promise<ICheckoutResponse> {
    return new Promise(resolve => {
        // start polling
        const pollingIntervalMilliseconds = 1000;
        interval(pollingIntervalMilliseconds)
            .pipe(
                switchMap(() => checkPendingOrder(orderId)),
                filter(({ Status }) => {
                    return Status !== 'Pending';
                }),
                take(1),
            )
            .subscribe(resolve);
    });
}

// legacy API do not use for new features
async function checkoutWithConfirmation(requestedContent): Promise<ICheckoutResponse> {
    let checkoutResponse = await checkout(requestedContent);

    checkoutResponse = await confirmCheckout(checkoutResponse);

    if (checkoutResponse.Status !== 'Approved') {
        throw new Error(checkoutResponse.Status);
    }
    return checkoutResponse;
}

async function confirmCheckout(checkoutResponse: ICheckoutResponse): Promise<ICheckoutResponse> {
    if (checkoutResponse.Status !== 'Pending') {
        return checkoutResponse;
    }

    let wnd = window.open(checkoutResponse.ChallengeUrl);
    if (!wnd) {
        await ndAlert().confirm(ndRes.val('v7.Client.Dialogs.ConfirmCreditCard'), ndRes.val('Buttons.Cancel.Text'), ndRes.val('Buttons.Confirm'));
        wnd = window.open(checkoutResponse.ChallengeUrl);
    }

    const response = await awaitPendingOrder(checkoutResponse.OrderId);
    wnd?.close();

    return response;
}

function getUserPromoEligibility(requestedContent?: any): Promise<IEligibilityStatus> {
    return ndServicesApi.get('subscriptions', 'trial/eligibility', requestedContent).then(
        (result: IEligibilityStatus) => result,
        () => ({ isEligible: false, notEligibleReason: NotEligibleReasonType.None, promoCode: '' }),
    );
}

export {
    IPurchaseInfoDto,
    IPurchaseInfoIssueDto,
    IPurchaseInfoProductDto,
    IPurchaseInfoPromoInfoDto,
    IPurchaseInfoPriceDto,
    PurchaseInfoProductTypeDto,
    PurchaseInfoPeriodTypeDto,
};

export default {
    getPurchaseInfo,
    getArchivePurchaseInfo,
    addProductToBasket,
    getPurchaseContext,
    checkout,
    confirm,
    checkoutWithConfirmation,
    getUserPromoEligibility,
    awaitPendingOrder,
};
