import { AuthenticationType } from '@pressreader/src/authentication/authenticationtypes';
import { IEvent } from '@pressreader/src/eventbus/events';

enum EventTypes {
    SignInRequested = '[Authentication] SignIn',
    SignInSucceeded = '[Authentication] SignInSucceeded',
    SignInFailed = '[Authentication] SignInFailed',

    SignUpRequested = '[Authentication] SignUp',
    SignUpSucceeded = '[Authentication] SignUpSucceeded',
    SignUpFailed = '[Authentication] SignUpFailed',

    AccountChanged = '[Authentication] Account Changed',

    SignUpEmailAlertSet = '[Authentication] SignUpEmailAlertSet',
}

enum EmailAlertTypes {
    EmailAlertPromotional = 'EmailAlerts.Promotional',
    EmailAlertTechnical = 'EmailAlerts.Technical',
}

class SignInRequestedEvent implements IEvent {
    readonly type = EventTypes.SignInRequested;

    constructor(public payload: { system: AuthenticationType }) {}
}

class SignInSucceededEvent implements IEvent {
    readonly type = EventTypes.SignInSucceeded;

    constructor(public payload: { system: AuthenticationType }) {}
}

class SignInFailedEvent implements IEvent {
    readonly type = EventTypes.SignInFailed;

    constructor(public payload: { system: AuthenticationType }) {}
}

class SignUpRequestedEvent implements IEvent {
    readonly type = EventTypes.SignUpRequested;

    constructor(public payload: { system: AuthenticationType }) {}
}

class SignUpSucceededEvent implements IEvent {
    readonly type = EventTypes.SignUpSucceeded;

    constructor(public payload: { system: AuthenticationType }) {}
}

class SignUpFailedEvent implements IEvent {
    readonly type = EventTypes.SignUpFailed;

    constructor(public payload: { system: AuthenticationType }) {}
}

class AccountChangedEvent implements IEvent {
    readonly type = EventTypes.AccountChanged;

    // eslint-disable-next-line @typescript-eslint/ban-types
    constructor(public payload?: {}) {}
}

class SignUpEmailAlertSetEvent implements IEvent {
    readonly type = EventTypes.SignUpEmailAlertSet;

    constructor(public payload: { alertKey: EmailAlertTypes; value: boolean; isDefault: boolean }) {}
}

export {
    EventTypes,
    EmailAlertTypes,
    SignInRequestedEvent,
    SignInSucceededEvent,
    SignInFailedEvent,
    SignUpRequestedEvent,
    SignUpSucceededEvent,
    SignUpFailedEvent,
    AccountChangedEvent,
    SignUpEmailAlertSetEvent,
};
