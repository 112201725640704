import * as $ from 'jquery';
import * as _ from 'lodash';
import * as ko from 'knockout';

import ndRes from 'nd.res';
import general from 'nd.views.general';
import $ndTemplates from 'nd.templates';
import ndLayout from '@pressreader/src/nd.layout';
import ndLoading from '@pressreader/src/shell/loading.indicator';
import { analytics } from 'analytics/analytics';
import ndUtils from '@pressreader/src/nd.utils';
import ManageAccountViewModel from 'account/viewmodels/manage.account.viewmodel';
import { getSupportedCardTypes, getExpirationMonths, getExpirationYears } from '@pressreader/src/account/services/creditcard.service';
import { getCountries, getRegions } from '@pressreader/src/account/services/address.service';
import { logAndAlertError } from '@pressreader/src/shared/errors/error.handlers';
import { getCurrentUserBundles } from 'bundle/provider';
import ndAlert from '@pressreader/src/nd.alert';
import ndConfig from '@pressreader/src/deprecated/nd.config';
import accountMenu from 'nd.ui.menu.account';
import { getCreditCard, updateCreditCardWithConfirmation, deleteCreditCard, getProfileConfig } from 'account/services/account.service';
import { getCurrentSubscription } from 'subscriptions/services';
import '@pressreader/src/account/components/userphoto.component';
import 'jquery-validation';

var commonView = (function () {
    return {
        init: function () {
            $ndTemplates.loaded().then(function () {
                $('.toolbar-button-signin').click(function () {
                    var toolbar = $('.toolbar-in');

                    accountMenu.show({
                        parentSelector: '.l-content',
                        position: {
                            x: function () {
                                return $.windowWidth() - 50;
                            },
                            y: toolbar.height() + 16,
                            align: {
                                x: $.nd.ui.menu.align.right,
                                y: $.nd.ui.menu.align.top,
                            },
                        },
                    });
                });

                return false;
            });
        },
    };
})();

function createMutableValue(value, placeholder) {
    return placeholder
        ? { current: ko.observable(value), old: ko.observable(value ? value : ''), placeholder }
        : { current: ko.observable(value), old: ko.observable(value ? value : '') };
}

var settingsTabs = {
    ACCOUNT: 1,
    CHANNEL: 2,
    SUBSCRIPTION: 3,
    NOTIFICATION: 4,
    CAMPAIGNS: 5,
    PUBLISHER: 6,
};

// custom anchors to parts of account page
// this map holds location of anchor
var customAnchors = {
    manageactivations: { tab: settingsTabs.ACCOUNT },
};

var anchorTabMap = {
    account: settingsTabs.ACCOUNT,
    channel: settingsTabs.CHANNEL,
    subscription: settingsTabs.SUBSCRIPTION,
    notifications: settingsTabs.NOTIFICATION,
    campaigns: settingsTabs.CAMPAIGNS,
    publisher: settingsTabs.PUBLISHER,
};

var tabUrlHashMap = _.invert(anchorTabMap);

var viewMode = {
    profile: 'profile',
    receipt: 'receipt',
};

var creditCardStyles = {
    mastercard: 'cc-mc',
    visa: 'cc-visa',
    amex: 'cc-amex',
    discover: 'cc-discover',
    diners: 'cc-diners',
    jcb: 'cc-jcb',
    paypal: 'cc-paypal',
};

viewMode.current = ko.observable(viewMode.profile);

function addCustomValidationRule(name, validation, message) {
    $.validator.addMethod(name, validation);
    if (message) {
        $.validator.messages[name] = message;
    }
}

function isCreditCardExpirationDateValid(year, month) {
    var expDate = new Date(year, month, 1);
    var curDate = new Date();

    return expDate >= new Date(curDate.getFullYear(), curDate.getMonth(), 1);
}

function inputEscapeAllButNumbers(e) {
    var theEvent = e || window.event;
    var key = theEvent.keyCode || theEvent.which;

    if (
        (key >= 48 && key <= 57) || // digits
        key === 8 || // backspace
        key === 27 || // escape
        key === 46 || // delete
        (key >= 37 && key <= 40)
    ) {
        // arrows
        return;
    }

    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
}

function getCreditCardStyle(accountNumber) {
    var result = '';
    if (/^5[1-5]/.test(accountNumber)) {
        result = creditCardStyles.mastercard;
    } else if (/^4/.test(accountNumber)) {
        result = creditCardStyles.visa;
    } else if (/^3[47]/.test(accountNumber)) {
        result = creditCardStyles.amex;
    } else if (/^6011|^65/.test(accountNumber)) {
        result = creditCardStyles.discover;
    } else if (/^3(?:0[0-5]|[68])/.test(accountNumber)) {
        result = creditCardStyles.diners;
    } else if (/^(?:2131|1800|35)/.test(accountNumber)) {
        result = creditCardStyles.jcb;
    }
    return result;
}

function CreditCardViewModel() {
    var self = this;

    var validationSettings = $.extend(
        {
            rules: {
                CardholderName: {
                    required: true,
                },
                CardNumber: {
                    usercreditcardnumber: {
                        old: '[name="ReadOnlyCardNumber"]',
                    },
                },
                'ExpDate.Month': {
                    required: true,
                    creditcardexpirationdate: {
                        year: '[name="ExpDate.Year"]',
                        month: '[name="ExpDate.Month"]',
                    },
                },
                'ExpDate.Year': {
                    required: true,
                    creditcardexpirationdate: {
                        year: '[name="ExpDate.Year"]',
                        month: '[name="ExpDate.Month"]',
                    },
                },
                CVVCode: {
                    required: true,
                },
                AddressLine1: {
                    required: true,
                },
                City: {
                    required: true,
                },
                CountryCode: {
                    usercreditcardcountry: {
                        emptyValue: '-',
                    },
                },
                ProvinceCode: {
                    usercreditcardcountry: {
                        emptyValue: '-',
                    },
                },
                ProvinceName: {
                    required: true,
                },
                PostalCode: {
                    required: true,
                },
            },
        },
        general.jqValidation(),
    );

    this.form = $('#creditCardForm');
    this.form.validate(validationSettings);

    function createSelectList(items, selectedValue) {
        return {
            list: items,
            selected: createMutableValue(selectedValue),
        };
    }

    function getCardType(keyProp, keyValue) {
        var length = self.cardTypes.items.length;
        for (var i = 0; i < length; i++) {
            var propVal = self.cardTypes.items[i][keyProp];
            var value = typeof propVal === 'function' ? propVal() : propVal;
            if (value === keyValue) {
                return self.cardTypes.items[i];
            }
        }
    }

    function loadReferenceData() {
        return Promise.all([getSupportedCardTypes(), getExpirationMonths(), getExpirationYears(), getCountries()]).then(
            ([supportedCardTypes, expirationMonths, expirationYears, countries]) => {
                self.supportedCardTypes = _.map(supportedCardTypes, item => {
                    return { ClassName: item.css, ImageName: item.image, TypeName: item.name };
                });
                self.expirationMonths = _.map(expirationMonths, item => {
                    return { Key: item.key, Value: item.value };
                });
                self.expirationYears = _.map(expirationYears, item => {
                    return { Key: item.key, Value: item.value };
                });
                self.countries = _.map(countries, item => {
                    return { Key: item.code, Value: item.name };
                });
            },
        );
    }

    function loadRegions(countryCode) {
        return getRegions(countryCode).then(regions => {
            self.regions = _.map(regions, item => {
                return { Key: item.code || '-', Value: item.name };
            });
        });
    }

    function init(data) {
        const { billingAddress } = data;

        self.addressLine1 = createMutableValue(billingAddress?.addressLine1 || '');
        self.cvvCode = createMutableValue('');

        const cardNumber = createMutableValue('');
        self.cardNumber = $.extend(
            {
                placeholder: data.lastFourDigits ? `**** **** **** ${data.lastFourDigits}` : ndRes.val('CreditCardView.CreditCardNumber'),
            },
            cardNumber,
        );

        self.cardholderName = createMutableValue(data.cardholderName);
        self.city = createMutableValue(billingAddress?.city || '');
        self.countries = createSelectList(self.countries, billingAddress?.countryCode || window.country);

        self.displayInfo = ko.observable(
            data.lastFourDigits ? `**** ${data.lastFourDigits} expires ${data.expirationMonth}/${data.expirationYear % 100}` : '',
        );

        self.expirationMonths = createSelectList(self.expirationMonths, data.expirationMonth || 12);
        self.expirationYears = createSelectList(self.expirationYears, data.expirationYear || new Date().getFullYear + 1);
        self.postalCode = createMutableValue(billingAddress?.postalCode || '');
        self.provinces = {
            selectedName: createMutableValue(billingAddress?.regionName || ''),
            items: ko.observable(self.regions),
            selectedItem: createMutableValue(billingAddress?.regionCode || ''),
            country: ko.observable(billingAddress?.countryCode || ''),
        };
        self.cardTypes = {
            items: _.map(self.supportedCardTypes, function (item) {
                return { name: ko.observable(item.TypeName), css: item.ClassName };
            }),
        };
        self.cardTypes.selected = createMutableValue(getCardType('name', data.cardType));
        self.isSubmitting = ko.observable(false);
    }

    this.load = async function () {
        await loadReferenceData();
        const data = await getCreditCard();
        await loadRegions(data.billingAddress?.countryCode || window.country);
        init(data);
    };

    this.determineCardType = function () {
        var number = self.cardNumber.current();
        self.cardTypes.selected.current({});
        if (number) {
            var className = getCreditCardStyle(number);
            if (!className) return;
            self.cardTypes.selected.current(getCardType('css', className));
        }
    };

    this.clearCustomFields = function () {
        function resetProvince() {
            self.provinces.selectedName.current(self.provinces.selectedName.old());
            self.provinces.selectedItem.current(self.provinces.selectedItem.old() || '-');
        }

        var country = self.countries.selected;
        var oldCountry = country.old();
        var newCountry = country.current();

        country.current(null);
        country.current(oldCountry);

        if (oldCountry != self.provinces.country()) {
            self.loadProvinces().then(resetProvince);
        } else {
            resetProvince();
        }

        self.cardTypes.selected.current({});
        self.cardTypes.selected.current(self.cardTypes.selected.old());
    };

    this.loadProvinces = async function () {
        if (self.countries.selected.current() === self.provinces.country()) {
            return;
        }

        await loadRegions(self.countries.selected.current());
        self.provinces.items(self.regions);
        self.provinces.country(self.countries.selected.current());
    };

    this.deleteCreditCard = function () {
        ndAlert()
            .confirm(ndRes.val('CreditCard.Delete.DoubleCheckConfirmationMessage'), ndRes.val('Buttons.No.Text'), ndRes.val('Buttons.Yes.Text'), true)
            .then(function () {
                self.isSubmitting(true);
                deleteCreditCard()
                    .then(() => {
                        window.location.reload();
                    })
                    .catch(logAndAlertError)
                    .finally(() => {
                        self.isSubmitting(false);
                    });
            });
    };

    this.save = function () {
        if (this.form.valid()) {
            const update = {
                cardNumber: self.cardNumber.current(),
                cardholderName: self.cardholderName.current(),
                expirationYear: self.expirationYears.selected.current(),
                expirationMonth: self.expirationMonths.selected.current(),
                cvv: self.cvvCode.current(),
                billingAddress: {
                    countryCode: self.countries.selected.current(),
                    regionCode: self.provinces.selectedItem.current() || null,
                    regionName: self.provinces.selectedItem.current() ? null : self.provinces.selectedName.current(),
                    city: self.city.current(),
                    postalCode: self.postalCode.current(),
                    addressLine1: self.addressLine1.current(),
                },
            };
            self.isSubmitting(true);

            updateCreditCardWithConfirmation(update)
                .then(result => {
                    if (result.status !== 'Approved') {
                        throw new Error(ndRes.val('Checkout.DeclinedMessage'));
                    }
                })
                .then(() => {
                    window.location.reload();
                })
                .catch(logAndAlertError)
                .finally(() => {
                    self.isSubmitting(false);
                });
        } else {
            return false;
        }
    };
}

function MyAccountViewModel(creditCard, profileConfig) {
    this.getString = function (property, defaultString) {
        if (!property) return defaultString;

        if (typeof property === 'function') {
            var res = property();
            return res ? res : defaultString;
        }

        return property;
    };

    this.isSubscriptionAvailable = profileConfig.isSubscriptionAvailable;
    this.isMultipleSessionsAllowed = profileConfig.isMultipleSessionsAllowed;

    this.creditCard = creditCard;

    this.viewMode = viewMode;
    this.enableAutoShare = ko.observable(ndConfig.get('ui.MyAccount.enableAutoshare', false));
    this.enableSmartCollections = ko.observable(ndConfig.get('ui.MyAccount.enableSmartCollections', false));
    this.enableRssImport = ko.observable(ndConfig.get('ui.MyAccount.enableRssImport', false));
    this.openExternalChangePasswordPage = function () {
        var url =
            ndConfig.get('Authentication.ExternalChangePasswordPage', false) + ndConfig.get('Authentication.Janrain.settings.capture.brand', false);
        window.open(url, '_blank');
    };

    this.tabs = settingsTabs;
    this.selectTab = function (tab) {
        if (tab !== this.selectedTab()) {
            window.location.hash = '#' + tabUrlHashMap[tab];
            this.selectedTab(tab);
        }
    };

    this.tabsVisibility = {};
    this.tabsVisibility[settingsTabs.ACCOUNT] = ko.observable(profileConfig.showProfileInfo);
    this.tabsVisibility[settingsTabs.CHANNEL] = ko.observable(false);
    this.tabsVisibility[settingsTabs.NOTIFICATION] = ko.observable(false);
    this.tabsVisibility[settingsTabs.SUBSCRIPTION] = ko.observable(
        profileConfig.showSubscriptionInfo || profileConfig.showPaymentHistory || profileConfig.showCreditCardInfo,
    );
    this.tabsVisibility[settingsTabs.PUBLISHER] = ko.observable(true);
    this.tabsVisibility[settingsTabs.CAMPAIGNS] = ko.observable(false);

    this.isTabVisible = function (tab) {
        return this.tabsVisibility[tab] && this.tabsVisibility[tab]();
    };

    this.selectedTab = ko.observable(this.isTabVisible(this.tabs.ACCOUNT) ? this.tabs.ACCOUNT : this.tabs.SUBSCRIPTION);

    // inform view, that model is ready for interaction
    this.ready = ko.observable(false);
}

var myAccountView = (function () {
    return {
        init: function () {
            ndRes.loaded().then(() => {
                const creditCard = new CreditCardViewModel();
                Promise.all([getProfileConfig(), getCurrentSubscription(), getCurrentUserBundles(), ndConfig.loaded(), creditCard.load()]).then(
                    ([profileConfig, subscription, bundles]) => {
                        var viewModel = new MyAccountViewModel(creditCard, profileConfig);
                        var manageAccountVm = new ManageAccountViewModel(subscription, bundles, profileConfig);

                        $.extend(viewModel, manageAccountVm);

                        // prepare temporary communication (will be moved inside ManageAccountViewModel)
                        manageAccountVm.profile.isLoaded.subscribe(function (profileLoaded) {
                            viewModel.tabsVisibility[settingsTabs.ACCOUNT](profileLoaded);
                        });

                        ko.applyBindings(viewModel);
                        // we ready to show something, let's start
                        ndLoading.hide();
                        viewModel.ready(true);

                        // load stuff that can be loaded later
                        manageAccountVm.load().then(function () {
                            // process url hash
                            var anchorName = window.location.hash.substr(1);
                            if (anchorName) {
                                if (customAnchors[anchorName]) {
                                    viewModel.selectTab(customAnchors[anchorName].tab);
                                    var anchorElement = $('a[name=' + anchorName + ']');
                                    // anchor element found
                                    if (anchorElement.length > 0) {
                                        $(document.body).scrollTop(anchorElement.offset().top);
                                    } else {
                                        window.console.warn('Anchor "' + anchorName + '" not found');
                                    }
                                } else if (anchorTabMap[anchorName]) {
                                    viewModel.selectTab(anchorTabMap[anchorName]);
                                } else {
                                    window.console.warn('Anchor "' + anchorName + '" not found');
                                }
                            }
                        });
                    },
                );
            });
        },
    };
})();

function handleUriParams() {
    var params = _.mapKeys(ndUtils.getUrlParameters(), function (_v, k) {
            return k.toLowerCase();
        }),
        noTopbarParam = _.has(params, 'notopbar') ? _.get(params, 'notopbar') : false,
        platformParam = _.has(params, 'platform') ? _.get(params, 'platform') : false,
        accountUpgradeSubscriptionLink;

    if (noTopbarParam !== false) {
        $('#toolbarTop').hide();
        accountUpgradeSubscriptionLink = $('#accountUpgradeSubscriptionLink');
        let href = accountUpgradeSubscriptionLink.attr('href').toLowerCase();
        if (!href.startsWith('http')) {
            href = `${window.location.origin}${window.basePath}${href}`;
        }
        const url = new URL(href);
        const params = new URLSearchParams(url.search);
        params.append('notopbar', undefined);
        accountUpgradeSubscriptionLink.attr('href', `${url}?${params}`);
    }
    if (platformParam && platformParam.toLowerCase && platformParam.toLowerCase() === 'ios') {
        $('#accountSubscriptionSection').hide();
        $('#paymentMethodSection').hide();
    }
}

let initialized = false;
function moduleInit(serverData) {
    if (initialized) {
        return;
    }
    initialized = true;

    var cc = $('#CardNumber'),
        cvv = $('#CVVCode');

    // Looking for loading panel built-in into static markup
    var loadingPanel = $('#Panel_loadingPanel');
    if (loadingPanel.length === 0) {
        var loadingPanel = ndLayout
            .root({
                name: 'loadingPanel',
                width: ndLayout.size.fill,
                height: ndLayout.size.fill,
                left: ndLayout.pos.auto,
                top: ndLayout.pos.auto,
                absolute: true,
            })
            .elm();
        loadingPanel.addClass('panel panel-loading').css({ zIndex: 10, overflow: 'hidden' });
    }

    handleUriParams();
    ndLoading.init(loadingPanel[0]);
    ndLoading.show();
    loadingPanel.resize();

    addCustomValidationRule(
        'usercreditcardnumber',
        function (value, element, params) {
            if (value === $(params.old).val()) {
                return true;
            }

            return $.validator.methods.creditcard.call(this, value, element);
        },
        $.validator.messages.creditcard,
    );

    addCustomValidationRule(
        'usercreditcardcountry',
        function (value, element, params) {
            if (value === params.emptyValue) {
                return false;
            }

            return $.validator.methods.required.call(this, value, element, params);
        },
        $.validator.messages.required,
    );

    addCustomValidationRule('creditcardexpirationdate', function (value, element, params) {
        var year = $(params.year).val();
        var month = $(params.month).val() - 1;

        return isCreditCardExpirationDateValid(year, month);
    });

    myAccountView.init(serverData);
    commonView.init();

    $('a.logo').click(function () {
        window.location = 'http://' + window.currentHostName + window.basePath;
    });

    cc.on('keypress', inputEscapeAllButNumbers).on('click focusin', function onClickOrFocus() {
        if (this.value && this.value.indexOf('#') > -1) {
            this.value = '';
        }
    });

    cvv.on('keypress', inputEscapeAllButNumbers);

    analytics.pageView({ page: 'UserAccount' });
}

const module = {
    init: function init(serverData) {
        document.addEventListener(
            'DOMContentLoaded',
            () => {
                if (/accounting\/account/i.test(document.location.pathname)) {
                    moduleInit(serverData);
                }
            },
            {
                once: true,
            },
        );
    },
};

export const init = module.init.bind(module);

export default module;
