import uniqueId from 'lodash/uniqueId';

import { Path, Subscribers, To, Update } from '../types';

export function createKey() {
    return uniqueId('location_');
}

export function createHref(to: To) {
    return typeof to === 'string' ? to : createPath(to);
}

export function createPath({ pathname = '/', search = '', hash = '' }: Partial<Path>) {
    if (search && search !== '?') {
        pathname += search.charAt(0) === '?' ? search : '?' + search;
    }

    if (hash && hash !== '#') {
        pathname += hash.charAt(0) === '#' ? hash : '#' + hash;
    }

    return pathname;
}

export function parsePath(path: string): Partial<Path> {
    const parsedPath: Partial<Path> = {};

    if (path) {
        const hashIndex = path.indexOf('#');
        if (hashIndex >= 0) {
            parsedPath.hash = path.slice(hashIndex);
            path = path.slice(0, hashIndex);
        }

        const searchIndex = path.indexOf('?');
        if (searchIndex >= 0) {
            parsedPath.search = path.slice(searchIndex);
            path = path.slice(0, searchIndex);
        }

        if (path) {
            parsedPath.pathname = path;
        }
    }

    return parsedPath;
}

export function createSubscribers<F extends (arg: Update) => void>(): Subscribers<F> {
    let handlers: F[] = [];

    return {
        get length() {
            return handlers.length;
        },
        push(fn: F) {
            handlers.push(fn);
        },
        remove(fn: F) {
            handlers = handlers.filter(handler => handler !== fn);
        },
        call(arg) {
            handlers.forEach(fn => fn?.(arg));
        },
    };
}
