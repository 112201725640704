import assign from 'lodash/assign';
import noop from 'lodash/noop';
import { getCurrentPath, withAbsolutePath, ensureError, updateUlrWithLangCode, withRelativePath } from './routing.utils';
import { ExecCallbackFn, Route } from './route';
import { createConstraint } from './route.constraint';

export interface IBuildExecConfigOptions {
    state?: Record<string, unknown>;
    stateLess?: Record<string, unknown>;
}

/**
 * @param name Route name (optional).
 * @param template Route template.
 * @param defaults Route segments default values.
 * @param constraints Route constraints.
 * @param looseQuery Indicator for arbitrary query string.
 * @param executeWith Route execution handler, called after successful context resolving.
 */
export interface IRouteConfig {
    name?: string;
    template?: string;
    defaults?: Record<string, unknown>;
    constraints?: Record<string, Parameters<typeof createConstraint>[0]>;
    looseQuery?: boolean;
    executeWith?: ExecCallbackFn;
}

const buildExecConfig = (routeData: Record<string, unknown>, { state, stateLess }: IBuildExecConfigOptions = {}) => ({
    ...state,
    ...stateLess,
    ...routeData,
});

export function createRouter() {
    const routes: Route[] = [];

    /**
     * Register route.
     * Note that registering order is essential during state and url resolving.
     * @see {@link routing/route} for more details.
     * @example Route registration.
     *
     * router.addRoute({
     *     name: 'issue_country_newspaper_date_article',
     *     template: '{country}/{newspaper}/{issueDate}/{articleId}?{viewName}',
     *     allowLooseQuery: true,
     *     defaults: {
     *         country: 'ca',
     *     },
     *     constraints: {
     *         articleId: articleConstraint,
     *         viewName: new EqualsConstraint('Bookmarks'),
     *         url: () => true,   // constraint not necessarily corresponds to a template segment
     *     },
     *     executeWith: () => {},
     * });
     *
     * @param  config Route configuration object.
     * @return  Reference to this instance.
     */
    const addRoute = ({ name, template, looseQuery, defaults, constraints, executeWith }: IRouteConfig = {}) => {
        routes.push(new Route(name || '', template || '', defaults, constraints, executeWith, looseQuery));
        return { addRoute };
    };

    /**
     * Traverse through registered routes and resolve current url with given state.
     *
     * The traversing process is a bit complex due to route matching having async nature
     * and being split into two main stages (state resolving and route execution).
     *
     * Taking into consideration the fact that state resolving for a particular
     * route might take a long time to finish, each resolving is started synchronously.
     * The returned promises are aggregated into a single promise
     * which is resolved with the value produced by the first (by registering order, not by time) matching route,
     * or rejected with an error indicating that no matching routes are found.
     *
     * When such route is found it is executed and the result of execution is returned.
     * Even if the execution has failed, the next matching route must not be executed.
     *
     * @param  path location (path + query) to be resolved with route.
     * @param  state State to resolve location with.
     * @param  referrer A referrer url
     * @return Promise fulfilled with data combined from given state and resolved context.
     */
    const resolvePath = async (path: string, state: IBuildExecConfigOptions = {}, referrer?: string) => {
        const normalizedPath = withRelativePath(updateUlrWithLangCode(path, ''));

        const routeContextWait = routes
            .reduce<Promise<{ route: Route; routeData: Record<string, unknown> }>>((prev, route) => {
                // Kick off url parsing immediately
                const current = route.resolveContext(normalizedPath, referrer);
                current.catch(noop);
                return prev.catch(() => current);
            }, Promise.reject('Cannot resolve current path: no matching routes found'))
            .catch(error => Promise.reject(ensureError(error)));

        const { route, routeData } = await routeContextWait;

        const config = buildExecConfig(routeData, state);
        const newConfig = await route.execute(config);
        return newConfig || config; // Resolve with built config because route "executeWith" is not required to return it.
    };

    const resolveCurrentPath = async (state: IBuildExecConfigOptions = {}) => {
        return resolvePath(getCurrentPath(), state);
    };

    /**
     * Traverse through registered routes and build an absolute url from given data object.
     *
     * Refer to the description of {@link resolveCurrentPath} for a detailed explanation of route traversing order.
     * @param  [data] Data object to build a url from.
     * @return Promise fulfilled with built absolute url and parsed parameters: { url, parameters }.
     */
    const generateUrl = async (data: Record<string, any>) => {
        try {
            const { url, parameters } = await routes.reduce<Promise<{ parameters: Record<string, unknown>; url: string }>>((prev, route) => {
                // Kick off url generating immediately but resolve only if all previous failed.
                const currentGen = route.generateUrl(data);
                currentGen.catch(noop);
                return prev.catch(() => currentGen);
            }, Promise.reject('Cannot generate url: no matching routes found'));
            return { parameters: assign({}, data, parameters), url: withAbsolutePath(url) };
        } catch (error: any) {
            throw ensureError(error);
        }
    };

    return {
        routes,
        addRoute,
        resolvePath,
        resolveCurrentPath,
        generateUrl,
    };
}

export const routingManager = createRouter();
