import * as ko from 'knockout';
import resources from 'nd.res';
import SectionBaseViewModel from 'account/viewmodels/section.base.viewmodel';
import { setEmail } from 'account/services/account.service';
import { alertError } from '@pressreader/src/shared/errors/error.handlers';
import { validate, resetValidationState } from '@pressreader/src/ko/nd/validate';

class EmailSectionViewModel extends SectionBaseViewModel {
    constructor({ profile, expandEventStream }) {
        super({ master: profile, properties: ['email', 'emailAddressEditable'], expandEventStream });

        this.email = ko.observable(profile.email()).extend({
            required: resources.val('Dialogs.Signin.EmailRequired', 'Valid email address is required'),
            email: resources.val('Dialogs.Signin.EmailRequired', 'Valid email address is required'),
            trackDirtyState: true,
        });

        this.displayName = ko.pureComputed(() => this.email() || resources.val('Accounting.UserProfile.NoEmailAddress'));
    }

    _save() {
        if (!validate(this)) {
            return Promise.reject();
        }

        return setEmail(this.email())
            .then(() => {
                this.master.load();
                resetValidationState(this);
            })
            .catch(alertError);
    }

    restore() {
        super.restore();
        resetValidationState(this);
    }
}

export default EmailSectionViewModel;
