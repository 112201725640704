import { eventChannel } from 'redux-saga';
import { call, fork, put, select, take } from 'redux-saga/effects';

import { takeEvery } from '@pressreader/appstore';
import { error } from '@pressreader/logger';

import {
    getParams,
    historyBack,
    historyForward,
    historyGo,
    pushState,
    replaceState,
    replaceStateWithReload,
    subscribeToTransition,
    unsubscribeFromTransition,
    viewer,
} from '../api';
import { NavigationParams, ReplaceType, Update } from '../types';

import {
    backHistoryAction,
    forwardHistoryAction,
    goHistoryAction,
    goViewAction,
    pushStateToHistoryAction,
    replaceStateHistoryAction,
    replaceStateWithReloadHistoryAction,
    resetStateHistoryAction,
    updateLocationAction,
    updateStateHistoryAction,
} from './actions';
import { goTo } from './sagas.utils';
import { selectLocation, selectNavigationHistoryState } from './selectors';

function createTransitionChannel() {
    return eventChannel<Update>(emit => {
        subscribeToTransition(update => {
            emit(update);
        });

        return () => unsubscribeFromTransition(emit);
    });
}

function* watchOnTransitions() {
    const channel: ReturnType<typeof createTransitionChannel> = yield call(createTransitionChannel);

    while (true) {
        try {
            const payload: Update = yield take(channel);

            yield put(updateLocationAction(payload));
        } catch (err) {
            error(err);
        }
    }
}

function* goView({ payload }: ReturnType<typeof goViewAction>) {
    const { viewName, replace, isForceReplace, ignoreIssueInfo } = payload;

    const params: NavigationParams = { ...payload.params, viewName };

    if (!ignoreIssueInfo) {
        const { state }: ReturnType<typeof selectNavigationHistoryState> = yield select(selectNavigationHistoryState);
        if ('currentIssueInfo' in state) {
            params['currentIssueInfo'] = state['currentIssueInfo'];
        }
    }

    yield put(resetStateHistoryAction());

    const replaceType = !replace ? ReplaceType.Off : isForceReplace ? ReplaceType.ForceOn : ReplaceType.On;

    yield call(goTo, params, replaceType);
}

function* updateStateHistory({ payload }: ReturnType<typeof updateStateHistoryAction>) {
    const { params, url, reload = false } = payload;

    const location: ReturnType<typeof selectLocation> = yield select(selectLocation);
    const { state, viewName }: ReturnType<typeof selectNavigationHistoryState> = yield select(selectNavigationHistoryState);

    const to = url || location.href;
    const name = viewer?.getActiveViewName() || viewName;
    const allParams = name ? getParams(name, params) : { stateParams: {} };
    const stateParams = { ...state, ...allParams.stateParams };

    if (reload) {
        yield put(replaceStateWithReloadHistoryAction({ to, state: stateParams }));
    } else {
        yield put(replaceStateHistoryAction({ to, state: stateParams }));
    }
}

function* resetStateHistory() {
    let activeView = viewer?.getActiveView();

    if (!activeView) {
        const { viewName }: ReturnType<typeof selectNavigationHistoryState> = yield select(selectNavigationHistoryState);

        activeView = viewer?.findView(viewName);
    }

    if (activeView) {
        activeView.resetState?.();
        const params = activeView.getResetParams?.();

        if (params) {
            yield put(updateStateHistoryAction({ params }));
        }
    }
}

function* pushStateToHistory(action: ReturnType<typeof pushStateToHistoryAction>) {
    const {
        payload: { to, state },
    } = action;

    yield call(pushState, to, state);
}

function* replaceStateHistory(action: ReturnType<typeof replaceStateHistoryAction>) {
    const {
        payload: { to, state },
    } = action;

    yield call(replaceState, to, state);
}

function* replaceStateHistoryWithReload(action: ReturnType<typeof replaceStateWithReloadHistoryAction>) {
    const {
        payload: { to, state },
    } = action;

    yield call(replaceStateWithReload, to, state);
}

function* goHistory(action: ReturnType<typeof goHistoryAction>) {
    const { payload } = action;

    yield call(historyGo, payload);
}

function* backHistory() {
    yield call(historyBack);
}

function* forwardHistory() {
    yield call(historyForward);
}

function* saga() {
    yield fork(watchOnTransitions);

    yield takeEvery(goViewAction, goView);
    yield takeEvery(resetStateHistoryAction, resetStateHistory);
    yield takeEvery(updateStateHistoryAction, updateStateHistory);
    yield takeEvery(pushStateToHistoryAction, pushStateToHistory);
    yield takeEvery(replaceStateHistoryAction, replaceStateHistory);
    yield takeEvery(replaceStateWithReloadHistoryAction, replaceStateHistoryWithReload);
    yield takeEvery(goHistoryAction, goHistory);
    yield takeEvery(backHistoryAction, backHistory);
    yield takeEvery(forwardHistoryAction, forwardHistory);
}

export { saga };
