import { ArticleKeyType } from './keytype';
import { ArticleKeyBase } from './articlekey.base';
import { Long } from './long';

const flagForIssueKey = Long.fromNumber(281474976710656);
const minValue = 281474976710656;
const maxValue = 562949953421312;
const issueIdLength = 32;
const articleNumberLength = 16;

/**
 * Article issue key.
 */
class IssueKey extends ArticleKeyBase {
    issueId: number;
    articleNumber: number;
    constructor(issueId: number, articleNumber: number) {
        super(ArticleKeyType.Issue);
        this.issueId = issueId;
        this.articleNumber = articleNumber;
    }

    toBinary() {
        let binary = flagForIssueKey.or(Long.fromNumber(this.issueId));
        binary = binary.or(Long.fromNumber(this.articleNumber).shiftLeft(issueIdLength));
        return binary.toNumber();
    }

    static isValid(articleId: number) {
        return articleId >= minValue && articleId < maxValue;
    }

    static parse(articleId: number) {
        const articleKey = Long.fromNumber(articleId).or(flagForIssueKey);

        const issueId = articleKey.extractBits(0, issueIdLength - 1);
        const articleNumber = articleKey.extractBits(issueIdLength, issueIdLength + articleNumberLength - 1);

        return new IssueKey(issueId, articleNumber);
    }
}

export { IssueKey };
