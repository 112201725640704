import { extend } from 'lodash';

import { ViewName } from '@pressreader/navigation-types';
import { toCamelCase } from '@pressreader/utils';

import ndNavHistoryService from 'nd.navigationhistory';
import { BackgroundSettings } from 'profile/background-image/models/background.settings';
import { IUserPublicInfo } from 'nd.data.userpublicinfo';

class UserProfile {
    readonly profileId: string;
    readonly enAccountNumber: string;
    accountNumber: number;
    email: string;
    displayName?: string;
    nickname: string;
    photoUrl: string;
    avatarImageId: string;
    location: string;
    isCurrentUser: boolean;
    isProfilePrivate: boolean;
    isDeviceAccount: boolean;
    isFollowedByCurrentUser: boolean;
    enableServiceEmails: boolean;
    enablePromotionalEmails: boolean;
    backgroundImageSettings: BackgroundSettings;
    publicInfo?: IUserPublicInfo;
    ownedPublications: any[];
    locationLatLng: ILocation;

    constructor(data: any, ownedPublications?: any[]) {
        if (!data) {
            return null;
        }

        data = toCamelCase(data);

        this.profileId = data.profileId || data.profileName;
        this.enAccountNumber = data.enAccountNumber;
        this.accountNumber = data.accountNumber;
        this.email = data.email;
        this.displayName = data.displayName;
        this.nickname = data.nickname;
        this.photoUrl = data.photoUrl;
        this.location = data.location;
        this.isCurrentUser = data.isCurrentUser;
        this.isProfilePrivate = data.isProfilePrivate;
        this.isDeviceAccount = data.isDeviceAccount;
        this.isFollowedByCurrentUser = data.followedByCurrentUser;
        this.backgroundImageSettings = BackgroundSettings.fromDto(data.backgroundImageSettings);
        this.avatarImageId = data.avatarImageId;
        this.ownedPublications = ownedPublications;
        this.enableServiceEmails = data.enableServiceEmails;
        this.enablePromotionalEmails = data.enablePromotionalEmails;

        if (data.publicInfo) {
            this.publicInfo = data.publicInfo;
        }

        if (this.location) {
            const latLng = data.locationLatLng;
            if (latLng && latLng.lat !== 0 && latLng.lng !== 0) {
                this.locationLatLng = { lat: latLng.lat, lng: latLng.lng };
            }
        }
    }

    equals(profileId: string): boolean {
        const s1 = profileId;
        const s2 = this.profileId;

        return s1 && s2 && (s1 || '').toUpperCase() === (s2 || '').toUpperCase();
    }

    goToChannelFeed(config: any): Promise<any> {
        return this.ensureProfileName().then(profileId => {
            return UserProfile.goToChannelFeed(profileId, config);
        });
    }

    goToProfile(): Promise<any> {
        return this.ensureProfileName().then(profileId => {
            UserProfile.goToProfile(profileId);
        });
    }

    static goToProfile(profileId: string) {
        return ndNavHistoryService.goView(ViewName.Profile, { profileId: profileId });
    }

    static goToChannelFeed(profileId: string, config?: any): Promise<any> {
        const params = extend({ channelfeed: 'channelfeed', profileId: profileId }, config);
        return ndNavHistoryService.goView(ViewName.Profile, params);
    }

    static ensureProfileName(profileId: string, enAccountNumber: string): Promise<string> {
        return new Promise((resolve, reject) => {
            if (!profileId && !enAccountNumber) {
                reject();
                return;
            }

            if (profileId) {
                resolve(profileId);
                return;
            }

            // TODO: Get rid of that circular references.
            import('nd.social.manager').then(({ default: manager }) => {
                manager.getProfileName(enAccountNumber).then(resolve, reject);
            });
        });
    }

    private ensureProfileName(): Promise<string> {
        return UserProfile.ensureProfileName(this.profileId, this.enAccountNumber);
    }
}

interface ILocation {
    lat: number;
    lng: number;
}

export { UserProfile, ILocation };
