import ndAlert from '@pressreader/src/nd.alert';

export function actionProhibitedForAccount(data: any) {
    if (data && data.Parameters) {
        const alertPromise = data.Parameters.message ? ndAlert().show(data.Parameters.message) : Promise.resolve();

        return alertPromise.then({ skipRetry: true }).finally(() => {
            if (data.Parameters.redirectUrl) {
                window.location.href = data.Parameters.redirectUrl;
            }
        });
    }
    return Promise.reject();
}
