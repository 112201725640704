import { IResult } from 'ua-parser-js';

import { IApplicationInfoDto } from '@pressreader/analytics-api';
import { IBrowserInfo } from '@pressreader/analytics-types';
import { Modernizr } from '@pressreader/modernizr';

function dntEnabled() {
    try {
        const doNotTrack =
            (window as any).doNotTrack === '1' ||
            navigator.doNotTrack === 'yes' ||
            navigator.doNotTrack === '1' ||
            (navigator as any).msDoNotTrack === '1' ||
            (window.external && 'msTrackingProtectionEnabled' in window.external && (window.external as any).msTrackingProtectionEnabled());

        return doNotTrack;
    } catch {
        /* do nothing */
    }

    // Do Not Track is not supported or crashed
    return false;
}

function cookiesEnabled() {
    // copied from modernizr https://github.com/Modernizr/Modernizr/blob/master/feature-detects/cookies.js

    try {
        document.cookie = 'cookietest=1'; // create cookie
        const enabled = document.cookie.indexOf('cookietest=') !== -1;
        document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'; // delete cookie
        return enabled;
    } catch (e) {
        return false;
    }
}

function buildBrowserInfo(ua: IResult): IBrowserInfo {
    return {
        name: ua.browser.name ?? '',
        version: ua.browser.version ?? '',
        DNT: dntEnabled(),
        touchSupported: Modernizr.touch,
        cookiesEnabled: cookiesEnabled(),
        userAgent: window.navigator.userAgent,
    };
}

function mapBrowserInfoToDto(info: IBrowserInfo): IApplicationInfoDto {
    return {
        name: info.name,
        version: info.version,
        acceptLanguage: info.acceptLanguage,
        DNT: info.DNT,
        touchSupported: info.touchSupported,
        cookiesEnabled: info.cookiesEnabled,
        userAgent: info.userAgent,
    };
}

export { buildBrowserInfo, mapBrowserInfoToDto };
