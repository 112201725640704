import * as $ from 'jquery';
import * as _ from 'lodash';
import * as ko from 'knockout';
import ndKo from '@pressreader/src/nd.ko';
import { cookies, createSimpleCache } from '@pressreader/utils';
import $ndRes from 'nd.res';
import $ndConfig from '@pressreader/src/deprecated/nd.config';
import $ndDialog from '@pressreader/src/nd.ui.dialog';
import ndViewerApp from 'nd.viewer.app';
import { DialogStatus } from '@pressreader/src/nd.ui.dialogstatus';
import { getSignInDialogTemplateId, getExternalSystems, socialNetworkSignInAllowed } from 'signin/signin.config.service';
import { eventbus } from '@pressreader/src/eventbus/eventbus';
import { EmailAlertTypes, SignInRequestedEvent, SignUpRequestedEvent, SignUpEmailAlertSetEvent } from './events/events';
import { AuthenticationType } from '@pressreader/src/authentication/authenticationtypes';
import { Modernizr } from '@pressreader/modernizr';

var self = ($ndDialog.signin = {
        parentDialog: null,
        show: null,
        hide: null,
        onShow: null,
        onHide: null,
        resize: null,
        states: null,
    }),
    states = {},
    viewModels = [],
    dialog,
    DEFAULT_EXPIRATION_TIME = 15 * 60 * 1000,
    NAVIGATION_REQUESTED_EVENT_NAME = 'onNavigationRequested',
    signUpCache = createSimpleCache({ expire: DEFAULT_EXPIRATION_TIME });

function PasswordRecoveryViewModel(conf, actions) {
    this.header = $ndRes.val('Dialogs.Signin.PasswordRecovery');
    this.state = 'passwordRecovery';
    this.requestSent = ko.observable(false);

    this.emailAddress = ko.observable('').extend({
        required: $ndRes.val('Dialogs.Signin.EmailRequired', 'Valid email address is required'),
        email: $ndRes.val('Dialogs.Signin.EmailRequired', 'Valid email address is required'),
        trackDirtyState: true,
    });
    this.emailAddressId = 'PasswordRecoveryEmailAddress';
    this.recoverPassword = function () {
        if (!ndKo.validate(this)) {
            dialog.resize();
            return;
        }

        if (!this.requestSent()) {
            this.requestSent(true);
            actions.recoverPassword({ logonName: this.emailAddress() });
        }
    };
    this.onActivated = function (prevView) {
        this.requestSent(false);
        ndKo.resetValidationState(this);

        var prefilledEmail = prevView && prevView.forgotPasswordEmailAddress;
        if (prefilledEmail) {
            var val = typeof prefilledEmail === 'function' ? prefilledEmail() : prefilledEmail;
            if (val) this.emailAddress(val);
        }
        dialog.focus(this.emailAddressId);
    };
}

function SignUpViewModel(conf, actions) {
    var registrationEmail = (conf && conf.emailAddress) || '',
        emailAddress = registrationEmail || signUpCache.get('emailAddress') || '',
        password = signUpCache.get('password') || '',
        firstName = (conf && conf.firstName) || signUpCache.get('firstName') || '',
        lastName = signUpCache.get('lastName') || '',
        cacheSpy = function (fieldName) {
            return function (newVal) {
                signUpCache.set(fieldName, newVal);
            };
        };
    var _isPromotionalDefault = true;
    var _isServiceDefault = true;

    this.header = $ndRes.val('Dialogs.Signin.Registration');
    this.state = 'signUp';

    this.emailAddress = ko.observable(emailAddress).extend({
        required: $ndRes.val('Dialogs.Validators.Required'),
        email: true,
    });
    // before dialog is shown no matter what value this field has - it will be ignored
    this.emailAddressId = 'SignUpEmailAddress';

    this.enablePromotional = ko.observable(false);
    this.enableService = ko.observable(false);

    var minLength = parseInt($ndRes.val('v7.Client.Signin.Validators.Password.Minlength'), 10),
        required = $ndRes.val('Dialogs.Validators.Required');

    this.password = ko.observable(password).extend({
        required: required,
        minLength: {
            val: minLength,
            message: $ndRes.format('v7.Client.Signin.Validators.Password.Minlength.Message', minLength),
        },
    });
    this.firstName = ko.observable(firstName).extend({
        required: required,
    });
    this.lastName = ko.observable(lastName).extend({
        required: required,
    });

    this.signUp = function () {
        if (!ndKo.validate(this)) return;

        eventbus.send(
            new SignUpEmailAlertSetEvent({
                alertKey: EmailAlertTypes.EmailAlertPromotional,
                value: this.enablePromotional(),
                isDefault: _isPromotionalDefault,
            }),
        );
        eventbus.send(
            new SignUpEmailAlertSetEvent({
                alertKey: EmailAlertTypes.EmailAlertTechnical,
                value: this.enableService(),
                isDefault: _isServiceDefault,
            }),
        );

        actions.signUp({
            emailAddress: this.emailAddress(),
            password: this.password(),
            firstName: this.firstName(),
            lastName: this.lastName(),
            enablePromotional: this.enablePromotional(),
            enableService: this.enableService(),
        });

        signUpCache.clear();
    };
    this.onActivated = function () {
        this.password('');
        ndKo.resetValidationState(this);

        dialog.focus(this.emailAddressId);
        eventbus.send(new SignUpRequestedEvent({ system: AuthenticationType.Application }));

        this.emailAddress.subscribe(dialog.resize.bind(dialog));
        this.password.subscribe(dialog.resize.bind(dialog));
        this.firstName.subscribe(dialog.resize.bind(dialog));
        this.lastName.subscribe(dialog.resize.bind(dialog));
    };

    this.emailAddress.subscribe(cacheSpy('emailAddress'));
    this.password.subscribe(cacheSpy('password'));
    this.firstName.subscribe(cacheSpy('firstName'));
    this.lastName.subscribe(cacheSpy('lastName'));
}

function SignInViewModel(conf, actions) {
    var loginNetworks = $ndConfig.get('ui.SocialSignIn.SocialNetworks');

    function Network(item) {
        this.provider = item.provider;
        this.css = item.css;
        this.name = item.name;
        this.networkName = $ndRes.val(item.resourceName);
    }

    this.socialNetworks = _.map(loginNetworks, function (item) {
        return new Network(item);
    });

    var registrationEnabled = $ndConfig.get('Registration.RegistrationEnabled', false);
    var trialRegistrationEnabled = $ndConfig.get('Registration.TrialRegistrationEnabled', false);
    var trialRegistrationForAllEnabled = $ndConfig.get('Registration.TrialRegistrationForAllEnabled', false);

    this.header = $.nd.res.val('Dialogs.Signin.Header');

    this.state = 'signIn';
    this.templateName = getSignInDialogTemplateId();
    this.externalSystems = getExternalSystems();

    this.emailAddress = ko.observable();
    this.emailAddressId = 'SignInEmailAddress';
    this.password = ko.observable();
    this.enableAutologin = ko.observable(true);
    this.signInErrorMessage = ko.observable('');
    this.showPassword = ko.observable(false);
    this.forgotPasswordEmailAddress = ko.computed(function () {
        return this.emailAddress();
    }, this);

    /**
     * Initiates sign in with external system
     * @param {String} name - name of system to sign in with
     */
    this.signInExternal = _.debounce(
        function (name) {
            actions.signInExternal({ system: name, enableAutologin: this.enableAutologin() });
        },
        300,
        { leading: false, trailing: true },
    );

    this.showRegistration = ko.computed(function () {
        return registrationEnabled;
    });
    this.socialNetworkSignInAllowed = ko.computed(function () {
        return socialNetworkSignInAllowed();
    });
    this.signUpText = ko.computed(function () {
        var textModel = '';

        if (trialRegistrationForAllEnabled) {
            textModel = $.nd.res.val('Dialogs.Signin.NewUserTrialRegistration');
        } else if (trialRegistrationEnabled) {
            var isTrialUsed = cookies.get('isTrialUsed', false);

            if (!isTrialUsed) textModel = $.nd.res.val('Dialogs.Signin.NewUserTrialRegistration');
            else textModel = $.nd.res.val('Dialogs.Signin.NewUser');
        } else if (registrationEnabled) {
            textModel = $.nd.res.val('Dialogs.Signin.NewUser');
        }

        return textModel;
    }, this);
    this.signIn = function () {
        //hack. to update knockout model if username and password were autocompleted by browser
        $('.pop-body-signin:visible :input').change();
        $('.pop-body-signin').height('100%');

        if (!this.emailAddress() || !this.password()) return;

        this.signInErrorMessage('');
        actions.signIn({
            userName: this.emailAddress(),
            password: this.password(),
            enableAutologin: this.enableAutologin(),
        });
    };

    this.onActivated = function () {
        dialog.focus(this.emailAddressId);

        this.password('');
        ndKo.resetValidationState(this);
        eventbus.send(new SignInRequestedEvent({ system: AuthenticationType.Application }));
    };
    this.showSocialNetwork = ko.computed(function () {
        return this.socialNetworkSignInAllowed() && this.externalSystems.length > 0;
    }, this);

    this.toggleShowPassword = function () {
        this.showPassword(!this.showPassword());
    };
}

function SignInOpenIdViewModel() {
    this.header = $.nd.res.val('Dialogs.Signin.OpenId');
    this.state = 'signInOpenId';

    this.openId = ko.observable('');
    this.signIn = function () {
        $(self).trigger('signInOpenId', { openId: this.openId() });
    };
}

function SocialNetworkModel(socialNetworkName) {
    this.provider = socialNetworkName;

    this.className = this.provider;
    this.text = $.nd.res.val('ExternalAuthentication.' + this.provider);

    this.view = this.provider === 'openid' ? 'signInOpenId' : '';
    this.action = function () {
        if (this.view) {
            $(this).trigger(NAVIGATION_REQUESTED_EVENT_NAME, { viewKey: this.view });
        } else {
            $(self).trigger('signInExternal', { provider: this.provider });
        }
    };
}

function SignInSocialNetworksViewModel() {
    this.header = $.nd.res.val('Dialogs.Signin.SocialNetwors');
    this.state = 'signInSocialNetworks';
    var socialNetworks = $ndConfig.get('ui.SignIn.SocialNetworks'),
        socialItems = [],
        that = this;

    if (!socialNetworks) return;

    for (var i = 0; i < socialNetworks.length; i++) {
        var item = new SocialNetworkModel(socialNetworks[i]);
        $(item).bind(NAVIGATION_REQUESTED_EVENT_NAME, this.onNavigationRequested);
        socialItems.push(item);
    }
    this.signInExternal = function (provider) {
        $(self).trigger('signInExternal', { provider: provider });
    };
    this.array = socialItems;
    this.onNavigationRequested = function (_, args) {
        $(that).trigger(NAVIGATION_REQUESTED_EVENT_NAME, args);
    };
}

function ViewModel(modelConf, actions) {
    var that = this,
        initialState = modelConf && modelConf.initialState;

    function registerViewModel(modelConfig, vmConfig) {
        var key = vmConfig.key,
            ViewModelConstructor = vmConfig.Ctor,
            config = modelConfig && modelConfig[key],
            model = new ViewModelConstructor(config, actions);

        $(model).bind(NAVIGATION_REQUESTED_EVENT_NAME, that.onNavigationRequested);

        that[key] = model;
        if (!that.stateMap) that.stateMap = {};
        if (that.stateMap[model.state]) throw new Error('Model ' + model.state + ' has already been registered.');

        that.stateMap[model.state] = model;
    }

    for (var i = 0, len = viewModels.length; i < len; i++) {
        registerViewModel(modelConf, viewModels[i]);
    }

    this.externalRequestKey = modelConf && modelConf.externalRequestKey;
    this.disablePasswordPlaceholder = $.browser.msie && parseInt($.browser.version) < 10;
    this.supportsPlaceholder = Modernizr.input.placeholder;

    this.currentState = ko.observable(this.signIn.state);
    this.prevState = ko.observable();
    this.isRTL = ko.observable(ndViewerApp.contentIsRtl());

    this.setState = function (state) {
        var previousView = this.currentView();

        this.prevState(this.currentState());
        this.currentState(state);

        var view = this.currentView();
        dialog.resize();
        if (view.onActivated) view.onActivated.call(view, previousView);
    };
    this.currentView = ko.computed(function () {
        return this.stateMap[this.currentState()];
    }, this);

    this.routeTo = function (destViewKey) {
        this.setState(this.stateMap[destViewKey].state);
    };
    this.routeFn = function (destViewKey) {
        return that.routeTo.bind(that, destViewKey);
    };
    this.onNavigationRequested = function (_, args) {
        var destViewKey = args.view;
        that.routeTo(destViewKey);
    };

    this.enablePasswordRecovery = ko.observable($ndConfig.get('ui.SignIn.enablePasswordRecovery', true));

    this.recoverPassword = function () {
        var externalPage = $ndConfig.get('Authentication.ExternalForgotPasswordPage');
        if ($ndConfig.get('Authentication.EnablePublisherAuthenticationWebService') && externalPage) {
            that.close();
            location.assign(externalPage);
        } else {
            that.routeTo('passwordRecovery');
        }
    };

    this.goSignUp = function () {
        var externalPage = $ndConfig.get('Authentication.ExternalRegistrationPage');
        if (externalPage && $ndConfig.get('Authentication.EnablePublisherAuthenticationWebService')) {
            that.close();
            window.location.href = externalPage;
        } else {
            window.location.href = 'accounting/signup';
        }
    };

    this.close = function () {
        self.hide(DialogStatus.Cancel);
        if (dialog.parentDialog) {
            dialog.parentDialog.restore({ listView: true });
        }
    };
    this.back = function () {
        var prevState = this.prevState();
        if (prevState) {
            this.setState(prevState);
        } else {
            this.onHistoryEmptied && this.onHistoryEmptied();
        }
    };
    this.initialState = initialState || this.signIn.state;
}

(function () {
    var currentModel;

    function register(key, ctor) {
        viewModels.push({ key: key, Ctor: ctor });
        states[key] = key;
    }

    register('passwordRecovery', PasswordRecoveryViewModel);
    register('signUp', SignUpViewModel);
    register('signIn', SignInViewModel);
    register('signInOpenId', SignInOpenIdViewModel);
    register('signInSocialNetworks', SignInSocialNetworksViewModel);

    function init(modelConfiguration, actions) {
        var model = new ViewModel(modelConfiguration, actions);
        return model;
    }

    function render(model, parentDialog) {
        if (parentDialog) {
            dialog = $ndDialog.createInstance(parentDialog);
        } else {
            dialog = $ndDialog.getInstance();
        }

        if (dialog.visible) {
            self.parentDialog = dialog;
            dialog = $ndDialog.createInstance();
            dialog.parentDialog = self.parentDialog;
            model.onHistoryEmptied = function () {
                dialog.hide();
                dialog.parentDialog.restore();
            };
            self.parentDialog.suspend();
        }

        dialog.onHide(function (_, data) {
            $(self).trigger('onhide', data).unbind();
        });

        dialog.onShow(function (_, data) {
            $(self).trigger('onshow', data);
        });

        var config = {
            templateName: model.currentView().templateName,
            model: model,
        };

        dialog.show(config);
        model.setState(model.initialState);
    }

    function show(conf, actions) {
        var parentDialog = conf && conf.parentDialog,
            model = init(conf, actions);

        render(model, parentDialog);
        currentModel = model;
    }

    function hide(dialogResult) {
        /* This will destroy the parent dialog,
             Please find another way to do this.
             if (dialog.parentDialog) {
             dialog.parentDialog.hide();
             }*/

        // dialog may not exist,
        // if external authentication was requested not from sign in dialog
        // but from method externalSignIn of nd.authentication.
        // this is fix of poor design - when dialog manipualation methods like "hide"
        // accessible outside of this module at time when dialog is not created (and even not supposed to be created)
        if (dialog) {
            dialog.hide(dialogResult);
        }

        currentModel = null;
    }

    function onHide(fn) {
        $(self).one('onhide', fn);
    }

    function onShow(fn) {
        $(self).one('onshow', fn);
    }

    function setSignInError(error) {
        if (dialog) {
            dialog.config.model.signIn.signInErrorMessage(error);
            dialog.resize();
        }
    }

    function resize() {
        dialog.resize();
    }

    self.resize = resize;
    self.show = show;
    self.hide = hide;
    self.onHide = onHide;
    self.onShow = onShow;
    self.states = states;
    self.setSignInError = setSignInError;
})();
export default self;
