import * as $ from 'jquery';
import * as _ from 'lodash';
import $ndUiUtils from 'nd.ui.utils';
import { keyCodes } from '@pressreader/utils';

var _groupTypes = {
    generic: 0,
    custom: 1,
};
var _titleTypes = {
    generic: 0,
    custom: 1,
};

function _ItemsGroup(title, className) {
    if (title) {
        this._title = title;
    }
    if (className) {
        this._className = className;
    }
}

_ItemsGroup.prototype = {
    _type: _groupTypes.generic,
    _items: null,
    _title: null,
    _footerText: null,
    _scrollable: false,
    _defaultClassName: 'list', // another option is "group"
    _template: 'default',
    _date: null,
    type: function () {
        return this._type;
    },
    add: function (item) {
        if (!this._items) this._items = [item];
        else this._items.push(item);
        return this;
    },
    title: function () {
        return this._title;
    },
    className: function () {
        if (!this._className) {
            return this._defaultClassName;
        }
        return this._className;
    },
    footerText: function (val) {
        if (!arguments.length) return this._footerText;
        this._footerText = val;
        return this;
    },
    items: function (items) {
        if (!arguments.length) return this._items;
        this._items = items;
        this._scrollable = true;
        return this;
    },
    data: function (data) {
        if (!arguments.length) return this._date;
        this._date = data;
        return this;
    },
    scrollable: function (val) {
        if (!arguments.length) return this._scrollable;
        this._scrollable = val;
        return this;
    },
    template: function (template) {
        if (!arguments.length) {
            var result = this._template;
            if (this._scrollable) result += '_scrollable';
            return result + '_group';
        }
        this._template = template;
        return this;
    },
    itemTemplate: function (item) {
        return item.template();
    },
};

function _CustomItemsGroup(template, title) {
    _ItemsGroup.call(this, title);
    this._type = _groupTypes.custom;
    this._jsTemplate = template;
}

$.extend(_CustomItemsGroup.prototype, _ItemsGroup.prototype, {
    _jsTemplate: null,
    jsTemplate: function () {
        return this._jsTemplate;
    },
});

function Title(template) {
    if (template) {
        this._jsTemplate = template;
        this._type = _titleTypes.custom;
    }
}

Title.prototype = {
    _type: _titleTypes.generic,
    _title: null,
    _backTitle: 'Back',
    _template: 'default',
    _jsTemplate: null,
    type: function () {
        return this._type;
    },
    title: function (title) {
        if (!arguments.length) return this._title;
        this._title = title;
        return this;
    },
    backTitle: function (title) {
        if (!arguments.length) return this._backTitle;
        this._backTitle = title;
        return this;
    },
    template: function (template) {
        if (!arguments.length) return this._template + '_title';
        this._template = template;
        return this;
    },
    jsTemplate: function () {
        return this._jsTemplate;
    },
};

function listKeyUpHUandler(e) {
    if (!e) e = window.event;
    var self = this;

    if (e.which === keyCodes.esc && self._controller._canHandleKeyUp) {
        if (self.canGoBack()) {
            self._controller.back();
            if (self._controller._parent) {
                self._controller._parent._canHandleKeyUp = true;
            }
        } else {
            self.hide();
        }
    }

    if (e.stopPropagation) {
        e.stopPropagation();
    } else {
        e.cancelBubble = true;
    }
    return false;
}

function initKeyboardEventsForList(list) {
    var self = list,
        controller = self._controller,
        parentController = controller._parent;

    self._keyUpHUandler = listKeyUpHUandler.bind(self);

    if (!controller.onShow || !controller.onHide) {
        controller._canHandleKeyUp = true;
        $(document).one('keyup', self._keyUpHUandler);
        return;
    }

    controller.onShow(function () {
        controller._canHandleKeyUp = true;
        $(document).on('keyup', self._keyUpHUandler);
        if (parentController) {
            delete parentController._canHandleKeyUp;
        }
    });

    controller.onHide(function () {
        delete controller._canHandleKeyUp;
        $(document).off('keyup', self._keyUpHUandler);
        if (parentController) {
            parentController._canHandleKeyUp = true;
        }
    });
}

function List(controller, template) {
    this._controller = controller;
    initKeyboardEventsForList(this);
    if (template) this._template = template;
}

List.groups = {};
List.groups.types = _groupTypes;
List.prototype = {
    _controller: null,
    _title: null,
    //_width: "300px",
    _template: 'v7.Client.Menu.List',
    _defaultGroup: null,
    _groups: null,
    _canGoBack: false,
    _canBeDone: false,
    _headerEnable: true,
    addGroup: function (title, className) {
        var result = new _ItemsGroup(title, className);
        if (!this._groups) this._groups = [result];
        else this._groups.push(result);
        return result;
    },
    addGroupIfnotExist: function (title, className) {
        var result;
        if (!this._groups) {
            result = new _ItemsGroup(title, className);
            this._groups = [result];
        } else {
            result = _.find(this._groups, function (group) {
                return (!group._title && !title) || (!!group._title && group._title === title);
            });
            if (!result) {
                result = new _ItemsGroup(title, className);
                this._groups.push(result);
            }
        }
        if (!this._defaultGroup && !title) {
            this._defaultGroup = result;
        }
        return result;
    },
    addCustomGroup: function (template, title) {
        var result = new _CustomItemsGroup(template, title);
        if (!this._groups) this._groups = [result];
        else this._groups.push(result);
        return result;
    },
    addItem: function (item, groupTitle) {
        if (!groupTitle) {
            if (!this._defaultGroup) this._defaultGroup = this.addGroup();
            this._defaultGroup.add(item);
        } else {
            var group = this.addGroupIfnotExist(groupTitle);
            group.add(item);
        }
        return this;
    },
    title: function (title) {
        if (!arguments.length) return this._title;
        if (typeof title == 'string') {
            if (this._title) this._title.title(title);
            else this._title = new Title().title(title);
        } else if (typeof title == 'object') this._title = title;
        return this;
    },
    width: function (val) {
        //we do not set width explicitly (will be handled by CSS))
        //				if (!arguments.length) {
        //					if (this._controller._parent) {
        //						return this._controller._parent.renderer().width();
        //					}
        //					return this._width;
        //				}
        //				this._width = val;
        return this;
    },
    groups: function () {
        return this._groups;
    },
    back: function () {
        this._controller.back();
    },
    done: function () {
        this._controller.done();
    },
    hide: function () {
        if (this._controller && this._controller.hide) this._controller.hide();
    },
    render: function (elm) {
        $.nd.templates.renderTo(this._template, this, elm);
        $ndUiUtils.toolbarSetup(elm);
    },
    canGoBack: function (val) {
        if (!arguments.length) return this._canGoBack;
        this._canGoBack = val;
        return this;
    },
    canBeDone: function (val) {
        if (!arguments.length) return this._canBeDone;
        this._canBeDone = val;
        return this;
    },
    headerEnable: function (val) {
        if (!arguments.length) return this._headerEnable;
        this._headerEnable = val;
        return this;
    },
    template: function (group) {
        return group.template();
    },
    jsTemplates: function () {
        var id = new Date().getTime(),
            map = {},
            result = {},
            idx = 0;

        function _do(obj) {
            var jsTemplate = obj.jsTemplate();
            var template = map[jsTemplate];
            if (!template) map[jsTemplate] = template = id + '_' + idx++;
            obj.template(template);
            result[obj.template()] = jsTemplate;
        }

        if (this._title.type() == _titleTypes.custom) {
            _do(this._title);
        }

        const groups = this._groups || [];

        for (var i = 0; i < groups.length; ++i) {
            var group = groups[i];
            if (group.type() == _groupTypes.custom) _do(group);
            var items = group.items();
            if (!items) continue;
            for (var j = 0; j < items.length; ++j) {
                var item = items[j];
                if (item.type() == $.nd.ui.menu.items.types.custom) _do(item);
            }
        }
        return result;
    },
};

function Custom(controller, template) {
    this._controller = controller;
    if (template) this._template = template;
}

Custom.prototype = {
    _controller: null,
    hide: function () {
        this._controller.hide();
    },
    render: function (elm) {
        $.nd.templates.renderTo(this._template, this, elm);
    },
    model: function () {
        return this._controller.model();
    },
};

if (!$.nd) $.nd = {};
if (!$.nd.ui) $.nd.ui = {};
if (!$.nd.ui.menu) $.nd.ui.menu = {};
$.nd.ui.menu.renderers = {
    list: List,
    title: Title,
    custom: Custom,
};

export default $.nd.ui.menu.renderers;
