// eslint-env es6

import TranslateTransformation from 'profile/background-image/models/translate.transformation';

class BackgroundTransformations {
    constructor(scale = 100, translate) {
        this.scale = scale;
        this.translate = translate || new TranslateTransformation();
    }

    static fromDto(data) {
        const transformations = new BackgroundTransformations(
            data && data.scale,
            data && data.translate && TranslateTransformation.fromDto(data.translate),
        );
        return transformations;
    }
}

export default BackgroundTransformations;
