import { createActionHandler, createReducer, OperationStatus } from '@pressreader/appstore';

import { configExtended, configLoadBegan, configLoadDone, configLoadFailed } from './actions';

interface IState {
    config: Record<string, unknown>;
    status: OperationStatus;
}

const initialState: IState = {
    config: {},
    status: OperationStatus.None,
};

const reducer = createReducer(
    initialState,
    createActionHandler(configLoadBegan, state => ({
        ...state,
        status: OperationStatus.Pending,
    })),
    createActionHandler(configLoadDone, (state, action) => ({
        ...state,
        config: action.payload.config,
        status: OperationStatus.Done,
    })),
    createActionHandler(configLoadFailed, state => ({
        ...state,
        status: OperationStatus.Done,
    })),
    createActionHandler(configExtended, (state, action) => ({
        ...state,
        config: action.payload.config,
    })),
);

export { IState, reducer };
