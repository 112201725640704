import { AuthenticationType } from '@pressreader/src/authentication/authenticationtypes';
import { IDictionary } from '@pressreader/src/core/idictionary';
import { eventbus } from '@pressreader/src/eventbus/eventbus';

import { analytics } from 'analytics/analytics';

import {
    EmailAlertTypes,
    EventTypes,
    SignInFailedEvent,
    SignInRequestedEvent,
    SignInSucceededEvent,
    SignUpEmailAlertSetEvent,
    SignUpFailedEvent,
    SignUpRequestedEvent,
    SignUpSucceededEvent,
} from '../events/events';

const alertEventCategory = 'CASL';

const systemMap = {
    [AuthenticationType.Application]: '/email',
    [AuthenticationType.Social]: '/social',
    [AuthenticationType.Library]: '/library',
};

const emailAlertEventActions: IDictionary<string> = {
    [EmailAlertTypes.EmailAlertPromotional]: 'SettingsPromo',
    [EmailAlertTypes.EmailAlertTechnical]: 'SettingsService',
};

function getSystemName(system: AuthenticationType) {
    return systemMap[system] ? systemMap[system] : '';
}

eventbus.ofType(EventTypes.SignInRequested).subscribe((action: SignInRequestedEvent) => {
    analytics.pageView({ page: `/reg/signin${getSystemName(action.payload.system)}` });
});

eventbus.ofType(EventTypes.SignInSucceeded).subscribe((action: SignInSucceededEvent) => {
    analytics.pageView({ page: `/reg/signin/success${getSystemName(action.payload.system)}` });
});

eventbus.ofType(EventTypes.SignInFailed).subscribe((action: SignInFailedEvent) => {
    analytics.pageView({ page: `/reg/signin/error${getSystemName(action.payload.system)}` });
});

eventbus.ofType(EventTypes.SignUpRequested).subscribe((action: SignUpRequestedEvent) => {
    analytics.pageView({ page: `/reg/signup${getSystemName(action.payload.system)}` });
});

eventbus.ofType(EventTypes.SignUpSucceeded).subscribe((action: SignUpSucceededEvent) => {
    analytics.pageView({ page: `/reg/signup/success${getSystemName(action.payload.system)}` });
});

eventbus.ofType(EventTypes.SignUpFailed).subscribe((action: SignUpFailedEvent) => {
    analytics.pageView({ page: `/reg/signup/error${getSystemName(action.payload.system)}` });
});

eventbus.ofType(EventTypes.SignUpEmailAlertSet).subscribe((action: SignUpEmailAlertSetEvent) => {
    const eventAction = emailAlertEventActions[action.payload.alertKey];
    const label = `${action.payload.isDefault ? 'default-' : ''}${action.payload.value ? 'on' : 'off'}`;
    analytics.event({ category: alertEventCategory, action: eventAction, label: label });
});
