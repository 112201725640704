import { createSelector } from 'reselect';
import isObject from 'lodash/isObject';
import memoize from 'lodash/memoize';

import { CONFIG_MODULE_NAME } from '../constants';

import { IState } from './reducer';

function getState(state: { [CONFIG_MODULE_NAME]: IState }) {
    return state[CONFIG_MODULE_NAME];
}

function getNodeValue<T>(node: Record<string, unknown>, path: string, defaultValue?: T): T | undefined {
    if (!isObject(node) || !path) {
        return defaultValue;
    }

    // Fix path for case-insensitive search
    const fixCase = (name: string) =>
        Object.keys(node).find(e => {
            return e.toLowerCase() === name.toLowerCase();
        }) || name; // In case the path contains "." or when it don't really exists in the config...

    path = fixCase(path);

    if (path in node) {
        return node[path] as T;
    }

    for (let i = path.indexOf('.'); i > 0; i = path.indexOf('.', i + 1)) {
        let key = path.substring(0, i);
        key = fixCase(key);
        if (key in node) {
            return getNodeValue<T>(node[key] as Record<string, unknown>, path.substring(i + 1), defaultValue);
        }
    }

    return defaultValue;
}

const selectConfig = createSelector(getState, state => state.config);

const selectStatus = createSelector(getState, state => state.status);

const selectGetConfigValue: (state: any) => { <T>(path: string, defaultValue: T): T; <T>(path: string): T | undefined } = createSelector(
    getState,
    state =>
        memoize(
            <T>(path: string, defaultValue?: T) => getNodeValue(state.config, path, defaultValue),
            (path: string, defaultValue: unknown) => JSON.stringify({ path, defaultValue }),
        ),
);

const selectConfigValue: <T>(state: any, path: string, defaultValue?: T) => T = createSelector(
    getState,
    (state: unknown, path: string) => path,
    (state: unknown, path: string, defaultValue?: any) => defaultValue,
    (state, path: string, defaultValue?: any) => getNodeValue(state.config, path, defaultValue),
);

export { selectConfig, selectStatus, selectGetConfigValue, selectConfigValue };
