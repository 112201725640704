/* eslint-env es6 */

import { ArticleKeyType } from './keytype';
import { ArticleKeyBase } from './articlekey.base';
import { Long } from './long';

const flagForPublicationKey = Long.fromNumber(4503599627370496);
const minValue = 4503599627370496;
const maxValue = 9007199254740992;
const flagFor32BitPublicationIdValue = 2251799813685248;
const flagFor32BitPublicationId = Long.fromNumber(flagFor32BitPublicationIdValue);

/**
 * Article publication key.
 */
class PublicationKey extends ArticleKeyBase {
    publicationId: number;
    articleNumber: number;
    constructor(publicationId: number, articleNumber: number) {
        super(ArticleKeyType.Publication);
        this.publicationId = publicationId;
        this.articleNumber = articleNumber;
    }

    toBinary() {
        const is24BitPublicationId = this.publicationId <= 0xffffff;

        let binary = flagForPublicationKey.or(Long.fromNumber(this.publicationId));

        if (is24BitPublicationId) {
            // 24 bit publication
            binary = binary.or(Long.fromNumber(this.articleNumber).shiftLeft(24));
        } else {
            // 32 bit publication
            binary = binary.or(Long.fromNumber(this.articleNumber).shiftLeft(32));

            // set 32-publication bit
            binary = binary.or(flagFor32BitPublicationId);
        }

        return binary.toNumber();
    }

    static isValid(articleId: number) {
        return articleId >= minValue && articleId < maxValue;
    }

    static parse(articleId: number) {
        const articleKey = Long.fromNumber(articleId);
        const publicationIdLength = articleKey.and(flagFor32BitPublicationId).toNumber() === flagFor32BitPublicationIdValue ? 32 : 24;
        const publicationId = articleKey.extractBits(0, publicationIdLength - 1);
        const articleNumber = articleKey.extractBits(publicationIdLength, 50);

        return new PublicationKey(publicationId, articleNumber);
    }
}

export { PublicationKey };
