import cloneDeep from 'lodash/cloneDeep';

import { getConfigValue, getConfigValueAsync } from '@pressreader/config';
import { HttpError } from '@pressreader/types';

import { authorized } from './authorization';
import { IFetchOptions } from './fetch';
import { baseUrl, fetchRequest, IRetryPolicy, isPageHttps } from './services';

interface IRequestConfig extends IFetchOptions {
    serviceName?: string;
    disableRetry?: boolean;
    cancellableKey?: string;
    disableBearerToken?: boolean;
    ignoreInitState?: boolean;
}

interface IServiceConfig {
    httpsrequired?: string | boolean;
    httpsRequired?: string | boolean;
    cdnProfile?: string;
    retryPolicy?: IRetryPolicy;
}

interface ICdnProfile {
    httpsBaseUrl?: string;
    httpsRequired?: string | boolean;
    baseUrl: string;
}

type IRequestParams<TData = unknown> =
    | {
          service: string;
          url?: never;
          command?: string;
          data?: TData;
          requestConfig?: IRequestConfig;
      }
    | {
          service?: never;
          url: string;
          command?: never;
          data?: TData;
          requestConfig?: IRequestConfig;
      };

function isTrue(value: string | boolean) {
    return value === true || value === 'true';
}

function getUrlPath(url: string) {
    if (url.indexOf('://') > 0) {
        url = url.substring(url.indexOf('/', 8));
    }
    return url;
}

function appendPath(url: string, path: string) {
    if (url[url.length - 1] === '/' && path[0] === '/') {
        path = path.substring(1);
    }
    return url + path;
}

async function sendRequest<TResult>(
    type: 'POST' | 'GET' | 'PUT' | 'DELETE',
    { url: fullUrl, service, command, data, requestConfig }: IRequestParams,
) {
    if (!requestConfig) {
        requestConfig = {};
    }

    if (!requestConfig.serviceName && service) {
        // serviceName is used as identification for lookup preloaded data
        // by default serviceName = {service}/{command};
        requestConfig.serviceName = `${service}${command ? `/${command}` : ''}`;
    }

    let serviceConfig = {} as IServiceConfig;

    if (service) {
        const services = await getConfigValueAsync<Record<string, IServiceConfig>>('services', {});
        serviceConfig = services[service] || {};
    }

    // for secure pages force using https
    let httpsRequired = isPageHttps() || isTrue(serviceConfig.httpsrequired) || isTrue(serviceConfig.httpsRequired);
    let isCDN = false;

    const headers: Record<string, string> = {};

    let _baseUrl = baseUrl();
    if (serviceConfig.cdnProfile) {
        const profile = getConfigValue<Record<string, ICdnProfile>>('cdnProfiles', {})[serviceConfig.cdnProfile];
        if (profile) {
            const httpsSupported = !!profile.httpsBaseUrl;
            if (httpsSupported || !httpsRequired) {
                const path = getUrlPath(_baseUrl);
                // check if the profile requires always use ssl (http 2.0)
                httpsRequired = httpsRequired || isTrue(profile.httpsRequired);

                _baseUrl = httpsRequired ? profile.httpsBaseUrl : profile.baseUrl;
                _baseUrl = appendPath(_baseUrl, path);

                isCDN = true;
            }
        }
    }

    let url = `${_baseUrl}${service}/`;
    const notUseBearerToken = requestConfig.disableBearerToken || isCDN;
    if (!notUseBearerToken) {
        // do not pass token to cdn servers
        const bearerToken = await authorized();
        headers['Authorization'] = `Bearer ${bearerToken}`;
    }

    if (httpsRequired) {
        url = url.replace(/^http:\/\//i, 'https://');
    }

    if (command) {
        url += command;
    }

    const defaultRetryPolicy = {
        maxRetries: requestConfig.disableRetry ? 0 : 3,
    };
    const retryPolicy = { ...defaultRetryPolicy, ...serviceConfig.retryPolicy };

    const settings: IFetchOptions = {
        method: type,
        url: fullUrl || url,
        data: type === 'GET' ? null : data,
        query: type === 'GET' ? (data as any) : null,
        ...requestConfig,
        headers: {
            ...headers,
            ...requestConfig.headers,
        },
    };

    try {
        return await fetchRequest<TResult>(settings, retryPolicy);
    } catch (e) {
        throw new HttpError(e as any);
    }
}

/** Parse arguments and call sendRequest. */
function callSendRequest<TResult>(type: 'POST' | 'GET' | 'PUT' | 'DELETE', ...args: unknown[]) {
    if (typeof args[0] === 'string') {
        // Keeping arguments parsing unchanged to avoid any side effects.

        const service = args[0];
        args = args.slice(1);

        // Next arg could be command or data.
        let command: string;
        if (args.length > 0 && typeof args[0] === 'string') {
            command = args[0];
            args = args.slice(1);
        }

        // Next arg is data.
        let data: unknown;
        if (args.length > 0) {
            if (typeof args[0] === 'object') {
                // Make sure the data is immutable.
                data = cloneDeep(args[0]);
            } else {
                data = args[0] === undefined ? {} : args[0];
            }
            args = args.slice(1);
        }

        // Next arg requestConfig.
        let requestConfig: IRequestConfig;
        if (args.length > 0) {
            requestConfig = args[0];
            args = args.slice(1);
        }

        return sendRequest<TResult>(type, { service, command, data, requestConfig });
    }

    // Ensuring the parameters object is immutable.
    const params = { ...(args[0] as IRequestParams<unknown>) };

    if (typeof params.data === 'object') {
        // Make sure the data is immutable.
        params.data = cloneDeep(params.data);
    } else if (params.data === undefined) {
        params.data = {};
    }

    if (params.requestConfig) {
        // Request config gets changed inside sendRequest so making sure the original stays intact.
        params.requestConfig = cloneDeep(params.requestConfig);
    }

    return sendRequest<TResult>(type, params);
}

type NotString<T> = T extends string ? never : T;

function httpGet<TResult>(service: string, command?: string, data?: unknown, requestConfig?: IRequestConfig): Promise<TResult>;
function httpGet<TResult>(service: string, data: NotString<unknown>, requestConfig?: IRequestConfig): Promise<TResult>;
function httpGet<TResult>(params: IRequestParams<unknown>): Promise<TResult>;
function httpGet<TResult>(...args: unknown[]) {
    return callSendRequest<TResult>('GET', ...args);
}

function httpPost<TResult>(service: string, command?: string, data?: unknown, requestConfig?: IRequestConfig): Promise<TResult>;
function httpPost<TResult>(service: string, data: NotString<unknown>, requestConfig?: IRequestConfig): Promise<TResult>;
function httpPost<TResult>(params: IRequestParams<unknown>): Promise<TResult>;
function httpPost<TResult>(...args: unknown[]) {
    return callSendRequest<TResult>('POST', ...args);
}

function httpPut<TResult>(service: string, command?: string, data?: unknown, requestConfig?: IRequestConfig): Promise<TResult>;
function httpPut<TResult>(service: string, data: NotString<unknown>, requestConfig?: IRequestConfig): Promise<TResult>;
function httpPut<TResult>(params: IRequestParams<unknown>): Promise<TResult>;
function httpPut<TResult>(...args: unknown[]) {
    return callSendRequest<TResult>('PUT', ...args);
}

function httpDelete<TResult>(service: string, command?: string, data?: unknown, requestConfig?: IRequestConfig): Promise<TResult>;
function httpDelete<TResult>(service: string, data: NotString<unknown>, requestConfig?: IRequestConfig): Promise<TResult>;
function httpDelete<TResult>(params: IRequestParams<unknown>): Promise<TResult>;
function httpDelete<TResult>(...args: unknown[]) {
    return callSendRequest<TResult>('DELETE', ...args);
}

export { IRequestConfig };
export { httpGet, httpPost, httpPut, httpDelete };
