// eslint-env es6

import serviceApi from 'nd.services.api';
import ndUser from 'nd.user';
import { cookies } from '@pressreader/utils';
import { Subject } from 'rxjs';

const serviceBasePath = 'api/v1/accounting/gdpr';
const CookieKeyConsentTypePrefix = 'gdpr_consent_';

const cookiesExpiresInDaysNumber = 365;

const userConsentsUpdatesSubject = new Subject();
const userConsentsUpdates = userConsentsUpdatesSubject.asObservable();

const cookieConsentValue = {
    none: 'none',
    granted: 'granted',
};

const gdprConsentType = {
    1: 'cookies',
    2: 'promoEmail',
    3: 'termsOfUse',
    cookies: 1,
    promoEmail: 2,
    termsOfUse: 3,
};

/**
 * Gets current user consents list. For anonymouse user the consents stored in cookies are returned.
 */
function getCurrentUserConsents() {
    if (ndUser.isLogin()) {
        return serviceApi.get(serviceBasePath, 'consents');
    }

    const cookieTermsOfUseValue = cookies.get(`${CookieKeyConsentTypePrefix}${gdprConsentType.termsOfUse}`, cookieConsentValue.none);
    const cookieCookieValue = cookies.get(`${CookieKeyConsentTypePrefix}${gdprConsentType.cookies}`, cookieConsentValue.none);
    const consents = [
        {
            consentType: gdprConsentType.termsOfUse,
            isGranted: cookieTermsOfUseValue === cookieConsentValue.granted,
        },
        {
            consentType: gdprConsentType.cookies,
            isGranted: cookieCookieValue === cookieConsentValue.granted,
        },
    ];

    return Promise.resolve(consents);
}

/**
 * Updates current user consent. For anonymouse user consent value is stored in the cookies.
 * @param consentType consent type
 * @param granted whether consent is granted by a user
 */
function updateCurrentUserConsent(consentType, granted) {
    if (ndUser.isLogin()) {
        return serviceApi.post(serviceBasePath, 'consent', { consentType, granted }).then(() => {
            userConsentsUpdatesSubject.next();
        });
    }

    cookies.set(`${CookieKeyConsentTypePrefix}${consentType}`, granted ? cookieConsentValue.granted : cookieConsentValue.none, {
        expiresInDays: cookiesExpiresInDaysNumber,
    });

    return Promise.resolve();
}

export { userConsentsUpdates, getCurrentUserConsents, updateCurrentUserConsent };
