import ndLogger from 'nd.logger';

import { LoginRadiusWidget, LRWidgetType } from './base';

export interface ISuccessResponse {
    Profile: any;
    Data: any;
    access_token: string;
    jwttoken: string;
}

export class LoginWidget extends LoginRadiusWidget<ISuccessResponse, any> {
    constructor(container: string, lrObject: any) {
        super(container);
        this.widgetType = LRWidgetType.LOGIN;
        this.lrObject = lrObject;

        ndLogger.log('login widget created');
    }
}
