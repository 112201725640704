import * as ko from 'knockout';
import * as $ from 'jquery';

        

        function getCoords(ev) { return { x: ev.clientX, y: ev.clientY }; }

        function satisfiesThresholdForClick(deltaCoords, threshold) {
            return ((Math.abs(deltaCoords.x) < threshold.x) && (Math.abs(deltaCoords.y) < threshold.y));
        }

        function SwipeClickHandler() {
            this.init = function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                var $el = $(element),
                    startCoords,
                    params = valueAccessor(),
                    threshold = params.threshold || { x: 50, y: 50 },
                    callback = params.handler || params;

                $el.on('mousedown', function (ev) { startCoords = getCoords(ev); });
                $el.on('click', function (ev) {
                    if (!startCoords) return;
                    var coords = getCoords(ev),
                        deltaCoords = { x: coords.x - startCoords.x, y: coords.y - startCoords.y };

                    if (satisfiesThresholdForClick(deltaCoords, threshold)) {
                        callback.apply(viewModel, [viewModel].concat(arguments));
                    }
                    startCoords = null;
                });

                ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                    $el.off();
                });
            };
        }

        ko.bindingHandlers['swipeClick'] = new SwipeClickHandler();
    