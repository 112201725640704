import * as $ from "jquery";
import "nd.ui";

    var self = {};

    self.menuItemsSetup = function (elm) {
        $(elm).find('.pop-list li > a, .pop-group li > a, .pop-list .list-item, .pop-group .list-item').has('.num, .val, .li-accessory').each(function () {

            $(this).css({
                "padding-right": function () {
                    return parseInt($(this).find('.num, .val, .li-accessory').outerWidth(true));
                }
            });
        });
    };

    self.toolbarSetup = function (elm) {
        var maxWidth = 0;

        elm.find('.toolbar-in').each(function () {
            var toolbar = $(this);
            var toolbarCenter = toolbar.find('.toolbar-center h1');
            var toolbarLeft = toolbar.find('.toolbar-left');
            var toolbarRight = toolbar.find('.toolbar-right');
            var toolbarSearch = toolbar.find('.toolbar-search');

            toolbarCenter.hide();

            toolbarCenter.css({ "width": "auto" });
            toolbarLeft.css({ "min-width": "0" });
            toolbarRight.css({ "min-width": "0" });
            toolbarSearch.css({ "min-width": "0" });

            var toolbarWidth = toolbar.outerWidth();
            var toolbarCenterWidth = toolbarCenter.outerWidth();
            var toolbarLeftWidth = toolbarLeft.outerWidth();
            var toolbarRightWidth = toolbarRight.outerWidth();
            var toolbarSearchWidth = toolbarSearch.outerWidth() || 0;

            maxWidth = Math.max(maxWidth, toolbarLeftWidth, toolbarRightWidth + toolbarSearchWidth);

            var toolbarExpectedWidth = toolbarCenterWidth + maxWidth + maxWidth - toolbarSearchWidth;

            if (toolbarExpectedWidth + maxWidth / 2 <= toolbarWidth) {
                toolbarLeft.css('min-width', maxWidth);
                toolbarRight.css('min-width', maxWidth - toolbarSearchWidth);
            }
            else {
                toolbarLeft.css('min-width', 0);
                toolbarRight.css('min-width', 0);
            }

            toolbarCenter.css('width', toolbarWidth - toolbarLeft.outerWidth() - toolbarRight.outerWidth() - (toolbarSearch.outerWidth() || 0));
            toolbarCenter.show();
        });
    };

    /**
     * Some pages are designed to have native vertical scroll.
     * By convention such pages are identified by a class name;
     */
    self.isPageVerticallyScrollable = function() {
        return  $(document.body).hasClass('l-serv');
    };

    $.nd = $.nd || {};
    $.nd.ui = $.nd.ui || {};
    $.nd.ui.utils = self;

    export default self;
