import { ViewName } from '@pressreader/navigation-types';
import { eventbus } from 'eventbus/eventbus';
import { EventTypes, ActiveViewChangedEvent, IssueViewChangedEvent, CommonViewChangedEvent } from './events';
import ndViewerApp from 'nd.viewer.app';

eventbus.ofType(EventTypes.ActiveViewChanged).subscribe((action: ActiveViewChangedEvent) => {
    const viewName = action.payload.viewName;
    switch (viewName) {
        case ViewName.TextView:
            eventbus.send(new IssueViewChangedEvent({ viewName, cid: ndViewerApp.cid(), issueDate: ndViewerApp.issueDate() }));
            return;
        default:
            eventbus.send(new CommonViewChangedEvent({ viewName }));
            break;
    }
});
