/* eslint-env es6 */

/**
 * Catalog service class
 * Provides methods for work with objects of catalog
 * - Groups of categories (like Countries, Languages, Sections)
 * - Publications
 * - Locales
 */

import { keyBy, uniq, extend, isDate } from 'lodash';

import { localizedString } from '@pressreader/resources';
import { parseDateString } from '@pressreader/utils';
import { CategoryGroupName } from '@pressreader/catalog-types';

import ndLogger from 'nd.logger';
import ndEvent from 'nd.event';
import ndUser from 'nd.user';

const updatesCheckInterval = 5 * 60 * 1000;

function logReject(err) {
    ndLogger.error(err);
    return Promise.reject(err);
}

// there is special case with synthetic categories
// they cannot be localized on server side for the time being
// it's planned, but not for now
// so as a temporary workaround let's localize them here - on the lowermost possible UI layer
let localizations;

/**
 * Ensure that localization resources loaded
 */
function ensureLocalizations() {
    if (!localizations) {
        localizations = {
            PublicationTypes: localizedString('v7.Client.Catalog.types'),
            Newspapers: localizedString('v7.Client.Catalog.newspapers'),
            Magazines: localizedString('v7.Client.Catalog.magazines'),
        };
    }
}

class CatalogService {
    constructor(provider) {
        this._provider = provider;
        this._localesCache = null;
        this._latestMetadata = null;
        this._publicationCache = {};
        this._groupCache = {};
        this._pathResolutionCache = {};
        this._pendingRequests = {};
        this._featuredCache = null;
        this._featuredHotspotCache = null;

        // start periodical updates check
        this._checkUpdates();

        this._invalidateCaches = this._invalidateCaches.bind(this);
        ndEvent.bind(ndUser, 'onuserstatechanged', this._invalidateCaches);
    }

    _deduplicateRequest(requestKey, requestFn) {
        if (this._pendingRequests[requestKey] !== undefined) {
            return this._pendingRequests[requestKey];
        }

        const promise = requestFn();
        this._pendingRequests[requestKey] = promise;
        return promise.finally(() => {
            delete this._pendingRequests[requestKey];
        });
    }

    /**
     * @typedef {Object} CatalogCategory
     * @property {Number} id - id of category
     * @property {String} name - system name
     * @property {String} displayName - displayable name localized to the catalog culture
     * @property {String} iso - ISO code of category if applicable
     * @property {String} slug - special name for URL generation
     * @property {Number} publicationsCount - count of publications according to the query ( path + search)
     * @property {Number} titlePublicationCid - CID of title publication in category according to the query ( path + search)
     */

    _normalizeCategory(category) {
        if (category === null) {
            return null;
        }
        return Object.freeze(
            extend({}, category, {
                displayName: localizations[category.name] || category.displayName,
            }),
        );
    }

    /**
     * @typedef {Object} CatalogCategoryGroup
     * @property {Number} id - id of category
     * @property {String} name - system name
     * @property {String} displayName - displayable name localized to the catalog culture
     * @property {Array.<CatalogCategory>} categories - list of categories in group
     */

    _normalizeCategoryGroup(group) {
        if (group === null) {
            return null;
        }
        return Object.freeze(
            extend({}, group, {
                displayName: localizations[group.name] || group.displayName,
                categories: group.categories.map(c => this._normalizeCategory(c)),
            }),
        );
    }

    /**
     * @typedef {Object} IssuePage
     * @property {Number} width - page width
     * @property {Number} height - page height
     */

    /**
     * @typedef {Object} Issue
     * @property {Number} id - id of the issue
     * @property {String} key - the issue key
     * @property {String} cid - the issue CID
     * @property {Number} version - version of the issue
     * @property {Number} expungeVersion - expunge version of the issue
     * @property {IssuePage} firstPage - first page of the issue
     */

    _normalizeIssue(issue) {
        if (!issue) {
            return null;
        }
        return Object.freeze(
            extend({}, issue, {
                issueDate: parseDateString(issue.issueDate),
                firstPage: Object.freeze(issue.firstPage),
            }),
        );
    }

    /**
     * @typedef {Object} Publication
     * @property {Number} cid - publication CID
     * @property {String} name - displayable name localized to the catalog culture
     * @property {String} slug - special name for URL generation
     * @property {Number} rank - publication rank
     * @property {Boolean} isSupplement - indicates whether this publication is supplement of another publication
     * @property {Boolean} isFree - indicates whether this publication can be free accessed
     * @property {Array.<Number>} categories - categories that publication belongs to
     * @property {Array.<Publication>} supplements - supplements of this publication
     * @property {Array.<Publication>} [otherRegionalEditions] - Other Regional Editions of this publication
     * @property {Issue} latestIssue - the latest issue of the publication
     */
    _normalizePublication(publication) {
        return Object.freeze(
            extend({}, publication, {
                supplements: (publication.supplements || []).map(s => this._normalizePublication(s)),
                latestIssue: this._normalizeIssue(publication.latestIssue),
            }),
        );
    }

    _cachePublication(publication) {
        this._publicationCache[publication.cid] = publication;
        return publication;
    }

    _cachePublications(publications) {
        publications.forEach(p => this._cachePublication(p));
        return publications;
    }

    /**
     * Gets list of publications if all of them found in cache, otherwise returns undefined.
     *
     * @param {Array.<String>} cids - CID of publications
     * @returns {Array.<Publication>|undefined}
     */
    _getCachedPublications(cids) {
        const count = cids.length;
        const result = new Array(count);
        for (let i = 0; i < count; i++) {
            const cached = this._publicationCache[cids[i]];
            if (cached === undefined) {
                return undefined;
            }
            result[i] = cached;
        }
        return result;
    }

    /**
     * @typedef {Object} CatalogMetadata
     * @property {Number} timestamp - timestamp of the last catalog modification
     * @property {String} version - hash of version
     * @property {String} locale - catalog locale (like "en-US")
     * @property {Number} publicationsCount - total count of publications in the catalog
     */

    /**
     * Gets catalog metadata.
     *
     * @returns {Promise.<CatalogMetadata>} - promise fulfilled with catalog metadata
     */
    getMetadata() {
        return this._provider.getMetadata().then(Object.freeze).catch(logReject);
    }

    /**
     * Gets publication by CID.
     *
     * @param {String} cid - CID of publication
     * @returns {Promise.<Publication>} - promise fulfilled with single publication
     */
    getPublication(cid) {
        if (this._publicationCache[cid] !== undefined) {
            return Promise.resolve(this._publicationCache[cid]);
        }

        return this._deduplicateRequest(`cid:${cid}`, () =>
            this._provider
                .getPublication(cid)
                .then(p => this._normalizePublication(p))
                .then(p => this._cachePublication(p))
                .catch(logReject),
        );
    }

    /**
     * Publication Owner
     * @typedef {Object} PublicationOwner
     * @property {String} userId - encrypted user Id (aka Encrypted Account Number)
     * @property {String} nickname - owner's nickname
     * @property {String} profileName - owner's profile name
     */

    /**
     * Gets an owner of the publication.
     *
     * @param {String} cid - publication CID
     * @returns {PublicationOwner} information about owner
     */
    getPublicationOwner(cid) {
        return this._deduplicateRequest(`cid-owner:${cid}`, () => this._provider.getPublicationOwner(cid).catch(logReject));
    }

    /**
     * @typedef {Object} PagedCollection
     * @property {Array} items - collection items
     * @property {Object} meta - metadata { offset, limit, totalCount }
     */

    /**
     * Finds publications in accordance to given criteria
     *
     * @param {Object} query - search query
     * @param {Filters} [filters] - object {@link Filters}
     * @param {Object} [query.orderBy] - sort order
     * @param {String} [query.orderBy.name] - fields to sort by - 'name', 'rank', 'latestIssueDate'
     * @param {String} [query.orderBy.order] - sort order 'asc' | 'desc', @see shared/data/sorting
     * @param {Number} [query.offset=0] - skip N items
     * @param {Number} query.limit - take N items
     * @returns {Promise.<PagedCollection.<Publication>>} - promise fulfilled with list of found publications
     */
    findPublications(query) {
        return this._provider
            .findPublications(query)
            .then(publications => ({
                items: publications.items.map(p => this._normalizePublication(p)),
                meta: publications.meta,
            }))
            .then(result => {
                this._cachePublications(result.items);
                return result;
            })
            .catch(logReject);
    }

    /**
     * Gets publications list by list of CID.
     *
     * @param {Array.<String>} cids - list of publications CIDs
     * @returns {Promise.<PagedCollection.<CatalogPublication>>} - promise fulfilled with single publication
     */
    getPublications(cids) {
        if (!Array.isArray(cids)) {
            throw new TypeError('"cids" argument has to be array');
        }
        if (cids.length === 0) {
            return Promise.resolve([]);
        }
        // try get all from cache
        const allCached = this._getCachedPublications(cids);
        if (allCached !== undefined) {
            return Promise.resolve(allCached);
        }

        return this.findPublications({
            filters: { cids },
            offset: 0,
            limit: cids.length,
        }).then(result => result.items);
    }

    /**
     * Gets a single issue of the publication by CID and issue date.
     * If issue date was not specified returns the latest issue of the publication.
     *
     * @param {String} cid - publication CID
     * @param {Date} [issueDate] - issue date
     * @returns {Promise.<Issue>} issue
     */
    getIssue(cid, issueDate) {
        if (issueDate && !isDate(issueDate)) {
            throw new TypeError('issueDate has to be a Date object');
        }

        if (!issueDate && this._publicationCache[cid] !== undefined) {
            return Promise.resolve(this._publicationCache[cid].latestIssue);
        }

        return this._deduplicateRequest(`issue:${cid}${issueDate ? issueDate.getTime() : 'latest'}`, () =>
            this._provider
                .getIssue(cid, issueDate)
                .then(issue => this._normalizeIssue(issue))
                .catch(logReject),
        );
    }

    /**
     * Gets group of categories by group name.
     *
     * @param {String} name - name of categories group, e.g. "Countries", "Categories" (aka BaseContentCatalog), "Sections"
     * @returns {Promise.<CatalogCategoryGroup>} - promise fulfilled with single group
     */
    getCategoryGroup(name) {
        if (this._groupCache[name] !== undefined) {
            return Promise.resolve(this._groupCache[name]);
        }

        ensureLocalizations();
        return this._deduplicateRequest(`group:${name}`, () =>
            this._provider
                .getCategoryGroup(name)
                .then(group => {
                    this._groupCache[name] = this._normalizeCategoryGroup(group);
                    return this._groupCache[name];
                })
                .catch(logReject),
        );
    }

    /**
     * Resolves navigation path components.
     *
     * Splits path into components (by "/"), then for each component tries to find category or publication
     * that has slug matched to the component.
     *
     * Returns array of resolved catalog items in the order of components in path.
     * If path component hasn't been resolved, null is returned in place of non-resolvable component.
     *
     * @param {String} pathUri - navigation path expressed as string, like "canada/ontario/the-province"
     * @param {String} prefer - specifies catalog item type to prefer
     *                                    when both Category and Publication has the same slug
     * @returns {Promise.<Array<Object>>} promise of resolved items
     */
    resolveNavigationPath(pathUri, prefer = 'Category') {
        if (this._pathResolutionCache[pathUri] !== undefined) {
            return Promise.resolve(this._pathResolutionCache[pathUri]);
        }

        return this._deduplicateRequest(`resolve-path:${pathUri}:${prefer}`, () => {
            ensureLocalizations();
            return this._provider.resolveNavigationPath(pathUri, prefer).then(results =>
                results.map(result => {
                    if (result === null) {
                        return null;
                    }
                    if (result.itemType === 'Category') {
                        return {
                            itemType: result.itemType,
                            item: this._normalizeCategory(result.item),
                        };
                    }
                    if (result.itemType === 'Publication') {
                        return {
                            itemType: result.itemType,
                            item: this._normalizePublication(result.item),
                        };
                    }

                    throw new Error(`Unexpected item type ${result.itemType}`);
                }),
            );
        });
    }

    /**
     * Gets Languages categories list.
     *
     * @returns {Promise.<Array.<CatalogCategory>>}
     */
    getLanguages() {
        return this.getCategoryGroup(CategoryGroupName.Languages).then(group => group.categories);
    }

    /**
     * Gets Countries categories list.
     *
     * @returns {Promise.<Array.<CatalogCategory>>}
     */
    getCountries() {
        return this.getCategoryGroup(CategoryGroupName.Countries).then(group => group.categories);
    }

    /**
     * Gets Categories list.
     *
     * @returns {Promise.<Array.<CatalogCategory>>}
     */
    getCategories() {
        return this.getCategoryGroup(CategoryGroupName.Categories).then(group => group.categories);
    }

    /**
     * Get available locales.
     *
     * Locale is a combination of country and language
     * that has count publications filtered with certain criteria (smart == true) > pre-configured value (10)
     *
     * @returns {Promise.<Array.<Locale>>} promise fulfilled with array of locale object
     */
    getLocales() {
        if (this._localesCache !== null) {
            return Promise.resolve(this._localesCache);
        }
        return this._provider
            .getLocales()
            .then(data => {
                // unpack response
                const countries = keyBy(data.countries, 'id');
                const languages = keyBy(data.languages, 'id');

                this._localesCache = data.locales.map(locale => {
                    const country = Object.freeze(countries[locale[0]]);
                    const language = Object.freeze(languages[locale[1]]);
                    return Object.freeze({
                        code: `${country.iso}-${language.iso}`,
                        country,
                        language,
                    });
                });

                return this._localesCache;
            })
            .catch(logReject);
    }

    /**
     * Extend each category in the list with titlePublication property which holds a Publication object
     * resolved by titlePublicationCid
     * @param {Array.<CatalogCategory>} categories - list of categories
     * @returns {Promise.<Array.<CatalogCategory>>} - list of categories extended with title publication
     */
    extendCategoriesWithTitlePublication(categories) {
        const cids = uniq(categories.map(category => category.titlePublicationCid));
        return this.getPublications(cids).then(pubs => {
            const pubsByCid = keyBy(pubs, 'cid');
            return categories.map(category =>
                extend(
                    {
                        titlePublication: pubsByCid[category.titlePublicationCid],
                    },
                    category,
                ),
            );
        });
    }

    _invalidateCaches() {
        this._groupCache = {};
        this._publicationCache = {};
        this._pathResolutionCache = {};
        this._localesCache = null;
        this._featuredCache = null;
        this._featuredHotspotCache = null;
    }

    /**
     * Checks whether catalog has been updated since last metadata request.
     */
    _checkUpdates() {
        this.getMetadata().then(metadata => {
            if (this._latestMetadata !== null && this._latestMetadata.version !== metadata.version) {
                this._invalidateCaches();
            }
            this._latestMetadata = metadata;

            // schedule next check
            this._checkUpdatesTimer = setTimeout(() => this._checkUpdates(), updatesCheckInterval);
        });
    }

    destroy() {
        if (this._checkUpdatesTimer) {
            clearTimeout(this._checkUpdatesTimer);
        }
        this._invalidateCaches();
        ndEvent.unbind(ndUser, 'onuserstatechanged', this._invalidateCaches);
    }
}

export default CatalogService;
