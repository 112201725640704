import { cloneDeep } from 'lodash';

import { getConfigValue, getConfigValueAsync } from '@pressreader/config';
import { error } from '@pressreader/logger';
import { baseUrl, fetchRequest, IFetchOptions, IRetryPolicy, isPageHttps, svcAuth } from '@pressreader/services';

import HttpError from 'shared/errors/http.error';

interface IRequestConfig extends IFetchOptions {
    serviceName?: string;
    disableRetry?: boolean;
    cancellableKey?: string;
}

interface IServiceConfig {
    httpsrequired?: string | boolean;
    httpsRequired?: string | boolean;
    cdnProfile?: string;
    retryPolicy?: IRetryPolicy;
}

interface ICdnProfile {
    httpsBaseUrl?: string;
    httpsRequired?: string | boolean;
    baseUrl: string;
}

type IRequestParams<TData = any> =
    | {
          service: string;
          url?: never;
          command?: string;
          data?: TData;
          requestConfig?: IRequestConfig;
      }
    | {
          service?: never;
          url: string;
          command?: never;
          data?: TData;
          requestConfig?: IRequestConfig;
      };

function isTrue(value: string | boolean) {
    return value === true || value === 'true';
}

function getUrlPath(url: string) {
    if (url.indexOf('://') > 0) {
        url = url.substr(url.indexOf('/', 8));
    }
    return url;
}

function appendPath(url: string, path: string) {
    if (url[url.length - 1] === '/' && path[0] === '/') {
        path = path.substr(1);
    }
    return url + path;
}

async function sendRequest<TResult>(
    type: 'POST' | 'GET' | 'PUT' | 'DELETE',
    { url: fullUrl, service, command, data, requestConfig }: IRequestParams,
) {
    if (!requestConfig) {
        requestConfig = {};
    }

    if (!requestConfig.serviceName) {
        // serviceName is used as identification for lookup preloaded data
        // by default serviceName = {service}/{command};
        requestConfig.serviceName = `${service}${command ? `/${command}` : ''}`;
    }

    const [bearerToken, services] = await Promise.all([svcAuth.authorized(), getConfigValueAsync<Record<string, IServiceConfig>>('services', {})]);
    const serviceConfig = services[service] || {};

    // for secure pages force using https
    let httpsRequired = isPageHttps() || isTrue(serviceConfig.httpsrequired) || isTrue(serviceConfig.httpsRequired);
    let isCDN = false;

    const headers: Record<string, string> = {};

    let _baseUrl = baseUrl();
    if (serviceConfig.cdnProfile) {
        const profile = getConfigValue<Record<string, ICdnProfile>>('cdnProfiles', {})[serviceConfig.cdnProfile];
        if (profile) {
            const httpsSupported = !!profile.httpsBaseUrl;
            if (httpsSupported || !httpsRequired) {
                const path = getUrlPath(_baseUrl);
                // check if the profile requires always use ssl (http 2.0)
                httpsRequired = httpsRequired || isTrue(profile.httpsRequired);

                _baseUrl = httpsRequired ? profile.httpsBaseUrl : profile.baseUrl;
                _baseUrl = appendPath(_baseUrl, path);

                isCDN = true;
            }
        }
    }

    let url = `${_baseUrl}${service}/`;
    if (!isCDN) {
        // do not pass token to cdn servers
        headers['Authorization'] = `Bearer ${bearerToken}`;
    }

    if (httpsRequired) {
        url = url.replace(/^http:\/\//i, 'https://');
    }

    if (command) {
        url += command;
    }

    const defaultRetryPolicy = {
        maxRetries: requestConfig.disableRetry ? 0 : 3,
    };
    const retryPolicy = { ...defaultRetryPolicy, ...serviceConfig.retryPolicy };

    const settings: IFetchOptions = {
        method: type,
        url: fullUrl || url,
        data: type === 'GET' ? null : data,
        query: type === 'GET' ? data : null,
        ...requestConfig,
        headers: {
            ...headers,
            ...requestConfig.headers,
        },
    };

    return await new Promise<TResult>((resolve, reject) => {
        fetchRequest<TResult>(settings, retryPolicy)
            .then(json => {
                try {
                    resolve(json);
                } catch (e) {
                    error(`Unable call resolve on deferred in service call: ${url}`, e);
                }
            })
            .catch(e => {
                try {
                    reject(new HttpError(e));
                } catch (e) {
                    error(`Unable call reject on deferred in service call: ${url}`, e);
                }
            });
    });
}

/** Parse arguments and call sendRequest. */
function callSendRequest<TResult>(type: 'POST' | 'GET' | 'PUT' | 'DELETE', ...args: any[]) {
    if (typeof args[0] === 'string') {
        // Keeping arguments parsing unchanged to avoid any side effects.

        const service = args[0];
        args = args.slice(1);

        // Next arg could be command or data.
        let command: string;
        if (args.length > 0 && typeof args[0] === 'string') {
            command = args[0];
            args = args.slice(1);
        }

        // Next arg is data.
        let data: any;
        if (args.length > 0) {
            if (typeof args[0] === 'object') {
                // Make sure the data is immutable.
                data = cloneDeep(args[0]);
            } else {
                data = args[0] === undefined ? {} : args[0];
            }
            args = args.slice(1);
        }

        // Next arg requestConfig.
        let requestConfig: IRequestConfig;
        if (args.length > 0) {
            requestConfig = args[0];
            args = args.slice(1);
        }

        return sendRequest<TResult>(type, { service, command, data, requestConfig });
    }

    // Ensuring the parameters object is immutable.
    const params: IRequestParams = { ...args[0] };

    if (typeof params.data === 'object') {
        // Make sure the data is immutable.
        params.data = cloneDeep(params.data);
    } else if (params.data === undefined) {
        params.data = {};
    }

    if (params.requestConfig) {
        // Request config gets changed inside sendRequest so making sure the original stays intact.
        params.requestConfig = cloneDeep(params.requestConfig);
    }

    return sendRequest<TResult>(type, params);
}

function get<TResult>(service: string, command?: string, data?: any, requestConfig?: IRequestConfig): Promise<TResult>;
function get<TResult>(service: string, data: object | boolean | number, requestConfig?: IRequestConfig): Promise<TResult>;
function get<TResult, TData = any>(params: IRequestParams<TData>): Promise<TResult>;
function get<TResult>(...args: any[]) {
    return callSendRequest<TResult>('GET', ...args);
}

function post<TResult>(service: string, command?: string, data?: any, requestConfig?: IRequestConfig): Promise<TResult>;
function post<TResult>(service: string, data: object | boolean | number, requestConfig?: IRequestConfig): Promise<TResult>;
function post<TResult, TData = any>(params: IRequestParams<TData>): Promise<TResult>;
function post<TResult>(...args: any[]) {
    return callSendRequest<TResult>('POST', ...args);
}

function put<TResult>(service: string, command?: string, data?: any, requestConfig?: IRequestConfig): Promise<TResult>;
function put<TResult>(service: string, data: object | boolean | number, requestConfig?: IRequestConfig): Promise<TResult>;
function put<TResult, TData = any>(params: IRequestParams<TData>): Promise<TResult>;
function put<TResult>(...args: any[]) {
    return callSendRequest<TResult>('PUT', ...args);
}

function _delete<TResult>(service: string, command?: string, data?: any, requestConfig?: IRequestConfig): Promise<TResult>;
function _delete<TResult>(service: string, data: object | boolean | number, requestConfig?: IRequestConfig): Promise<TResult>;
function _delete<TResult, TData = any>(params: IRequestParams<TData>): Promise<TResult>;
function _delete<TResult>(...args: any[]) {
    return callSendRequest<TResult>('DELETE', ...args);
}

const servicesApi = {
    baseUrl,
    get,
    put,
    post,
    delete: _delete,
};

export { servicesApi };
