const scriptPromises: Record<string, Promise<HTMLScriptElement | never>> = {};

function loadExternalScript(src: string, scriptProperties?: Partial<Omit<HTMLScriptElement, 'src'>>): Promise<HTMLScriptElement | never> {
    try {
        if (src?.startsWith('//')) {
            src = `${document.location.protocol}${src}`;
        }
        new URL(src);
    } catch (error) {
        return Promise.reject(error);
    }

    const properties = {
        async: true,
        ...scriptProperties,
    };

    if (!scriptPromises[src]) {
        scriptPromises[src] = new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.onload = () => resolve(script);
            script.onerror = (oError: string | Event) => reject(new URIError(`The script ${src} didn't load correctly. ${oError}`));
            Object.keys(properties).forEach(option => {
                (script as any)[option] = (properties as any)[option];
            });
            document.body.appendChild(script);
            script.src = src;
        });
    }

    return scriptPromises[src];
}

export { loadExternalScript };
