import { httpGet, httpPost } from '@pressreader/services';

interface IResponseDto {
    DefaultLocales: string[];
    FollowingUsers: {
        AccountNumber: number;
        AvatarImageId: string;
        BackgroundImageSettings: null;
        DisplayName: string;
        Email: string;
        EnAccountNumber: string;
        EnablePromotionalEmails: boolean;
        EnableServiceEmails: boolean;
        IsCurrentUser: boolean;
        IsDeviceAccount: boolean;
        IsProfilePrivate: boolean;
        Location: null;
        LocationLatLng: { Lat: number; Lng: number };
        Nickname: string;
        PhotoUrl: string;
        PostsCount: number;
        ProfileId: string;
        PublicInfo: null;
    }[];
    UserSettings: {
        FeedSettings?: {
            locales: { country: string; language: string }[];
            sections: {
                cid: string;
                issueName: string;
                sectionName: string;
            }[];
        };
        [key: string]: unknown;
    };
    MyNewspapers: string[];
}

let load = async () => httpGet<IResponseDto>('user', 'settings');

let merge = (changes: Record<string, unknown>) =>
    httpPost<boolean>('user', 'settings/merge', JSON.stringify(changes), { contentType: 'application/json' });

function setGlobalSettingsApi(loadGlobalSettings: typeof load, mergeGlobalSettings: typeof merge) {
    load = loadGlobalSettings;
    merge = mergeGlobalSettings;
}

export { IResponseDto, load, merge, setGlobalSettingsApi };
