import camelCase from 'lodash/camelCase';
import isPlainObject from 'lodash/isPlainObject';

import { ToCamelCase } from './types';

function toCamelCase<T>(object: T): ToCamelCase<T> {
    if (Array.isArray(object)) {
        return object.map(value => {
            if (typeof value === 'object') {
                return toCamelCase(value);
            }
            return value;
        }) as ToCamelCase<T>;
    }
    if (isPlainObject(object)) {
        const newObject: Record<string, unknown> = {};
        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key)) {
                newObject[camelCase(key)] = toCamelCase(object[key]);
            }
        }
        return newObject as ToCamelCase<T>;
    }
    return object as ToCamelCase<T>;
}

export { ToCamelCase, toCamelCase };
