/* eslint-env es6 */

'use strict';

import { isString } from 'lodash';

/**
 * The localizable error object.
 * This class is derived from Error-object that imposes the restrictions on child classes (see description).
 *
 * @description
 * TypeScript transpiler works incorrectly when extend the Error-object so that:
 *   1. Avoid using any code located outside constructor such as getters/setters/methods or use the fix suggested by Microsoft (see link below).
 *   2. Transpiled version of this class will not support 'instanceof' operator as expected.
 *      For example: (new LocalizableError('message', 'key', error) instanceof LocalizableError) -> false
 *                   (new LocalizableError('message', 'key', error) instanceof Error) -> true.
 * @see https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
 */
class LocalizableError extends Error {
    constructor(message, resourceKey, originalError) {
        if (!isString(message)) {
            throw new TypeError('Exception message has to be a string.');
        }

        super(message);

        this.resourceKey = resourceKey;
        this.error = originalError;
    }
}

export default LocalizableError;
