import { Observable, Subject } from 'rxjs';

import config from '@pressreader/src/deprecated/nd.config';

import { IIdentity } from './adapter';
import { ScreensetType } from './screenSets';

const GigyaScreensets = {
    [ScreensetType.signIn]: 'signIn',
    [ScreensetType.register]: 'register',
};

export class Gigya implements IIdentity {
    public readonly onLoginSuccess: Observable<any>;
    public readonly onModalClosed: Observable<any>;
    public readonly onloginPluginLoad: Observable<any>;
    public readonly onloginPluginButtonClicked: Observable<any>;
    public readonly purchaseDialogEnabled = false;
    public enabled = false;

    private _screenSetParameters = {};
    private _loginUIParameters = {};

    private loginSuccess = new Subject<any>();
    private modalClosed = new Subject<void>();
    private modalReady = new Subject<void>();

    private loginPluginLoad = new Subject<void>();
    private loginPluginButtonClicked = new Subject<void>();

    private screenSetEventHandlers: { [key: string]: (...args: any[]) => void } = {
        onAfterScreenLoad: this.onAfterScreenLoad,
        onHide: this.onHide,
    };

    private loginPluginEventHandlers: { [key: string]: (...args: any[]) => void } = {
        onLoad: this.onPluginLoad,
        onButtonClicked: this.onPluginButtonClicked,
    };

    constructor() {
        this.onLoginSuccess = this.loginSuccess.asObservable();
        this.onModalClosed = this.modalClosed.asObservable();
        this.onloginPluginLoad = this.loginPluginLoad.asObservable();
        this.onloginPluginButtonClicked = this.loginPluginButtonClicked.asObservable();
    }

    public async load() {
        await config.loaded();

        const gigyaConfig = config.get('Authentication.Gigya');
        if (!gigyaConfig || !gigyaConfig.enabled) {
            return Promise.reject();
        }
        this.enabled = true;
        this._screenSetParameters = gigyaConfig.screenSets || {};
        this._loginUIParameters = gigyaConfig.LoginUIParams || {};

        if (!window.gigya) {
            try {
                await this.loadScript(gigyaConfig);
                this.initialize();
            } catch (error) {
                return Promise.reject();
            }
        }

        return Promise.resolve();
    }

    public signOut() {
        window.gigya.accounts.logout();
    }

    public signIn() {
        this.showScreenSet(ScreensetType.signIn);
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public closeModal() {}

    public showScreenSet(screenType: ScreensetType) {
        const screen = GigyaScreensets[screenType];
        if (!screen) {
            throw new Error(`screen ${screenType} is not defined.`);
        }

        const parameters = this._screenSetParameters[screen];
        if (!parameters || !parameters.screenSet) {
            throw new Error(`screen ${screenType} is not configured.`);
        }

        Object.keys(this.screenSetEventHandlers).forEach(key => (parameters[key] = this.screenSetEventHandlers[key].bind(this)));

        window.gigya.accounts.showScreenSet(parameters);
    }

    public showInlineScreenSet(screenType: ScreensetType, containerSelector: string) {
        this.showScreenSet(screenType);
    }

    public showLoginPlugin() {
        const parameters = this._loginUIParameters;
        parameters['containerID'] = 'LoginPluginComponentDiv';
        Object.keys(this.loginPluginEventHandlers).forEach(key => (parameters[key] = this.loginPluginEventHandlers[key].bind(this)));

        window.gigya.socialize.showLoginUI(parameters);
    }

    private async loadScript(cfg: any): Promise<void> {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://cdns.gigya.com/JS/socialize.js?apikey=${cfg.apiKey}`;
            script.text = JSON.stringify(cfg.Global);
            script.onload = () => resolve();
            script.onerror = reject;
            const firstScript = document.getElementsByTagName('script')[0];
            firstScript.parentNode.insertBefore(script, firstScript);
        });
    }

    private async initialize() {
        window.gigya.socialize.addEventHandlers({
            onLogin: this.onLogin.bind(this),
        });

        window.gigya.accounts.getAccountInfo({
            callback: (account: any) => {
                if (account.UID) {
                    this.onLogin({
                        UID: account.UID,
                        UIDSignature: account.UIDSignature,
                        signatureTimestamp: account.signatureTimestamp,
                    });
                }
            },
        });
    }

    private onLogin(result: any) {
        this.loginSuccess.next(result);
    }

    private onAfterScreenLoad() {
        this.modalReady.next();
        document.body.classList.add('gigya-body');
    }

    private onHide() {
        this.modalClosed.next();
        document.body.classList.remove('gigya-body');
    }

    private onPluginLoad() {
        this.loginPluginLoad.next();
    }

    private onPluginButtonClicked() {
        this.loginPluginButtonClicked.next();
    }
}
