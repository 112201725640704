/* eslint-env es6 */
/* eslint-disable */
import * as $ from 'jquery';
import { httpPost } from '@pressreader/services';
import { fixToLocalURL, getBasePath } from '@pressreader/routing';

var baseView = function () {

    function openField(field) {
        if ($(field).next('.sl-edit').length > 0) {
            closeFields();
            clearFields();
            $(field).hide();
            $(field).next('.sl-edit').show();
            $(api).trigger("fieldOpened");
        }
    }

    function closeFields() {
        $('.sl-button').show();
        $('.sl-edit').hide();
    }

    function clearFields() {
        var fields = $(".js-field:not(.js-custom-clear)");
        fields.each(function () {
            var $this = $(this),
                old = $this.attr("data-field-old-value");

            if (typeof old !== "undefined" && old !== false) {
                $this.val(old).change();
                var $next = $this.next();
                if ($next.hasClass("select")) {
                    $next.text($('option:selected', this).text());
                }
            }
            else {
                $this.val('').change();
            }
        });

        fields.parents('form').each(function (index, item) {
            var validator = $.data(item, 'validator');
            if (validator) {
                validator.resetForm();
            }
        });
    }

    function updateAndCloseFields(form) {
        form.find(".js-field").each(function () {
            var $this = $(this),
                old = $this.attr("data-field-old-value");

            if (typeof old !== "undefined" && old !== false) {
                $this.attr("data-field-old-value", $this.val()).trigger('oldValueChanged');
            }
        });
        closeFields();
    }

    function save(form, url) {
        var $form = $(form);

        httpPost({
            url,
            requestConfig: {
                withCredentials: true,
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                accept: 'application/json',
            },
            data: $form.serialize(),
        }).then(function (data) {
            $form.find(".sl-button > .sli-content").text(data).change();
            updateAndCloseFields($form);
            $(api).trigger("saved", form);
        }).catch(function (error) {
            $.nd.alert().show(error.responseText);
        });
    }

    // Input elements focus state
    function textBoxFocus() {
        $('.textbox input, .selectbox select, .textarea textarea, .checkbox input, .radio input').focus(function () {
            $(this).parents('.textbox, .selectbox, .textarea, .checkbox, .radio').addClass('focus');
        }).blur(function () {
            $(this).parents('.textbox, .selectbox, .textarea, .checkbox, .radio').removeClass('focus')
        });
    }

    var api = {

        jqValidation: jqValidation,

        init: function () {
            var section = $("section.settings-group");
            section.on('click', '.sl-button', function () {
                openField(this);
            });

            if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i))) {

                section.on('touchstart', '.sl-button', function () {
                    $(this).addClass('sel');
                });
                section.on('touchend', '.sl-button', function () {
                    $(this).removeClass('sel');
                });
            }

            textBoxFocus();

            section.on('click', '[data-fields-save-url]', function (e) {

                // $("[data-fields-save-url]").click(function (e ) {
                var url = $(this).attr("data-fields-save-url");
                var form = $(this).parents("form");
                var changed = false;
                $(form).find(".js-field").each(function () {
                    var $field = $(this),
                        oldVal = $field.attr("data-field-old-value"),
                        newVal = $field.val();

                    if (oldVal !== newVal) {
                        changed = true;
                    }
                });

                if (!changed) {
                    return;
                }

                if (form.valid()) {
                    save(form, url);
                }

                e.stopImmediatePropagation();
                return false;
            });

            section.on('click', '.sl-edit .btn:not(.save) *', function (e) {
                clearFields();
                closeFields();
                e.preventDefault();
            });

            $("[data-fields-navigate-url]").click(function () {
                var url = $(this).attr("data-fields-navigate-url");
                window.location.assign(fixToLocalURL(url) || getBasePath());
            })
        },
        onSaved: function (fn) {
            $(api).bind("saved", fn);
        },
        onFieldOpened: function (fn) {
            $(api).bind("fieldOpened", fn);
        },
        openField: openField,
        updateAndCloseFields: updateAndCloseFields
    };

    return api;
}();

function jqValidation() {
    return {
        highlight: function (element) {
            $(element).parent('span').addClass('error');
        },
        unhighlight: function (element) {
            $(element).parent('span').removeClass('error');
        },
        errorElement: "span",
        errorClass: "validation-msg",
        showErrors: function (errorMap, errorList) {
            if (errorList.length < 1) {
                $('span.validation-msg').remove();
                return;
            }
            $.each(errorList, function (index, error) {
                $(error.element).next('span.validation-msg').remove();
                $(error.element).after(
                    $('<span/>')
                        .addClass('validation-msg')
                        .append($('<span/>').append(error.message))
                );
            });
        }
    }
}


$(function () {
    baseView.init();
});

var self = {
    jqValidation: jqValidation,
    onSaved: baseView.onSaved,
    openField: baseView.openField,
    updateAndCloseFields: baseView.updateAndCloseFields,
    onFieldOpened: baseView.onFieldOpened
};

export default self;