/**
 * Shims/replaces global properties/functions with project specific implementations.
 */

// Expressions such as require.ensure, import('').then(...) are transpiled into an expression using a Promise from a global scope.
// To support old browsers (such as IE10 or IE11) need to define that global Promise variable.
import { Subscription } from 'rxjs';

import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'core-js/features/promise';
import 'core-js/features/set';
import 'core-js/features/map'; // IE10
import 'core-js/features/array/from';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/fill';
import 'pepjs';
import '@pressreader/src/shim/requestAnimation.shim';
import '@pressreader/src/shim/performance.shim';
import '@pressreader/src/shim/dom/remove.polyfill';
import '@pressreader/src/shim/dom/matches.polyfill';

function subscriptionDispose() {
    // eslint-disable-next-line no-console
    if (console.trace) {
        // eslint-disable-next-line no-console
        console.trace('RXJS.Subscription.dispose() is obsolete. Please use .unsubscribe() instead.');
    }

    // eslint-disable-next-line prefer-rest-params
    return Subscription.prototype.unsubscribe.apply(this, arguments);
}

(Subscription.prototype as any).dispose = subscriptionDispose;
