import * as ko from 'knockout';
import * as $ from 'jquery';
        

        function TextWithDefaultBindingHandler() {
            this.update = function (element, valueAccessor) {
                var value = valueAccessor();
                $(element).text(value.value() || value.default);
            };

            this.init = function(element, valueAccessor) {
                var $element = $(element);
                $element.on('change', function() {
                    valueAccessor().value($element.text());
                    valueAccessor().value.notifySubscribers();
                })
            };
        }

        ko.bindingHandlers['textWithDefault'] = new TextWithDefaultBindingHandler();
    