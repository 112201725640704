﻿const COMPONENTS_SEPARATOR = '; ';

/**
 * Gets cookie value by name
 * @returns - cookie value if set, or default value if not
 */
function get(name: string, defaultValue: string | null = null) {
    const [, value] = document.cookie.match(new RegExp(`^${name}=(.*?)((;)|($))`)) ||
        document.cookie.match(new RegExp(`; ${name}=(.*?)((;)|($))`)) || [undefined, defaultValue];
    return typeof value === 'string' ? decodeURIComponent(value) : value;
}

/**
 * Sets cookie.
 *
 * @param name - cookie name
 * @param value - encoded value, should not contain ' ' or ';'
 * @param options - cookie options
 */
function set(name: string, value = '', options: ICookieOptions = DefaultCookieOptions) {
    const components = [
        `${
            encodeURIComponent(name) // Encode cookie name
        }=${
            encodeURIComponent(value) // Encode cookie value (RFC6265)
        }`,
    ];

    options = { ...DefaultCookieOptions, ...options };

    if (options.expiryDate) {
        if (typeof options.expiryDate.toUTCString !== 'function') {
            throw new Error('expiryDate has to be a Date');
        }
        components.push(`expires=${options.expiryDate.toUTCString()}`);
    } else if (options.expiresInDays) {
        if (typeof options.expiresInDays !== 'number') {
            throw new Error('expiresInDays has to be a number');
        }

        const expiryDate = new Date();
        // set date supports out of range argument,
        // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/setDate
        expiryDate.setDate(expiryDate.getDate() + options.expiresInDays);
        components.push(`expires=${expiryDate.toUTCString()}`);
    }

    if (options.path) {
        components.push(`path=${options.path}`);
    }
    if (options.domain) {
        components.push(`domain=${options.domain}`);
    }
    if (options.secure) {
        components.push('secure');
    }
    if (options.sameSite) {
        components.push(`SameSite=${options.sameSite}`);
    }

    document.cookie = components.join(COMPONENTS_SEPARATOR);
}

/**
 * Removes cookie and returns removed value
 * @returns removed value
 */
function remove(name: string) {
    const result = get(name);
    set(name, '', { expiresInDays: -1 });
    return result;
}

function has(name: string) {
    return get(name) !== null;
}

enum SameSiteOptions {
    'Lax' = 'Lax',
    'Strict' = 'Strict',
    'None' = 'None',
}

interface ICookieOptions {
    expiryDate?: Date;
    expiresInDays?: number;
    path?: string;
    domain?: string;
    secure?: boolean;
    sameSite?: SameSiteOptions;
}

const DefaultCookieOptions: ICookieOptions = {
    secure: /^https:$/i.test(document.location.protocol),
    sameSite: SameSiteOptions.Lax,
};

export { get, has, set, remove, SameSiteOptions, ICookieOptions, DefaultCookieOptions };
