/* eslint-env es6 */

import * as ko from 'knockout';
import { isFunction } from 'lodash';
import * as autosize from 'autosize';

/**
 * Automatically adjust textarea height.
 * See http://www.jacklmoore.com/autosize/ for details.
 *
 * Usage:
 * <textarea data-bind="autosize"></textarea>
 * or
 * <textarea data-bind="autosize: { onResize: handler }"></textarea>
 */
ko.bindingHandlers.autosize = {
    update(element, valueAccessor) {
        if (!element || element.tagName.toLowerCase() !== 'textarea') {
            throw new TypeError('autosize works only with textarea element');
        }

        autosize(element);

        const { onResize } = ko.unwrap(valueAccessor()) || {};
        if (isFunction(onResize)) {
            element.addEventListener('autosize:resized', onResize);
            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                element.removeEventListener('autosize:resized', onResize);
            });
        }
    },
};
