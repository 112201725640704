type NonNullableFields<T, TKeys extends keyof T = keyof T> = { [K in TKeys]-?: NonNullable<T[K]> } & T;

function isNonNullable<T>(value: T): value is NonNullable<T> {
    return value !== undefined && value !== null;
}

function isNonNullableKey<T extends object, TKey extends keyof T>(key: TKey) {
    return (value => isNonNullable(value?.[key])) as (value: T) => value is NonNullableFields<T, TKey>;
}

function isNonNullableFields<T extends object>(value: T): value is NonNullableFields<T>;
function isNonNullableFields<T extends object, TKey extends keyof T>(value: T, key: TKey): value is NonNullableFields<T, TKey>;
function isNonNullableFields<T extends object, K0 extends keyof T, K1 extends Exclude<keyof T, K0>>(
    value: T,
    key0: K0,
    key1: K1,
): value is NonNullableFields<T, K0 | K1>;
function isNonNullableFields<T extends object, K extends keyof T>(value: T, ...keys: K[]) {
    if (!keys.length) {
        keys = Object.keys(value) as K[];
    }
    return keys.every(key => isNonNullable(value[key]));
}

function deleteFields<T, TKeys extends keyof T>(value: T, ...keys: TKeys[]): Omit<T, TKeys> {
    const result = { ...value };
    for (const key of keys) {
        delete result[key];
    }

    return result;
}

export { NonNullableFields, isNonNullable, isNonNullableFields, deleteFields, isNonNullableKey };
