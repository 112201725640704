import servicesApi from 'nd.services.api';
import { apiSiteSignIn, apiSiteSignOut } from '@pressreader/services';

function signin(userName, password) {
    return servicesApi.post('auth', 'Signin', { userName, password });
}

function signinByKey(key) {
    return servicesApi.post('auth', 'SigninByKey', { key });
}

function siteSignin(token, enableAutologin) {
    return apiSiteSignIn(token, enableAutologin);
}

function signOut() {
    return servicesApi.get('auth', 'SignOut');
}

async function siteSignOut() {
    const data = await apiSiteSignOut();
    if (data?.RedirectLocation) {
        window.location.assign(data.RedirectLocation);
        return;
    }
    return data;
}

function getExternalRequestKey() {
    return servicesApi.get('ExternalAuth', 'GetRequestKey');
}

function getSott() {
    return servicesApi.get('ExternalAuth', 'GetSott');
}

function recoverPassword(logonName) {
    return servicesApi.post('auth', 'RecoverPassword', { logonName }, { disableRetry: true });
}

function register(data) {
    return servicesApi.post('auth', 'Register', data, { disableRetry: true });
}

function authorizeExternal(data) {
    return servicesApi.post('externalauth', 'AuthorizeExtAuth', JSON.stringify(data), { contentType: 'application/json' });
}

export default {
    signin,
    signinByKey,
    siteSignin,
    signOut,
    siteSignOut,
    getExternalRequestKey,
    getSott,
    recoverPassword,
    register,
    authorizeExternal,
};
