import { createActionCreator } from '@pressreader/appstore';
import { TargetPlatform } from '@pressreader/types';

import { MODULE_NAME } from '../constants';
import { Platform } from '../types';

const prefix = `${MODULE_NAME}/`;

export const coreModuleLoadDone = createActionCreator<{
    targetPlatform: TargetPlatform;
    faceliftEnabled: boolean;
    experimentalEnabled?: boolean;
}>(`${prefix}core-module-load-done`);

export const startAppAction = createActionCreator<{
    /** Whether app/common/navigation module should track navigation. Default true. */
    handleRouting?: boolean;
}>(`${prefix}start-app`);

export const appStarted = createActionCreator(`${prefix}app_started`);

export const changeTargetPlatformAction = createActionCreator<{
    newTargetPlatform: TargetPlatform;
    keepLocation?: boolean;
}>(`${prefix}change-target-platform`);

export const toggleFaceliftUiAction = createActionCreator<{
    enable: boolean;
}>(`${prefix}toggle-facelift-ui`);

export const legacyPlatformChanged = createActionCreator<{
    platform: Platform;
}>(`${prefix}legacy-platform-changed`);
