import { createSelector } from 'reselect';

import { createParameterSelector } from '@pressreader/appstore';
import { selectGetConfigValue } from '@pressreader/config';
import { localizedStringWithDefault } from '@pressreader/resources';

interface LanguagesDto {
    [code: string]: { name: string | null; pairs: string[] };
}

const selectLanguageCode = createParameterSelector<'language', string>('language');

const selectLanguageTitle = createSelector(selectGetConfigValue, selectLanguageCode, (getConfigValue, code) => {
    const languages = getConfigValue<Partial<LanguagesDto>>('translation.languages');
    if (!languages) {
        return '';
    }
    const dto = languages[code];
    if (!dto) {
        return '';
    }

    return localizedStringWithDefault(`Languages.${code}`, dto.name ?? '');
});

export { selectLanguageTitle };
