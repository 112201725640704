import * as $ from 'jquery';
import ndConfig from '@pressreader/src/deprecated/nd.config';
import ndEvent from '@pressreader/src/nd.event';
import serviceApi from 'nd.services.api';
import '@pressreader/src/nd.baseloader';
import { svcAuth } from '@pressreader/services';
import { isUserAuthenticated } from '@pressreader/authentication-api';
import { getBasePath } from '@pressreader/routing';

var _loader = new $.nd.baseLoader();

function isLogin() {
    return isUserAuthenticated();
}

function userStateChanged() {
    ndEvent.trigger('onuserstatechanged', user);
}

svcAuth.bind('tokenUpdated', userStateChanged);

function itemsToIssues(items) {
    var result = [];
    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        result.push(new $.nd.issue(item.cid, item.d, item.v, item.w, item.h, null));
    }
    return result;
}

function getLatestReadIssues() {
    return serviceApi.get('user', 'GetLatestReadIssues').then(function (items) {
        return itemsToIssues(items);
    });
}

function getRedirectionUrl(name) {
    if (!name) {
        return;
    }

    var url = '';
    const baseUrl = getBasePath();
    var redirectionPath = 'pagesView.Redirection.';
    var redirectionType = ndConfig.get(redirectionPath + name + '.Type');
    switch (redirectionType) {
        case '1': //HomePage
            url = baseUrl;
            break;
        case '2': //BackToReferrerUrl
            url = decodeURIComponent($.nd.navHistory.getReturnUrl());
            break;
        case '3': //MainCid
            url = baseUrl + ndConfig.get('catalog.MasterCid');
            break;
        case '4': //LatestReleaseCid
            url = baseUrl + 'latest';
            break;
        case '5': //SpecificCid
            url = baseUrl + ndConfig.get(redirectionPath + name + '.Cid');
            break;
        case '6': //SpecifiedUrl
            url = ndConfig.get(redirectionPath + name + '.Url');
            break;
        default:
            break;
    }
    return url;
}

function redirectTo(name) {
    var url = getRedirectionUrl(name);
    if (url) {
        window.location.assign(url);
    }
}

var user = {
    loader: _loader,
    //Settings: _userSettings,
    userStateChanged: userStateChanged,
    onUserStateChanged: function (fn) {
        ndEvent.bind(user, 'onuserstatechanged', null, fn);
    },
    userLocalesChanged: function () {
        ndEvent.trigger('onuserlocaleschanged', user);
    },
    onUserLocalesChanged: function (fn) {
        ndEvent.bind(user, 'onuserlocaleschanged', null, fn);
    },
    isLogin: isLogin,
    getSmartEditorUrl: function (issueId, page) {
        return serviceApi.get('smartEditor', 'getEditUrl', { issueInfoId: issueId, page: page });
    },
    getLatestReadIssues: getLatestReadIssues,
    getRedirectionUrl: getRedirectionUrl,
    redirectTo: redirectTo,
};

$.nd.user = user;
export default user;
