import { httpGet } from '@pressreader/services';
import { eventbus } from '@pressreader/src/eventbus/eventbus';

import { IEcommerceData, IEcommercePromotion } from 'analytics/ecommerce/models/models';
import { PromoClickEvent } from 'purchase/events/events';

function sendPromoCodeAnalytics(promoCode: string) {
    const ecommercePromotions: IEcommercePromotion[] = [
        {
            id: 'PromoCode',
            name: promoCode,
        },
    ];
    eventbus.send(
        new PromoClickEvent({
            promotions: ecommercePromotions,
        } as IEcommerceData),
    );
}

function get(path: string, promoCode: string) {
    const url = new URL(`${window.location.origin}${window.basePath}Subscription/${path}`);
    const params = new URLSearchParams(url.search);
    params.append('promoCode', promoCode);
    return httpGet({
        url: `${url}?${params}`,
        requestConfig: {
            cache: 'no-store',
        },
    });
}

const validatePromoCode = async (promoCode: string) => {
    if (!promoCode.trim()) {
        return Promise.resolve({
            isValid: false,
        });
    }

    const data: any = await get('ValidatePromoCode', promoCode);
    if (data && data.isValid) {
        sendPromoCodeAnalytics(promoCode);
    }
    return data;
};

export default {
    validatePromoCode,
    getPromoSubscriptions: (promoCode: string) => get('GetPromoSubscriptions', promoCode),
};
