/* eslint-env es6 */

import * as $ from 'jquery';
import { get, has, mapKeys } from 'lodash';

import ndUtils from '@pressreader/src/nd.utils';

import printingCore from 'nd.printing.core';
import ndRes from 'nd.res';

function handleUriParams() {
    const params = mapKeys(ndUtils.getUrlParameters(), (_v, k) => k.toLowerCase());
    const noTopbarParam = has(params, 'notopbar') ? get(params, 'notopbar') : false;

    if (noTopbarParam !== false) {
        $('#toolbarTop').hide();
    }
}

function init() {
    handleUriParams();
    $('.js-print').on('click', () => {
        ndRes.loaded().then(() => {
            const elem = document.getElementsByClassName('receiptSection')[0];
            printingCore.printElement(ndRes.val('Init.Title'), elem);
        });
    });
}

export default { init };
