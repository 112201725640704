import { NIL as NIL_UUID } from 'uuid';

import api from 'nd.services.api';
import { IEcommerceData } from 'analytics/ecommerce/models/models';

const getPurchaseInfo = (successOrderId?: string): Promise<IEcommerceData> =>
    api.get('AnalyticsPurchaseInfo', '', {
        successOrderId: successOrderId || NIL_UUID,
    });

export { getPurchaseInfo };
