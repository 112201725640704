import ContextMenuItemController from 'contextmenu/base.controller';
import ndViews from '@pressreader/src/nd.viewer.views';
import ndUser from 'nd.user';
import ndAuthentication from 'authentication/nd.authentication';
import { getBasePath } from '@pressreader/routing';
import { getConfigValue } from '@pressreader/config';
import ndTranslation from '@pressreader/src/nd.utils.translation';
import ndUiUtils from '@pressreader/src/nd.ui.utils';
import navigation from '@pressreader/src/nd.navigationhistory';

class SignOutMenuItemController extends ContextMenuItemController {
    item() {
        return this._getItem('signout', 'Viewer.SignOut', 'pri pri-logout');
    }

    available() {
        return Promise.resolve(true);
    }

    enabled() {
        return Promise.resolve(true);
    }

    async activate() {
        this._parent.hide();
        // should redirect to the main page if used from server page
        const activeView = ndViews.getActiveView();
        const externalPage = getConfigValue('Authentication.ExternalLogoutPage');
        const enablePublisherAuthenticationWebService = getConfigValue('Authentication.EnablePublisherAuthenticationWebService');
        let url = '';

        if (enablePublisherAuthenticationWebService && externalPage) {
            url = externalPage;
        } else {
            url = ndUser.getRedirectionUrl('AfterSignOut');
        }

        const redirectRequired = activeView === null || url !== '';

        const signOutUrl = await ndAuthentication.signOut({ supressReauthorize: redirectRequired });
        if (redirectRequired || signOutUrl) {
            window.location.assign(signOutUrl || url || getBasePath());
        } else {
            navigation.resetState();
            ndTranslation.enableOriginalLanguage();
            ndUiUtils.toolbarSetup($('#toolbarTop'));
        }
    }
}

export default SignOutMenuItemController;
