import { createSelector } from 'reselect';

import { format } from '@pressreader/utils';

import { RESOURCES_MODULE_NAME } from '../constants';

import { IState } from './reducer';

function getState(state: { [RESOURCES_MODULE_NAME]: IState }) {
    return state[RESOURCES_MODULE_NAME];
}

export const selectResources = createSelector(getState, state => state.resources);
export const selectTemplates = createSelector(getState, state => state.templates);
export const selectStatus = createSelector(getState, state => state.status);

export const selectGetLocalizedString = createSelector(getState, state => (resourceId: string, ...values: string[]) => {
    if (!resourceId) {
        return resourceId;
    }

    const resValue = state.resources[resourceId.toLowerCase()];
    return resValue && values.length ? format(resValue, ...values) : resValue ?? resourceId;
});
