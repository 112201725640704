import { storeManager } from '@pressreader/appstore';

import { RESOURCES_MODULE_NAME } from '../constants';
import { reducer } from './reducer';
import { saga } from './sagas';

storeManager.addFeature(RESOURCES_MODULE_NAME, reducer, saga);

export { loadResourcesAction, resourcesChanged, resourcesLoadBegan, resourcesLoadDone, resourcesLoadFailed } from './actions';
export { selectGetLocalizedString, selectTemplates, selectStatus } from './selectors';
