import res from 'nd.res';

const signInStatusMessages = {
    1: () => res.val('Dialogs.Signin.InvalidLoginOrPass', 'Invalid user name or password.'),
    2: () => res.val('Dialogs.Signin.MaxAllowedSimultaneousSessionsExceeded', 'You have exceeded the number of simultaneous sessions.'),
    5: () => res.val('Dialogs.Signin.Invactive', 'Your account is not active.'),
};

const authorizeExtAuthStatusMessages = {
    InvalidUserNameOrPassword: () => res.val('Errors.SignInFailed2', 'Invalid user name or password.'),
    SubscriptionRequired: () => res.val('Errors.SubscriptionRequired', 'Subscription Required.'),
    Found: () => res.val('Errors.UserFound', 'User Found.'),
    Error: () => res.val('Errors.SignInFailed2', 'SignIn Failed.'),
    InvalidToken: () => res.val('Errors.AccessTokenExpired', 'Invalid Access Token'),
    UserAlreadyExists: () => res.val('Errors.UserAlreadyExists', 'User Already Exists'),
};

// Map backend enum results to front functions that return messages
const VerifyAccountStatusMessages = {
    InvalidUserName: signInStatusMessages[1],
    InvalidPassword: signInStatusMessages[1],
    UnknownError: () => res.val('Errors.ErrorOccurred', 'Error Occurred'),
    Found: authorizeExtAuthStatusMessages.Found,
    SubscriptionRequired: authorizeExtAuthStatusMessages.SubscriptionRequired,
    Unknown: () => res.val('Errors.ErrorOccurred', 'Error Occurred'),
    InvalidToken: authorizeExtAuthStatusMessages.InvalidToken,
    UserAlreadyExists: authorizeExtAuthStatusMessages.UserAlreadyExists,
};

const AuthenticationResultMessages = {
    InvalidUserName: signInStatusMessages[1],
    InvalidPassword: signInStatusMessages[1],
    ExceedMaxSimultaneousSessionsPerUser: signInStatusMessages[2],
    UnknownError: () => res.val('Errors.ErrorOccurred', 'Error Occurred'),
    ExceedMaxIncorrectPasswordAttempts: () => res.val('Errors.ErrorOccurred', 'Error Occurred'),
};

export { VerifyAccountStatusMessages, AuthenticationResultMessages, signInStatusMessages, authorizeExtAuthStatusMessages };
