import { registerErrorHandler } from '@pressreader/content-api';

import { archivedIssueBuyingRequired } from '@pressreader/src/contentservice/handlers/archive.issue.purchase';
import { issueReadingConfirmationRequired } from 'web/contentservice/handlers/issue.reading.confirmation';
import { externalAuthenticationRequired } from '@pressreader/src/contentservice/handlers/external.authentication';
import { emailRequired } from '@pressreader/src/contentservice/handlers/email.required';
import { offlineReaderRequired } from '@pressreader/src/contentservice/handlers/offline.reader.required';

registerErrorHandler('ArchivedIssueBuyingRequired', archivedIssueBuyingRequired, true);
registerErrorHandler('ConfirmationRequired', issueReadingConfirmationRequired, true);
registerErrorHandler('ExternalAuthenticationRequired', externalAuthenticationRequired, true);
registerErrorHandler('EmailRequired', emailRequired, true);
registerErrorHandler('OfflineReaderIsNotInstalled', offlineReaderRequired, true);
