// eslint-env es6
import { supportsValidation } from 'ko/nd/validate';

/**
 * Resolves 'validation' parameter.
 * Used with controls which support validation.
 *
 * @param {ko.observable|undefined|Boolean} validationSource - value of 'validation' parameter,
 *                                                  that holds an observable which validation state
 *                                                  is displayed inside the control.
 * @param {ko.observable|undefined} defaultSource - default observable source,
 *                                                  serves as validationSource if validationSource === true.
 * @returns {ko.observable|undefined} one of the sources or undefined if both undefined.
 * @throws {Error} if one of defined sources does not support validation.
 */
export function resolveValidationParameter(validationSource, defaultSource) {
    if (validationSource === true) {
        if (!supportsValidation(defaultSource)) {
            throw new Error('Invalid "validation" param value - "true": value model does not support validation');
        }
        return defaultSource;
    } else if (validationSource !== false && validationSource !== undefined) {
        if (!supportsValidation(validationSource)) {
            throw new Error('Invalid "validation" param value: provided model does not support validation');
        }
        return validationSource;
    }

    return undefined;
}
