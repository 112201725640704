import * as ko from 'knockout';
import * as $ from 'jquery';
        

        /*
         * Usage:
         * <div data-bind="nd-scrolled-to-bottom-edge: $root.onScrolled, edgeDelta:150">
         * </div>
         *
         * "onScrolled" will be called every time user scrolls to the bottom edge and scroller has less than 150px left
         * */
        function ScrolledToBottomEdge() {
            this.update = function(element, valueAccessor, allBindingsAccessor) {
                var handler = valueAccessor();

                if(!$.isFunction(handler)) {
                    return;
                }

                var $scrollContainer = $(element);

                var d = allBindingsAccessor.get('edgeDelta') || 150;
                $scrollContainer.scroll(function() {
                    var containerHeight = $scrollContainer.outerHeight();
                    var contentHeight = $scrollContainer.prop('scrollHeight');
                    var scrollPosition = $scrollContainer.scrollTop();
                    if (scrollPosition + containerHeight + d > contentHeight) {
                        handler();
                    }
                    return true;
                });
            };
        }
        
        ko.bindingHandlers['nd-scrolled-to-bottom-edge'] = new ScrolledToBottomEdge();
    