const TWO_PWR_16_DBL = 1 << 16;
const TWO_PWR_32_DBL = TWO_PWR_16_DBL * TWO_PWR_16_DBL;
const TWO_PWR_64_DBL = TWO_PWR_32_DBL * TWO_PWR_32_DBL;
const TWO_PWR_63_DBL = TWO_PWR_64_DBL / 2;

/**
 * Port of the https://github.com/google/closure-library/blob/master/closure/goog/math/long.js
 */
class Long {
    private _low: number;
    private _high: number;
    /**
     * Constructs a 64-bit two's-complement integer, given its low and high 32-bit
     * values as *signed* integers.  See the from* functions below for more
     * convenient ways of constructing Longs.
     *
     * @param low  The low (signed) 32 bits of the long.
     * @param high  The high (signed) 32 bits of the long.
     */
    constructor(low: number, high: number) {
        this._low = low;
        this._high = high;
    }

    static fromInt(value: number) {
        return new Long(value | 0, value < 0 ? -1 : 0);
    }

    static fromBits(lowBits: number, highBits: number) {
        return new Long(lowBits, highBits);
    }

    static fromNumber(value: number): Long {
        if (isNaN(value)) {
            return this.zero;
        } else if (value <= -TWO_PWR_63_DBL) {
            return Long.minValue;
        } else if (value + 1 >= TWO_PWR_63_DBL) {
            return Long.maxValue;
        } else if (value < 0) {
            return Long.fromNumber(-value).negate();
        }

        return new Long(value % TWO_PWR_32_DBL | 0, (value / TWO_PWR_32_DBL) | 0);
    }

    shiftLeft(numBits: number) {
        numBits &= 63;
        if (numBits === 0) {
            return this;
        }

        const low = this._low;
        if (numBits < 32) {
            const high = this._high;
            return Long.fromBits(low << numBits, (high << numBits) | (low >>> (32 - numBits)));
        }
        return Long.fromBits(0, low << (numBits - 32));
    }

    shiftRight(numBits: number) {
        numBits &= 63;

        if (numBits === 0) {
            return this;
        }

        const high = this._high;
        if (numBits < 32) {
            const low = this._low;
            return Long.fromBits((low >>> numBits) | (high << (32 - numBits)), high >> numBits);
        }

        return Long.fromBits(high >> (numBits - 32), high >= 0 ? 0 : -1);
    }

    and(other: Long) {
        return Long.fromBits(this._low & other._low, this._high & other._high);
    }

    or(other: Long) {
        return Long.fromBits(this._low | other._low, this._high | other._high);
    }

    toNumber() {
        return this._high * TWO_PWR_32_DBL + this.getLowBitsUnsigned();
    }

    equals(other: Long) {
        return this._high === other._high && this._low === other._low;
    }

    add(other: Long) {
        // Divide each number into 4 chunks of 16 bits, and then sum the chunks.
        const a48 = this._high >>> 16;
        const a32 = this._high & 0xffff;
        const a16 = this._low >>> 16;
        const a00 = this._low & 0xffff;

        const b48 = other._high >>> 16;
        const b32 = other._high & 0xffff;
        const b16 = other._low >>> 16;
        const b00 = other._low & 0xffff;

        let c48 = 0;
        let c32 = 0;
        let c16 = 0;
        let c00 = 0;
        c00 += a00 + b00;
        c16 += c00 >>> 16;
        c00 &= 0xffff;
        c16 += a16 + b16;
        c32 += c16 >>> 16;
        c16 &= 0xffff;
        c32 += a32 + b32;
        c48 += c32 >>> 16;
        c32 &= 0xffff;
        c48 += a48 + b48;
        c48 &= 0xffff;

        return Long.fromBits((c16 << 16) | c00, (c48 << 16) | c32);
    }

    subtract(other: Long) {
        return this.add(other.negate());
    }

    not() {
        return Long.fromBits(~this._low, ~this._high);
    }

    negate() {
        if (this.equals(Long.minValue)) {
            return Long.minValue;
        }

        return this.not().add(Long.one);
    }

    getLowBitsUnsigned() {
        return this._low >= 0 ? this._low : TWO_PWR_32_DBL + this._low;
    }

    static get zero() {
        return Long.fromInt(0);
    }

    static get one() {
        return Long.fromInt(1);
    }

    static get minValue() {
        return Long.fromBits(0, 0x80000000 | 0);
    }

    static get maxValue() {
        return Long.fromBits(0xffffffff | 0, 0x7fffffff | 0);
    }

    /**
     * Extracts bits from long.
     * @param {Number} from - number of the bit from.
     * @param {Number} to - number of the bit to.
     */
    extractBits(from: number, to: number) {
        if (isNaN(from) || isNaN(to) || from < 0 || to < 0 || from > to) {
            throw new Error('invalid parameter');
        }

        const mask = Long.one
            .shiftLeft(to - from + 1)
            .subtract(Long.one)
            .shiftLeft(from);
        return this.and(mask).shiftRight(from).toNumber();
    }
}

export { Long };
