import * as $ from 'jquery';
import Uri from 'jsuri';
import { Observable, Subject } from 'rxjs';

import config from '@pressreader/src/deprecated/nd.config';

import { GesturesManager } from 'nd.gestures';
import res from 'nd.res';
import templates from 'nd.templates';

import { IIdentity } from './adapter';
import { ScreensetType } from './screenSets';

const JanrainScreensets = {
    [ScreensetType.signIn]: 'signIn',
    [ScreensetType.forgotPassword]: 'forgotPassword',
    [ScreensetType.resetPasswordRequestCode]: 'resetPasswordRequestCode',
};

export class Janrain implements IIdentity {
    public readonly onLoginSuccess: Observable<any>;
    public readonly onModalClosed: Observable<any>;
    public readonly purchaseDialogEnabled = false;
    public enabled = false;

    private loginSuccess = new Subject<any>();
    private modalClosed = new Subject<void>();
    private modalReady = new Subject<void>();

    private janrainEventHandlers: { [key: string]: (...args: any[]) => void } = {
        onCaptureEmailVerificationSuccess: this.onCaptureEmailVerificationSuccess,
        onCaptureLoginSuccess: this.onCaptureLoginSuccess,
        onCaptureLoginFailed: this.onCaptureLoginFailed,
        onCaptureSessionFound: this.onCaptureSessionFound,
        onCaptureAccountReactivateSuccess: this.onCaptureAccountReactivateSuccess,
        onCaptureRegistrationSuccess: this.onCaptureRegistrationSuccess,
        onCaptureAccountReactivateFailed: this.onCaptureAccountReactivateFailed,
        onModalOpen: this.onModalOpen,
        onModalClose: this.onModalClose,
        onCaptureScreenShow: this.onCaptureScreenShow,
        onCaptureModalReady: this.onCaptureModalReady,
    };

    constructor() {
        this.onLoginSuccess = this.loginSuccess.asObservable();
        this.onModalClosed = this.modalClosed.asObservable();
    }

    public async load() {
        await Promise.all([config.loaded(), templates.loaded(), res.loaded()]);

        const janrainConfig = config.get('Authentication.Janrain');
        if (!janrainConfig || !janrainConfig.enabled) {
            return Promise.reject();
        }
        this.enabled = true;

        if (!window.janrain) {
            window.janrain = window.janrain || { settings: {} };

            window.janrain = {
                ...window.janrain,
                ...{
                    settings: {
                        ...janrainConfig.settings,
                    },
                },
            };

            try {
                await this.loadScript(janrainConfig);
                await this.initialize();
                this.renderScreenByUrlParams();
            } catch (error) {
                return Promise.reject();
            }
        }

        return Promise.resolve();
    }

    public signOut() {
        window.janrain.capture.ui.endCaptureSession();
    }

    public signIn() {
        this.showScreenSet(ScreensetType.signIn);
    }

    public closeModal() {
        window.janrain.capture.ui.modal.close();
    }

    public showScreenSet(screenType: ScreensetType) {
        const screen = JanrainScreensets[screenType];
        if (!screen) {
            throw new Error(`screen ${screenType} is not defined.`);
        }

        window.janrain.capture.ui.renderScreen(screen);
    }

    public showInlineScreenSet(screenType: ScreensetType, containerSelector: string) {
        this.showScreenSet(screenType);
    }

    private async loadScript(cfg: any): Promise<void> {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.id = 'janrainAuthWidget';
            script.src = cfg.httpLoadUrl;
            script.onload = () => resolve();
            script.onerror = reject;
            const firstScript = document.getElementsByTagName('script')[0];
            firstScript.parentNode.insertBefore(script, firstScript);
        });
    }

    private async initialize() {
        return new Promise(resolve => {
            window.janrain.ready = true;
            this.loadScreenSets();
            this.modalReady.subscribe(resolve);
            window.janrainCaptureWidgetOnLoad = () => {
                Object.keys(this.janrainEventHandlers).forEach(key =>
                    window.janrain.events[key].addHandler(this.janrainEventHandlers[key].bind(this)),
                );
                window.janrain.capture.ui.start();
            };
        });
    }

    private loadScreenSets() {
        const root = document.createElement('div');
        root.setAttribute('data-bind', 'allowBindings: false');

        const panel = document.createElement('div');
        panel.setAttribute('id', 'janrainScreensets');

        root.appendChild(panel);
        document.body.appendChild(root);

        templates.renderTo('v7.Client.Janrain.Screensets', null, $(panel));
    }

    private renderScreenByUrlParams() {
        const uri = new Uri(window.location.href);
        const screen = uri.getQueryParamValue('screenToRender');

        if (screen && screen !== undefined) {
            window.janrain.capture.ui.renderScreen(screen);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private onCaptureEmailVerificationSuccess() {}

    private onCaptureLoginSuccess(result: any) {
        this.loginSuccess.next(result);
    }

    private onCaptureRegistrationSuccess(result: any) {
        this.loginSuccess.next(result);
    }

    private onCaptureAccountReactivateSuccess(result: any) {
        if (result.status === 'success') {
            window.janrain.capture.ui.renderScreen('reactivateAccountSuccess');
        }
    }

    private onCaptureSessionFound(result: any) {
        this.loginSuccess.next(result);
    }

    private onCaptureLoginFailed(result: any) {
        if (result.statusMessage === 'accountDeactivated') {
            window.janrain.capture.ui.renderScreen('accountDeactivated');
        }
    }

    private onCaptureAccountReactivateFailed(result: any) {
        if (result.status === 'error') {
            window.janrain.capture.ui.renderScreen('reactivateAccount');
        }
    }

    private onModalOpen() {
        document.body.classList.add('janrain-body');
    }

    private onModalClose() {
        this.modalClosed.next();
        document.body.classList.remove('janrain-body');
    }

    private onCaptureScreenShow(result: any) {
        if (result.screen === 'emailVerificationNotification') {
            window.janrain.capture.ui.modal.close();
        } else {
            GesturesManager.enableTouchScroll($(`#${result.screen}`));
        }
    }

    private onCaptureModalReady() {
        this.modalReady.next();
    }
}
