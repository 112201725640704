import { ILanguage } from '@pressreader/types';

interface LanguageConfigDto {
    id: string;
    original: string;
    title: string;
    slug: string;
    locale: string;
    hrefLangs: string[];
}

function mapLanguageConfigDto(dto: LanguageConfigDto): ILanguage {
    return {
        code: dto.id,
        originalTitle: dto.original,
        title: dto.title,
        slug: dto.slug,
        locale: dto.locale,
        hrefLangs: dto.hrefLangs,
    };
}

export { mapLanguageConfigDto, LanguageConfigDto };
