/* eslint-env es6 */

'use strict';

import { isObject, endsWith, find } from 'lodash';
import { utils as koUtils } from 'knockout';
import { templates } from 'deprecated/nd.templates';

/**
 *  Conventional suffix mandatory used for each components which uses this loader to fetch its template
 */
export const TEMPLATE_SUFFIX = '.ko.Component';

/**
 *  Reserved property for temptlate configuration.
 */
export const CONFIG_PROP_NAME = 'ndTemplate';

export function getFirstElementNode(templateNodes = []) {
    return find(templateNodes, ({ nodeType }) => nodeType === Node.ELEMENT_NODE);
}

/**
 * Get an array of DOM nodes parsed from nd-templates by name.
 * @param {String} templateName The name of the template to parse.
 * @return {Promise} Promise fulfilled with parsed array of DOM nodes or undefined if template was not found.
 */
export function getParsedNodes(templateName) {
    return templates.getTemplateAsync(templateName).then(jsTemplate => {
        if (!jsTemplate) {
            // Template not found
            return undefined;
        }

        const templateBody = templates.process(templateName); // Exception here indicates an error in template body and should be propagated
        return koUtils.parseHtmlFragment(templateBody);
    });
}

export function getParsedNodesSync(templateName) {
    if (!templates.isLoaded()) {
        return undefined;
    }

    const jsTemplate = templates.getTemplate(templateName);

    if (!jsTemplate) {
        // Template not found
        return undefined;
    }

    const templateBody = templates.process(templateName); // Exception here indicates an error in template body and should be propagated
    return koUtils.parseHtmlFragment(templateBody);
}

/**
 * Get first element-like (e.g. div, a, span etc.) node from nd-templates by name.
 * @param {String} templateName     Name of the template
 * @returns {Promise}   Promise fulfilled with parsed node,
 *                      or rejected with an error if template was not found or does not contain any element-like nodes (eg. conatins only text nodes).
 */
export function getElementNodeAsync(templateName) {
    return getParsedNodes(templateName).then(templateNodes => {
        if (!templateNodes || !templateNodes.length) {
            // Template is not found
            throw new Error(`Template ${templateName} was not found`);
        }

        const firstElementNode = getFirstElementNode(templateNodes);
        if (!firstElementNode) {
            throw new Error(`Template ${templateName} is invalid: no element nodes found`);
        }

        return firstElementNode;
    });
}

/**
 * Knockout custom component loader,
 * which allows to use templates served by standard 'deprecated/nd.res' service.
 *
 * Note that there is a naming convention applied to such components:
 * 1. The template configuration must be an object,
 * which contains a property specified in CONFIG_PROP_NAME constant,
 * the value of this property is to contain a template name to be loaded.
 * 2. The name of the template must end with suffix specified in TEMPLATE_SUFFIX constant,
 * otherwise this loader will pass by the component instantiating to the next registered loader.
 *
 * @example
 *
 * ko.components.register('color-picker', {
 *     viewModel: ...
 *     template: {
 *         ndTemplate: 'v7.Client.ColorPicker.ko.Component'
 *     }
 * });
 *
 */
export const ndTemplateloader = {
    loadTemplate: (componentName, templateConfig, callback) => {
        if (!isObject(templateConfig) || !endsWith(templateConfig[CONFIG_PROP_NAME], TEMPLATE_SUFFIX)) {
            callback(null);
            return;
        }

        // IMPORTANT: after migration to native Promise which is asynchronous,
        // some of rendering functionality (like contextmenu) fails to position content correctly
        // due to async nature of rendered knockout templates
        // This is why template loader was changed to try loading templates synchronously and fallback to async way

        const templateName = templateConfig[CONFIG_PROP_NAME];
        const parsedNodes = getParsedNodesSync(templateName);
        if (parsedNodes) {
            callback(parsedNodes);
        } else {
            getParsedNodes(templateName).then(
                nodes => {
                    callback(nodes);
                },
                () => {
                    // error loading template
                    // TODO: propagate error here.
                    callback(null);
                },
            );
        }
    },
};

export default ndTemplateloader;
