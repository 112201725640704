import * as $ from 'jquery';
import * as _ from 'lodash';
import * as ndStates from 'nd.states';
import { getCurrentDialogZIndex, getNextTintZIndex } from 'nd.layout.zindex';
import { DialogStatus } from 'nd.ui.dialogstatus';
import 'nd.core';
import scrollbarInfo from 'utils/scrollbar';
import ndUiUtils from 'nd.ui.utils';
import 'nd.layout';
import 'deprecated/nd.res';
import 'nd.stats';
import '@pressreader/modernizr';
import 'nd.navigationhistory';
import 'nd.widgets';
import { keyCodes } from '@pressreader/utils';

var instantiated;

function getFullScreenHeightStr() {
    // for old android set height in pixels
    return $.browser.android && !$.browser.chrome ? $.windowHeight() + 'px' : '100%';
}
function getFullScreenWidthStr() {
    // for old android set width in pixels
    return $.browser.android && !$.browser.chrome ? $.windowWidth() + 'px' : '100%';
}

var lockDataId = '__bg_lock';
function lockWindowScroll() {
    var $bodyEl = $('body');
    var lockData = $bodyEl.data(lockDataId);
    if (lockData) {
        // parent dialog has already set lock
        return false;
    }

    lockData = {
        // top: -window.scrollY + 'px',
        overflow: 'hidden',
    };

    if (!scrollbarInfo.isHidden) {
        lockData.paddingRight = scrollbarInfo.width + 'px';
    }

    $bodyEl.css(lockData).data(lockDataId, lockData);
    return true;
}

function unlockWindowScroll() {
    var $bodyEl = $('body');
    var lockData = $bodyEl.data(lockDataId);
    if (lockData) {
        $bodyEl
            .css(
                _.mapValues(lockData, function () {
                    return '';
                }),
            )
            .removeData(lockDataId);
    }
}

function createDialogPanel(id, css, name) {
    var zIndex = getCurrentDialogZIndex();
    var defStyles = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: getFullScreenWidthStr(),
        height: getFullScreenHeightStr(),
        overflow: 'hidden',
        'z-index': zIndex,
    };
    var styles = $.extend({}, defStyles, css);
    var dialogPanel = $(document.body).createChild('div').hide().css(styles).attr('id', id);
    if (name) {
        dialogPanel.attr('dialog-name', name);
    }
    dialogPanel.addClass('pop-wrapper');

    return dialogPanel;
}

function createTintPanel(tintId, dialogName) {
    var zIndex = getNextTintZIndex();
    var css = {
        'z-index': zIndex,
        width: getFullScreenWidthStr(),
        height: getFullScreenHeightStr(),
        overflow: 'hidden',
    };
    var $tint = $(document.body).createChild('div').css(css).addClass('dialog-tint').hide().attr('id', tintId);

    $.nd.gestures.enableTouchScroll($tint);

    $tint.on('wheel', function (evt) {
        evt.preventDefault();
    });

    if (dialogName) {
        $tint.attr('dialog-name', dialogName);
    }

    return $tint;
}

var dialogs = (window.__dialogs = {});

$(document).keydown(function (e) {
    _.forEachRight(dialogs, function (dialog) {
        if (dialog.visible) {
            if (e.which === keyCodes.esc) {
                var backFn = _.get(dialog, 'config.model.back');
                if (_.isFunction(backFn)) {
                    backFn.apply(_.get(dialog, 'config.model'));
                } else {
                    dialog.hide(DialogStatus.Cancel);
                }

                return false;
            }
        }
    });
});

function resizeVisibleDialogs() {
    _.each(dialogs, function (dialog) {
        if (dialog.visible) {
            dialog.resize();
        }
    });
}

$.nd.layout.resize(resizeVisibleDialogs);
$(window).bind('orientationchange', resizeVisibleDialogs);

function Dialog(parentDialog) {
    this.parentDialog = parentDialog;
    this.id = 'dialog-' + new Date().getTime();
    this.tintId = 'tint-' + new Date().getTime();
    this.visible = false;
}

Dialog.prototype = (function () {
    function getDialog(id) {
        return $('#' + id);
    }

    function getTint(id) {
        return $('#' + id);
    }

    function showTintPanel(config, tintId) {
        var $tint = getTint(tintId);
        if (!$tint.length) {
            $tint = createTintPanel(tintId, config.name);

            if (!config.preventClosingOnClickOutside) {
                var self = this;
                $tint.bind('click', function () {
                    var backFn = _.get(self, 'config.model.back');
                    if (_.isFunction(backFn)) {
                        backFn.apply(_.get(self, 'config.model'));
                    } else {
                        self.hide.apply(self, [DialogStatus.Destroy]);
                    }
                });
            }
        }

        $tint.show();
    }

    /**
     * Updates all scrollable panels - enables Scroll in Ipad
     */
    var refreshScroll = function ($el) {
        $el.find('[nd-scrollable]').each(function () {
            var $this = $(this);
            var $scroll = $this.data('scroller');
            if ($scroll && $.isFunction($scroll.refresh)) {
                $scroll.refresh();
            }
        });
    };

    function restoreDialogPanel(config, id) {
        var $dialog = getDialog(id).show();
        refreshScroll($dialog);
    }

    var _koElm;
    function cleanKo() {
        if (_koElm) {
            ko.safeCleanNode(_koElm);
            _koElm = null;
        }
    }
    function bindKo(model, elm) {
        cleanKo();
        _koElm = elm;
        ko.applyBindings(model, elm);
    }

    function showDialogPanel(config, id) {
        cleanKo();

        var $dialog = getDialog(id);
        if (!$dialog.length) {
            var css = config.css || {};
            $dialog = createDialogPanel(id, css, config.name);
            $.nd.gestures.enableTouchScroll($dialog);

            //Why do we need to hide dialog when user clicks inside it?
            //$dialog.bind('click', this.hide.bind(this));

            // somehow nd.navigationhistory waits until dialog is closed for navigation to proceed
            // can't remove this line until all dialog is fixed to be closed after the navigation action was invoked
            $.nd.navHistory.oneNavigate(this.hide.bind(this));
        }

        if (config.lockBackground) {
            this.ownWinLock = lockWindowScroll();
        }

        var panel = $dialog.css({ display: 'block', visibility: 'hidden' });
        if (config.templateName) {
            $.nd.templates.renderTo(config.templateName, config.model, panel);
        } else {
            panel.html(config.content || '');
            bindKo(config.model, panel[0]);
        }

        panel = panel.children().css({ opacity: 0, height: 'auto' });

        if (!config.defaultEventFlow) {
            $.nd.gestures.bindDefault(panel);
        }

        panel.css({
            transitionProperty: 'opacity',
            transitionTimingFunction: 'ease-out',
            transitionDuration: '300ms',
            opacity: 1,
            visibility: 'visible',
        });

        this.visible = true;
        popSetup.call(this, panel);

        dialogs[this.id] = this;
    }

    function hideTintPanel(tintId, options) {
        var $tint = getTint(tintId).hide();
        if (!options || !options.suspend) {
            $tint.remove();
        }
    }

    function hideDialogPanel(id, options) {
        var $dialog = getDialog(id).css({
            transitionProperty: '',
            transitionTimingFunction: '',
            transitionDuration: '',
        });
        $dialog.find('header.toolbar').focus();
        $dialog.hide();

        if (this.ownWinLock) {
            unlockWindowScroll();
            delete this.ownWinLock;
        }

        if (!options || !options.suspend) {
            cleanKo();
            $dialog.empty();
        }
    }

    function dispose(id, tintId) {
        cleanKo();

        var $dialog = getDialog(id);
        var $tint = getTint(tintId);

        $.nd.gestures.unbind($dialog);
        $.nd.gestures.unbind($tint);

        $dialog.remove();
        $tint.remove();

        if (!ndUiUtils.isPageVerticallyScrollable()) {
            $.nd.fixTouchScroll();
        }
        this.disposed = true;
        delete dialogs[this.id];
    }

    function popSetup(panel) {
        var popBodyHeight = 0;
        var popHeaderHeight = 0;

        if (this.config.height === '100%') {
            panel.css({
                height: getFullScreenHeightStr(),
            });
        } else {
            panel.removeClass('pop-fullheight').css({
                height: this.config.height,
                position: 'absolute',
            });
        }

        // BUGFIX: iOS7 in landscape mode $(window).height gives screensize on iOS, not innerHeight)
        var winheight = window.innerHeight ? window.innerHeight : $(window).height();

        popHeaderHeight = popHeaderHeight + (panel.find('.pop-cover').outerHeight(true) || 0);
        panel.find('.pop-body:visible').each(function () {
            // reset
            $(this).css({ height: 'auto' });
            $(this).children().css({ 'min-height': 0 });

            if (panel.outerHeight() >= winheight) {
                panel.addClass('pop-fullheight').height(winheight - parseInt(panel.css('border-top')) - parseInt(panel.css('border-bottom')));
            }

            var popHeight = panel.height();
            var toolbarHeight = 0;
            var subheaderHeight = 0;
            var footerHeight = 0;

            toolbarHeight = toolbarHeight + ($(this).parent().find('.toolbar, .pop-header').outerHeight(true) || 0);
            footerHeight = footerHeight + ($(this).parent().find('.pop-footer').outerHeight() || 0);

            $(this)
                .parent()
                .find('.pop-subheader:visible')
                .each(function () {
                    if ($(this).parent().is('.pop-body') || $(this).parent().parent().is('.pop-body')) {
                        return false;
                    } else {
                        subheaderHeight = subheaderHeight + $(this).outerHeight(true);
                    }
                });

            popBodyHeight =
                popHeight -
                popHeaderHeight -
                toolbarHeight -
                subheaderHeight -
                footerHeight -
                parseInt($(this).css('padding-top')) -
                parseInt($(this).css('padding-bottom'));
            $(this).height(popBodyHeight);
        });

        if (panel.is('.pop-center')) {
            var ww = $.windowWidth();
            var wh = $.windowHeight();
            panel.css({
                left: Math.round((ww - panel.width()) / 2),
                top: Math.round((wh - panel.height()) / 2),
            });
        } else {
            var left, top;
            if (this.config.getLeft) {
                left = this.config.getLeft(panel.width());
            }
            if (this.config.getTop) {
                top = this.config.getTop(panel.height());
            }
            if (!_.isUndefined(left) || !_.isUndefined(top)) {
                panel.css({
                    left: left || 0,
                    top: top || 0,
                });
            }
        }
        refreshScroll(panel);
        setTimeout(function () {
            ndUiUtils.toolbarSetup(panel);
        }, 0);

        $(this).trigger('onResize', {
            headerHeight: popHeaderHeight,
            bodyHeight: popBodyHeight,
            screenHeight: winheight,
        });
    }

    return {
        /**
         * Renders and shows dialog with given configuration
         *
         * @param {Object} config - dialog configuration
         * @param {String} [config.height=auto] - dialog height in px, % or 'auto' (default)
         * @param {Object} [config.css] - custom css to be applied dialog element
         * @param {String} [config.content] - custom html content of dialog
         * @param {String} [config.templateName] - name of template to be rendered as dialog content
         * @param {Boolean} [config.defaultEventFlow = false] - forces browser default event flow,
         * 														suppresses custom nd.gestures
         */
        show: function (config) {
            if (this.visible) {
                return;
            }

            if (!config) {
                throw new Error('config required');
            }

            // default configuration
            var defaults = {
                height: 'auto',
            };

            this.config = _.extend({}, defaults, config);

            if (this.parentDialog) {
                this.parentDialog.suspend();
            }

            $.nd.stats.pauseStats();

            ndStates.busy.set();
            ndStates.modal.set();
            this._(showTintPanel)(this.config, this.tintId);
            this._(showDialogPanel)(this.config, this.id);
            if (this.config.focusElement) {
                this.htmlRoot().find(this.config.focusElement).focus();
            }
            this.disposed = false;

            $(this).trigger('onshow');

            return this;
        },

        /**
         * Hides and removes Dialog from DOM
         * Fires onHide event handler
         */
        hide: function (status, data) {
            if (this.disposed) {
                return;
            }

            data = $.extend(
                {
                    status: status || DialogStatus.Unknown,
                },
                data,
            );

            $(this).trigger('onhide', data);

            $.nd.stats.resumeStats();

            this._(hideDialogPanel)(this.id);
            this._(hideTintPanel)(this.tintId);
            this.visible = false;
            ndStates.busy.reset();
            ndStates.modal.reset();
            this._(dispose)(this.id, this.tintId);

            if (data.status == DialogStatus.Destroy && this.parentDialog) {
                var parent = this.parentDialog;
                while (parent) {
                    parent._.call(parent, dispose)(parent.id, parent.tintId);
                    parent = parent.parentDialog;
                }
                return;
            }

            var restoreParent = function (st) {
                var closeStatusList = [DialogStatus.Unknown, DialogStatus.Close];
                for (var i = 0; i < closeStatusList.length; i++) {
                    if (closeStatusList[i] == st) {
                        return false;
                    }
                }
                return true;
            };
            //debugger;
            if (this.parentDialog && !this.parentDialog.disposed && restoreParent(data.status)) {
                this.parentDialog.restore(data);
            }
        },

        /**
         * Suspends current dialog - hides it, but not removes it from DOM.
         * Fires onSuspend event handler
         */
        suspend: function () {
            if (this.visible) {
                $.nd.stats.resumeStats();

                this._(hideDialogPanel)(this.id, {
                    suspend: true,
                });
                this._(hideTintPanel)(this.tintId, {
                    suspend: true,
                });
                this.visible = false;
                $(this).trigger('onSuspend');
            }
        },

        /**
         * Restores suspended dialog as-is. Should be used when used clicks "Back" button in child dialog.
         * Fires onRestore event
         */
        restore: function (data) {
            if (this.visible) {
                return;
            }

            $.nd.stats.pauseStats();
            this._(showTintPanel)({}, this.tintId);
            this._(restoreDialogPanel)(
                {
                    data: data,
                },
                this.id,
            );

            this.visible = true;
            $(this).trigger('onRestore', data);
        },

        /**
         * Restores suspended dialog and tries to continue execution.
         * Should be used when used clicks "OK" button in child dialog.
         * Fires onResume event
         */
        resume: function () {
            if (this.visible) {
                return;
            }

            $.nd.stats.pauseStats();
            this._(showTintPanel)({}, this.tintId);
            this._(restoreDialogPanel)({}, this.id);

            this.visible = true;
            $(this).trigger('onResume');
        },

        onShow: function (fn) {
            $(this).one('onshow', fn);
            if (this.visible) {
                $(this).trigger('onshow');
            }
            return this;
        },

        onHide: function (fn) {
            $(this).one('onhide', fn);
            return this;
        },

        onResize: function (fn) {
            if ($.isFunction(fn)) {
                $(this).bind('onResize', fn);
            }
            return this;
        },

        /**
         * Binds event handler for "onRestore" event.
         * @param {function} fn instance of event handler
         */
        onRestore: function (fn) {
            if ($.isFunction(fn)) {
                $(this).bind('onRestore', fn);
            }
        },

        /**
         * Binds event handler for "onResume" event.
         * @param {function} fn instance of event handler
         */
        onResume: function (fn) {
            if ($.isFunction(fn)) {
                $(this).bind('onResume', fn);
            }
        },

        /**
         * Binds event handler for "onResume" event.
         * @param {function} fn instance of event handler
         */
        onSuspend: function (fn) {
            if ($.isFunction(fn)) {
                $(this).bind('onSuspend', fn);
            }
        },

        /**
         * Resizes visible dialog.
         */
        resize: function () {
            if (!this.visible) {
                return;
            }

            var cssSize = {
                height: getFullScreenHeightStr(),
                width: getFullScreenWidthStr(),
            };

            // resize tint: size in pixels for old androids, 100% x 100% for everything else.
            getTint(this.tintId).css(cssSize);

            var dialog = getDialog(this.id);

            // resize dialog: size in pixels for old androids, 100% x 100% for everything else.
            dialog.css(cssSize);

            var panel = dialog.children();

            //resize all children
            popSetup.call(this, panel);
        },

        refreshScroll: function () {
            refreshScroll(getDialog(this.id));
        },

        /**
         * Returns html root of the dialog as jquery object
         */
        htmlRoot: function () {
            return getDialog(this.id);
        },

        focus: function (id) {
            if (!$.browser.iOS) {
                //there are several issues how focus works on IPad
                this.htmlRoot()
                    .find('input#' + id)
                    .focus();
            }
        },

        /**
         * Define private methods dedicated one
         */
        _: function (callback) {
            /**
             * instance referer
             */
            var self = this;

            /**
             * callback that will be used
             */
            return function () {
                return callback.apply(self, arguments);
            };
        },
    };
})();

$.nd.ui.dialog = {
    /**
     * Returns single instance of the dialog
     */
    getInstance: function () {
        if (!instantiated) {
            //instantiated = new Dialog();
            instantiated = this.createInstance();
        }
        return instantiated;
    },

    getParent: function () {
        return instantiated;
    },

    /**
     * Creates new dialog instance
     */
    createInstance: function (parentDialog) {
        var dialog = new Dialog(parentDialog);

        var resetStates = function () {
            ndStates.busy.reset();
            ndStates.modal.reset();
        };

        var setStates = function () {
            ndStates.busy.set();
            ndStates.modal.set();
        };

        dialog.onSuspend(resetStates);
        dialog.onRestore(setStates);
        dialog.onResume(setStates);

        return dialog;
    },
};

export default $.nd.ui.dialog;
