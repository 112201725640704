/* eslint-env es6 */

import * as ko from 'knockout';
import * as $ from 'jquery';

/**
 * Repaint custom binding.
 *
 * Please note this is a crutch intended to fix Chrome behaviour,
 * when CSS rules on an element are properly computed, but not eventually applied.
 *
 * Subsribes to value changes, and repaints bound element then.
 *
 * @example:
 * <label class="checkbox make-public" data-bind="css: { checked: isPublic }, repaint: isPublic">
 *     <input type="checkbox" data-bind="checked: isPublic"/>
 * </label>
 **/
const repaintBindingHandler = {
    update: (element, valueAccessor) => {
        // this is to "subscribe" this handler on value updates,
        // otherwise this.update will be invoked only once
        // this line should be placed exactly here, in "update" method, not in "init" one
        ko.unwrap(valueAccessor());

        // there are several methods of forcing repaint from JavaScript.
        // modification of opacity has been chosen as a simple and enough to solve the problem
        const styleProperty = 'opacity';
        const originalStyleComputedValue = window.getComputedStyle(element).getPropertyValue(styleProperty);
        const originalStyleValue = originalStyleComputedValue === undefined ? 1.0 : parseFloat(originalStyleComputedValue);

        const $element = $(element);
        $element.css(styleProperty, originalStyleValue - 0.01);
        $element.css(styleProperty, originalStyleValue);
    },
};

// binding auto registration
ko.bindingHandlers.repaint = repaintBindingHandler;
