import { createActionCreator } from '@pressreader/appstore';

import { ILegacyTemplate } from '../types';

const prefix = '@pressreader/resources/';

export interface IResourcesLoadDonePayload {
    resources: Record<string, string>;
    templates: Record<string, ILegacyTemplate>;
}

export const loadResourcesAction = createActionCreator(`${prefix}load_resources`);

export const resourcesLoadBegan = createActionCreator(`${prefix}resources_load_began`);
export const resourcesLoadDone = createActionCreator<IResourcesLoadDonePayload>(`${prefix}resources_load_done`);
export const resourcesLoadFailed = createActionCreator(`${prefix}resources_load_failed`);

export const resourcesChanged = createActionCreator(`${prefix}resources_changed`);
