import resources from 'deprecated/nd.res';

const productDescriptions = {
    monthly: resources.val('Accounting.SubscriptionInfo.MonthlySubscription', 'Monthly Subscription'),
    singleIssue: resources.val('Accounting.SubscriptionInfo.SingleIssue', 'Single Issue'),
};

const productTypes = {
    subscription: 0,
    singleIssue: 1,
    bundle: 2,
    unknown: 99,
};

const orderProductTypes = {
    subscription: 1,
    newspaper: 2,
    iRexDeliveryCharge: 3,
    bundleProduct: 4,
};

function orderProductTypeToProductType(orderProductType) {
    switch (orderProductType) {
        case orderProductTypes.subscription:
            return productTypes.subscription;
        case orderProductTypes.newspaper:
            return productTypes.singleIssue;
        case orderProductTypes.bundleProduct:
            return productTypes.bundle;
        default:
            return productTypes.unknown;
    }
}

export { productDescriptions, productTypes, orderProductTypes, orderProductTypeToProductType };
