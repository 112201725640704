import ContextMenuItemController from 'contextmenu/base.controller';
import ndConfig from '@pressreader/src/deprecated/nd.config';
import { getCurrentSubscription } from 'subscriptions/services';

class ManageAccountMenuItemController extends ContextMenuItemController {
    item() {
        return this._getItem('manageaccount', 'Menu.Account.MyAccount', 'pri pri-account');
    }

    available() {
        return Promise.all([ndConfig.loaded(), getCurrentSubscription()]).then(([, currentSubscription]) => {
            // For NIE subscriptions
            const simultaneousSessionsAllowed = currentSubscription && currentSubscription.maxSessionRequired;

            return ndConfig.get('pagesView.enableMyAccountMenu') && !simultaneousSessionsAllowed;
        });
    }

    enabled() {
        return Promise.resolve(true);
    }

    activate() {
        ndConfig.loaded().then(() => {
            this._parent.hide();
            window.location = ndConfig.get('Authentication.ExternalMyAccountPage') || `${window.basePath}accounting/account`;
        });
    }
}

export default ManageAccountMenuItemController;
