import { ScreenSizeType } from '@pressreader/ui-types';
import { device, getScreenSize } from '@pressreader/utils';

const screenSizeToType = [
    [1200, ScreenSizeType.XLarge],
    [992, ScreenSizeType.Large],
    [768, ScreenSizeType.Medium],
    [480, ScreenSizeType.Small],
    [0, ScreenSizeType.XSmall],
] as const;

export function getScreenSizeType() {
    const size = getScreenSize();

    let width = size.width;
    if (device.mobile) {
        // handle landscape orientation for mobile devices
        width = Math.min(size.width, size.height);
    }

    for (const item of screenSizeToType) {
        if (width >= item[0]) {
            return item[1];
        }
    }

    return ScreenSizeType.XSmall;
}
