import { createActionCreator, IAction } from '@pressreader/appstore';
import { AnyPopup } from '@pressreader/popups-types';

import { MODULE_NAME } from '../constants';

const prefix = MODULE_NAME + '/';

interface IPopupOpenedPayload<TPopup extends AnyPopup> {
    popup: TPopup;
}

interface IPopupChangedPayload {
    popup: AnyPopup;
}

interface IClosePopupPayload<TResult> {
    id: string;

    isBack?: boolean;
    isCancelled?: boolean;

    result?: TResult;
}

interface IPopupClosedPayload<TResult> {
    id: string;

    isCancelled?: boolean;

    result?: TResult;
}

interface popupOpened<TPopup extends AnyPopup> {
    (payload: IPopupOpenedPayload<TPopup>): IAction<IPopupOpenedPayload<TPopup>>;
    toString(): string;
}

const popupOpened = createActionCreator(`${prefix}popup_opened`) as {
    <TPopup extends AnyPopup>(payload: IPopupOpenedPayload<TPopup>): IAction<IPopupOpenedPayload<TPopup>>;
    toString(): string;
};

const popupChanged = createActionCreator<IPopupChangedPayload>(`${prefix}popup_options_changed`);

interface closePopupAction<TResult> {
    (payload: IClosePopupPayload<TResult>): IAction<IClosePopupPayload<TResult>>;
    toString(): string;
}

const closePopupAction = createActionCreator(`${prefix}close_popup`) as {
    <TResult>(payload: IClosePopupPayload<TResult>): IAction<IClosePopupPayload<TResult>>;
    toString(): string;
};

interface popupClosed<TResult> {
    (payload: IPopupClosedPayload<TResult>): IAction<IPopupClosedPayload<TResult>>;
    toString(): string;
}

const popupClosed = createActionCreator(`${prefix}popup_closed`) as {
    <TResult>(payload: IPopupClosedPayload<TResult>): IAction<IPopupClosedPayload<TResult>>;
    toString(): string;
};

export { popupOpened, popupChanged, closePopupAction, popupClosed };
