import { storeManager } from '@pressreader/appstore';

import { MODULE_NAME } from '../constants';

import { reducer } from './reducer';

storeManager.addFeature(MODULE_NAME, reducer);

export * from './actions';
export * from './selectors';
export * from './utils';
