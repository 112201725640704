import { difference, find } from 'lodash';

enum GdprConsentType {
    Cookies = 1,
    Email = 2,
    TermsOfUse = 3,
    TermsOfUseAndCookies = 4,
    Ccpa = 5, // California Consumer Privacy Act
}

interface IGdprConsentContext {
    consentType: GdprConsentType;
    isGranted: boolean;
}

// Consents that are recorded as "accepted" in a database or cookies.
const simpleConsents = [GdprConsentType.Cookies, GdprConsentType.TermsOfUse, GdprConsentType.Ccpa];

// Consents that are combinations of simple consents. Accepting a compound consent implies accepting all the simple consents in succeededConsentTypes.
// Compound consents are not recorded anywhere. Instead, composing simple consents are recorded.
// To show the message of a compound consent, it is necessary to have requiredConsentTypes not accepted.
// The order is significant.
const compoundConsents: Array<{
    mainConsentType: GdprConsentType;
    requiredConsentTypes: GdprConsentType[];
    succeededConsentTypes: GdprConsentType[];
}> = [
    {
        mainConsentType: GdprConsentType.TermsOfUseAndCookies,
        requiredConsentTypes: [GdprConsentType.Cookies, GdprConsentType.TermsOfUse],
        succeededConsentTypes: [GdprConsentType.Cookies, GdprConsentType.TermsOfUse],
    },
];

function getRequiredConsents(acceptedConsents: GdprConsentType[]): GdprConsentType[] {
    const notAcceptedConsents = difference(simpleConsents, acceptedConsents);
    const requiredConsents = tryComposeCompoundConsents(notAcceptedConsents);
    return requiredConsents;
}

// Replaces simple consents with compound consents.
function tryComposeCompoundConsents(consents: GdprConsentType[]): GdprConsentType[] {
    let result = consents;
    compoundConsents.forEach(compoundConsent => {
        if (compoundConsent.requiredConsentTypes.every(x => result.indexOf(x) >= 0)) {
            result = difference(result, compoundConsent.succeededConsentTypes);
            result.push(compoundConsent.mainConsentType);
        }
    });
    return result;
}

// Replaces compound consent with simple consents.
function tryDecomposeConsent(consentType: GdprConsentType): GdprConsentType[] {
    const compoundConsent = find(compoundConsents, x => x.mainConsentType === consentType);
    if (compoundConsent) {
        return compoundConsent.succeededConsentTypes;
    } else {
        return [consentType];
    }
}

export { GdprConsentType, IGdprConsentContext, getRequiredConsents, tryDecomposeConsent };
