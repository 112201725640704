import * as ko from 'knockout';

/*
 * Usage:
 * <img data-bind="attrAsync: {src: getThumbnailUrl('9GSW', 200)}" class="thumbnail">
 *
 * The function bound (getThumbnailUrl in the example) should return a Promise.
 */
ko.bindingHandlers.attrAsync = {
    init: (element, valueAccessor) => {
        const params = valueAccessor();

        Object.keys(params).forEach(e => {
            if (params[e] && typeof params[e].then === 'function') {
                params[e].then(val => {
                    element[e] = val;
                });
            } else {
                throw new Error(`The function bound to '${e}' does not return a Promise. Use 'attr' binding instead.`);
            }
        });
    },
};
