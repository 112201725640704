/* eslint-disable prefer-rest-params */
/* eslint-disable no-console */
import config from '@pressreader/src/deprecated/nd.config';

import { EcommerceTypes } from 'analytics/ecommerce/models/models';
import { IEventInfo, IPageViewInfo, ITracker, ITransactionInfo } from 'analytics/models/models';
import { getGtagType } from 'analytics/trackers/helpers/googleGtagTypes';
import { SetupUserId } from 'analytics/trackers/helpers/UserId';

let _debug = false;

function gtag(...args) {
    if (_debug) {
        console.log('tagmanager - gtag ', arguments);
    }
    dataLayerTagManager.push(arguments);
}

function gtagObj(object: any) {
    if (_debug) {
        console.log('tagmanager - gtagObj ', object);
    }

    // Cleanup default attributes on dataLayer dictionary...
    object = {
        ...{
            Action: null,
            Category: null,
            Context: null,
            Label: null,
            PageTitle: null,
            PageURL: null,
            //UserId: null, /* NEVER clean this parameter */
            Value: null,
            Var: null,
        },
        ...object,
    };

    dataLayerTagManager.push(object);
}

// https://developers.google.com/analytics/devguides/collection/gtagjs
function initTagManager(w, d, s, l, i, c) {
    i.split(',').forEach(element => {
        w[l] = w[l] || c ? [c] : [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + element + dl;
        f.parentNode.insertBefore(j, f);

        const n = d.createElement('noscript');
        d.body.insertBefore(n, d.body.firstElementChild);
        const iframe = d.createElement('iframe');
        iframe.height = '0';
        iframe.width = '0';
        iframe.style.display = 'none';
        iframe.style.visibility = 'hidden';
        iframe.src = 'https://www.googletagmanager.com/ns.html?id=' + element + dl;
        n.appendChild(iframe);
    });

    gtag('policy', 'all', function (container: any, policy: any, data: any) {
        // Only set policy for 1 specific container.
        // This enables other containers loaded on the page to
        // operate without restrictions on permissions.
        if (container !== i) return true;

        // Default policy...
        const cfg = config.get('googletagmanager');
        const result = cfg.AllowPolicy?.indexOf(policy) >= 0;
        if (!result) {
            const msg = `This Google Tag Manager implementation is requesting permissions, but the XML backend configuration is missing, make sure you have the AllowPolicy, like:

<addgroup name="GoogleTagManager">
    <add name="Enabled" value="true" />
    <add name="ContainerID" value="GTM-XXXX" />
    <add name="AllowPolicy" value="permission1,permission2" />
</addgroup>

https://developers.google.com/tag-manager/templates/permissions?hl=en
            `;
            console.error(msg);
            console.warn(`Checking policy for "${policy} = ${result}", data=${JSON.stringify(data)}`);
        }
        return result;
    });
}

declare let window: any;
declare let dataLayerTagManager: any;

class TagManager implements ITracker {
    private _debug: boolean;
    private _ecommerceEnabled = false;
    private _enabled = false;
    private _eventsEnabled = false;
    private _loaded: Promise<boolean>;
    private _triggers: any = {};
    private _variables: any = {};
    private _ContainerID: string;

    constructor() {
        this._loaded = (async () => {
            await config.loaded();

            const cfg = config.get('googletagmanager');

            this._debug = _debug = !!cfg.Debug;
            if (this._debug) {
                console.log('tagmanager - cfg ', cfg);
            }

            if (!cfg || !cfg.Enabled) {
                return false;
            }
            this._enabled = true;
            this._eventsEnabled = cfg.Events && cfg.Events.Enabled;

            this._ContainerID = cfg.ContainerID;

            if (!window.dataLayerTagManager) {
                initTagManager(window, document, 'script', 'dataLayerTagManager', cfg.ContainerID, cfg.CustomVariables);
            }

            // Secure _ga, _gid, _gat_gtag_ClientId cookies
            gtag('set', {
                cookie_flags: cfg.CookieSameSite || 'SameSite=None;Secure',
            });

            // https://developers.google.com/analytics/devguides/collection/ga4/views?client_type=gtag#manual_pageviews
            cfg.ContainerID.split(',').forEach((element: string) => {
                gtag('config', element, {
                    send_page_view: false,
                });
            });

            if (cfg.Ecommerce && cfg.Ecommerce.Enabled) {
                this._ecommerceEnabled = true;
            }

            this._triggers = cfg.Triggers;
            this._variables = cfg.Variables;

            if (cfg.UseUserId) {
                SetupUserId((user: string) => {
                    gtagObj({
                        UserId: user,
                    });
                });
            }

            return true;
        })();
    }

    toString() {
        return `Google TagManager (${this._ContainerID})`;
    }

    loaded() {
        return this._loaded;
    }

    enabled() {
        return this._enabled;
    }

    pageView(info: IPageViewInfo = {}) {
        if (!this._enabled) {
            if (this._debug) {
                console.log('NOT ENABLED pageView', info);
            }
            return;
        }

        const fields: any = {
            event: this._triggers.Pageview,
        };
        fields[this._variables.PageTitle] = info.title;
        fields[this._variables.PageURL] = info.page;
        if (info.context) {
            fields[this._variables.Context] = info.context;
        }

        gtagObj(fields);
    }

    event(info: IEventInfo) {
        if (!this._enabled || !this._eventsEnabled) {
            if (this._debug) {
                console.log('NOT ENABLED event', info);
            }
            return;
        }

        const fields: any = {
            event: this._triggers.Event,
        };

        if (info.category) {
            fields[this._variables.Category] = info.category;
        }

        if (info.action) {
            fields[this._variables.Action] = info.action;
        }

        if (info.label) {
            fields[this._variables.Label] = info.label;
        }

        if (info.value) {
            fields[this._variables.Value] = info.value;
        }

        if (info.context) {
            fields[this._variables.Context] = info.context;
        }

        gtagObj(fields);
    }

    // https://developers.google.com/tag-manager/enhanced-ecommerce
    transaction(transaction: ITransactionInfo) {
        if (!this._enabled || !this._ecommerceEnabled || !transaction) {
            if (this._debug) {
                console.log('NOT ENABLED transaction', transaction);
            }
            return;
        }

        const fields: any = {
            // E.g. "Transaction => begin_checkout"
            event: `${this._triggers.Transaction} => ${getGtagType(transaction.type)}`,
        };
        fields.ecommerce = {};

        let products: any[];
        if (transaction.data.items) {
            products = transaction.data.items.map(m => {
                return {
                    id: m.id,
                    name: m.name,
                    brand: m.brand,
                    category: m.category,
                    variant: m.variant,
                    subtotal: m.subTotal,
                    discount: m.discount,
                    price: m.total,
                    quantity: m.quantity,
                    coupon: m.coupon,
                };
            });
        }

        switch (transaction.type) {
            case EcommerceTypes.BeginCheckout:
                fields.ecommerce.currencyCode = transaction.data.currency;
                fields.ecommerce.checkout = {
                    actionField: {
                        step: 1,
                    },
                    products: products,
                };
                break;

            case EcommerceTypes.SelectContent:
                if (transaction.data.promotions) {
                    fields.ecommerce.promoClick = {
                        promotions: transaction.data.promotions.map(m => {
                            return {
                                id: m.id,
                                name: m.name,
                            };
                        }),
                    };
                } else {
                    throw new Error('select_content type not supported');
                }
                break;

            case EcommerceTypes.CheckoutProgress:
                fields.ecommerce.currencyCode = transaction.data.currency;
                fields.ecommerce.checkout = {
                    actionField: {
                        step: transaction.data.checkout_step,
                        option: transaction.data.checkout_option,
                        value: transaction.data.value,
                    },
                    products: products,
                };
                break;

            case EcommerceTypes.AddToCart:
                fields.ecommerce.currencyCode = transaction.data.currency;
                fields.ecommerce.add = {
                    products: products,
                };
                break;

            case EcommerceTypes.RemoveFromCart:
                fields.ecommerce.currencyCode = transaction.data.currency;
                fields.ecommerce.remove = {
                    products: products,
                };
                break;

            case EcommerceTypes.AddPaymentInfo:
                fields.ecommerce.currencyCode = transaction.data.currency;
                fields.ecommerce.checkout = {
                    actionField: {
                        step: 3,
                        option: transaction.data.payment_type,
                    },
                    products: products,
                };
                break;

            case EcommerceTypes.SetCheckoutOption:
                fields.ecommerce.checkout = {
                    actionField: {
                        step: transaction.data.checkout_step,
                        option: transaction.data.checkout_option,
                    },
                    products: products,
                };
                break;

            case EcommerceTypes.Purchase:
                fields.ecommerce.currencyCode = transaction.data.currency;
                fields.ecommerce.purchase = {
                    actionField: {
                        id: transaction.data.transactionId, // Transaction ID. Required for purchases and refunds.
                        affiliation: transaction.data.affiliation,
                        revenue: transaction.data.value, // Total transaction value (incl. tax and shipping)
                        tax: transaction.data.tax,
                        shipping: transaction.data.shipping,
                    },
                    products: products,
                };
                break;

            default:
                throw new Error(`Not Implemented: ${transaction.type}`);
        }

        gtagObj(fields);
    }

    // https://developers.google.com/analytics/devguides/collection/gtagjs/user-timings
    timing(category: string, variable: string, timeSpent: string, label: string) {
        if (!this._enabled) {
            if (this._debug) {
                console.log('NOT ENABLED timing', { category, variable, timeSpent, label });
            }
            return;
        }

        const fields: any = {
            event: this._triggers.Timing, // Timing
        };

        fields[this._variables.Var] = variable;
        fields[this._variables.Category] = category;
        fields[this._variables.Value] = timeSpent;
        fields[this._variables.Label] = label;

        gtagObj(fields);
    }

    exception(description: string, fatal: boolean): void {
        gtag('event', 'exception', {
            description: description,
            fatal: fatal,
        });
    }

    customSourceTracking(source: string, medium: string): void {
        gtag('config', this._ContainerID, {
            campaign_source: source,
            campaign_medium: medium,
        });
    }
}

const tagManager: ITracker = new TagManager();

export { tagManager };
