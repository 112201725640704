import { map, find } from 'lodash';
import OpinionViewModel from 'opinions/opinion.viewmodel';
import { UserProfile } from 'nd.data.userprofile';
import FollowersInfo from 'nd.data.userprofile.followersinfo';
import ndConfig from 'deprecated/nd.config';
import * as ko from 'knockout';

class UserProfileFull extends UserProfile {
    commentsTotal: number;
    opinionsTotal: number;
    collectionsTotal: number;
    trendsWhereUserIsLeaderTotal: number;
    followers: FollowersInfo;
    opinions: OpinionViewModel[];
    trendsWhereUserIsLeader: TrendViewModel[];
    collections: any[];

    constructor(data) {
        super(data.socialInfo);

        this.commentsTotal = data.commentsTotal || 0;
        this.opinionsTotal = data.opinionsTotal || 0;
        this.collectionsTotal = data.collectionsTotal || 0;
        this.trendsWhereUserIsLeaderTotal = data.trendsWhereUserIsLeaderTotal || 0;

        this.followers = new FollowersInfo({
            IsFollowing: data.userFollowers.isFollowing,
            FollowersTotal: data.userFollowers.followersTotal,
            FollowsTotal: data.userFollowers.followsTotal,
            Followers: data.userFollowers.followers,
            Follows: data.userFollowers.follows,
        });

        const opinionsPage = data.opinions || {};
        this.opinions = map(opinionsPage.items || [], (opinion: any) => {
            return new OpinionViewModel({
                opinionId: opinion.opinionId.toString(),
                webName: opinion.webName,
                text: opinion.text,
                numberOfFollowers: opinion.numberOfFollowers,
                followedByCurrentUser: opinion.followedByCurrentUser,
            });
        });

        this.trendsWhereUserIsLeader = map(data.trendsWhereUserIsLeader || [], trend => {
            return new TrendViewModel(trend);
        });

        this.collections = map(data.collections || [], (collection: any) => {
            return {
                id: collection.Id,
                customId: collection.CustomId,
                name: collection.Name,
                count: collection.Count,
            };
        });

        this.collections = this.collections.concat(mapSharingTypes(data.sharingTypes));

        function mapSharingTypes(sharingTypes: { SharingType: unknown; Count: number }[]) {
            const bmConfig: { id: unknown; title: unknown }[] = ndConfig.get('ui.FavoritesMenu.Bookmarks');
            return sharingTypes.map(item => {
                const sharingTypeCfg = find(bmConfig, cfg => {
                    return item.SharingType === cfg.id;
                });

                return {
                    name: sharingTypeCfg?.title,
                    sharingType: item.SharingType,
                    count: item.Count,
                };
            });
        }
    }

    static toPageSource(data): any {
        return {
            skip: data.Skip,
            take: data.Take,
            total: data.Total,
            otherCount: data.OtherCount,
            items: map(data.Items, (item: any) => new UserProfile(item)),
        };
    }
}

class TrendViewModel {
    trendId: number;
    text: string;
    relatedWeight: KnockoutObservable<number>;

    constructor(dataTrend: any) {
        this.trendId = dataTrend.id;
        this.text = dataTrend.text;
        this.relatedWeight = ko.observable(0);
    }
}

export { UserProfileFull, TrendViewModel };
