import { storeManager } from '@pressreader/appstore';

import { CONFIG_MODULE_NAME } from '../constants';

import { reducer } from './reducer';
import { saga } from './sagas';

storeManager.addFeature(CONFIG_MODULE_NAME, reducer, saga);

export {
    loadConfigAction,
    configChanged,
    configLoadBegan,
    configLoadDone,
    configLoadFailed,
    forceConfigLoadDoneAction,
    extendConfigAction,
} from './actions';
export { selectGetConfigValue, selectConfig, selectStatus, selectConfigValue } from './selectors';
export * from './utils';
