import 'web/contentservice/handlers/init.common'; // import common handlers

import { registerErrorHandler } from '@pressreader/content-api';

import { issuePurchaseRequired } from 'web/contentservice/handlers/issue.purchase';
import { authenticationRequired } from 'web/contentservice/handlers/authenticationRequired';
import { actionProhibitedForAccount } from 'web/contentservice/handlers/actionProhibitedForAccount';

registerErrorHandler('IssueBuyingRequired', issuePurchaseRequired, true);
registerErrorHandler('SubscriptionRequired', issuePurchaseRequired, true);
registerErrorHandler('SubscriptionNotValid', issuePurchaseRequired, true);

registerErrorHandler('AuthenticationRequired', authenticationRequired, true);
registerErrorHandler('ActionProhibitedForAccount', actionProhibitedForAccount, true);
