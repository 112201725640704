export const staticResources = {
    'Important.Title.aa': 'Important ({0})',
    'Important.Title.ab': 'Important ({0})',
    'Important.Title.ae': 'Important ({0})',
    'Important.Title.af': 'Important ({0})',
    'Important.Title.ak': 'Important ({0})',
    'Important.Title.am': 'Important ({0})',
    'Important.Title.an': 'Important ({0})',
    'Important.Title.ar': 'Important ({0})',
    'Important.Title.as': 'Important ({0})',
    'Important.Title.av': 'Important ({0})',
    'Important.Title.ay': 'Important ({0})',
    'Important.Title.az': 'Important ({0})',
    'Important.Title.ba': 'Important ({0})',
    'Important.Title.be': 'Important ({0})',
    'Important.Title.bg': 'Important ({0})',
    'Important.Title.bh': 'Important ({0})',
    'Important.Title.bi': 'Important ({0})',
    'Important.Title.bm': 'Important ({0})',
    'Important.Title.bn': 'Important ({0})',
    'Important.Title.bo': 'Important ({0})',
    'Important.Title.br': 'Important ({0})',
    'Important.Title.bs': 'Important ({0})',
    'Important.Title.ca': 'Important ({0})',
    'Important.Title.ce': 'Important ({0})',
    'Important.Title.ch': 'Important ({0})',
    'Important.Title.co': 'Important ({0})',
    'Important.Title.cr': 'Important ({0})',
    'Important.Title.cs': 'Important ({0})',
    'Important.Title.cu': 'Important ({0})',
    'Important.Title.cv': 'Important ({0})',
    'Important.Title.cy': 'Important ({0})',
    'Important.Title.da': 'Important ({0})',
    'Important.Title.de': 'Important ({0})',
    'Important.Title.dv': 'Important ({0})',
    'Important.Title.dz': 'Important ({0})',
    'Important.Title.ee': 'Important ({0})',
    'Important.Title.el': 'Important ({0})',
    'Important.Title.en': 'Important ({0})',
    'Important.Title.eo': 'Important ({0})',
    'Important.Title.es': 'Important ({0})',
    'Important.Title.et': 'Important ({0})',
    'Important.Title.eu': 'Important ({0})',
    'Important.Title.fa': 'Important ({0})',
    'Important.Title.ff': 'Important ({0})',
    'Important.Title.fi': 'Important ({0})',
    'Important.Title.fj': 'Important ({0})',
    'Important.Title.fo': 'Important ({0})',
    'Important.Title.fr': 'Important ({0})',
    'Important.Title.fy': 'Important ({0})',
    'Important.Title.ga': 'Important ({0})',
    'Important.Title.gd': 'Important ({0})',
    'Important.Title.gl': 'Important ({0})',
    'Important.Title.gn': 'Important ({0})',
    'Important.Title.gu': 'Important ({0})',
    'Important.Title.gv': 'Important ({0})',
    'Important.Title.ha': 'Important ({0})',
    'Important.Title.he': 'Important ({0})',
    'Important.Title.hi': 'Important ({0})',
    'Important.Title.ho': 'Important ({0})',
    'Important.Title.hr': 'Important ({0})',
    'Important.Title.ht': 'Important ({0})',
    'Important.Title.hu': 'Important ({0})',
    'Important.Title.hy': 'Important ({0})',
    'Important.Title.hz': 'Important ({0})',
    'Important.Title.ia': 'Important ({0})',
    'Important.Title.id': 'Important ({0})',
    'Important.Title.ie': 'Important ({0})',
    'Important.Title.ig': 'Important ({0})',
    'Important.Title.ii': 'Important ({0})',
    'Important.Title.ik': 'Important ({0})',
    'Important.Title.io': 'Important ({0})',
    'Important.Title.is': 'Important ({0})',
    'Important.Title.it': 'Important ({0})',
    'Important.Title.iu': 'Important ({0})',
    'Important.Title.ja': 'Important ({0})',
    'Important.Title.jv': 'Important ({0})',
    'Important.Title.jw': 'Important ({0})',
    'Important.Title.ka': 'Important ({0})',
    'Important.Title.kg': 'Important ({0})',
    'Important.Title.ki': 'Important ({0})',
    'Important.Title.kj': 'Important ({0})',
    'Important.Title.kk': 'Important ({0})',
    'Important.Title.kl': 'Important ({0})',
    'Important.Title.km': 'Important ({0})',
    'Important.Title.kn': 'Important ({0})',
    'Important.Title.ko': 'Important ({0})',
    'Important.Title.kr': 'Important ({0})',
    'Important.Title.ks': 'Important ({0})',
    'Important.Title.ku': 'Important ({0})',
    'Important.Title.kv': 'Important ({0})',
    'Important.Title.kw': 'Important ({0})',
    'Important.Title.ky': 'Important ({0})',
    'Important.Title.la': 'Important ({0})',
    'Important.Title.lb': 'Important ({0})',
    'Important.Title.lg': 'Important ({0})',
    'Important.Title.li': 'Important ({0})',
    'Important.Title.ln': 'Important ({0})',
    'Important.Title.lo': 'Important ({0})',
    'Important.Title.lt': 'Important ({0})',
    'Important.Title.lu': 'Important ({0})',
    'Important.Title.lv': 'Important ({0})',
    'Important.Title.mg': 'Important ({0})',
    'Important.Title.mh': 'Important ({0})',
    'Important.Title.mi': 'Important ({0})',
    'Important.Title.mk': 'Important ({0})',
    'Important.Title.ml': 'Important ({0})',
    'Important.Title.mn': 'Important ({0})',
    'Important.Title.mo': 'Important ({0})',
    'Important.Title.mr': 'Important ({0})',
    'Important.Title.ms': 'Important ({0})',
    'Important.Title.mt': 'Important ({0})',
    'Important.Title.my': 'Important ({0})',
    'Important.Title.na': 'Important ({0})',
    'Important.Title.nb': 'Important ({0})',
    'Important.Title.nd': 'Important ({0})',
    'Important.Title.ne': 'Important ({0})',
    'Important.Title.ng': 'Important ({0})',
    'Important.Title.nl': 'Important ({0})',
    'Important.Title.nn': 'Important ({0})',
    'Important.Title.no': 'Important ({0})',
    'Important.Title.nr': 'Important ({0})',
    'Important.Title.nv': 'Important ({0})',
    'Important.Title.ny': 'Important ({0})',
    'Important.Title.oc': 'Important ({0})',
    'Important.Title.oj': 'Important ({0})',
    'Important.Title.om': 'Important ({0})',
    'Important.Title.or': 'Important ({0})',
    'Important.Title.os': 'Important ({0})',
    'Important.Title.pa': 'Important ({0})',
    'Important.Title.pi': 'Important ({0})',
    'Important.Title.pl': 'Important ({0})',
    'Important.Title.ps': 'Important ({0})',
    'Important.Title.pt': 'Important ({0})',
    'Important.Title.qu': 'Important ({0})',
    'Important.Title.rm': 'Important ({0})',
    'Important.Title.rn': 'Important ({0})',
    'Important.Title.ro': 'Important ({0})',
    'Important.Title.ru': 'Important ({0})',
    'Important.Title.rw': 'Important ({0})',
    'Important.Title.ry': 'Important ({0})',
    'Important.Title.sa': 'Important ({0})',
    'Important.Title.sc': 'Important ({0})',
    'Important.Title.sd': 'Important ({0})',
    'Important.Title.se': 'Important ({0})',
    'Important.Title.sg': 'Important ({0})',
    'Important.Title.sh': 'Important ({0})',
    'Important.Title.si': 'Important ({0})',
    'Important.Title.sk': 'Important ({0})',
    'Important.Title.sl': 'Important ({0})',
    'Important.Title.sm': 'Important ({0})',
    'Important.Title.sn': 'Important ({0})',
    'Important.Title.so': 'Important ({0})',
    'Important.Title.sq': 'Important ({0})',
    'Important.Title.sr': 'Important ({0})',
    'Important.Title.ss': 'Important ({0})',
    'Important.Title.st': 'Important ({0})',
    'Important.Title.su': 'Important ({0})',
    'Important.Title.sv': 'Important ({0})',
    'Important.Title.sw': 'Important ({0})',
    'Important.Title.ta': 'Important ({0})',
    'Important.Title.te': 'Important ({0})',
    'Important.Title.tg': 'Important ({0})',
    'Important.Title.th': 'Important ({0})',
    'Important.Title.ti': 'Important ({0})',
    'Important.Title.tk': 'Important ({0})',
    'Important.Title.tl': 'Important ({0})',
    'Important.Title.tn': 'Important ({0})',
    'Important.Title.to': 'Important ({0})',
    'Important.Title.tr': 'Important ({0})',
    'Important.Title.ts': 'Important ({0})',
    'Important.Title.tt': 'Important ({0})',
    'Important.Title.tw': 'Important ({0})',
    'Important.Title.ty': 'Important ({0})',
    'Important.Title.ua': 'Important ({0})',
    'Important.Title.ug': 'Important ({0})',
    'Important.Title.uk': 'Important ({0})',
    'Important.Title.ur': 'Important ({0})',
    'Important.Title.uz': 'Important ({0})',
    'Important.Title.ve': 'Important ({0})',
    'Important.Title.vi': 'Important ({0})',
    'Important.Title.vo': 'Important ({0})',
    'Important.Title.wa': 'Important ({0})',
    'Important.Title.wo': 'Important ({0})',
    'Important.Title.xh': 'Important ({0})',
    'Important.Title.yi': 'Important ({0})',
    'Important.Title.yo': 'Important ({0})',
    'Important.Title.za': 'Important ({0})',
    'Important.Title.zh': 'Important ({0})',
    'Important.Title.zu': 'Important ({0})',

    'Countries.af-na': 'Namibia',
    'Countries.af-za': 'South Africa',
    'Countries.ar-_i': 'International',
    'Countries.ar-ae': 'UAE',
    'Countries.ar-au': 'Australia',
    'Countries.ar-bh': 'Bahrain',
    'Countries.ar-eg': 'Egypt',
    'Countries.ar-gb': 'International',
    'Countries.ar-in': 'India',
    'Countries.ar-jo': 'Jordan',
    'Countries.ar-kw': 'Kuwait',
    'Countries.ar-lb': 'Lebanon',
    'Countries.ar-om': 'Oman',
    'Countries.ar-ps': 'Palestine',
    'Countries.ar-qa': 'Qatar',
    'Countries.ar-ru': 'Russia',
    'Countries.ar-sa': 'Saudi Arabia',
    'Countries.ar-sd': 'Sudan',
    'Countries.be-by': 'Belarus',
    'Countries.bg-bg': 'Bulgaria',
    'Countries.bg-ru': 'Bulgaria',
    'Countries.bs-ba': 'Bosnia and Herzegovina',
    'Countries.ca-es': 'Spain',
    'Countries.cs-cz': 'Czech Republic',
    'Countries.da-dk': 'Denmark',
    'Countries.de-at': 'Austria',
    'Countries.de-ch': 'Switzerland',
    'Countries.de-de': 'Deutschland',
    'Countries.de-es': 'Spain',
    'Countries.de-li': 'Liechtenstein',
    'Countries.de-lu': 'Luxembourg',
    'Countries.de-ru': 'Germany',
    'Countries.de-th': 'Thailand',
    'Countries.de-us': 'USA',
    'Countries.el-cy': 'Cyprus',
    'Countries.el-gr': 'Ελλάδα',
    'Countries.el-ru': 'Greece',
    'Countries.en-_i': 'Austria',
    'Countries.en-ae': 'UAE',
    'Countries.en-at': 'Austria',
    'Countries.en-au': 'Australia',
    'Countries.en-az': 'Azerbaijan',
    'Countries.en-bb': 'Barbados',
    'Countries.en-be': 'Belgium',
    'Countries.en-bg': 'Austria',
    'Countries.en-bh': 'Bahrain',
    'Countries.en-br': 'Brazil',
    'Countries.en-by': 'Belarus',
    'Countries.en-ca': 'Canada',
    'Countries.en-ch': 'Russia',
    'Countries.en-cn': 'China',
    'Countries.en-cy': 'Cyprus',
    'Countries.en-cz': 'Austria',
    'Countries.en-de': 'Australia',
    'Countries.en-dk': 'Austria',
    'Countries.en-eg': 'Egypt',
    'Countries.en-es': 'Spain',
    'Countries.en-fi': 'Austria',
    'Countries.en-fr': 'Australia',
    'Countries.en-gb': 'UK',
    'Countries.en-gr': 'Austria',
    'Countries.en-gy': 'Guyana',
    'Countries.en-hk': 'Australia',
    'Countries.en-hr': 'Croatia',
    'Countries.en-hu': 'Austria',
    'Countries.en-id': 'Austria',
    'Countries.en-ie': 'Ireland',
    'Countries.en-il': 'Israel',
    'Countries.en-in': 'India',
    'Countries.en-iq': 'Iraq',
    'Countries.en-is': 'Austria',
    'Countries.en-it': 'Australia',
    'Countries.en-jm': 'Jamaica',
    'Countries.en-jp': 'Austria',
    'Countries.en-ke': 'Kenya',
    'Countries.en-kh': 'Cambodia',
    'Countries.en-kr': 'Australia',
    'Countries.en-kw': 'Kuwait',
    'Countries.en-ky': 'Cayman Islands',
    'Countries.en-kz': 'Kazakhstan',
    'Countries.en-lb': 'Lebanon',
    'Countries.en-li': 'Austria',
    'Countries.en-lk': 'Sri Lanka',
    'Countries.en-lu': 'Austria',
    'Countries.en-me': 'Austria',
    'Countries.en-mk': 'Macedonia',
    'Countries.en-mt': 'Australia',
    'Countries.en-mu': 'Mauritius',
    'Countries.en-my': 'Malaysia',
    'Countries.en-ng': 'Nigeria',
    'Countries.en-nl': 'Netherlands',
    'Countries.en-no': 'Austria',
    'Countries.en-np': 'Nepal',
    'Countries.en-nz': 'New Zealand',
    'Countries.en-om': 'Oman',
    'Countries.en-ph': 'Philippines',
    'Countries.en-pk': 'Pakistan',
    'Countries.en-pl': 'Austria',
    'Countries.en-pt': 'Austria',
    'Countries.en-qa': 'Austria',
    'Countries.en-ro': 'Austria',
    'Countries.en-rs': 'Austria',
    'Countries.en-ru': 'Russia',
    'Countries.en-sa': 'Saudi Arabia',
    'Countries.en-se': 'Austria',
    'Countries.en-sg': 'Austria',
    'Countries.en-sk': 'Austria',
    'Countries.en-th': 'Thailand',
    'Countries.en-tr': 'Turkey',
    'Countries.en-tw': 'Taiwan',
    'Countries.en-ua': 'Austria',
    'Countries.en-ug': 'Uganda',
    'Countries.en-us': 'USA',
    'Countries.en-vi': 'USA',
    'Countries.en-vn': 'Viet Nam',
    'Countries.en-za': 'South Africa',
    'Countries.en-zm': 'Zambia',
    'Countries.en-zw': 'UK',
    'Countries.es-ar': 'Argentina',
    'Countries.es-cl': 'Chile',
    'Countries.es-co': 'Colombia',
    'Countries.es-cr': 'Costa Rica',
    'Countries.es-do': 'Dominican Republic',
    'Countries.es-ec': 'Ecuador',
    'Countries.es-es': 'España',
    'Countries.es-gt': 'Guatemala',
    'Countries.es-mx': 'Mexico',
    'Countries.es-pa': 'Panama',
    'Countries.es-pr': 'Puerto Rico',
    'Countries.es-py': 'Paraguay',
    'Countries.es-ru': 'Russia',
    'Countries.es-sv': 'El Salvador',
    'Countries.es-us': 'USA',
    'Countries.es-ve': 'Venezuela',
    'Countries.et-ee': 'Estonia',
    'Countries.fa-ir': 'Iran',
    'Countries.fi-ee': 'Estonia',
    'Countries.fi-fi': 'Finland',
    'Countries.fr-be': 'Belgium',
    'Countries.fr-ca': 'Canada',
    'Countries.fr-ch': 'Switzerland',
    'Countries.fr-dz': 'Algeria',
    'Countries.fr-eg': 'Egypt',
    'Countries.fr-fr': ' France',
    'Countries.fr-ht': 'Haiti',
    'Countries.fr-il': 'Israel',
    'Countries.fr-kh': 'Cambodia',
    'Countries.fr-lb': 'Lebanon',
    'Countries.fr-lu': 'Luxembourg',
    'Countries.fr-mu': 'Mauritius',
    'Countries.fr-nc': 'New Caledonia',
    'Countries.fr-ru': 'Russia',
    'Countries.fr-sn': 'Senegal',
    'Countries.fr-us': 'Haiti',
    'Countries.fr-vn': 'Viet Nam',
    'Countries.ga-ie': 'Ireland',
    'Countries.gu-in': 'India',
    'Countries.he-il': 'Israel',
    'Countries.hi-in': 'India',
    'Countries.hi-ru': 'India',
    'Countries.hr-ae': 'Croatia',
    'Countries.hr-hr': 'Croatia',
    'Countries.hu-hu': 'Hungary',
    'Countries.hu-ro': 'Romania',
    'Countries.hu-sk': 'Slovakia',
    'Countries.hy-am': 'Armenia',
    'Countries.id-id': 'Indonesia',
    'Countries.is-is': 'Iceland',
    'Countries.it-ca': 'Canada',
    'Countries.it-ch': 'Switzerland',
    'Countries.it-it': 'Italia',
    'Countries.it-ru': 'Italy',
    'Countries.ja-jp': 'Japan',
    'Countries.ja-ru': 'Japan',
    'Countries.kn-in': 'India',
    'Countries.ko-kr': 'Korea (South)',
    'Countries.ko-ru': 'Korea (South)',
    'Countries.ko-us': 'USA',
    'Countries.lt-lt': 'Lithuania',
    'Countries.lv-lv': 'Latvia',
    'Countries.mk-mk': 'Macedonia',
    'Countries.ml-in': 'India',
    'Countries.mr-in': 'India',
    'Countries.ms-my': 'Malaysia',
    'Countries.ne-np': 'Nepal',
    'Countries.nl-be': 'Belgium',
    'Countries.nl-nl': 'Nederland',
    'Countries.nl-sr': 'Suriname',
    'Countries.no-no': 'Norway',
    'Countries.pa-gb': 'UK',
    'Countries.pl-_i': 'International',
    'Countries.pl-pl': 'Polska',
    'Countries.pt-ao': 'Angola',
    'Countries.pt-br': 'Brazil',
    'Countries.pt-pt': 'Portugal',
    'Countries.pt-ru': 'Brazil',
    'Countries.ro-ro': 'Romania',
    'Countries.ru-_i': 'Germany',
    'Countries.ru-am': 'Armenia',
    'Countries.ru-by': 'Belarus',
    'Countries.ru-de': 'Germany',
    'Countries.ru-ee': 'Estonia',
    'Countries.ru-fi': 'Finland',
    'Countries.ru-fr': 'France',
    'Countries.ru-gb': 'Germany',
    'Countries.ru-ru': 'Россия',
    'Countries.ru-tj': 'Tajikistan',
    'Countries.ru-ua': 'Russia',
    'Countries.ru-us': 'USA',
    'Countries.ru-uz': 'Uzbekistan',
    'Countries.si-lk': 'Sri Lanka',
    'Countries.sk-sk': 'Slovakia',
    'Countries.sl-si': 'Slovenia',
    'Countries.sq-al': 'Albania',
    'Countries.sq-ks': 'Kosovo',
    'Countries.sr-me': 'Montenegro',
    'Countries.sr-rs': 'Serbia',
    'Countries.sr-ru': 'Russia',
    'Countries.sv-ee': 'Estonia',
    'Countries.sv-fi': 'Finland',
    'Countries.sv-se': 'Sweden',
    'Countries.ta-in': 'India',
    'Countries.te-in': 'India',
    'Countries.th-th': 'Thailand',
    'Countries.tl-ph': 'Philippines',
    'Countries.tr-cy': 'Northern Cyprus',
    'Countries.tr-tr': 'Turkey',
    'Countries.uk-ua': 'Ukraine',
    'Countries.vi-vn': 'Viet Nam',
    'Countries.yi-us': 'USA',
    'Countries.zh-ca': 'Canada',
    'Countries.zh-cn': 'China',
    'Countries.zh-hk': 'China',
    'Countries.zh-my': 'Malaysia',
    'Countries.zh-ru': 'China',
    'Countries.zh-sg': 'Singapore',
    'Countries.zh-tw': 'Taiwan',
    'Countries.zh-us': 'USA',
    'Countries.zu-za': 'South Africa',
};
