type BrowserType = 'chrome' | 'webkit' | 'opera' | 'trident' | 'msie' | 'mozilla';

const userAgent = navigator.userAgent || '';
const uaMatch = (ua: string) => {
        ua = ua.toLowerCase();

        const match =
            /(chrome)[ /]([\w.]+)/.exec(ua) ||
            /(webkit)[ /]([\w.]+)/.exec(ua) ||
            /(opera)(?:.*version)?[ /]([\w.]+)/.exec(ua) ||
            /(trident)(?:.*rv:([\d.]+))?/.exec(ua) ||
            /(msie) ([\w.]+)/.exec(ua) ||
            (ua.indexOf('compatible') < 0 ? /(mozilla)(?:.*? rv:([\w.]+))?/.exec(ua) : null);

        return {
            browser: (match?.[1] || '') as BrowserType,
            version: match?.[2] || '0',
        };
    },
    matched = uaMatch(userAgent);

const browser = {} as {
    chrome?: boolean;
    webkit?: boolean;
    opera?: boolean;
    trident?: boolean;
    msie?: boolean;
    mozilla?: boolean;

    safari?: boolean;
    chromeIOS?: boolean;
    chromeIOSVer?: string;
    msie_mode?: number;

    playBook?: boolean;
    android?: boolean;
    iOS?: boolean;

    version: string;
    isWebView: boolean;
};

if (matched.browser) {
    browser[matched.browser] = true;
    browser.version = matched.version;
}

if (browser.webkit) {
    // Deprecated, use jQuery.browser.webkit instead
    // Maintained for back-compat only
    browser.safari = true;
    browser.chrome = !!userAgent.match(/Chrome/i);

    // Check if it's Chrome on iOS
    const criosMatch = /(crios)[/]([\d.]+)/.exec(userAgent.toLowerCase());
    if (criosMatch?.[1]?.length) {
        browser.chromeIOS = true;
        browser.chromeIOSVer = criosMatch[2] || '0';
    }
}

// in jquery 1.8 for chrome browsers the "$.browser" will indicate that browser is "chrome" and it is not "webkit"
// so make it to be "webkit"
// NOTE: moved from nd.core
if (browser.chrome) {
    browser.webkit = true;
}

if (browser.trident) {
    browser.msie = true;
}

if (browser.msie) {
    browser.msie_mode = (document as any).documentMode || 0;
}

const webViewChecks = [
    /\(.*\Wwv\W/, // Android 5 and above check: user-agent should contain "wv"
    /Android 4\..*Chrome\/30\.0\.0\.0/, // Android 4 has fixed version of Chrome for web view.
];

function isWebViewCheck(ua: string) {
    for (const check of webViewChecks) {
        if (check.test(ua)) {
            return true;
        }
    }
    return false;
}

browser.isWebView = isWebViewCheck(userAgent);

export { browser };
