import * as $ from "jquery";
import readingMap from 'nd.viewer.readingstats.readingmap';
import "nd.core";
    

    function readingMapExists() {
        return $.nd.viewer && readingMap;
    }

    var self = {
        pauseStats : function() {
            if(readingMapExists()) {
                readingMap.pause();
            }
        },
        resumeStats: function() {
            if(readingMapExists()) {
                readingMap.resume();
            }
        }
    };

    $.nd.stats = self;
    export default self;

