import { Subject } from 'rxjs';

import ndLogger from 'nd.logger';

export enum LRWidgetType {
    FORGOT_PASSWORD = 'forgotPassword',
    LOGIN = 'login',
    SOCIAL_LOGIN = 'socialLogin',
    REGISTRATION = 'registration',
    VERIFY_EMAIL = 'verifyEmail',
    RESET_PASSWORD = 'resetPassword',
}

export enum IdentityScreen {
    SUCCESS = 'identity-screen-success--active',
    ERROR = 'identity-screen-error--active',
    FORM = 'identity-screen-form--active',
}

export interface ILRWidgetOptions<P, S> {
    container: string;
    templateName?: string;
    onSuccess(response: P): void;
    onError(errors: S): void;
}

export class LoginRadiusWidget<P, S> {
    public widgetOptions: ILRWidgetOptions<P, S>;

    protected widgetType: LRWidgetType;
    protected lrObject: any;

    private successSubject: Subject<P>;
    private errorSubject: Subject<S>;

    constructor(container: string) {
        this.successSubject = new Subject<P>();
        this.errorSubject = new Subject<S>();

        this.onSuccessInternal = this.onSuccessInternal.bind(this);
        this.onErrorInternal = this.onErrorInternal.bind(this);

        this.widgetOptions = {
            container,
            onError: this.onErrorInternal,
            onSuccess: this.onSuccessInternal,
        };
    }

    /**
     * This function will initialize the LR widget that is used to generate the registration forms
     * reference: https://www.loginradius.com/docs/libraries/js-libraries/js-form-library/
     * reference: https://www.loginradius.com/docs/libraries/identity-experience-framework/customization/
     */
    public initForm() {
        if (document.querySelector(`#${this.widgetOptions.container}`)) {
            // insert widget into the div container
            this.lrObject.init(this.widgetType, this.widgetOptions);
        }
    }

    private onSuccessInternal(response: P) {
        this.successSubject.next(response);
    }

    private onErrorInternal(errors: S) {
        ndLogger.error(errors);
        this.errorSubject.next(errors);
    }

    get onSuccess() {
        return this.successSubject.asObservable();
    }

    get onError() {
        return this.errorSubject.asObservable();
    }
}
