// eslint-env es6

import api from 'nd.services.api';

const serviceBasePath = 'v1/accounts/current/clientactivations';

/**
 * @typedef ClientActivation
 * @type Object
 * @property {Number} id - activation Id
 * @property {Boolean} isActive - is active
 * @property {String} clientName - client name
 * @property {String} clientVersion - vesrion of client software
 * @property {Date} activationDate - date of activation
 * @property {Date} lastRequestDate - date of last request from client
 * @property {Date} updateDate - activation update date
 */

function normalize(data) {
    // normalize dates
    ['activationDate', 'lastRequestDate', 'updateDate'].forEach(field => {
        if (data[field] !== null) {
            data[field] = new Date(data[field]);
        }
    });

    return data;
}

/**
 * Gets profile of current user
 *
 * @returns {Promise.<Array.<ClientActivation>>} activations list
 */
function getActivations({ includeInactive = false }) {
    return api.get(serviceBasePath, '', { includeInactive }).then(items => items.map(normalize));
}

/**
 * Deactivates given client activation
 *
 * @param Number - activation id
 * @returns Promise
 */
function deactivate(id) {
    if (!id) {
        return Promise.reject('id required');
    }

    return api.put(serviceBasePath, `${id}/deactivate`, { disableRetry: true });
}

/**
 * Re-activates given client activation
 *
 * @param {Number} id - activation id
 * @returns Promise
 */
function reactivate(id) {
    if (!id) {
        return Promise.reject('id required');
    }

    return api.put(serviceBasePath, `${id}/reactivate`, { disableRetry: true });
}

export { getActivations, reactivate, deactivate };
