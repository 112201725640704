/* eslint-env es6 */

import { Modernizr } from '@pressreader/modernizr';

// CSS style expression used to determine scrollbar dimensions
const styleExpression = 'width:100px;height:100px;overflow:scroll';

/**
 * Measures scrollbar width.
 * @returns {Number} scrollbar width in pixels.
 */
function measureScrollbar() {
    let width;
    Modernizr.testStyles(`#modernizr {${styleExpression}}`, elem => {
        width = elem.offsetWidth - elem.clientWidth;
    });

    return width;
}

let scrollbarWidth;
const scrollbarInfo = {};
Object.defineProperties(scrollbarInfo, {
    width: {
        enumerable: true,
        get: function getScrolbarWidth() {
            if (scrollbarWidth === undefined) {
                scrollbarWidth = measureScrollbar();
            }

            return scrollbarWidth;
        },
    },
    isHidden: {
        enumerable: true,
        get: function getHiddenFlag() {
            return scrollbarInfo.width === 0;
        },
    },
});

window.__scrollbar = scrollbarInfo;

export default scrollbarInfo;
