import { httpGet, httpPost } from '@pressreader/services';
import { ReCaptchaVersion } from '@pressreader/utils';

import { NewUser, SignInResultDto } from './types';

function apiSignIn(userName: string, password: string, reCaptchaVersion: ReCaptchaVersion, reCaptchaToken: string): Promise<SignInResultDto> {
    return httpPost('auth', 'Signin', { userName, password, reCaptchaVersion, reCaptchaToken }, { disableRetry: true });
}

function apiSignInByKey(key: string): Promise<SignInResultDto> {
    return httpPost('auth', 'SigninByKey', { key });
}

function apiSignOut(): Promise<void> {
    return httpGet('auth', 'SignOut');
}

function apiRegister(data: NewUser): Promise<SignInResultDto> {
    return httpPost('auth', 'Register', JSON.stringify(data), { contentType: 'application/json', disableRetry: true });
}

function apiGetExternalRequestKey(): Promise<{ Key: string }> {
    return httpGet('ExternalAuth', 'GetRequestKey');
}

function apiRecoverPassword(logonName: string): Promise<void> {
    return httpPost('v1/account', 'recoverpassword', JSON.stringify({ logonName }), { contentType: 'application/json', disableRetry: true });
}

export { apiSignIn, apiSignInByKey, apiSignOut, apiGetExternalRequestKey, apiRecoverPassword, apiRegister };
