import ndService from 'nd.services.api';

function getBundlesPrice(bundlesData) {
    return ndService.post('bundle', 'GetBundlesPrice', JSON.stringify(bundlesData), { contentType: 'application/json' });
}

function getZeroCurrency() {
    return ndService.get('bundle', 'GetZeroCurrency');
}

function getProducts(isTrialUsed, isNewUser) {
    return ndService.get('bundle', 'GetProducts', { isTrialUsed: isTrialUsed, isNewUser: isNewUser });
}

function getPromoProducts(promoCode) {
    return ndService.get('bundle', 'GetPromoProducts', { promoCode: promoCode, isV2: true });
}

function isActiveBundle(bundleId, cids, issueDates) {
    return ndService.get('bundle', 'IsActiveBundle', { bundleId, cids, issueDates });
}

function getCurrent(mainCid) {
    return ndService.get('bundle', 'getCurrent', { mainCid: mainCid });
}

function getCurrentUserBundles(mainCid) {
    return ndService.get('bundle', 'GetCurrentUserBundles');
}

export { getBundlesPrice, getZeroCurrency, getProducts, getPromoProducts, isActiveBundle, getCurrent, getCurrentUserBundles };
