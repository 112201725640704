import { stringify } from 'qs';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

enum ContentType {
    JSON = 'application/json',
    FORM = 'application/x-www-form-urlencoded',
    MULTIPART = 'multipart/form-data',
    TEXT = 'text/plain',
}

type IData = FormData | URLSearchParams | object | string | null;

export const getContentType = (data: unknown, contentType?: string) => {
    // If content type is specified, use it as is. Otherwise, try to guess it.
    if (contentType) {
        return contentType;
    }

    if (data instanceof FormData) {
        return ContentType.MULTIPART;
    }

    // Default form data. So if application/json is not specified, we assume that
    // form data is expected.
    if (isObject(data)) {
        return ContentType.FORM;
    }

    // This is default content type for fetch.
    return ContentType.TEXT;
};

export const isValidData = (data: unknown): data is IData => {
    return data === null || isObject(data) || isString(data);
};

export const serializeData = (data: IData, contentType: string) => {
    if (contentType.includes(ContentType.MULTIPART) && data instanceof FormData) {
        return data;
    }

    if (contentType.includes(ContentType.FORM)) {
        if (data instanceof URLSearchParams) {
            return data;
        }

        const formattedData = isObject(data) ? stringify(data, { arrayFormat: 'indices', encodeValuesOnly: true }) : null;
        return formattedData ? new URLSearchParams(formattedData) : null;
    }

    if (contentType.includes(ContentType.JSON)) {
        return isObject(data) ? JSON.stringify(data) : data;
    }

    if (contentType.includes(ContentType.TEXT) && isString(data)) {
        return data;
    }

    return null;
};
