import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { Subject } from 'rxjs';
import simpleStorage from 'simplestorage.js';

const settingsChanged: Record<string, Subject<unknown>> = {};

function set<T>(key: string, value: T) {
    const oldValue = simpleStorage.get(key) as T;
    if (!isEqual(oldValue, value)) {
        simpleStorage.set(key, value);
        settingsChanged[key]?.next(value);
    }

    // Cloning it to make sure the value stored here doesn't get changed from outside.
    return cloneDeep(value);
}

function get<T>(key: string, defaultValue: T): T {
    return simpleStorage.get(key) ?? defaultValue;
}

function onSettingsChanged<T>(key: string) {
    const subject = (key in settingsChanged ? settingsChanged[key] : (settingsChanged[key] = new Subject())) as Subject<T>;
    return subject.asObservable();
}

export { set, get, onSettingsChanged };
