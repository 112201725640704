import { isEmpty } from 'lodash';

import { IApplicationInfoDto, IDeviceInfoDto, IOSInfoDto, IPageViewInfoDto, ISessionInfoDto } from '@pressreader/analytics-api';
import { makeAbsolutePath, mapBrowserInfoToDto, mapDeviceInfoToDto, mapOSInfoToDto, mapSessionInfoToDto } from '@pressreader/analytics-core';
import { getCurrentPath } from '@pressreader/routing';
import { svcAuth } from '@pressreader/services';

import navHistory from 'nd.navigationhistory';
import servicesApi from 'nd.services.api';
import { userAgentInfo } from 'analytics/useragent.info';
import { BaseUploader } from 'readingstats/uploaders/base';

const { deviceInfo, osInfo, browserInfo, sessionInfo } = userAgentInfo;

const baseReadEventModel = {
    device: mapDeviceInfoToDto(deviceInfo),
    os: mapOSInfoToDto(osInfo),
    application: mapBrowserInfoToDto(browserInfo),
    session: mapSessionInfoToDto(sessionInfo),
};

const MAX_BUFFER_SIZE = 0;

interface IReadingMapModel {
    device: IDeviceInfoDto;
    os: IOSInfoDto;
    application: IApplicationInfoDto;
    session: ISessionInfoDto;
    pageView: IPageViewInfoDto;
    userCurrentTime: string;
    userTimeOffset: number;
    frames: any[];
    view: string;
}

class ReadingMapUploader extends BaseUploader<any> {
    private _readingMap: IReadingMapModel = null;

    upload(frames: any[], force: boolean) {
        if (isEmpty(frames)) {
            return;
        }
        if (!this._readingMap) {
            this._readingMap = {
                ...baseReadEventModel,
                pageView: { id: null, url: makeAbsolutePath(getCurrentPath()), referer: null },
                userCurrentTime: null,
                userTimeOffset: null,
                view: navHistory.getState().viewName,
                frames: [],
            };
        }
        this._readingMap.frames.push(...frames);

        if (force || this._readingMap.frames.length > MAX_BUFFER_SIZE) {
            this._uploadBufferData();
        } else {
            this._uploadBufferDataDebounced();
        }
    }

    protected _uploadBufferData() {
        if (this._readingMap && svcAuth.online()) {
            const now = new Date();
            this._readingMap.userCurrentTime = now.toISOString();
            this._readingMap.userTimeOffset = now.getTimezoneOffset();
            servicesApi.post('ReadArticles', 'Add', JSON.stringify(this._readingMap), { contentType: 'application/json' });
            this._readingMap = null;
        }
    }
}

export { ReadingMapUploader };
