/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-console */

import { ViewName } from '@pressreader/navigation-types';
import { getBasePath } from '@pressreader/routing';
import config from '@pressreader/src/deprecated/nd.config';
import ndViewerViews from '@pressreader/src/nd.viewer.views';
import { loadExternalScript } from '@pressreader/utils';

import ndUser from 'nd.user';
import { IEventInfo, IPageViewInfo, ITracker, ITransactionInfo } from 'analytics/models/models';
import { SetupUserId } from 'analytics/trackers/helpers/UserId';
import { getCurrentSubscription } from 'subscriptions/services';

// https://www.marfeel.com/docs/compass/instrumentation/web/#single-page-application-support
function initMarfeelAnalytics(cfg: any) {
    window.marfeel = {
        config: cfg.UserConfig,
        cmd: [],
    };

    loadExternalScript(`${cfg.ModuleSdkUrl}?id=${cfg.UserConfig.accountId}`, {
        type: 'module',
    });
    loadExternalScript(`${cfg.NoModuleSdkUrl}?id=${cfg.UserConfig.accountId}`, {
        type: 'text/javascript',
        noModule: true,
    });
}

class MarfeelAnalytics implements ITracker {
    private _debug = false;
    private _enabled = false;
    private _loaded: Promise<boolean>;

    constructor() {
        this._loaded = (async () => {
            await config.loaded();

            const cfg = config.get('analytics.Marfeel');

            this._debug = cfg.Debug;

            if (this._debug) {
                console.log('Marfeel analytics configuration: ', cfg);
            }

            if (!cfg || !cfg.Enabled) {
                return false;
            }

            this._enabled = true;

            if (!window.marfeel) {
                try {
                    initMarfeelAnalytics(cfg);
                } catch (error) {
                    throw new Error(`Marfeel analytics - MarfeelAnalytics ctor - error on initMarfeelAnalytics: ${error.message}`);
                }
            }

            // https://www.marfeel.com/docs/compass/instrumentation/web/subscriber.html#user-subscriber-status
            const setUserStatus = () => {
                window.marfeel.cmd.push([
                    'compass',
                    async compass => {
                        const subscription = await getCurrentSubscription();
                        compass.setUserType(
                            cfg.UserJourney[subscription?.subscriptionName] ||
                                (ndUser.isLogin() ? cfg.UserJourney.Registered : cfg.UserJourney.Anonymous),
                        );
                    },
                ]);
            };
            ndUser.onUserStateChanged(setUserStatus);
            setUserStatus();

            // https://www.marfeel.com/docs/compass/instrumentation/web/subscriber.html#device-unification
            if (cfg.UseUserId) {
                SetupUserId((user: string) => {
                    window.marfeel.cmd.push([
                        'compass',
                        compass => {
                            compass.setSiteUserId(user || 0);
                        },
                    ]);
                });
            }

            return true;
        })();
    }

    toString() {
        return `Marfeel Analytics (${window.marfeel?.config?.accountId})`;
    }

    enabled(): boolean {
        return this._enabled;
    }

    async event(info: IEventInfo) {
        if (!this._enabled) {
            if (this._debug) {
                console.log('Marfeel - Event - Not enabled', info);
            }
            return;
        }

        if (info.category.toLowerCase() === 'article' && info.action.toLowerCase() === 'view') {
            const articleUrl = info.context.articleSource || `${document.location.origin}${getBasePath()}/article/${info.value}`;

            window.marfeel.cmd.push([
                'compass',
                compass => {
                    if (this._debug) {
                        console.log('Marfeel - Event - Article View - sending URL: ', articleUrl);
                    }
                    compass.trackNewPage(articleUrl);
                },
            ]);
        }
    }

    exception(description: string, fatal: boolean): void {}

    loaded(): Promise<boolean> {
        return this._loaded;
    }

    pageView(info: IPageViewInfo = {}): void {
        if (!this._enabled) {
            if (this._debug) {
                console.log('Marfeel - Page View - Not enabled', info);
            }
            return;
        }

        if (ndViewerViews.getActiveViewName() === ViewName.PagesView) {
            const pageUrl = `${window.location.origin}${info.page}`;

            window.marfeel.cmd.push([
                'compass',
                compass => {
                    if (this._debug) {
                        console.log('Marfeel - Page View - sending URL: ', pageUrl);
                    }
                    compass.trackNewPage(pageUrl);
                },
            ]);
        }
    }

    timing(category: string, variable: string, timeSpent: string, label: string): void {}

    transaction(transaction: ITransactionInfo): void {}

    customSourceTracking(source: string, medium: string): void {}
}

const marfeelAnalytics: ITracker = new MarfeelAnalytics();

export { marfeelAnalytics };
