import * as ko from 'knockout';
import * as $ from 'jquery';

        

        function TextTwoWayBindingHandler() {
            this.update = function (element, valueAccessor) {
                $(element).text(valueAccessor()());
            };

            this.init = function(element, valueAccessor) {
                var $element = $(element);
                $element.on('change', function() {
                    valueAccessor()($element.text());
                });

                ko.utils.domNodeDisposal.addDisposeCallback(element, function onElementDispose() {
                    $element.off('change');
                    $element = null;
                });
            };
        }

        ko.bindingHandlers['twoWayText'] = new TextTwoWayBindingHandler();
    