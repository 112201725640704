import * as $ from 'jquery';
import * as _ from 'lodash';
import * as ndStates from 'nd.states';
import ndLogger from 'nd.logger';
import $ndRes from 'deprecated/nd.res';
import $ndLayout from 'nd.layout';
import $navHistory from 'nd.navigationhistory';
import { DialogStatus } from 'nd.ui.dialogstatus';
import { getNextTintZIndex } from 'nd.layout.zindex';
import { Deferred } from 'promise/deferred';
import { keyCodes } from '@pressreader/utils';

var instantiated;

/*
 * Usage:
 * var alertDialog = new $.nd.alert();
 *
 * alertDialog.show(@message, @title, @buttonTitle, @hideCloseButton).then(@closeCallback);
 * alertDialog.alert(@message, @buttonTitle, @hideCloseButton).then(@closeCallback);
 * alertDialog.confirm(@message, @noButtonTitle, @yesButtonTitle, @hideCloseButton).then(@closeCallback);
 * */
function _Alert() {
    this._panel = null;
    this._tintPanel = null;
    this._isVisible = false;
    this._deferred = null;
    this._timer = null;
}

_.extend(_Alert.prototype, {
    _createTintPanel: function () {
        if (!this._tintPanel) {
            var self = this;
            this._tintZIndex = getNextTintZIndex();
            this._tintPanel = $(document.body).createChild('div');
            this._tintPanel.addClass('dialog-tint').css('z-index', this._tintZIndex);

            this._tintPanel.bind('click', function () {
                if (self._panel.model && self._panel.model.modal) {
                    return;
                }

                self.hide.call(self, DialogStatus.Cancel);
            });
        }
        return this._tintPanel;
    },
    _show: function (data, title, buttonTitle, hideCloseButton) {
        if (this._isVisible) return this._deferred.promise();
        this._createTintPanel();
        this._deferred = new Deferred();
        var self = this;
        var model;
        if (typeof data == 'object') {
            model = $.extend({ messageType: 'text', ndScrollDisabled: false }, data);
            model.click = function () {
                self.hide(DialogStatus.Ok);
            };
        } else
            model = {
                title: title || null,
                message: data,
                buttonTitle: buttonTitle || $ndRes.val('Buttons.Close'),
                hideCloseButton: !!hideCloseButton,
                click: function () {
                    self.hide(DialogStatus.Ok);
                },
                ndScrollDisabled: false,
            };
        if (!model.hideCloseButton) {
            model.closeClick = (function (self) {
                return function () {
                    self.hide(DialogStatus.Close);
                };
            })(this);
        } else {
            model.closeClick = $.noop;
        }

        if (!this._panel) {
            this._panel = $(document.body)
                .createChild('div')
                .addClass('alert-wrapper')
                .css({
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    'z-index': this._tintZIndex + 1,
                    visibility: 'hidden',
                });

            $ndLayout.resize(this._adjustWithDelay.bind(this));
            $navHistory.oneNavigate(function () {
                self.hide(DialogStatus.Ok);
            });
        }
        if (model.templateContent) {
            try {
                this._panel.html(model.templateContent);
            } catch (err) {
                ndLogger.error(err);
            }
            ko.applyBindings(model, this._panel[0]);
        } else {
            switch (model.type) {
                case 'busy':
                    $.nd.templates.renderTo('v7.Client.Dialogs.Busy', model, this._panel);
                    break;
                case 'custom':
                    $.nd.templates.renderTo(model.templateName, model, this._panel);
                    break;
                default:
                    $.nd.templates.renderTo('v7.Client.Dialogs.Alert', model, this._panel);
            }

            this._panel.model = model;
        }
        var panel = this._panel.children().css({ opacity: 0, margin: 0 });
        this._isVisible = true;
        this._adjust();
        panel.css({
            transitionProperty: 'opacity',
            transitionTimingFunction: 'ease-out',
            transitionDuration: '300ms',
            opacity: 1,
            visibility: 'visible',
        });
        ndStates.modal.set();
        return this._deferred.promise();
    },
    _adjustWithDelay: function () {
        // execute async to avoid unnecessary calls
        if (this._timer) {
            clearTimeout(this._timer);
        }

        this._timer = setTimeout(this._adjust.bind(this), 200);
    },
    _adjust: function () {
        if (!this._panel || !this._isVisible) return;

        var aHeight = 0;
        var aHeightExtra = 0;
        var aHeaderHeight = 0;
        var aControlsHeight = 0;
        var aBodyHeightOrigin = 0;
        var aBodyHeight = 0;
        var aWidth = 0;
        var aMinWidth = 0;

        this._panel.find('.alert').each(function () {
            aWidth = $(this).outerWidth();
            aMinWidth = parseInt($(this).css('min-width'));
            if (aWidth > aMinWidth) {
                $(this).addClass('alert-textleft');
                //this._panel.addClass('alert-textleft');
            }
        });

        if (this._panel.find('.alert-label').length > 0) {
            this._panel.find('.alert-label').show();
            this._panel.addClass('alert-labeled');
        }

        this._panel.find('header:visible').each(function () {
            aHeaderHeight = $(this).outerHeight(true);
        });

        this._panel.find('.controls:visible').each(function () {
            aControlsHeight = $(this).outerHeight(true);
        });

        var ww = $.windowWidth();
        var wh = $.windowHeight();
        this._panel.find('.alert-body').each(function () {
            $(this).css('height', 'auto'); // reset
            aBodyHeightOrigin = $(this).outerHeight();
            aHeight = $(this).parent('.alert').height();
            aHeightExtra = $(this).parent('.alert').outerHeight() - aHeight;

            if ($(this).parent('.alert').outerHeight() > wh) {
                aHeight = wh - aHeightExtra;
            } else {
                $(this).parent('.alert').css('height', 'auto'); // reset
            }

            aBodyHeight = aHeight - aHeaderHeight - aControlsHeight;
            $(this).height(aBodyHeight);

            // sometimes the actual height has a decimal value, but jquery returns only rounded values
            // the alert dialog could be positioned to the top (with alert-scaled class) even if the diff in 1px
            if (aBodyHeight < aBodyHeightOrigin && Math.abs(aBodyHeight - aBodyHeightOrigin) > 1) {
                $(this).parent('.alert').addClass('alert-scaled');
            } else {
                $(this).parent('.alert').removeClass('alert-scaled');
            }
        });

        this._screenCenter(ww, wh);
        this._refreshScroll();
    },
    _screenCenter: function (ww, wh) {
        var panel = this._panel.children();
        panel.css({
            left: (ww - panel.width()) >> 1,
            top: (wh - panel.height()) >> 1,
        });
    },
    _refreshScroll: function () {
        this._panel.find('[nd-scrollable]').each(function () {
            var $this = $(this);
            var $scroll = $this.data('scroller');
            if ($scroll && $.isFunction($scroll.refresh)) {
                $scroll.refresh();
            }
        });
    },
    _loaded: function () {
        return Promise.all([$ndRes.loaded(), $.nd.templates.loaded()]);
    },
    show: function (data, title, buttonTitle, hideCloseButton) {
        var self = this;
        return this._loaded().then(self._show.bind(this, data, title, buttonTitle, hideCloseButton));
    },
    confirm: function (message, noButtonTitle, yesButtonTitle, hideCloseButton, messageType) {
        var self = this;
        return this._loaded().then(function () {
            var model = {
                message: message,
                messageType: messageType || 'text',
                noButtonTitle: noButtonTitle || $ndRes.val('Buttons.No.Text'),
                yesButtonTitle: yesButtonTitle || $ndRes.val('Buttons.Yes.Text'),
                hideCloseButton: !!hideCloseButton,
                noButtonClick: function () {
                    self.hide(DialogStatus.Cancel);
                },
                yesButtonClick: function () {
                    self.hide(DialogStatus.Ok);
                },
                templateName: 'v7.Client.Dialogs.Confirm',
                type: 'custom',
            };
            return self._show(model);
        });
    },

    /**
     * Show custom dialog.
     * @param {Object} customVm
     * @param {string} customVm.message
     * @param {string} customVm.messageType - 'text' or 'html'.
     * @param {string} customVm.noButtonTitle
     * @param {string} customVm.yesButtonTitle
     * @param {boolean} customVm.hideCloseButton
     * @param {function} customVm.noButtonClick
     * @param {function} customVm.yesButtonClick
     * @param {boolean} customVm.ndScrollDisabled
     * @param {boolean} customVm.modal
     * @param {string} [customVm.templateName=v7.Client.Dialogs.Confirm]
     * @param {string} [customVm.type=custom]
     * @returns {Promise}
     */
    custom: function (customVm) {
        var self = this;

        return this._loaded().then(function () {
            const model = $.extend(
                {},
                {
                    templateName: 'v7.Client.Dialogs.Confirm',
                    type: 'custom',
                },
                customVm,
            );

            return self._show(model);
        });
    },

    /**
     * Shows confirmation dialog.
     * @return {Promise<Boolean>} true if confirmation was OK, false otherwise.
     */
    confirmSafe: function (message, noButtonTitle, yesButtonTitle, hideCloseButton) {
        var self = this;
        return new Promise(function (resolve) {
            self.confirm(message, noButtonTitle, yesButtonTitle, hideCloseButton)
                .then(function () {
                    resolve(true);
                })
                .catch(function () {
                    resolve(false);
                });
        });
    },
    alert: function (message, buttonTitle, hideCloseButton) {
        var self = this;
        return this._loaded().then(function () {
            var model = {
                message: message,
                buttonTitle: buttonTitle || $ndRes.val('Buttons.Ok.Text'),
                hideCloseButton: !!hideCloseButton,
                click: function () {
                    self.hide(DialogStatus.Ok);
                },
            };
            return self._show(model);
        });
    },
    hide: function (status) {
        if (!this._isVisible) return;
        ndStates.modal.reset();
        this._tintPanel.remove();
        this._tintPanel = null;
        this._panel.remove();
        this._panel = null;
        this._isVisible = false;
        $(this).trigger('closed');
        if (status === DialogStatus.Close || status === DialogStatus.Cancel) {
            this._deferred.reject();
        } else {
            this._deferred.resolve();
        }
    },
    onClosed: function (fn) {
        $(this).one('closed', fn);
    },
    visible: function () {
        return this._isVisible;
    },
});

$(document).keydown(function (e) {
    if (instantiated && instantiated.visible()) {
        if (e.which === keyCodes.esc) {
            instantiated._deferred.reject();
            instantiated.hide(DialogStatus.Close);
            return false;
        }
    }
});

$.nd.alert = function () {
    if (!instantiated) {
        instantiated = new _Alert();
    }
    return instantiated;
};

export default $.nd.alert;
