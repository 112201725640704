/* eslint-env es6 */
'use strict';

import * as ko from 'knockout';

/**
 * Opinion view model.
 */
class OpinionViewModel {
    /**
     * Creates new instance of OpinionViewModel.
     */
    constructor({ opinionId, webName, text, numberOfFollowers, followedByCurrentUser }) {
        this.id = opinionId.toString();
        this.opinion = text;
        this.webName = webName;
        this.supporting = ko.observable(followedByCurrentUser);
        this.supporters = ko.observable(numberOfFollowers);
    }

    update({ supporting, supporters } = {}) {
        if (supporting !== undefined) {
            this.supporting(ko.unwrap(supporting));
        }

        if (supporters !== undefined) {
            this.supporters(ko.unwrap(supporters));
        }
    }
}

export default OpinionViewModel;
