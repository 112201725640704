import * as $ from 'jquery';
import { isString, isObject, forIn, isEmpty } from 'lodash';
import ndLogger from 'nd.logger';

/* eslint-disable prefer-rest-params */

class Callbacks {
    private _nd_callbacks_callbacks: { [name: string]: JQuery.Callbacks };

    bind(callbacks: { [name: string]: (...params) => void }): this;
    bind(name: string, callback: (...params) => void): this;
    bind() {
        return this._bindProxy(...arguments);
    }

    on(callbacks: { [name: string]: (...params) => void }): this;
    on(name: string, callback: (...params) => void): this;
    on() {
        return this._bindProxy(...arguments);
    }

    unbind(name?: string, ...callbacks: Array<(...params) => void>) {
        if (!arguments.length) {
            delete this._nd_callbacks_callbacks;
        } else if (this._nd_callbacks_callbacks && name in this._nd_callbacks_callbacks) {
            if (isEmpty(callbacks)) {
                delete this._nd_callbacks_callbacks[name];
            } else {
                this._nd_callbacks_callbacks[name].remove(...callbacks);
            }
        }
        return this;
    }

    off(name?: string, ...callbacks: Array<(...params) => void>) {
        return this.unbind(name, ...callbacks);
    }

    protected _fire(name: string, ...rest) {
        if (this._nd_callbacks_callbacks && name in this._nd_callbacks_callbacks) {
            try {
                this._nd_callbacks_callbacks[name].fire(this, ...rest);
            } catch (e) {
                ndLogger.error(e);
            }
        }
        return this;
    }

    private _bindProxy(...params: any[]) {
        if (params.length === 2 && isString(params[0])) {
            return this._bind(params[0], params[1]);
        }
        if (params.length === 1 && isObject(params[0])) {
            forIn(params[0], (callback, name) => {
                this._bind(name, callback);
            });
            return this;
        }
        throw new Error('Invalid arguments');
    }

    private _bind(name: string, callback: (...params) => void) {
        let callbacks: JQuery.Callbacks;
        if (!this._nd_callbacks_callbacks) {
            this._nd_callbacks_callbacks = {};
            this._nd_callbacks_callbacks[name] = callbacks = $.Callbacks('unique');
        } else if (name in this._nd_callbacks_callbacks) {
            callbacks = this._nd_callbacks_callbacks[name];
        } else {
            this._nd_callbacks_callbacks[name] = callbacks = $.Callbacks('unique');
        }
        callbacks.add(callback);
        return this;
    }
}

export { Callbacks };
