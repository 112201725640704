import { ViewName } from '@pressreader/navigation-types';

export enum ReplaceType {
    Off,
    On,
    ForceOn,
}

export interface NavigationParams {
    [key: string]: unknown;

    viewName: ViewName;
}
