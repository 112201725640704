import { IDeviceInfoDto } from '@pressreader/analytics-api';
import { IDeviceInfo, DeviceType } from '@pressreader/analytics-types';

import { IResult } from 'ua-parser-js';

function buildDeviceInfo(ua: IResult): IDeviceInfo {
    return {
        type: ua.device.type as DeviceType,
        model: ua.device.model ?? '',
        vendor: ua.device.vendor ?? '',
        colorDepth: window.screen.colorDepth,
        screenSize: `${window.screen.width}x${window.screen.height}`,
        devicePixelRatio: window.devicePixelRatio,
    };
}

function mapDeviceInfoToDto(info: IDeviceInfo): IDeviceInfoDto {
    return {
        type: info.type,
        model: info.model,
        vendor: info.vendor,
        screenSize: info.screenSize,
        colorDepth: info.colorDepth,
        devicePixelRatio: info.devicePixelRatio,
    };
}

export { buildDeviceInfo, mapDeviceInfoToDto };
