// yyyyMMdd | yyyy-MM-dd | yyyy-MM-ddT00:00:00 | yyyy-MM-ddT00:00:00Z
const CUSTOM_FORMAT_DATE_REGEXP = /^(\d{4})-?(\d{2})-?(\d{2})(T00:00:00Z?)?$/;

/**
 * Parses given string to a Date object.
 * Parsing logic is the same as standard Date.parse,
 * with one addition that strings formatted as 'yyyyMMdd' or 'yyyy-MM-dd'
 * are treated as though they represent local time.
 * @param {string} str A string to be parsed.
 * @return {Date} Parsed date object.
 */
function parseDateString(str: string): Date | null {
    if (typeof str !== 'string') {
        return null;
    }
    const parsed = str.match(CUSTOM_FORMAT_DATE_REGEXP);
    if (!parsed) {
        return new Date(str);
    }

    const year = parseInt(parsed[1], 10);
    const month = parseInt(parsed[2], 10) - 1; // month is zero based;
    const day = parseInt(parsed[3], 10);

    if ([year, month, day].some(isNaN)) {
        return null;
    }

    // When called as a constructor with more than one argument,
    // the specifed arguments represent local time
    return new Date(year, month, day);
}

export { parseDateString };
