// codes of languages which are written from right-to-left
// https://meta.wikimedia.org/wiki/Template:List_of_language_names_ordered_by_code
const RtlLanguageList = [
    'ar', // Arabic
    'arc', // Aramaic
    'dv', // Divehi
    'far', // Farsi
    'ha', // Hausa
    'he', // Hebrew
    'khw', // Khowar
    'ks', // Kashmiri
    'ku', // Kurdish
    'ps', // Pashto
    'ur', // Urdu
    'yi', // Yiddish
];

function isLanguageRtl(langCode: string) {
    return RtlLanguageList.indexOf(langCode) >= 0;
}

export { isLanguageRtl };
