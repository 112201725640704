import { AuthSignInSystemType, IAuthenticationAnalyticsContext, IAuthenticationContext } from '@pressreader/authentication-types';
import { error } from '@pressreader/logger';
import { localizedString } from '@pressreader/resources';
import { apiSiteSignIn, apiSiteSignOut, svcAuth } from '@pressreader/services';
import { CustomError } from '@pressreader/types';
import { executeReCaptcha, getErrorMessage, ReCaptchaVersion } from '@pressreader/utils';

import { apiRecoverPassword, apiRegister, apiSignIn, apiSignOut } from './auth.api';
import {
    publishAccountChangedEvent,
    sendLoginBeganEvent,
    sendLoginDoneEvent,
    sendLoginFailedEvent,
    sendLogOutBeganEvent,
    sendLogOutDoneEvent,
    sendLogOutFailedEvent,
    sendSignUpBeganEvent,
    sendSignUpDoneEvent,
    sendSignUpFailedEvent,
} from './auth.events';
import { createEmailExternalProfile } from './external.profile.api';
import { AuthStatus, NewUser, SignInResultDto } from './types';
import { isUserAuthenticated } from './user.api';

const authErrorResources = {
    [AuthStatus.InvalidUserNameOrPassword]: 'Dialogs.Signin.InvalidLoginOrPass',
    [AuthStatus.ExceedMaxSimultaneousSessionsPerUser]: 'Dialogs.Signin.MaxAllowedSimultaneousSessionsExceeded',
    [AuthStatus.CaptchaFailed]: 'ReCaptcha.Verification.ErrorMessage',
    [AuthStatus.UnknownError]: 'Errors.UnknownError',
    [AuthStatus.ExceedMaxIncorrectPasswordAttempts]: 'Auth.Errors.ExceedMaxIncorrectPasswordAttempts',
} as const;

interface AuthUserRequest {
    signInResult: SignInResultDto;
    enableAutologin: boolean;
    authContext?: IAuthenticationContext;
}

async function authorizeUser({ signInResult, enableAutologin, authContext }: AuthUserRequest): Promise<void> {
    if (!signInResult) {
        throw new Error('signInResult not specified');
    }

    if (signInResult.Status !== AuthStatus.Success) {
        const errorMessage = localizedString(authErrorResources[signInResult.Status], 'Unknown Error');
        throw new CustomError(errorMessage);
    }
    const oldUserKey = svcAuth.userKey();
    const siteSignInResult = await apiSiteSignIn(signInResult.Token, enableAutologin);
    svcAuth.updateAuthorizationTickets(siteSignInResult);
    if (!isUserAuthenticated()) {
        throw new Error('Not authorized');
    }

    // account change
    if (svcAuth.userKey() !== oldUserKey) {
        publishAccountChangedEvent('before', authContext);
    }
    // account remains the same
    publishAccountChangedEvent('after', authContext);
}

async function signIn({
    userName,
    password,
    enableAutologin,
    analyticsContext,
}: {
    userName: string;
    password: string;
    enableAutologin: boolean;
    analyticsContext?: IAuthenticationAnalyticsContext;
}) {
    try {
        sendLoginBeganEvent({ system: 'email' });

        const reCaptchaToken = await executeReCaptcha('signin');
        const signInResult = await apiSignIn(userName, password, ReCaptchaVersion.v3, reCaptchaToken);
        await authorizeUser({
            signInResult,
            enableAutologin,
            authContext: { method: 'signin', system: 'email', analytics: analyticsContext },
        });

        sendLoginDoneEvent({ system: 'email' });
    } catch (e: unknown) {
        sendLoginFailedEvent({ error: { message: getErrorMessage(e) }, system: 'email' });
        throw e;
    }
}

async function signUp(newUser: NewUser, analyticsContext?: IAuthenticationAnalyticsContext): Promise<{ sponsorId?: number }> {
    try {
        sendSignUpBeganEvent({ system: 'email' });

        newUser.reCaptchaVersion = ReCaptchaVersion.v3;
        newUser.reCaptchaToken = await executeReCaptcha('signup');

        analyticsContext = {
            ...analyticsContext,
            consentToMarketingEmails: newUser.enablePromotional || newUser.enableService,
        };

        const signInResult = await apiRegister(newUser);

        analyticsContext.verificationRequired = !!signInResult.SponsorId;
        const authContext: IAuthenticationContext = {
            method: 'signup',
            system: newUser.libraryUser ? 'library' : 'email',
            analytics: analyticsContext,
        };

        await authorizeUser({
            signInResult,
            authContext,
            enableAutologin: false,
        });

        if (signInResult.SponsorId) {
            await createEmailExternalProfile(newUser.emailAddress, signInResult.SponsorId);
        }

        sendSignUpDoneEvent({
            system: 'email',
            sponsorId: signInResult.SponsorId,
            analyticsCtx: analyticsContext,
        });
        return { sponsorId: signInResult.SponsorId };
    } catch (e: unknown) {
        sendSignUpFailedEvent({ error: { message: getErrorMessage(e), system: AuthSignInSystemType.Email } });
        throw e;
    }
}

async function signOut({ supressReauthorize }: { supressReauthorize?: boolean } = {}) {
    try {
        sendLogOutBeganEvent();

        await apiSignOut();
        const siteSignOutResult = await apiSiteSignOut();
        svcAuth.updateAuthorizationTickets(siteSignOutResult);
        if (supressReauthorize) {
            return;
        }

        publishAccountChangedEvent('before');
        publishAccountChangedEvent('after');

        sendLogOutDoneEvent();
    } catch (e) {
        sendLogOutFailedEvent({ error: { message: getErrorMessage(e), system: AuthSignInSystemType.Email } });
        throw e;
    }
}

async function recoverPassword({ userName }: { userName: string }) {
    try {
        await apiRecoverPassword(userName);
    } catch (e) {
        error(e);
        throw e;
    }
}

export { authorizeUser, signIn, recoverPassword, signUp, signOut };
