import { createActionHandler, createReducer } from '@pressreader/appstore';
import { IAuthError, IUser, IUserSettings } from '@pressreader/authentication-types';

import {
    authInitialized,
    loginDone,
    loginFailed,
    logOutDone,
    logOutFailed,
    pwdRecoveryBegan,
    pwdRecoveryDone,
    pwdRecoveryFailed,
    resetAuthStateAction,
    signUpDone,
    signUpFailed,
    userAvatarChanged,
    userChangeBegan,
    userChangeDone,
    userChangeFailed,
} from './actions/common';
import { createAnonymousUser, createDefaultUserSettings } from './utils';

interface IState {
    initialized: boolean;

    authInProgress: boolean;
    lastAuthError: IAuthError | null;

    lastRecoveryEmailSentTo: string | null;

    user: IUser;
    userSettings: IUserSettings;
}

const initialState: IState = {
    initialized: false,

    authInProgress: false,
    lastAuthError: null,
    lastRecoveryEmailSentTo: null,

    user: createAnonymousUser(),
    userSettings: createDefaultUserSettings(),
};

const reducer = createReducer<IState>(
    initialState,
    createActionHandler(authInitialized, (state, action) => ({
        ...state,
        initialized: true,
        user: action.payload.user,
        userSettings: action.payload.userSettings,
    })),
    createActionHandler(loginDone, (state, action) => ({
        ...state,
        user: action.payload.user,
        userSettings: action.payload.userSettings,
    })),
    createActionHandler(
        signUpDone,
        (state, action): IState => ({
            ...state,
            user: action.payload.user,
            userSettings: action.payload.userSettings,
        }),
    ),
    createActionHandler(logOutDone, state => ({
        ...state,
        user: createAnonymousUser(),
        userSettings: createDefaultUserSettings(),
    })),
    createActionHandler(userChangeBegan, state => ({
        ...state,
        authInProgress: true,
        lastAuthError: null,
    })),
    createActionHandler(userChangeDone, state => ({
        ...state,
        authInProgress: false,
        lastAuthError: null,
    })),
    createActionHandler(userChangeFailed, state => ({
        ...state,
        authInProgress: false,
    })),
    createActionHandler(loginFailed, (state, action) => ({
        ...state,
        lastAuthError: action.payload.error,
    })),
    createActionHandler(logOutFailed, (state, action) => ({
        ...state,
        lastAuthError: action.payload.error,
    })),
    createActionHandler(signUpFailed, (state, action) => ({
        ...state,
        lastAuthError: action.payload.error,
    })),
    createActionHandler(pwdRecoveryBegan, state => ({
        ...state,
        lastAuthError: null,
    })),
    createActionHandler(pwdRecoveryDone, (state, action) => ({
        ...state,
        lastAuthError: null,
        lastRecoveryEmailSentTo: action.payload.email,
    })),
    createActionHandler(pwdRecoveryFailed, (state, action) => ({
        ...state,
        lastAuthError: { message: action.payload.error },
    })),
    createActionHandler(resetAuthStateAction, state => ({
        ...state,
        lastAuthError: null,
        lastRecoveryEmailSentTo: null,
    })),
    createActionHandler(userAvatarChanged, (state, action) => ({
        ...state,
        user: { ...state.user, avatarImageId: action.payload.imageId },
    })),
);

export { IState, initialState, reducer };
