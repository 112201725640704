import { GeoPoint } from '@pressreader/types';
import { browser } from '@pressreader/utils';

const TIMEOUT_GET_GEOLOCATION_BY_CLIENT = 60000;

// Possible error codes thrown via the GeoLocation API
enum ErrorCode {
    Unknown = 0,
    PermissionDenied = 1,
    PositionUnavailable = 2,
    Timeout = 3,
}

const ErrorMessages: Record<number, string> = {
    [ErrorCode.Unknown]: 'Unknown error',
    [ErrorCode.PermissionDenied]: 'Permission denied by user',
    [ErrorCode.PositionUnavailable]: 'Position is not available',
    [ErrorCode.Timeout]: 'Request timed out',
};

async function checkGeolocation() {
    if (browser.safari) {
        // Since navigator.permissions.query() is not supported in Safari, you need to enable geolocation in the settings
        // Permissions will be checked in getCurrentPositionByClient()
        // Potentionaly we need to display a message to the user to enable geolocation in the settings
        return { state: 'prompt', onchange: null } as PermissionStatus;
    }
    return await navigator.permissions.query({ name: 'geolocation' });
}

async function getCurrentPositionByClient(timeout = TIMEOUT_GET_GEOLOCATION_BY_CLIENT): Promise<GeoPoint> {
    const permissionDenied = await isLocationPermissionDenied();
    if (permissionDenied) {
        return Promise.reject(ErrorMessages[ErrorCode.PositionUnavailable]);
    }

    return new Promise((resolve, reject) => {
        let timeoutID: number;

        if (timeout > 0) {
            timeoutID = window.setTimeout(() => reject(new Error(ErrorMessages[ErrorCode.Timeout])), timeout);
        }

        window.navigator.geolocation.getCurrentPosition(
            position => {
                window.clearTimeout(timeoutID);
                resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
            },
            error => {
                window.clearTimeout(timeoutID);
                // Error message to log
                let errorMessage = ErrorMessages[error.code];
                // Error codes 0 and 2 have extra message information wrapped
                //  into the error message
                if (error.code === ErrorCode.Unknown || error.code === ErrorCode.PositionUnavailable) {
                    errorMessage += ` ${error.message}`;
                }
                reject(new Error(errorMessage));
            },
            { timeout: timeout, enableHighAccuracy: true, maximumAge: 0 },
        );
    });
}

async function isLocationPermissionGranted(): Promise<boolean> {
    const queryResult = await checkGeolocation();
    return queryResult.state === 'granted';
}

async function isLocationPermissionDenied(): Promise<boolean> {
    const queryResult = await checkGeolocation();
    return queryResult.state === 'denied';
}

async function isLocationPermissionNotDefined(): Promise<boolean> {
    const queryResult = await checkGeolocation();
    return queryResult.state === 'prompt';
}

async function requestLocationPermission(): Promise<boolean> {
    const queryResult = await checkGeolocation();
    if (queryResult.state === 'denied') {
        return false;
    }
    if (queryResult.state === 'granted') {
        return true;
    }
    // Whatever comes first between the actual event (Chrome)
    // or the request to getCurrentPosition() (others).
    return Promise.race([
        new Promise<boolean>(resolve => {
            navigator.geolocation.getCurrentPosition(
                () => resolve(true),
                _err => resolve(false),
            );
        }),
        new Promise<boolean>(resolve => {
            queryResult.onchange = () => resolve(queryResult.state === 'granted');
        }),
    ]);
}

export {
    checkGeolocation,
    getCurrentPositionByClient,
    isLocationPermissionGranted,
    isLocationPermissionDenied,
    isLocationPermissionNotDefined,
    requestLocationPermission,
};
