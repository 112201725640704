let isLoaded = false;

async function loadModule() {
    if (isLoaded) {
        return;
    }

    const { init } = await import('./init');
    init();

    isLoaded = true;
}

export { loadModule };
