/* eslint-env es6 */

'use strict';

import * as ko from 'knockout';
import { forOwn, some, values, isFunction } from 'lodash';

/**
 * Tests if given source supports validation
 * @param {Object|ko.observable} source - source observable
 * @return {Boolean}
 */
function supportsValidation(source) {
    return ko.isObservable(source) && isFunction(source.hasErrors);
}

/**
 * Forces validation checks on components of the model and triggering their 'onchange' event.
 * Validation is forced only on those components which have 'hasErrors' method.
 *
 * @param {Object|Observable} viewModel - object to be validated. Usually contains some knockout observable properties
 * that will be validated.
 */
function forceValidation(viewModel) {
    if (!viewModel) {
        return;
    }

    viewModel = ko.utils.unwrapObservable(viewModel);

    if (viewModel.__validationAttempted) {
        viewModel.__validationAttempted(true);
    }

    values(viewModel).forEach(observable => {
        if (supportsValidation(observable) && isFunction(observable.valueHasMutated)) {
            observable.valueHasMutated();
        }
    });
}

/**
 * Checks that model is in valid state.
 * @param {Object|Observable|Array} viewModel - object to be validated. Usually contains some knockout observable properties
 * that will be validated.
 * @param {boolean} [forceMode=false] - Need to do make a forcible validation of viewmodel before check?
 * It leads to reevaluation all validations bindings and showing validation error messages.
 * @returns {boolean} - true, if:
 *  1. viewModel is undefined/null.
 *  2. viewModel does not contain any observables.
 *  3. viewModel does not contain any observable that was extended with validator extender.
 *  4. all observables in the viewModel report that they're valid.
 *  otherwise, returns false.
 */
function validate(viewModel, forceMode) {
    if (!viewModel) {
        return true;
    }

    if (Array.isArray(viewModel)) {
        return viewModel.map(vm => validate(vm, forceMode)).reduce((accumulator, currentValue) => accumulator && currentValue, true);
    }

    viewModel = ko.utils.unwrapObservable(viewModel);

    if (forceMode) {
        forceValidation(viewModel);
    }

    if (viewModel.__validationAttempted) {
        viewModel.__validationAttempted(true);
    }

    // stop on first invalid observable and return false.
    // if all observables are valid, return true.
    return !some(values(viewModel), observable => supportsValidation(observable) && observable.hasErrors());
}

/**
 * Flushes all saved validation state in the corresponding view model.
 * @param {Object|ko.observable} viewModel - object, validation state of which has to be flushed.
 */
function resetValidationState(viewModel) {
    if (!viewModel) {
        return;
    }

    if (ko.isObservable(viewModel) && isFunction(viewModel.isDirty)) {
        viewModel.isDirty(false);
        viewModel = ko.utils.unwrapObservable(viewModel);
    }

    forOwn(viewModel, observable => {
        if (ko.isObservable(observable) && isFunction(observable.isDirty)) {
            observable.isDirty(false);
        }
    });

    if (viewModel.__validationAttempted) {
        viewModel.__validationAttempted(false);
    }
}

export { validate, forceValidation, resetValidationState, supportsValidation };
