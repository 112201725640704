// eslint-env es6

import { openAlertOkAsync } from '@pressreader/popups-alert';
import { localizedString } from '@pressreader/resources';
import { purchase } from 'purchase/nd.purchase';

export function archivedIssueBuyingRequired(data) {
    const requestedContent = {};

    if (data.Parameters) {
        requestedContent.articleId = data.Parameters.articleId;
        requestedContent.issueId = data.Parameters.issueId;
    }
    return purchase(requestedContent);
}

export function issueIsInArchiveHandler() {
    openAlertOkAsync({ message: localizedString('ContentAccessDeniedException.IssueIsInArchive.Text'), isHtml: true });
    return { skipRetry: true };
}
