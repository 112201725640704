/* eslint-env es6 */

'use strict';

/**
 * Layout rectangle
 */
export default class Rectangle {
    constructor(left, top, width, height) {
        this.left = left || 0;
        this.top = top || 0;
        this.width = width || 0;
        this.height = height || 0;
    }

    clone() {
        return new Rectangle(this.left, this.top, this.width, this.height);
    }

    get right() {
        return this.left + this.width;
    }
    get bottom() {
        return this.top + this.height;
    }

    /**
     * Locates rectangle to the position
     * @param {Number} left - left
     * @param {Number} top - top
     * @returns {Rectangle}
     */
    locate(left, top) {
        this.left = left;
        this.top = top;
        return this;
    }

    resize(width, height) {
        this.width = width;
        this.height = height;
        return this;
    }
}
