import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { IEvent } from './events';

function ofType<T extends IEvent>(...allowedTypes: string[]) {
    return filter((action: IEvent): action is T => allowedTypes.some(type => type === action.type));
}

class EventBus<V = IEvent> extends Observable<V> {
    constructor() {
        super();

        this.source = new Subject<V>();
    }

    send(action: V) {
        (this.source as Subject<V>).next(action);
    }

    ofType<V2 extends V = V>(...allowedTypes: string[]): Observable<V2> {
        return this.pipe(ofType<any>(...allowedTypes) as any);
    }
}

const eventbus = new EventBus<IEvent>();

export { eventbus };
