import { call, put, race, select, take, takeEvery } from 'redux-saga/effects';

import { OperationStatus } from '@pressreader/appstore';
import { ExtractReturnType } from '@pressreader/types';

import { getConfig } from '../services';

import {
    configChanged,
    configExtended,
    configLoadBegan,
    configLoadDone,
    configLoadFailed,
    extendConfigAction,
    forceConfigLoadDoneAction,
    loadConfigAction,
} from './actions';
import { selectConfig, selectStatus } from './selectors';

function* loadConfig() {
    const status: ReturnType<typeof selectStatus> = yield select(selectStatus);
    if (status === OperationStatus.Pending) {
        return;
    }

    yield put(configLoadBegan());

    if (!getConfig) {
        yield put(configLoadFailed());
        return;
    }

    try {
        const { config, cancel }: { config: ExtractReturnType<typeof getConfig>; cancel: ReturnType<typeof forceConfigLoadDoneAction> } = yield race({
            config: call(getConfig),
            cancel: take(forceConfigLoadDoneAction),
        });
        if (cancel) {
            // May be called by the native adapter when switching to offline mode.
            yield put(configLoadDone({ config: {} }));
        } else {
            yield put(configLoadDone({ config }));
        }
        yield put(configChanged());
    } catch (e) {
        yield put(configLoadFailed());
    }
}

function* extendConfig(action: ReturnType<typeof extendConfigAction>) {
    const config: ReturnType<typeof selectConfig> = yield select(selectConfig);
    const newConfig = { ...config };
    for (const key of Object.keys(action.payload.values)) {
        const newValue = action.payload.values[key];
        const value = config[key];
        if (value === undefined || value === null || typeof value !== 'object' || typeof newValue !== 'object') {
            newConfig[key] = newValue;
        } else {
            newConfig[key] = { ...value, ...newValue };
        }
    }
    yield put(configExtended({ config: newConfig }));
    yield put(configChanged());
}

export function* saga() {
    yield takeEvery(loadConfigAction, loadConfig);
    yield takeEvery(extendConfigAction, extendConfig);
}
