// eslint-env es6

import { isString } from 'lodash';
import { nativeTemplateEngine as NativeTemplateEngine } from 'knockout';
import { templates } from 'deprecated/nd.templates';

/**
 * The template source that fetch templates supplied from external resource.
 * [see spec details https://github.com/knockout/knockout/blob/master/src/templating/templateSources.js]
 */
class ExternalResourceTemplateSource {
    constructor(templateName) {
        this.templateName = templateName;

        this.templateData = {};
        this.template = null;
    }

    /**
     * Get/set a value accosiated with template.
     * @param {String} key
     * @param {*} value
     */
    data(key, value) {
        if (arguments.length === 1) {
            return this.templateData[key];
        }

        this.templateData[key] = value;
        return undefined;
    }

    /**
     * Get a template text.
     * @returns {String}
     */
    text() {
        if (arguments.length === 1) {
            throw new Error("ExternalResourceTemplateSource does't support write operation");
        }

        if (!this.template) {
            this.template = templates.process(this.templateName, this.templateData);
        }

        return this.template;
    }
}

/**
 * Template engine that extends the default knockout's implementation with ability to load templates supplied from external resource.
 *
 * Execution steps:
 *    1. search a template registered via knockout
 *    2. look up a template supplied from external resource
 *    3. throw exception if not found any template.
 *
 * Example: "<div data-bind="template: { name: 'v7.Client.SignIn', data: signinModel }"></div>",
 *          "<!-- ko template: { name: 'v7.Client.SignIn', data: signinModel } --><!-- /ko -->".
 *
 * [see base class ref https://github.com/knockout/knockout/blob/master/src/templating/native/nativeTemplateEngine.js]
 */
class ExternalTemplateEngine extends NativeTemplateEngine {
    /**
     * Make a template source.
     * @param {String} template - The text of the template you should render or file name of template supplied from external resource.
     * @param {Object} templateDocument
     * @returns {Object} - The object should follow spec https://github.com/knockout/knockout/blob/master/src/templating/templateSources.js.
     */
    makeTemplateSource(template, templateDocument) {
        try {
            return super.makeTemplateSource(template, templateDocument);
        } catch (err) {
            // native engine throws an error if couldn't find a template
        }

        if (isString(template)) {
            if (templates.getTemplate(template)) {
                return new ExternalResourceTemplateSource(template);
            }
        }

        throw new Error(`Unknown template type: ${template}`);
    }
}

export default ExternalTemplateEngine;
