import { PublicationKey } from './publicationkey';
import { IssueKey } from './issuekey';

class UniversalArticleKey {
    static fromPublication(publicationId: number, articleNumber: number) {
        return new PublicationKey(publicationId, articleNumber);
    }

    static fromIssue(issueId: number, articleNumber: number) {
        return new IssueKey(issueId, articleNumber);
    }

    static parse(articleId: number) {
        if (PublicationKey.isValid(articleId)) {
            return PublicationKey.parse(articleId);
        }

        if (IssueKey.isValid(articleId)) {
            return IssueKey.parse(articleId);
        }

        throw new TypeError(`Article ID ${articleId} is not valid`);
    }

    static isValid(articleId: number) {
        return PublicationKey.isValid(articleId) || IssueKey.isValid(articleId);
    }
}

export { UniversalArticleKey };
