/* eslint-env es6 */

'use strict';

import { noop } from 'lodash';
import * as $ from 'jquery';
import { getElementNodeAsync } from 'ko/nd/template.loader';
import { applyBindings, utils as koUtils, tasks as koTasksQueue } from 'knockout';

/**
 * Generate a generic knockout binding definition which asynchronously loads a template by given name
 * and adds the first element node to the element the binding is applied to.
 * The definition can be easily customized: DOM manipulation, context generating and others might be overwritten if needed.
 *
 * This generator has a potential to serve as a base for bindings which share logic of
 * adding some HTML with ko bindings to the node the binding is applied to.

 * @param {Object} params   Binding configuration object.
 * @param {function} params.getDescendantContext    Function to create a binding context for the child node being added.
 *                                                  Called after the template is loaded.
 * @param {function} params.inject  Function that performs actual DOM manipulation.
 *                                  By default it's child append.
 * @param {function} params.dispose Function called on element disposing. Should be specified if custom disposing logic is needed.
 * @return {Object} Knockout binding definition.
 */
function inserterBindingDefinition(params = {}) {
    const { templateName, getDescendantContext = () => ({}), inject = (n, p) => p.appendChild(n), dispose = noop } = params;
    if (templateName === undefined) {
        throw new Error('Template name is required');
    }

    return {
        init(element, valueAccessor, allBindings, viewModel, bindingContext) {
            let el = element;
            getElementNodeAsync(templateName).then(node => {
                // Playing around jQuery promises not being asynchronous if underlying promise is already resolved.
                // This is needed to avoid double binding.
                koTasksQueue.schedule(() => {
                    // Element the binding is applied to was disposed before the template has been loaded.
                    if (!el) {
                        return;
                    }

                    const context = getDescendantContext(element, valueAccessor, allBindings, viewModel, bindingContext);
                    inject(node, el);
                    applyBindings(context, node);
                });
            });

            koUtils.domNodeDisposal.addDisposeCallback(element, () => {
                dispose(el);
                el = undefined;
            });
        },
    };
}

export const BINDING_NAME = 'reset';

/**
 * Register "reset" binding.
 * The binding provides common functionality of displaying an "x" icon to the side of the input or span
 * clicking on which usually performs clearing or removing current value.
 * The binding value must be a function to be assigned as a handler of "x" icon click.
 * In this terms the binding is similar to "click" and such bindings.
 *
 * @example
 * ``` HTML
 *
 * <span class="formbox-row" data-bind="reset: clear">
 *  <span class="formbox-in"><span data-bind="text: text"></span></span>
 * </span>
 *
 * ``` JavaScript
 *
 * const value = ko.observable('some text value');
 * const model = {
 *  clear() {
 *      value(null);
 *  },
 *  text: value,
 * }
 *
 */
export default function registerResetBinding(koInstance) {
    if (koInstance.bindingHandlers[BINDING_NAME]) {
        // eslint-disable-next-line no-console
        console.warn(`KO binding ${BINDING_NAME} already registered`);
        return;
    }

    koInstance.bindingHandlers[BINDING_NAME] = inserterBindingDefinition({
        templateName: 'v7.Client.Reset.ko.Binding',
        getDescendantContext: (element, valueAccessor, allBindings, viewModel, bindingContext) => {
            const ctx = {
                activate(data, event) {
                    valueAccessor().call(viewModel, bindingContext.$data, event);
                },
            };

            return ctx;
        },
        inject: (node, parent) => {
            const el = $(parent);
            el.addClass('has-reset').append(node);
        },
    });
}
