import _provider from 'nd.imagesource.provider';
import 'nd.core';
import { getPublication } from '@pressreader/src/catalog/services/catalog';

export default (function () {
    function getImageScaleForWidth(img, width, maxScale) {
        if (width > 0 && img.Width > 0) {
            var scale = (width * 100) / img.Width;
            return Math.min(scale, maxScale);
        }
        return 0;
    }

    function getImageScaleForHeight(img, height, maxScale) {
        if (height > 0 && img.Height > 0) {
            var scale = (height * 100) / img.Height;
            return Math.min(scale, maxScale);
        }
        return 0;
    }

    return ($.nd.imageSource = {
        getPictureUrlByIssueForWidth: function (issue, uid, key, imageWidth, requiredWidth) {
            var scale = Math.floor((requiredWidth * 100) / imageWidth);
            return this.getPictureUrlByIssueForScale(issue, uid, key, scale);
        },
        getPictureUrlByIssueForHeight: function (issue, uid, key, imageHeight, requiredHeight) {
            var scale = Math.floor((requiredHeight * 100) / imageHeight);
            return this.getPictureUrlByIssueForScale(issue, uid, key, scale);
        },
        getPictureUrlByIssueForSize: function (issue, uid, key, imageSize, requiredSize) {
            var imageWidth = imageSize.width || imageSize.Width;
            var requiredWidth = requiredSize.width || requiredSize.Width;
            var imageHeight = imageSize.height || imageSize.Height;
            var requiredHeight = requiredSize.height || requiredSize.Height;
            var scaleWidth = Math.floor((requiredWidth * 100) / imageWidth);
            var scaleHeight = Math.floor((requiredHeight * 100) / imageHeight);
            return this.getPictureUrlByIssueForScale(issue, uid, key, Math.min(scaleWidth, scaleHeight));
        },
        getPictureUrlByIssueForScale: function (issue, uid, key, scale, ver) {
            if (issue.issue) {
                issue = issue.issue;
            }
            return _provider.getPictureUrlByIssueForScale(issue, uid, key, scale, ver);
        },
        getThumbUrlByIssueForWidth: function (issue, width, pageNumber, supportRetina) {
            if (issue.issue) {
                issue = issue.issue;
            }
            return _provider.getThumbUrlByIssueForWidth(issue, width, pageNumber, supportRetina);
        },
        getFirstPageThumbUrlByIssueForWidth: function (issue, width, pageNumber, supportRetina) {
            if (issue.issue) {
                issue = issue.issue;
            }
            return _provider.getFirstPageThumbUrlByIssueForWidth(issue, width, pageNumber, supportRetina);
        },
        getThumbUrlByIssueForHeight: function (issue, height, pageNumber, supportRetina) {
            if (issue && issue.issue) {
                issue = issue.issue;
            }
            return _provider.getThumbUrlByIssueForHeight(issue, height, pageNumber, supportRetina);
        },
        getThumbUrlByCidForHeight: async function (cid, height) {
            if (cid) {
                return await getPublication(cid).then(publication => {
                    return this.getThumbUrlByIssueForHeight(publication.latestIssue.key, height);
                });
            }

            return null;
        },
        getThumbUrlForLandingPage: function (cid, height) {
            return `${this.getThumbUrlByCidForHeight(cid, height)}&timestamp=${+new Date()}`;
        },
        getThumbUrlByCidAndDateForHeight: function (cid, date, height, supportRetina) {
            return _provider.getThumbUrlByCidAndDateForHeight(cid, date, height, 1, supportRetina);
        },
        getThumbUrlByCidAndDateForWidth: function (cid, date, width, pageNumber) {
            return _provider.getThumbUrlByCidAndDateForWidth(cid, date, width, pageNumber);
        },
        getThumbUrlForScale: function (issue, scale, pageNumber, ver) {
            return _provider.getThumbUrlForScale(issue, scale, pageNumber, ver);
        },
        getPageImageUrlByPageInfo: function (issue, pageInfo, fitToSize) {
            return this.getPageImageUrl(issue, pageInfo.pageNumber, pageInfo, fitToSize);
        },
        getPageImageUrl: function (issue, pageNumber, pageSize, fitToSize, layer) {
            return _provider.getPageImageUrl(issue, pageNumber, pageSize, fitToSize, layer);
        },
        getPageImageScale: function (pageNumber, pageSize, fitToSize) {
            return _provider.getPageImageScale(pageNumber, pageSize, fitToSize);
        },
        getThumbUrlByRegionKey: function (regionKey, file, scale, ver) {
            return _provider.getThumbUrlByRegionKey(regionKey, file, scale, ver);
        },
        getFirstImage: function (images) {
            // find first image
            var firstImage = null;
            if (images) {
                var firstImageWidth = 600;
                var imagesHeight = 120;

                var sizeScaleMin = 0.7;
                var sizeScaleMax = 2;

                var MaxImageScale = 400;

                for (var i = 0; i < images.length; i++) {
                    var img = images[i];

                    if (img.Width <= 10 || img.Height <= 10 || img.Height / img.Width > 10 || img.Width / img.Height > 10) {
                        continue;
                    }

                    var sizeScale = img.Width / img.Height;
                    if (sizeScale > sizeScaleMin && sizeScale < sizeScaleMax) {
                        var imageScale = getImageScaleForWidth(img, firstImageWidth, MaxImageScale);
                        var imageScale2 = getImageScaleForHeight(img, imagesHeight, MaxImageScale);

                        if (imageScale <= 0 || imageScale2 <= 0) {
                            continue;
                        }

                        if (firstImage == null) {
                            firstImage = img;
                            continue;
                        } else {
                            if (img.Width > firstImage.Width || img.Height > firstImage.Height) {
                                firstImage = img;
                                continue;
                            }
                        }
                    }
                }
            }

            return firstImage;
        },
    });
})();
