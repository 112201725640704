import { takeEvery as originalTakeEvery } from 'redux-saga/effects';

import { ActionCreator, IAction } from '../models';

type TakeEvery = <TPayload, TResult>(
    actionCreator: ActionCreator<TPayload>,
    handler: (action: IAction<TPayload>) => TResult,
) => ReturnType<typeof originalTakeEvery>;

const takeEvery = originalTakeEvery as TakeEvery;

export { takeEvery };
