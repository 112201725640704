import { observable } from 'knockout';
import { debounce } from 'lodash';
import { Subject } from 'rxjs';

import { EXTERNAL_AUTH_ERROR } from '@pressreader/authentication-types';
import ndDialog from '@pressreader/src/nd.ui.dialog';
import { DialogStatus } from '@pressreader/src/nd.ui.dialogstatus';

import res from 'nd.res';
import { doSignInExternal } from 'authentication/nd.authentication.service';
import { getExternalSystems, socialNetworkSignInAllowed } from 'signin/signin.config.service';

const TEMPLATE_NAME = 'v7.Client.Dialogs.Signin.LoginRadius';

export enum State {
    Signin,
    Signup,
    ForgotPassword,
    ResetPassword,
}

class DialogViewModel {
    public state = State;
    public header: KnockoutObservable<State>;
    public currentState: KnockoutObservable<State>;
    public errorMessage: KnockoutObservable<string>;
    public dialogCss: KnockoutComputed<string>;
    public showSocialNetwork: KnockoutComputed<boolean>;
    public externalSystems: any;
    public signInExternal: any;

    private readonly resMapping = {
        [State.Signin]: 'Dialogs.Signin.Signin',
        [State.Signup]: 'Dialogs.Signup.Email',
        [State.ForgotPassword]: 'Dialogs.Signin.PasswordRecovery',
        [State.ResetPassword]: 'Dialogs.Signin.PasswordRecovery',
    };
    private readonly dialog: any;

    constructor(dialog: any) {
        this.dialog = dialog;
        this.header = observable();
        this.currentState = observable();
        this.errorMessage = observable();
        this.externalSystems = getExternalSystems();
        this.showSocialNetwork = ko.computed(() => socialNetworkSignInAllowed() && this.externalSystems.length > 0);

        this.signInExternal = debounce(
            (name: string) => {
                doSignInExternal({ system: name, enableAutologin: true })
                    .then(() => this.close())
                    .catch((error: any) => {
                        if (!error || error.code === EXTERNAL_AUTH_ERROR.CANCELLED) {
                            return Promise.reject();
                        }

                        this.errorMessage(error.message || 'Unknown error');
                        setTimeout(() => this.resize(), 0);
                        return Promise.reject(error);
                    });
            },
            300,
            { leading: false, trailing: true },
        );

        this.currentState.subscribe((newState: State) => {
            this.errorMessage('');
            this.header(res.val(this.resMapping[newState]) || '');
            setTimeout(() => this.resize(), 0);
        });
    }

    public back(): void {
        if (this.currentState() === State.Signin) {
            this.cancel();
        } else {
            this.currentState(State.Signin);
        }
    }

    public resize(): void {
        this.dialog.resize();
    }

    public cancel(): void {
        this.errorMessage('');
        this.dialog.hide(DialogStatus.Cancel);
    }

    public close(): void {
        this.errorMessage('');
        this.dialog.hide(DialogStatus.Close);
    }

    public setState(newState = State.Signin): void {
        this.currentState(newState);
    }
}

export class LoginRadiusDialog {
    private dialog: any;
    private dialogModel: DialogViewModel;
    private dialogShowed: Subject<void>;
    private dialogClosed: Subject<DialogStatus>;

    constructor() {
        this.dialog = ndDialog.createInstance();
        this.dialogModel = new DialogViewModel(this.dialog);
        this.dialogShowed = new Subject<void>();
        this.dialogClosed = new Subject<DialogStatus>();
    }

    get onShow() {
        return this.dialogShowed.asObservable();
    }

    get onClose() {
        return this.dialogClosed.asObservable();
    }

    set errorMessage(value: string) {
        this.dialogModel.errorMessage(value);
    }

    set state(value: State) {
        this.dialogModel.setState(value);
    }

    public close() {
        this.dialog.hide(DialogStatus.Close);
    }

    public show() {
        this.dialog
            .show({
                templateName: TEMPLATE_NAME,
                model: this.dialogModel,
            })
            .onShow(() => this.dialogShowed.next())
            .onHide((_, dialogStatus) => this.dialogClosed.next(dialogStatus));
    }

    public resize() {
        this.dialog.resize();
    }
}
