// eslint-env es6
import api from 'nd.services.api';
import { memoize } from 'lodash';

const serviceBasePath = 'v1/addresses';

/**
 * @typedef Country
 * @type Object
 * @property {String} code - country code (ISO 3166 ALPHA2)
 * @property {String} name - country name in a language of current user locale
 */

/**
 * Gets list of countries supported by system in addresses.
 *
 * @returns {Promise.<Array.<Country>>}
 */
function _getCountries() {
    return api.get(serviceBasePath, 'countries');
}

const getCountries = memoize(_getCountries);

/**
 * @typedef Region
 * @type Object
 * @property {String} code - region code
 * @property {String} name - region name in a language of current user locale
 */

/**
 * Gets list of regions for given country.
 *
 * @returns {Promise.<Array.<Region>>}
 */
function _getRegions(countryCode) {
    if (!countryCode) {
        throw new Error('countryCode must not be empty');
    }
    return api.get(serviceBasePath, `countries/${countryCode}/regions`);
}

const getRegions = memoize(_getRegions);

export { getCountries, getRegions };
