import { httpDelete, httpGet, httpPost, httpPut } from '@pressreader/services';

import { ExternalProfileDto } from './types';

const serviceBasePath = 'profile/externalsystems';

/**
 * Gets list of external systems associated with current user
 *
 */
function getExternalProfiles(): Promise<ExternalProfileDto> {
    return httpGet(serviceBasePath);
}

/**
 * Deletes external profile associated with current user
 *
 * @param provider: String - name of provider (e.g. 'facebook', 'twitter', 'bell' etc)
 * @returns Promise
 */
function unlinkExternalProfile(provider: string) {
    if (!provider) {
        throw new Error('provider required');
    }

    return httpDelete<void>(serviceBasePath, provider, { disableRetry: true });
}

/**
 * Changes publicity of external profile associated with current user
 *
 * @param provider - name of provider (e.g. 'facebook', 'twitter', 'bell' etc)
 * @param isPublic - indicates whether external profile should be public
 * @returns Promise
 */
function changeExternalProfilePublicity(provider: string, isPublic: boolean) {
    if (!provider) {
        throw new Error('provider required');
    }
    if (typeof isPublic !== 'boolean') {
        throw new TypeError('isPublic parameter has to be boolean');
    }

    return httpPut<void>(serviceBasePath, `${provider}/isPublic`, JSON.stringify(isPublic), {
        disableRetry: true,
        // single value is sent to the server side (true|false),
        // so to let server know how to treat it, we should override default
        // 'application/x-www-form-urlencoded', which is pretty good for sending key=value pairs,
        // with 'application/json'
        contentType: 'application/json',
    });
}

/**
 * Updates social profile with external profile data
 *
 * @param provider name of provider (e.g. 'facebook', 'twitter', 'bell' etc)
 * @returns Promise
 */
function updateSocialProfile(provider: string) {
    if (!provider) {
        throw new Error('provider required');
    }

    return httpPost<void>(serviceBasePath, `${provider}/updateSocialProfile`, { disableRetry: true });
}

/**
 * Enables sign-in with external account
 *
 * @param {provider} provider:String - name of provider (e.g. 'facebook', 'twitter', 'bell' etc)
 * @returns Promise
 */
function enableExternalSignIn(provider: string) {
    if (!provider) {
        throw new Error('provider required');
    }

    return httpPost<void>(serviceBasePath, `${provider}/enableSignIn`, null, { disableRetry: true });
}

/**
 * Disables sign-in with external account
 *
 * @param {provider} provider:String - name of provider (e.g. 'facebook', 'twitter', 'bell' etc)
 * @returns Promise
 */
function disableExternalSignIn(provider: string) {
    if (!provider) {
        throw new Error('provider required');
    }

    return httpPost<void>(serviceBasePath, `${provider}/disableSignIn`, null, { disableRetry: true });
}

/**
 * Unlinks subscription associated with external account
 *
 * @param provider - name of provider (e.g. 'facebook', 'twitter', 'bell' etc)
 * @returns Promise
 */
function unlinkExternalSubscription(provider: string) {
    if (!provider) {
        throw new Error('provider required');
    }

    return httpPost<void>(serviceBasePath, `${provider}/unlinksubscription`, null, { disableRetry: true });
}

/**
 * Update profile link.
 */
function changeExternalProfileLink(provider: string, profileLink: string): Promise<{ status: number; profileLink: string }> {
    if (!provider) {
        throw new Error('provider required');
    }
    return httpPut(serviceBasePath, `providers/${provider}/links`, JSON.stringify(profileLink), {
        disableRetry: true,
        contentType: 'application/json',
    });
}

function removeExternalProfileLink(provider: string) {
    if (!provider) {
        throw new Error('provider required');
    }

    return httpDelete<void>(serviceBasePath, `providers/${provider}/links`, { disableRetry: true });
}

function createEmailExternalProfile(email: string, sponsorId: unknown) {
    if (!email) {
        throw new Error('email not specified');
    }
    if (!sponsorId) {
        throw new Error('sponsorId not specified');
    }

    return httpPost<void>(serviceBasePath, 'email', { email, sponsorId }, { disableRetry: true });
}

function resendActivationLink(email: string) {
    if (!email) {
        throw new Error('email not specified');
    }

    return httpPost<void>(serviceBasePath, 'activation/link/resend', undefined, { disableRetry: true });
}

function validateSponsoredEmail(email: string, sponsorId: unknown) {
    if (!email) {
        return Promise.reject(new Error('email not specified'));
    }
    if (!sponsorId) {
        return Promise.reject(new Error('sponsorId not specified'));
    }

    return httpPost<void>(serviceBasePath, 'validation/email', { email, sponsorId }, { disableRetry: true });
}

export {
    getExternalProfiles,
    unlinkExternalProfile,
    changeExternalProfilePublicity,
    updateSocialProfile,
    enableExternalSignIn,
    disableExternalSignIn,
    unlinkExternalSubscription,
    changeExternalProfileLink,
    removeExternalProfileLink,
    createEmailExternalProfile,
    resendActivationLink,
    validateSponsoredEmail,
};
