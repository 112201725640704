import { find, forEach, isArray } from 'lodash';

import { extractCid } from '@pressreader/content-utils';

import $ndApi from 'nd.services.api';
import $ndConfig from '@pressreader/src/deprecated/nd.config';
import { Deferred } from '@pressreader/src/promise/deferred';
import 'nd.core';

var issueRestrictions = {},
    contentRestrictionsDefer;

$ndConfig.onConfigUpdated(function onConfigUpdated() {
    var reloadMsg = 'Config data reloaded';

    if (contentRestrictionsDefer) {
        // if it's been resolved already, then this call will be ignored
        // otherwise all current subscribes that are waiting for this promise result
        // will be notified that no result is available at the moment they've requested it.
        contentRestrictionsDefer.reject(new Error(reloadMsg));
    }

    forEach(issueRestrictions, function (issueRestriction) {
        issueRestriction.reject(reloadMsg);
    });

    issueRestrictions = {};
    contentRestrictionsDefer = null;
});

function loadContentRestrictions() {
    if (!contentRestrictionsDefer) {
        contentRestrictionsDefer = new Deferred();

        $ndApi
            .get('contentRestrictions')
            .then(function (contentRestrictions) {
                forEach(contentRestrictions, function (restriction) {
                    restriction.ruleType = restriction.ruleType.toLowerCase();
                });

                return contentRestrictions;
            })
            .then(contentRestrictionsDefer.resolve, contentRestrictionsDefer.reject);
    }

    return contentRestrictionsDefer.promise();
}

function loadIssueRestrictions(issueId) {
    if (!issueId) return Promise.reject('issueId is required.');

    if (!issueRestrictions[issueId]) {
        issueRestrictions[issueId] = new Deferred();

        $ndApi
            .get('contentRestrictions', 'issues', { issues: issueId })
            .then(function onIssueRestrictionsLoaded(response) {
                if (isArray(response) && response.length) {
                    var issueResponse = response[0];

                    return issueResponse.restrictions;
                }

                return response;
            })
            .then(issueRestrictions[issueId].resolve, issueRestrictions[issueId].reject);
    }

    return issueRestrictions[issueId].promise();
}

function setIssueRestrictions(issueId, restrictions) {
    if (!issueRestrictions[issueId]) {
        issueRestrictions[issueId] = new Deferred();
    }

    issueRestrictions[issueId].resolve(restrictions);
}

function isRestricted(restrictionType, cid, issueId) {
    restrictionType = restrictionType.toLowerCase();

    if (!issueId && !cid) return Promise.reject('issueId or cid required.');

    if (!cid) {
        cid = extractCid(issueId);
    }

    return loadContentRestrictions()
        .then(function contentRestrictionsLoaded(contentRestrictions) {
            // check cid first
            var entry = find(contentRestrictions, { ruleType: restrictionType });

            if (entry && entry.cids?.includes(cid)) {
                return true;
            }

            // cid check failed, trying to validate issue restrictions
            return loadIssueRestrictions(issueId).then(function onIssueRestrictionsLoaded(issueRestrictions) {
                var issueRestriction = find(issueRestrictions, function (_v, k) {
                    return k.toLowerCase() === restrictionType;
                });

                return !!issueRestriction;
            });
        })
        .catch(function onRestrictionValidationFail(error) {
            console.warn('contentRestriction: checking restriction resulted to an error', error);
            return false;
        });
}

function isAllowed(restrictionType, cid, issueId) {
    return isRestricted(restrictionType, cid, issueId).then(function onRestrictionStatusDetermined(isRestricted) {
        return !isRestricted;
    });
}

function getRestrictions(restrictionType) {
    return loadContentRestrictions()
        .then(function (contentRestrictions) {
            return restrictionType ? find(contentRestrictions, { ruleType: restrictionType.toLowerCase() }) : contentRestrictions;
        })
        .catch(function () {
            return false;
        });
}

export { isRestricted, isAllowed, getRestrictions, setIssueRestrictions };
