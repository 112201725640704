import sortBy from 'lodash/sortBy';

import { IReplicaPage } from '@pressreader/content-types';
import { ISize } from '@pressreader/geometry';
import { NullableFields } from '@pressreader/types';

/** Maximum height different between spread pages. */
const SpreadPageHeightDifference = 15;
const MaxAllowedImageScale = 3;

interface PageSize {
    size: ISize;
    tiled: boolean;
    scale: number;
}

function calculateScale(size: ISize, requiredSize: NullableFields<ISize>): number {
    let wScale: number | null = null;
    let hScale: number | null = null;

    if (requiredSize.width) {
        wScale = Math.floor((requiredSize.width / size.width) * 100) / 100;
    }

    if (requiredSize.height) {
        hScale = Math.floor((requiredSize.height / size.height) * 100) / 100;
    }

    if (wScale === null || hScale === null) {
        return wScale || hScale || 0;
    }

    return Math.min(wScale, hScale);
}

function calculatePageSizes(
    page: IReplicaPage,
    meta: { nonTiledSizes: NullableFields<ISize, 'width'>[]; tiledSizes: ISize[]; maxPageSize?: ISize | null },
): PageSize[] {
    let dpr = window.devicePixelRatio > 1 ? 1.6 : window.devicePixelRatio; // NOTE: do not use dpr until imageserver supports better quality images

    const maxImageScale = meta.maxPageSize ? calculateScale(page.size, meta.maxPageSize) : 0;

    let sizes = meta.nonTiledSizes.reduce<PageSize[]>((sizes, size) => {
        const imageScale = calculateScale(page.size, size);
        const scale = imageScale / dpr;

        if ((maxImageScale === 0 || imageScale <= maxImageScale) && imageScale <= MaxAllowedImageScale) {
            sizes.push({
                size: { width: Math.floor(page.size.width * scale), height: Math.floor(page.size.height * scale) },
                tiled: false,
                scale: imageScale,
            });
        }

        return sizes;
    }, []);

    dpr = 1; // set dpr to 1 for tiled sizes

    sizes = meta.tiledSizes.reduce<PageSize[]>((sizes, size) => {
        const imageScale = calculateScale(page.size, size);
        const scale = imageScale / dpr;

        if ((maxImageScale === 0 || imageScale <= maxImageScale) && imageScale <= MaxAllowedImageScale) {
            sizes.push({
                size: { width: Math.floor(page.size.width * scale), height: Math.floor(page.size.height * scale) },
                tiled: true,
                scale: imageScale,
            });
        }

        return sizes;
    }, sizes);

    return sortBy(sizes, s => s.size.height);
}

function findClosestByHeight(height: number, pageSizes: PageSize[]) {
    let result: PageSize | null = null;
    let dResult = Number.MAX_VALUE;

    for (const p of pageSizes) {
        const d = Math.abs(p.size.height - height);
        if (d < dResult) {
            dResult = d;
            result = p;
        }
    }

    if (result?.tiled || dResult <= SpreadPageHeightDifference) {
        return result;
    }

    return null;
}

/**
 * Adjusts left and right pagesizes by height
 */
function adjustSpreadPageSizes(left: PageSize[], right: PageSize[]): { left: PageSize[]; right: PageSize[] } {
    const resultLeft: PageSize[] = [];
    const resultRight: PageSize[] = [];

    if (left.length === right.length || left.length < right.length) {
        left.forEach(leftSize => {
            const rightSize = findClosestByHeight(leftSize.size.height, right);
            if (rightSize) {
                resultLeft.push(leftSize);
                resultRight.push(rightSize);
            }
        });
    } else {
        right.forEach(rightSize => {
            const leftSize = findClosestByHeight(rightSize.size.height, left);
            if (leftSize) {
                resultLeft.push(leftSize);
                resultRight.push(rightSize);
            }
        });
    }

    if (resultLeft.length === 0 || resultRight.length === 0) {
        return { left: [left[left.length - 1]], right: [right[right.length - 1]] };
    }

    return { left: resultLeft, right: resultRight };
}

function getMaxUnrestrictedPageScale(pageSize: ISize, maxUnrestrictedPageSize: ISize, availableSizes: NullableFields<ISize, 'width'>[]) {
    return availableSizes.reduce(
        (result, size) =>
            (size.width || 0) > maxUnrestrictedPageSize.width || size.height > maxUnrestrictedPageSize.height
                ? result
                : calculateScale(pageSize, size),
        0,
    );
}

export { calculateScale, calculatePageSizes, adjustSpreadPageSizes, getMaxUnrestrictedPageScale };
