import * as ko from 'knockout';
import * as $ from 'jquery';
        

        /*
         * Usage:
         * <div data-bind="nd-onTransitionEnd: $root.onElementCSSTransitionEnd">
         * </div>
         *
         * function onElementCSSTransitionEnd(element) {}
         * */
        function OnTransitionEnd() {
            this.update = function(element, valueAccessor, allBindingsAccessor) {
                var handler = valueAccessor();

                if(!$.isFunction(handler)) {
                    return;
                }

                $(element).bind('transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd', function() {
                    handler(element);
                });
            };
        }

        ko.bindingHandlers['nd-onTransitionEnd'] = new OnTransitionEnd();
    