// eslint-env es6

import * as ko from 'knockout';
import { assign, map } from 'lodash';
import BehaviourActionViewModel from 'subscriptions/viewmodels/behaviouraction.viewmodel';

class BehaviourCaseViewModel {
    constructor({ properties, actions }) {
        this.properties = assign({}, properties);
        this.actions = ko.observableArray(map(actions, actionDefinition => new BehaviourActionViewModel(actionDefinition)));
    }
}

export default BehaviourCaseViewModel;
