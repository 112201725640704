import auth0 from 'auth0-js';
import { Observable, Subject } from 'rxjs';

import config from '@pressreader/src/deprecated/nd.config';

import ndUser from 'nd.user';
import ndAuthentication from 'authentication/nd.authentication';

import { IIdentity } from './adapter';
import { ScreensetType } from './screenSets';

interface Auth0SSOConfig {
    enabled: boolean;
    clientId: string;
    domain: string;
    scope: string;
    audience: string;
    returnUrl: string;
    logoutUrl: string;
}

export class Auth0SSO implements IIdentity {
    private loginSuccess = new Subject<any>();
    private logoutSuccess = new Subject<void>();
    private api: auth0.WebAuth;
    private auth0Config: Auth0SSOConfig;

    getConfig(root: string): Auth0SSOConfig {
        return {
            enabled: config.get(`${root}.Enabled`),
            clientId: config.get(`${root}.ClientId`),
            domain: config.get(`${root}.Domain`) || config.get(`${root}.ApiUrl`),
            scope: config.get(`${root}.Scope`),
            audience: config.get(`${root}.Audience`),
            returnUrl: config.get(`${root}.returnUrl`),
            logoutUrl: config.get(`${root}.LogoutUrl`),
        };
    }

    isAuth0Authenticated(auth0Config: Auth0SSOConfig): Promise<boolean> {
        return new Promise((resolve, _) => {
            this.api.checkSession(
                {
                    audience: auth0Config.audience,
                    scope: auth0Config.scope,
                    responseType: 'token',
                    redirectUri: auth0Config.returnUrl,
                },
                function (err: unknown, authResult: unknown) {
                    if (!err && authResult) {
                        return resolve(true);
                    }
                    return resolve(false);
                },
            );
        });
    }

    async load(): Promise<void> {
        await config.loaded();

        this.auth0Config = this.getConfig('Authentication.Auth0SSO');
        if (!this.auth0Config || !this.auth0Config.enabled) {
            return Promise.reject();
        }

        this.api = new auth0.WebAuth({
            domain: this.auth0Config.domain,
            clientID: this.auth0Config.clientId,
        });

        if (!ndUser.isLogin()) {
            const result = await this.isAuth0Authenticated(this.auth0Config);
            if (result) {
                ndAuthentication.signIn();
            }
        }

        return Promise.resolve();
    }

    get enabled() {
        return true;
    }

    get onLoginSuccess() {
        return this.loginSuccess.asObservable();
    }

    get onModalClosed() {
        return new Observable<any>();
    }

    get onLogoutSuccess() {
        return this.logoutSuccess.asObservable();
    }

    signIn(): void {
        throw new Error('Not Implemented');
    }

    signOut() {
        this.logoutSuccess.next();
        return this.api.client.buildLogoutUrl({
            returnTo: this.auth0Config.logoutUrl,
            clientID: this.auth0Config.clientId,
        });
    }

    showScreenSet(screenType: ScreensetType): void {
        throw new Error('Not Implemented');
    }

    showInlineScreenSet(screenType: ScreensetType, containerSelector: string): void {
        throw new Error('Not Implemented');
    }
}
