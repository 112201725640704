import * as $ from 'jquery';
import * as _ from 'lodash';
import * as invariants from '@pressreader/src/nd.gestures.invariants';
import ndLoading from '@pressreader/src/shell/loading.indicator';
import 'nd.core';
import { Modernizr } from '@pressreader/modernizr';

var RequestAnimationFrameDebouncer = {
    debounce: function (func) {
        var lastArgs;
        var self;
        var requested = false;

        function requestImpl() {
            if (lastArgs) {
                func.apply(self, lastArgs);
                lastArgs = null;
                self = null;
            }
        }

        function flush() {
            requestImpl();
        }

        function onAnimationFrame() {
            requested = false;
            requestImpl();
        }

        function onevent() {
            self = this;
            lastArgs = arguments;
            if (!requested) {
                // requestAnimationFrame is not always return requestId (it is not implemented in old browsers)
                // so use bool flag to indicate we have active request
                requested = true;
                window.requestAnimationFrame(onAnimationFrame);
            }
        }

        onevent._flush = flush;

        return onevent;
    },
    flushBeforeExecute: function (func, debouncedFuncs) {
        return function () {
            for (var i = 0; i < debouncedFuncs.length; i++) {
                debouncedFuncs[i]._flush();
            }
            return func.apply(this, arguments);
        };
    },
};

function findId(touch) {
    var elm = touch;
    if (touch.target) {
        elm = touch.target;
    }
    //return elm.innerHTML;
    var id = elm.id || elm.className;
    while (!id && elm) {
        id = elm.id || elm.className;
        elm = elm.parentNode;
    }
    return id + '(#' + touch.identifier + ')';
}

var keyUpHandlers = {};
var keyPressHandlers = {};
var keyDownHandlers = {};
(function () {
    var binder = function (keyEventHandlers) {
        return function (event) {
            if (ndLoading.isVisible) {
                return;
            }

            for (var h in keyEventHandlers) {
                if (typeof keyEventHandlers[h] === 'function') {
                    keyEventHandlers[h](event);
                }
            }
        };
    };
    $(document).keyup(binder(keyUpHandlers));
    var keypress = binder(keyPressHandlers);
    var keydown = binder(keyDownHandlers);
    if ($.browser.msie) {
        $(document).keydown(keypress);
        $(document).keydown(keydown);
        /* workaround for Task 748:Bug: Keyboard navigation doesn't work in IE */
        $('body').click(function () {
            var $currentElement = $(document.activeElement);
            if ($currentElement.is(':input') || $currentElement.attr('contenteditable')) {
                return;
            }
            $(this).focus();
        });
    } else {
        $(document).keypress(keypress);
        $(document).keydown(keydown);
    }
})();

var _activeTouches = (function () {
    var _map = {};

    function add(instance, event) {
        var touches = event.changedTouches;
        for (var i = 0; i < touches.length; ++i) {
            var id = touches[i].identifier;
            if (id in _map) {
                var item = _map[id];
                if (instance._id in item.map) {
                    continue;
                }
                item.map[instance._id] = 1;
                item.instances.push(instance);
            } else {
                var item = (_map[id] = {
                    map: {},
                    instances: [instance],
                });
                item.map[instance._id] = 1;
            }
        }
    }

    function remove(event) {
        var touches = event.changedTouches,
            map = null,
            instances = null;
        for (var i = 0; i < touches.length; ++i) {
            var id = touches[i].identifier;
            if (id in _map) {
                var item = _map[id];
                delete _map[id];
                if (!i) {
                    // no need to remove duplicates
                    map = item.map;
                    instances = item.instances;
                } else
                    for (var j = 0; j < item.instances.length; ++j) {
                        var instance = item.instances[j];
                        if (instance._id in map) continue;
                        map[instance._id] = 1;
                        instances.push(instance);
                    }
            }
        }
        if (!instances) return false;
        for (var i = 0; i < instances.length; ++i) instances[i]._manualTouchEnd(event);
    }

    return {
        add: add,
        remove: remove,
    };
})();

function _evts(instance) {
    /// adds instance._namespace to each event name and returns a single string ready to be used with .on() or .bind()
    /// example: _evts(this, "click", "mouseup", "mousemove", "mousedown") ==> "click.gestures1 mouseup.gestures1 mousemove.gestures1 mousedown.gestures1"
    return Array.prototype.slice.call(arguments, 1).join('.' + instance._namespace + ' ') + '.' + instance._namespace;
}

var _longPressTimeThreshold = 500,
    _doubleTapTimeThreshold = 200,
    _distanceThreshold = 4;

var _gId = 0;

var _Gestures = ($.nd.gestures = function (elm) {
    this._id = ++_gId;
    this._namespace = 'gestures' + this._id;
    this._elm = elm;
    this._longPressTimeoutHandler = this._onLongPressTimeout.bind(this);
    this._doubleTapTimeoutHandler = this._onDoubleTapTimeout.bind(this);

    this._bindDomEvents();

    this._initRequestAnimationFrame();
});
$.extend(_Gestures.prototype, {
    _id: 0,
    _namespace: null,

    _elm: null,

    _touches: null,
    _touchesCount: 0,
    _pinch: null,
    _tap: null,

    _longPressTimeoutID: 0, // to track the time before triggering the long press event
    _longPressTimeStamp: 0,
    _doubleTapTimeoutID: 0, // to track the time between two consecutive taps
    _doubleTapTimeStamp: 0,

    _scrollListener: null,
    _keyUpListener: null,
    _keyPressListener: null,
    _keyDownListener: null,
    _singleTapListener: null,
    _singleTapConfirmedListener: null,
    _doubleTapListener: null,
    _flingListener: null,
    _pinchListener: null,
    _longPressListener: null,
    _contextMenuListener: null,
    _gesturesStartListener: null,
    _gesturesEndListener: null,

    _evtTracker: null,

    _bindDomEvents: function () {
        if (window.navigator.pointerEnabled || window.navigator.msPointerEnabled) {
            this._elm.css('touch-action', 'none').on(_evts(this, 'MSPointerDown', 'pointerdown'), this._msPointerDown.bind(this));
        } else {
            this._elm.on(_evts(this, 'touchstart'), this._touchStart.bind(this)).on(_evts(this, 'mousedown'), this._mouseDown.bind(this));
            if ($.browser.msie && $.browser.msie_mode < 9) {
                this._elm.on(_evts(this, 'dblclick'), this._mouseDoubleClick.bind(this));
            }
        }
        this._elm.on(_evts(this, 'contextmenu'), event => {
            if ($(event.target).data('allow-default')) {
                return true;
            }

            event.preventDefault();
            return false;
        });
        keyUpHandlers[this._id] = this._keyUp.bind(this);
        keyPressHandlers[this._id] = this._keyPress.bind(this);
        keyDownHandlers[this._id] = this._keyDown.bind(this);
    },
    _bind: function (name, listener) {
        var fullName = '_' + name + 'Listener';
        if (fullName in this) {
            var callbacks = this[fullName];
            if (callbacks) {
                callbacks.add(listener);
            } else {
                this[fullName] = new _Callbacks(fullName).add(listener);
            }
        }
    },
    _unbind: function (name, listener) {
        var fullName = '_' + name + 'Listener';
        if (fullName in this) {
            var callbacks = this[fullName];
            if (callbacks) {
                if (listener) {
                    callbacks.remove(listener);
                    if (!callbacks.count) {
                        callbacks.empty();
                        delete this[fullName];
                    }
                } else {
                    callbacks.empty();
                    delete this[fullName];
                }
            }
        }
    },
    _hasListeners: function () {
        var listeners = [
            this._scrollListener,
            this._keyUpListener,
            this._keyPressListener,
            this._keyDownListener,
            this._singleTapListener,
            this._singleTapConfirmedListener,
            this._doubleTapListener,
            this._flingListener,
            this._pinchListener,
            this._gesturesEndListener,
            this._longPressListener,
        ];

        for (var i = 0; i < listeners.length; i++) {
            if (listeners[i]) {
                return true;
            }
        }
        return false;
    },
    _start: function (event) {
        if (invariants.isIgnored(event)) {
            return false;
        }

        this._checkTimeouts(event);

        _activeTouches.add(this, event);

        _tracker.init(event);

        this._validateTouches(event);

        if (!this._touches) {
            this._touches = {};
            if (this._tap && this._doubleTapTimeoutID) {
                // should wait until single tap or double tap is confirmed
                clearTimeout(this._doubleTapTimeoutID);
                this._doubleTapTimeoutID = 0;
            } else {
                this._onGesturesStart();
            }
        }
        this._addTouches(event);

        if (this._touchesCount == 1) {
            // start timer to track taps
            this._setLongPressTimeout(event);
            if (!this.touches()[0].button()) {
                // highlight only if it's a touch or a left mouse button click
                this._highlight(event.target);
            }
        } else {
            this._unhighlight(true);
            if (this._longPressTimeoutID) {
                // it cannot be any tap
                clearTimeout(this._longPressTimeoutID);
                this._longPressTimeoutID = 0;
            }
            if (this._touchesCount == 2) {
                // start tracking pinch
                var touches = this.touches();
                this._pinch = new _Pinch(touches[0], touches[1]);
                this._onPinch(event);
            } else {
                // too many touches...
                this._pinch = null;
            }
        }
        if ($(event.target).data && $(event.target).data('allow-default')) {
            return true;
        }

        event.preventDefault();
        return false;
    },
    _initRequestAnimationFrame: function () {
        if (window.requestAnimationFrame) {
            this._onPinch = RequestAnimationFrameDebouncer.debounce(this._onPinch);
            this._onScroll = RequestAnimationFrameDebouncer.debounce(this._onScroll);
            this._onFling = RequestAnimationFrameDebouncer.debounce(this._onFling);
            this._onGesturesEnd = RequestAnimationFrameDebouncer.flushBeforeExecute(this._onGesturesEnd, [
                this._onPinch,
                this._onScroll,
                this._onFling,
            ]);
        }
    },
    _touchStart: function (event) {
        if (!this._touches) {
            $(document).on(_evts(this, 'touchmove'), this._touchMove.bind(this)).on(_evts(this, 'touchend'), this._touchEnd.bind(this));
        }

        this._start(event);
    },
    _touchMove: function (event) {
        this._checkTimeouts(event);

        _tracker.init(event);

        if (!this._touches) {
            return;
        }
        this._updateTouches(event);

        if (this._touchesCount == 2) {
            this._onPinch(event);
        } else if (this._touchesCount == 1) {
            var touch = this.touches()[0];
            if (touch.distance() > _distanceThreshold) {
                if (this._longPressTimeoutID) {
                    clearTimeout(this._longPressTimeoutID);
                    this._longPressTimeoutID = 0;
                }
                this._unhighlight(true);
            }
            if (!this._longPressTimeoutID) {
                if (this._tap) {
                    this._onSingleTapConfirmed();
                    this._tap = null;
                    this._onGesturesEnd();
                    this._onGesturesStart();
                }
                this._onScroll(touch);
            }
        }

        if ($(event.target).data && $(event.target).data('allow-move-default')) {
            return true;
        }

        if (event.type === 'touchend' && $(event.target).data && $(event.target).data('allow-default')) {
            // in case of _manualTouchEnd, do not prevent touchend event if there is allow-default attr set
            return true;
        }

        if (!$.browser.android) {
            // according to the source this should help avoiding possible problems in android: http://uihacker.blogspot.ca/2010/10/android-bug-miss-drag-as-we-are-waiting.html
            event.preventDefault();
            return false;
        }

        return true;
    },
    _touchEnd: function (event) {
        _activeTouches.remove(event);
        return $(event.target).data && $(event.target).data('allow-default');
    },
    _manualTouchEnd: function (event) {
        // first check if touch's position changed since last touchStart or touchMove.
        this._touchMove(event);

        _tracker.init(event);

        if (!this._touches) {
            return;
        }
        this._updateTouches(event);
        var newTouchesCount = this._touchesCount - event.changedTouches.length;

        this._unhighlight();

        if (newTouchesCount == 2) {
            this._removeTouches(event.changedTouches);
            var touches = this.touches();
            this._pinch = new _Pinch(touches[0], touches[1]);
            this._onPinch();
        } else {
            if (this._pinch) {
                this._pinch = null;
            }
            if (!newTouchesCount) {
                $(document).off(
                    _evts(
                        this,
                        'touchmove',
                        'touchend',
                        'mousemove',
                        'mouseup',
                        'MSPointerMove',
                        'pointermove',
                        'MSPointerUp',
                        'pointerup',
                        'MSPointerCancel',
                        'pointercancel',
                    ),
                );

                if (this._longPressTimeoutID) {
                    // single tap happened
                    clearTimeout(this._longPressTimeoutID);
                    this._longPressTimeoutID = 0;
                    this._onSingleTap();
                    if (this._tap) {
                        // double tap happened
                        this._onDoubleTap();
                        this._unhighlight(true);
                        this._tap = null;
                    } else {
                        // need to wait to find out if it's a double tap
                        this._tap = this.touches()[0];
                        this._setDoubleTapTimeout(event);
                    }
                } else if (this._touchesCount == 1) {
                    this._onFling(this.touches()[0]);
                }
                this._touches = null;
                this._touchesCount = 0;
                if (!this._tap) {
                    this._onGesturesEnd();
                }
            } else {
                this._removeTouches(event.changedTouches);
            }
        }

        if ($(event.target).data && $(event.target).data('allow-default')) {
            return true;
        }

        event.preventDefault();
        return false;
    },
    _keyUp: function (event) {
        this._onKeyUp(event);
    },
    _keyPress: function (event) {
        this._onKeyPress(event);
    },
    _keyDown: function (event) {
        this._onKeyDown(event);
    },
    _mouseDown: function (event) {
        $(document).on(_evts(this, 'mousemove'), this._mouseMove.bind(this)).on(_evts(this, 'mouseup'), this._mouseUp.bind(this));
        event.touches = event.changedTouches = [
            {
                clientX: event.clientX,
                clientY: event.clientY,
                screenX: event.screenX,
                screenY: event.screenY,
                pageX: event.pageX,
                pageY: event.pageY,
                target: event.target,
                // don't use event.button, it is wrong for old IE,
                // event.which normalizes event.button in jquery, (1|2|3)
                button: event.which - 1, //0|1|2, left, middle, right
                identifier: 0,
            },
        ];
        this._start(event);
    },
    _mouseMove: function (event) {
        // Make sure the left mouse button is pressed
        if (event.which != 1) {
            // mouse button was released outside window
            this._mouseUp(event);
            return;
        }

        event.touches = event.changedTouches = [
            {
                clientX: event.clientX,
                clientY: event.clientY,
                screenX: event.screenX,
                screenY: event.screenY,
                pageX: event.pageX,
                pageY: event.pageY,
                target: event.target,
                // don't use event.button, it is wrong for old IE,
                // event.which normalizes event.button in jquery, (1|2|3)
                button: event.which - 1, //0|1|2, left, middle, right
                identifier: 0,
            },
        ];
        this._touchMove(event);
    },
    _mouseUp: function (event) {
        event.touches = event.changedTouches = [
            {
                clientX: event.clientX,
                clientY: event.clientY,
                screenX: event.screenX,
                screenY: event.screenY,
                pageX: event.pageX,
                pageY: event.pageY,
                target: event.target,
                // don't use event.button, it is wrong for old IE,
                // event.which normalizes event.button in jquery, (1|2|3)
                button: event.which - 1, //0|1|2, left, middle, right
                identifier: 0,
            },
        ];
        this._touchEnd(event);
    },
    _mouseDoubleClick: function (event) {
        //handle doubleclick for ie, old ie has missing mousedown when doubleclick happened
        _tracker.init(event);
        event.touches = event.changedTouches = [
            {
                clientX: event.clientX,
                clientY: event.clientY,
                screenX: event.screenX,
                screenY: event.screenY,
                pageX: event.pageX,
                pageY: event.pageY,
                target: event.target,
                button: 0, // make sure _onDoubleTap will fire the event
                identifier: 0,
            },
        ];
        if (!this._touches) {
            this._touches = {};
            this._addTouches(event);
        } else {
            this._updateTouches(event);
        }

        this._tap = this.touches()[0];
        this._onDoubleTap();
        this._tap = null;

        if (this._longPressTimeoutID) {
            // single tap happened
            clearTimeout(this._longPressTimeoutID);
            this._longPressTimeoutID = 0;
        }
        if (this._doubleTapTimeoutID) {
            clearTimeout(this._doubleTapTimeoutID);
            this._doubleTapTimeoutID = 0;
        }
        this._touches = null;
        this._touchesCount = 0;
    },
    _msPointerDown: function (event) {
        if (!this._touches) {
            $(document)
                .on(_evts(this, 'MSPointerMove', 'pointermove'), this._msPointerMove.bind(this))
                .on(_evts(this, 'MSPointerUp', 'MSPointerCancel', 'pointerup', 'pointercancel'), this._msPointerUp.bind(this));
        }
        event.changedTouches = [event.originalEvent];
        event.originalEvent.identifier = event.originalEvent.pointerId;

        if (this._start(event)) {
            // this._start(event) returns true if 'allow-default' is specified for an element
            // IE specific: if .preventDefault() isn't called, images showed via image.gallery/gallery.puzzle (which have 'allow-default') can be drag-to-copy using a mouse (do not confuse with drag&drop)
            // (other browsers stop image from being dragged if mouseMove is prevented, which controlled with 'allow-move-default')
            // but, for example, in case of search input field, specifying 'ms-pointer-down-allow-default' allows selection of text with a mouse
            var msAllowDefault = $(event.target).data && $(event.target).data('ms-pointer-down-allow-default');
            if (!msAllowDefault) {
                event.originalEvent.preventDefault();
            }
        } else {
            event.originalEvent.preventDefault();
        }
    },
    _msPointerMove: function (event) {
        event.changedTouches = [event.originalEvent];
        event.originalEvent.identifier = event.originalEvent.pointerId;
        if (!this._touchMove(event)) {
            event.originalEvent.preventDefault();
        }
    },
    _msPointerUp: function (event) {
        event.changedTouches = [event.originalEvent];
        event.originalEvent.identifier = event.originalEvent.pointerId;
        if (!this._touchEnd(event)) {
            event.originalEvent.preventDefault();
        }
    },
    _validateTouches: function (event) {
        if (!this._touches || !event.touches) return;

        var touches = event.touches,
            map = {},
            count = 0;
        for (var i = 0; i < touches.length; ++i) {
            var key = touches[i].identifier;
            if (!(key in this._touches)) continue;
            map[key] = this._touches[key];
            ++count;
        }
        if (!count) {
            this._reset();
            this._onGesturesEnd();
        } else {
            this._touches = map;
            this._touchesCount = count;
            if (this._pinch && count != 2) this._pinch = null;
        }
    },
    _addTouches: function (event) {
        var touches = event.changedTouches;
        for (var i = 0; i < touches.length; ++i) {
            var touch = touches[i];
            if (!(touch.identifier in this._touches)) {
                // sometimes the touch can be lost without mouse up event being fired
                this._touchesCount++;
            }
            this._touches[touch.identifier] = new _Touch(this._elm, touch, event);
        }
    },
    _updateTouches: function (event) {
        var touches = event.changedTouches;
        for (var i = 0; i < touches.length; ++i) {
            var touch = touches[i],
                item = this._touches[touch.identifier];
            if (item) {
                item.update(event, touch);
            }
        }
    },
    _removeTouches: function (touches) {
        for (var i = 0; i < touches.length; ++i) {
            var touch = touches[i];
            delete this._touches[touch.identifier];
            this._touchesCount--;
        }
    },
    _highlightObject: null,
    _highlight: function (elm) {
        this._unhighlight(true);
        elm = $(elm).closest('*[' + _HighlightObject.attr + ']', this._elm[0]);
        if (elm.length) {
            this._highlightObject = new _HighlightObject(elm);
        }
    },
    _unhighlight: function (force) {
        if (this._highlightObject) {
            this._highlightObject.unhighlight(force);
            if (force) {
                this._highlightObject = null;
            }
        }
    },
    _checkTimeouts: function (event) {
        var timeStamp = event.timeStamp || $.nd.now();
        if (this._longPressTimeoutID) {
            if (timeStamp - this._longPressTimeStamp >= _longPressTimeThreshold) {
                this._onLongPressTimeout();
            }
        }
        if (this._doubleTapTimeoutID) {
            if (timeStamp - this._doubleTapTimeStamp >= _doubleTapTimeThreshold) {
                this._onDoubleTapTimeout();
            }
        }
    },
    _setLongPressTimeout: function (event) {
        if (this._longPressTimeoutID) {
            window.clearTimeout(this._longPressTimeoutID);
        }
        this._longPressTimeoutID = setTimeout(this._longPressTimeoutHandler, _longPressTimeThreshold);
        this._longPressTimeStamp = event.timeStamp || $.nd.now();
    },
    _longPressTimeoutHandler: null,
    _onLongPressTimeout: function () {
        if (!this._longPressTimeoutID) {
            return;
        }
        clearTimeout(this._longPressTimeoutID);
        this._longPressTimeoutID = 0;
        this._unhighlight(true);
        if (this._tap) {
            this._onSingleTapConfirmed();
            this._tap = null;
            this._onGesturesEnd();
            this._onGesturesStart();
        }
        this._onLongPress();
    },
    _setDoubleTapTimeout: function (event) {
        if (this._doubleTapTimeoutID) {
            window.clearTimeout(this._doubleTapTimeoutID);
        }
        this._doubleTapTimeoutID = setTimeout(this._doubleTapTimeoutHandler, _doubleTapTimeThreshold);
        this._doubleTapTimeStamp = (event && event.timeStamp) || new Date().getTime();
    },
    _doubleTapTimeoutHandler: null,
    _onDoubleTapTimeout: function () {
        if (!this._doubleTapTimeoutID) {
            return;
        }
        clearTimeout(this._doubleTapTimeoutID);
        this._doubleTapTimeoutID = 0;
        this._onSingleTapConfirmed();
        this._tap = null;
        this._onGesturesEnd();
    },
    _onGesturesStart: function () {
        if (!this._gesturesStartListener) {
            return;
        }
        this._gesturesStartListener.fire();
    },
    _onKeyUp: function (event) {
        if (this._keyUpListener) {
            this._keyUpListener.fire(event);
        }
    },
    _onKeyPress: function (event) {
        if (this._keyPressListener) {
            this._keyPressListener.fire(event);
        }
    },
    _onKeyDown: function (event) {
        if (this._keyDownListener) this._keyDownListener.fire(event);
    },
    _onScroll: function (touch) {
        if (!this._scrollListener) {
            return;
        }
        if (!touch.button()) this._scrollListener.fire(touch._consume());
    },
    _onSingleTap: function () {
        if (!this._singleTapListener) return;
        var touch = this.touches()[0];
        if (!touch.button()) this._singleTapListener.fire(touch);
    },
    _onSingleTapConfirmed: function () {
        var button = this._tap.button();
        if (!button) {
            if (!this._singleTapConfirmedListener) return;
            this._singleTapConfirmedListener.fire(this._tap);
        } else if (button == 2) {
            if (!this._contextMenuListener) return;
            this._contextMenuListener.fire(this._tap);
        }
    },
    _onDoubleTap: function () {
        if (!this._doubleTapListener || this._tap.button()) {
            return;
        }
        this._doubleTapListener.fire(this._tap);
    },
    _onFling: function (touch) {
        if (!this._flingListener) {
            return;
        }
        if (!touch.button()) {
            this._flingListener.fire(touch._consume());
        }
    },
    _onPinch: function () {
        if (!this._pinchListener || !this._pinch) {
            return;
        }
        this._pinchListener.fire(this._pinch);
    },
    _onLongPress: function () {
        if (!this._longPressListener && !this._contextMenuListener) {
            return;
        }
        var touch = this.touches()[0];
        if (!touch.button()) {
            if (this._longPressListener) {
                this._longPressListener.fire(touch);
            }
            if (this._contextMenuListener) {
                this._contextMenuListener.fire(touch);
            }
        }
    },
    _onGesturesEnd: function () {
        if (!this._gesturesEndListener) {
            return;
        }
        this._gesturesEndListener.fire();
    },
    _reset: function () {
        if (this._longPressTimeoutID) {
            clearTimeout(this._longPressTimeoutID);
            this._longPressTimeoutID = 0;
        }
        if (this._doubleTapTimeoutID) {
            clearTimeout(this._doubleTapTimeoutID);
            this._doubleTapTimeoutID = 0;
        }
        this._touches = null;
        this._touchesCount = 0;
        this._pinch = null;
        this._tap = null;
    },
    dispose: function () {
        this._elm.off('.' + this._namespace);
        delete this._elm;
        $(document).off('.' + this._namespace);
        var listeners = [
            '_scrollListener',
            '_keyUpListener',
            '_keyPressListener',
            '_keyDownListener',
            '_singleTapListener',
            '_singleTapConfirmedListener',
            '_doubleTapListener',
            '_flingListener',
            '_pinchListener',
            '_longPressListener',
            '_gesturesStartListener',
            '_gesturesEndListener',
        ];

        for (var i = 0; i < listeners.length; i++) {
            if (this[listeners[i]]) {
                this[listeners[i]].empty();
                delete this[listeners[i]];
            }
        }

        if (this._longPressTimeoutID) {
            clearTimeout(this._longPressTimeoutID);
            delete this._longPressTimeoutID;
        }
        if (this._doubleTapTimeoutID) {
            clearTimeout(this._doubleTapTimeoutID);
            delete this._doubleTapTimeoutID;
        }

        delete keyUpHandlers[this._id];
        delete keyPressHandlers[this._id];
        delete keyDownHandlers[this._id];
    },
    touches: function () {
        if (!this._touches) {
            return null;
        }
        var result = [];
        for (var id in this._touches) {
            result.push(this._touches[id]);
        }
        return result;
    },
});

function _init(elm) {
    var gestures = elm.data('ndGestures');
    if (!gestures) {
        gestures = new _Gestures(elm);
        elm.data('ndGestures', gestures);
    }
    return gestures;
}

_Gestures.bind = function (elm) {
    if (arguments.length == 3 && typeof arguments[1] == 'string' && typeof arguments[2] == 'function') {
        var names = arguments[1].split(' '),
            handler = arguments[2];
        elm.each(function () {
            var gestures = _init($(this));
            for (var i = 0; i < names.length; ++i) gestures._bind(names[i], handler);
        });
    } else if (arguments.length == 2 && typeof arguments[1] == 'object') {
        var listeners = arguments[1];
        elm.each(function () {
            var gestures = _init($(this));
            for (var name in listeners) {
                gestures._bind(name, listeners[name]);
            }
        });
    }
};
_Gestures.unbind = function (elm) {
    if (arguments.length == 1) {
        elm.each(function () {
            var elm = $(this),
                gestures = elm.data('ndGestures');
            if (!gestures) {
                return;
            }
            gestures.dispose();
            elm.removeData('ndGestures');
        });
    } else if (arguments.length == 2 && typeof arguments[1] == 'string') {
        var listeners = arguments[1].split(' ');
        elm.each(function () {
            var elm = $(this),
                gestures = elm.data('ndGestures');
            if (!gestures) {
                return;
            }
            for (var i = 0; i < listeners.length; ++i) {
                gestures._unbind(listeners[i]);
            }
            if (!gestures._hasListeners()) {
                gestures.dispose();
                elm.removeData('ndGestures');
            }
        });
    }
};
_Gestures.reset = function (elm) {
    elm.each(function () {
        var elm = $(this),
            gestures = elm.data('ndGestures');
        if (!gestures) return;
        gestures._reset();
    });
};

function enableTouchScrollHandler(event) {
    event = event.originalEvent || event;
    if (event.stopPropagation) {
        event.stopPropagation();
    }
    event.cancelBubble = true;
}

_Gestures.enableTouchScroll = function (el) {
    if (Modernizr.touch) {
        $(el).on('touchstart touchmove', enableTouchScrollHandler);
    }
};
_Gestures.disableTouchScroll = function (el) {
    if (Modernizr.touch) {
        $(el).off('touchstart touchmove', enableTouchScrollHandler);
    }
};

_Gestures.bindDefault = function ($el) {
    $el.on('touchstart touchmove mousedown MSPointerDown pointerdown click', function (event) {
        event = event.originalEvent || event;
        if (event.stopPropagation) {
            event.stopPropagation();
        }
        event.cancelBubble = true;
    });
};

var _VelocityTracker = function (timeStamp) {
    this._startTime = timeStamp;
};
_VelocityTracker._velocity_history_time_threshold = 100;
_VelocityTracker.prototype = {
    _head: null,
    _tail: null,
    _startTime: 0,
    update: function (dx, dy, timeStamp) {
        if (!timeStamp) {
            timeStamp = $.nd.now();
        }
        if (dx || dy) {
            var item = {
                time: timeStamp,
                expires: timeStamp + _VelocityTracker._velocity_history_time_threshold,
                dx: dx,
                dy: dy,
                next: null,
                dt: timeStamp - (this._tail ? this._tail.time : this._startTime),
            };
            if (!this._tail) {
                this._head = this._tail = item;
            } else {
                this._tail = this._tail.next = item;
            }
        }
        item = this._head;
        while (item) {
            if (item.expires >= timeStamp) {
                break;
            }
            this._head = item = item.next;
        }
    },
    velocity: function () {
        if (!this._head) {
            return {
                vx: 0,
                vy: 0,
            };
        }
        var item = this._head;
        var dx = 0,
            dy = 0,
            dt = 0;
        while (item) {
            dx += item.dx;
            dy += item.dy;
            dt += item.dt;
            item = item.next;
        }
        return {
            vx: dt ? dx / dt : 0,
            vy: dt ? dy / dt : 0,
        };
    },
};

var _Touch = function (elm, touch, event) {
    this._elm = elm;
    this._id = this.identifier = touch.identifier;
    this._origin = {
        x: touch.pageX,
        y: touch.pageY,
    };
    this._prevPosition = this._position = {
        x: touch.pageX,
        y: touch.pageY,
    };
    this._event = event;
    this._events = [event];
    this._startTime = event && event.timeStamp ? event.timeStamp : $.nd.now();
    this._vtracker = new _VelocityTracker(this._startTime);
    this._target = touch.target;
    if (touch.button) this._button = touch.button;
};
_Touch.prototype = {
    _id: 0,
    _origin: null,
    _position: null,
    _prevPosition: null,
    _vtracker: null,
    _startTime: 0,
    _dx: 0,
    _dy: 0,
    _target: null,
    _event: null,
    _elm: null,
    _button: 0,
    identifier: null,
    clone: function () {
        return new _TouchClone(this);
    },
    _consume: function () {
        var clone = this.clone();
        if (this._prevPosition && this._prevPosition !== this._position) {
            // because we use debounce for scroll and fling events
            // some events are missing and consumers received wrong dx, dy.
            // Adjust dx and dy so its will contain delta from previous consumed event
            clone._dx = this._position.x - this._prevPosition.x;
            clone._dy = this._position.y - this._prevPosition.y;
        }
        this._prevPosition = this._position;
        return clone;
    },
    update: function (event, touch) {
        var position = {
            x: touch.pageX,
            y: touch.pageY,
        };
        this._dx = position.x - this._position.x;
        this._dy = position.y - this._position.y;

        this._events.push(event);
        this._lastEvent = event;

        var timeStamp = event && event.timeStamp ? event.timeStamp : $.nd.now();

        this._vtracker.update(this._dx, this._dy, timeStamp);
        this._position = position;
    },
    distance: function () {
        var dx = this._position.x - this._origin.x,
            dy = this._position.y - this._origin.y;
        return Math.sqrt(dx * dx + dy * dy);
    },
    stop: function () {},
    dx: function () {
        return this._dx;
    },
    dy: function () {
        return this._dy;
    },
    totalDx: function () {
        return this._position.x - this._origin.x;
    },
    totalDy: function () {
        return this._position.y - this._origin.y;
    },
    position: function () {
        return {
            x: this._position.x,
            y: this._position.y,
        };
    },
    origin: function () {
        return {
            x: this._origin.x,
            y: this._origin.y,
        };
    },
    velocity: function () {
        return this._vtracker.velocity();
    },
    target: function () {
        return this._target;
    },
    event: function (event) {
        if (!arguments.length) return this._event;
        else this._event = event;
        return this;
    },
    /**
     * Returns the last event that caused this Touch to fire.
     * @returns {Event} - browser event, such as: mouseDown, touchend.
     */
    lastEvent: function lastEvent() {
        return this._lastEvent;
    },
    element: function () {
        return this._elm;
    },
    button: function () {
        return this._button;
    },
};
var _TouchClone = function (touch) {
    _.assign(this, touch);
};
_TouchClone.prototype = _.create(_Touch.prototype, {
    constructor: _TouchClone,
});

var _Pinch = function (touch1, touch2) {
    this._touch1 = touch1;
    this._touch2 = touch2;
    this._origin = this.center();
    this._distance = this.distance();
};
_Pinch.prototype = {
    _touch1: null,
    _touch2: null,
    _origin: null,
    _distance: 0,
    origin: function () {
        return {
            x: this._origin.x,
            y: this._origin.y,
        };
    },
    center: function () {
        return {
            x: (this._touch1._position.x + this._touch2._position.x) / 2,
            y: (this._touch1._position.y + this._touch2._position.y) / 2,
        };
    },
    scale: function () {
        return this.distance() / this._distance;
    },
    distance: function () {
        var dx = this._touch2._position.x - this._touch1._position.x,
            dy = this._touch2._position.y - this._touch1._position.y;
        return Math.sqrt(dx * dx + dy * dy);
    },
};

var _Callbacks = function (name) {
    this._name = name;
};
_Callbacks.prototype = {
    _name: null,
    _list: null,
    count: function () {
        if (!this._list) {
            return 0;
        }
        return this._list.length;
    },
    add: function (callback) {
        if (!this._list) {
            this._list = [callback];
        } else if (!this.contains(callback)) {
            this._list.push(callback);
        }
        return this;
    },
    contains: function (callback) {
        if (!this._list) {
            return false;
        }
        var count = this._list.length;
        for (var i = 0; i < count; ++i) {
            if (this._list[i] === callback) {
                return true;
            }
        }
        return false;
    },
    remove: function (callback) {
        if (!this._list) {
            return this;
        }
        var count = this._list.length;
        for (var i = 0; i < count; ++i) {
            if (this._list[i] !== callback) {
                continue;
            }
            this._list.splice(i, 1);
            break;
        }
        return this;
    },
    empty: function () {
        delete this._list;
        return this;
    },
    fire: function () {
        if (!this._list) {
            return;
        }
        if (_tracker.isPropagationStopped(this._name)) {
            return;
        }
        var list = this._list.slice();
        var count = this._list.length;
        for (var i = 0; i < count; ++i) {
            if (list[i].apply(this, arguments) !== false) {
                continue;
            }
            _tracker.stopPropagation(this._name);
            return;
        }
    },
};

function _HighlightObject(elm) {
    // highligh objects being tapped
    this._elm = elm;
    this._highlightTimeoutID = setTimeout(this._highlight.bind(this), _HighlightObject._highlightThreshold);
}

_HighlightObject.attr = '-nd-tap-highlight-class-name';
_HighlightObject._highlightThreshold = 150;
_HighlightObject._unhighlightThreshold = 150;
_HighlightObject.prototype = {
    _elm: null,
    _highlightTimeoutID: 0, // to make sure it's not something other than click/tap
    _unhighlightTimeoutID: 0, // to make sure the object stas highlighted long enough for user to notice
    _flag: false,
    _highlight: function () {
        this._highlightTimeoutID = 0;
        this._elm.addClass(this._elm.attr(_HighlightObject.attr));
        this._unhighlightTimeoutID = setTimeout(this._onUnhighlightTimeout.bind(this), _HighlightObject._unhighlightThreshold);
    },
    unhighlight: function (force) {
        if (force) {
            this._flag = true;
            if (this._highlightTimeoutID) {
                // hasn't been highlighted yet
                clearTimeout(this._highlightTimeoutID);
                this._highlightTimeoutID = 0;
                delete this._elm;
            } else {
                if (this._unhighlightTimeoutID) {
                    clearTimeout(this._unhighlightTimeoutID);
                    this._unhighlightTimeoutID = 0;
                }
                this._unhighlight();
            }
        } else {
            this._flag = true; // make note that unhighlight has been requested
            if (!this._highlightTimeoutID && !this._unhighlightTimeoutID)
                // has been highlighted and stayed highlighted long enough
                this._unhighlight();
        }
    },
    _onUnhighlightTimeout: function () {
        this._unhighlightTimeoutID = 0;
        if (this._flag)
            // if unhighlight has been requested
            this._unhighlight();
    },
    _unhighlight: function () {
        if (this._elm) {
            this._elm.removeClass(this._elm.attr(_HighlightObject.attr));
            delete this._elm;
        }
    },
};

var _tracker = (function () {
    var _id = 0,
        _events = {},
        _lastEvent;

    function init(event) {
        _lastEvent = event;
        if (!event.timeStamp) {
            // ie could not have timeStamp, so ensure it exists
            event.timeStamp = $.nd.now();
            event._brokenTimeStamp = true;
        }

        if (_id == event.timeStamp) {
            return;
        }
        _id = event.timeStamp;
        _events = {};
    }

    function isPropagationStopped(name) {
        return name in _events;
    }

    function stopPropagation(name) {
        _events[name] = null;
        if (_lastEvent && _lastEvent._brokenTimeStamp) {
            _lastEvent.stopPropagation();
        }
    }

    return {
        init: init,
        isPropagationStopped: isPropagationStopped,
        stopPropagation: stopPropagation,
    };
})();

export const enableTouchScroll = _Gestures.enableTouchScroll.bind(_Gestures);
export { _Gestures as GesturesManager };
export default _Gestures;
