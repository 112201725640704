// eslint-env es6

import { isObject, assign } from 'lodash';
import Action from 'actions/action';
import ndActions from 'nd.actions';

class ActionWithPredefinedContextWrapper extends Action {
    constructor(action, predefinedContext) {
        super();
        this._action = action;
        this._predefinedContext = predefinedContext;

        this._buildContext = this._buildContext.bind(this);
        this.available = this.available.bind(this);
        this.enabled = this.enabled.bind(this);
        this.activate = this.activate.bind(this);
    }

    _buildContext(context) {
        return assign({}, this._predefinedContext, context);
    }

    available(context) {
        return this._action.available(this._buildContext(context));
    }

    enabled(context) {
        return this._action.enabled(this._buildContext(context));
    }

    activate(context) {
        return this._action.activate(this._buildContext(context));
    }
}

/**
 * Resolves action by name
 *
 * @param {String} name - name of action registered in nd.actions
 * @param {Object} params - pre-defined action parameters
 * @returns {Action} action instance
 */
function resolveAction(name, params) {
    const action = ndActions[name];
    if (action === undefined) {
        throw new Error(`action '${name}' not supported`);
    }

    // es-disable-next-line eqeqeq
    if (params == null) {
        return action;
    }

    if (!isObject(params)) {
        throw new Error(`unexpected type of action params '${params}' for action '${name}'. Object expected.`);
    }

    return new ActionWithPredefinedContextWrapper(action, params);
}

export { resolveAction };
