/* eslint-env es6 */
import * as ko from 'knockout';
import { isFunction } from 'lodash';
import * as Clipboard from 'clipboard';

const clipboardHandler = {
    init: (element, valueAccessor) => {
        const accessor = valueAccessor();
        const clip = new Clipboard(element);
        if (isFunction(accessor.copyHandler)) {
            clip.on('success', accessor.copyHandler);
        }
    },
};
// binding auto registration
ko.bindingHandlers.clipboard = clipboardHandler;
