import { IDictionary } from '@pressreader/src/core/idictionary';

import { EcommerceTypes } from 'analytics/ecommerce/models/models';

function getGtagType(ecommerceType: EcommerceTypes) {
    const gtagTypes: IDictionary<string> = {
        [EcommerceTypes.AddPaymentInfo]: 'add_payment_info',
        [EcommerceTypes.AddToCart]: 'add_to_cart',
        [EcommerceTypes.BeginCheckout]: 'begin_checkout',
        [EcommerceTypes.CheckoutProgress]: 'checkout_progress',
        [EcommerceTypes.Purchase]: 'purchase',
        [EcommerceTypes.RemoveFromCart]: 'remove_from_cart',
        [EcommerceTypes.SelectContent]: 'select_content',
        [EcommerceTypes.SetCheckoutOption]: 'set_checkout_option',
    };
    if (gtagTypes[ecommerceType]) {
        return gtagTypes[ecommerceType];
    }
    throw new Error(`Not Implemented: ${ecommerceType}`);
}

export { getGtagType };
