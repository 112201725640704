import { getCurrentSubscription as getCurrent } from '@pressreader/commerce-api';
import { createSimpleCache } from '@pressreader/utils';

const cacheExpire = { expire: 5000 };
const cache = createSimpleCache(cacheExpire);

function getCurrentSubscription() {
    const key = 'CurrentSubscription';
    let promise = cache.get(key);
    if (!promise) {
        promise = getCurrent();
        cache.set(key, promise);
    }
    return promise;
}

export { getCurrentSubscription };
