import { ReCaptchaVersion } from '@pressreader/utils';

export enum AuthStatus {
    Success,
    InvalidUserNameOrPassword,
    ExceedMaxSimultaneousSessionsPerUser,
    UnknownError,
    ExceedMaxIncorrectPasswordAttempts,
    CaptchaFailed,
}

export interface NewUser {
    emailAddress: string;
    password: string;
    firstName: string;
    lastName: string;
    enablePromotional: boolean;
    enableService: boolean;

    libraryUser?: unknown;
    reCaptchaVersion?: ReCaptchaVersion;
    reCaptchaToken?: string;
}

export interface SignInResultDto {
    SponsorId?: number;
    Token: string;
    Status: AuthStatus;
    UserKey: string;
}
