import * as $ from 'jquery';
import config from '@pressreader/src/deprecated/nd.config';
import $ndServicesApi from 'nd.services.api';
import 'nd.res';

var provider = $.nd.resLoader.createBaseProvider();

// override "load" method to load data
provider.load = function () {
    return config.loaded().then(function () {
        var resConfig = config.get('res', {});

        var params = {};
        params.id = resConfig.id;
        params.locale = resConfig.locale;
        params.ts = resConfig.ts;
        params.version = 2;

        var debug = DEBUG && /debug=true/i.test(window.location.search);

        return $ndServicesApi.get('res', params, { useCdn: !debug }).then(function (data) {
            provider.onload(data);
        });
    });
};

$.nd.resLoader.setProvider(provider);

export default provider;
