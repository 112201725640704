import { httpGet } from '@pressreader/services';
import { ToCamelCase, toCamelCase } from '@pressreader/utils';

import { IUserProfileDto } from './types';
import { isUserAuthenticated } from './user.api';

function getUserProfile(profileId: string): Promise<IUserProfileDto> {
    return httpGet('social', `profiles/${profileId}/brief`);
}

async function getCurrentUserProfile(): Promise<ToCamelCase<IUserProfileDto>> {
    if (!isUserAuthenticated()) {
        throw new Error('Cannot get profile for anonymous user.');
    }
    const result = await httpGet<IUserProfileDto>('social', 'profiles/current');
    return toCamelCase(result);
}

export { getUserProfile, getCurrentUserProfile };
