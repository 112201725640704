import * as $ from 'jquery';
import {
    registerView,
    goViewAction,
    updateStateHistoryAction,
    resetStateHistoryAction,
    selectNavigationHistoryState,
    replaceStateHistoryAction,
    subscribeToTransition,
    backHistoryAction,
    isLanding,
    setIsLanding,
    getViewUrl,
} from '@pressreader/navigation-core';
import { dispatch, select } from '@pressreader/appstore';
import once from 'lodash/once';
import { CatalogView, ViewName } from '@pressreader/navigation-types';

/** @deprecated Please use imports from `@pressreader/navigation-core` directly. */
var ndNavHistory = ($.nd.navHistory = (function () {
    // + hack for proper 'back' navigation from refreshed radio view
    var _updateStateCallCount = 0;
    let _SubscribeToTransitionOnNavigate = false;
    let _SubscribeToTransitionOneNavigate = false;
    // - hack for proper 'back' navigation from refreshed radio view
    var self = {
        historyProvider: {
            back: () => {
                dispatch(backHistoryAction());
            },
        },
        registerView,
        getViewUrl,
        goView: (viewName, params, replace, ignoreIssueInfo, isForceReplace) => {
            const newParams = params || {};
            // Set catalogView for StoreHub view
            if (viewName === ViewName.StoreHub && !newParams.catalogView) {
                newParams.catalogView = CatalogView.Catalog;
            }
            dispatch(goViewAction({ viewName, params: newParams, replace, ignoreIssueInfo, isForceReplace }));
        },
        updateState: (params, reload, url) => {
            _updateStateCallCount++;

            dispatch(updateStateHistoryAction({ params, reload, url }));
        },
        // + hack for proper 'back' navigation from refreshed radio view
        updateStateCallCount: function () {
            return _updateStateCallCount;
        },
        // - hack for proper 'back' navigation from refreshed radio view
        resetState: () => {
            dispatch(resetStateHistoryAction());
        },
        getState: () => select(selectNavigationHistoryState),
        getReturnUrl: () => encodeURIComponent(window.location.href),
        onNavigate: fn => {
            if (!_SubscribeToTransitionOnNavigate) {
                _SubscribeToTransitionOnNavigate = true;
                subscribeToTransition(update => {
                    if (update.action !== 'REPLACE') {
                        const { state } = update.location;
                        const data = { state, stateLess: {}, viewName: state.viewName || ViewName.Error };
                        fn(data);
                    }
                });
            }
        },
        oneNavigate: fn => {
            if (!_SubscribeToTransitionOneNavigate) {
                _SubscribeToTransitionOneNavigate = true;
                const event = once(fn);
                subscribeToTransition(update => {
                    if (update.action !== 'REPLACE') {
                        const { state } = update.location;
                        const data = { state, stateLess: {}, viewName: state.viewName || ViewName.Error };
                        event(data);
                    }
                });
            }
        },
        replaceState: (to, state) => {
            dispatch(replaceStateHistoryAction({ to, state }));
        },
        get isLanding() {
            return isLanding;
        },
        set isLanding(value) {
            setIsLanding(value);
        },
    };

    return self;
})());

export default $.nd.navHistory;
export { ndNavHistory };
