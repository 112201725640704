/* eslint-env es6 */

'use strict';

import * as ko from 'knockout';
import ndRes from 'deprecated/nd.res';

/*
 * Usage:
 * <div data-bind="resourceText: titleResource"></div>
 * <div data-bind="resourceText: [titleResource, 'default title']"></div>
 *
 * Populates element with text retrieved from the resources, properly handles async resources initialization.
 * */
ko.bindingHandlers.resourceText = {
    init: function init() {
        return { controlsDescendantBindings: true };
    },
    update: function update(element, valueAccessor) {
        const res = ko.unwrap(valueAccessor());
        let resName = res;
        let resDefault = null;

        if (Array.isArray(res)) {
            [resName, resDefault] = res;
        }

        if (ndRes.isLoaded()) {
            ko.utils.setTextContent(element, ndRes.val(resName, resDefault));
        } else {
            ko.virtualElements.emptyNode(element);
            ndRes.valAsync(resName, resDefault).then(text => {
                // double-check if target node still bound (ko.cleanNode was not called for the node)
                if (ko.dataFor(element)) {
                    ko.utils.setTextContent(element, text);
                }
            });
        }
    },
};

ko.virtualElements.allowedBindings.resourceText = true;
