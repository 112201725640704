import isString from 'lodash/isString';
import escapeRegExp from 'lodash/escapeRegExp';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';

import { encodePathComponent } from './routing.utils';

/**
 * Symbols allowed in route path and query (in regexp notation).
 * Route parameter always parses encoded strings,
 * so allowed chars are only those which can appear in properly encoded url component.
 */
// eslint-disable-next-line quotes
const ALLOWED_PARAM_CHARSET = "[\\w\\d%@$_.+!*'(),\\-~]";

/**
 * A RegExp used for parsing templates of route path, e.g. in "channel/@{userId}$feed".
 */
const INTERPOLATED_PARAM_REG_EXP = /^(.*)\{([^}]+)\}(.*)$/i;

/**
 * Represents client-side route parameter.
 */
class RouteParameter {
    private _defaultVal?: string;
    protected readonly prefix: string;
    protected readonly stem: string;
    protected readonly suffix: string;
    readonly name: string;

    constructor(name: string, prefix: string, stem: string, suffix: string) {
        if (some([prefix, stem, suffix], str => !isString(str))) {
            throw new Error('Invalid route parameter arguments');
        }

        this.name = name;
        this.prefix = prefix;
        this.suffix = suffix;
        this.stem = stem;
        this._defaultVal = undefined;
    }

    set defaultValue(value: string | undefined) {
        // undefined and null must remain undefined
        if (value !== undefined && value !== null) {
            this._defaultVal = String(value);
        } else {
            this._defaultVal = undefined;
        }
    }
    get defaultValue() {
        return this._defaultVal;
    }

    get pattern() {
        return `${escapeRegExp(this.prefix)}(${this.stem})${escapeRegExp(this.suffix)}`;
    }

    get optional() {
        return this.defaultValue !== undefined;
    }

    /**
     * Indicates that route parameter uses interpolation for parsing/building its value.
     */
    get interpolated() {
        return false;
    }

    /**
     * Indicates that route parameter is partially interpolated,
     * meaning it interpolates only a part of given string on parsing/building value.
     * For example template "{issue}" is interpolated but not partially, this property returns false in that case,
     * however template "@{issue}" is partially interpolated (the property is truthful).
     */
    get fragmentary() {
        return false;
    }

    build(value?: string) {
        if (!this.optional && value === undefined) {
            throw new Error(`Value is required for mandatory route parameter '${this.name}'`);
        }

        if (value === undefined || value === null) {
            value = this.defaultValue;
        }

        return decodeURIComponent(`${this.prefix}${value}${this.suffix}`);
    }
}

/**
 * Represents route parameter with interpolation, e.g. "{issue}".
 */
export class InterpolatedRouteParameter extends RouteParameter {
    constructor(template: string) {
        const matched = INTERPOLATED_PARAM_REG_EXP.exec(template);
        if (!matched) {
            throw new Error(`Invalid template for interpolated route parameter '${template}'`);
        }

        const [, prefix, name, suffix] = matched;
        super(name, encodePathComponent(prefix), `${ALLOWED_PARAM_CHARSET}+`, encodePathComponent(suffix));
    }

    override get interpolated() {
        return true;
    }

    override get fragmentary() {
        return !isEmpty(this.prefix) || !isEmpty(this.suffix);
    }
}

export class NoninterpolatedRouteParameter extends RouteParameter {
    constructor(template: string) {
        if (INTERPOLATED_PARAM_REG_EXP.test(template)) {
            throw new Error(`Invalid template for non-interpolated route parameter '${template}'`);
        }

        super(template, '', escapeRegExp(encodePathComponent(template)), '');
    }

    override set defaultValue(value: string | undefined) {
        // Calls only allowed for undefined
        if (value !== undefined) {
            throw new Error(`Default value is not allowed for non-interpolated route parameter. Was given '${value}'`);
        }
    }
    override get defaultValue() {
        return undefined;
    }

    override get optional() {
        return false;
    }

    override build(value?: string) {
        if (value !== undefined && value !== this.name) {
            throw new Error(`Value is not allowed for non-interpolated route parameter. Was given '${value}'`);
        }

        return super.build(this.name);
    }
}

/**
 * Factory method for creating {@link RouteParameter} route parameters based on template string.
 * @param template Template string to create a route parameter from.
 * @return Created {@link RouteParameter} route parameter.
 */
export const routeParameterFactory = (template: string): RouteParameter => {
    if (!isString(template)) {
        throw new Error(`Invalid route parameter template '${template}'`);
    }

    if (INTERPOLATED_PARAM_REG_EXP.test(template)) {
        return new InterpolatedRouteParameter(template);
    }

    return new NoninterpolatedRouteParameter(template);
};

export default routeParameterFactory;
