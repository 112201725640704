// eslint-env es6

import { getImplementation } from 'externalsystems/signin';

interface IAuthResult {
    /** indicates that new user was created during authorization */
    isNewUser: boolean;
}

interface ISignInResult {
    /** seconds to expire */
    ExpiresIn: number;
    /** PressReader authentication token */
    Token: string;
    /** authenticated user key */
    UserKey: string;
}

interface ICombinedAuthResult {
    /** result of external authorization */
    authResult: IAuthResult;
    /** result of signing in into PressReader */
    signInResult: ISignInResult;
}

interface ISignInPayload {
    /** name of external system, @see EXTERNAL_SYSTEM */
    system: string;
    /** operation, which for authorization is requested */
    context?: {
        /** operation, which for authorization is requested */
        command?: string;
        returnUrl?: string;
        [key: string]: any;
    };
}

/**
 * Single entry point to sign in with with external systmes
 *
 * Performs request for authorization to external system,
 * waits user input if required by authorization flow
 * process response
 */
function signIn({ system, context = {} }: ISignInPayload): Promise<ICombinedAuthResult | boolean | Error> {
    if (!system) {
        throw new Error('System not specified');
    }

    const implementation = getImplementation(system);
    return implementation.signIn({ system, context });
}

export { signIn };
