import * as ko from 'knockout';
import { isFunction } from 'lodash';

/**
 * @see https://github.com/joshwnj/react-visibility-sensor
 */
const defaultOptions = {
    offset: {},
    partialVisibility: false,
    minTopValue: 0,
    intervalDelay: 100,
    containmentSelector: null
};

class VisibilitySensor {
    constructor(element, options) {
        this.el = element;
        this.containment = document.querySelector(options.containmentSelector);
        this.options = options;

        this.state = {
            isVisible: null,
            visibilityRect: {}
        }
        this.interval = null;
    }

    startWatching() {
        if (this.interval) {
            return;
        }

        const {intervalDelay} = this.options;
    
        this.interval = setInterval(this._check.bind(this), intervalDelay);
    
        this._check();
    }

    stopWatching() {
        if (this.interval) {
            this.interval = clearInterval(this.interval);
        }
    }

    _check() {
        const { offset, partialVisibility, onChange } = this.options;

        let containmentRect;
        const rect = this._normalizeRect(this._roundRectDown(this.el.getBoundingClientRect()));
    
        if (this.containment) {
            const containmentDOMRect = this.containment.getBoundingClientRect();
            containmentRect = {
                top: containmentDOMRect.top,
                left: containmentDOMRect.left,
                bottom: containmentDOMRect.bottom,
                right: containmentDOMRect.right
            };
        } else {
            containmentRect = {
                top: 0,
                left: 0,
                bottom: window.innerHeight || document.documentElement.clientHeight,
                right: window.innerWidth || document.documentElement.clientWidth
            };
        }
    
        // Check if visibility is wanted via offset
        const hasValidOffset = typeof offset === "object";
    
        if (hasValidOffset) {
            containmentRect.top += offset.top || 0;
            containmentRect.left += offset.left || 0;
            containmentRect.bottom -= offset.bottom || 0;
            containmentRect.right -= offset.right || 0;
        }
    
        const visibilityRect = {
            top: rect.top >= containmentRect.top,
            left: rect.left >= containmentRect.left,
            bottom: rect.bottom <= containmentRect.bottom,
            right: rect.right <= containmentRect.right
        };
    
        const hasSize = rect.height > 0 && rect.width > 0;
    
        let isVisible =
            hasSize &&
            visibilityRect.top &&
            visibilityRect.left &&
            visibilityRect.bottom &&
            visibilityRect.right;
    
        // check for partial visibility
        if (hasSize && partialVisibility) {
            let partialVisible =
                rect.top <= containmentRect.bottom &&
                rect.bottom >= containmentRect.top &&
                rect.left <= containmentRect.right &&
                rect.right >= containmentRect.left;
    
            // account for partial visibility on a single edge
            if (typeof partialVisibility === "string") {
                    partialVisible = visibilityRect[partialVisibility];
            }
        
            // if we have minimum top visibility set by props, lets check, if it meets the passed value
            // so if for instance element is at least 200px in viewport, then show it.
            const { minTopValue } = this.options;
            isVisible = minTopValue
                ? partialVisible &&
                    rect.top <= containmentRect.bottom - minTopValue
                : partialVisible;
        }
    
        // notify the parent when the value changes
        if (this.state.isVisible !== isVisible) {
            this.state = {
                isVisible: isVisible,
                visibilityRect: visibilityRect
            };
    
            if (isFunction(onChange))
                onChange(isVisible);
        }
    
        return this.state;
    }

    _roundRectDown(rect) {
        return {
            top: Math.floor(rect.top),
            left: Math.floor(rect.left),
            bottom: Math.floor(rect.bottom),
            right: Math.floor(rect.right)
        };
    }

    _normalizeRect(rect) {
        if (rect.width === undefined) {
            rect.width = rect.right - rect.left;
        }
      
        if (rect.height === undefined) {
            rect.height = rect.bottom - rect.top;
        }
      
        return rect;
    }
}

/**
 * Visibility Sensor
 * Calls a function when the DOM element goes in or out of the window viewport.
 *
 * @example
 * data-bind="visibilitySensor: action, visibilitySensorOptions: { containmentSelector: '.pop-body' }"
*/
ko.bindingHandlers.visibilitySensor = {
    init: (element, valueAccessor, allBindings) => {
        const handler = valueAccessor();
        const visibilitySensorOptions = ko.unwrap(allBindings.get('visibilitySensorOptions'));
        let options = ko.unwrap(ko.utils.extend(defaultOptions, visibilitySensorOptions));

        if (!isFunction(handler)) { throw new Error('Handler should be a function'); }
        
        options.onChange = handler;

        const visibilitySensor = new VisibilitySensor(element, options);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            visibilitySensor.stopWatching();
        });

        visibilitySensor.startWatching();
    },
};

export {
    VisibilitySensor,
    defaultOptions as VisibilitySensorDefaultOptions,
};
