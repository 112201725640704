import * as $ from "jquery";
import staticResources from "nd.res.static";
import * as _ from "lodash";
import "nd.core";
import "@pressreader/src/nd.baseloader";
import { Deferred } from '@pressreader/src/promise/deferred';
import { format as formatTemplate, embedRTL, dateFormat } from '@pressreader/utils';
import ndUtils from '@pressreader/src/nd.utils';

var _loader = $.nd.resLoader = new $.nd.baseLoader();

var res = $.nd.res = $.res = window.ResourceManager = (function () {
    var _items,
        _rtlEscapedItems = {},
        _api,
        _ready,
        _loaded = new Deferred();

    var cachedDateFormat = _.memoize(dateFormat.createDateFormat);

    _loader.bind(function (evt, data) {
        if (data && data.res) {
            _items = $.extend({}, staticResources.getResources(), data.res);
            $.nd.event.trigger("loaded", _api, _api);
            bindDomElm();
            _loaded.resolve(_api);
        }
    });
    $(function () {
        _ready = true;
        bindDomElm();
    });

    function bindDomElm() {
        if (_items && _ready) {
            $("[ndres]").each(function () {
                var name = this.getAttribute("ndres"),
                    val = getVal(name);
                if (val) this.innerHTML = val;
            });
        }
    }

    function getVal(id, defaultVal) {
        const { showResourceNames } = ndUtils.getUrlParameters();
        if (ndUtils.convertToBool(showResourceNames)) {
            return id;
        }
        return (_items && _items[id]) || defaultVal || "";
    }

    /**
     * Check if there is any rtl symbol in the input string and wrap with unicode bidirectional embedding symbols if necessary.
     * @param {String} id - resource identifier.
     * @param {String} [defaultVal] - the value to return if resource does not exist. Parameter is optional.
     */
    function getSafeRTLString(id, defaultValue) {
        if (_rtlEscapedItems[id]) {
            return _rtlEscapedItems[id];
        }
        var val = _api.getVal(id);
        if (!val) {
            return defaultValue;
        }
        _rtlEscapedItems[id] = _api.embedRTL(val);
        return _rtlEscapedItems[id];
    }

    /**
     * Returns a value for the specified resource id.
     * @param {String} id - resource identifier.
     * @param {String} [defaultVal] - the value to return if resource does not exist. Parameter is optional.
     * @returns {Promise} - a promise, when fulfilled, contains value for the specified resource id.
     */
    function getValAsync(id, defaultVal) {
        return loaded().then(function onResourcesLoaded() {
            return getVal(id, defaultVal);
        });
    }

    function loaded(data, fn) {
        if (arguments.length > 0) {
            $.nd.event.one(_api, "loaded", data, fn);
            if (_items)
                $.nd.event.trigger("loaded", _api, _api);

        }
        return _loaded.promise();
    }

    function isLoaded() {
        return !!_items;
    }

    var defaultDateFormat = 'd MMM yyyy';

    // localized formats for MMM, MMMM, ddd and dddd date components.
    var customDateFormats = {};

    _api = {
        val: getVal,
        valAsync: getValAsync,
        get: getVal,
        getVal: getVal,
        getSafeRTLString: getSafeRTLString,
        loaded: loaded,
        isLoaded: isLoaded,
        getItems: function () {
            return _items;
        },
        /**
         * Format template. Template will be loaded synchronously.
         * @returns {String}
         */
        format: function () {
            // to use ResourceManager.format("resname",1,2,"bush")
            if (arguments.length === 0) { return ''; }

            var template = getVal(arguments[0]);
            return formatTemplate.apply(null, [template].concat(Array.prototype.slice.call(arguments, 1)));
        },
        /**
         * Format template. Template will be loaded asynchronously.
         * @param {String} resname - a name of template
         * @param {String} defaultValue - a default template. It will be used when a desired template not found
         * @param {...*} templateParams - a param list that contains zero or more values to format
         * @returns {Promise.<String>}
         */
        formatAsync: function (resname, defaultValue) {
            if (!resname) {
                return Promise.reject(new Error('resname is empty'));
            }

            var params = Array.prototype.slice.call(arguments, 2);

            return getValAsync(resname, defaultValue)
                .then(function (template) {
                    return formatTemplate.apply(null, [template].concat(params));
                });
        },
        format2: function () {
            // to use ResourceManager.format2("resname",{name:"cpage",value:1},{name:"pages",value:99})
            var a = arguments;
            if (a.length > 0) {
                var val = this.getVal(a[0]);
                if (!$.nd.isEmpty(val)) {
                    // replace params
                    // ie. "Page: from {firstPage} to {lastPage}" => "Page: from 1 to 10"
                    for (var i = 1; i < a.length; i++) {
                        var item = a[i];
                        if (item && item.name)
                            val = val.replace(new RegExp("(\\{" + item.name + "})", "g"), (item.value || "").toString());
                    }
                }
                return val || "";
            }
            return "";
        },

        formatDate: function (date, resourceName, fallbackFormat) {
            // Examples of formats:
            // "dddd, dd-MM-yyyy"
            // "ddd, dd-MM-yyyy"
            // "dd-MM-yyyy"
            // "d-MMM-yy"
            // "d-MMMM-y"
            // "h:mm TT"

            if (!_.isDate(date) || _.isNaN(date.getTime())) {
                return "";
            }

            var format = resourceName
                ? this.getVal(resourceName, fallbackFormat) || defaultDateFormat
                : fallbackFormat || defaultDateFormat;

            var format = cachedDateFormat(format, customDateFormats);

            return format(date);
        },
        formatDateAsync: function () {
            var args = [].slice.call(arguments);
            return loaded()
                .then(function (res) {
                    return res.formatDate.apply(res, args);
                });
        },
        getMonthFullName: function (num) {
            // num from 0 to 11
            var name = dateFormat.longMonths[num];
            return this.getSafeRTLString('Calendar.' + name, name);
        },
        getMonthShortName: function (num) {
            // num from 0 to 11
            var name = dateFormat.shortMonths[num];
            return this.getSafeRTLString('Calendar.' + name, name);
        },
        getWeekDayFullName: function (num) {
            // num from 0 to 6
            var name = dateFormat.longDays[num];
            return this.getSafeRTLString('Calendar.' + name, name);
        },
        getWeekDayShortName: function (num) {
            // num from 0 to 6
            var name = dateFormat.shortDays[num];
            return this.getSafeRTLString('Calendar.' + name, name);
        },
        getWeekDayMinName: function (num) {
            // num from 0 to 6
            var name = dateFormat.minDays[num];
            return this.getSafeRTLString('Calendar.' + name, name);
        },
        embedRTL: embedRTL,
    };

    // Formats localized with resources
    customDateFormats.MMM = function (month) { return _api.getMonthShortName(month); };
    customDateFormats.MMMM = function (month) { return _api.getMonthFullName(month); };
    customDateFormats.ddd = function (weekday) { return _api.getWeekDayShortName(weekday); };
    customDateFormats.dddd = function (weekday) { return _api.getWeekDayFullName(weekday); };

    return _api;
})();

var templates = $.nd.templates = $.templates = res.templates = window.JSTemplatesManager = (function (res) {
    var _items, _api, _loaded = new Deferred();

    _loader.bind(function (evt, data) {
        if (data && data.templates) {
            var t = [], items = data.templates;
            for (var name in items) {
                var funBody = items[name];
                if (name && funBody) {
                    t[name] = new JSTemplate(name, funBody);
                }
            }
            _items = t;
            $.nd.event.trigger("loaded", _api, _api);
            _loaded.resolve(_api);
        }
    });

    function loaded(data, fn) {
        if (arguments.length > 0) {
            $.nd.event.one(_api, "loaded", data, fn);
            if (_items)
                $.nd.event.trigger("loaded", _api, _api);
        }
        return _loaded.promise();
    }

    function isLoaded() {
        return !!_items;
    }

    function getTemplate(name) {
        return _items ? _items[name] : null;
    }

    function getTemplateAsync(name) {
        return loaded()
            .then(function () {
                return getTemplate(name);
            });
    }

    function process(name, data) {
        var t = getTemplate(name);
        if (t) return t.process(data, window);
        return "";
    }

    function renderTo(name, data, elm) {
        var t = getTemplate(name);
        if (t) t.renderTo(data, elm);
        return this;
    }

    function appendTo(name, data, elm) {
        var t = getTemplate(name);
        if (t) t.appendTo(data, elm);
        return this;
    }

    function prependTo(name, data, elm) {
        var t = getTemplate(name);
        if (t) t.prependTo(data, elm);
        return this;
    }

    var _stack = [], _buffer, _win;

    function pushContext(context) {
        if (!_buffer) {
            _buffer = [];
        }
        _stack.push(context);
    }

    function popContext() {
        if (_stack.length) {
            var context = _stack.pop();
            if (context)
                for (var name in context)
                    context[name] = null;
        }
        if (!_stack.length) {
            _buffer = null;
            _win = null;
        }
    }

    function write(str) {
        if (!_buffer) _buffer = [];
        _buffer.push(str);
    }

    function writeRes(id, defaultVal) {
        return write(res.val(id, defaultVal));
    }

    function execTemplate(name, data) {
        var t = getTemplate(name);
        if (t) processImp(t, _win, data, _stack[_stack.length]);
    }

    function makeFun(name, funBody) {
        try {
            funBody = "var write = __w;" + funBody;
            if ($.isDebug) {
                // using eval to enable debug
                var funName = "nd_templates_" + name.replace(/\./gi, "_");
                funBody = funName + " = function(window,context,__w,__t, __r){" + funBody + "}";
                return eval(funBody) || window[funName];
            }
            return new Function("window", "context", "__w", "__t", "__r", funBody);
        } catch (E) {
            $.showError("Unable to parse template '" + name + "'", E);
        }
        return null;
    }

    function processImp(template, win, data, parentContext) {

        var res = { result: "" };

        if (!template) return res;
        if (!template._process && template._funBody)
            template._process = makeFun(template._name, template._funBody);
        if (!template._process)
            return res;

        var render = parentContext ? parentContext.render : $.nd.delegate(true);

        var context = { data: data, template: template, _parent: parentContext, write: write, render: render }, ok = false;

        pushContext(context);
        try {
            template._process(_win, context, write, execTemplate, writeRes);
            ok = true;
        } catch (E) {
            if ($.isDebug) $.showError("Unable to execute template '" + template._name + "'", E);
            else throw E;
        }
        if (context.onrender) {
            context.render(context.onrender);
        }
        if (ok && !parentContext && _buffer) {
            res.result = _buffer.join("");
            res.render = context.render;
        }

        popContext();

        return res;
    }

    function renderToImp(template, data, elm) {
        var result = processImp(template, window, data);
        elm.html(result.result || "");
        if (result.render) {
            if (typeof ko !== "undefined") {
                if (elm.length) {
                    ko.safeCleanNode(elm[0]);
                }
            }
            result.render(elm);
        }
    }

    function appendToImp(template, data, elm) {
        var result = processImp(template, window, data);
        elm.append(result.result || "");
        if (result.render) {
            result.render(elm);
        }
    }

    function prependToImp(template, data, elm) {
        var result = processImp(template, window, data);
        elm.prepend(result.result || "");
        if (result.render) {
            result.render(elm);
        }
    }

    function JSTemplate(name, funBody) {
        this._name = name;
        this._funBody = funBody;
    }

    JSTemplate.prototype = {
        name: function () {
            return this._name;
        },
        process: function (data, win) {
            _win = win || window;
            return processImp(this, win, data).result;
        },
        renderTo: function (data, elm) {
            renderToImp(this, data, elm);
            return this;
        },
        appendTo: function (data, elm) {
            appendToImp(this, data, elm);
            return this;
        },
        prependTo: function (data, elm) {
            prependToImp(this, data, elm);
            return this;
        }
    };

    _api = {
        loaded: loaded,
        isLoaded: isLoaded,
        process: process,
        renderTo: renderTo,
        appendTo: appendTo,
        prependTo: prependTo,
        getTemplate: getTemplate,
        getTemplateAsync: getTemplateAsync,
    };
    return _api;
})(res);

_loader.load();

export {
    res,
    templates,
};

export const val = res.val.bind(res);
export const get = res.get.bind(res);
export const format = res.format.bind(res);

export default res;

