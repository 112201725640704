import * as ko from 'knockout';
import { Subject } from 'rxjs';

import AccountProfileModel from 'account/models/profile.model';
import {
    NameSectionViewModel as NameSection,
    NicknameSectionViewModel as NicknameSection,
    PublicPhoneSectionViewModel as PublicPhoneSection,
    UserPhotoSectionViewModel as UserPhotoSection,
    DateOfBirthSectionViewModel as DateOfBirthSection,
    AddressLine1SectionViewModel as AddressLine1Section,
    AddressLine2SectionViewModel as AddressLine2Section,
    CitySectionViewModel as CitySection,
    CountrySectionViewModel as CountrySection,
    RegionSectionViewModel as RegionSection,
    PostalCodeSectionViewModel as PostalCodeSection,
    MobileNumberSectionViewModel as MobileNumberSection,
    ConsentSectionViewModel as ConsentSection,
} from 'account/viewmodels/profile.sections';

import LogonNameSection from 'account/viewmodels/logonName.section.viewmodel';
import EmailSection from 'account/viewmodels/email.section.viewmodel';
import PasswordSection from 'account/viewmodels/password.section.viewmodel';
import PaymentHistoryViewModel from 'account/viewmodels/paymenthistory.viewmodel';
import SubscriptionViewModel from 'account/viewmodels/subscription.viewmodel';
import CurrentBundleViewModel from 'account/viewmodels/bundle.viewmodel';
import ClientActivationsViewModel from 'account/viewmodels/clientactivations.viewmodel';

/**
 * Manage Account Page view model
 * Combines all parts of account manage page into one view model
 */
class ManageAccountViewModel {
    constructor(subscription, bundles, profileConfig) {
        // -- infrastructure
        this._isLinked = false;
        this._expandEventStream = new Subject();

        this.isLoading = ko.observable(true);

        // -- data
        this.profile = new AccountProfileModel(profileConfig);

        // profile sections
        this.nameSection = new NameSection({ profile: this.profile, expandEventStream: this._expandEventStream });
        this.emailSection = new EmailSection({ profile: this.profile, expandEventStream: this._expandEventStream });
        this.passwordSection = new PasswordSection({ profile: this.profile, expandEventStream: this._expandEventStream });
        this.nicknameSection = new NicknameSection({ profile: this.profile, expandEventStream: this._expandEventStream });
        this.publicPhoneSection = new PublicPhoneSection({ profile: this.profile, expandEventStream: this._expandEventStream });
        this.userPhotoSection = new UserPhotoSection({ profile: this.profile });
        this.clientActivations = new ClientActivationsViewModel({ expandEventStream: this._expandEventStream });
        this.logonNameSection = new LogonNameSection({ profile: this.profile, expandEventStream: this._expandEventStream });
        this.dateOfBirthSection = new DateOfBirthSection({ profile: this.profile, expandEventStream: this._expandEventStream });
        this.addressLine1Section = new AddressLine1Section({ profile: this.profile, expandEventStream: this._expandEventStream });
        this.addressLine2Section = new AddressLine2Section({ profile: this.profile, expandEventStream: this._expandEventStream });
        this.citySection = new CitySection({ profile: this.profile, expandEventStream: this._expandEventStream });
        this.countrySection = new CountrySection({ profile: this.profile, expandEventStream: this._expandEventStream });
        this.regionSection = new RegionSection({ profile: this.profile, expandEventStream: this._expandEventStream });
        this.postalCodeSection = new PostalCodeSection({ profile: this.profile, expandEventStream: this._expandEventStream });
        this.mobileNumberSection = new MobileNumberSection({ profile: this.profile, expandEventStream: this._expandEventStream });
        this.consentSection = new ConsentSection();

        // current subscription
        this.subscription = new SubscriptionViewModel(subscription);

        this.manage = this.subscription.manage.bind(this.subscription);

        this.currentBundlesInfo = bundles ? ko.observableArray(bundles.map(bundle => new CurrentBundleViewModel(bundle))) : null;

        this.paymentHistory = new PaymentHistoryViewModel();
    }

    // loads model with async data
    // it's essential to follow the order:
    // 1. instantiate model
    // 2. apply knockout binding
    // 3. load data
    // because load data can trigger adding of new html elements into DOM
    load() {
        // start loading
        return Promise.all([this.profile.load(), this.clientActivations.load(), this.paymentHistory.load()])
            .then(() => {
                this._link();
            })
            .finally(() => {
                this.isLoading(false);
            });
    }

    // links models to each other
    _link() {
        // link only once
        if (this._isLinked) {
            return;
        }

        this._isLinked = true;
    }
}

export default ManageAccountViewModel;
