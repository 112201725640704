import { IEvent } from '@pressreader/src/eventbus/events';

import { EventTypes, ExceptionPayload } from 'exceptions/models/models';

class ExceptionEvent implements IEvent {
    readonly type = EventTypes.Exception;
    constructor(public payload: ExceptionPayload) {}
}

export { ExceptionEvent };
