import { AnyPopup, IAlertPopup, IDialogPopup, IMenuPopup } from '@pressreader/popups-types';

function isDialogPopup(popup: AnyPopup): popup is IDialogPopup {
    return popup?.type === 'dialog';
}

function isMenuPopup(popup: AnyPopup): popup is IMenuPopup {
    return popup?.type === 'menu';
}

function isAlertPopup(popup: AnyPopup): popup is IAlertPopup {
    return popup?.type === 'alert';
}

export { isDialogPopup, isMenuPopup, isAlertPopup };
