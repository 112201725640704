/* eslint-disable prefer-rest-params */
/* eslint-disable no-console */
import config from '@pressreader/src/deprecated/nd.config';

import { EcommerceTypes } from 'analytics/ecommerce/models/models';
import { IEventInfo, IPageViewInfo, ITracker, ITransactionInfo } from 'analytics/models/models';
import { getGtagType } from 'analytics/trackers/helpers/googleGtagTypes';
import { SetupUserId } from 'analytics/trackers/helpers/UserId';

let _debug = false;

function gtag(...args) {
    if (_debug) {
        console.log('googleanalytics - gtag ', arguments);
    }
    dataLayer.push(arguments);
}

// https://developers.google.com/analytics/devguides/collection/gtagjs
function initGoogleAnalytics(w, d, s, l, i, c) {
    i.split(',').forEach(element => {
        w[l] = w[l] || c ? [c] : [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtag/js?id=' + element + dl;
        f.parentNode.insertBefore(j, f);
    });
}

declare let window: any;
declare let dataLayer: any;

class GoogleAnalytics implements ITracker {
    private _debug: boolean;
    private _ecommerceEnabled = false;
    private _enabled = false;
    private _events: any = { enabled: false };
    private _GA_MEASUREMENT_ID: string;
    private _loaded: Promise<boolean>;
    private _siteSpeedEnabled: boolean;

    constructor() {
        this._loaded = (async () => {
            await config.loaded();
            const cfg = config.get('analytics.Google');

            this._debug = _debug = !!cfg.Debug;
            if (this._debug) {
                console.log('googleanalytics - cfg ', cfg);
            }

            if (!cfg || !cfg.Enabled) {
                return false;
            }
            this._enabled = true;
            this._events = cfg.Events && cfg.Events.Enabled ? { enabled: true, categories: cfg.Events.Categories } : { enabled: false };

            this._GA_MEASUREMENT_ID = cfg.ClientId;

            if (!window.dataLayer) {
                initGoogleAnalytics(window, document, 'script', 'dataLayer', cfg.ClientId, cfg.CustomVariables);
            }

            // Secure _ga, _gid, _gat_gtag_ClientId cookies
            gtag('set', {
                cookie_flags: cfg.CookieSameSite || 'SameSite=None;Secure',
            });

            // https://developers.google.com/analytics/devguides/collection/ga4/views?client_type=gtag#manual_pageviews
            cfg.ClientId.split(',').forEach((element: string) => {
                gtag('config', element, {
                    send_page_view: false,
                });
            });

            if (cfg.Ecommerce && cfg.Ecommerce.Enabled) {
                this._ecommerceEnabled = true;
            }

            if (cfg.SiteSpeed && cfg.SiteSpeed.Enabled) {
                this._siteSpeedEnabled = true;
            }

            if (cfg.UseUserId) {
                SetupUserId((user: string) => {
                    cfg.ClientId.split(',').forEach((element: string) => {
                        gtag('config', element, {
                            user_id: user,
                        });
                    });
                });
            }

            return true;
        })();
    }

    toString() {
        return `Google Analytics (${this._GA_MEASUREMENT_ID})`;
    }

    loaded() {
        return this._loaded;
    }

    enabled() {
        return this._enabled;
    }

    pageView(info: IPageViewInfo = {}) {
        if (!this._enabled) {
            if (this._debug) {
                console.log('NOT ENABLED pageView', info);
            }
            return;
        }

        this._GA_MEASUREMENT_ID.split(',').forEach(() => {
            gtag('event', 'page_view', {
                page_title: info.title ? info.title : '',
                page_location: info.page ? info.page : `${window.location.pathname}${window.location.search}${window.location.hash}`,
            });
        });
    }

    event(info: IEventInfo) {
        if (!this._enabled || !this._events.enabled || (!this._events.categories[info.category]?.Enabled && !this._events.categories['*']?.Enabled)) {
            if (this._debug) {
                console.log('NOT ENABLED event', info);
            }
            return;
        }

        gtag('event', info.action, {
            event_category: info.category,
            event_label: info.label,
            event_value: info.value,
        });
    }

    // https://developers.google.com/analytics/devguides/collection/gtagjs/ecommerce
    transaction(transaction: ITransactionInfo) {
        if (!this._enabled || !this._ecommerceEnabled || !transaction) {
            if (this._debug) {
                console.log('NOT ENABLED transaction', transaction);
            }
            return;
        }

        let gData: any = {};

        let products: any[];
        if (transaction.data.items) {
            products = transaction.data.items.map(m => {
                return {
                    id: m.id,
                    name: m.name,
                    brand: m.brand,
                    category: m.category,
                    variant: m.variant,
                    price: m.total,
                    quantity: m.quantity,
                    coupon: m.coupon,
                };
            });
        }

        switch (transaction.type) {
            case EcommerceTypes.AddPaymentInfo:
                gData = {
                    currency: transaction.data.currency,
                    payment_type: transaction.data.cardType,
                    items: products,
                    value: transaction.data.value,
                };

                break;
            case EcommerceTypes.AddToCart:
                gData = {
                    currency: transaction.data.currency,
                    items: products,
                    value: transaction.data.value,
                };
                break;

            case EcommerceTypes.BeginCheckout:
            case EcommerceTypes.RemoveFromCart:
                gData = {
                    currency: transaction.data.currency,
                    items: products,
                    value: transaction.data.value,
                };
                break;

            case EcommerceTypes.CheckoutProgress:
                break;

            case EcommerceTypes.Purchase:
                gData = {
                    affiliation: transaction.data.affiliation,
                    currency: transaction.data.currency,
                    items: products,
                    shipping: 0,
                    tax: transaction.data.tax,
                    transaction_id: transaction.data.transactionId,
                    value: transaction.data.value,
                };
                break;

            case EcommerceTypes.SelectContent:
                // https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#measure_promotion_clicks
                if (transaction.data.promotions) {
                    gData = {
                        promotions: transaction.data.promotions.map(m => {
                            return {
                                id: m.id,
                                name: m.name,
                            };
                        }),
                    };
                } else {
                    throw new Error('select_content type not supported');
                }
                break;

            case EcommerceTypes.SetCheckoutOption:
                // https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#2_measure_checkout_options
                gData = {
                    checkout_step: transaction.data.checkout_step,
                    checkout_option: transaction.data.checkout_option,
                    value: transaction.data.value,
                };
                break;

            default:
                throw new Error(`Not Implemented: ${transaction.type}`);
        }

        gtag('event', getGtagType(transaction.type), gData);
    }

    // https://developers.google.com/analytics/devguides/collection/gtagjs/user-timings
    timing(category: string, variable: string, timeSpent: string, label: string) {
        if (!this._enabled || !this._siteSpeedEnabled) {
            if (this._debug) {
                console.log('NOT ENABLED timing', { category, variable, timeSpent, label });
            }
            return;
        }

        gtag('event', 'timing_complete', {
            name: variable,
            value: timeSpent,
            event_category: category,
            event_label: label,
        });
    }

    exception(description: string, fatal: boolean): void {
        gtag('event', 'exception', {
            description: description,
            fatal: fatal,
        });
    }

    customSourceTracking(source: string, medium: string): void {
        this._GA_MEASUREMENT_ID.split(',').forEach(element => {
            gtag('config', element, {
                campaign_source: source,
                campaign_medium: medium,
            });
        });
    }
}

const googleAnalytics: ITracker = new GoogleAnalytics();

export { googleAnalytics };
