import * as $ from 'jquery';
import * as ko from 'knockout';

import { defineShim } from '@pressreader/src/shim/requirejs';
import { browser, device } from '@pressreader/utils';

import ndGigya from 'nd.gigya';
import ndJanrain from 'nd.janrain';
import accountView from 'nd.views.account';
import ndReceipt from 'nd.views.receipt';
import passwordView from 'nd.views.resetPassword';
import { analytics } from 'analytics/analytics';
import ConsentViewModel from 'gdpr/consent';

defineShim('jquery', function () {
    return $;
});
defineShim('knockout', function () {
    return ko;
});

defineShim('nd.browser', function () {
    return browser;
});
defineShim('nd.device', function () {
    return device;
});
defineShim('nd.views.account', function () {
    return accountView;
});
defineShim('nd.analytics', function () {
    return analytics;
});
defineShim('nd.janrain', function () {
    return ndJanrain;
});
defineShim('nd.gigya', function () {
    return ndGigya;
});
defineShim('gdpr/consent', function () {
    return ConsentViewModel;
});
defineShim('nd.views.resetPassword', function () {
    return passwordView;
});
defineShim('nd.views.receipt', function () {
    return ndReceipt;
});
