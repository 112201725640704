import { UAParser } from 'ua-parser-js';

import { IDeviceInfo, IOSInfo, IBrowserInfo, ISessionInfo } from '@pressreader/analytics-types';
import { buildBrowserInfo, buildDeviceInfo, buildOSInfo } from '@pressreader/analytics-core';
import { getAnalyticsSessionId } from '@pressreader/analytics-utils';

interface IUserAgentInfo {
    deviceInfo: IDeviceInfo;
    osInfo: IOSInfo;
    browserInfo: IBrowserInfo;
    sessionInfo: ISessionInfo;
}

const ua = new UAParser().getResult();

const userAgentInfo: IUserAgentInfo = {
    deviceInfo: buildDeviceInfo(ua),
    osInfo: buildOSInfo(ua),
    browserInfo: buildBrowserInfo(ua),
    sessionInfo: { id: getAnalyticsSessionId(), referrer: document.referrer },
};

export { IUserAgentInfo, userAgentInfo };
