import { keysOf } from './keys.of';

const emptyTestRegex = new RegExp('^\\s*$');
const sizeAbbreviations = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const paramRegex = /{(\d+)}/g;
const rtlSymbolsRegex = /[\u0591-\u07FF\u200F\u202B\u202E\uFB1D-\uFDFD\uFE70-\uFEFC]/;

/**
 * Determines whether the argument type is a string and it's value is
 * `null`, `undefined`, `empty` or `whitespace`.
 *
 * @param value - input string.
 * @returns true if string is null or empty.
 */
function isNullOrWhitespace(value: string) {
    return typeof value !== 'string' || value === '' || emptyTestRegex.test(value);
}

/**
 * Formats string using template.
 * @see http://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format
 *
 * @example format('Hi, {0} {1}', 'John', 'Rembo')
 */
function format(template: string, ...params: string[]) {
    if (isNullOrWhitespace(template) || params.length === 0) {
        return template;
    }
    return template.replace(paramRegex, (match, index) => (params[index] !== undefined ? params[index] : match));
}

/**
 * Converts number to a string with Kb/Mb/Gb suffix.
 *
 * @see http://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript?answertab=active#tab-top
 */
function formatBytes(bytes: number, decimals = 0) {
    if (typeof bytes !== 'number' || isNaN(bytes)) {
        return bytes;
    }

    if (bytes === 0 || bytes === 1) {
        return `${bytes} Byte`;
    }

    const k = 1000;
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const value = parseFloat((bytes / Math.pow(k, i)).toFixed(decimals));
    return `${value} ${sizeAbbreviations[i]}`;
}

const reverseAccentsMap = {
    A: 'АÁÀĂẮẰẴẲÂẤẦẪẨǍÅǺÄǞÃȦǠĄĀẢȀȂẠẶẬḀȺǼǢ',
    a: 'аáàăắằẵẳâấầẫẩǎåǻäǟãȧǡąāảȁȃạặậḁⱥǽǣẚ',
    B: 'БḂḄḆɃƁƃ',
    b: 'бḃḅḇƀɓᵬ',
    C: 'ĆĈČĊÇḈȻƇ',
    c: 'ćĉčċçḉȼƈɕ',
    D: 'ĎḊḐḌḒḎĐƉƊƋƌ',
    d: 'ďḋḑḍḓḏđᵭɖɗȡð',
    E: 'ÈÉƏĔÊẾỀỄỂĚËẼĖȨḜĘĒḖḔẺȄȆẸỆḘḚɆƎ',
    e: 'éèǝĕêếềễểěëẽėȩḝęēḗḕẻȅȇẹệḙḛɇɚɝ',
    F: 'ФḞƑ',
    f: 'фḟᵮƒ',
    G: 'ГǴĞĜǦĠĢḠǤƓ',
    g: 'гǵğĝǧġģḡǥɠ',
    H: 'ĤȞḦḢḨḤḪH̱ĦⱧ',
    h: 'ĥȟḧḣḩḥḫẖħⱨ',
    I: 'ÍÌĬÎǏÏḮĨİĮĪỈȈȊỊḬIƗ',
    i: 'íìĭîǐïḯĩiįīỉȉȋịḭıɨ',
    J: 'ĴJJ̌Ɉ',
    j: 'ĵȷǰɉʝɟʄ',
    K: 'ḰǨĶḲḴƘⱩ',
    k: 'ḱǩķḳḵƙⱪ',
    L: 'ĹĽĻḶḸḼḺĿȽⱠⱢꞭ',
    l: 'ĺľļḷḹḽḻŀƚⱡɫɬɭȴ',
    M: 'ḾṀṂⱮ',
    m: 'ḿṁṃɱ',
    N: 'ŃǸŇÑṄŅṆṊṈƝȠ',
    n: 'ńǹňñṅņṇṋṉɲƞɳȵ',
    O: 'ÓÒŎÔỐỒỖỔǑÖȪŐÕṌṎȬȮȰØǾǪǬŌṒṐỎȌȎƠỚỜỠỞỢỌỘƟ',
    o: 'óòŏôốồỗổǒöȫőõṍṏȭȯȱøǿǫǭōṓṑỏȍȏơớờỡởợọộɵ',
    P: 'ṔṖⱣƤ',
    p: 'ṕṗᵽƥ',
    Q: 'Ɋ',
    q: 'ɋʠ',
    R: 'ŔŘṘŖȐȒṚṜṞɌⱤ',
    r: 'ŕřṙŗȑȓṛṝṟɍɼɽɾᵳᵲ',
    S: 'ŚṤŜŠṦṠŞṢṨȘ',
    s: 'śṥŝšṧṡşṣṩșʂẛß',
    T: 'ÞŤT̈ṪŢṬȚṰṮŦȾƬƮ',
    t: 'þťẗṫţṭțṱṯŧⱦƭʈȶƫᵵ',
    U: 'ÚÙŬÛǓŮÜǗǛǙǕŰŨṸŲŪṺỦȔȖƯỨỪỮỬỰỤṲṶṴɄ',
    u: 'úùŭûǔůüǘǜǚǖűũṹųūṻủȕȗưứừữửựụṳṷṵʉ',
    V: 'ṼṾƲ',
    v: 'ṽṿʋ',
    W: 'ẂẀŴWẄẆẈ',
    w: 'ẃẁŵẘẅẇẉ',
    X: 'ẌẊ',
    x: 'ẍẋ',
    Y: 'ÝỲŶY̊ŸỸẎȲỶỴɎƳ',
    y: 'ýỳŷẙÿỹẏȳỷỵɏƴʏ',
    Z: 'ŹẐŽŻẒẔƵȤⱫǮ',
    z: 'źẑžżẓẕƶȥʐʑⱬƺ',
};

const accentMap: Record<string, keyof typeof reverseAccentsMap> = {};
keysOf(reverseAccentsMap).forEach(toChar => {
    for (const char of reverseAccentsMap[toChar]) {
        accentMap[char] = toChar;
    }
});

/**
 * Folds accents in string.
 * Replaces non-latin characters with their latin equivalents.
 *
 * @param input - input string
 * @returns new folded string
 */
function foldAccents(input: string) {
    if (isNullOrWhitespace(input)) {
        return input;
    }

    return Array.from(input)
        .map(char => accentMap[char] ?? char)
        .join('');
}

/**
 * Splits a string by a separator. The resulting segments will be trimmed. Empty resulting segments will be removed.
 * @param str - the string to split.
 * @param separator - the separator pattern to split by.
 * @returns the array of string segments.
 */
function split(input: string, separator: string) {
    if (isNullOrWhitespace(input)) {
        return [];
    }

    return input
        .split(separator)
        .map(str => str.trim())
        .filter(str => !isNullOrWhitespace(str));
}

function hashCode(str: string) {
    let hash = 0;
    if (str.length === 0) {
        return hash;
    }
    for (let i = 0; i < str.length; i++) {
        const chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

/**
 * Truncates string.
 */
function truncate(source: string, number: number, ending = '...') {
    if (!source || !number) {
        return source;
    }
    if (number < 0) {
        throw new Error();
    }

    return source.length <= number ? source : source.substring(0, number) + ending;
}

function truncateByWord(source: string, number: number, ending = '...') {
    if (!source || !number) {
        return source;
    }
    if (number < 0) {
        throw new Error();
    }
    if (source.length <= number) {
        return source;
    }
    const words = source.split(' ');
    const truncatedWords = [];
    let currentLength = 0;
    for (const word of words) {
        if (currentLength + word.length + 1 <= number) {
            truncatedWords.push(word);
            currentLength += word.length + 1;
        } else {
            break;
        }
    }
    return truncatedWords.join(' ') + ending;
}

/**
 * Wrap the opposite-direction phrase using the following format {RLE}{phraze}{PDF}{LRM}
 *  refer for more details: https://www.w3.org/International/questions/qa-bidi-unicode-controls#exceptions
 */
function embedRTL(text: string) {
    if (typeof text !== 'string' || !rtlSymbolsRegex.test(text)) {
        return text;
    }
    return `\u202B${text}\u202C\u200E`;
}

const charCode0 = '0'.charCodeAt(0);
const charCode9 = '9'.charCodeAt(0);

function isDigitCharCode(charCode: number): boolean {
    return charCode >= charCode0 && charCode <= charCode9;
}

function isNonDigitCharCode(charCode: number): boolean {
    return !Number.isNaN(charCode) && !isDigitCharCode(charCode);
}

function isPrintableChar(str: string) {
    return str.length === 1 && !!str.match(/\S/);
}

function deleteChar(input: string, pos: number): string {
    return input.substring(0, pos) + input.substring(pos + 1);
}

function deleteCharRange(input: string, posFrom: number, posTo: number) {
    if (posFrom > posTo) {
        const swap = posFrom;
        posFrom = posTo;
        posTo = swap;
    }

    return input.substring(0, posFrom) + input.substring(posTo + 1);
}

function unhyphen(input: string): string {
    if (!input) {
        return input;
    }

    return input.replace(/\u00AD/g, '');
}

function getFirstLettersOfTwoWords(txt = '') {
    let result = '';

    for (const word of txt.split(' ')) {
        if (word) {
            result += word[0];
            if (result.length === 2) {
                break;
            }
        }
    }

    return result;
}

function toUrlFriendlyFormat(str: string): string {
    return str
        .toLowerCase()
        .trim() // Remove leading and trailing spaces
        .replace(/[^\w\s]/g, '') // // remove special characters except whitespace
        .replace(/\s+/g, '_'); // Replace multiple spaces with a single underscore
}

export {
    format,
    formatBytes,
    isNullOrWhitespace,
    foldAccents,
    split,
    truncate,
    truncateByWord,
    hashCode,
    embedRTL,
    isDigitCharCode,
    isNonDigitCharCode,
    isPrintableChar,
    deleteChar,
    deleteCharRange,
    unhyphen,
    getFirstLettersOfTwoWords,
    toUrlFriendlyFormat,
};
