import { createActionCreator, createGenericActionCreator } from '@pressreader/appstore';
import { NavConfig, ViewName } from '@pressreader/navigation-types';

import { State, To, Update } from '../types';

const prefix = '@pressreader/navigation-core/';

interface RouteChangeBeganPayload {
    navState: { state?: Record<string, unknown>; stateLess?: Record<string, unknown>; viewName: ViewName };
}

interface RouteChangeDonePayload {
    newConfig: NavConfig;

    /** Route changed to legacy view. */
    isLegacyRoute: boolean;
}

interface UpdateNavConfigPayload {
    newNavConfig: NavConfig;

    /** Whether to update url or not. Or use another NavConfig for url generation. */
    urlNavConfig?: boolean | NavConfig;
}

interface NavConfigUpdatedPayload {
    newNavConfig: NavConfig;
}

interface NavigateToPayload {
    resetState?: boolean;

    replace?: boolean;

    viewName: ViewName;
    params?: any;
}

interface NavigateToLinkPayload {
    link: string;

    openNewWindow?: boolean;
}

interface NavigateHomePayload {
    replace?: boolean;
}

interface WillNavigatePayload {
    currentConfig: NavConfig;
}

interface NavigateBeganPayload {
    isLegacyRoute?: boolean;

    prevConfig: NavConfig | null;
    config: NavConfig;
}

interface NavigateDonePayload {
    isLegacyRoute?: boolean;

    prevConfig: NavConfig | null;
    config: NavConfig;
}

interface NavigateFailedPayload {
    error: unknown;

    config: NavConfig;
}

interface HistoryPayload {
    to: To;
    state?: State;
}

interface NavigateToViewParams {
    [key: string]: unknown;

    cacheKey?: string;
    referrer?: string;
    replaced?: boolean;
    viewName?: string;
}

interface NavigateToViewPayload {
    viewName: ViewName;
    params: NavigateToViewParams;
    replace?: boolean;
    ignoreIssueInfo?: boolean;
    isForceReplace?: boolean;
}

interface UpdateStatePayload {
    params: Record<string, any>;
    reload?: boolean;
    url?: string;
}

export const routeChangeBegan = createActionCreator<RouteChangeBeganPayload>(`${prefix}route_change_began`);
export const routeChangeDone = createActionCreator<RouteChangeDonePayload>(`${prefix}route_change_done`);

export const updateNavConfigAction = createActionCreator<UpdateNavConfigPayload>(`${prefix}update_nav_config`);
export const navConfigUpdated = createActionCreator<NavConfigUpdatedPayload>(`${prefix}nav_config_updated`);

export const navigateBackAction = createActionCreator(`${prefix}navigate_back`);

export const navigateToAction = createActionCreator<NavigateToPayload>(`${prefix}navigate_to`);
export const navigateToLinkAction = createActionCreator<NavigateToLinkPayload>(`${prefix}navigate_to_link`);
export const navigateHomeAction = createActionCreator<NavigateHomePayload>(`${prefix}navigate_home`);

export const willNavigateEvent = createActionCreator<WillNavigatePayload>(`${prefix}will_navigate`);

export const navigateBegan = createGenericActionCreator<NavigateBeganPayload>(`${prefix}navigate_began`);
export const navigateDone = createGenericActionCreator<NavigateDonePayload>(`${prefix}navigate_done`);
export const navigateFailed = createGenericActionCreator<NavigateFailedPayload>(`${prefix}navigate_failed`);

export const updateLocationAction = createActionCreator<Update>(`${prefix}location_update`);
export const pushStateToHistoryAction = createActionCreator<HistoryPayload>(`${prefix}history_push`);
export const replaceStateHistoryAction = createActionCreator<HistoryPayload>(`${prefix}history_replace`);
export const replaceStateWithReloadHistoryAction = createActionCreator<HistoryPayload>(`${prefix}history_replace_reload`);
export const goHistoryAction = createActionCreator<number>(`${prefix}history_go`);
export const backHistoryAction = createActionCreator(`${prefix}history_back`);
export const forwardHistoryAction = createActionCreator(`${prefix}history_forward`);
export const goViewAction = createActionCreator<NavigateToViewPayload>(`${prefix}history_go_view`);
export const resetStateHistoryAction = createActionCreator(`${prefix}history_reset_state`);
export const updateStateHistoryAction = createActionCreator<UpdateStatePayload>(`${prefix}history_update_state`);

// These are required since their respective action creators are generic.
export { NavigateBeganPayload, NavigateDonePayload, NavigateFailedPayload, NavigateToPayload };
