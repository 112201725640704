import * as $ from 'jquery';
import { debounce } from 'lodash';

const DEBOUNCE_DURATION = 1500;

abstract class BaseUploader<T> {
    protected readonly _uploadBufferDataDebounced: ReturnType<typeof debounce>;

    constructor(debounceDuration: number = DEBOUNCE_DURATION) {
        this._uploadBufferDataDebounced = debounce(() => {
            this._uploadBufferData();
        }, debounceDuration);
        $(window).on('unload', () => {
            this._uploadBufferData();
        });
    }

    abstract upload(data: T[], force?: boolean): void;

    purge() {
        this._uploadBufferData();
    }

    protected abstract _uploadBufferData(): void;
}

export { BaseUploader };
