import * as ko from 'knockout';
import * as $ from 'jquery';
import * as clamp from 'clamp';
        

        /*
         * Usage:
         * <div data-bind="nd-clamp: clamp, [max-lines=5]">
         * </div>
         *
         *
         * if clamp evaluates to true, element text is clamped.
         * */
        function ClampBindingHandler() {

            this.update = function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                var lines = ko.unwrap(valueAccessor());

                // apply clamping if binding returns true AND
                // it has never been applied before OR max number of displayed lines has changed
                if (lines && lines > 0) {
                    var hidden = $(element).css('visibility') === 'hidden';

                    // do not use native (webkit) clamp if element is hidden
                    // see https://bugs.webkit.org/show_bug.cgi?id=45399
                    clamp(element, { clamp: lines, useNativeClamp: !hidden });
                }
            }
        }

        ko.bindingHandlers['nd-clamp'] = new ClampBindingHandler();
    