import { AuthSignInSystemType, IAuthSignInSystem } from '../auth.system';

export interface IExternalSignInSystemV1ConfigDto {
    name: string;
    isPrimary: boolean;
    titleResourceId: string;
}

export interface IExternalSignInSystemV2ConfigDto {
    enabled: 'true' | 'false';
    IsPrimary: boolean;
    TitleResourceId: string;
}

export interface IExternalSignInSystemV3ConfigDto {
    IsPrimary: boolean;
    TitleResourceId: string;
    CtaResourceId?: string;
}

export function mapExternalSignInSystemV1(dto: IExternalSignInSystemV1ConfigDto): IAuthSignInSystem {
    return { ...dto, type: dto.name as AuthSignInSystemType, featured: false };
}

export function mapExternalSignInSystemV2(name: string, dto: IExternalSignInSystemV2ConfigDto, featured: boolean): IAuthSignInSystem {
    return {
        type: name as AuthSignInSystemType,
        featured,
        isPrimary: dto.IsPrimary,
        titleResourceId: dto.TitleResourceId,
    };
}

export function mapExternalSignInSystemV3(name: string, dto: IExternalSignInSystemV3ConfigDto, featured: boolean): IAuthSignInSystem {
    return {
        type: name as AuthSignInSystemType,
        featured,
        isPrimary: dto.IsPrimary,
        titleResourceId: dto.TitleResourceId,
        ctaResourceId: dto.CtaResourceId,
    };
}
