/* eslint-env es6 */

// css_browser_selector fixes weird issues in firefox (mozilla) for handling click events
import 'css_browser_selector';

import { Observable } from 'rxjs';
import { isFunction, forEach } from 'lodash';
import { ignoreEvent } from 'nd.gestures.invariants';

// nd.gestures should not apply its logic over events fired on the clickable element
// as it prevents browser 'click' event from being triggered.
// (internally nd.gestures module widely uses preventDefault)
const preventNdGesturesHandler = e => ignoreEvent(e);

// we need to stop propagating touch move events up to the tree
// as there is a touchmove handler listening on document that calls e.preventDefault()
// this causes click events to disappear on Mobile Safari
// https://docs.google.com/document/d/12k_LL_Ot9GjF8zGWP9eI_3IMbSizD72susba0frg44Y/edit
const preventTouchMovePropagationHandler = e => e.stopPropagation();

/**
 * Creates a new subscription that provides messages for 'click' event for the specified DOM element.
 * @param {Element} elem - DOM element.
 */
function create(elem) {
    if (!isFunction(elem && elem.addEventListener)) {
        throw new Error("Parameter 'Elem' is required and has to be a DOM element.");
    }

    const source = new Observable(observer => {
        const clickHandler = e => observer.next(e);
        const handlersDef = {
            touchstart: preventNdGesturesHandler,
            touchmove: preventTouchMovePropagationHandler,
            click: clickHandler,
        };

        // attach listeners to the events described above.
        forEach(handlersDef, (handler, event) => elem.addEventListener(event, handler));

        // on subscription dispose, remove all listeners.
        return () => forEach(handlersDef, (handler, event) => elem.removeEventListener(event, handler));
    });

    return source;
}

export default { create };
