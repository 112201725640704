import * as ko from 'knockout';

    var customComponents = [];

    function nop(){ };

    (function(ko, components){

        var res = {
            name: 'nd-textbox'
        };

        res.settings = {
            viewModel: function(params){
                var self = this;

                self.value = params.value ? params.value : ko.observable('');
                self.event = params.event ? params.event : {};

                self.spanAttr = params.spanAttr ? params.spanAttr : {};
                self.inputAttr = params.inputAttr ? params.inputAttr : {};

                self.isFocused = ko.observable(false);
                self.hasReset = params.hasReset === undefined ? ko.observable(true) : ko.observable(params.hasReset);

                self.old = params.oldValue ? params.oldValue : undefined;

                self.onClear = params.onclear;

                self.clear = function(){
                    if (self.onClear){
                        self.onClear();
                    }

                    self.value('');
                    self.isFocused(true);
                };
            },
            template: '<span data-bind="attr: spanAttr, css: { \'has-value\': isFocused() && value(), focus: isFocused, \'has-reset\': hasReset }" class="textbox">' +
                '      <input type="text" data-bind="attr: inputAttr, textInput: value, oldValue: old, hasFocus: isFocused, event: event" />' +
                '      <a href="javascript:void(0)" class="b-reset" data-bind="event: { mousedown: clear.bind() }" tabindex="-1"></a>' +
                '</span>'
        };

        components.push(res);

    })(ko, customComponents);

    (function(ko, components){

        var res = {
            name: 'nd-autocompleteitems'
        };

        res.settings = {
            viewModel: function(params){
                var self = this;

                self.items = params.items;;
                self.isVisible = params.visible;
                self.onmousedown = params.onselectitem ? params.onselectitem : nop;
                self.css = params.css;
            },
            template: '<div class="dropmenu" data-bind="visible: isVisible, style: css">' +
                '<div class="dropmenu-body">' +
                '<div class="pop-list">' +
                '<ul id="someid" data-bind="foreach: items">' +
                '<li>' +
                '<a href="javascript:void(0)" data-bind="event: { mousedown: $parent.onmousedown.bind($root, $data) }">' +
                '<em data-bind="text: displayName"></em>' +
                '</a>' +
                '</li>' +
                '</ul>' +
                '</div>' +
                '</div>' +
                '</div>'
        };

        components.push(res);

    })(ko, customComponents);

    (function(ko, components){

        function renderAutocomplete(){
            var dropMenu = $('<nd-autocompleteitems params="visible: isAutocompleteItemsVisible, items: items, onselectitem: onSelectItem, css: dropDownCss"></nd-autocompleteitems>');

            $('body').append(dropMenu);

            return dropMenu;
        };

        function generateAutocompleteId(){
            return 'ndAutocompleteId_' + $('nd-autocomplete[id]').length;
        };

        var res = {
            name: 'nd-autocomplete'
        };

        res.settings = {
            viewModel: function(params){
                var self = this;

                params.inputAttr = params.inputAttr ? params.inputAttr : {};
                params.inputAttr.id = params.inputAttr.id ? params.inputAttr.id : generateAutocompleteId();

                self.showAutocomplete = ko.observable(false);
                self.items = ko.observable([]);

                self.resetAutocomplete = function(canShow){
                    self.items([]);
                    self.showAutocomplete(!!canShow);
                };

                self.isAutocompleteItemsVisible = ko.computed(function(){
                    return self.showAutocomplete() && !!self.items().length;
                });

                var loadItems = nop;
                if (params.loadItems){
                    loadItems = function(){
                        if (self.showAutocomplete() && self.txtParams.value()){
                            params.loadItems(self.items);
                        }
                    };
                }
                self.loadItems = _.debounce(loadItems, 300);

                self.onSelectItem = function (item){
                    self.txtParams.value(item.displayName);

                    if (params.onselectitem){
                        params.onselectitem(item);
                    }

                    self.resetAutocomplete(false);
                }

                var event = params.event ? params.event : {};

                event.keyup = self.loadItems.bind();
                event.focusin = self.resetAutocomplete.bind(self, true);
                event.focusout = self.resetAutocomplete.bind(self, false);

                self.txtParams = {
                    value: params.value,
                    event: event,
                    spanAttr: params.spanAttr,
                    inputAttr: params.inputAttr,
                    hasReset: params.hasReset,
                    oldValue: params.oldValue,
                    onClear: params.onclear
                };

                var dropDown = renderAutocomplete();
                self.dropDownCss = ko.observable({
                    display: 'none'
                });

                if (params.locateEvent){
                    params.locateEvent(function(){
                        var inputElement = $('#' + params.inputAttr.id);
                        self.dropDownCss({
                            width : inputElement.outerWidth() + 'px',
                            left : inputElement.offset().left + 'px',
                            top : inputElement.offset().top + inputElement.outerHeight() + 'px',
                            zIndex : 20000
                        });
                    });
                }

                ko.applyBindings(self, dropDown[0]);
            },
            template: '<nd-textbox data-bind="id: id" ' +
                'params="spanAttr: txtParams.spanAttr, ' +
                'inputAttr: txtParams.inputAttr, ' +
                'event: txtParams.event, ' +
                'value: txtParams.value, ' +
                'oldValue: txtParams.oldValue, ' +
                'hasReset: txtParams.hasReset, ' +
                'onclear: txtParams.onClear">' +
                '</nd-textbox>'
        };

        components.push(res);

    })(ko, customComponents);

    (function (ko, components) {

        for (var i = 0, len = components.length; i < len; i++) {
            var current = components[i];
            ko.components.register(current.name, current.settings);
        }

    })(ko, customComponents);
