// eslint-env es6

import ndAlert from 'nd.alert';
import ndRes from 'deprecated/nd.res';
import ndActions from 'nd.actions';

export function emailRequired() {
    return ndAlert()
        .confirm(ndRes.val('Dialogs.EmailRequired.Text'), ndRes.val('Buttons.Cancel.Text'), ndRes.val('Buttons.GoToMyAccount.Text'))
        .then(() => {
            if (ndActions.myAccount) {
                ndActions.myAccount.activate();
            }
        });
}
