// eslint-env es6

/**
 * Holds current user info state shared across various components of app.
 * Broadcasts user info updates via Rx observable.
 */

import { ReplaySubject } from 'rxjs';
import socialManager from 'nd.social.manager';
import ndUser from 'nd.user';
import { logError } from 'shared/errors/error.handlers';

class UserInfo {

    constructor() {
        this._reset();
        this._loaded = false;
        this._loadingPromise = undefined;
        // we need to keep last update event in order to deliver it to subscriber
        // when the one subscribed later than event occurred
        this._updates = new ReplaySubject(1);
        this._updatesObservable = this._updates.asObservable();

        ndUser.onUserStateChanged(() => this.load(true));
    }

    get displayName() { return this._displayName; }

    get nickname() { return this._nickname; }

    get photo() { return this._photo; }

    get photoUrl() { return this._photo.url; }

    get enAccountNumber() { return this._enAccountNumber; }

    get avatarImageId() { return this._avatarImageId; }

    get email() { return this._email; }

    get enableServiceEmails() { return this._enableServiceEmails; }

    get enablePromotionalEmails() { return this._enablePromotionalEmails; }

    getProfileId() { return this._profileId; }

    getProfileIdAsync() {
        return this.load().then(() => this.getProfileId());
    }

    get loaded() {
        return this._loaded;
    }

    get updates() {
        return this._updatesObservable;
    }

    _reset() {
        this._nickname = '';
        this._photo = { url: null };
        this._enAccountNumber = '';
        this._profileId = null;
        this._avatarImageId = '';
        this._email = '';
        this._enableServiceEmails = false;
        this._enablePromotionalEmails = false;
    }

    /**
     * Loads current user info
     */
    load(forceReload = false) {
        if (forceReload) {
            this._loaded = false;
        }

        // loaded, nothing to do
        if (this._loaded) {
            return Promise.resolve();
        }

        // anonymous
        if (!ndUser.isLogin()) {
            this._reset();
            this._loaded = true;
            this._updates.next();
            return Promise.resolve();
        }

        // This code getCurrentProfilewith extra variable 'loading' inspired by jQuery implementation of Promise, which is not A+ compliant.
        // Code like 'let a = promise.then(() a => null)' will behave differently,
        // depending on whether the promise was resolved by the moment of execution.
        // In case of resolved promise: 'a' will never be set to null, becasue jQuery executes "then" synchroniously
        // so that execution order in that case will be: a = null; a = promise;
        // DO NOT CHANGE THIS CODE until made sure of Promise is A+ compliant.
        let loading = this._loadingPromise;
        if (loading === undefined) {
            this._loadingPromise = socialManager.getCurrentProfile()
                .then(data => {
                    if (data) {
                        this._displayName = data.displayName || data.nickname || '';
                        this._nickname = data.nickname || '';
                        this._photo = { url: data.photoUrl || '' };
                        this._avatarImageId = data.avatarImageId || '';
                        this._enAccountNumber = data.enAccountNumber || '';
                        this._profileId = data.profileId;
                        this._email = data.email;
                        this._enableServiceEmails = data.enableServiceEmails;
                        this._enablePromotionalEmails = data.enablePromotionalEmails;
                    } else {
                        this._reset();
                    }

                    this._loaded = true;
                    this._updates.next();
                })
                .catch(logError);

            loading = this._loadingPromise;
            loading.finally(() => {
                this._loadingPromise = undefined;
            });
        }

        return loading;
    }

    isPrivate(profileIdToCheck) {
        if (ndUser.isLogin()) {
            return this.load().then(
                () => this._profileId && profileIdToCheck && this._profileId.toUpperCase() === profileIdToCheck.toUpperCase(),
                () => Promise.resolve(false));
        }

        return Promise.resolve(false);
    }
}

export default new UserInfo();
