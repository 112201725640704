import { error } from '@pressreader/logger';
import { save } from '@pressreader/src/user/settings/emailservice';

const checked = 'checked';

function init() {
    const fields = document.querySelectorAll<HTMLElement>('[data-fields-email-settings]');
    fields.forEach(element => {
        if (element.getAttribute(checked) === checked) {
            element.parentElement.classList.add(checked);
        }
        element.addEventListener('click', evt => {
            const element = evt.target as HTMLElement;
            if (element.getAttribute(checked) === checked) {
                element.parentElement.classList.remove(checked);
                element.removeAttribute(checked);
            } else {
                element.parentElement.classList.add(checked);
                element.setAttribute(checked, checked);
            }
        });
    });

    document.getElementById('submitButton').addEventListener(
        'click',
        () => {
            const emailSettings: Record<string, string | boolean> = Array.from(fields).reduce((accumulator, currentValue) => {
                const name: string = currentValue.getAttribute('data-fields-email-settings');
                accumulator[name] = currentValue.getAttribute(checked) === checked;
                return accumulator;
            }, {});

            const encryptedAccountId = document.getElementById('cryptedUserId').getAttribute('value');

            save(emailSettings, encryptedAccountId)
                .then(() => {
                    const sectionUnsubscribe = document.getElementById('section-unsubscribe');
                    sectionUnsubscribe.style.display = 'none';
                    const sectionDone = document.getElementById('section-done');
                    sectionDone.style.display = 'block';
                })
                .catch(err => {
                    error(err);
                });
        },
        {
            once: true,
        },
    );
}

document.addEventListener(
    'DOMContentLoaded',
    () => {
        if (document.getElementById('section-unsubscribe')) {
            init();
        }
    },
    {
        once: true,
    },
);
