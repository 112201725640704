import { AuthenticationResult, AuthorizationTicket } from '@pressreader/authentication-types';
import { getCurrentPositionByClient } from '@pressreader/geo';
import { warn } from '@pressreader/logger';
import { GeoPoint } from '@pressreader/types';
import { cookies } from '@pressreader/utils';

import { httpPost } from './http';
import { getInterfaceLanguage } from './interfacelanguage';
import { getJson } from './services';

const service = `${window.basePath}authentication/v1`;

const AUTHORIZATION_TICKETS_ITEM_NAME = `pr_${window.basePath}authTickets`;

function saveAuthorizationTickets(tickets: AuthorizationTicket[]) {
    window.sessionStorage.setItem(AUTHORIZATION_TICKETS_ITEM_NAME, JSON.stringify(tickets.filter(t => t.session).map(t => t.value)));
    window.localStorage.setItem(AUTHORIZATION_TICKETS_ITEM_NAME, JSON.stringify(tickets.filter(t => !t.session).map(t => t.value)));
}

function getAuthorizationTickets(): string[] {
    const sessionTickets = JSON.parse(window.sessionStorage.getItem(AUTHORIZATION_TICKETS_ITEM_NAME) ?? '[]') as string[];
    const localTickets = JSON.parse(window.localStorage.getItem(AUTHORIZATION_TICKETS_ITEM_NAME) ?? '[]') as string[];
    return [...sessionTickets, ...localTickets];
}

function initAuthentication(): Promise<AuthenticationResult> {
    // Temporary hack => do not send tickets if PDAuth cookie is present
    const pdAuth = cookies.get('PDAuth');
    const tickets = pdAuth ? [] : getAuthorizationTickets();
    return getJson({
        url: `${service}/initialize`,
        data: {
            tickets,
            language: getInterfaceLanguage(),
            urlReferrer: window.document.referrer,
            url: window.location.href,
        },
        maxRetries: 3,
        method: 'POST',
    });
}

function apiSiteSignIn(token: string, enableAutoLogin: boolean): Promise<AuthenticationResult> {
    return httpPost({
        url: `${service}/signIn`,
        data: { signInToken: token, tickets: getAuthorizationTickets(), language: getInterfaceLanguage(), enableAutoLogin },
        requestConfig: { contentType: 'application/json', disableBearerToken: true },
    });
}

async function apiSiteSignOut(): Promise<AuthenticationResult> {
    return httpPost({
        url: `${service}/signout`,
        data: { tickets: getAuthorizationTickets(), language: getInterfaceLanguage() },
        requestConfig: { contentType: 'application/json', disableBearerToken: true },
    });
}

let lastKnowLocation: GeoPoint = undefined;

async function getGeolocation() {
    if (document.hidden) {
        // Document is hidden, returning last known location
        return lastKnowLocation;
    }
    try {
        const geoLocation = await getCurrentPositionByClient();
        lastKnowLocation = geoLocation;
        return geoLocation;
    } catch (error) {
        warn('Authentication API refreshBearerToken', 'geoLocation', error);
        return undefined;
    }
}

async function refreshBearerToken(useGeoLocation?: boolean): Promise<AuthenticationResult> {
    const geoLocation = useGeoLocation ? await getGeolocation() : undefined;
    return httpPost({
        url: `${service}/refresh`,
        data: {
            tickets: getAuthorizationTickets(),
            language: getInterfaceLanguage(),
            lat: geoLocation?.lat,
            lng: geoLocation?.lng,
        },
        requestConfig: { contentType: 'application/json', disableBearerToken: true },
    });
}

export { initAuthentication, apiSiteSignIn, apiSiteSignOut, refreshBearerToken, saveAuthorizationTickets };
