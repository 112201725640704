import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
    AuthSystem,
    IAuthenticationAnalyticsContext,
    IAuthenticationContext,
    IAuthError,
    LibraryExternalAuthProvider,
    SocialExternalAuthProvider,
} from '@pressreader/authentication-types';

export type AccountChangedEventType = 'before' | 'after';

interface AccountChangedEvent {
    type: AccountChangedEventType;
    authContext?: IAuthenticationContext;
}

const accountChangedSubject = new Subject<AccountChangedEvent>();

export const onBeforeAccountChanged = accountChangedSubject.pipe(filter(event => event.type === 'before'));
export const onAfterAccountChanged = accountChangedSubject.pipe(filter(event => event.type === 'after'));

export function publishAccountChangedEvent(type: AccountChangedEventType, authContext?: IAuthenticationContext): void {
    accountChangedSubject.next({ type, authContext });
}

interface AuthEventPayload {
    system: AuthSystem;
}
interface SignUpDonePayload extends AuthEventPayload {
    sponsorId?: number;
    analyticsCtx?: IAuthenticationAnalyticsContext;
}

export interface AuthEventMap {
    loginBegan: AuthEventPayload;
    loginDone: AuthEventPayload & { provider?: SocialExternalAuthProvider | LibraryExternalAuthProvider };
    loginFailed: { system: AuthSystem; error: IAuthError };

    signUpBegan: AuthEventPayload;
    signUpDone: SignUpDonePayload;
    signUpFailed: { error: IAuthError };

    logOutBegan: undefined;
    logOutDone: undefined;
    logOutFailed: { error: IAuthError };
}

export type AuthEventType = keyof AuthEventMap;

interface AuthEvent<T extends AuthEventType> {
    eventType: T;
    payload: AuthEventMap[T];
}

const authSubject = new Subject<AuthEvent<AuthEventType>>();

function createAuthEventSender<T extends AuthEventType>(eventType: T) {
    return (...args: AuthEventMap[T] extends undefined ? [] : [AuthEventMap[T]]) => {
        const payload = args[0];
        authSubject.next({ eventType, payload });
    };
}

function createAuthEventObservable<T extends AuthEventType>(eventType: T): Observable<AuthEvent<T>> {
    return authSubject.pipe(filter((event): event is AuthEvent<T> => event.eventType === eventType));
}

export const sendLoginBeganEvent = createAuthEventSender('loginBegan');
export const sendLoginDoneEvent = createAuthEventSender('loginDone');
export const sendLoginFailedEvent = createAuthEventSender('loginFailed');

export const sendSignUpBeganEvent = createAuthEventSender('signUpBegan');
export const sendSignUpDoneEvent = createAuthEventSender('signUpDone');
export const sendSignUpFailedEvent = createAuthEventSender('signUpFailed');

export const sendLogOutBeganEvent = createAuthEventSender('logOutBegan');
export const sendLogOutDoneEvent = createAuthEventSender('logOutDone');
export const sendLogOutFailedEvent = createAuthEventSender('logOutFailed');

export const onLoginBeganEvent = createAuthEventObservable('loginBegan');
export const onLoginDoneEvent = createAuthEventObservable('loginDone');
export const onLoginFailedEvent = createAuthEventObservable('loginFailed');

export const onSignUpBeganEvent = createAuthEventObservable('signUpBegan');
export const onSignUpDoneEvent = createAuthEventObservable('signUpDone');
export const onSignUpFailedEvent = createAuthEventObservable('signUpFailed');

export const onLogOutBeganEvent = createAuthEventObservable('logOutBegan');
export const onLogOutDoneEvent = createAuthEventObservable('logOutDone');
export const onLogOutFailedEvent = createAuthEventObservable('logOutFailed');
