import { createSelector } from 'reselect';

import { AUTHENTICATION_CORE_MODULE_NAME } from '../../constants';
import { IState } from '../reducer';

const getState = (state: { [AUTHENTICATION_CORE_MODULE_NAME]: IState }) => state[AUTHENTICATION_CORE_MODULE_NAME];

export const selectIsInitialized = createSelector(getState, state => state.initialized);

export const selectUser = createSelector(getState, state => state.user);

export const selectUserSettings = createSelector(getState, state => state.userSettings);

export const selectUserLocales = createSelector(
    selectUserSettings,
    settings => (settings && settings.feedSettings && settings.feedSettings.locales) || [],
);

export const selectAuthInProgress = createSelector(getState, state => state.authInProgress);

export const selectAuthLastError = createSelector(getState, state => state.lastAuthError);

export const selectLastRecoveryEmailSentTo = createSelector(getState, state => state.lastRecoveryEmailSentTo);
