import { IUser, IUserSettings } from '@pressreader/authentication-types';

export function createAnonymousUser(): IUser {
    return {
        id: 'anon_user_id',
        enAccountNumber: '',
        userKey: 'anon_user_key',
        name: 'anon_user',
        avatarImageId: '',
        photo: { url: null },
        email: '',
        isAuthenticated: false,
        enableServiceEmails: false,
        enablePromotionalEmails: false,
    };
}

export function createDefaultUserSettings(): IUserSettings {
    return {
        feedSettings: {
            locales: [],
            following: [],
        },
        shareEmail: null,
    };
}
