import { httpGet } from '@pressreader/services';

import { BundleOrderDto, PaymentHistoryDto, ReceiptDto, YearDto } from './types';

const serviceBasePath = 'v1/accounts/current/payments';

/**
 * @typedef PaymentHistoryItem
 * @type Object
 * @property {String} id - payment Id
 * @property {Date} date - date of payment
 * @property {Number} amount - amount
 * @property {String} formattedAmount - type name
 * @property {String} formattedDate - css class name
 */

/**
 * Gets list of user orders on year.
 *
 * @returns {Promise.<Array.<OrderHistoryItem>>} history
 */
function getPaymentHistory(year: number) {
    return httpGet<PaymentHistoryDto[]>(serviceBasePath, 'history', { year });
}

/**
 * Gets list of supported expiration months.
 *
 * @returns {Promise.<Array.<int>>} years list
 */
function getPaymentYears() {
    return httpGet<YearDto[]>(serviceBasePath, 'years');
}

function getBundleOrders() {
    return httpGet<BundleOrderDto[]>(serviceBasePath, 'bundle/orders');
}

function getIssues(orderId: string) {
    return httpGet(serviceBasePath, 'issues', { orderId });
}

function getReceipt(orderId: string) {
    return httpGet<ReceiptDto>(serviceBasePath, 'receipt', { orderId });
}

export { getPaymentYears, getPaymentHistory, getReceipt, getBundleOrders, getIssues };
