import cloneDeep from 'lodash/cloneDeep';

/**
 * @param object { [key: string]: unknown } Any object.
 * @param name Name of the property/key to look up in the object keys collection.
 * @param caseSensitive (default false) Determine if the search is case sensitive or case insensitive.
 */
interface PropertySearchParams<T> {
    object: { [key: string]: T };
    name: string;
    caseSensitive?: boolean;
}

const DefaultPropertySearchParams = {
    object: {},
    name: '',
    caseSensitive: false,
};

/**
 *
 * @param params @see PropertySearchParams
 * @returns string The first occurence of the _name_ in the right case.
 */
export function findPropertyName(params: PropertySearchParams<unknown>): string | undefined {
    const { object, name, caseSensitive } = { ...DefaultPropertySearchParams, ...params };
    const sensitive = (w: string) => w === name;
    const insensitive = (w: string) => w.toLocaleLowerCase() === name.toLocaleLowerCase();
    return Object.keys(object).find(caseSensitive ? sensitive : insensitive);
}

/**
 *
 * @param params @see PropertySearchParams
 * @returns T The value of the property
 */
export function getPropertyValue<T>(params: PropertySearchParams<T>): T | undefined {
    const f = findPropertyName(params) || '';
    return params.object[f];
}

/**
 * Given a "shaped" (case sensitive) object, it reads the property values from a source object.
 * Commonly use to map DTO to Default Object structures.
 * @param source Any Object
 * @param destination An initializated object with the expected attributes to copy from the source object.
 * @param caseSensitive Indicate if the lookup of the properties should be case sensitive
 */
export function ShapeObject(source: { [key: string]: any }, destination: { [key: string]: any }, caseSensitive = false) {
    Object.keys(destination).forEach(key => {
        const value = getPropertyValue({ object: source, name: key, caseSensitive });
        if (typeof value === 'object') {
            return ShapeObject(value, destination[key]);
        }
        destination[key] = value;
    });
}

export function removeFN(obj: unknown): unknown {
    if (!obj || typeof obj !== 'object') {
        return obj;
    }

    const cleanObj = cloneDeep(obj) as { [id: string]: unknown };
    Object.keys(cleanObj).forEach(key => {
        if (typeof cleanObj[key] === 'function') {
            delete cleanObj[key];
        } else if (typeof cleanObj[key] === 'object') {
            cleanObj[key] = removeFN(cleanObj[key]);
        }
    });
    return cleanObj;
}
