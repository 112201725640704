import { call, put, select, takeEvery } from 'redux-saga/effects';

import { OperationStatus } from '@pressreader/appstore';
import { ExtractReturnType } from '@pressreader/types';
import { forOwn } from '@pressreader/utils';

import { getResources } from '../services';
import { ILegacyTemplate } from '../types';

import { loadResourcesAction, resourcesChanged, resourcesLoadBegan, resourcesLoadDone, resourcesLoadFailed } from './actions';
import { selectStatus } from './selectors';

function* loadResources() {
    const status: ReturnType<typeof selectStatus> = yield select(selectStatus);
    if (status === OperationStatus.Pending) {
        return;
    }

    yield put(resourcesLoadBegan());

    if (!getResources) {
        yield put(resourcesLoadFailed());
        return;
    }

    try {
        const res: ExtractReturnType<typeof getResources> = yield call(getResources);

        const resources: Record<string, string> = {};
        forOwn(res.resources, (value, key) => {
            resources[key.toLowerCase()] = value;
        });

        const templates: Record<string, ILegacyTemplate> = {};
        for (const name in res.templates) {
            const fnBody = res.templates[name];
            if (name && fnBody) {
                templates[name] = { name, fnBody };
            }
        }
        yield put(resourcesLoadDone({ resources, templates }));
        yield put(resourcesChanged());
    } catch (e) {
        yield put(resourcesLoadFailed());
    }
}

export function* saga() {
    yield takeEvery(loadResourcesAction, loadResources);
}
