/* eslint-env es6 */

'use strict';

import { isString, isObject } from 'lodash';
import ndAlert from 'nd.alert';
import ndRes from 'deprecated/nd.res';
import ndLogger from 'nd.logger';
import HttpError from 'shared/errors/http.error';

function normalize(error) {
    if (error == null) {
        // eslint-disable-line eqeqeq
        return new Error('Unexpected error');
    }

    if (isString(error)) {
        return new Error(error);
    }

    if (!isObject(error)) {
        throw new TypeError('Error has to be an object');
    }

    if (!(error instanceof Error)) {
        // treat HTTP response as error
        if ('status' in error && 'responseText' in error) {
            return new HttpError({
                status: error.status,
                responseText: error.responseText,
                responseJSON: error.responseJSON,
            });
        }
    }

    return error;
}

function resolveResourceKey({ error, message }) {
    if (error.resourceKey) {
        return ndRes
            .valAsync(error.resourceKey)
            .then(translation => ({ error, message: translation || message }))
            .catch(err => {
                ndLogger.error(err);
                return Promise.reject(new Error(err));
            });
    }
    return Promise.resolve({ error, message });
}

function showAlert({ message }) {
    return new Promise(resolve => {
        const alert = ndAlert();
        alert.onClosed(() => {
            resolve();
        });
        alert.show(message);
    });
}

/**
 * Creates error handler function
 *
 * @param {Boolean} alert - indicates that  alert with error message whould be shown
 * @returns {function({Exception})} - error handler function
 */
function createErrorHandler({ log = true, alert = false } = {}) {
    const processingChain = [];
    if (alert) {
        processingChain.push(resolveResourceKey);
        processingChain.push(showAlert);
    }

    return error =>
        new Promise((resolve, reject) => {
            if (error === undefined) {
                reject(error);
                return;
            }

            const normalizedError = normalize(error);
            if (log) {
                ndLogger.error(error);
            }

            processingChain
                .reduce((prev, handler) => prev.then(handler), Promise.resolve({ error: normalizedError, message: normalizedError.message }))
                .then(() => {
                    reject(normalizedError);
                });
        });
}

const logError = createErrorHandler();
const logAndAlertError = createErrorHandler({ alert: true });
const alertError = createErrorHandler({ alert: true, log: false });

export {
    /**
     * Logs the error.
     *
     * @param {string|Error} error
     * @returns {Promise} -  rejected Promise with the Error
     */
    logError,
    /**
     * Logs the error and shows alert with the error message.
     * Once user closed the alert, returns rejected promise.
     *
     * @param {string|Error} error
     * @returns {Promise.<Error>} -  rejected Promise with the Error
     */
    logAndAlertError,
    /**
     * Alert with the error message.
     * Once user closed the alert, returns rejected promise.
     *
     * @param {string|Error} error
     * @returns {Promise.<Error>} -  rejected Promise with the Error
     */
    alertError,
};
