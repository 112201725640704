import { getConfigValue } from '@pressreader/config';
import { ISize } from '@pressreader/geometry';
import { getJson } from '@pressreader/services';
import { buildImageUrl, IImageTransformationParams } from '@pressreader/utils';

interface IImageMeta extends Partial<ISize> {
    contentType: string;
}

function getImageUrl(imageId: string, transformationParameters?: IImageTransformationParams) {
    return buildImageUrl(getConfigValue('imageServer.download.url', ''), imageId, transformationParameters);
}

function getImageMeta(imageId: string) {
    return getJson<IImageMeta>({ url: buildImageUrl(getConfigValue('imageServer.meta.url', ''), imageId) });
}

export { IImageMeta, getImageUrl, getImageMeta };
