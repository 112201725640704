import * as ko from 'knockout';
import resources from 'nd.res';
import { setCurrentSubscriptionAutoRenew } from '@pressreader/commerce-api';
import ndAlert from '@pressreader/src/nd.alert';
import ndConfig from '@pressreader/src/deprecated/nd.config';
import { getBehaviourCase, BEHAVIOUR_CASE } from '@pressreader/src/subscriptions/services/behaviourprofile.service';
import BehaviourActionViewModel from '@pressreader/src/subscriptions/viewmodels/behaviouraction.viewmodel';
import { getBasePath } from '@pressreader/routing';

function externalManageSubscriptionUrl() {
    return ndConfig.get('Authentication.ExternalUpgradeSubscriptionPage', '');
}

function defaultManageSubscriptionUrl() {
    const url = `${getBasePath()}Accounting/UpgradeSubscription/`;
    const params = new URLSearchParams(window.location.search);
    params.append('returnUrl', window.location.href.replace(window.location.origin, ''));
    return `${url}?${params}`;
}

function manageAction() {
    window.location = externalManageSubscriptionUrl() || defaultManageSubscriptionUrl();
}

class SubscriptionViewModel {
    constructor(data) {
        this.isLoading = ko.observable(false);
        this.isLoaded = ko.observable(false);

        this.isFree = ko.observable(false);
        this.subscriptionName = ko.observable();
        this.subscriptionStatusName = ko.observable();
        this.subscriptionMonthlyFee = ko.observable();
        this.subscriptionRecoveredAndMustBePayed = ko.observable(false);
        this.showSubscriptionDateAndFee = ko.observable(false);
        this.subscriptionExpirationDate = ko.observable();
        this.subscriptionExpirationDateFormatted = ko.observable();

        this.showStopAutoRenewButton = ko.pureComputed(() => {
            const showStopAutoRenewButton = ndConfig.get('Accounting.ShowStopAutoRenewButton', false);
            return showStopAutoRenewButton && this.subscriptionRecoveredAndMustBePayed();
        });

        this.isUnlimited = ko.pureComputed(() => {
            const expireDate = this.subscriptionExpirationDate();
            if (!expireDate) {
                return true;
            }

            const threshold = new Date();
            threshold.setYear(threshold.getFullYear() + 5);

            const current = new Date(expireDate);
            return current > threshold;
        });

        this.customActions = ko.observableArray();
        this.manageActionTitle = ko.observable();

        this.confirmStopAutoRenew = async () => {
            if (!this.showStopAutoRenewButton()) {
                return;
            }

            const stopAutoRenewMessage = resources.val('Accounting.Subscription.StopAutoRenewMessage');
            const cancelButtonText = resources.val('Buttons.Cancel.Text');
            const yesButtonText = resources.val('Buttons.Yes.Text');
            await ndAlert().confirm(stopAutoRenewMessage, cancelButtonText, yesButtonText, true);
            try {
                await setCurrentSubscriptionAutoRenew(false);
            } catch (error) {
                ndAlert().alert(ndRes.val('Errors.Post.SaveFailed'));
            }
        };

        // dummy implementation, until real one loaded
        this._manageAction = () => false;

        this._populate(data);
        this.isLoaded(true);
    }

    manage() {
        this._manageAction();
    }

    _populate(data = {}) {
        if (data) {
            this.isFree(data.isFree);
            this.subscriptionName(data.subscriptionName);
            this.subscriptionStatusName(data.subscriptionStatusName);
            this.subscriptionMonthlyFee(data.subscriptionMonthlyFee);
            this.subscriptionRecoveredAndMustBePayed(data.subscriptionRecoveredAndMustBePayed);
            this.showSubscriptionDateAndFee(!externalManageSubscriptionUrl() && data.showSubscriptionDateAndFee);
            this.subscriptionExpirationDate(data.subscriptionExpirationDate);
            this.subscriptionExpirationDateFormatted(data.subscriptionExpirationDateFormatted);

            if (data.behaviourProfileName) {
                this._applyBehaviourProfile(data.behaviourProfileName);
            }
        }

        this.manageActionTitle(resources.val('Buttons.UpgradeSubscription.Text'));
        this._manageAction = manageAction;
    }

    _applyBehaviourProfile(profileName) {
        getBehaviourCase(profileName, BEHAVIOUR_CASE.MANAGE_SUBSCRIPTION).then(behaviourCase => {
            const actions = behaviourCase.actions.map(actionDefinition => new BehaviourActionViewModel(actionDefinition));
            this.customActions(actions);

            const customManage = actions.filter(a => a.name === 'manage');
            if (customManage.length > 0) {
                const action = customManage[0];
                this.manageActionTitle(action.title);
                this._manageAction = () => Promise.resolve(action.execute()).then(() => this.load());
            }
        });
    }
}

export default SubscriptionViewModel;
