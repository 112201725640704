import { ArticleKeyType } from './keytype';

class ArticleKeyBase {
    type: ArticleKeyType;
    constructor(keyType: ArticleKeyType) {
        this.type = keyType;
    }
}

export { ArticleKeyBase };
