import { createSelector } from 'reselect';

import {
    AuthSignInSystemType,
    IAuthSignInSystem,
    IExternalSignInSystemV1ConfigDto,
    IExternalSignInSystemV2ConfigDto,
    IExternalSignInSystemV3ConfigDto,
    mapExternalSignInSystemV1,
    mapExternalSignInSystemV2,
    mapExternalSignInSystemV3,
} from '@pressreader/authentication-types';
import { selectGetConfigValue } from '@pressreader/config';

export const selectExternalSignInSystems = createSelector(selectGetConfigValue, getConfigValue => {
    const externalSystems = getConfigValue<IExternalSignInSystemV1ConfigDto[]>('ui.SignIn.ExternalSystems') || [];
    return externalSystems.map(mapExternalSignInSystemV1);
});

export const selectExternalSignInSystemsV2 = createSelector(selectGetConfigValue, getConfigValue => {
    const featuredSystem = getConfigValue<AuthSignInSystemType>('features.app.auth.featuredSystem');
    const externalSystemsMap = getConfigValue<Record<string, IExternalSignInSystemV2ConfigDto>>('ui.SignIn.ExternalSystemsV2') || {};

    const systems: IAuthSignInSystem[] = [];
    for (const name in externalSystemsMap) {
        const dto = externalSystemsMap[name];

        if (dto.enabled === 'true') {
            const featured = name === featuredSystem;
            systems.push(mapExternalSignInSystemV2(name, dto, featured));
        }
    }

    return systems;
});

export const selectExternalSignInSystemsV3 = createSelector(selectGetConfigValue, getConfigValue => {
    const featuredSystem = getConfigValue<AuthSignInSystemType>('features.app.auth.featuredsystem');
    const externalSystemsMap = getConfigValue<Record<string, IExternalSignInSystemV3ConfigDto>>('ui.SignIn.ExternalSystemsV3') || {};

    const systems: IAuthSignInSystem[] = [];
    for (const name in externalSystemsMap) {
        const dto = externalSystemsMap[name];

        const featured = name === featuredSystem;
        systems.push(mapExternalSignInSystemV3(name, dto, featured));
    }

    return systems;
});

export const selectAllowPromoNotif = createSelector(selectGetConfigValue, getConfigValue =>
    getConfigValue<boolean>('features.signup.allowpromotionalnotifications', false),
);

export const selectAllowServiceNotif = createSelector(selectGetConfigValue, getConfigValue =>
    getConfigValue<boolean>('features.signup.allowservicenotifications', false),
);
