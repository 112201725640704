/**
 * Created on 5/22/2015.
 */

// Delegate
    //////////////////////////////////////

import * as $ from 'jquery';

    var nd = $.nd = $.nd || {};

    nd.event = (function () {

        var global = {
            trigger: function (event, extraParameters) {
                events.trigger(event, global, extraParameters);
                return global;
            },
            bind: function (type, data, fn) {
                events.bind(global, type, data, fn);
                return global;
            },
            unbind: function (type, fn) {
                events.unbind(global, type, fn);
                return global;
            }
        };

        var events = {
            global:global,
            makeEvent: function (evt, eventType) {
                if (!evt) evt = {};
                evt.type = eventType;
                return evt;
            },
            createEventDelegate: function (eventType) {
                /// <summary>
                ///     Create function to use as object's event with 'eventType' event name.
                ///     Using:
                ///     function ClassA{
                ///         this.click = $.nd.event.createEventDelegate("click");
                ///     };
                ///     ClassA.prototype={
                ///         dblclick: $.nd.event.createEventDelegate("dblclick"),
                ///         execute:function(){
                ///             this.click();
                ///             this.click({x:0});
                ///             this.dblclick({x:0});
                ///             $.nd.event.trigger("dblclick", this, {item1: "somedata"});
                ///         }
                ///     };
                ///
                ///     var classA = new ClassA();
                ///     classA.click(function(){alert("click on " + this);});
                ///     classA.dblclick(function(){alert("dblclick on " + this);});
                ///     classA.execute();
                /// </summary>
                /// <returns type="Function" />
                /// <param name="eventType" type="String">
                ///     Event's name
                /// </param>

                //$.nd.event.trigger("loaded", _api, _api);
                //$.nd.event.one(this,"loaded", data, fn);
                //$.nd.event.trigger("loaded", this, this);
                //eventType, [ eventData ], handler(eventObject)
                return function (eventData, handler) {
                    /// <summary>
                    ///  Event
                    /// </summary>
                    /// <param name="[eventData]" type="Object">
                    ///     Event's name
                    /// </param>
                    /// <param name="handler" type="Function">
                    ///     Event's name
                    /// </param>

                    if (arguments.length == 0
                        || (arguments.length == 1 && !$.isFunction(eventData))) {
                        // fire event
                        events.trigger(eventType, this, eventData);
                    } else {
                        events.bind(this, eventType, eventData, handler);
                    }
                    return this;
                };
            },
            add: function (obj, type, handler, data) {

                var handleObjIn, handleObj;

                if (handler.handler) {
                    handleObjIn = handler;
                    handler = handleObjIn.handler;
                }

                // Make sure that the function being executed has a unique ID
                if (!handler.guid) {
                    handler.guid = $.guid++;
                }

                // Init the element's event structure
                var elemData = $.data(obj);

                // If no elemData is found then we must be trying to bind to one of the
                // banned noData elements
                if (!elemData) {
                    return;
                }

                var _events = elemData.events = elemData.events || {};

                handleObj = handleObjIn ?
                    $.extend({}, handleObjIn) :
                { handler: handler, data: data };

                handleObj.type = type;
                handleObj.guid = handler.guid;

                var handlers = _events[type];
                // Init the event handler queue
                if (!handlers) {
                    handlers = _events[type] = [];
                }
                // Add the function to the element's handler list
                handlers.push(handleObj);

                return {
                    dispose: function () {
                        events.unbind(obj, type, handler);
                    }
                };
            },
            trigger: function (event, obj, extraParameters) {
                var type = event.type || event;
                if (type == event) {
                    event = {
                        type: type
                    };
                }

                var objData = $.data(obj),
                    events = objData && objData.events;

                if (!objData || !events) {
                    return;
                }
                var eventType = events[type];
                if (!eventType || eventType.length === 0)
                    return;

                // Clone the handlers to prevent manipulation
                eventType = eventType.slice(0);

                // Clone the incoming data, if any
                var data = $.makeArray(extraParameters);
                data.unshift(event);

                for (var j = 0; j < eventType.length; j++) {
                    var handleObj = eventType[j];
                    event.data = handleObj.data;
                    var ret = handleObj.handler.apply(obj, data);
                    if (ret === false) break;
                }
            },
            bind: function (obj, type, data, fn) {
                if ($.isFunction(data)) {
                    fn = data;
                    data = undefined;
                }
                return $.nd.event.add(obj, type, fn, data);
            },
            one: function (obj, type, data, fn) {
                if (jQuery.isFunction(data)) {
                    fn = data;
                    data = undefined;
                }
                var handler = $.proxy(fn, function (event) {
                    $.nd.event.unbind(this, type, handler);
                    return fn.apply(this, arguments);
                });
                $.nd.event.add(obj, type, handler, data);
            },
            unbind: function (obj, type, fn) {
                var objData = $.data(obj),
                    events = objData && objData.events;

                if (!objData || !events) {
                    return;
                }

                var eventType = events[type];
                if (eventType) {
                    for (var j = 0; j < eventType.length; j++) {
                        var handleObj = eventType[j];
                        if (!fn) {
                            $.nd.event.unbind(obj, type, handleObj.handler);
                            eventType.splice(j--, 1);
                        } else {
                            // remove the given handler for the given type
                            if (fn.guid === handleObj.guid) {
                                eventType.splice(j--, 1);
                            }
                        }
                    }
                    // remove generic event handler if no more handlers exist
                    if (eventType.length === 0) {
                        delete events[type];
                    }
                }

                // Remove the expando if it's no longer used
                if ($.isEmptyObject(events)) {

                    delete objData.events;

                    if ($.isEmptyObject(objData)) {
                        $.removeData(obj);
                    }
                }
            }
        };
        return events;
    })();

    export default nd.event;

