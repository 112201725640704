import { httpGet, httpPut } from '@pressreader/services';

import { ISubscriptionDto, ISubscriptionProductDto } from './types';

const serviceBasePath = 'v1/subscriptions';

/**
 * Gets subscription of current user
 */
function getCurrentSubscription() {
    return httpGet<ISubscriptionProductDto>(serviceBasePath, 'current');
}

/**
 * Set auto renew status for current subscription
 */
function setCurrentSubscriptionAutoRenew(isAutoRenewable: boolean) {
    return httpPut<void>(serviceBasePath, 'current/autorenew', JSON.stringify(isAutoRenewable), {
        contentType: 'application/json',
        disableRetry: true,
    });
}

/**
 * Set auto renew status for user bundle product
 */
function setUserBundleAutoRenew(userProductId: number, isAutoRenewable: boolean) {
    return httpPut<void>(serviceBasePath, `${userProductId}/autorenew`, JSON.stringify(isAutoRenewable), {
        contentType: 'application/json',
        disableRetry: true,
    });
}

/**
 * Gets subscription by productId
 */
function getSubscriptionById(productId: string) {
    return httpGet<ISubscriptionDto>('subscriptions', productId);
}

/**
 * Gets subscriptions by promoCampaignId
 */
function getPromoSubscriptions(promoCampaignId: string) {
    return httpGet<ISubscriptionDto[]>('promo', `${promoCampaignId}/subscriptions`);
}

export { getCurrentSubscription, setCurrentSubscriptionAutoRenew, setUserBundleAutoRenew, getSubscriptionById, getPromoSubscriptions };
