import { StoreStoragesMapObject, IStoreStorage } from './storage';

enum PersistentStorageType {
    /** Data stored here has no expiration time. */
    Local,
    /** Data stored here expires when page is closed. */
    Session,
}

const storagesImpl = {
    [PersistentStorageType.Local]: localStorage,
    [PersistentStorageType.Session]: sessionStorage,
};

function storageKey(a: string, b: string) {
    return `${a}_${b}`;
}

function persistByFeature(storageType: PersistentStorageType, key: string, state: any) {
    try {
        const serializedState = JSON.stringify(state);
        storagesImpl[storageType].setItem(key, serializedState);
    } catch {
        // ignore write errors
    }
}

function persist(storageType: PersistentStorageType, keyPrefix: string, state: any, storeStorages: StoreStoragesMapObject) {
    for (const featureName in storeStorages) {
        if (state[featureName]) {
            const storeStorage = storeStorages[featureName];
            const storageState = storeStorage.persist(state[featureName]);
            persistByFeature(storageType, storageKey(keyPrefix, featureName), storageState);
        }
    }
}

function hydrate(storageType: PersistentStorageType, key: string, storeStorage: IStoreStorage<any, any>): any {
    try {
        const stateStr = storagesImpl[storageType].getItem(key);

        if (stateStr === null) {
            return undefined;
        }

        const state = JSON.parse(stateStr);
        storeStorage.hydrate(state);
    } catch (err) {
        return undefined;
    }
}

interface IPersistentStorage {
    name: string;
    storageType: PersistentStorageType;
    persist(state: any, storeStorages: StoreStoragesMapObject): void;
    hydrate(featureName: string, storage: IStoreStorage<any, any>): void;
}

function createPersistentStorage(name: string, storageType: PersistentStorageType): IPersistentStorage {
    return {
        name,
        storageType,
        persist: (state, storeStorages) => persist(storageType, name, state, storeStorages),
        hydrate: (featureName, storeStorage) => hydrate(storageType, storageKey(name, featureName), storeStorage),
    };
}

export { PersistentStorageType, IPersistentStorage, createPersistentStorage };
