import { Lock, LockHandle } from 'core/lock';

function div(className: string, id?: string) {
    const el = document.createElement('div');
    el.className = className;
    if (id !== undefined) {
        el.id = id;
    }
    return el;
}

// Content panel markup:
/*
<div id="Panel_contentPanel">
    <div id="Panel_loadingPanel" class="panel panel-loading">
        <div id="Panel_loadingScreenLabel" class="alert alert-wait">
            <div class="alert-body">
                <div class="ab-content overthrow">
                    <div id="Panel_loadingPanel_spinner" class="pr-spinner pr-spinner-l"></div>
                </div>
            </div>
        </div>
    </div>
</div>
 */

const loadingIndicatorMarkup = `<div class="spinner" role="progressbar">
 <div class="spinner-line line1"></div>
 <div class="spinner-line line2"></div>
 <div class="spinner-line line3"></div>
 <div class="spinner-line line4"></div>
 <div class="spinner-line line5"></div>
 <div class="spinner-line line6"></div>
 <div class="spinner-line line7"></div>
 <div class="spinner-line line8"></div>
 <div class="spinner-line line9"></div>
 <div class="spinner-line line10"></div>
 <div class="spinner-line line11"></div>
</div>`;

function build(container: HTMLElement) {
    const spinnerEl = div('pr-spinner pr-spinner-l', 'Panel_loadingPanel_spinner');
    spinnerEl.innerHTML = loadingIndicatorMarkup;
    const alertBodyContent = div('ab-content overthrow');
    alertBodyContent.appendChild(spinnerEl);

    const alertBody = div('alert-body');
    alertBody.appendChild(alertBodyContent);

    const loadingScreenLabel = div('alert alert-wait', 'Panel_loadingScreenLabel');
    loadingScreenLabel.appendChild(alertBody);

    container.appendChild(loadingScreenLabel);
}

/**
 * Single instance of loading indicator per entire application.
 * Shared across all components.
 * Markup of loading indicator should be statically included into page.
 */
class LoadingIndicator {
    // it's visivle wnen app just started
    private _visible = true;
    private _panel: HTMLElement = null;
    private _lock = new Lock();

    get isVisible() {
        return this._visible;
    }

    /**
     * Mounts loading indicator behaviour to the existing panel
     * @param {Element} panel
     */
    init(panel: HTMLElement) {
        if (!panel) {
            throw new Error('Loading panel must be an HTMLElement');
        }

        this._panel = panel;
        // build panel content with spinner from scratch,
        // if it was not statically preloaded
        if (!panel.childNodes || panel.childNodes.length === 0) {
            build(panel);
        }
    }

    show(): void;
    show(handle: LockHandle): LockHandle;
    /**
     * Shows loading panel.
     * @param handle - If provided - shows indicator with locking. Gotta be provided to hide method to release the lock.
     */
    show() {
        if (this._panel !== null && !this._visible) {
            this._panel.style.display = 'block';
            this._visible = true;
        }
        if (!arguments.length) {
            return;
        }
        // eslint-disable-next-line prefer-rest-params
        const handle = arguments[0];
        if (handle === null || handle === undefined || handle instanceof LockHandle) {
            return handle || this._lock.lock();
        }
    }

    /**
     * Hides loading panel.
     * @param handle - The `handle` returned by show method when indicator was shown with locking.
     */
    hide(handle?: LockHandle) {
        if (arguments.length) {
            if (!handle) {
                return;
            }
            if (handle instanceof LockHandle) {
                handle.release();
            }
        }
        if (this._lock.isLocked) {
            return;
        }
        if (this._panel !== null && this._visible) {
            // strictly speaking it's better to stop animation
            // in fact in the most of browsers CSS aniamtion will work
            // so hiding panel automatically stops it, execpt IE9-
            // as we don't support such old-fashion browsers we don't care to stop animation
            this._panel.style.display = 'none';
            this._visible = false;
        }
    }
}

const indicator = new LoadingIndicator();

// TODO: duplication of export default and export const statement
// so not to change A LOT of vanillajs and es6 files to support one standard
export const init = indicator.init.bind(indicator);
export const show = indicator.show.bind(indicator);
export const hide = indicator.hide.bind(indicator);

export default indicator;

export { loadingIndicatorMarkup };
