import { createSelector } from 'reselect';

import { selectGetConfigValue } from '@pressreader/config';
import { error } from '@pressreader/logger';
import { TargetPlatform } from '@pressreader/types';

import { MODULE_NAME } from '../../constants';
import { AppGroupType, VALID_APP_GROUP_TYPES } from '../../types/app.group.type';
import { validateTargetPlatform } from '../../utils';
import { State } from '../reducer';

const getState = (state: { [MODULE_NAME]: State }) => state[MODULE_NAME];

export const selectDefaultTargetPlatform = createSelector(selectGetConfigValue, getConfigValue =>
    getConfigValue<TargetPlatform>('features.app.target.platform', TargetPlatform.Web),
);

export const selectTargetPlatform = createSelector(getState, state => state.targetPlatform);

export const selectSupportedPlatforms = createSelector(selectTargetPlatform, selectGetConfigValue, (targetPlatform, getConfigValue) => {
    const supportedPlatformsJoined = getConfigValue<string>('features.app.platforms.supported', '') || '';

    const supportedPlatforms = supportedPlatformsJoined.split(',').filter(validateTargetPlatform);

    if (supportedPlatforms.indexOf(targetPlatform) < 0) {
        supportedPlatforms.push(targetPlatform);
    }

    return supportedPlatforms;
});

export const selectFaceliftAllowed = createSelector(selectGetConfigValue, getConfigValue =>
    getConfigValue<boolean>('features.app.facelift.allowed', false),
);
export const selectFaceliftEnabled = createSelector(getState, state => state.faceliftEnabled);

export const selectExperimentalEnabled = createSelector(getState, state => state.experimentalEnabled);

export const selectGiftCardTopbarEnabled = createSelector(
    selectTargetPlatform,
    selectGetConfigValue,
    (platform, getConfigValue) =>
        [TargetPlatform.Pervasive, TargetPlatform.Voyager].indexOf(platform) < 0 &&
        getConfigValue<boolean>('features.app.topbar.giftcard.enabled', false),
);

export const selectNewPurchaseFlowEnabled = createSelector(selectGetConfigValue, getConfigValue =>
    getConfigValue<boolean>('features.app.purchase.flow.enabled', false),
);

export const selectAppGroupType = createSelector(selectGetConfigValue, getConfigValue => {
    const appGroupType = getConfigValue<AppGroupType>('features.app.group.type', AppGroupType.Pressreader);

    if (VALID_APP_GROUP_TYPES.indexOf(appGroupType) < 0) {
        error(`Invalid app group type ${appGroupType}`);
    }
    return appGroupType;
});

export const selectLegacyPlatform = createSelector(getState, state => state.legacyPlatform);
