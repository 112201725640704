import { createSelector } from 'reselect';

import { createParameterSelector } from '@pressreader/appstore';
import { PopupType } from '@pressreader/popups-types';

import { MODULE_NAME } from '../constants';
import { isDialogPopup } from '../types/utils';

import { IState } from './reducer';

const getState = (state: { [MODULE_NAME]: IState }) => state[MODULE_NAME];

const selectPopups = createSelector(getState, state => state.popups);

const selectId = createParameterSelector<'id', string>('id');

const selectPopupById = createSelector(getState, selectId, (state, id) => state.popups.find(popup => popup.id === id));

const selectPopupType = createParameterSelector<'popupType', PopupType>('popupType');

const selectPopupsByType = createSelector(selectPopups, selectPopupType, (popups, popupType) => popups.filter(p => p.type === popupType));

const selectDialogPopups = createSelector(selectPopups, popups => popups.filter(isDialogPopup));

export { selectPopups, selectPopupById, selectPopupsByType, selectDialogPopups };
