import { AnyAction } from 'redux';
import { delay, put, race, take } from 'redux-saga/effects';
import { Subject } from 'rxjs';

import { isNonNullable } from '@pressreader/types';

import { ActionCreator } from './models/action';
import { StoreManager } from './store.manager';

export const storeManager = new StoreManager();
export const store = storeManager.store;
export const dispatch = store.dispatch.bind(store);
export const select = <S = unknown, R = unknown>(selector: (state: S) => R) => selector(store.getState());
/** Be careful using it: store subscription remains even if there are no more observable subscibers. */
export function observeSelector<S = unknown, R = unknown>(selector: (state: S) => R) {
    const subject = new Subject<R>();

    let prevValue = select(selector);

    store.subscribe(() => {
        const newValue = select(selector);

        if (prevValue !== newValue) {
            prevValue = newValue;
            subject.next(newValue);
        }
    });

    return subject.asObservable();
}

export function waitForAction(actionName: string, timeout = 1000) {
    return new Promise<void>(resolve => {
        storeManager.runSaga(function* () {
            yield race({
                wait: take(actionName),
                timeout: delay(timeout),
            });

            resolve();
        });
    });
}

export function runAction<TResult, TError>(action: AnyAction, doneAction: ActionCreator<TResult>, failedAction?: ActionCreator<TError>) {
    return new Promise<TResult>((resolve, reject) => {
        storeManager.runSaga(function* () {
            yield put(action);
            const result: AnyAction = yield take([doneAction, failedAction].filter(isNonNullable));
            if (result.type === `${doneAction}`) {
                resolve((result as ReturnType<typeof doneAction>).payload);
            } else if (failedAction && result.type === `${failedAction}`) {
                reject((result as ReturnType<typeof failedAction>).payload);
            }
        });
    });
}

export function subscribe<S = unknown, R = unknown>(selector: (state: S) => R, listener: (next: R) => void) {
    let prevValue = select(selector);

    return store.subscribe(() => {
        const newValue = select(selector);

        if (prevValue !== newValue) {
            prevValue = newValue;
            listener(newValue);
        }
    });
}

export function createParameterSelector<P extends string, R extends string | number | boolean | null>(paramName: P) {
    return (_: unknown, param: { [K in P]: R }) => param[paramName];
}

function createOptionalParameterSelector<P extends string, R extends string | number | boolean | Date>(
    paramName: P,
): (_: unknown, param: { [K in P]?: R }) => R | undefined;
function createOptionalParameterSelector<P extends string, R extends string | number | boolean | Date>(
    paramName: P,
    defaultValue: R,
): (_: unknown, param: { [K in P]?: R }) => R;
function createOptionalParameterSelector<P extends string, R extends string | number | boolean | Date>(paramName: P, defaultValue?: R) {
    return (_: unknown, param: { [K in P]?: R }) => param[paramName] ?? defaultValue;
}
export { createOptionalParameterSelector };
