import { call, put } from 'redux-saga/effects';

import { error } from '@pressreader/logger';
import { ViewName } from '@pressreader/navigation-types';
import { routingManager } from '@pressreader/routing';
import { ExtractReturnType } from '@pressreader/types';

import { getParams, viewer } from '../api';
import { NavigationParams, ReplaceType } from '../types';

import { pushStateToHistoryAction, replaceStateWithReloadHistoryAction } from './actions';

function* goTo(params: NavigationParams, replaceType: ReplaceType) {
    const currentView = viewer?.getActiveView();

    if (currentView && currentView.canLeaveView) {
        try {
            const canLeave: ExtractReturnType<typeof currentView.canLeaveView> = yield call(currentView.canLeaveView, params);

            if (!canLeave) {
                return;
            }
        } catch (err) {
            error(err);
        }
    }

    yield call(replaceView, params, replaceType);
}

function* replaceView(params: NavigationParams, replaceType: ReplaceType) {
    const data: ExtractReturnType<typeof routingManager.generateUrl> = yield call(routingManager.generateUrl, params);
    let replace = false;

    if (!data) {
        return;
    }

    const viewName = params.viewName;

    if (replaceType === ReplaceType.ForceOn) {
        replace = true;
    }

    if (replaceType === ReplaceType.On) {
        replace = viewName === viewer?.getActiveViewName();
    }

    yield call(createState, viewName, params, replace, data.url);
}

function* createState(viewName: ViewName, params: Record<string, unknown>, isReplace: boolean, to: string) {
    const allParams = getParams(viewName, params);
    const state = { ...allParams.stateParams };

    if (isReplace) {
        yield put(replaceStateWithReloadHistoryAction({ to, state }));
    } else {
        yield put(pushStateToHistoryAction({ to, state }));
    }
}

export { goTo };
