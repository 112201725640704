import { cookies } from '@pressreader/utils';

import { httpPut } from './http';

export const INTERFACE_LANGUAGE_ITEM_NAME = `${window.basePath}interfaceLanguage`;

function setInterfaceLanguage(language: string): Promise<void> {
    saveInterfaceLanguage(language);
    return httpPut(
        'v1/interfacelanguage',
        { language },
        {
            contentType: 'application/json',
            disableRetry: true,
        },
    );
}

function saveInterfaceLanguage(language: string) {
    window.localStorage.setItem(INTERFACE_LANGUAGE_ITEM_NAME, language);
}

function getInterfaceLanguage(): string {
    const lng = cookies.get('lng');
    return window.localStorage.getItem(INTERFACE_LANGUAGE_ITEM_NAME) || lng || 'en-us';
}

export { setInterfaceLanguage, getInterfaceLanguage };
